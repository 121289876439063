import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Popover from '@mui/material/Popover';
import { useQuery, useMutation } from '@apollo/client';
import { IconButton, InputBase, Box, Button, Divider, Paper, Typography, List, ListItem, ListItemText } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom';
import { EmplpoyeesList } from '../../graphicalQl/usequery/userProfileUseQuery';
import { Delete_Employee } from '../../graphicalQl/mutation/userProfileMutation';
import CircularLoader from '../../loader/loader';
import DriverProfile from './driverProfile';
import { t } from "i18next"
import "./userList.css"




interface usersListState {
  id: number
  userName: any,
  email: string,
  status: string,
  date: string,
  phone: string,
  role: string

}

function UserList() {
  const navigate = useNavigate()
  const [usersList, setUsersList] = useState<usersListState[]>([])
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
  
  const [showData, setShowData] = React.useState(usersList.map(() => true));

  const { loading, error, data, refetch } = useQuery(EmplpoyeesList,{context:{clientName:"userClient"}});

  
  const [deleteEmployee] = useMutation(Delete_Employee, {
    onCompleted: (data) => {
      
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: 'userClient' },
    refetchQueries:[EmplpoyeesList]
  });
   

   //poopover functions
   const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
   const PopoverOpen = Boolean(anchorEl);
   
   const id = PopoverOpen ? 'simple-popover' : undefined;
   const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
     setAnchorEl(event.currentTarget);
     setProfileId(id)
   };
   const handlePopoverClose = () => {
     setAnchorEl(null);
   };

     //Search input functions
  const [searchInput, setSearchInput] = React.useState("")
  const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
  }
  const searchInputResultRows = usersList.filter((each) => (each.userName.toLowerCase().includes(searchInput)))

  //Popup funtions
  const [profileId, setProfileId] = React.useState<"" | string>("")
  const [popUpOpen, setPopupOpen] = React.useState(false);
  const handlePopupOpen = () => setPopupOpen(true);
  const handlePopupClose = () => setPopupOpen(false);

  const handleViewProfileButton = () => {
    handlePopupOpen()
    handlePopoverClose()
  }

  const getRow = () => {
    return "user-list-table-row"
  }

  



  useEffect(() => {
    if (data) {
      if(data!==null){
        
      const requiredDataList = data.employees.edges.map((each: any) => ({
        id: each.node.employeeId,
        userName: `${each.node.firstName} ${each.node.lastName}`,
        email: each.node.email,
        status: each.node.employeeStatus,
        date: each.node.createdOn,
        pin:each.node.pin,
        phone: each.node.mobile,
        // role: each.node.role.roleName

      }

      ))
      setUsersList(requiredDataList)

      }
    }
  }, [data]);

  
  
  
  
  if (loading) return <CircularLoader />;
  if (error) return <p>Error : {error.message}</p>;

  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state
    const updatedRows = usersList.filter((row) => rowSelectionModel.includes(row.id));
    const categoryValue = updatedRows.map((each) => each.id);
    
    // JSON.stringify(categoryValue)
    deleteEmployee({ variables: { employeeIds:JSON.stringify(categoryValue)}})
    setRowSelectionModel([]);
    
  }
   
  const handleEditClick=()=>{
    navigate(`/create-user/${profileId}`)
  }

  


 

  const popoverContent = (
    <List style={{ padding: '0px 0px 0px 0px' }}>
      <Button onClick={handleViewProfileButton} style={{ textTransform: "capitalize", padding: '0px 0px 0px 0px' }}>
        <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
          <ListItemText primary="View Profile" />
        </ListItem>
      </Button>
      <Divider />
      <Button onClick={handleEditClick} style={{ textTransform: "capitalize", padding: '0px 0px 0px 0px' }}>
        <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
          <ListItemText primary="Edit" />
        </ListItem>
      </Button>
      <Divider />
      <Button style={{ textTransform: "capitalize", padding: '0px 0px 0px 0px' }}>
        <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
          <ListItemText primary="Delete" />
        </ListItem>
      </Button>
    </List>
  );
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', headerClassName: "user-role-header-names", flex: 0 },
    {
      field: 'userName',
      //@ts-ignore
      headerName: t("users_list.user_name"),
      headerClassName: "user-role-header-names",
      flex: 1,

    },
    {
      field: 'email',
      //@ts-ignore
      headerName: t("users_list.email"),
      headerClassName: "user-role-header-names",
      flex: 1.5,

    },
    {
      field: 'phone',
      //@ts-ignore
      headerName: t("users_list.phone"),
      headerClassName: "user-role-header-names",
      flex: 1,

    },
    {
      field: 'role',
      //@ts-ignore
      headerName: t("users_list.role"),
      headerClassName: "user-role-header-names",
      flex: 0,


    },
    {
      field: 'date',
      //@ts-ignore
      headerName: t("users_list.date_added"),
      headerClassName: "user-role-header-names",

      flex: 1,

    },
    {

      field: 'status',
      //@ts-ignore
      headerName: t("users_list.status"),
      headerClassName: "user-role-header-names",
      
      flex: 1,

    },
    {
      field: 'pin',
      //@ts-ignore
      headerName: t("users_list.reveal_pin"),
      headerClassName: "user-role-header-names",
      
      flex: 1,

      renderCell: (params) => {

         //show the visibleOffIcon and pin
         const toggleVisibility = (index: number) => {
          const updatedShowData = [...showData];
          updatedShowData[index] = !updatedShowData[index];
          setShowData(updatedShowData);


        };
        //show the visibleIcon and stars
        const toggleVisibilityHide = (index: any) => {
          const updatedShowData = [...showData];
          updatedShowData[index] = !updatedShowData[index];
          setShowData(updatedShowData);
        }
        
        return (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
             {showData[params.row.id] ? params.value :"* ".repeat(6) }
            {showData[params.row.id] ?
            <IconButton onClick={() => toggleVisibilityHide(params.row.id)}>
            <VisibilityOffIcon sx={{marginLeft:"15px"}} />
          </IconButton>
             
              :
              <IconButton onClick={() => toggleVisibility(params.row.id)}>
              <VisibilityIcon sx={{marginLeft:"15px"}} />
            </IconButton>
              
              }


          </Box>
        )
      },
    },
    {
      field: '',
      //@ts-ignore

      headerClassName: "user-role-header-names",
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box className='user-roles-header-hover' style={{ display: 'flex', alignItems: 'center', height: "32px" }}>
          <IconButton onClick={(event) => handleClick(event, params.row.id)}>
            <MoreVertOutlinedIcon />
          </IconButton>
          <Popover
            id={id}
            open={PopoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            style={{
              width: "180px",
              height: "225px",
              paddingTop: "0px",
              paddingBottom: "0px"
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>

          {/* User profile Pop Up */}
          <DriverProfile popUpOpen={popUpOpen} handlePopupClose={handlePopupClose} profileId={profileId} />
        </Box>
      ),

    }
  ];


  return (
    <Box sx={{ backgroundColor: "#f9f9f9" }}>
      <Paper sx={{ padding: "10px" }} >
        <Box className='user-requests-header-order'>
          <Typography className='user-requests-header-title'>{t("users_list.users")}</Typography>
          <Button onClick={() => { navigate("/create-user") }} sx={{ textTransform: "none" }} variant='contained' color='warning'>{t("users_list.add_user")}</Button>
        </Box>
        <Divider />
        <Paper
          component="form"
          sx={{ p: "2px 4px", marginTop: "10px", display: "flex", alignItems: "center", width: 400 }}
        >
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            //@ts-ignore
            placeholder={t("users_list.search_by_name")}
            inputProps={{ "aria-label": "search google maps" }}
            onChange={handleUserInputChange}
          />

        </Paper>
        <Box sx={{ marginTop: "10px", padding: "10px" }}>
          <div style={{ width: "100%", textAlign: "right" }}>
            <Delete onClick={handleDeleteClick} />
          </div>
          <DataGrid
            rows={searchInputResultRows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 7, 10]}
            getRowClassName={getRow}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}

          />

        </Box>



      </Paper>

    </Box>
  )

}
export default UserList
