export function register() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/serviceWorker.js")
        .then((registration) => {
          console.log(
            "ServiceWorker registration successful with scope: ",
            registration.scope
          );
        })
        .catch((error) => {
          console.log("ServiceWorker registration failed: ", error);
        });
    }
  }
  
  export function unregister() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.unregister()
            .then((success) => {
              console.log("ServiceWorker unregistration successful: ", success);
            })
            .catch((error) => {
              console.error("ServiceWorker unregistration failed: ", error);
            });
        })
        .catch((error) => {
          console.error("ServiceWorker not ready to unregister: ", error);
        });
    }
  }
  