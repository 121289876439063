import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './profile.css'
import { Button, Divider, FormControlLabel, List, ListItem, Switch, TextField } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import image from '../../assets/planImage.png';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface UserData {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    phoneNumber: string;
    presentDay: number;
    lastDay: number;
  }

  interface ProfileTabsProps {
    list: UserData[];
  }
const plans=[
  {
  id:1,imageValue:image,amount:12,buy:true,name:"Receipt Builder",representation:""},
  {
    id:2,imageValue:image,amount:3,buy:false,name:"Gift Cards",representation:"recommended"},
    {
      id:3,imageValue:image,amount:24,buy:true,name:"Email Marketing",representation:""},
      {
        id:4,imageValue:image,amount:13,buy:false,name:"Loyality Points",representation:"Popular"},
        {
          id:5,imageValue:image,amount:13,buy:true,name:"Receipt Builder",representation:""},
          {
            id:6,imageValue:image,amount:13,buy:false,name:"Receipt Builder",representation:""},
]

const columns: GridColDef[] = [
  
  {
    field: 'date',
    headerName: 'Payment date',
    headerClassName:"profile-details1",
   flex:1,
   editable: false,
  },
  {
    field: 'invoiceID',
    headerName: 'Invoice ID',
    headerClassName:"profile-details1",
    flex:1,
    editable: false,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    headerClassName:"profile-details1",
    flex:1,
    editable: false,
  },
  
  {
    field: 'status',
    headerName: 'Status',
    headerClassName:"profile-details1",
    
    sortable: false,
    flex:1,
    editable: false,
    renderCell:(params)=>{
      let classValue;
      switch(params.row.status){
        case "success":
          classValue="successValue"
          break;
          case "pending":
            classValue="pendingValue"
            break;
            default:
              classValue="failureValue"
              break;
      }
      return(
<Typography className={classValue}>{params.row.status}</Typography>
      )
    }
  },
  {
    field: 'pdf',
    headerName: 'Detailse',
    headerClassName:"profile-details1",
    flex:1,
    editable: false,
    renderCell:(pramas)=>{
      return(
        <PictureAsPdfIcon/>
      )
    }
  },
];
const rows = [
  { id: 1, date: '11-10-2023, 14:20', invoiceID: '102211AZ0', amount: 35,status:"success",pdf:"" },
  { id: 2, date: '11-10-2023, 14:20', invoiceID: '102211AZ0', amount: 42,status:"success",pdf:"" },
  { id: 3, date: '11-10-2023, 14:20', invoiceID: '102211AZ0', amount: 45 ,status:"pending",pdf:""},
  { id: 4, date: '11-10-2023, 14:20', invoiceID: '102211AZ0', amount: 16,status:"success",pdf:"" },
  { id: 5, date: '11-10-2023, 14:20', invoiceID: '102211AZ0', amount: null,status:"failed",pdf:"" },
  
];
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
 

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const handleRowClass=()=>{
  return "profile-rowclass"
}

// const handleCellClass=()=>{
//   return "cellClass"
// }
export default function ProfileTabs(props:ProfileTabsProps) {

  
    const{list}=props
    const[listData,setList]=React.useState(list[0])
    const initialValues={
      companyName:"",
      phoneNumber:"",
      email:"",
      taxId:"",
      vatNumber:"",
      pinCode:"",
billing:"",
country:"",
state:""
    
    }
  const [value, setValue] = React.useState(0);
  const [state, setState] = React.useState(initialValues);
  const [switchValue,setSwitch]=React.useState<boolean>(false)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleNavigation=(event:React.ChangeEvent<HTMLInputElement>)=>{
    setSwitch(event.target.checked)
  }
 

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value} = event.target;
  
    setState((prevState) => ({
      ...prevState,
      [name]:  value,
    }));
  };
  const handleInputChange2= (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
  
    setList((prevState) => ({
      ...prevState,
      [name]:  value,
    }));
  };

  const handleSelectChange = (
    event: SelectChangeEvent
  ) => {
    const { name, value } = event.target;
  
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  console.log(listData)
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Profile" {...a11yProps(0)} />
          <Tab label="Pricing & Subscription" {...a11yProps(1)} />
          <Tab label="Billing Details" {...a11yProps(2)} />
          <Tab label="Billing History" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box >
            <Typography className='profile-details1' >Details</Typography>
            <Box sx={{width:"60%",display:'flex',alignItems:"center",justifyContent:"space-between",marginTop:"2%"}}>
                <TextField label="First Name"  value={listData.firstName} color='warning' name='firstName' onChange={handleInputChange2}/>
                <TextField label="Last Name"  value={listData.lastName} color='warning' name="lastName" onChange={handleInputChange2}/>
            </Box>
            <Box sx={{width:"60%",display:'flex',alignItems:"center",justifyContent:"space-between",marginTop:"2%"}}>
                <TextField label="Email Address"  value={listData.email} color='warning' name="email" onChange={handleInputChange2}/>
                <TextField label="Phone Number"  value={listData.phoneNumber} color='warning' name="phoneNumber" onChange={handleInputChange2}/>
            </Box>
         
            
            <FormControlLabel
  control={<Switch color='warning' onChange={(e)=>handleNavigation} value={switchValue}/>}
  label={<Typography className='profile-label'>Use same for billing details</Typography>}
  labelPlacement="start"
  sx={{marginTop:"2%"}}
/>
<Button className='profile-button-save' sx={{textTransform:"none"}}>Save</Button>
<Button className='profile-delete' sx={{marginTop:"2%",marginLeft:"85%",textTransform:"none"}}>Delete My Account</Button>


              
          
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box>
        <Box sx={{padding:"1%",display:"flex",width:'100%'}}>
          <Box sx={{width:"60%"}}>
      <Typography className='profile-details1'>Current Plan</Typography>
      <Typography className='profile-details1' sx={{marginTop:"1%"}}>Your Current Plan is Premium(£15 <span className='profile-text3'>/month</span>)</Typography>
      <Typography className='profile-text3' sx={{marginTop:"1%"}}>A simple for your restaurant business</Typography>
      <Typography className='profile-details1' sx={{marginTop:"3%"}}>Active Until Oct 10, 2023</Typography>
      <Typography className='profile-text3' sx={{marginTop:"1%"}}>We will send you a notification upon Subscription expiration</Typography>
      <Typography className='profile-details1' sx={{marginTop:"3%"}}>£180<span className='profile-text3'>/month </span>Gold Plan</Typography>
      <Typography className='profile-text3' sx={{marginTop:"1%"}}>Standard plan for small to medium businesses</Typography>
      </Box>
      <Box  sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",width:"40%"}}>
        <Box className="profile-alert-con" sx={{ width: '60%' }}>
          <Typography className='profile-attention'>We need your Attention!</Typography>
          <Typography className='profile-alert'>Your plan requires update</Typography>
        </Box>
        <Box sx={{display:"flex",alignItems:"center",justifyContent:'space-between',marginTop:"2%",width:"60%"}}>
                <Typography className='profile-day'>Days</Typography>
                <Typography className='profile-date'>{list[0].presentDay} of {list[0].lastDay} days</Typography>
            </Box>
        <Box sx={{ width: '60%' }}>
      <LinearProgress variant="determinate"  value={(list[0].presentDay / list[0].lastDay) * 100} />
    </Box>
    <Typography className='profile-remaing-days' sx={{marginTop:"2%"}}>{list[0].lastDay-list[0].presentDay} days remaining until your plan requires update</Typography>
      </Box>
      </Box>
      <Box  sx={{marginTop:"2%",display:"flex",alignItems:'center',justifyContent:"space-between"}}>
        <Button  className='button-Background'>Upgrade Plan</Button>
        <Button  className='profile-attention' sx={{textTransform:"none"}}>View Current Plan { <DoubleArrowIcon/>}</Button>
       
      </Box>
      <Divider sx={{marginTop:"2%"}}/>
      <Box className="profile-recommend-con">
{/* <Typography className='profile-recommend-text'>Recommended</Typography> */}
<Typography className='profile-addonText' sx={{marginTop:"1%"}}>Add On’s to Customise Your Selected Package</Typography>
      <Typography className='profile-remaing-days' sx={{marginTop:"1%"}}>Crafted to draw in fresh customers and encourage return visits through these software solutions.</Typography>
      <Box sx={{width:"100%"}}>
      <List sx={{display:"flex",flexWrap:"wrap"}}>
        {plans.map((each)=>(
          <ListItem key={each.id} sx={{width:"25%"}}>
<Box className="profile-plan-con" sx={{width:"100%"}}>
  {each.representation!=="" && (<Box className="bestSellerRgt">
    <Typography>{each.representation}</Typography>
  </Box>)}
  
  <img src={each.imageValue} alt="plan" style={{marginTop:"4%"}}/>
  <Typography sx={{marginTop:"5%"}} className='profile-email'>{each.name}</Typography>
  <Typography sx={{marginTop:"2%"}} className='profile-email'> £{each.amount}/mo</Typography>
  {each.buy ? ( <Typography className='profile-pack-buy'>In Use</Typography>):( <Button className='profile-attention' sx={{marginTop:"4%",textTransform:"none"}}>Add to Pack</Button>)}
 
</Box>
          </ListItem>
        ))}
      </List>
      </Box>
      </Box>
      </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Box>
          <Box sx={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>
          <Typography className='profile-details1'>Billing Details</Typography>
          <Button className='button-Background'>save</Button>
        </Box>
        <Box sx={{display:'flex',alignItems:'center',justifyContent:"space-between",width:"60%"}}>
          <TextField label="company Name" color='warning' name='companyName' onChange={handleInputChange} value={state.companyName}/>
          <TextField label="phone Number" color='warning' name='phoneNumber' onChange={handleInputChange} value={state.phoneNumber}/>
        </Box>
        <Box sx={{display:'flex',alignItems:'center',justifyContent:"space-between",width:"60%",marginTop:"2%"}}>
        <TextField label="Email Address" color='warning' name='email' onChange={handleInputChange} value={state.email}/>
        <Box sx={{width:"35%"}}>
      <FormControl sx={{  minWidth: '100%'} } >
        <InputLabel id="demo-simple-select-label">Country</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={state.country}
          label="Country"
          color="warning"
          onChange={handleSelectChange}
          name='country'
        >
          <MenuItem value={"uk"}>UK</MenuItem>
          <MenuItem value={"usa"}>USA</MenuItem>
          <MenuItem value={"india"}>India</MenuItem>
        </Select>
      </FormControl>
    </Box>
        </Box>
        <Box sx={{display:'flex',alignItems:'center',justifyContent:"space-between",width:"60%",marginTop:"2%"}}>
        <TextField label="Tax Id" color='warning' name='taxId' onChange={handleInputChange} value={state.taxId}/>
        <Box sx={{width:"35%"}}>
      <FormControl sx={{  minWidth: '100%'} } >
        <InputLabel id="demo-simple-select-label">State</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={state.state}
          label="State"
          color="warning"
          onChange={handleSelectChange}
          name='state'
        >
          <MenuItem value={"uk"}>UK</MenuItem>
          <MenuItem value={"usa"}>USA</MenuItem>
          <MenuItem value={"india"}>India</MenuItem>
        </Select>
      </FormControl>
    </Box>
        </Box>
        <Box sx={{display:'flex',alignItems:'center',justifyContent:"space-between",width:"60%",marginTop:"2%"}}>
          <TextField label="VAT Number" color='warning' name='vatNumber' onChange={handleInputChange} value={state.vatNumber}/>
          <TextField label="pin code" color='warning' name='pinCode' onChange={handleInputChange} value={state.pinCode}/>
        </Box>
        <Box sx={{width:"60%",marginTop:"2%"}}>
<TextField label="Billing Address" name='billing' fullWidth color='warning' onChange={handleInputChange} value={state.billing}/>
        </Box>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowClassName={handleRowClass}
        // getCellClassName={handleCellClass}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        
        disableRowSelectionOnClick
      />
    </Box>
      </CustomTabPanel>
    </Box>
  );
}