
import React from "react"
import { Box, FormGroup, FormControlLabel, Checkbox, Modal, Paper, Typography, Autocomplete, TextField, InputBase, IconButton, Button, Divider, Switch } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid';
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { t } from 'i18next'
import "./inventoryLists.css"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router";


import {
    GridRowModesModel,
    GridRowModes,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { ProgressBar } from "primereact/progressbar";



const InventoryPopUpStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 525,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

const style = {
    position: 'absolute' as 'absolute',
    top: '53%',
    left: '77%',
    transform: 'translate(-50%, -50%)',
    width: "45%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};


const locationLists = ["Location1", "Location2", "Location3", "Location4"]

const popUpRows = [
    { id: 1, product: "Burger", sku: "001", variation: "small", stockInHand: "24", Reason: ["Not received", "Received"], adjustments: "40", Barcode: "00110" },
    { id: 2, product: "Burger", sku: "001", variation: "small", stockInHand: "24", Reason: ["Not received", "Received"], adjustments: "40", Barcode: "00110" },
    { id: 3, product: "Burger", sku: "001", variation: "small", stockInHand: "24", Reason: ["Not received", "Received"], adjustments: "40", Barcode: "00110" },
    { id: 4, product: "Burger", sku: "001", variation: "small", stockInHand: "24", Reason: ["Not received", "Received"], adjustments: "40", Barcode: "00110" },
    { id: 5, product: "Burger", sku: "001", variation: "small", stockInHand: "24", Reason: ["Not received", "Received"], adjustments: "40", Barcode: "00110" },
    { id: 6, product: "Burger", sku: "001", variation: "small", stockInHand: "24", Reason: ["Not received", "Received"], adjustments: "40", Barcode: "00110" },
    { id: 7, product: "Burger", sku: "001", variation: "small", stockInHand: "24", Reason: ["Not received", "Received"], adjustments: "40", Barcode: "00110" },
    { id: 8, product: "Burger", sku: "001", variation: "small", stockInHand: "24", Reason: ["Not received", "Received"], adjustments: "40", Barcode: "00110" },
    { id: 9, product: "Burger", sku: "001", variation: "small", stockInHand: "24", Reason: ["Not received", "Received"], adjustments: "40", Barcode: "00110" },
    { id: 10, product: "Burger", sku: "001", variation: "small", stockInHand: "24", Reason: ["Not received", "Received"], adjustments: "40", Barcode: "00110" },
    { id: 11, product: "Burger", sku: "001", variation: "small", stockInHand: "24", Reason: ["Not received", "Received"], adjustments: "40", Barcode: "00110" }
];




const rows = [
    { id: 1, productName: "Pizza", variation: '10inch', quantity: 35, price: "£3.00" },
    { id: 2, productName: "Pizza", variation: '10inch', quantity: 45, price: "£3.00" },
    { id: 3, productName: "Pizza", variation: '10inch', quantity: 75, price: "£3.00" },
    { id: 4, productName: "Pizza", variation: '10inch', quantity: 18, price: "£3.00" },
    { id: 5, productName: "Pizza", variation: '10inch', quantity: 11, price: "£3.00" },
    { id: 6, productName: "Pizza", variation: '10inch', quantity: 30, price: "£3.00" },
    { id: 7, productName: "Pizza", variation: '10inch', quantity: 15, price: "£3.00" },
    { id: 8, productName: "Pizza", variation: '10inch', quantity: 25, price: "£3.00" },
    { id: 9, productName: "Pizza", variation: '10inch', quantity: 5, price: "£3.00" },
    { id: 10, productName: "Pizza", variation: '10inch', quantity: 45, price: "£3.00" },
    { id: 11, productName: "Pizza", variation: '10inch', quantity: 35, price: "£3.00" },
    { id: 12, productName: "Pizza", variation: '10inch', quantity: 55, price: "£3.00" },
    { id: 13, productName: "Pizza", variation: '10inch', quantity: 65, price: "£3.00" },
    { id: 14, productName: "Pizza", variation: '10inch', quantity: 16, price: "£3.00" }


];



interface State {
    Sun: boolean
    Mon: boolean
    Tue: boolean
    Wed: boolean
    Thu: boolean
    Fri: boolean
    Sat: boolean
    popUpSearch: string
    inventoyListSearch: string
    inventoryListPopUpOnChnage: string

}



//intial states of checkbox elements
const intialStatesOfCheckbox: State = {
    Sun: false,
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    popUpSearch: "",
    inventoyListSearch: "",
    inventoryListPopUpOnChnage: "",

}

const DeletePopupStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
};

interface ProgressCellProps {
    value: number;
  }
 


const InventoryLists = () => {

    const navigate = useNavigate()

    const [statesOfCheckBox, setStatesOfCheckbox] = React.useState<State>(intialStatesOfCheckbox);

    const [open, setOpen] = React.useState(false);
    const [inventoryListRows, setInventoryListRows] = React.useState(rows);
    const [innerRows, setRows] = React.useState(popUpRows);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [showPopUp, setShowPopUP] = React.useState(inventoryListRows.map(() => false));
    const [inventoryListPopUpOpen, setInventoryListPopUpOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
    const [selectedTime, setSelectedTime] = React.useState<Date | null>(null);

    //handle delete icon pop-up's
    const [deletePopupOpen, setDeletePopupOpen] = React.useState(false);
    const [wantToDeleteRow, setWantToDeleteRow] = React.useState<GridRowModel>({})
    const handleDeletePopupOpen = () => setDeletePopupOpen(true);
    const handleDeletePopupClose = () =>{
        setDeletePopupOpen(false);
        setWantToDeleteRow({})

    }


    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date); // Update the selectedDate state with the chosen date
    };

    const handleTimeChange = (time: Date | null) => {
        setSelectedTime(time); // Update the selectedTime state with the chosen time
    };


    const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked, name, type } = event.target

        setStatesOfCheckbox((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    }

    const handleDeleteAndPopup = (id:GridRowId) => {
        const selectedRow = innerRows.filter((row) => row.id === id);
        setWantToDeleteRow(selectedRow[0])
        setDeletePopupOpen(true)

    }

    const handleDontRemoveButton = () => {
        setDeletePopupOpen(false)
        setWantToDeleteRow({})
    }

    






    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        setRows(innerRows.filter((row) => row.id !== id));
        setDeletePopupOpen(false);
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };


    const columns: GridColDef[] = [
        {
            field: 'id',
            //@ts-ignore
            headerName: t("inventory_lists.product_id"),
            headerClassName: "inventory-list-table-column",
            flex:1,
            
        },
        {
            field: 'productName',
            //@ts-ignore
            headerName: t("inventory_lists.product_name"),
            headerClassName: "inventory-list-table-column",
            flex:1,
            
        },
        {
            field: 'variation',
            //@ts-ignore
            headerName: t("inventory_lists.variation"),
            headerClassName: "inventory-list-table-column",
            flex:1,
            
        },
        {
            field: 'quantity',
            //@ts-ignore
            headerName: t("inventory_lists.quantity"),
            headerClassName: "inventory-list-table-column",
            flex:1,
            renderCell:(params)=>{
                var colorOfPath=""
                if (params.value <=15){
                    colorOfPath="red"
                }else if((params.value<30) &&(params.value)>15 ){
                    colorOfPath="#F38B08"
                }
                
                else{
                    colorOfPath="#00AE26"
                }

                
               

                

                return (
                    <Box style={{width: 48, height: 48 }}>
                    <CircularProgressbar
                    styles={buildStyles({
                        textColor:"#707070",
                        textSize:"27px",
                        pathColor:colorOfPath
                    })}
                      value={params.value} text={params.value} />
                    </Box>
                )
            }

        },
        {
            //@ts-ignore
            headerName: t("inventory_lists.recurring"),
            headerClassName: "inventory-list-table-column",
            flex:1,
            renderCell: (params) => {

                //Inventory list popup controls
                const handleInventoryListPopUpOpen = () => setInventoryListPopUpOpen(true);
                const handleinventoryListPopUpClose = () => setInventoryListPopUpOpen(false);

                const handleToggle = (index: number) => {
                    const updatedShowData = [...showPopUp];
                    updatedShowData[index] = !updatedShowData[index];
                    setShowPopUP(updatedShowData);

                }
                const handlePopupCombinedClick = (id: number) => {
                    handleToggle(id); // Call the first function
                    handleinventoryListPopUpClose(); // Call the second function
                };

                const handlePopUpSaveButton = (row: any) => {
                    const { id, quantity } = row

                    const changedQuantityRows = inventoryListRows.map((row) => {
                        if (row.id === id) {
                            return { ...row, quantity: parseInt(statesOfCheckBox.inventoryListPopUpOnChnage) >= 0 ? (quantity + parseInt(statesOfCheckBox.inventoryListPopUpOnChnage)) : quantity }
                        }
                        return row
                    })
                    console.log(changedQuantityRows)



                }

                const handleCombineFunctionsInPopUpSaveButton = (id: number) => {
                    handlePopUpSaveButton(id)
                    handleinventoryListPopUpClose()

                }



                const handleCombinedClick = (id: number) => {
                    handleToggle(id); // Call the first function
                    handleInventoryListPopUpOpen(); // Call the second function
                };

                return (
                    <Box>
                        {showPopUp[params.row.id] &&
                            <Box>
                                <Modal
                                    open={inventoryListPopUpOpen}
                                    onClose={handleinventoryListPopUpClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={InventoryPopUpStyle}>
                                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <IconButton sx={{ marginRight: "50px" }} onClick={() => handlePopupCombinedClick(params.row.id)} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                                                <CloseIcon />
                                            </IconButton>
                                            <Typography sx={{ marginLeft: "50px" }} className="inventory-pop-up-header-name">{t("inventory_lists.recurring_stock")}</Typography>
                                        </Box>
                                        <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />

                                        <Typography className="inventory-pop-up-paragraph4">{t("inventory_lists.every_day")}</Typography>
                                        <Box sx={{ textAlign: "center", marginTop: "15px", marginBottom: "15px" }}>
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label={t("inventory_lists.pop_up_quantity")}
                                                name="inventoryListPopUpOnChnage"
                                                onChange={handleCheckBox}
                                                defaultValue="0"
                                            />
                                        </Box>
                                        <Box sx={{ textAlign: "right", marginTop: "10px" }}>
                                            <Button onClick={() => handleCombineFunctionsInPopUpSaveButton(params.row)} color="warning" variant="contained">{t("inventory_lists.save")}</Button>
                                        </Box>
                                    </Box>
                                </Modal>

                            </Box>
                        }
                        <IconButton onChange={() => { handleCombinedClick(params.row.id) }}>
                            <Switch
                                checked={showPopUp[params.row.id]}
                                color='warning' />
                        </IconButton>
                    </Box>
                )
            }



        },
        {
            field: 'price',
            //@ts-ignore
            headerName: t("inventory_lists.price"),
            headerClassName: "inventory-list-table-column"

        },
        {
            field: '',
            //@ts-ignore
            // headerName: t("inventory_lists.actions"),
            headerClassName: "inventory-list-table-column",
            disableColumnMenu:true,         
            renderCell: (params) => (
                <IconButton>
                    <MoreVertIcon />
                </IconButton>
            )

        },
    ];

    const popUpColumns: GridColDef[] = [
        {
            field: 'sku',
            //@ts-ignore
            headerName: t("inventory_lists.sku"),
            headerClassName: "inventory-list-table-column",
            width: 150
        },
        {
            field: 'product',
            //@ts-ignore
            headerName: t("inventory_lists.product"),
            headerClassName: "inventory-list-table-column",
            width: 120,
            align: 'left',
            headerAlign: 'left',

        },
        {
            field: 'variation',
            //@ts-ignore
            headerName: t("inventory_lists.variation"),
            headerClassName: "inventory-list-table-column",

            width: 100,

        },
        {   field: 'stockInHand',
            //@ts-ignore
            headerName: t("inventory_lists.quantity"),
            headerClassName: "inventory-list-table-column",
            editable:true,
            width:30
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            getActions: ({ id }) => {
              const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      
              if (isInEditMode) {
                return [
                  <GridActionsCellItem
                    icon={<SaveIcon />}
                    label="Save"
                    sx={{
                      color: 'primary.main',
                    }}
                    onClick={handleSaveClick(id)}
                  />,
                  <GridActionsCellItem
                    icon={<CancelIcon sx={{height:"20px",color:"#ababaa"}} />}
                    label="Cancel"
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                  />,
                ];
              }
      
              return [
                <GridActionsCellItem
                  icon={<EditIcon  sx={{marginLeft:"0px",height:"20px",color:"#ababaa"}} />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(id)}
                  color="inherit"
                />,
                <GridActionsCellItem
                  icon={<DeleteIcon sx={{height:"20px",color:"#ababaa"}} />}
                  label="Delete"
                  onClick={() => handleDeleteAndPopup(id)}
                  color="inherit"
                />,
              ];
            },
          },
    
        

    ];



    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const getHover = () => {
        return "inventory-list-table-row-hover"
    }


    const getFontSize = () => {
        return "inventory-list-table-row"
    }

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Paper elevation={0}>
                <Box className='inventory-header-buttons-order' sx={{ padding: "10px" }}>
                    <Box className="inventory-header-names-order" sx={{ width: "55%" }}>
                        <Typography className='inventory-header-name'>{t("inventory")}</Typography>
                        <Autocomplete
                            defaultChecked
                            id="combo-box-demo"
                            options={locationLists}
                            sx={{ width: 150 }}
                            renderInput={(params) => <TextField {...params} label={locationLists[0]} />}
                        />
                        <Paper 
                            component="form"
                            sx={{ p: "2px 4px", marginTop: "10px", display: "flex", alignItems: "center", width: 350 }}
                        >
                            <IconButton sx={{ p: "10px" }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                onChange={handleCheckBox}
                                name="inventoyListSearch"
                                sx={{ ml: 1, flex: 1 }}
                                //@ts-ignore
                                placeholder={t("inventory_lists.search_by_name_sku")}
                                inputProps={{ "aria-label": "search google maps" }}
                            />

                        </Paper>
                    </Box>
                    <Box className="inventory-header-buttons-order" sx={{ width: "35%" }}>
                            <Button onClick={() => { navigate("/stockAdjustments") }} variant='outlined' className='inventory-list-header-buttons-hover inventory-header-buttons'>{t("inventory_lists.bulk_stock_adjustments")}</Button>
                            <Button onClick={handleOpen} variant='outlined' className='inventory-list-header-buttons-hover inventory-header-buttons'>{t("inventory_lists.recurring_stock_settings")}</Button>
                        
                    </Box>
                </Box>
                <Divider />
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={inventoryListRows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        getRowClassName={getHover}
                        getCellClassName={getFontSize}
                    />
                </Box>


            </Paper>
           


            <Modal open={deletePopupOpen}
                onClose={handleDeletePopupClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={DeletePopupStyle}>
                    <Box>
                        <IconButton onClick={handleDeletePopupClose} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                            <CloseIcon />

                        </IconButton>
                        <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Box>
                    <Typography className="delete-pop-up-text">{t("inventory_lists.are_you_want")} "{wantToDeleteRow.product}" {t("inventory_lists.from_recurring_stock")}</Typography>
                    <Box sx={{marginTop:"20px",display:"flex",justifyContent:"space-around",alignItems:"center"}}>
                        <Button sx={{textTransform:"none"}} className="delete-pop-up-don-not-button" onClick={handleDontRemoveButton} color="warning" variant="outlined">{t("inventory_lists.don't_remove")}</Button>
                        <Button sx={{textTransform:"none"}} className="delete-pop-up-remove-button" onClick={handleDeleteClick(wantToDeleteRow.id)} color="warning" variant="outlined">{t("inventory_lists.yes_remove")}</Button>
                    </Box>
                </Box>


            </Modal>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <IconButton onClick={handleClose} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                            <CloseIcon />
                        </IconButton>
                        <Typography className="inventory-pop-up-header-name">{t("inventory_lists.recurring_stock")}</Typography>
                        <Button color="warning" variant="contained">{t("inventory_lists.save")}

                        </Button>
                    </Box>
                    <Box sx={{ margin: "10px" }}>
                        <Box>
                            <Typography className="inventory-pop-up-paragraph1">{t("inventory_lists.choose_a_date")}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        label={t("inventory_lists.select_date_range")} />
                                </DemoContainer>
                            </LocalizationProvider>
                            <Box sx={{ marginLeft: "25px" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['TimePicker']}>
                                        <TimePicker
                                            onChange={handleTimeChange}
                                            value={selectedTime}
                                            label={t("inventory_lists.select_time")} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Box>

                        </Box>
                        <Box sx={{ display: "flex", margin: "10px" }}>
                            <FormGroup sx={{ margin: "10px" }}>
                                <FormControlLabel

                                    control={
                                        <Checkbox
                                            value={intialStatesOfCheckbox.Sun}
                                            onChange={handleCheckBox}
                                            name='Sun'
                                            color='warning'
                                        />
                                    }
                                    label={<Typography >{t("inventory_lists.sun")}</Typography>}
                                    className="createDiscount_checkBox"
                                />
                            </FormGroup>
                            <FormGroup sx={{ margin: "10px" }}>
                                <FormControlLabel

                                    control={
                                        <Checkbox
                                            onChange={handleCheckBox}
                                            name='Mon'
                                            color='warning'
                                        />
                                    }
                                    label={<Typography >{t("inventory_lists.mon")}</Typography>}
                                    className="createDiscount_checkBox"
                                />
                            </FormGroup>
                            <FormGroup sx={{ margin: "10px" }}>
                                <FormControlLabel

                                    control={
                                        <Checkbox
                                            onChange={handleCheckBox}
                                            name='Tue'
                                            color='warning'
                                        />
                                    }
                                    label={<Typography >{t("inventory_lists.tue")}</Typography>}
                                    className="createDiscount_checkBox"
                                />
                            </FormGroup>
                            <FormGroup sx={{ margin: "10px" }}>
                                <FormControlLabel

                                    control={
                                        <Checkbox
                                            onChange={handleCheckBox}
                                            name='Wed'
                                            color='warning'
                                        />
                                    }
                                    label={<Typography >{t("inventory_lists.wed")}</Typography>}
                                    className="createDiscount_checkBox"
                                />
                            </FormGroup>


                        </Box>
                        <Box sx={{ display: "flex", margin: "10px" }}>
                            <FormGroup sx={{ margin: "10px" }}>
                                <FormControlLabel

                                    control={
                                        <Checkbox
                                            onChange={handleCheckBox}
                                            name='Thu'
                                            color='warning'
                                        />
                                    }
                                    label={<Typography >{t("inventory_lists.thu")}</Typography>}
                                    className="createDiscount_checkBox"
                                />
                            </FormGroup>
                            <FormGroup sx={{ margin: "10px" }}>
                                <FormControlLabel

                                    control={
                                        <Checkbox
                                            onChange={handleCheckBox}
                                            name='Fri'
                                            color='warning'
                                        />
                                    }
                                    label={<Typography >{t("inventory_lists.fri")}</Typography>}
                                    className="createDiscount_checkBox"
                                />
                            </FormGroup>
                            <FormGroup sx={{ margin: "10px" }}>
                                <FormControlLabel

                                    control={
                                        <Checkbox
                                            onChange={handleCheckBox}
                                            name='Sat'
                                            color='warning'
                                        />
                                    }
                                    label={<Typography >{t("inventory_lists.sat")}</Typography>}
                                    className="createDiscount_checkBox"
                                />
                            </FormGroup>
                        </Box>
                        <Divider sx={{ marginBottom: "10px" }} />
                        <Typography className="inventory-pop-up-paragraph2">{t("inventory_lists.edit_the_variants")} </Typography>
                        <Box>
                            <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 370, marginTop: "10px", marginBottom: "10px" }}
                            >
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                    <SearchIcon />
                                </IconButton>

                                <InputBase
                                    onChange={handleCheckBox}
                                    name="popUpSearch"
                                    sx={{ ml: 1, flex: 1 }}
                                    //@ts-ignore
                                    placeholder={t("inventory_lists.search_by_name_sku")}
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />



                            </Paper>
                            <Typography className="inventory-pop-up-paragraph3">{t("inventory_lists.44_variants")}</Typography>
                            <Box
                                sx={{
                                    margin: "10px",
                                    height: 300,
                                    width: '100%',
                                    '& .actions': {
                                        color: 'text.secondary',
                                    },
                                    '& .textPrimary': {
                                        color: 'text.primary',
                                    },
                                }}
                            >
                                <DataGrid
                                    rows={innerRows}
                                    columns={popUpColumns}
                                    editMode="row"
                                    rowModesModel={rowModesModel}
                                    onRowModesModelChange={handleRowModesModelChange}
                                    onRowEditStop={handleRowEditStop}
                                    getRowClassName={getHover}
                                    getCellClassName={getFontSize}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}


                                    slotProps={{
                                        toolbar: { setRows, setRowModesModel },
                                    }}
                                />
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Modal>


        </Box>
    )
}

export default InventoryLists
