import { Box,InputLabel, Typography,OutlinedInput,InputAdornment,Button,ToggleButton,ToggleButtonGroup,FormControl } from '@mui/material'
import React from 'react'
import { GridColDef } from '@mui/x-data-grid';
import "./kdsSettings.css"

import {t} from "i18next"



const itemsList=["pizza","Biryani","Dhum biryani","Burger"]

  const initialRows=[
    {
        id:1,
        stationName:"Madhapur1",
        pairedDevice:"KDS 1"
    },
    {
        id:2,
        stationName:"Madhapur2",
        pairedDevice:"KDS 2"
    },

  ]
;
interface NewRow{
    id:number
    stationName:string
    pairedDevice:string
}

const initialNewRow:NewRow={
    id:0,
    stationName:"",
    pairedDevice:""
}



const initialLimitOrderPerItemList=[
    {
        id:1,
        limitPerUser:"",
        item:itemsList[0],
        fromTiime:"",
        toTime:"",
        day:{
          Monday:false,
          Tuesday:false,
          Wednesday:false,
          Thursday:false,
          Friday:false,
          Saturday:false,
          Sunday:false
        }
    }
]

interface inputValues{
    dineInHr:string
    dineInMin:string
    collectionHr:string
    collectionMin:string
    deliveryHr:string
    deliveryMin:string
    addHr:string
    addMin:string
    amount:string
    channel1Hr:string
    channel1Min:string
    channel2Hr:string
    channel2Min:string
    channel3Hr:string
    channel3Min:string


}

const initialAllInputValues:inputValues={
    dineInHr:"",
    dineInMin:"",
    collectionHr:"",
    collectionMin:"",
    deliveryHr:"",
    deliveryMin:"",
    channel1Hr:"",
    channel1Min:"",
    channel2Hr:"",
    channel2Min:"",
    channel3Hr:"",
    channel3Min:"",
    addHr:"",
    addMin:"",
    amount:""

}

const initialAllCheckboxInputValues={
    displayPOSTerminal:false,
    displayOrderTypeWithEachOrder:false,
    displayOrderId:false,
    displaySeatNumberForEachItem:false
}



function KitchenCapacityQuotingTime() {
    const [rows,setRows]=React.useState(initialRows)
    const [limitOrderPerItemList,setLimitOrderPerItemList]=React.useState(initialLimitOrderPerItemList)
  
    const [newRow,setNewRow]=React.useState(initialNewRow)
    
    const [alignment, setAlignment] = React.useState('orderType');

    const newOrderPerItemList= {
        id:limitOrderPerItemList.length+1,
        limitPerUser:"",
        item:itemsList[0],
        fromTiime:"",
        toTime:"",
        repeatOn:"",
        day:{
          Monday:false,
          Tuesday:false,
          Wednesday:false,
          Thursday:false,
          Friday:false,
          Saturday:false,
          Sunday:false
        }

    }

    const columns: GridColDef[] = [
        {
          field: 'branchName',
          headerClassName:"device-set-up-table-header",
          headerName: 'Branch Name',
          flex:1
        },
        {
          field: 'status',
          headerClassName:"device-set-up-table-header",
          headerName: 'Status',
          width:300
        },
      ];

      //Radio buttons functions
      const [manualQuotingTime,setManualQuotingTime]=React.useState("manualQuotingTime")
      
      const handleTextSize=(event:React.ChangeEvent<HTMLInputElement>)=>{
        console.log(event.target.value)
      }

      const handleTicketsPerPage=(event:React.ChangeEvent<HTMLInputElement>)=>{
        console.log(event.target.value)
      }
      const handleNotes=(event:React.ChangeEvent<HTMLInputElement>)=>{
        console.log(event.target.value)
      }

      const handleManualQuotingTime=(event:React.ChangeEvent<HTMLInputElement>)=>{
        setManualQuotingTime(event.target.value)
      }

      const handleNewOnlinePlaced=(event:React.ChangeEvent<HTMLInputElement>)=>{
        console.log(event.target.value)
      }
      
      //Textfield functions

      const [allInputValues,setAllInputValues]=React.useState(initialAllInputValues)
      

      const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const { name, value, type, checked } = event.target;
        setAllInputValues((prevState) => ({
          ...prevState,
          [name]: type === 'checkbox' ? checked : value,
        }));
      };

      const handleUserPerItem=(event:string,id:number)=>{
        setLimitOrderPerItemList(prev=>
          prev.map((each)=>each.id===id?{...each,limitPerUser:event}:each)
          )
      }

      const handleFromTime=(id:number,event:string)=>{
        setLimitOrderPerItemList(prev=>
          prev.map((each)=>each.id===id?{...each,fromTiime:event}:each)
          )
      }

      const handleToTime=(id:number,event:string)=>{
        setLimitOrderPerItemList(prev=>
          prev.map((each)=>each.id===id?{...each,toTime:event}:each)
          )
      }

      const handleAutoComplete=(event:any,newValue:any,id:number)=>{
        setLimitOrderPerItemList(prev=>
          prev.map((each)=>each.id===id?{...each,item:newValue}:each)
          )
      }

      //Add new Limit Orders per Item

      const handleAddNewButton=()=>{

        setLimitOrderPerItemList((prev)=>(
            [newOrderPerItemList,...prev]
        ))
      }

      console.log(limitOrderPerItemList)

      const handleDeleteOrderItem=(id:number)=>{
        const filteredRows=limitOrderPerItemList.filter((each)=>each.id!==id)
        setLimitOrderPerItemList(filteredRows)

      }

      

      //table Edit functions
      const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
      ) => {
        setAlignment(newAlignment);
      };
      //All checkbox input functions

      const [allCheckboxInputValues,setAllCheckboxInputValues]=React.useState(initialAllCheckboxInputValues)
      // const [CheckboxInputValuesInAccordion,setCheckboxInputValuesInAccordion]=React.useState(initialLimitOrderPerItemList[0].day)

      const handleCheckBoxInputValues=(event:React.ChangeEvent<HTMLInputElement>)=>{
        const { name, value, type, checked } = event.target;
        setAllCheckboxInputValues((prevState) => ({
          ...prevState,
          [name]: type === 'checkbox' ? checked : value,
        }))
      }
      const handleCheckBoxInputValuesInAccordion=(event:React.ChangeEvent<HTMLInputElement>,id:number)=>{ 
        const { name, value, type, checked } = event.target;
        const filtered=limitOrderPerItemList.map((each:any)=>{
          if(each.id===id){
            each.day[name]=checked
          } return each
        })
      
        setLimitOrderPerItemList(filtered)
      }

     
  return (
   <Box>
    <Box>
            <Box sx={{marginBottom:"20px",marginTop:"20px",width:"20%"}}>
                <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    fullWidth  
                    onChange={handleChange}
                    >
                    <ToggleButton className={`distance-wise-circle-button ${alignment==="orderType"&& "distance-wise-group-circle-button"}`} sx={{textTransform:"none"}} value="orderType"><Typography className='distance-wise-circle-button'>{t("kitchen_capacity.order_type")}</Typography></ToggleButton>
                    <ToggleButton className={`distance-wise-circle-button ${alignment==="channels"&& "distance-wise-group-circle-button"}`} sx={{textTransform:"none"}} value="channels"><Typography className='distance-wise-circle-button'>{t("kitchen_capacity.channels")}</Typography></ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>

            

            {alignment==="orderType"?(
              <Box>
              <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"90%"}}>
              <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"40%"}}>
                  <Typography className='kds-settings-dinein-text'>{t("kitchen_capacity.dine_in")}</Typography>
                  <Box>
                  <FormControl sx={{ m: 1, width: '35%' }} variant="outlined">
  
                  <OutlinedInput
                  onChange={handleInputChange}
                  value={allInputValues.dineInHr}
                  name='dineInHr'
                   color='warning'
                      endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.hr")}</Typography>}</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                      'aria-label': 'weight',
                      }}
                  />
                  </FormControl>
                  <FormControl sx={{ m: 1, width: '35%' }} variant="outlined">
                  <OutlinedInput
                   onChange={handleInputChange}
                   value={allInputValues.dineInMin}
                   name='dineInMin'
                  color='warning'
                      endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.min")}</Typography>}</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                      'aria-label': 'weight',
                      }}
                  />
                  </FormControl>
                  </Box>
              </Box>
              <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"40%"}}>
                  <Typography className='kds-settings-dinein-text'>{t("kitchen_capacity.collection")}</Typography>
                  <Box>
                  <FormControl sx={{ m: 1, width: '35%' }} variant="outlined">
                  <OutlinedInput
                   onChange={handleInputChange}
                   value={allInputValues.collectionHr}
                   name='collectionHr'
                   color='warning'
                      endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.hr")}</Typography>}</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                      'aria-label': 'weight',
                      }}
                  />
                  </FormControl>
                  <FormControl sx={{ m: 1, width: '35%' }} variant="outlined">
                  <OutlinedInput
                  value={allInputValues.collectionMin}
                   onChange={handleInputChange}
                   name='collectionMin'
                   color='warning'
                      endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.min")}</Typography>}</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                      'aria-label': 'weight',
                      }}
                  />
                  </FormControl>
                  </Box>
              </Box>
  
          </Box>
          <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"36%",marginTop:"15px"}}>
                  <Typography className='kds-settings-dinein-text'>{t("kitchen_capacity.delivery")}</Typography>
                  <Box>
                  <FormControl sx={{ m: 1, width: '35%' }} variant="outlined">
                  <OutlinedInput
                   onChange={handleInputChange}
                   value={allInputValues.deliveryHr}
                   name='deliveryHr'
                   color='warning'
                      endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.hr")}</Typography>}</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                      'aria-label': 'weight',
                      }}
                  />
                  </FormControl>
                  <FormControl sx={{ m: 1, width: '35%' }} variant="outlined">
                  <OutlinedInput
                   onChange={handleInputChange}
                   value={allInputValues.deliveryMin}
                   name='deliveryMin'
                   color='warning'
                      endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.min")}</Typography>}</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                      'aria-label': 'weight',
                      }}
                  />
                  </FormControl>
                  </Box>
              </Box>
              </Box>
            ):(
              <Box>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"90%"}}>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"42%"}}>
                <Typography className='kds-settings-dinein-text'>{t("kitchen_capacity.channel_1")}</Typography>
                <Box>
                <FormControl sx={{ m: 1, width: '35%' }} variant="outlined">

                <OutlinedInput
                onChange={handleInputChange}
                value={allInputValues.dineInHr}
                name='channel1Hr'
                 color='warning'
                    endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.hr")}</Typography>}</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
                </FormControl>
                <FormControl sx={{ m: 1, width: '35%' }} variant="outlined">
                <OutlinedInput
                 onChange={handleInputChange}
                 value={allInputValues.dineInMin}
                 name='channel1Min'
                color='warning'
                    endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.min")}</Typography>}</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
                </FormControl>
                </Box>
            </Box>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"42%"}}>
                <Typography className='kds-settings-dinein-text'>{t("kitchen_capacity.channel_2")}</Typography>
                <Box>
                <FormControl sx={{ m: 1, width: '35%' }} variant="outlined">
                <OutlinedInput
                 onChange={handleInputChange}
                 value={allInputValues.collectionHr}
                 name='channel2Hr'
                 color='warning'
                    endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.hr")}</Typography>}</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
                </FormControl>
                <FormControl sx={{ m: 1, width: '35%' }} variant="outlined">
                <OutlinedInput
                value={allInputValues.collectionMin}
                 onChange={handleInputChange}
                 name='channel2Min'
                 color='warning'
                    endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.min")}</Typography>}</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
                </FormControl>
                </Box>
            </Box>

        </Box>
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"38%",marginTop:"15px"}}>
                <Typography className='kds-settings-dinein-text'>{t("kitchen_capacity.channel_3")}</Typography>
                <Box>
                <FormControl sx={{ m: 1, width: '35%' }} variant="outlined">
                <OutlinedInput
                 onChange={handleInputChange}
                 value={allInputValues.deliveryHr}
                 name='channel3Hr'
                 color='warning'
                    endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.hr")}</Typography>}</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
                </FormControl>
                <FormControl sx={{ m: 1, width: '35%' }} variant="outlined">
                <OutlinedInput
                 onChange={handleInputChange}
                 value={allInputValues.deliveryMin}
                 name='channel3Min'
                 color='warning'
                    endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.min")}</Typography>}</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
                </FormControl>
                </Box>
            </Box>
            </Box>
            )}


            <Box sx={{marginTop:"15px",width:"70%"}}>
             <Typography className='kds-settings-dinein-text'>{t("kitchen_capacity.order_pricing_rules")}</Typography>
             <Typography className='kds-settings-accommodate-label-text'>{t("kitchen_capacity.to_accommodate_large")}</Typography>
            </Box>
            <Box>
                <Button className='kds-settings-add-rules-button' variant='outlined'>{t("kitchen_capacity.add_order_price_rules")}</Button>
                <Typography className='kds-settings-add-rules-text'>{t("kitchen_capacity.add_price_and_time")}</Typography>
            </Box>
            <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"70%",marginTop:"15px"}}>
                <Typography className='kds-settings-if-order-text'>{t("kitchen_capacity.if_an_order")}</Typography>
                <FormControl sx={{ m: 1,width:"12%" }}>
                <InputLabel className='kds-settings-amt-text' htmlFor="outlined-adornment-amount">{t("kitchen_capacity.amount")}</InputLabel>
                <OutlinedInput
                 onChange={handleInputChange}
                 value={allInputValues.amount}
                 name='amount'
                color='warning'
                    id="outlined-adornment-amount"
                    startAdornment={<InputAdornment position="start">£</InputAdornment>}
                    label="Amount"
                />
                </FormControl>
                <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"60%"}}>
                <Typography className='kds-settings-if-order-text'>{t("kitchen_capacity.add")}</Typography>
                <FormControl sx={{ m: 1, width: '25%' }} variant="outlined">
                <OutlinedInput
                 onChange={handleInputChange}
                 value={allInputValues.addHr}
                 name='addHr'
                 color='warning'
                    endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.hr")}</Typography>}</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
                </FormControl>
                <FormControl sx={{ m: 1, width: '25%' }} variant="outlined">
                <OutlinedInput
                 onChange={handleInputChange}
                 value={allInputValues.addMin}
                 name='addMin'
                 color='warning'
                    endAdornment={<InputAdornment position="end">{<Typography className='kds-settings-hr-label-text'>{t("kitchen_capacity.min")}</Typography>}</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
                </FormControl>
                <Typography sx={{ml:0}} className='kds-settings-if-order-text'>{t("kitchen_capacity.to_the_order")}</Typography>
                </Box>
                

            </Box>
            
           
           
           
        

   </Box>
  )
}

export default KitchenCapacityQuotingTime
