import React, { useState,useEffect } from 'react';

import { Box, Button, IconButton,FormGroup, Paper, TextField,FormControlLabel, Typography,FormControl,Select,MenuItem,InputLabel, SelectChangeEvent, Switch, Divider, List, ListItem } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './createProduct.css'
import Checkbox from '@mui/material/Checkbox';
import imageValue1 from '../../assets/image-avatar.png';
import { CloseTwoTone} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import Tab from '@mui/material/Tab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import Rating from '@mui/material/Rating';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Chip from '@mui/material/Chip';
import axios from 'axios';

import AddIcon from '@mui/icons-material/Add';


interface Item {
  id: string;
  text: string;
}

const initialItems: Item[] = [
  { id: '1', text: 'Item 1' },
  { id: '2', text: 'Item 2' },
  { id: '3', text: 'Item 3' },
];

interface List1{
  id:string;
  value:string
}
interface ChipData {
  id: number;
  value: string;
}

interface NestedData {
  product: ChipData;
  sizeCount: ChipData;
  small: ChipData;
  medium: ChipData;
  large: ChipData;
}

const checkboxList:List1[]=[
  {id:"milk",value:"Milk"},
  {id:"fish",value:"Fish"},
  {id:"soya",value:"Soya"},
  {id:"eggs",value:"Eggs"},
  {id:"peanuts",value:"Peanuts"},
  {id:"shellfish",value:"ShellFish"},
  {id:"nuts",value:"Nuts"},
  {id:"wheat",value:"Wheat"},
]

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});




interface Row {
  id: number;
  size: string;
  price: string;
  sku: string;
  barCode: string;
  preselect: boolean;
  disabled: boolean;
  sequence?: string; // Make this property optional since it's only used in the second useState
}
interface State {
  [key: string]: string | boolean;
  categoryName: string;
  shotCode: string;
  description: string;
  showPOS: boolean;
  showOnline: boolean;
  selectedLocation: string;
  selectedCategory: string;
  selectedUnit: string;
  selectedCourse: string;
  price:string;
  sku:string;
  barCode:string;
  minQuantity:string;
  maxSlices:string;
  openPrice:boolean;
  allowRefunds:boolean;
  allowPrice:boolean;
  minDiscountAllowed:string;
  maxDiscountAllowd:string;
  aditionalPrice:string;
  nutritionValue:string;
  milk:boolean;
  fish:boolean;
  soya:boolean;
  eggs:boolean;
  peanut:boolean;
  shellfish:boolean;
  nuts:boolean;
  wheat:boolean;

}



const CreateProduct=()=>{

  const categoryOptions = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ];
  
  const initialState: State = {
    
    categoryName: '',
    shotCode: '',
    description: '',
    showPOS: false,
    showOnline: false,
    selectedLocation: '',
    selectedCategory: '',
    selectedUnit: '',
    selectedCourse: '',
    price:"",
    sku:'',
    barCode:"",
    minQuantity:"",
    maxSlices:"",
    openPrice:false,
    allowRefunds:false,
    allowPrice:false,
    minDiscountAllowed:"",
    maxDiscountAllowd:"",
    aditionalPrice:"",
    nutritionValue:"",
    milk:false,
  fish:false,
  soya:false,
  eggs:false,
  peanut:false,
  shellfish:false,
  nuts:false,
  wheat:false,

  };
  

    const [state, setState] = useState<State>(initialState);

    const handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { name, value, type, checked } = event.target;
    
      setState((prevState) => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }));
    };
    
    const handleSelectChange = (
      event: SelectChangeEvent
    ) => {
      const { name, value } = event.target;
    
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };


  const [rows, setRows] = useState<Row[]>([
    // Initialize with some initial rows if needed
    
  ]);

  const handleAddSizeClick = () => {
    const newRow: Row = {
      id: rows.length + 1,
      size: '',
      price:"",
      sku:'',
      barCode:"",
      preselect: false,
      disabled: false,
    };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (id: number) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };
  
  const handleCheckboxChange = (id: number,value:boolean) => {
    if(value===true){
      const updatedRows = rows.map((row) =>
      ( row.id !== id ) ? { ...row, disabled: true } : {...row,preselect:value}
     );
     setRows(updatedRows);
    }
    
else{
  const updatedRows = rows.map((row) =>(
    {...row,preselect:false,disabled:false}
  ))
  setRows(updatedRows);
}
    

    
  };

  const handleRowInputChange = (id: number, field: keyof Row, value: string) => {
    const updatedRows = rows.map((row) =>
      row.id !== id ? row : { ...row, [field]: value }
    );
    setRows(updatedRows);
  };
  
  


  
  

  const [expanded1, setExpanded1] = React.useState<string | false>(false);

  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded1(isExpanded ? panel : false);
    };
    const [expanded2, setExpanded2] = React.useState<string | false>(false);

    const handleChange2 =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded2(isExpanded ? panel : false);
      };
      const [expanded3, setExpanded3] = React.useState<string | false>(false);

      const handleChange3 =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
          setExpanded3(isExpanded ? panel : false);
        };
        const [expanded4, setExpanded4] = React.useState<string | false>(false);

        const handleChange4 =
          (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded4(isExpanded ? panel : false);
          };
            
    
  const [row2, setRow2] = useState<Row[]>([
  // Initialize with some initial rows if needed
  {
    id: 1,
    size: '',      // Provide default values for missing properties
    price: '',
    sku: '',
    barCode: '',
    preselect: false,
    disabled: false,
    sequence: '1st Selection',
  },
  {
    id: 2,
    size: '',     
    price: '',
    sku: '',
    barCode: '',
    preselect: false,
    disabled: false,
    sequence: '2nd Selection',
  },
  {
    id: 3,
    size: '',     
    price: '',
    sku: '',
    barCode: '',
    preselect: false,
    disabled: false,
    sequence: '3rd Selection',
  },
]);

const [nextSequence, setNextSequence] = useState<number>(4);

const addRow = () => {
  setRow2((prevRows) => [
    ...prevRows,
    {
      id: nextSequence,
      size: '',
      price: '',
      sku: '',
      barCode: '',
      preselect: false,
      disabled: false,
      sequence: `${nextSequence}th Selection`, // Use the next sequence number
    },
  ]);
  setNextSequence((prev) => prev + 1); // Increment the next sequence number
};

const handleDeleteRow2 = (id: number) => {
  const updatedRows = row2.filter((row) => row.id !== id);
  setRow2(updatedRows);
};
const handleRowInputChange2 = (id: number, field: keyof Row, value: string) => {
  const updatedRows = row2.map((row) =>
    row.id !== id ? row : { ...row, [field]: value }
  );
  setRow2(updatedRows);
};

const [value, setValue] = React.useState("1");

const handleChangetab = (event: React.SyntheticEvent, newValue: string) => {
  setValue(newValue);
};
const Kds=[{
  value:"kds1",label:"KDS1"},
  {value:"kds2",label:"KDS2"},
  {value:"kds3",label:"KDS3"},
  {
  value:"kds4",label:"KDS4"},
  {value:"kds5",label:"KDS5"},
]

const [selectedKDS, setSelectedKDS] = useState<string[]>([]);

const handleKDSChange = (event: React.ChangeEvent<{}>, newValue: { value: string; label: string; }[]) => {
  setSelectedKDS(newValue.map((option) => option.value));
};



const [items, setItems] = useState<Item[]>(initialItems);

  const onDragEnd = (result: any) => {
    if (!result.destination) return; // Item was dropped outside of the list

    const updatedItems = [...items];
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    setItems(updatedItems);
  };
  
const[nutritionList,setNutritionList]=React.useState<{value:string;id:number}[]>([])
const handleAddNutrition=(nutritionValue:string)=>{
  const newItem = {
    value:nutritionValue ,
    id: Date.now(),
  };

  setNutritionList([...nutritionList, newItem]);
  setAddNutrition(false)
}

const handleDeleteNutrition=(id:number)=>{
  const updateList=nutritionList.filter((each)=>each.id !==id )
  setNutritionList(updateList)
}

const[addNutritionValue,setAddNutrition]=React.useState<boolean>(false)

const handleaddNutritionValue=()=>{
  setAddNutrition(true)
}

const handleRemoveNutrition=()=>{
  setAddNutrition(false)
}

const [chips, setChips] = useState<string[]>([]);
  const [newChipText, setNewChipText] = useState('');

  const handleAddChip = () => {
    if (newChipText.trim() !== '') {
      setChips([...chips, newChipText]);
      setNewChipText('');
    }
  };

  const handleDeleteChip = (chipToDelete: string) => {
    setChips(chips.filter((chip) => chip !== chipToDelete));
  };

  const [chips1, setChips1] = useState<string[]>([]);
  const [newChipText1, setNewChipText1] = useState('');

  const handleAddChip1 = () => {
    if (newChipText1.trim() !== '') {
      setChips1([...chips1, newChipText1]);
      setNewChipText1('');
    }
  };

  const handleDeleteChip1 = (chipToDelete: string) => {
    setChips1(chips1.filter((chip) => chip !== chipToDelete));
  };


  const [imageValue, setImageValue] = useState<string | null>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageValue(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const [data, setData] = useState<NestedData | null>(null);
 
  useEffect(() => {
    
    axios.get<NestedData>('/producOptions.json')
    .then((response) => {
      setData(response.data);
    })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

 
  
const product: ChipData[]=[{"id":1,"value":"veg"},{"id":2,"value":"nonVeg"},{"id":3,"value":"crust"}]

const [chipData, setChipData] = React.useState< ChipData[]>(product);

const handleDelete = (chipToDelete: ChipData) => () => {
  setChipData((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
};



const [chipData1, setChipData1] = React.useState< ChipData[]>(product);

const handleDelete1 = (chipToDelete: ChipData) => () => {
  setChipData1((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
};



const [chipData2, setChipData2] = React.useState< ChipData[]>(product);

const handleDelete2 = (chipToDelete: ChipData) => () => {
  setChipData2((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
};

const [chipData3, setChipData3] = React.useState< ChipData[]>(product);

const handleDelete3 = (chipToDelete: ChipData) => () => {
  setChipData3((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
};


    return(
        <Box sx={{'background': 'var(--background-light-200, #FBFBFB)'}}>
          
            <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",padding:"1%"}}>
                <IconButton className="create-product-exit">
            <ArrowBackIcon className="create-product-exitIcon"/>
            </IconButton>
            <Typography className="create-product-head">Create a Product</Typography>
            <Button className="create-product-button" style={{textTransform:"none"}}>Save</Button>
            
            </div>
            <Paper elevation={2} style={{marginLeft:"10%",marginRight:"15%",padding:'1%',borderLeft:"2px solid var(--stroke-color-1-violet, #AE9BE2)"}}>
                <Typography className="create-product-details">Details</Typography>
                <div style={{padding:"3%",display:'flex'}}>
                    <div style={{width:"90%"}}>
                <div className="create-product-text-container">
            <TextField id="outlined-basic" label="Category Name" variant="outlined" 
           name="categoryName"
           value={state.categoryName}
           onChange={handleInputChange}
            color="warning" style={{width:'45%'}}/>
            <FormControl style={{width:"50%"}}>
  <InputLabel id="demo-simple-select-label">Select Location</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    name="selectedLocation"
    value={state.selectedLocation}
    onChange={handleSelectChange}
    label="Select Location"
   color="warning"
   
  >
     {categoryOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
  </Select>
</FormControl>
            </div>
            <div className="create-product-text-container">
            <TextField id="outlined-basic" label="Shot Code" variant="outlined" 
            name="shotCode"
            value={state.shotCode}
            onChange={handleInputChange}
            color="warning" style={{width:'45%'}}/>
            <FormControl style={{width:"50%"}}>
  <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    color="warning"
    label="Select Category"
  name='selectedCategory'
    value={state.selectedCategory}
    onChange={handleSelectChange}
   
  >
    {categoryOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
  </Select>
</FormControl> 
            </div>
            <div  className="create-product-text-container">
            <FormControl style={{width:"45%"}}>
  <InputLabel id="demo-simple-select-label">Unit</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    color="warning"
    label="Unit"
    name='selectedUnit'
   value={state.selectedUnit}
   onChange={handleSelectChange}
  >
    {categoryOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
  </Select>
</FormControl> 
            <FormControl style={{width:"50%"}}>
  <InputLabel id="demo-simple-select-label">Select Course</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    name='selectedCourse'
    label="Select Course"
    color="warning"
    value={state.selectedCourse}
    onChange={handleSelectChange}
  >
    {categoryOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
  </Select>
</FormControl>
</div> 
            </div>
            
            <Button
      style={{ width: '25%', marginLeft: '10%' }}
     
    
    >
      <div   className="create-product-img-con">
        <img
          src={imageValue || imageValue1} // You can set a placeholder image here
          alt="add Img"
          style={{ width: '90%' ,backgroundSize:"cover"}}
          onClick={() => {
            const input = document.getElementById('image-upload-input');
            if (input) {
              input.click();
            }
          }}
        />
        {
          imageValue===null ? (<Typography className="create-product-img-text">Add img</Typography>):("")
        }
        
      </div>
      <input
        type="file"
        hidden
        id="image-upload-input"
        onChange={handleImageUpload}
      />
    </Button>
            </div>
            <div style={{paddingLeft:"3%"}}>
            <TextField multiline label="Description"
             placeholder="enter a shoert description for the category" 
             value={state.description}
             name='description'
             onChange={handleInputChange}
             style={{width:"70%"}} color="warning"/>
            </div>
            <div style={{marginLeft:'3%',marginTop:"1%",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                <div style={{width:"50%"}}>
                <FormControlLabel
  control={<Checkbox color="warning"  name='showPOS' checked={state.showPOS} onChange={handleInputChange} />}
  label={<Typography className='create-product-switch-text'>Show POS</Typography>}
  style={{width:"40%"}}
/>
<FormControlLabel
  control={<Checkbox color="warning" name='showOnline' checked={state.showOnline} onChange={handleInputChange} />}
  label={<Typography className='create-product-switch-text'>Show Online</Typography>}
  style={{width:"40%"}}
/>

                
            </div>
            <div style={{display:"flex",alignItems:"center"}}>
                <Button style={{textTransform:"none",textDecoration:"underLine"}} className="create-product-cancel_Button">Cancel</Button>
                <Button style={{textTransform:"none"}} className="create-product-button">Save</Button>
            </div>
            </div>
            </Paper>
            <Paper elevation={2} style={{margin:"1% 15% 0% 10%",padding:'1%',borderLeft:"2px solid var(--stroke-color-2-tale, #9BDAE2)"}}>
           
                
                <Typography className="create-product-details" style={{width:"10%"}}>Pricing</Typography>

                <div className='create-product-base-size-con'>
                  <Typography className='create-product-base-size'>Base/Size Price</Typography>
                </div>
            <div style={{display:"flex" ,alignItems:'center',marginTop:"1%"}}>
            <TextField
        label="Price"
        style={{ width: "18%", marginRight: "1%" }}
        color="warning"
        name='price'
        value={state.price}
        onChange={handleInputChange}
      />
      <TextField
        label="SKU"
        name='sku'
        style={{ width: "18%", marginRight: "1%" }}
        color="warning"
        value={state.sku}
        onChange={handleInputChange}
      />
      <TextField
        label="BarCode"
        name='warning'
        style={{ width: "18%" }}
        color="warning"
        value={state.barCode}
        onChange={handleInputChange}
      />

             </div>
             <TableContainer >
      <Table 
      sx={{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none"
        }
      }} aria-label="simple table">
        <TableHead >
        <TableRow>
            
            <TableCell align="right" style={{padding:"0px"}}>  </TableCell>
            <TableCell align="right" style={{padding:"0px"}}>  </TableCell>
            <TableCell align="right" style={{padding:"0px"}}> </TableCell>
            <TableCell align="right" style={{padding:"0px"}}>  </TableCell>
            <TableCell align="right" style={{padding:"0px"}}>  </TableCell>
            <TableCell align="right" style={{padding:"0px"}}>  </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        
        {rows.map((row) => (
              <TableRow key={row.id}>
                
              <TableCell align="right" style={{paddingLeft:"0px"}}><TextField  label="Size"  color="warning"value={row.size}
                    onChange={(event) => handleRowInputChange(row.id, 'size', event.target.value)}/></TableCell>
              <TableCell align="right"><TextField  label="Price"   color="warning" value={row.price}
                    onChange={(event) => handleRowInputChange(row.id, 'price', event.target.value)}/></TableCell>
              <TableCell align="right"><TextField  label="SKU"  color="warning"  value={row.sku}
                    onChange={(event) => handleRowInputChange(row.id, 'sku', event.target.value)}/></TableCell>
              <TableCell align="right"><TextField  label="BarCode"  color="warning"  value={row.barCode}
                    onChange={(event) => handleRowInputChange(row.id, 'barCode', event.target.value)}/></TableCell>
              <TableCell> <FormControlLabel
  control={<Checkbox
    color="warning"
                       
    onChange={(event) => handleCheckboxChange(row.id, event.target.checked)}
                        checked={row.preselect}
                        disabled={row.disabled}
  />}
  label={<Typography className='create-product-check' style={{width:"60px"}}
  >Pre-select</Typography>}
  /></TableCell>
  <TableCell style={{padding:"0px"}}><IconButton onClick={() => handleDeleteRow(row.id)}><CloseTwoTone/></IconButton></TableCell>
            </TableRow>
       ))}
        </TableBody>
      </Table>
    </TableContainer>
    <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
             <Button style={{textTransform:"none"}} className='create-product-add-size' 
        
        onClick={handleAddSizeClick}>Add size</Button>
                <div style={{display:"flex",alignItems:"center"}}>
                <Button style={{textTransform:"none",textDecoration:"underLine"}} className="create-product-cancel_Button">Clear</Button>
                <Button style={{textTransform:"none"}} className="create-product-button">Save</Button>
                </div>
            </div>
                </Paper>
                <Paper elevation={2} style={{ margin: '1% 15% 0% 10%', padding: '1%', borderLeft: '2px solid var(--stroke-color-3-green, #BAE29B)' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: '1%' }}>
        <TextField
          label="Minimum Quantity"
          color="warning"
          name='minQuantity'
          value={state.minQuantity}
          onChange={handleInputChange}
        />
        <TextField
          label="Max No Of Slices"
          color="warning"
          style={{ marginLeft: '1%' }}
          name='maxSlices'
          value={state.maxSlices}
          onChange={handleInputChange}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: '1%' }}>
        <FormControl style={{ marginLeft: '0px' }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={state.openPrice}

                  onChange={handleInputChange}
                  name="openPrice"
                  color="warning"
                />
              }
              label={<Typography className="create-product-switch-text">Open Price</Typography>}
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>
        <FormControl style={{ marginLeft: '10%' }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={state.allowRefunds}
                  onChange={handleInputChange}
                  name="allowRefunds"
                  color="warning"
                />
              }
              label={<Typography className="create-product-switch-text">Allow Refunds</Typography>}
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>
      </Box>
      <Box sx={{ marginTop: '1%', display: 'flex', alignItems: 'center' }}>
        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={state.allowPrice}
                  onChange={handleInputChange}
                  name="allowPrice"
                  color="warning"
                />
              }
              label={<Typography className="create-product-switch-text">Allow Discounts</Typography>}
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>
        {state.allowPrice && (
          <>
        <TextField
          label="Min Discount Allowed"
          color="warning"
          name='minDiscountAllowed'
          style={{ marginLeft: '5%' }}
          value={state.minDiscountAllowed}
          onChange={handleInputChange}
        />
        <TextField
          label="Max Discount Allowed"
          color="warning"
          style={{ marginLeft: '1%' }}
          name='maxDiscountAllowd'
          value={state.maxDiscountAllowd}
          onChange={handleInputChange}
        />
        </>
        )}
      </Box>
    </Paper>
    <Paper elevation={2} style={{ margin: '1% 15% 0% 10%', padding: '1%', borderLeft: '2px solid var(--stroke-color-4-brown, #E2A39B)' }}>
    <Typography className="create-product-details">Option Groups</Typography>
    <Box className='create-product-option-group-con' >
      <Box sx={{display:"flex",flexDirection:"column"}}>
<Typography className='create-product-base-size'>Product Name</Typography>
<Paper elevation={0}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {chipData.map((data) => {
        let icon;

        

        return (
          <ListItem key={data.id}>
            <Chip
              icon={icon}
              label={data.value}
              onDelete={ handleDelete(data)}
            />
          </ListItem>
        );
      })}
    </Paper>
    </Box>
    <Box sx={{display:"flex",alignItems:"center",marginTop:'1%'}}>
    <Typography className='create-product-base-size'>Small</Typography>
    <Paper elevation={0}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {chipData1.map((data) => {
        let icon;

        

        return (
          <ListItem key={data.id}>
            <Chip
              icon={icon}
              label={data.value}
              onDelete={ handleDelete1(data)}
            />
          </ListItem>
        );
      })}
    </Paper>
    </Box>
    <Divider sx={{color:"#D9D9D9"}}/>
    <Box sx={{display:"flex",alignItems:"center",marginTop:'1%'}}>
    <Typography className='create-product-base-size'>medium</Typography>
    <Paper elevation={0}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {chipData2.map((data) => {
        let icon;

        

        return (
          <ListItem key={data.id}>
            <Chip
              icon={icon}
              label={data.value}
              onDelete={ handleDelete2(data)}
            />
          </ListItem>
        );
      })}
    </Paper>
    </Box>
    <Divider sx={{color:"#D9D9D9"}}/>
    <Box sx={{display:"flex",alignItems:"center",marginTop:'1%'}}>
    <Typography className='create-product-base-size'>Large</Typography>
    <Paper elevation={0}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {chipData3.map((data) => {
        let icon;

        

        return (
          <ListItem key={data.id}>
            <Chip
              icon={icon}
              label={data.value}
              onDelete={ handleDelete3(data)}
            />
          </ListItem>
        );
      })}
    </Paper>
    </Box>
    </Box>
    </Paper>
    <Paper elevation={2} style={{ margin: '1% 15% 0% 10%', padding: '1%', borderLeft: '2px solid var(--stroke-color-5-magenta, #E19BE2)' }}>
      <Box>
      <Accordion expanded={expanded1 === 'panel1'}elevation={0} onChange={handleChange1('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{display:"flex",alignItems:"center"}}
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }} className='create-product-details'>
          Sequence Pricing
          </Typography>
          <FormControl style={{ marginLeft: '0px' }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                 
                  name="Size "
                  color="warning"
                />
              }
              label={<Typography className="create-product-switch-text">Size </Typography>}
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>
        </AccordionSummary>
        <AccordionDetails>
         <TableContainer >
      <Table aria-label="simple table">
        <TableHead >
          
        <TableRow>
            
            <TableCell style={{paddingTop:"0px"}}>Sequence</TableCell>
            <TableCell   style={{paddingTop:"0px"}}>  Price</TableCell>
            <TableCell  style={{paddingTop:"0px"}}> </TableCell>
            
          </TableRow>
          
        </TableHead>
        <TableBody>
        
        {row2.map((each)=>
              <TableRow key={each.id}>
                
              <TableCell  style={{padding:"5px"}} className='create-product-base-size'>
              <TextField
  value={each.sequence}
  color="warning"
  variant='standard'
  InputProps={{
    disableUnderline: true
  }}
/>
</TableCell>
              <TableCell style={{padding:"5px"}} className='create-product-base-size'><TextField  label="Price" value={each.price}  onChange={(event) => handleRowInputChange2(each.id, 'price', event.target.value)}color="warning" /></TableCell> 
            <TableCell style={{padding:"0px"}} ><IconButton onClick={() => handleDeleteRow2(each.id)}><CloseTwoTone/></IconButton></TableCell>
            </TableRow>
      )}
     
      
        </TableBody>
      </Table>
    </TableContainer>
    <Button className='create-product-button-text' onClick={addRow}>+Add level</Button>
    <Divider/>
    <Box sx={{display:'flex',alignItems:'center',marginTop:"1%"}}>
      <Typography className='create-product-check'>All Additional Selections</Typography>
      <TextField
 
  color="warning"
  style={{marginLeft:"22%"}}
  label="price"
  value={state.aditionalPrice}
  onChange={handleInputChange}

/>

    </Box>
    <Divider style={{marginTop:"1%"}}/>
        </AccordionDetails>
      </Accordion>
      </Box>
    </Paper>

<Paper elevation={2} style={{ margin: '1% 15% 0% 10%', padding: '1%', borderLeft: '2px solid var(--stroke-color-1-violet, #AE9BE2)',backgroundColor: '#FCFCFC' }}>
<Typography className="create-product-details">Product Settings</Typography>
<Box  sx={{display:"flex",alignItems:"center", marginTop:"1%",justifyContent:"space-between"}} >
<Box sx={{display:"flex",alignItems:"center",width:"40%",padding:"1%"}} className="create-product-rating-con">
  <Typography className='create-product-switch-text'>Spice Indicator</Typography>
<Box
      sx={{
        marginLeft:"20%",
       
        '& > legend': { mt: 2 },
      }}
    >
      
      <StyledRating
        name="customized-color"
        defaultValue={0}
        
        
        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 46 46" fill="none">
        <g clip-path="url(#clip0_5377_73402)">
          <path d="M28.0846 10.8892L26.8577 13.6434L31.6007 26.2847L29.5607 37.0185C29.5607 37.0185 34.3213 32.9863 35.4173 27.2481C36.5133 21.5099 35.2175 16.7635 33.3278 13.7424C31.4363 10.723 30.441 10.2581 30.441 10.2581L28.0846 10.8892Z" fill="#DC0D28"/>
          <path d="M20.9834 14.8049C20.9834 14.8049 19.8891 16.2315 21.4465 19.1183C23.0039 22.0051 25.1288 23.3981 25.5601 26.8169C25.9915 30.2358 25.3621 32.3253 22.509 34.2486C18.611 36.8773 16.4332 34.2787 15.3089 35.1449C14.4462 35.8096 16.4367 38.7299 19.555 39.5254C22.6734 40.3209 26.4564 39.5926 29.1434 37.3687C31.8304 35.1449 34.7172 29.4721 33.4232 23.3981C32.1292 17.324 29.8081 14.4054 28.2171 12.6801C26.6261 10.9548 23.4706 12.3813 23.4706 12.3813L20.9834 14.8049Z" fill="#FF5117"/>
          <path d="M24.0452 16.604C23.0747 16.7295 23.0252 18.073 23.7712 19.367C24.5172 20.661 25.3639 21.5572 25.7122 22.2025C26.0604 22.8477 26.3592 23.9455 27.1812 23.721C28.0032 23.4965 27.5542 22.0045 27.0079 21.0587C26.4617 20.113 25.6132 18.918 25.3904 18.1472C25.1677 17.3765 24.8088 16.505 24.0452 16.604Z" fill="#FFCB88"/>
          <path d="M27.4534 24.725C26.6898 25.0892 27.0557 25.6884 27.1052 25.9359C27.1547 26.1834 27.3368 26.7332 27.853 26.6165C28.4275 26.4875 28.4505 25.9518 28.3656 25.5382C28.2825 25.1228 27.8883 24.5164 27.4534 24.725Z" fill="#FFCB88"/>
          <path d="M21.8619 14.7887C22.6751 14.7711 22.4912 15.7504 23.0233 15.685C23.6491 15.6072 24.084 14.0587 25.1641 13.5124C26.2442 12.9662 27.1723 13.9614 27.8352 13.4293C28.4999 12.899 27.9165 12.351 28.8464 11.6545C29.7762 10.958 31.2187 11.6368 31.5016 11.1242C31.7844 10.6115 30.2571 9.5155 28.3832 9.565C26.8895 9.60565 26.2265 9.96451 25.827 9.66399C25.4293 9.36524 24.1848 7.39241 23.5042 6.47848C22.8254 5.56631 21.6958 4.50388 20.2038 5.00239C18.71 5.49913 18.3282 6.81082 18.4448 7.25983C18.5845 7.80253 19.136 8.20912 19.6575 7.04416C20.1384 5.96582 21.3829 6.14967 21.9804 6.94517C22.5779 7.74066 23.041 9.26801 23.1259 9.98219C23.2107 10.6964 23.2761 11.0941 23.0923 11.377C22.9102 11.658 22.014 12.2891 21.3334 13.0369C20.522 13.9261 20.057 15.2431 20.5202 15.4764C20.9833 15.7098 21.2273 14.8011 21.8619 14.7887Z" fill="#98B71E"/>
        </g>
        <defs>
          <clipPath id="clip0_5377_73402">
            <rect width="32" height="32" fill="white" transform="translate(0 23) rotate(-45)"/>
          </clipPath>
        </defs>
      </svg>}
        emptyIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 46 46" fill="none">
        <g clip-path="url(#clip0_5377_73402)">
          <path d="M28.0846 10.8892L26.8577 13.6434L31.6007 26.2847L29.5607 37.0185C29.5607 37.0185 34.3213 32.9863 35.4173 27.2481C36.5133 21.5099 35.2175 16.7635 33.3278 13.7424C31.4363 10.723 30.441 10.2581 30.441 10.2581L28.0846 10.8892Z" fill="#707070"/>
          <path d="M20.9834 14.8049C20.9834 14.8049 19.8891 16.2315 21.4465 19.1183C23.0039 22.0051 25.1288 23.3981 25.5601 26.8169C25.9915 30.2358 25.3621 32.3253 22.509 34.2486C18.611 36.8773 16.4332 34.2787 15.3089 35.1449C14.4462 35.8096 16.4367 38.7299 19.555 39.5254C22.6734 40.3209 26.4564 39.5926 29.1434 37.3687C31.8304 35.1449 34.7172 29.4721 33.4232 23.3981C32.1292 17.324 29.8081 14.4054 28.2171 12.6801C26.6261 10.9548 23.4706 12.3813 23.4706 12.3813L20.9834 14.8049Z" fill="#707070"/>
          <path d="M24.0452 16.604C23.0747 16.7295 23.0252 18.073 23.7712 19.367C24.5172 20.661 25.3639 21.5572 25.7122 22.2025C26.0604 22.8477 26.3592 23.9455 27.1812 23.721C28.0032 23.4965 27.5542 22.0045 27.0079 21.0587C26.4617 20.113 25.6132 18.918 25.3904 18.1472C25.1677 17.3765 24.8088 16.505 24.0452 16.604Z" fill="#707070"/>
          <path d="M27.4534 24.725C26.6898 25.0892 27.0557 25.6884 27.1052 25.9359C27.1547 26.1834 27.3368 26.7332 27.853 26.6165C28.4275 26.4875 28.4505 25.9518 28.3656 25.5382C28.2825 25.1228 27.8883 24.5164 27.4534 24.725Z" fill="#707070"/>
          <path d="M21.8619 14.7887C22.6751 14.7711 22.4912 15.7504 23.0233 15.685C23.6491 15.6072 24.084 14.0587 25.1641 13.5124C26.2442 12.9662 27.1723 13.9614 27.8352 13.4293C28.4999 12.899 27.9165 12.351 28.8464 11.6545C29.7762 10.958 31.2187 11.6368 31.5016 11.1242C31.7844 10.6115 30.2571 9.5155 28.3832 9.565C26.8895 9.60565 26.2265 9.96451 25.827 9.66399C25.4293 9.36524 24.1848 7.39241 23.5042 6.47848C22.8254 5.56631 21.6958 4.50388 20.2038 5.00239C18.71 5.49913 18.3282 6.81082 18.4448 7.25983C18.5845 7.80253 19.136 8.20912 19.6575 7.04416C20.1384 5.96582 21.3829 6.14967 21.9804 6.94517C22.5779 7.74066 23.041 9.26801 23.1259 9.98219C23.2107 10.6964 23.2761 11.0941 23.0923 11.377C22.9102 11.658 22.014 12.2891 21.3334 13.0369C20.522 13.9261 20.057 15.2431 20.5202 15.4764C20.9833 15.7098 21.2273 14.8011 21.8619 14.7887Z" fill="#707070"/>
        </g>
        <defs>
          <clipPath id="clip0_5377_73402">
            <rect width="32" height="32" fill="white" transform="translate(0 23) rotate(-45)"/>
          </clipPath>
        </defs>
      </svg>}
      />
      
    </Box>
    
    </Box>
    <FormControl>
 
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="female" control={<Radio  color="success"/>} label={<Typography className='create-product-switch-text'>Veg</Typography>} />
        <FormControlLabel value="male" control={<Radio  color="error" />} label={<Typography className='create-product-switch-text'>Non veg</Typography>} />
        <FormControlLabel value="other" control={<Radio  color="warning"/>} label={<Typography className='create-product-switch-text'>Vegon</Typography>} />
        
      </RadioGroup>
    </FormControl>
    </Box>
    <Accordion expanded={expanded2 === 'panel2'}elevation={0} onChange={handleChange2('panel2')} style={{marginTop:"2%"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{display:"flex",alignItems:"center"}}
        >
         <Typography>Select</Typography>
         
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{ width: '100%',marginTop:'20px'}}className="create-product-border_style">
        <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <TabContext value={value}>
        <TabList value={value} onChange={handleChangetab} aria-label="basic tabs example">
        <Tab
      label="Order Type"
      value="1"
      className={value === "1" ? 'create-product-change_color' : 'create-product-add_color'}
    />
    <Tab
      label="Channel"
      value="2"
      className={value === "2" ? 'create-product-change_color' : 'create-product-add_color'}
    />
    
        </TabList>
        </TabContext>
      </Box>
      <TabPanel value="1" >
        <Box>
          <Typography className='create-product-preparation'>Preparation Time</Typography>
          <Box sx={{display:"flex",alignItems:"center",marginTop:"2%"}}>
          <Box sx={{display:'flex',alignItems:'center',marginRight:"40%"}}>
            <Typography className='create-product-preparation'>Dine In</Typography>
            <TextField label="Time" color='warning' style={{marginLeft:"10%",width:"44%",marginRight:"1%"}}/>
            <Typography>min</Typography>
          </Box>
          <Box sx={{display:'flex',alignItems:'center'}}>
            <Typography className='create-product-preparation'>Collection</Typography>
            <TextField label="Time" color='warning' style={{marginLeft:"10%",width:"44%",marginRight:"1%"}}/>
            <Typography>min</Typography>
          </Box>
          </Box>

          <Box sx={{display:"flex",alignItems:"center",marginTop:"2%"}}>
          <Box sx={{display:'flex',alignItems:'center',marginRight:"40%"}}>
            <Typography className='create-product-preparation'>Shop</Typography>
            <TextField label="Time" color='warning' style={{marginLeft:"15%",width:"44%",marginRight:"1%"}}/>
            <Typography>min</Typography>
          </Box>
          <Box sx={{display:'flex',alignItems:'center'}}>
            <Typography className='create-product-preparation'>Delivery</Typography>
            <TextField label="Time" color='warning' style={{marginLeft:"17%",width:"44%",marginRight:"1%"}}/>
            <Typography>min</Typography>
          </Box>
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value="2" >
        <Box>
        <Box sx={{display:"flex",alignItems:"center",marginTop:"2%"}}>
          <Box sx={{display:'flex',alignItems:'center',marginRight:"40%"}}>
            <Typography className='create-product-preparation'>Channel 1</Typography>
            <TextField label="Time" color='warning' style={{marginLeft:"10%",width:"44%",marginRight:"1%"}}/>
            <Typography>min</Typography>
          </Box>
          <Box sx={{display:'flex',alignItems:'center'}}>
            <Typography className='create-product-preparation'>Channel 2</Typography>
            <TextField label="Time" color='warning' style={{marginLeft:"10%",width:"44%",marginRight:"1%"}}/>
            <Typography>min</Typography>
          </Box>
          </Box>

          <Box sx={{display:"flex",alignItems:"center",marginTop:"2%"}}>
          <Box sx={{display:'flex',alignItems:'center',marginRight:"40%"}}>
            <Typography className='create-product-preparation'>Channel 3</Typography>
            <TextField label="Time" color='warning' style={{marginLeft:"10%",width:"44%",marginRight:"1%"}}/>
            <Typography>min</Typography>
          </Box>
          <Box sx={{display:'flex',alignItems:'center'}}>
            <Typography className='create-product-preparation'>Channel 4</Typography>
            <TextField label="Time" color='warning' style={{marginLeft:"10%",width:"44%",marginRight:"1%"}}/>
            <Typography>min</Typography>
          </Box>
          </Box>
        </Box>
      </TabPanel>
      </TabContext>
    </Box>
    
        </AccordionDetails>
      </Accordion>
      <Box>
      {/* <Autocomplete
      multiple={true}
      id="tags-outlined"
      options={Kds}
      getOptionLabel={(option:any) => option.label}
      filterSelectedOptions
      value={Kds.filter((option) => selectedKDS.includes(option.value))}
      onChange={handleKDSChange}
      renderInput={(params:any) => (
        <TextField
          {...params}
          label="Select KDS station"
          placeholder="Select KDS station"
          color='warning'
        />
      )}
      style={{ marginTop: "2%", background: "#FFF" }}
    /> */}
    


      </Box>

      {selectedKDS.length>1 &&(
      <Box sx={{mt:"2%",background: "#FFF"}}>
        <Typography className='create-product-switch-text'>Set KDS Sequence</Typography>
        <div>
      <h2>Drag and Drop List</h2>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="itemList">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ listStyle: 'none', padding: 0 }}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        border: '1px solid #ccc',
                        margin: '4px',
                        padding: '8px',
                        background: 'white',
                        ...provided.draggableProps.style,
                      }}
                    >
                      {item.text}
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
        </Box>
    )}
    <Box sx={{display:'flex'}}>
    <Box  sx={{width:"45%"}}>
    <Accordion expanded={expanded3 === 'panel3'}elevation={0} onChange={handleChange3('panel3')} style={{marginTop:"2%",width:"95%"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{display:"flex",alignItems:"center"}}
        >
         <Typography className='create-product-switch-text'>Nutritional Values</Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
        <FormControlLabel control={<Checkbox color='warning'/>} label={<Typography className='create-product-check'>Low Calorie</Typography>} />
        <FormControlLabel control={<Checkbox color='warning'/>} label={<Typography className='create-product-check'>Low Carbohydrates</Typography>} />
        
        </Box>
        <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}></Box>
        <FormControl>
    
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
        <FormControlLabel value="female" control={<Radio color='warning'/>} label={<Typography className='create-product-check'>Per Serving</Typography>} />
        <FormControlLabel value="male" control={<Radio color='warning'/>} label={<Typography className='create-product-check'>Per 100g</Typography>}/>
        </Box>
        </RadioGroup>
        </FormControl>
        <TextField label="Total Calories(kcal)" fullWidth color='warning' style={{marginTop:"2%"}}/>
        <TextField label="Carbohydrates(g)" fullWidth color='warning' style={{marginTop:"2%"}}/>
        <TextField label="Total Fat(g)" fullWidth color='warning' style={{marginTop:"2%"}}/>
        <TextField label="Saturated Fat Fat(g)" fullWidth color='warning' style={{marginTop:"2%"}}/>
        <TextField label="Protein(g)" fullWidth color='warning' style={{marginTop:"2%"}}/>
        <TextField label="Added Sugar(g)" fullWidth color='warning' style={{marginTop:"2%"}}/>
        <TextField label="Salt(g)" fullWidth color='warning' style={{marginTop:"2%"}}/>
        <TextField label="Fiber(g)" fullWidth color='warning' style={{marginTop:"2%"}}/>
         
        <List style={{width:"100%",paddingLeft:"0px"}}>
        {nutritionList.map((each)=>(
        <ListItem key={each.id} style={{width:"100%",paddingLeft:"0px"}}>
          <TextField color='warning' style={{marginTop:"1%",width:"80%",paddingLeft:"0px"}} label={each.value}/>
          <DeleteIcon sx={{color:"#ABABAB",marginLeft:"20%"}} onClick={()=>handleDeleteNutrition(each.id)}/>
          </ListItem>
        ))}
         </List>
         {addNutritionValue &&(
        <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>

        <TextField label="enter nutritional value" name='nutritionValue' color='warning' style={{marginTop:"2%",width:"7 0%"}} onChange={handleInputChange}/>
        <Button onClick={handleRemoveNutrition} className='create-product-button-cancel'>Cancel</Button>
        <Button onClick={()=>{handleAddNutrition(state.nutritionValue)}} className='create-product-button-add'>Add</Button>
        </Box>
        )}
     
   
    <Button onClick={handleaddNutritionValue} className='create-product-addValue-button'>+Add value </Button>
        </AccordionDetails>
      </Accordion>
    </Box>
    <Box sx={{width:"45%"}}>
    <Accordion expanded={expanded4 === 'panel4'}elevation={0} onChange={handleChange4('panel4')} style={{marginTop:"2%",width:"95%"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{display:"flex",alignItems:"center"}}
        >
         <Typography className='create-product-switch-text'>Allergens</Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{display:"flex",alignItems:"center",flexWrap:"wrap"}}>
          {checkboxList.map((each)=>(
            <ListItem key={each.id} sx={{width:"45%"}}>
            <FormGroup>
              <FormControlLabel
                name={each.id}
                control={
                  <Checkbox
                    color='warning'
                    checked={state[each.id] as boolean}
                    onChange={handleInputChange}
                  />
                }
                label={<Typography className='create-product-switch-text'>{each.value}</Typography>}
              />
            </FormGroup>
          </ListItem>
          ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
    </Box>
    <Box sx={{display:"flex",alignItems:"center",marginLeft:"80%",marginTop:"2%"}}>
                <Button style={{textTransform:"none",textDecoration:"underLine"}} className="create-product-cancel_Button">Cancel</Button>
                <Button style={{textTransform:"none"}} className="create-product-button">Save</Button>
            </Box>
</Paper>
<Paper elevation={2} style={{ margin: '1% 15% 0% 10%', padding: '1%', borderLeft: '2px solid var(--stroke-color-3-green, #BAE29B)',backgroundColor: '#FCFCFC' }}>
<Typography className="create-product-details">Additional Settings</Typography>
<div style={{display:"flex",marginTop:"2%",width:"70%"}} className='create-product-chip'>
      <div>
        {chips.map((chip) => (
          <Chip
            key={chip}
            label={chip}
            onDelete={() => handleDeleteChip(chip)}
    
          />
        ))}
      </div>
      <div style={{marginLeft:"auto"}}>
        <TextField
          label="SEO Keyword"
          variant="standard"
          value={newChipText}
          onChange={(e) => setNewChipText(e.target.value)}
        />
        <IconButton onClick={handleAddChip} >
          <AddIcon />
        </IconButton>
      </div>
    </div>
    <TextField multiline label="Meta tag description"
             placeholder="Meta tag description" 
            
             
             style={{width:"70%",marginTop:'2%'}} color="warning"/>
             <div style={{display:"flex",marginTop:"2%",width:"70%"}} className='create-product-chip'>
      <div>
        {chips1.map((chip) => (
          <Chip
            key={chip}
            label={chip}
            onDelete={() => handleDeleteChip1(chip)}
    
          />
        ))}
      </div>
      <div style={{marginLeft:"auto"}}>
        <TextField
          label="Meta tag key words"
          variant="standard"
          value={newChipText1}
          onChange={(e) => setNewChipText1(e.target.value)}
        />
        <IconButton onClick={handleAddChip1} >
          <AddIcon />
        </IconButton>
      </div>
    </div>

  </Paper>
  
        </Box>
    )
}
export default CreateProduct