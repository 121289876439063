import { useEffect, useCallback, useContext } from "react";
import UseLocationData from "../../context/UseLocationData";

const LocationComponent = () => {
  const { handlePositionUpdate } = useContext(UseLocationData);
  const locationKey = process.env.REACT_APP_GOOGLE_MAPS_API;
  const onSuccess = useCallback(
    async (position: { coords: { latitude: any; longitude: any } }) => {
      const { latitude, longitude } = position.coords;
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${locationKey}`
        );
        if (response.ok) {
          const data = await response.json();
          const getAddressComponent = (type: string) => {
            const component = data.results[0].address_components.find(
              (c: { types: string | any[] }) => c.types.includes(type)
            );
            return component ? component.long_name : "";
          };

          const address1 = getAddressComponent("route");
          const address2 = getAddressComponent("sublocality_level_1");
          const city = getAddressComponent("locality");
          const street = getAddressComponent("street_number");
          const state = getAddressComponent("administrative_area_level_1");
          const pincode = getAddressComponent("postal_code");
          handlePositionUpdate({
            latitude,
            longitude,
            addressLine1: address1,
            addressLine2: address2,
            street: street,
            city: city,
            state: state,
            postCode: pincode,
          });
        } else {
          throw new Error("Failed to fetch address");
        }
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handlePositionUpdate]
  );

  useEffect(() => {
    
    let isMounted = true;


    const onError = (error: any) => {
      console.error(error);
    };


    // Request current position
    navigator.geolocation.getCurrentPosition((position) => {
      if (isMounted) {
        onSuccess(position);
      }
    }, onError);

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default LocationComponent;
