import { Box, Button, Typography,IconButton } from '@mui/material'
import React, { useState } from 'react'
import image2 from "../../assets/theme2Small.png"
import image4 from "../../assets/theme4Small.png"
import image3 from "../../assets/theme3Small.png"
import image1 from "../../assets/theme1Small.png"
import "./layoutOrTheme.css"
import { Visibility } from '@mui/icons-material'
import PosPopupTheme1 from './posPopupTheme1'
import PosPopupTheme2 from './posPopupTheme2'
import PosPopupTheme3 from './posPopupTheme3'
import PosPopupTheme4 from './posPopupTheme4'
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

const popupStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  bgcolor: 'background.paper',
  borderRadius:"8px",
  p: 4,
};

const savePopupStyle= {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30%",
  bgcolor: 'background.paper',
  borderRadius:"8px",
  p: 4,
};

export default function LayoutOrTheme() {
    const [previewTextStateTheme1,setPreviewTextStateTheme1]=useState(false)
    const [imageHoverStateTheme1,setImageHoverStateTheme1]=useState(false)

    const [previewTextStateTheme2,setPreviewTextStateTheme2]=useState(false)
    const [imageHoverStateTheme2,setImageHoverStateTheme2]=useState(false)

    const [previewTextStateTheme3,setPreviewTextStateTheme3]=useState(false)
    const [imageHoverStateTheme3,setImageHoverStateTheme3]=useState(false)

    const [previewTextStateTheme4,setPreviewTextStateTheme4]=useState(false)
    const [imageHoverStateTheme4,setImageHoverStateTheme4]=useState(false)

    //theme 1
    const handleOnMouseTheme1Enter=()=>{
         setPreviewTextStateTheme1(true)      
    }
    const handleOnMouseTheme1Leave=()=>{
        setPreviewTextStateTheme1(false)      
   }

    const handleMouseEventOnImageTheme1Enter=()=>{
        setImageHoverStateTheme1(true)
    }
    const handleMouseEventOnImageTheme1Leave=()=>{
        setImageHoverStateTheme1(false)
    }

    //theme 2

    const handleOnMouseTheme2Enter=()=>{
        setPreviewTextStateTheme2(true)      
   }
   const handleOnMouseTheme2Leave=()=>{
       setPreviewTextStateTheme2(false)      
  }

   const handleMouseEventOnImageTheme2Enter=()=>{
       setImageHoverStateTheme2(true)
   }
   const handleMouseEventOnImageTheme2Leave=()=>{
       setImageHoverStateTheme2(false)
   }

     //theme 3

     const handleOnMouseTheme3Enter=()=>{
        setPreviewTextStateTheme3(true)      
   }
   const handleOnMouseTheme3Leave=()=>{
       setPreviewTextStateTheme3(false)      
  }

   const handleMouseEventOnImageTheme3Enter=()=>{
       setImageHoverStateTheme3(true)
   }
   const handleMouseEventOnImageTheme3Leave=()=>{
       setImageHoverStateTheme3(false)
   }

      //theme 4

      const handleOnMouseTheme4Enter=()=>{
        setPreviewTextStateTheme4(true)      
   }
   const handleOnMouseTheme4Leave=()=>{
       setPreviewTextStateTheme4(false)      
  }

   const handleMouseEventOnImageTheme4Enter=()=>{
       setImageHoverStateTheme4(true)
   }
   const handleMouseEventOnImageTheme4Leave=()=>{
       setImageHoverStateTheme4(false)
   }


   //popupTheme1Functions
   const [popUpTheme1Open, setPopupTheme1Open] = React.useState(false);
   const handlePopupTheme1Open = () => setPopupTheme1Open(true);
   const handlePopupTheme1Close = () => setPopupTheme1Open(false);


   //popupTheme2Functions
   const [popUpTheme2Open, setPopupTheme2Open] = React.useState(false);
   const handlePopupTheme2Open = () => setPopupTheme2Open(true);
   const handlePopupTheme2Close = () => setPopupTheme2Open(false);


   //popupTheme3Functions
   const [popUpTheme3Open, setPopupTheme3Open] = React.useState(false);
   const handlePopupTheme3Open = () => setPopupTheme3Open(true);
   const handlePopupTheme3Close = () => setPopupTheme3Open(false);


   //popupTheme4Functions
   const [popUpTheme4Open, setPopupTheme4Open] = React.useState(false);
   const handlePopupTheme4Open = () => setPopupTheme4Open(true);
   const handlePopupTheme4Close = () => setPopupTheme4Open(false);


   //Save popUp functions
   const [savePopUpOpen, setSavePopupOpen] = React.useState(false);
   const handleSavePopupOpen = () => setSavePopupOpen(true);
   const handleSavePopupClose = () => setSavePopupOpen(false);

   const handleDoneButton=()=>{
    setSavePopupOpen(false)
   }


   //Theme in Use settings
   const [theme1InUseState,setTheme1InUseState]=useState(true)
   const [theme2InUseState,setTheme2InUseState]=useState(false)
   const [theme3InUseState,setTheme3InUseState]=useState(false)
   const [theme4InUseState,setTheme4InUseState]=useState(false)
   

   const handleTheme1State=()=>{
    setTheme1InUseState(true)
    setTheme2InUseState(false)
    setTheme3InUseState(false)
    setTheme4InUseState(false)

   }
   const handleTheme2State=()=>{
    setTheme1InUseState(false)
    setTheme2InUseState(true)
    setTheme3InUseState(false)
    setTheme4InUseState(false)
   }

   const handleTheme3State=()=>{
    setTheme1InUseState(false)
    setTheme2InUseState(false)
    setTheme3InUseState(true)
    setTheme4InUseState(false)
   

   }
   const handleTheme4State=()=>{
    setTheme1InUseState(false)
    setTheme2InUseState(false)
    setTheme3InUseState(false)
    setTheme4InUseState(true)

   }





    
  return (
    <Box>
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Box>
                <Typography className='layout-or-theme-manage-layout-text'>Menu layout</Typography>
                <Typography className='layout-or-theme-select-theme-text'>Select a theme that best suits your business type</Typography>
            </Box>
            <Box sx={{display:"flex",justifyContent:"space-between",width:"28%",alignItems:"center"}}>
                <Button className='layout-or-theme-request-button' variant='outlined'>Request for Customised Theme</Button>
                <Button onClick={handleSavePopupOpen} className='layout-or-theme-save-button' variant='contained'>Save</Button>
                <Modal
                  open={savePopUpOpen}
                  onClose={handleSavePopupClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={savePopupStyle}>
                    <Box>
                    <IconButton onClick={handleSavePopupClose} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                        <CloseIcon/>
                    </IconButton>
                    </Box>
                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",margin:"15px"}}>
                      <Typography className='layout-or-image-saved-theme-text'>The saved theme would be updated in the POS when the user restarts the POS device</Typography>
                    </Box>
                    <Box sx={{textAlign:"right",marginTop:"25px"}}>
                      <Button onClick={handleDoneButton} className='layout-or-image-done-button' variant='contained'>Done</Button>
                    </Box>
                  </Box>
                </Modal>

            </Box>     
        </Box>


      <Box sx={{display:"flex",flexWrap:"wrap"}}>   

            <Box  sx={{width:"40%"}}>
                <Box sx={{margin:"15px",padding:"10px",width:"70%"}}>
                <Typography className='layout-or-theme-theme1-text'>Theme 1</Typography>
                <Box sx={{position:"relative"}}>
                 <img width="100%" onMouseEnter={handleMouseEventOnImageTheme1Enter} onMouseLeave={handleMouseEventOnImageTheme1Leave} src={image1} alt="Theme 1"/>
                 <Box
                  style={{
                  position: 'absolute',
                  top: '15%',
                  left: `${previewTextStateTheme1?"80%":"92%"}`,
                  transform: 'translate(-50%, -50%)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '8px',
                
                }}
          >
            <Box className={previewTextStateTheme1? "width-of-preview-text":""} sx={{display:"flex",alignItems:"center"}}>   
            {
                !previewTextStateTheme1 && (
                    <IconButton onMouseEnter={handleOnMouseTheme1Enter} className='layout-or-theme-vivibility-icon'>
                    <Visibility />
                    </IconButton>
                )
            }
            {previewTextStateTheme1 &&(
                <IconButton onClick={handlePopupTheme1Open} onMouseLeave={handleOnMouseTheme1Leave} className={previewTextStateTheme1?"layout-or-theme-preview-text":'layout-or-theme-vivibility-icon'}>
                <Visibility />
                {previewTextStateTheme1 && <Typography className='layout-or-theme-preview-text'>Preview</Typography>}
              </IconButton>

            )}
            
            </Box>   
            </Box>
              <Box>
                 {
              theme1InUseState?(
                <Box
                style={{
                position: 'absolute',
                top: '88%',
                left: "23.5%",
                transform: 'translate(-50%, -50%)', 
                }}
          >   
                  <Box className="bestSellerRgt" sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <Typography className='layout-or-image-theme-in-use-text'>Theme in Use</Typography>
                  </Box>
                
                 </Box>
             
              ):(
                <Box>
                   {
                    imageHoverStateTheme1 &&(
                        <Box
                style={{
                position: 'absolute',
                top: '78%',
                left: "50%",
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',      
                padding: '8px',
                
                }}
          >
              <Box className="layout-or-image-hover-background">
               <Button onClick={()=>setPopupTheme1Open(true)} className='layout-or-image-hover-button' variant='outlined'>Use this Theme</Button>
             </Box>
          
            
                 </Box>
                    )
                 }

                </Box>
              
              )
            }  
                </Box>
                
                </Box>
                <Typography className='layout-or-theme-recommended-text'>Recommended for a small business type such as a cafe, dessert shop..</Typography>            
                </Box>
            </Box>


            <Box  sx={{width:"40%"}}>
                <Box sx={{margin:"15px",padding:"10px",width:"70%"}}>
                <Typography className='layout-or-theme-theme1-text'>Theme 2</Typography>
                <Box sx={{position:"relative"}}>
                 <img width="100%" onMouseEnter={handleMouseEventOnImageTheme2Enter} onMouseLeave={handleMouseEventOnImageTheme2Leave} src={image2} alt="Theme 2"/>
                 <Box
                  style={{
                  position: 'absolute',
                  top: '15%',
                  left: `${previewTextStateTheme2?"80%":"92%"}`,
                  transform: 'translate(-50%, -50%)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '8px',
                
                }}
          >
            <Box className={previewTextStateTheme2? "width-of-preview-text":""} sx={{display:"flex",alignItems:"center"}}>   
            {
                !previewTextStateTheme2 && (
                    <IconButton onMouseEnter={handleOnMouseTheme2Enter} className='layout-or-theme-vivibility-icon'>
                    <Visibility />
                    </IconButton>
                )
            }
            {previewTextStateTheme2 &&(
                <IconButton onClick={handlePopupTheme2Open} onMouseLeave={handleOnMouseTheme2Leave} className={previewTextStateTheme2?"layout-or-theme-preview-text":'layout-or-theme-vivibility-icon'}>
                <Visibility />
                {previewTextStateTheme2 && <Typography className='layout-or-theme-preview-text'>Preview</Typography>}
              </IconButton>

            )}
            
            </Box>   
            </Box>
              <Box>
                 {
              theme2InUseState?(
                <Box
                style={{
                position: 'absolute',
                top: '88%',
                left: "23.5%",
                transform: 'translate(-50%, -50%)', 
                }}
          >   
                  <Box className="bestSellerRgt" sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <Typography className='layout-or-image-theme-in-use-text'>Theme in Use</Typography>
                  </Box>
                
                 </Box>
             
              ):(
                <Box>
                   {
                    imageHoverStateTheme2 &&(
                        <Box
                style={{
                position: 'absolute',
                top: '78%',
                left: "50%",
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',      
                padding: '8px',
                
                }}
          >
              <Box className="layout-or-image-hover-background">
               <Button className='layout-or-image-hover-button' variant='outlined'>Use this Theme</Button>
             </Box>
          
            
                 </Box>
                    )
                 }

                </Box>
              
              )
            }  
                </Box>
                
                </Box>
                <Typography className='layout-or-theme-recommended-text'>Recommended for a small business type such as a cafe, dessert shop..</Typography>            
                </Box>
            </Box>


            <Box  sx={{width:"40%"}}>
                <Box sx={{margin:"15px",padding:"10px",width:"70%"}}>
                <Typography className='layout-or-theme-theme1-text'>Theme 3</Typography>
                <Box sx={{position:"relative"}}>
                 <img width="100%" onMouseEnter={handleMouseEventOnImageTheme3Enter} onMouseLeave={handleMouseEventOnImageTheme3Leave} src={image3} alt="Theme 3"/>
                 <Box
                  style={{
                  position: 'absolute',
                  top: '15%',
                  left: `${previewTextStateTheme3?"80%":"92%"}`,
                  transform: 'translate(-50%, -50%)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '8px',
                
                }}
          >
            <Box className={previewTextStateTheme3? "width-of-preview-text":""} sx={{display:"flex",alignItems:"center"}}>   
            {
                !previewTextStateTheme3 && (
                    <IconButton onMouseEnter={handleOnMouseTheme3Enter} className='layout-or-theme-vivibility-icon'>
                    <Visibility />
                    </IconButton>
                )
            }
            {previewTextStateTheme3 &&(
                <IconButton onClick={handlePopupTheme3Open} onMouseLeave={handleOnMouseTheme3Leave} className={previewTextStateTheme3?"layout-or-theme-preview-text":'layout-or-theme-vivibility-icon'}>
                <Visibility />
                {previewTextStateTheme3 && <Typography className='layout-or-theme-preview-text'>Preview</Typography>}
              </IconButton>

            )}
            
            </Box>   
            </Box>
              <Box>
                 {
              theme3InUseState?(
                <Box
                style={{
                position: 'absolute',
                top: '88%',
                left: "23.5%",
                transform: 'translate(-50%, -50%)', 
                }}
          >   
                  <Box className="bestSellerRgt" sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <Typography className='layout-or-image-theme-in-use-text'>Theme in Use</Typography>
                  </Box>
                
                 </Box>
             
              ):(
                <Box>
                   {
                    imageHoverStateTheme3 &&(
                        <Box
                style={{
                position: 'absolute',
                top: '78%',
                left: "50%",
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',      
                padding: '8px',
                
                }}
          >
              <Box className="layout-or-image-hover-background">
               <Button className='layout-or-image-hover-button' variant='outlined'>Use this Theme</Button>
             </Box>
          
      
                 </Box>
                    )
                 }

                </Box>
              
              )
            }  
                </Box>
                
                </Box>
                <Typography className='layout-or-theme-recommended-text'>Recommended for a small business type such as a cafe, dessert shop..</Typography>            
                </Box>
            </Box>


            <Box  sx={{width:"40%"}}>
                <Box sx={{margin:"15px",padding:"10px",width:"70%"}}>
                <Typography className='layout-or-theme-theme1-text'>Theme 4</Typography>
                <Box sx={{position:"relative"}}>
                 <img width="100%" onMouseEnter={handleMouseEventOnImageTheme4Enter} onMouseLeave={handleMouseEventOnImageTheme4Leave} src={image4} alt="Theme 4"/>
                 <Box
                  style={{
                  position: 'absolute',
                  top: '15%',
                  left: `${previewTextStateTheme4?"80%":"92%"}`,
                  transform: 'translate(-50%, -50%)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '8px',
                
                }}
          >
            <Box className={previewTextStateTheme4? "width-of-preview-text":""} sx={{display:"flex",alignItems:"center"}}>   
            {
                !previewTextStateTheme4 && (
                    <IconButton onMouseEnter={handleOnMouseTheme4Enter} className='layout-or-theme-vivibility-icon'>
                    <Visibility />
                    </IconButton>
                )
            }
            {previewTextStateTheme4 &&(
                <IconButton onClick={handlePopupTheme4Open} onMouseLeave={handleOnMouseTheme4Leave} className={previewTextStateTheme4?"layout-or-theme-preview-text":'layout-or-theme-vivibility-icon'}>
                <Visibility />
                {previewTextStateTheme4 && <Typography className='layout-or-theme-preview-text'>Preview</Typography>}
              </IconButton>

            )}
            
            </Box>   
            </Box>
              <Box>
                 {
              theme4InUseState?(
                <Box
                style={{
                position: 'absolute',
                top: '88%',
                left: "23.5%",
                transform: 'translate(-50%, -50%)', 
                }}
          >   
                  <Box className="bestSellerRgt" sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <Typography className='layout-or-image-theme-in-use-text'>Theme in Use</Typography>
                  </Box>
                
                 </Box>
             
              ):(
                <Box>
                   {
                    imageHoverStateTheme4 &&(
                        <Box
                style={{
                position: 'absolute',
                top: '78%',
                left: "50%",
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',      
                padding: '8px',
                
                }}
          >
              <Box className="layout-or-image-hover-background">
               <Button className='layout-or-image-hover-button' variant='outlined'>Use this Theme</Button>
             </Box>
          
      
                 </Box>
                    )
                 }

                </Box>
              
              )
            }  
                </Box>
                
                </Box>
                <Typography className='layout-or-theme-recommended-text'>Recommended for a small business type such as a cafe, dessert shop..</Typography>            
                </Box>
            </Box>
        
      </Box>
      <Modal
        open={popUpTheme1Open}
        onClose={handlePopupTheme1Close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={popupStyle}>
          <PosPopupTheme1 handleTheme1State={handleTheme1State} handlePopupTheme1Close={handlePopupTheme1Close} />
        </Box>
      </Modal>

      <Modal
        open={popUpTheme2Open}
        onClose={handlePopupTheme2Close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={popupStyle}>
          <PosPopupTheme2 handleTheme2State={handleTheme2State} handlePopupTheme2Close={handlePopupTheme2Close} />
        </Box>
      </Modal>
      <Modal
        open={popUpTheme3Open}
        onClose={handlePopupTheme3Close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={popupStyle}>
          <PosPopupTheme3 handleTheme3State={handleTheme3State} handlePopupTheme3Close={handlePopupTheme3Close} />
        </Box>
      </Modal>

      <Modal
        open={popUpTheme4Open}
        onClose={handlePopupTheme4Close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={popupStyle}>
          <PosPopupTheme4 handleTheme4State={handleTheme4State} handlePopupTheme4Close={handlePopupTheme4Close} />
        </Box>
      </Modal>


    </Box>
  )
}
