import React, { useState } from 'react';
import {
  Typography, Paper, Button, Divider, TextField, RadioGroup, Radio
} from '@mui/material';
import "./createServiceCharge.css";

import {
  FormControl, FormControlLabel, Checkbox
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { InputLabel, Select, MenuItem } from '@mui/material';
import { t } from "i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './createSalesTax.css'
import './createServiceCharge.css'
import BasicModal from './modal'
import { SelectChangeEvent } from '@mui/material';

const CreateServiceCharge = () => {

  const navigate = useNavigate();
  const [value, setValue] = useState(false);
  const handleNavigateExit = () => {
    setValue(prevState => !prevState)
  };

  const [selecteRadioValue, setSelectedRadioValue] = useState('option1');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadioValue(event.target.value);
  };

  const [chargeRate, setChargeRate] = useState('');

  const handleChargeRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChargeRate(event.target.value);
  };

  const [extraCharge, setExtraCharge] = useState('');

  const handleExtraCharge1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExtraCharge(event.target.value);
  };

  const [noOfCustomers, setNoOfCustomers] = useState('');

  const handleNoOfCustomers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNoOfCustomers(event.target.value);
  };

  const [changeRate, setChangeRate] = useState('');

  const handleChangeRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangeRate(event.target.value);
  };

  const [selectedLocation, setSelectedLocation] = useState('');

  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    setSelectedLocation(event.target.value);
  };

  const [selectedExtraCharge, setSelectedExtraCharge] = useState('');

  const handleExtraCharge =(event: SelectChangeEvent<string>) => {
    setSelectedExtraCharge(event.target.value );
  };

  return (
    <div>
      <Paper elevation={3} style={{ paddingBottom: "20px" }}>
        <div className='create_tax_head_container' style={{ padding: "12px 20px 0px 12px" }}>

          <div className='create_exitIcon'>
            <ArrowBackIcon onClick={handleNavigateExit} sx={{ height: "24px", width: '24px' }} />
          </div>
          <Typography className="create_tax_main">{t("settings.service_charge_create")}</Typography>
          <div className='create_tax_button_con'>
            <Button variant="contained" className='create_tax_button_style'>
              {t("settings.clear")}
            </Button>
            <Button variant="contained" className='create_tax_button_style1'>
              {t("settings.save")}
            </Button>
          </div>
        </div>
        <div style={{marginLeft: '8%', marginTop: '44px', width: "73%",}}>
          <Paper elevation={1} style={{ padding: "20px 12px 20px 12px", borderLeft: "2px solid var(--stroke-color-1-violet, #AE9BE2)" }} className='create_tax_page'>
            <Typography className="create_tax_basicDetails">{t("settings.basic_details")}</Typography>

            <TextField
              label={
                <Typography className='create_tax_label'>
                  {t("settings.charge_name")}
                </Typography>
              }
              variant="outlined"
              value={chargeRate}
              onChange={handleChargeRate}
              className='create_tax_textField'
              color='warning'
              sx={{
                "&:hover": {
                  "&& fieldset": {
                    border: "2px solid #F9C584"
                  }
                }
              }}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                label={
                  <Typography className='create_tax_label'>
                    {t("settings.charge%")}
                  </Typography>
                }
                variant="outlined"
                value={changeRate}
                onChange={handleChangeRate}
                className='create_tax_textField1'
                color='warning'
                sx={{
                  "&:hover": {
                    "&& fieldset": {
                      border: "2px solid #F9C584"
                    }
                  }
                }}
              />
              <FormControl className='create_tax_dropDown' style={{ margin: "20px 0px 0px 12px" }}>
                <InputLabel id="demo-simple-select-label">{t("settings.select_location")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedLocation}
                  label={t("settings.select_location")}
                  onChange={handleLocationChange}
                  color='warning'
                  sx={{
                    "&:hover": {
                      "&& fieldset": {
                        border: "2px solid #F9C584"
                      }
                    }
                  }}
                >
                  <MenuItem value="Hyderabad">Hyderabad</MenuItem>
                  <MenuItem value="Vizag">Vizag</MenuItem>
                  <MenuItem value="Kakinada">Kakinada</MenuItem>
                </Select>
              </FormControl>
              {value && (
                <BasicModal />
              )}
            </div>

          </Paper>

          <Paper elevation={1} style={{ padding: "20px 12px 20px 12px", borderLeft: "2px solid var(--stroke-color-2-tale, #9BDAE2)", marginTop: "24px" }} className='create_tax_page'>
            <Typography className="create_tax_basicDetails">{t("settings.charge_applicable")}</Typography>
            <div style={{ marginLeft: "12px" }}>
              <FormControlLabel
                control={<Checkbox color='warning' />}
                label={<Typography className='createService_check_text'>{t("settings.all_orders")}</Typography>}
                className='createService_check_box'
              />
              <Typography className='createService_check_para'>{t("settings.orders_options")}</Typography>
              <Divider style={{ marginTop: "12px" }} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  control={<Checkbox color='warning' />}
                  label={<Typography className='createService_check_text'>{t("settings.dineIn")}</Typography>}
                  className='createService_check_box'
                />
                <FormControlLabel
                  control={<Checkbox color='warning' />}
                  label={<Typography className='createService_check_text'>{t("settings.shop")}</Typography>}
                  className='createService_check_box'
                />
                <FormControlLabel
                  control={<Checkbox color='warning' />}
                  label={<Typography className='createService_check_text'>{t("settings.collections")}</Typography>}
                  className='createService_check_box'
                />
                <FormControlLabel
                  control={<Checkbox color='warning' />}
                  label={<Typography className='createService_check_text'>{t("settings.delivery")}</Typography>}
                  className='createService_check_box'
                />
              </div>
            </div>
          </Paper>
          <Paper elevation={1} style={{ padding: "20px 12px 20px 10px", borderLeft: "2px solid var(--stroke-color-3-green, #BAE29B)", marginTop: "24px" }} className='create_tax_page'>
            <Typography className="create_tax_basicDetails">{t("settings.custom_settings")}</Typography>
            <Divider style={{ marginTop: "16px", marginBottom: "8px" }} />
            <Typography className='createService_para2'>{t("settings.custom_para")}</Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="options"
                name="options"
                value={selecteRadioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel value="option1" control={<Radio color='warning' />} label={<Typography className='createService_radio_text'>{t("settings.custom_radio1")}</Typography>} />
                <FormControlLabel value="option2" control={<Radio color='warning' />} label={<Typography className='createService_radio_text'>{t("settings.custom_radio2")}</Typography>} />
              </RadioGroup>
            </FormControl>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl className='create_tax_dropDown' style={{ margin: "20px 0px 0px " }}>
                <InputLabel id="demo-simple-select-label" className='create_service_label'>{t("settings.extra_charge")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedExtraCharge}
                  label={t("settings.extra_charge")}
                  onChange={handleExtraCharge}
                  color='warning'
                  sx={{
                    "&:hover": {
                      "&& fieldset": {
                        border: "2px solid #F9C584"
                      }
                    }
                  }}
                >
                  <MenuItem value="Hyderabad">Hyderabad</MenuItem>
                  <MenuItem value="Vizag">Vizag</MenuItem>
                  <MenuItem value="Kakinada">Kakinada</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label={
                  <Typography className='create_service_label'>
                    5 %
                  </Typography>
                }
                variant="outlined"
                value={extraCharge}
                onChange={handleExtraCharge1}
                className='create_tax_textField2'
                style={{ marginLeft: "16px" }}
                color='warning'
                sx={{
                  "&:hover": {
                    "&& fieldset": {
                      border: "2px solid #F9C584"
                    }
                  }
                }}
              />
            </div>

            <TextField
              label={
                <Typography className='create_service_label'>
                  {t("settings.no_of_customers")}
                </Typography>
              }
              variant="outlined"
              value={noOfCustomers}
              onChange={handleNoOfCustomers}
              className='create_tax_textField1'
              color='warning'
              sx={{
                "&:hover": {
                  "&& fieldset": {
                    border: "2px solid #F9C584"
                  }
                }
              }}
            />

          </Paper>
        </div>
      </Paper>
    </div>
  )
}
export default CreateServiceCharge;
