import { Button, Divider, Paper, Typography } from '@mui/material';
import React, { useState, ChangeEvent, useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { TextField, IconButton,Box } from '@mui/material';
import './optionsList.css'
import { useQuery,useMutation } from '@apollo/client';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router';
import OptionsGridTable from './optionTableGrid';
import CreateOptionGroup from './createOptionGroupModule';
import { CREATE_OPTION_GROUP } from '../../graphicalQl/mutation/optionsMutation';
import { GET_OPTION_GROUP_IN_OPTIONS } from '../../graphicalQl/usequery/optionsQuery';
import { DELETE_OPTIONGROUP_OPTION } from '../../graphicalQl/mutation/optionsMutation';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
const styles = {
  paper: {
    width: '100%',
    height: '100%',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  typography: {
    color: '#333',
    fontFamily: 'Poppins',
    fontSize: '112.5%',
    fontWeight: 600,
  },
  button: {
    color: 'var(--base-base-100, #FEFEFE)',
    fontFamily: 'Poppins',
    fontSize: '112.5%',
    fontWeight: 500,
    display: 'inline-flex',
    height: '25%',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '4px',
    backgroundColor: '#F38B08',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E5850B',
    },
  },
  selectBox: {
    display: 'flex',
    padding: '0px 12px',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: '4px',
   
  }
};
interface Data{
  id:string,
        name:string,
        value:string,
}


interface OptionValue{
  name: string
optionId: string
}

interface OptionGroup{
  groupName:string,
    groupId:string,
    optionsObj:OptionValue[]
}


const Options: React.FC = () => {

  const navigate=useNavigate()
  const handleNavigate=()=>{
    navigate('/createOption')
  }
  const [filter, setfilter] = useState<string>(''); // You can set the initial value here

  const handleChangeFilter = (event: SelectChangeEvent) => {
    setfilter(event.target.value);
  };

  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const [addGroup, setAddGroup] = useState<string>(''); // Initialize the state with an empty string

  const handleAddGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddGroup(event.target.value); // Update the state with the new value
  };
  const [addbutton, setAddButton] = useState<boolean>(false); // Initialize the state with an empty string



  const handleAddButton = () => {
    setAddButton(!addbutton); // Update the state with the new value
  };

  const[optionGroupName,setOptionGroupName]=useState<string>("")

  console.log(optionGroupName)

  const handleGroupName=(data:string)=>{
    setOptionGroupName(data)
  }

  const [createOptionGroup,] = useMutation(CREATE_OPTION_GROUP, {
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
     console.log(error.message)
    },
    context: { clientName: "categoryClient" },
    refetchQueries:[GET_OPTION_GROUP_IN_OPTIONS]
  });

  const [deleteOptionGroup] = useMutation(DELETE_OPTIONGROUP_OPTION, {
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
     console.log(error.message)
    },
    context: { clientName: "categoryClient" },
    refetchQueries:[GET_OPTION_GROUP_IN_OPTIONS]
   
  });

  const handleCreateGroup=()=>{
    createOptionGroup({variables:{name:optionGroupName,options:JSON.stringify(selectedDataIds)}})
  }

  const [selectedData, setSelectedData] = useState<Data[]>([]);

  // Callback function to receive selected data from OptionTable component
  const handleSelectedData = (data: Data[]) => {
    setSelectedData(data);
  };


  const selectedDataIds = selectedData.map((each) => each.id);

console.log(selectedDataIds)
  const handleDataList = (data: Data[], id: number) => {
    setAccordionList((prevAccordions) =>
      prevAccordions.map((accordion) =>
        accordion.id === id
          ? { ...accordion, data: [...accordion.data, ...data] }
          : accordion
      )
    );
  };




  const [accordionList, setAccordionList] = useState<{ id: number; name: string; data: Data[] }[]>([]);
  const handleAddValue = () => {
    if (addGroup.trim() !== '') {
      const id = Date.now();
      const newValue = { id, name: addGroup, data: [] }; // Include the name in the accordion object
      setAccordionList([...accordionList, newValue]);
      setAddGroup('');
      setAddButton(false);
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (index: number) => {
    setAccordionList((prevAccordions) =>
      prevAccordions.map((accordion, i) =>
        i === index ? { ...accordion, isHovered: true } : accordion
      )
    );
  };

  const handleMouseLeave = (index: number) => {
    setAccordionList((prevAccordions) =>
      prevAccordions.map((accordion, i) =>
        i === index ? { ...accordion, isHovered: false } : accordion
      )
    );
  };

  const handleRemoveOption = (groupId: number, optionIndex: number) => {
    // Remove the specific option from the group with the given ID and index
    setAccordionList((prevAccordions) =>
      prevAccordions.map((accordion) =>
        accordion.id === groupId
          ? {
            ...accordion,
            data: accordion.data.filter((_, index) => index !== optionIndex),
          }
          : accordion
      )
    );
  };

  const handleDeleteAccordion = (accordionId: number) => {
    setAccordionList((prevAccordions) =>
      prevAccordions.filter((accordion) => accordion.id !== accordionId)
    );
    deleteOptionGroup({variables:{optionGroupId:accordionId},refetchQueries:[GET_OPTION_GROUP_IN_OPTIONS]})
  };

  const [optionGroupData,setOptionGroup]=useState<OptionGroup[]>([])
  const {data,loading,error}=useQuery(GET_OPTION_GROUP_IN_OPTIONS, { context: { clientName:"categoryClient" }})
  console.log(data)

  useEffect(()=>{
if(data){
  if(data.optionGroupList){
  const formatedOptionData=data.optionGroupList.edges.map((each:any)=>({
    groupName:each.node.name,
    groupId:each.node.optionGroupId,
    optionsObj:each.node.optionObjs


  }))

  setOptionGroup(formatedOptionData)
}
}
  },[data])

  console.log(optionGroupData)
  
  return (
    <Paper elevation={3} style={{ width: "100%", padding: '20px',maxHeight:'200vh' }}>
      <div style={styles.paper} >
        <Typography variant="h6" sx={styles.typography}>
          Options
        </Typography>
        <Button variant="contained" sx={styles.button} onClick={handleNavigate}>
          Create an Option
        </Button>
      </div>
      <Divider style={{ margin: "1%" }} />
      <FormControl style={{ width: "14%" }} >
        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter}
          label='Filter'
          onChange={handleChangeFilter}
          color='warning'
          style={styles.selectBox}
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
      <TextField
        id="outlined-basic"
        label="Search by name. description or sku"

        variant="outlined"
        color='warning'
        value={inputValue}
        onChange={handleInputChange}
        style={{ marginLeft: "1.5%", width: "30%" }}
      />
      <Divider style={{ marginTop: "2%" }} />
      <CreateOptionGroup addbutton={addbutton} handleAddButton={handleAddButton} handleGroupName={handleGroupName} handleCreateGroup={handleCreateGroup}/>
      {selectedData.length<=0 ? ( <div style={{display:"flex",alignItems:"center",borderLeft: "2px solid var(--states-green, #00AE26)",background: "#F1FFF4",width:"100%",padding:"1%",marginTop:"1%"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 9H11V7H13V9Z" fill="#00AE26"/>
</svg>
        <Typography className='options_table_head'>Select one or more than one option to create an option group</Typography>
      </div>):(<div style={{background: "var(--prime-orange-100, #FFF8F0)",padding:"1%",marginTop:"1%",width:'100%',display:'flex',alignItems:"center"}}>
        <Typography className='options_table_head' sx={{marginLeft:"4%"}}>{selectedData.length} Options Selected</Typography>
        <Typography className='options_table_para' sx={{marginLeft:"20%"}}>Add the selected options in existing option groups or </Typography>
     <Button className='group_button' onClick={handleAddButton}> Create a Option Group</Button>
      </div>)}
     

      <div style={{ display: "flex" }}>
        <div style={{ width: "70%" }}>
          <OptionsGridTable handleSelectedData={handleSelectedData} optionGroupName={optionGroupName}/>
        </div>

        <div style={{ padding: "1%", width: "30%",height:"100%" }}>
          <Typography className='option_table_head2' >Option Groups</Typography>
          <Divider style={{ width: "100%", marginTop: "5%" }} />
          <div style={{ width: '100%',height:"100%" }}>
            <div style={{ overflowY: "auto", width: "100%", maxHeight: "60vh" }}>
              {optionGroupData.map((accordion, index) => (
                <Accordion key={accordion.groupId}>
                  <AccordionSummary

                    expandIcon={<ExpandMoreIcon className='delete_button'/>}
                    aria-controls={`panel${accordion.groupId}-content`}
                    id={`panel${accordion.groupId}-header`}
                    style={{display:"flex",alignItems:"center"}}
                  >
                    <Typography  >{accordion.groupName} </Typography>
                    <IconButton
                      aria-label="delete"

                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        marginRight: '50px',
                        marginTop: "6px",
                        transition: 'background-color 0.3s', // Add a transition for smooth color change
                     
                        
                      }}
                      onClick={() => handleDeleteAccordion(+accordion.groupId)}
                      className='delete_button'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </AccordionSummary>
                  {accordion.optionsObj && accordion.optionsObj.map((each, dataIndex) => (
                    <AccordionDetails key={dataIndex} style={{ padding: "3px" }}>
                      <div
                        className="accoridion_text options_table_row"
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                      >
                        {each.name}

                        <IconButton
                          onClick={() => handleRemoveOption(+accordion.groupId, dataIndex)}
                          style={{ marginLeft: "5px" }}
                          className='delete_button'
                        >
                          <ClearTwoToneIcon fontSize="small" />
                        </IconButton>

                      </div>
                    </AccordionDetails>

                  ))}
                  <AccordionDetails>
                    <Button className='options_add_button' onClick={() => handleDataList(selectedData, +accordion.groupId)}>
                      Add Option
                    </Button>
                  </AccordionDetails>
                </Accordion>
              ))}

            </div>

            {/* <Button className='group_button' style={{ marginTop: "4%", width: "100%" }} onClick={handleAddButton}>Create a Group</Button>

            {addbutton && (
              <div>
                <TextField
                  label="Group Name"
                  value={addGroup}
                  onChange={handleAddGroup}
                  variant="outlined"
                  fullWidth
                  color='warning'
                  margin="normal"
                />
                <Button className='options_add_button' onClick={handleAddValue}>Add Value</Button>
              </div>
            )
            } */}
          </div>

        </div>
      </div>
    </Paper>
  );
};

export default Options;