import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Switch,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Popover, List, ListItem, ListItemText } from '@mui/material';
import { t } from 'i18next';
import './salesTax.css';
import { SelectChangeEvent } from '@mui/material';

interface Option {
  value: string;
  label: string;
}

interface UserData {
  id: number;
  taxName: string;
  location: string;
  status: string;
  taxRate: number;
}

const SalesTax: React.FC = () => {
  const options: Option[] = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ];

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/create_sales_tax');
  };

  const [usersData, setUsersData] = useState<UserData[]>([]);
  const [toggleState, setToggleState] = useState(false);

  useEffect(() => {
    axios
      .get('/salesTax.json')
      .then(response => {
        setUsersData(response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggleState(event.target.checked);
  };

  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value );
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const popoverContent = (
    <List style={{ padding: '0px 0px 0px 0px' }}>
      <Button style={{ padding: '0px 0px 0px 0px' }}>
        <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
          <ListItemText primary={t('selfMadeDiscount.edit')} />
        </ListItem>
      </Button>
      <Divider />
      <Button style={{ padding: '0px 0px 0px 0px' }}>
        <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
          <ListItemText primary={t('selfMadeDiscount.delete')} />
        </ListItem>
      </Button>
    </List>
  );

  return (
    <div>
      <Box >
        <div style={{ paddingLeft: '12px', marginBottom: '8px', marginTop: '8px' }}>
          <div className='sales_head_container'>
            <div className='sales_head_center'>
              <Typography className='sales_head'>{t('settings.sales_head')}</Typography>
            </div>
            <Switch checked={toggleState} onChange={handleToggleChange} color='warning' />
          </div>
          <Typography className='sales_para'>{t('settings.sales_para')}</Typography>
        </div>
        <Divider style={{ backgroundColor: ' #D9D9D9', marginTop: '15px' }} />
        {toggleState && (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <FormControl style={{ margin: '12px 0px 12px 20px', width: '180px', height: '48px' }}>
                <InputLabel id='demo-simple-select-label'>{t('settings.select_location')}</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedOption}
                  label={t('settings.select_location')}
                  onChange={handleChange}
                  className='dropDown_con'
                  color='warning'
                  sx={{
                    '&:hover': {
                      '&& fieldset': {
                        border: '2px solid #F9C584',
                      },
                    },
                  }}
                >
                  {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button variant='contained' onClick={handleNavigate} className='salesButton'>
                {t('settings.create_tax')}
              </Button>
            </div>
            <Divider style={{ backgroundColor: ' #D9D9D9' }} />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow style={{ paddingLeft: '60px' }}>
                    <TableCell className='table_row_head' style={{ paddingLeft: '44px' }}>
                      {t('settings.tax_name')}
                    </TableCell>
                    <TableCell className='table_row_head'>{t('settings.location')}</TableCell>
                    <TableCell className='table_row_head'>{t('settings.status')}</TableCell>
                    <TableCell className='table_row_head'>{t('settings.tax_rate')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersData.map(row => (
                    <TableRow key={row.id} className='table_row1'>
                      <TableCell className='table_row' style={{ paddingLeft: '44px' }}>
                        {row.taxName}
                      </TableCell>
                      <TableCell className='table_row'>{row.location}</TableCell>
                      <TableCell className='table_row'>{row.status}</TableCell>
                      <TableCell className='table_row'>{row.taxRate}</TableCell>
                      <TableCell>
                        <div>
                          <IconButton onClick={handleClick}>
                            <MoreVertIcon />
                          </IconButton>

                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left"
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right"
                            }}
                            style={{
                              width: "180px",
                              height: "225px",
                              paddingTop: "0px",
                              paddingBottom: "0px"
                            }}
                            elevation={8}
                          >
                            {popoverContent}
                          </Popover>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </div>
  );
};

export default SalesTax;
