import React, { useState } from 'react';
import {
    Typography, Paper, Button, Switch, TextField
} from '@mui/material';

import { t } from "i18next";

import './rounding.css';

const OpenItems = () => {
    const [toggleState, setToggleState] = useState<boolean>(false);
    const [discountsEnabled, setDiscountsEnabled] = useState<boolean>(false);
    const [rewardPointsEnabled, setRewardPointsEnabled] = useState<boolean>(false);
    const [stockReductionEnabled, setStockReductionEnabled] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');

    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToggleState(event.target.checked);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleDiscountsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscountsEnabled(event.target.checked);
    };

    const handleRewardPointsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRewardPointsEnabled(event.target.checked);
    };

    const handleStockReductionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStockReductionEnabled(event.target.checked);
    };

    return (
        <div>
            <Paper elevation={3} style={{ padding: "20px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography className="rounding_titleValue">{t("settings.adoc_title")}</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button variant="contained" className="rounding_Button_clear">
                            {t("settings.clear")}
                        </Button>
                        <Button variant="contained" className="Rounding_save_button" style={{ marginLeft: "20px" }}>
                            {t("settings.save")}
                        </Button>
                    </div>
                </div>
                <Paper style={{ marginLeft: '8%', marginTop: '44px', width: "73%", padding: "8px 16px 8px 16px", borderLeft: "2px solid  var(--stroke-color-1-violet, #AE9BE2)" }} className='refund_page'>
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography className="refund_head" style={{ marginRight: "60px" }}>{t("settings.adhoc_para")}</Typography>
                            <Switch checked={toggleState} onChange={handleToggleChange} color='warning' />
                        </div>
                        <Typography className="refund_para">{t("settings.adoc_para2")}</Typography>
                    </div>
                </Paper>

                {toggleState && (
                    <>
                        <div>
                            <Paper style={{marginLeft: '8%', marginTop: '44px', width: "73%", padding: "8px 16px 8px 16px", borderLeft: "2px solid   var(--stroke-color-2-tale, #9BDAE2)" }} className='refund_page'>
                                <Typography className="refund_head">{t("settings.adoc_para3")}</Typography>
                                <TextField
                                    style={{ marginLeft: "10px", marginTop: '10px' }}
                                    label={t("settings.max_price")}
                                    variant="outlined"
                                    color='warning'
                                    value={inputValue}
                                    onChange={handleInputChange}
                                />
                                <Typography className='refund_para' style={{ marginTop: "12px" }}>{t("settings.adoc_para4")}</Typography>

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography className='refund_head4' style={{ marginRight: "60px" }}>{t("settings.adoc_para5")}</Typography>
                                    <Switch
                                        checked={discountsEnabled}
                                        onChange={handleDiscountsChange}
                                        color='warning'
                                    />
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography className='refund_head4' style={{ marginRight: "27px" }}>{t("settings.adoc_para6")}</Typography>
                                    <Switch
                                        checked={rewardPointsEnabled}
                                        onChange={handleRewardPointsChange}
                                        color='warning'
                                    />
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography className='refund_head4' style={{ marginRight: "18px" }}>{t("settings.adoc_para7")}</Typography>
                                    <Switch
                                        checked={stockReductionEnabled}
                                        onChange={handleStockReductionChange}
                                        color='warning'
                                    />
                                </div>
                            </Paper>
                        </div>
                    </>
                )}

            </Paper>
        </div>
    )
}

export default OpenItems
