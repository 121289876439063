import React, { useState } from 'react';
import {
  Typography, Paper, Button, TextField, Switch, RadioGroup, Radio, FormControlLabel,
  FormControl,  Select, MenuItem, Divider,InputLabel
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import BasicModal from './modal';
import { SelectChangeEvent } from '@mui/material';
import './createSalesTax.css'

interface SalesValues {
  value: boolean;
  isOn: boolean;
  selectedRounding: string;
  taxName: string;
  taxRate: string;
  taxException: string;
  selectedLocation: string;
  selectedLocation1: string;
  selectedDine: string;
  selectedRadioValue: string;
}

const CreateSalesTax = () => {
  const navigate = useNavigate();

  const [salesValues, setSalesValues] = useState<SalesValues>({
    value: false,
    isOn: false,
    selectedRounding: '',
    taxName: '',
    taxRate: '',
    taxException: '',
    selectedLocation: '',
    selectedLocation1: '',
    selectedDine: '',
    selectedRadioValue: 'option1',
  });

  const option1Element = (
    <div>
      <Typography className='create_tax_type_table_row_radio'>{t("settings.all_taxable")}</Typography>
      <Typography className='create_tax_type_table_row' style={{ fontSize: "14px" }}>{t("settings.all_taxable_para")}</Typography>
    </div>
  );

  const option2Element = (
    <div>
      <Typography className='create_tax_type_table_row_radio' >{t("settings.select_items")}</Typography>
      <Typography className='create_tax_type_table_row' style={{ fontSize: "14px" }}>{t("settings.select_items_para")}</Typography>
    </div>
  );



  
  const option1 = (
    <div>
      <Typography className='rounding_value'>{t("settings.none")}</Typography>
    </div>
  )
  const option3 = (
    <div>
      <Typography className='rounding_value'>{t("settings.half_down")}<span className='span_style'>({t("settings.if")} 7.5==7.00)</span></Typography>
    </div>
  )
  const option2 = (
    <div>
      <Typography className='rounding_value'>{t("settings.half_up")}<span className='span_style'>( {t("settings.if")} 7.5 ==8.00)</span></Typography>
    </div>
  )
  const option4 = (
    <div>
      <Typography className='rounding_value'>{t("settings.upwards")}<span className='span_style'>( {t("settings.if")} 7.62 ==8.00)</span></Typography>
    </div>
  )
  const option6 = (
    <div>
      <Typography className='rounding_value'>{t("settings.upwards_to")}<span className='span_style'>( {t("settings.if")} 7.62 ==7.99)</span></Typography>
    </div>
  )
  const option5 = (
    <div>
      <Typography className='rounding_value1'>{t("settings.downwards")}<span className='span_style'>({t("settings.if")} 7.62==7.00)</span></Typography>
    </div>
  )
  const handleNavigateExit = () => {
    setSalesValues((prevState) => ({ ...prevState, value: !prevState.value }));
  };

  const handleToggle = () => {
    setSalesValues((prevState) => ({ ...prevState, isOn: !prevState.isOn }));
  };

  const handleChangeRounding = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSalesValues((prevState) => ({ ...prevState, selectedRounding: event.target.value }));
  };

  const handleTaxNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSalesValues((prevState) => ({ ...prevState, taxName: event.target.value }));
  };

  const handleTaxRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSalesValues((prevState) => ({ ...prevState, taxRate: event.target.value }));
  };

  const handleTaxException = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSalesValues((prevState) => ({ ...prevState, taxException: event.target.value }));
  };

  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    setSalesValues((prevState) => ({ ...prevState, selectedLocation: event.target.value as string }));
  };

  const handleLocationChange1 = (event: SelectChangeEvent<string>) => {
    setSalesValues((prevState) => ({ ...prevState, selectedLocation1: event.target.value as string }));
  };

  const handleDine = (event: SelectChangeEvent<string>) => {
    setSalesValues((prevState) => ({ ...prevState, selectedDine: event.target.value }));
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSalesValues((prevState) => ({ ...prevState, selectedRadioValue: event.target.value }));
  };

  const updatedSalesValue: SalesValues = {
    ...salesValues,
  };
  console.log(updatedSalesValue);



  return (
    <div>
      <Paper elevation={3} style={{ paddingBottom: "20px" }} >
        
        <div className='create_tax_head_container' style={{ padding: "12px 20px 0px 12px" }}>
       
<div className='create_exitIcon'>
<ArrowBackIcon  onClick={handleNavigateExit} sx={{height:"24px",width:'24px'}}/>
</div>

          
          <Typography className="create_tax_main">{t("settings.create_tax")}</Typography>
          <div className='create_tax_button_con'>
            <Button variant="contained" className='create_tax_button_style'>
              {t("settings.clear")}
            </Button>
            <Button variant="contained" className='create_tax_button_style1'>
              {t("settings.save")}
            </Button>
          </div>
        </div>
        <div style={{ width: "80%", marginLeft: "10%", marginTop: "44px" }}>
          <Paper  style={{ padding: "20px 12px 20px 12px" ,borderLeft: "2px solid var(--stroke-color-1-violet, #AE9BE2)"}} className='create_tax_page'>
            <Typography className="create_tax_basicDetails">{t("settings.basic_details")}</Typography>

            <TextField
              label={
                <Typography className='create_tax_label'>
                  {t("settings.tax_name")}
                </Typography>
              }
              variant="outlined"
              value={salesValues.taxName}
        onChange={handleTaxNameChange}
        className='create_tax_textField'
        color='warning'
        sx={{
          "&:hover": {
            "&& fieldset": {
              border: "2px solid #F9C584"
            }
          }
        }}
        />
        <div style={{display:"flex",alignItems:"center"}}>
        <TextField
              label={
                <Typography className='create_tax_label'>
                 {t("settings.tax_rate%")}
                </Typography>
              }
              variant="outlined"
              value={salesValues.taxRate}
        onChange={handleTaxRate}
        className='create_tax_textField1'
        color='warning'
        sx={{
          "&:hover": {
            "&& fieldset": {
              border: "2px solid #F9C584"
            }
          }
        }}
        />
       <FormControl className='create_tax_dropDown' style={{ margin: "20px 0px 0px 12px" }}>
      <InputLabel id="demo-simple-select-label">{t("settings.select_location")}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={t("settings.select_location")}
        value={salesValues.selectedLocation}
        onChange={handleLocationChange}
        color='warning'
        sx={{
          "&:hover": {
            "&& fieldset": {
              border: "2px solid #F9C584"
            }
          }
        }}
      >
        <MenuItem value="Hyderabad">Hyderabad</MenuItem>
        <MenuItem value="Vizag">Vizag</MenuItem>
        <MenuItem value="Kakinada">Kakinada</MenuItem>
      </Select>
    </FormControl>

        </div>
        {salesValues.value && (
          <BasicModal/>
        )}
        
          </Paper>
      
         <Paper  style={{ padding: "20px 12px 20px 12px",borderLeft: "2px solid var(--stroke-color-2-tale, #9BDAE2)",marginTop:"24px" }} className='create_tax_page'>
         <Typography className="create_tax_basicDetails">{t("settings.tax_application")} </Typography>
         <Divider style={{marginTop:"16px",marginBottom:"12px"}}/>
         <FormControl component="fieldset">
    
      <RadioGroup
        aria-label="options"
        name="options"
        value={salesValues.selectedRadioValue}
        onChange={handleRadioChange}
        
      >
        <FormControlLabel value="option1" control={<Radio color='warning'/>} label={option1Element} />
        <Divider style={{marginTop:"12px", marginBottom:"12px"}}/>
        <FormControlLabel value="option2" control={<Radio  color='warning' />} label={option2Element} />
        
      </RadioGroup>
    </FormControl> 
    <Divider style={{marginTop:"16px",marginBottom:"12px"}}/>
    <div style={{display:"flex",alignItems:"center"}}>
<Typography className='create_tax_selectProducts'>{t("settings.select_products")}</Typography>
<Typography className='create_tax_products' style={{marginLeft:'36px'}}>{t('settings.product_select')}</Typography>
</div>
<Button className='create_tax_select_button' style={{width:"100%",marginTop:"16px"}}>{t("settings.assign_products")}</Button>
         </Paper>
         <Paper style={{ padding: "20px 12px 20px 10px",borderLeft: "2px solid var(--stroke-color-3-green, #BAE29B)",marginTop:"24px" }} className='create_tax_page'>
        
            <div style={{ display: "flex" }}>
              <Switch onChange={handleToggle} checked={salesValues.isOn} color='warning' />
              <div style={{marginLeft:'32px'}}>
                <Typography className="create_tax_exception">{t("settings.add_rule")}</Typography>
                <Typography className='create_tax_type_table_row' >{t("settings.add_rule_para")}</Typography>
              </div>
            </div>
            <Divider style={{marginTop:"20px"}}/>
            {salesValues.isOn &&
              <div>
                <TextField
              label={
                <Typography className='create_tax_label'>
                {t('settings.tax_exception_name')}
                </Typography>
              }
              variant="outlined"
              value={salesValues.taxException}
        onChange={handleTaxException}
        className='create_tax_textField'
        color='warning'
        sx={{
          "&:hover": {
            "&& fieldset": {
              border: "2px solid #F9C584"
            }
          }
        }}
        />
        <div style={{display:"flex"}}>
        <FormControl className='create_tax_dropDown' style={{ margin: "20px 0px 0px 0px" }}>
      <InputLabel id="demo-simple-select-label">{t("settings.select_location")}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={t("settings.select_location")}
        value={salesValues.selectedLocation1}
        onChange={handleLocationChange1}
        color='warning'
        sx={{
          "&:hover": {
            "&& fieldset": {
              border: "2px solid #F9C584"
            }
          }
        }}
      >
        <MenuItem value="Hyderabad">Hyderabad</MenuItem>
        <MenuItem value="Vizag">Vizag</MenuItem>
        <MenuItem value="Kakinada">Kakinada</MenuItem>
      </Select>
    </FormControl>

    <FormControl className='create_tax_dropDown' style={{ margin: "20px 0px 0px 12px" }}>
      <InputLabel id="demo-simple-select-label">{t("settings.dining_options")}</InputLabel>
      <Select
  labelId="demo-simple-select-label"
  id="demo-simple-select"
  label={t("settings.dining_options")}
  value={salesValues.selectedDine} 
  onChange={handleDine}
  color='warning'
  sx={{
    "&:hover": {
      "&& fieldset": {
        border: "2px solid #F9C584"
      }
    }
  }}
>
  <MenuItem value="dineIn">Dine In</MenuItem>
  <MenuItem value="DineOut">Dine Out</MenuItem>
  <MenuItem value="pickUp">Pick Up</MenuItem>
</Select>

    </FormControl>
        </div>
        <Divider style={{marginTop:"16px",marginBottom:"12px"}}/>
    <div style={{display:"flex",alignItems:"center"}}>
<Typography className='create_tax_selectProducts'>{t("settings.select_products")}</Typography>
<Typography className='create_tax_products' style={{marginLeft:'36px'}}>{t("settings.product_select")}</Typography>
</div>
<Button className='create_tax_select_button' style={{width:"100%",marginTop:"16px"}}>{t("settings.assign_products")}</Button>
              </div>
            }
          
          </Paper>
          <Paper  style={{ padding: "12px 12px 20px 10px",borderLeft: "2px solid var(--stroke-color-4-brown, #E2A39B)",marginTop:"24px"}} className='create_tax_page'>
          <Typography className="create_tax_basicDetails" style={{ marginTop: "10px", marginBottom: "10px" }}>Tax Rounding</Typography>
          <div style={{ display: "flex" }}>
          <RadioGroup
    aria-label="radio-button-group"
    name="radio-button-group"
    value={salesValues.selectedRounding}
    onChange={handleChangeRounding}
    row
    style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}
  >
    <FormControlLabel value="None" control={<Radio color='warning'/>} label={option1} className='create_tax_rounding'/>
    <FormControlLabel value="option2" control={<Radio color='warning'/>} label={option2} className='create_tax_rounding'/>
    <FormControlLabel value="option3" control={<Radio color='warning'/>} label={option3} className='create_tax_rounding' />
    <FormControlLabel value="option4" control={<Radio color='warning'/>} label={option4} className='create_tax_rounding'/>
    <FormControlLabel value="option5" control={<Radio color='warning'/>} label={option5} className='create_tax_rounding'/>
    <FormControlLabel value="option6" control={<Radio color='warning'/>} label={option6} className='create_tax_rounding'/>
  </RadioGroup>
</div>
          </Paper>
          <Paper  style={{ padding: "12px 12px 20px 10px",borderLeft: "2px solid var(--stroke-color-5-magenta, #E19BE2)",marginTop:"24px"}} className='create_tax_page'>
      
          <Typography className='create_tax_basicDetails' >{t("settings.tax_calculation")}</Typography>
          <Divider style={{marginTop:"20px",marginBottom:"12px"}}/>
          <div style={{ display: "flex",alignItems:'center'}}>
            <Switch color='warning' style={{marginRight:"32px"}}/>
            <div>
              <Typography className='create-tax_last_head'>{t("settings.include_tax")}</Typography>
              <Typography className='create-tax_last_para'>{t("settings.include_tax_para")}</Typography>
            </div>
          
        </div>
        </Paper>
        
        </div>
        
       
       
      </Paper>
    </div>
  )
}
export default CreateSalesTax