import React, { useState,useEffect } from 'react';
import { Divider, Typography,Box, Button, List, ListItem, IconButton } from "@mui/material";
import './menuSetup.css';
import NestedModal from "./menuModal";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuDragDrop from './menuDragDrop';
import { MoreVertOutlined } from '@mui/icons-material';
import BulkAdujustment from './bulkAdjustment';

interface State{
    menu:string;
    description:string;
    showPOS: boolean;
    showOnline: boolean;
    location:string;
    startTime:string;
    endTime:string;
   
}
const InitialData:State[]=[{
    menu: '',
    description: '',
    showPOS: false,
    showOnline: false,
    location:"",
    startTime:"",
    endTime:"",
}]
const MenuSetup=()=>{

const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
const [menuData,setMenuData]=React.useState<State[]>(InitialData);


  const addMenu = () => {
    setIsModalOpen(true);
  };
 

  const getData = (state: State) => {
    const newMenuItem: State = { ...state };
    setMenuData((prevState) => [...prevState, newMenuItem]);
  };
  const [active,setActive]=useState<string>("")

  useEffect(() => {
    if (menuData.length === 2) {
      setActive(menuData[1].menu);
    }
  }, [menuData]);
  

  const handleActive=(value:string)=>{
    setActive(value)
  }
  const [isModalOpenBulk, setIsModalOpenBulk] = React.useState<boolean>(false);



  const addModalBulk = () => {
    setIsModalOpenBulk(true);
    
  };
 
  
 
    return(
        <Box sx={{padding:"2%"}}>
          <Box sx={{display:"flex",alignItems:"center"}}>
          <Typography className="menuSetup">Menus({menuData.length-1})</Typography>
          {(active!=="")&&(
        <Box sx={{marginLeft:"15%",display:"flex",alignItems:"center" ,width:"60%"}}>
            <Typography className='menuSetup'>{active}</Typography>
            {menuData.map((each)=>{
                if(each.menu===active){
                  
                      if (each.startTime !=="" && each.endTime !==""){
                        return(
                        <Typography className='menuSetup-time-text' sx={{marginLeft:"5%"}}>only {each.startTime} to {each.endTime}</Typography>
                        )
                      }
                    
                }
                return null
            })}
            <Box sx={{marginLeft:"auto",display:'flex'}}>
            <Button className='menuSetup-button2'>Publish</Button>
            <IconButton onClick={addModalBulk } >
            <MoreVertOutlined  />
            </IconButton>
            </Box>
        </Box>
        )
        }
          </Box>
        
        <Divider sx={{margin:"1% 0% 1% 0%"}}/>
        
        
      
        <NestedModal open={isModalOpen} handleClose={() => setIsModalOpen(false)} handleData={getData} />
<BulkAdujustment openModal={isModalOpenBulk} handleClose={() => setIsModalOpenBulk(false)}/>
        <Box sx={{display:"flex"}}>
        <Box sx={{display:"flex",flexDirection:"column",width:"16%"}}>
        <Button className="menuSetup-button" sx={{width:"100%"}} onClick={addMenu}>+Add Menu</Button>
        <List sx={{width:"100%"}}>
        {
            menuData.map((each)=>{
                if(each.menu !==""){
                   return(
                    <ListItem
  key={each.menu}
  className={active === each.menu ? "menuSetup-menu2" : "menuSetup-menu"}
  onClick={() => handleActive(each.menu)}
>

  {active === each.menu ? (
   
      <Typography className='menuSetup-menu-text' style={{width:"80%"}}>{each.menu} <span style={{ marginLeft: "36%" }}>
        <KeyboardArrowRightIcon />
      </span></Typography>
   
  ) : (
    <Typography className='menuSetup-menu-text'>{each.menu}</Typography>
  )}


</ListItem>

                   ) 
                }
                return null
            })
        }
        </List>
       
       
       
          </Box>
          
          {menuData.map((each) => {
  return each.menu === active ? <MenuDragDrop key={each.menu}/> : null;
})}

        
        </Box>
        </Box>
    )
}
export default MenuSetup