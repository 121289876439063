import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { t } from 'i18next';
import './createCategory.css'
import { useEffect } from 'react';
import { EDIT_CATEGORY } from '../../graphicalQl/usequery/categoryListQuery';
import { useQuery } from '@apollo/client';


interface Props{
    categoryId:string;
    handleStepByOptionModal:()=>void;
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
 
  boxShadow: 24,
  p: 4,
};

export default function OptionsDailouge(props:Props) {
    const {categoryId,handleStepByOptionModal}=props
  
  const [open, setOpen] = React.useState(false);

 

  const {data:editData}=useQuery(EDIT_CATEGORY,{variables:{categoryId:categoryId}, context: { clientName: 'categoryClient' }})

useEffect(()=>{
    if (editData){
        if (editData.category){
        const productCount=editData.category.productSet.edges.length;
      
   
        if (productCount<=0){
            setOpen(true)
            
        }
        
    }
    else {
      setOpen(true)
    }
    }
    
   
},[editData,categoryId])

  const handleClose = () => {
    setOpen(false);
   
  };
  const handleOkayButton=()=>{
    handleStepByOptionModal()
  }
 
 
  return (
    <div>
  
    <Modal
      open={open}
      
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Box sx={{display:"flex",alignItems:"center",width:"60%",justifyContent:"space-between"}}>
      {/* <Box className="create-product-exit" sx={{display:"flex",justifyContent:"center",alignItems:"center"}} onClick={handleClose}>
    <CloseIcon className="create-product-exitIcon"  />
  </Box> */}
  
      
      </Box>
     
      <Box sx={{padding:"3% 1% 3% 1%",marginTop:'1%',textAlign:'center'}}>

    
      <Typography className='category-created'>

      {t("menu.catageory.please_fill")} <span className='create_category_product_text'> {t("menu.catageory.assign_products")}</span> {t("menu.catageory.first_add_product")}</Typography>
</Box>
<Box sx={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"2%"}}>
 
<Button className='create_category_modal_next_button' sx={{marginLeft:"3%",textTransform:"none"}} onClick={()=>{handleClose();handleOkayButton()}}>{t("menu.catageory.okay")}</Button>
</Box>
      </Box>
    </Modal>
  </div>
  );
}










