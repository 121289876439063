import { Autocomplete,Box,FormControl,Button, Paper,InputAdornment,InputLabel,OutlinedInput, Typography,TextField,Switch, Divider,Checkbox ,FormGroup,FormControlLabel,IconButton} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React,{useState,useEffect} from 'react'
import "./createUser.css"
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { User_Roles } from '../../graphicalQl/usequery/userProfileUseQuery';
import {t} from "i18next"
import { CREATE_EMPLOYEE,UPDATE_USER } from '../../graphicalQl/mutation/userProfileMutation';
import { LOCATIONS_LIST } from '../../graphicalQl/usequery/userProfileUseQuery';
import { SEARCH_PERMISSIONS_BY_ROLE_ID } from '../../graphicalQl/usequery/userProfileUseQuery';
import { Navigate, useParams } from 'react-router';
import { GET_UPDATE_USER_DETAILS } from '../../graphicalQl/usequery/userProfileUseQuery';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EmplpoyeesList } from '../../graphicalQl/usequery/userProfileUseQuery';
import {
  
  InputBase
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { useNavigate } from 'react-router-dom';







interface usersListState {
  id: number
  userName: any,
  email: string,
  status: string,
  date: string,
  phone: string,
  role: string

}

interface Option{
  id: number|string
  role: string
};

interface Locations{
  id: string;
  status:string
  storeId:string
  storeName:string
  isChecked:boolean
};


const initialValuesOfTextFields={
  firstName:"",
  lastName:"",
  email:"",
  mobile:"",
  role:""

}

interface StatePermissionsSearchByRoleList{
  appliesFor:[]
  name:string
  permissionId:string 

}

function CreateUser() {
  const navigate = useNavigate()

  const {editId}=useParams()

  //Locations List
  const {data:locationsData,loading:locationsLoading,error:locationsError}=useQuery(LOCATIONS_LIST,{context:{clientName:"businessClient"}})
  const [locationsList, seLocationsList] = useState<Locations[]>([]);
  const [initialLocationsList,setInitialLocationsList]=useState<Locations[]>([]);

  useEffect(() => {
    if (locationsData) {
      const requiredDataList:Locations[] = locationsData.storeList.edges.map((each: any) => ({
        status:each.node.status,
        id:each.node.id,
        storeId:each.node.storeId,
        storeName:each.node.storeName,
        isChecked:false
      }
      ))
      seLocationsList(requiredDataList)
      setInitialLocationsList(requiredDataList)
    }
  },[locationsData])

  

  //Accordian settings
  const [allCheckboxChecked, setAllCheckboxChecked] = React.useState(false);
  const [driversList, setDriversList] = React.useState(locationsList);
  const [searchInput,setSearchInput]=React.useState("")

  const searchResults=locationsList.filter((eachLocation)=>eachLocation.storeName.toLowerCase().includes(searchInput))

  const handleSearchIcon=(e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>)=>{
    setSearchInput(e.target.value)

  }

  const checkedCount = driversList.filter((location) => location.isChecked).length;
  const [noOfLocationsSelected, setNoOfLocationsSelected] = React.useState(checkedCount);


  const handlePopupCheckbox= (id: string, isChecked: boolean)  => {
    
    const filteredList = locationsList.map((each) => {
      if (each.storeId === id) {
        return { ...each, isChecked: !each.isChecked };
      }
      return each;
    });

    
    const checkedCount = filteredList.filter((location) => location.isChecked).length;    
    setNoOfLocationsSelected(checkedCount);
    setAllCheckboxChecked(checkedCount === filteredList.length);
    seLocationsList(filteredList);
  };


  const handlePopupCheckbo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const filteredList = locationsList.map((each) => ({ ...each, isChecked: checked }));

    setNoOfLocationsSelected(checked ? filteredList.length : 0);
    setAllCheckboxChecked(checked);
    seLocationsList(filteredList);
  };


   //handle All InputValues
   const [stateValuesOfTextFields,setStateValuesOfTextFields]=React.useState(initialValuesOfTextFields)

   const handleInputChange = (
     event: React.ChangeEvent<HTMLInputElement>
   ) => {
     const { name, value } = event.target;
     setStateValuesOfTextFields((prevState) => ({
       ...prevState,
       [name]:  value,
     }));
   };

  //Getting Update User Details
  
  const [updateUserDetails,setUpdateUserDetails]=useState({})
  const { loading:updateUserLoading, error:updateUserError, data:updateUserData } = useQuery(GET_UPDATE_USER_DETAILS,{context:{clientName:"userClient"},variables:{employeeId:editId}})
  
  useEffect(() => {
    if (updateUserData) {
      if(updateUserData.employee!==null){
        const { employeeId,firstName,lastName,email,locations,status,date,mobile,role} = updateUserData.employee
       if(JSON.parse(locations)!==null){
        const addCheckboxesToTheLocationList=locationsList.map((eachLocation:any)=>{
          if(JSON.parse(locations).includes(parseInt(eachLocation.storeId))){
            return {...eachLocation,isChecked:true}
          }return {...eachLocation,isChecked:true}
        }
        )
        const checkedCount = addCheckboxesToTheLocationList.filter((location) => location.isChecked).length;    
        setNoOfLocationsSelected(checkedCount);
        seLocationsList(addCheckboxesToTheLocationList)

       }
        const {roleName,roleId}=role 
        setStateValuesOfTextFields((prev)=>({...prev,firstName,lastName,email,mobile,role:roleName}))
      }
      }
  },[updateUserData])

  

  
  
  

  //Roles List
  const {data:userRolesData,loading:userRolesLoading,error:userRolesError}=useQuery(User_Roles,{context:{clientName:"userClient"}})
  const [rolesList, seRolesList] = useState<Option[]>([]);
  useEffect(() => {
    if (userRolesData) {
      const requiredDataList:Option[] = userRolesData.roles.edges.map((each: any) => ({
        id:each.node.roleId,
        role:each.node.roleName,
        
      }
      ))
      seRolesList(requiredDataList)
    }
    
    
  
  }, [userRolesData]);


 
  
  


  const [createUser] = useMutation(CREATE_EMPLOYEE, {
    onCompleted: (data) => {
      console.log(data)
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: 'userClient' },
    refetchQueries:[EmplpoyeesList]
  });

   
    
  const handleCreateSaveSubitButton=(event:React.FormEvent<HTMLFormElement>)=>{
    const empty:any=[]
    locationsList.filter((each)=>{
      if(each.isChecked){
        empty.push(each.storeId)
      }
    })

    

    
    event.preventDefault() 
      createUser({variables:{
        businessId:2,
        firstName:stateValuesOfTextFields.firstName,
        lastName:stateValuesOfTextFields.lastName,
        email:stateValuesOfTextFields.email,
        mobile:stateValuesOfTextFields.mobile,
        role:+roleId,
        locations:JSON.stringify(empty)
        
      }})
      setStateValuesOfTextFields(initialValuesOfTextFields)
      setRoleName("")
      setRoleId("")
      seLocationsList(initialLocationsList)
      setAllCheckboxChecked(false);
      setNoOfLocationsSelected(0)
      navigate("/user-list")
  
    }

    
  

    //Update User Function
  

    const [updateUser] = useMutation(UPDATE_USER, {
      onCompleted: (data) => {
        console.log(data)
      },
      onError: (error) => {
        // Handle any errors here, e.g., display an error message.
        console.error("Mutation error:", error);
      },
      context: { clientName: 'userClient' },
      refetchQueries:[EmplpoyeesList]
    });

    const handleEditSaveSubitButton=(event:React.FormEvent<HTMLFormElement>)=>{
      event.preventDefault() 
      const empty:any=[]
    locationsList.filter((each)=>{
      if(each.isChecked){
        empty.push(each.storeId)
      }
    })
      if(editId!==undefined){
        updateUser({variables:{
          businessId:2,
          employeeId:+editId ,
          firstName:stateValuesOfTextFields.firstName,
          lastName:stateValuesOfTextFields.lastName,
          email:stateValuesOfTextFields.email,
          mobile:stateValuesOfTextFields.mobile,
          role:+roleId,
          locations:JSON.stringify(empty)   
        }})
  
        setStateValuesOfTextFields(initialValuesOfTextFields)
        setRoleName("")
        setRoleId("")
        seLocationsList(initialLocationsList)
        setAllCheckboxChecked(false);
        setNoOfLocationsSelected(0)
        navigate("/user-list")
      }
      
    }
    
    


  //Automcomplete functions
  const [roleName, setRoleName] = useState<string>('');
  const [roleId, setRoleId] = useState<number |string>("");
  

  const [locationName, setLocationName] = React.useState<string|"">("");
  const [locationId, setLocationId] = React.useState<string|"">("");
  const [permissionsSearchByRoleList,setPermissionsSearchByRoleList]=useState<StatePermissionsSearchByRoleList[]>([])

  
  const {data:permissionsSearchByRoleData,loading:loadingPermissionsSearchByRoleData,error:errorPermissionsSearchByRoleData}=useQuery(SEARCH_PERMISSIONS_BY_ROLE_ID,{context:{clientName:"userClient"},variables:{roleId:roleId}})
  
  
  useEffect(() => {

    if (permissionsSearchByRoleData) {
        if (permissionsSearchByRoleData.role!==null && permissionsSearchByRoleData!==undefined){
            const requiredDataList = permissionsSearchByRoleData.role.permissions.map((each: any) => ({
            appliesFor:each.appliesFor,
            permissionId:each.permissionId,
            name:each.name  
    }))
    setPermissionsSearchByRoleList(requiredDataList)
    }  
    }
  }, [permissionsSearchByRoleData]);
  

  const [posPermissionList, setPosPermissionList] = useState<any>([])
  const [adminPermissionList, setAdminPermissionList] = useState<any>([])

  const gettingPermissionsListSeperate=()=>{

    const emptyPosPermissionList:any=[]
    const emptyAdminPermissionList:any=[]

    permissionsSearchByRoleList.map((eachPermission:any)=>{
      const {appliesFor}=eachPermission   
      if(JSON.parse(appliesFor).length===1 && JSON.parse(appliesFor)[0]==="pos"){
          emptyPosPermissionList.push(eachPermission)
      }else if (JSON.parse(appliesFor).length===1 && JSON.parse(appliesFor)[0]==="admin"){
        emptyAdminPermissionList.push(eachPermission)
        
      }else{
        emptyPosPermissionList.push(eachPermission)
        emptyAdminPermissionList.push(eachPermission)
      }
      
    })

    setPosPermissionList(emptyPosPermissionList)
    setAdminPermissionList(emptyAdminPermissionList)
  }
  useEffect(()=>{
    gettingPermissionsListSeperate()
  },[permissionsSearchByRoleList])





  


  const handleRoleChange = (event: React.ChangeEvent<{}>, newValue: Option | null) => {
    setRoleName(newValue ? newValue.role : '');
    setRoleId(newValue ? newValue.id : "");
  };

  const handleClearButton=()=>{
    setStateValuesOfTextFields(initialValuesOfTextFields)
    setRoleName("")
    setRoleId("")
    seLocationsList(initialLocationsList)
    setAllCheckboxChecked(false);
    setNoOfLocationsSelected(0)

  }

  

   
  
  return (
    <Box sx={{backgroundColor:"#f9f9f9",padding:"20px"}} className="create-user-bg-container">

      <form  onSubmit={editId===undefined?handleCreateSaveSubitButton:handleEditSaveSubitButton}>

      <Box className='create-a-use-role-header-order' sx={{width:"100%",padding:"15px",backgroundColor:"#fff"}}>
            <IconButton className='create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover'>
            <ArrowBackIcon
            style={{backgroundColor:"#EEEEEE",color:"#333333"}}/>
            </IconButton>
            <Typography className='create-a-use-role-heading'>{editId===undefined?t("create_a_user.create_a_user"):"Edit a User"}</Typography>
            <Box className='create-a-use-role-header-buttons-order'>
                {editId===undefined && <Button sx={{textTransform:"none"}} onClick={handleClearButton} className='create-a-use-role-clear-button' variant='outlined' color='warning'>{t("create_a_user.clear")}</Button>}
                <Button type='submit' sx={{textTransform:"none"}} variant='contained' color='warning'>{t("create_a_user.save")}</Button>
            </Box>     
      </Box>



      <Box sx={{display:"flex",zIndex:"1",alignItems:"flex-start",padding:"20px"}}>   
      <Paper elevation={1} sx={{width:"75%",maxHeight:"42vh",zIndex:"10",padding:"15px",borderLeft:"2px solid #AE9BE2",borderRadius:"5px",marginTop:"15px"}}>
        <Box>
        <Typography className='create-user-details-heading'>Details</Typography>
        </Box>
        <Box style={{padding:"10px"}}>
            <Box className="create-user-inputs-order">
          <TextField
            onChange={handleInputChange}
            name="firstName"
            value={stateValuesOfTextFields.firstName}
            color='warning'
            id="demo-helper-text-misaligned"
            label={<Typography className='create-a-use-role-label'>{t("create_a_user.first_name")}</Typography>}
            style={{width:"40%",margin:"10px"}}
          
          />
          <TextField
            onChange={handleInputChange}
            value={stateValuesOfTextFields.lastName}
            name="lastName"
            color='warning'
            id="demo-helper-text-misaligned"
            label={<Typography className='create-a-use-role-label'>{t("create_a_user.last_name")}</Typography>}
            style={{width:"40%",margin:"10px"}}
          />
          
          </Box>
          <Box className="create-user-inputs-order">
          <TextField
            onChange={handleInputChange}
            value={stateValuesOfTextFields.email}
            name="email"
            color='warning'
            id="demo-helper-text-misaligned"
            label={<Typography className='create-a-use-role-label'>{t("create_a_user.email_address")}</Typography>}
            style={{width:"40%",margin:"10px"}}
          />
          <TextField
            onChange={handleInputChange}
            value={stateValuesOfTextFields.mobile}
            name="mobile"
            color='warning'
            id="demo-helper-text-misaligned"
            label={<Typography className='create-a-use-role-label'>{t("create_a_user.phone_number")}</Typography>}
            style={{width:"40%",margin:"10px"}}
          />

          </Box>
        <Box className="create-user-inputs-order-auto-complete" sx={{marginTop:"10px",padding:"10px",width:"84%"}}>     
        <Autocomplete
        getOptionLabel={(option: Option) => option.role}
        onChange={handleRoleChange}
        id="combo-box-demo"

        value={{ role: roleName,id:roleId }}
        options={rolesList}
        sx={{ width: "49%"}}
        renderInput={(params) => <TextField  color='warning' {...params} label={<Typography className='create-a-use-role-label'>{t("create_a_user.assign_role")}</Typography>} />}
      />   

      <Box>
        
       <Accordion elevation={1} sx={{width:"25vw"}}>
        <AccordionSummary
        sx={{width:"100%",marginBottom:0}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        
        >
          <Typography sx={{marginBottom:"0px"}}>{noOfLocationsSelected} {noOfLocationsSelected<2?"Location":"Locations"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
          <Paper
            elevation={1}
            component="form"
            sx={{
            
              display: "flex",
              alignItems: "center",
              width: "100%"
            }}
          >
            <IconButton type="button" sx={{ p: "10px" }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              value={searchInput}
              onChange={(e)=>handleSearchIcon(e)}
              sx={{ ml: 1, flex: 1 }}
              //@ts-ignore
              // placeholder={t("driver_commission.search_by_driver_name")}
              inputProps={{ "aria-label": "search google maps" }}
            />
          </Paper>
          </Box>
         <Box sx={{height:"37vh",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
         <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection:"column",
              alignItems: "flex-start",
              overflowY:"auto",
              maxHeight:"32vh",
              marginTop:"10px",
              minHeight:"10vh"

            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allCheckboxChecked}
                    onChange={(e) => handlePopupCheckbo(e)}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    color="warning"
                  />
                }
                label={
                  <Typography className="driver-petty-cash-popup-checkbox">
                    All
                  </Typography>
                }
              />
              {searchResults.map((each) => (
                <FormControlLabel
                  key={each.id}
                  control={
                    <Checkbox
                      onChange={() => {
                        handlePopupCheckbox(each.storeId, each.isChecked);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      color="warning"
                      checked={each.isChecked}
                    />
                  }
                  label={
                    <Typography className="driver-petty-cash-popup-checkbox">
                      {each.storeName}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </Box>
         </Box>
        </AccordionDetails>
      </Accordion>
      </Box>
       
        </Box>

        </Box>
      </Paper>
      </Box> 
      </form>

      <Box sx={{display:"flex",justifyContent:"center",alignItems:"flex-start",flexDirection:"column",padding:"20px",width:"80%"}}>
      <Paper elevation={1} sx={{padding:"15px",borderRadius:"5px",width:"80%",borderLeft:"2px solid #BAE29B",marginTop:"35px"}}>
      <Box>
        <Typography className='create-user-second-table-heading'>
        {t("create_a_user.user_access_permissions_for_assigned_role")}
        </Typography>
      </Box>
      <div style={{display:"flex",justifyContent:"space-around",alignItems:"flex-start",marginTop:"20px",width:"100%"}}>
        
        <Paper elevation={0} sx={{width:"45%",padding:"15px",border:"1px solid #ABABAB"}}>
          <div style={{display:"flex", justifyContent:"space-between",padding:"10px", alignItems:"center"}}>
            <div>
              <Typography className='create-user-checkbox-heading' >{t("create_a_user_role.pos")}</Typography>
              <Typography className='create-user-checkbox-paragraph'>{t("create_a_user_role.users_can_log")}</Typography>
            </div>
            
          </div>
          <Divider/>

          <FormGroup sx={{padding:"10px"}}>
          {posPermissionList.map((each:any)=>(
            <FormControlLabel 
          
            defaultChecked
            checked
            control={<Checkbox disabled color='warning'/>} label={<Typography className='create-user-checkbox-label'>{each.name}</Typography>}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} 
            />
          ))}

          </FormGroup>
          
        </Paper>
    
      
        <Paper elevation={0} sx={{width:"45%",border:"1px solid #ABABAB"}}>
          <div style={{display:"flex", justifyContent:"space-between",padding:"10px", alignItems:"center"}}>
            <div>
              <Typography className='create-user-checkbox-heading' >{t("create_a_user_role.back_office")}</Typography>
              <Typography className='create-user-checkbox-paragraph'>{t("create_a_user_role.users_can_log_into_admin")}</Typography>
            </div>
            
          </div>

          <Divider/>
          <FormGroup sx={{padding:"10px"}}>
          {adminPermissionList.map((each:any)=>(
            <FormControlLabel 
            defaultChecked 
            checked
            control={<Checkbox disabled color='warning'/>} label={<Typography className='create-user-checkbox-label'>{each.name}</Typography>} 
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} 
            />
          ))}
          </FormGroup>
      
        </Paper>
    
      </div>


    <Box sx={{textAlign:"right",margin:"15px"}}>
      <Button color='warning' variant='text'>{t("create_a_user.view_all_permissions")}</Button>
    </Box>
      </Paper>
      </Box>
    </Box>
  )
}

export default CreateUser
