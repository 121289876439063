import { Box, Typography } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './menuHistory.css'
import { DataGrid, GridColDef, } from '@mui/x-data-grid';
import { t } from "i18next";


const MenuHistory = () => {
    const columns: GridColDef[] = [
        {
            field: 'variations',
            //@ts-ignore
            headerName: t("menu.menuHistory.variation"),
            flex: 1,
            headerClassName: 'menuHistory-header'

        },

        {
            field: 'note',
            //@ts-ignore
            headerName: t("menu.menuHistory.none"),
            flex: 1,
            headerClassName: 'menuHistory-header'
        },
        {
            field: 'date',
            //@ts-ignore
            headerName: t("menu.menuHistory.date"),
            headerClassName: 'menuHistory-header',
            flex: 1,
        },
        {
            field: 'employee',
            //@ts-ignore
            headerName: t("menu.menuHistory.employee"),

            sortable: false,

            flex: 1,
            headerClassName: 'menuHistory-header'
        },
        {
            field: 'useThis',
            //@ts-ignore
            headerName: t("menu.menuHistory.useThis"),

            flex: 1,
            headerClassName: 'menuHistory-header'
        },
    ];

    const rows = [
        { id: 1, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
        { id: 2, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
        { id: 3, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
        { id: 4, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
        { id: 5, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
        { id: 6, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
        { id: 7, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
        { id: 8, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
        { id: 9, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
    ];
    const getCellClass = () => {
        return 'menuHistory-cell'
    }

    const getRowClass = () => {
        return 'menuHistory-row'
    }
    return (
        <Box sx={{ padding: "1%" }}>
            <Box className="menuHistory">
                <ArrowBackIcon />
                <Typography className="menuHistory-text">{t("menu.menuHistory.menuHistory")}</Typography>
            </Box>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    getCellClassName={getCellClass}
                    getRowClassName={getRowClass}
                    disableRowSelectionOnClick
                />
            </Box>
        </Box>
    )
}
export default MenuHistory