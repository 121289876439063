import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Typography, Button, Checkbox, FormControlLabel, InputBase, IconButton} from "@mui/material";
import '../options/createProduct.css'
import './createCategory.css'
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CloseOutlined} from '@mui/icons-material';
import SearchIcon from "@mui/icons-material/Search";
import { t } from 'i18next';
import { GET_PRODUCT } from '../../graphicalQl/usequery/categoryListQuery';
import { useMutation, useQuery } from '@apollo/client';
import { CATEGORY_LIST } from '../../graphicalQl/usequery/categoryListQuery';
import { ASSIGN_PRODUCTS } from '../../graphicalQl/mutation/mutation';


import { EDIT_CATEGORY } from '../../graphicalQl/usequery/categoryListQuery';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
   
    boxShadow: 24,
    p: 4,
  };

  interface Data{
    id:number;
    product:string;
    price:string;
    isChecked:boolean;
  }

  interface Product{
    
    
    node:{
      productId: number,
        name: string,
        price: string,
    }
}

interface Props{
    open:  boolean;
    upDateOpen:()=> void;
    categoryId:string|undefined;
   
}
const AssignProducts=(props:Props)=>{
    
    const {open,upDateOpen,categoryId}=props
  
    
    const [open1, setOpen] = React.useState<boolean>(open);
    useEffect(() => {
        setOpen(open);
      }, [open]);

    const handleClose = () => {
        setOpen(false)
        upDateOpen()
    };
    const [productList, setProductList] = useState<Product[]>([]);
    const [upDatedProductData, setProductData] = useState<Data[]>([]);

const { data } = useQuery(GET_PRODUCT,{ context: { clientName: 'categoryClient' }});


useEffect(() => {
  if (data) {
    setProductList(data.productList.edges);
    
  }
}, [data]);

useEffect(() => {
  const productUpdatedList = productList.map((item) => ({
    id: item.node.productId,
    product: item.node.name,
    price: item.node.price,
    isChecked: false,
  }));
  setProductData(productUpdatedList); 
}, [productList])

    const[searchValue,setSearchValue]=useState<string>("")
    const handleSearch=(event:ChangeEvent<HTMLInputElement>)=>{
        setSearchValue(event.target.value)
    }

    const searchedResult=upDatedProductData.filter((each)=>(
        each.product.toLowerCase().includes(searchValue.toLowerCase())
      ))
      const handleCheckboxChange = (id:number) => {
        setProductData((prevProductData) =>
          prevProductData.map((item) =>
            item.id === id ? { ...item, isChecked: !item.isChecked } : item
          )
        );
      };

      let tableData=upDatedProductData.filter((each)=>(
        each.isChecked===true
      ))




const checkedId = tableData.map((each) => {
  return each.id;
});

      
      const [assignProducts] = useMutation(ASSIGN_PRODUCTS, {
        onCompleted: (data) => {
          if(data){
            
          }
         
        },
        onError: (error) => {
          // Handle any errors here, e.g., display an error message.
          
        }, 
        
        context: { clientName: 'categoryClient' },
        refetchQueries: [
           EDIT_CATEGORY , // Specify the query using an object with a 'query' property
           CATEGORY_LIST ,
        ],
      });
      
      const handleAssignProducts=()=>{
       assignProducts({
  variables: {
    categoryId: categoryId,
    products: JSON.stringify(checkedId),
  },
 
});
      }

   

    
    return(
<Modal
        open={open1}
        
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div style={{ display: "flex", alignItems: "center", padding: "1%",width:'100%' }}>
        <IconButton className="create-product-exit" onClick={handleClose}>
          <CloseOutlined className="create-product-exitIcon" />
        </IconButton>
        <Typography style={{marginLeft:"40%"}} className='createCategory-select-product'>{t("menu.catageory.selectProducts")}</Typography>
       
      </div>
      <Box sx={{width:"100%",backgroundColor:"#EEEEEE",padding:"2%",textAlign:"center"}}>
        <Typography className='createCategory-para'>{t("menu.catageory.selectPara")}</Typography>
      </Box>
      <Box
      component="form"
      sx={{ display: "flex", alignItems: "center", marginTop: "2%", width: "100%" }}
      className='product-search'
    >
      <IconButton type="button" aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        //@ts-ignore
      placeholder={t("menu.catageory.searchByName")}
        value={searchValue}
        name='search'
        
        onChange={handleSearch}
      />
    </Box>
   

    <TableContainer component={Box} style={{maxHeight:'50vh'}}>
      <Table sx={{ Width: '80%'}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className='createCategory-table-head'>{t("menu.catageory.allProducts")}</TableCell>
            <TableCell className='createCategory-table-head'>{t("menu.catageory.price")}</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {searchedResult.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <FormControlLabel control={<Checkbox color='warning' />}  checked={row.isChecked}
            onChange={() => handleCheckboxChange(row.id)}
            label={<Typography className='createCategory-row'>{row.product}</Typography>}/>
                
              </TableCell>
              <TableCell className='createCategory-row2'>{row.price}</TableCell>
            
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button className='createCategory-button' onClick={() => {
  handleClose();
  handleAssignProducts();
  
}}
 sx={{textTransform:"none"}}>{t("menu.catageory.assignProducts2category")}</Button>
        </Box>

      </Modal>
    )
}
export default AssignProducts