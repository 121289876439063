import { gql } from "@apollo/client";

export const Delete_Employee=gql`
mutation deleteEmployee(
  $employeeIds: JSONString!
) {
  deleteEmployee(employeeIds: $employeeIds) {
    message
    success
  }
}

`

export const DELETE_ROLE=gql`
mutation DeleteRole($roleId: ID!) {
  deleteRole(roleId: $roleId) {
    message
    role {
      roleId
    }
    success
  }
}

`

export const CREATE_EMPLOYEE=gql`
mutation CreateEmployee(
    $businessId: Int!,
    $role: Int!,
    $firstName: String,
    $lastName: String,
    $email: String,
    $mobile: String,
    $locations: JSONString!
  ) {
    createEmployee(
      businessId: $businessId,
      role: $role,
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      mobile: $mobile,
      locations: $locations
    ) {
      errorMessage
      employee {
        employeeId
      }
      success
    }
  }

`

export const createUserRole=gql`
mutation createUserRole(
  $businessId: Int!,
  $roleName: String,
  $permissions: JSONString!
) {
  createRole(
    businessId: $businessId,
    roleName: $roleName,
    permissions: $permissions
  ) {
    errorMessage
    role {
      roleId
    }
    success
  }
}
`

export const USER_ACCESS=gql`
mutation ChangeEmployeePermissions(
  $employeeId: Int!,
  $permissions: JSONString
) {
  changeEmployeePermissions(
    employeeId: $employeeId,
    permissions: $permissions,
    businessId:2
  ) {
    message
    success
  }
}

`

export const UNBLOCK_EMPLOYEE=gql`
mutation UnblockEmployee($employeeId: ID!) {
  unblockEmployee(employeeId: $employeeId) {
    message
    success
  }
}

`

export const UPDATE_ROlE=gql`
mutation UpdateRole($roleId: Int!, $roleName: String, $permissions: JSONString) {
  updateRole(
    roleId: $roleId,
    roleName: $roleName,
    permissions: $permissions
  ) {
    errorMessage
    role {
      roleId
    }
    success
  }
}

`


export const UPDATE_USER=gql`
mutation UpdateEmployee($businessId:Int!,$employeeId:Int!, $firstName: String, $lastName: String, $email: String, $mobile: String,$role:Int,$locations:JSONString) {
  updateEmployee(
    businessId:$businessId
    employeeId: $employeeId,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    mobile: $mobile,
    role:$role,
    locations:$locations

  ) {
    errorMessage
    employee {
      employeeId
    }
    success
  }
}

`




  