import * as React from "react";
import { useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useTranslation } from "react-i18next";
import UploadImage from "../utils/uploadImage";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useForm, Controller } from "react-hook-form";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface Clear {
  clearImage: () => void;
}
export default function AddOption(props: { open: boolean; setOpen: any }) {
  const { open, setOpen } = props;
  const [image, setImage] = useState("");
  const clear = useRef<Clear>();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const { handleSubmit, control, reset, formState } = useForm({
    defaultValues: {
      name: "",
      price: "",
    },
  });
  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ name: "", price: "" });
    }
  }, [formState, reset]);

  const onSubmit = (data: any) => {
    clear.current?.clearImage();
    console.log(data);
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={"sm"}
        fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>&nbsp;</DialogTitle>
        <DialogContent>
          <DialogContentText id="add-option">
            <div className="optionCreationContainer">
              <UploadImage image={image} setImage={setImage} ref={clear} />
              <div style={{ width: "100%" }}>
                <form
                  style={{ width: "100%" }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormControl
                    style={{ width: "100%" }}
                    sx={{ m: 1 }}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="standard-adornment-name">
                      {t("menu.create.name")}
                    </InputLabel>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => <Input {...field} />}
                    />
                  </FormControl>
                  <FormControl
                    style={{ width: "100%" }}
                    sx={{ m: 1 }}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="standard-adornment-business-owner">
                      {t("menu.create.price")}
                    </InputLabel>
                    <Controller
                      name="price"
                      control={control}
                      render={({ field }) => <Input {...field} />}
                    />
                  </FormControl>
                  <br />
                  <br />
                  <div style={{ float: "right" }}>
                    <Button
                      onClick={() => {
                        clear.current?.clearImage();
                        reset({ name: "", price: "" });
                      }}
                    >
                      {t("buttons.clear")}
                    </Button>
                    <Button type="submit">{t("buttons.save")}</Button>
                  </div>
                </form>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
