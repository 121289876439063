import { Box, Paper, Typography,TextField,Checkbox,FormControl, Switch, IconButton } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react'

import "./cartCrossSellingLeftPart.css"
import { string } from 'prop-types';



const daysOfWeek=[
    {
        id:1,
        day:"Monday"
    },
    {
        id:2,
        day:"Tuesday"
    },
    {
        id:3,
        day:"Wednesday"
    },
    {
        id:4,
        day:"Thursday"
    },
    {
        id:5,
        day:"Friday"
    },
    {
        id:6,
        day:"Saturday"
    },
    {
        id:7,
        day:"Sunday"
    }
]

const menuList=[
  "Biryani",
  "Pizza",
  "Burger"
]

function CartCrossSellingLeftPart() {

  //days checkboxes functions

    const [openValues, setOpenValues] = React.useState<any>({});
    const [closeValues, setCloseValues] =  React.useState<any>({});
    const [checkedDays, setCheckedDays] =  React.useState({});
    
    const [schedule, setSchedule] =React.useState<any>({});
    const [selectedDay, setSelectedDay] = React.useState<string|null>(null);
    console.log(openValues)
    console.log(closeValues)
    console.log(checkedDays)
    console.log(schedule)
    console.log(selectedDay)

  const handleOpenTimeChange = (day:string, newValue:any) => {
    setOpenValues((prevValues:any) => ({
        ...prevValues,
        [day]: newValue,
    }));
    if (newValue && closeValues[day]) {
        setCheckedDays((prevCheckedDays) => ({
            ...prevCheckedDays,
            [day]: true,
        }));
    }
};



const handleCloseTimeChange = (day:string, newValue:any) => {
    setCloseValues((prevValues:any) => ({
        ...prevValues,
        [day]: newValue,

    }));

    if (newValue && openValues[day]) {
        setCheckedDays((prevCheckedDays) => ({
            ...prevCheckedDays,
            [day]: true,
        }));
    }
};

const handleDayCheckboxChange = (day:string, isChecked:boolean) => {
    setCheckedDays((prevCheckedDays) => ({
        ...prevCheckedDays,
        [day]: isChecked,
    }));

    if (!isChecked) {
        setOpenValues((prevValues:any) => ({
            ...prevValues,
            [day]: '',
        }));
        setCloseValues((prevValues:any) => ({
            ...prevValues,
            [day]: '',
        }));
    }
};

const handleTimeChange = (day:string, type:any, newValue:any) => {
    const updatedSchedule = {
        ...schedule,
        [day]: {
            ...schedule[day],
            [type]: newValue,
        },
    };
    setSchedule(updatedSchedule);

    if (newValue && updatedSchedule[day].open && updatedSchedule[day].close) {
        setSelectedDay(day);
    } else if (selectedDay === day) {
        setSelectedDay(null);
    }
};

  //Cross Sell schedule functions

  const [crossSellScheduleStatus,setCrossSellScheduleStatus]=React.useState(false)

  const handleCrossSellScheduleStatus=()=>{
    setCrossSellScheduleStatus(!crossSellScheduleStatus)
  }

  //Cross Icon Functions
  const [closeIconState,setCloseIconState]=React.useState(false)

  //Cross Sell schedule functions

  const [crossSellavailabilityStatus,setCrossSellAvailabilityStatus]=React.useState(false)

  const handleCrossSellAvailabilityStatus=()=>{
    setCrossSellAvailabilityStatus(!crossSellavailabilityStatus)
  }

  const handleCheckboxChange=(id:number)=>{
    console.log(id)
  }



  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date); // Update the selectedDate state with the chosen date
};
  return (
    <Box sx={{width:"50%"}}>
    <Box>
        <Paper elevation={1} sx={{padding:"10px",borderLeft:"1px solid #AE9BE2",marginTop:"15px"}}>
            <Typography className='cart-cross-selling-left-part-details-text'>Details</Typography>
            <Box sx={{display:"flex", flexDirection:"column"}}>
             <TextField
              label={<Typography className='cart-cross-selling-left-input-label'>Internal Name</Typography>}  
               color='warning'
                variant="outlined"
                 sx={{width:"35%",marginTop:"10px",marginBottom:"10px"}} />
             <TextField
              label={<Typography className='cart-cross-selling-left-input-label'>Title</Typography>}
               color='warning'
               variant="outlined"
               sx={{width:"50%",marginTop:"10px",marginBottom:"10px"}} />

            <Autocomplete
                  
                  id="combo-box-demo"
                  options={menuList}
                  sx={{ width: "35%",marginTop:"10px",marginBottom:"10px" }}
                    
                  renderInput={(params) => <TextField color='warning' {...params} label={<Typography className='cart-cross-selling-left-input-label'>Select Menu</Typography>} />}
                />

            </Box>
        </Paper>
    </Box>

    <Box sx={{marginTop:"20px"}}>
        <Paper elevation={1} sx={{padding:"15px",borderLeft:"1px solid #E2A39B"}}>
          <Box className="cart-cross-selling-left-box-border">
          <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"flex-start",width:"37%"}} >
          <IconButton onClick={handleCrossSellScheduleStatus}>
             <Switch color='warning'/>
           </IconButton>
          <Box>   
             <Typography className='cart-cross-selling-left-part-details-text'>Cross Sell Schedule</Typography>
             <Typography className='cart-cross-selling-left-part-set-date-text'>Set date range</Typography>
          </Box>
          </Box>

          {crossSellScheduleStatus && (
            <Box sx={{display:"flex",justifyContent:"space-around",alignItems:"center",width:"100%",marginTop:"10px"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer sx={{width:"120%"}} components={['DatePicker']}>
                    <DatePicker
                    sx={{width:"90%"}}
                        value={selectedDate}
                        onChange={handleDateChange}
                        label="Start date" />
                </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer sx={{width:"120%"}} components={['DatePicker']}>
                    <DatePicker
                       sx={{width:"90%"}}
                        value={selectedDate}
                        onChange={handleDateChange}
                        label="End date" />
                </DemoContainer>
            </LocalizationProvider>
            </Box>
          )}

          </Box>

         <Box className="cart-cross-selling-left-box-border" sx={{marginTop:"15px"}}>
         <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"flex-start",width:"40%",marginTop:"10px",marginBottom:"10px"}} >
          <IconButton onClick={handleCrossSellAvailabilityStatus}>
             <Switch color='warning'/>
           </IconButton>
          <Box>   
             <Typography className='cart-cross-selling-left-part-details-text'>Cross Sell Availability</Typography>
             <Typography className='cart-cross-selling-left-part-set-date-text'>Select items to be offered to guests</Typography>
          </Box>
          </Box>
          {crossSellavailabilityStatus && (
            <Box sx={{width:"100%",padding:"10px"}}>
            {daysOfWeek.map((each, index) => (
                <Box key={each.id} sx={{width:"90%",paddingRight:"10px",margin:"5px",display:"flex",justifyContent:"space-between",alignItems:"center",borderRadius: "6px",border:"1px solid #EEE"}}>
                    
                        <Box className="cart-cross-selling-left-input-label-bg">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox
                                sx={{width:"13.5"}}
                                    color="warning"
                                    onChange={()=>{handleCheckboxChange(each.id)}}
                                    // checked={checkedDays[each.day] || false}
                                    // onChange={(event) => handleDayCheckboxChange(each.day, event.target.checked)}
                                />
                                <Typography className='cart-cross-selling-left-checkbox-label'>{each.day}</Typography>
                            </div>
                        </Box>

                        <Box>
                            <FormControl sx={{ m: 1 }} variant="standard" focused>
                                <Typography style={{ marginBottom: "4px" }} className='createMinimum_para'>open</Typography>
                                <TextField
                                    onChange={()=>{handleCheckboxChange(each.id)}}
                                    variant="standard"
                                    type='time'
                                    className="createDiscount_timeInput"
                                    id={`standard-adornment-${each.day}-start`}


                                    // value={openValues[each.day] || ''}
                                    // onChange={(event) => {
                                    //     handleOpenTimeChange(each.day, event.target.value);
                                    //     handleTimeChange(each.day, 'open', event.target.value);
                                    // }}


                                    InputProps={{
                                        disableUnderline: true, // This removes the underline
                                    }}
                                />
                            </FormControl>
                        </Box>

                        <Box>
                            <FormControl sx={{ m: 1 }} variant="standard" focused>
                                <Typography style={{ marginBottom: "4px" }} className='createMinimum_para'>close</Typography>
                                <TextField
                                    onChange={()=>{handleCheckboxChange(each.id)}}
                                    variant="standard"
                                    type='time'
                                    className="createDiscount_timeInput"
                                    id={`standard-adornment-${each.day}-end`}

                                    // value={closeValues[day] || ''}
                                    // onChange={(event) => {
                                    //     handleCloseTimeChange(each.day, event.target.value)
                                    //     handleTimeChange(each.day, 'close', event.target.value);
                                    // }}
                                    
                                    InputProps={{
                                        disableUnderline: true, // This removes the underline
                                    }}
                                />
                            </FormControl>
                        </Box>

                        {
                            closeIconState && (
                                <Box>
                            <IconButton onClick={()=>{handleCheckboxChange(each.id)}}>
                            <CloseIcon/>
                            </IconButton>
                        </Box>
                            )
                        }  
                    
                </Box>
            ))}



       </Box>
          )}
         </Box>


        
            
          

            
        </Paper>
    </Box>
    </Box>
    
  )
}

export default CartCrossSellingLeftPart
