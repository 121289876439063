import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { Paper, Typography, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './createDiscount.css';
import imageAvtar from '../../assets/image-avatar.png';
import { Radio, RadioGroup, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import { t } from "i18next";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

import { Switch, IconButton } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

const CreateDiscountCustomerGroup = () => {
    const [discountName, setDiscountName] = useState('');
    const [couponCode, setCouponcode] = useState('');
    const [limitedStock, setLimitedStock] = useState('');
    const [minAmount, setMinAmount] = useState('');
    const [maxDiscountAmount, setmaxDiscountAmount] = useState('');


    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedPromotion, setSelectedPromotion] = useState('');
    const [selectedMarkDiscount, setSelectedMarkDiscount] = useState('');

    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value);
    };

    const handleMarkDiscount = (event) => {
        setSelectedMarkDiscount(event.target.value);
    };
    const handlePromotion = (event) => {
        setSelectedPromotion(event.target.value);
    };

    const [selectedDiscountType, setSelectedDiscountType] = useState('');
    const [discountAmount, setDiscountAmount] = useState('');

    const handleDiscountTypeChange = (event) => {
        setSelectedDiscountType(event.target.value);
    };
    const [checkboxes, setCheckboxes] = useState({
        DineIn: false,
        Collection: false,
        Delivery: false,
        All: false,
    });

    const handleCheckboxChange = (event) => {
        setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
    };

    const [checkboxes1, setCheckboxes1] = useState({
        Epos: false,
        Online: false,
        Mobile: false,
        All1: false,
    });

    const handleCheckboxChange1 = (event) => {
        setCheckboxes1({ ...checkboxes1, [event.target.name]: event.target.checked });
    };

    const [checkboxes2, setCheckboxes2] = useState({
        Zone1: false,
        Zone2: false,
        Zone3: false,
        All2: false,
    });

    const handleCheckboxChange2 = (event) => {
        setCheckboxes2({ ...checkboxes2, [event.target.name]: event.target.checked });
    };

    const [checkboxes3, setCheckboxes3] = useState({
        card: false,
        cash: false,
        onlinePay: false,
        All3: false,
    });

    const handleCheckboxChange3 = (event) => {
        setCheckboxes3({ ...checkboxes3, [event.target.name]: event.target.checked });
    };

    const [selectedValue, setSelectedValue] = useState('');



    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const [selectedValue1, setSelectedValue1] = useState('');



    const handleRadioChange1 = (event) => {
        setSelectedValue1(event.target.value);
    };

    const [minCartValue, setMinCartValue] = useState('');






    const [setMinimumSpend, setChecked] = useState(false);

    const handleChangesetMinimumSpend = () => {
        setChecked(!setMinimumSpend);
    }



    const [setdateRange, setChecked5] = useState(false);

    const handleChangesetSetDate = () => {
        setChecked5(!setdateRange);
    }

    const [applyToAll, setApplyToAll] = useState(false);

    const handleChangeApplyToAll = () => {
        setApplyToAll(!applyToAll);
    }
    const [maxDiscount, setMaxDiscount] = useState(false);

    const handleMaxDiscount = () => {
        setMaxDiscount(!maxDiscount);
    }
    const [minSpend, setMinSpend] = useState(false);

    const handleMinSpend = () => {
        setMinSpend(!minSpend);
    }






    const [openValues, setOpenValues] = useState({});
    const [closeValues, setCloseValues] = useState({});
    const [checkedDays, setCheckedDays] = useState({});


    const handleOpenTimeChange = (day, newValue) => {
        setOpenValues((prevValues) => ({
            ...prevValues,
            [day]: newValue,
        }));
        if (newValue && closeValues[day]) {
            setCheckedDays((prevCheckedDays) => ({
                ...prevCheckedDays,
                [day]: true,
            }));
        }
    };



    const handleCloseTimeChange = (day, newValue) => {
        setCloseValues((prevValues) => ({
            ...prevValues,
            [day]: newValue,

        }));

        if (newValue && openValues[day]) {
            setCheckedDays((prevCheckedDays) => ({
                ...prevCheckedDays,
                [day]: true,
            }));
        }
    };

    const handleDayCheckboxChange = (day, isChecked) => {
        setCheckedDays((prevCheckedDays) => ({
            ...prevCheckedDays,
            [day]: isChecked,
        }));

        if (!isChecked) {
            setOpenValues((prevValues) => ({
                ...prevValues,
                [day]: '',
            }));
            setCloseValues((prevValues) => ({
                ...prevValues,
                [day]: '',
            }));
        }


    };



    const handleClearRow = (day) => {
        setCheckedDays((prevCheckedDays) => ({
            ...prevCheckedDays,
            [day]: false,
        }));
        setOpenValues((prevValues) => ({
            ...prevValues,
            [day]: '',
        }));
        setCloseValues((prevValues) => ({
            ...prevValues,
            [day]: '',
        }));
    };

    const [schedule, setSchedule] = useState({});
    const [selectedDay, setSelectedDay] = useState(null);

    const handleTimeChange = (day, type, newValue) => {
        const updatedSchedule = {
            ...schedule,
            [day]: {
                ...schedule[day],
                [type]: newValue,
            },
        };
        setSchedule(updatedSchedule);

        if (newValue && updatedSchedule[day].open && updatedSchedule[day].close) {
            setSelectedDay(day);
        } else if (selectedDay === day) {
            setSelectedDay(null);
        }
    };
    const [addedRows, setAddedRows] = useState({});

    const handleAddRow = (day) => {
        setAddedRows((prevAddedRows) => ({
            ...prevAddedRows,
            [day]: (prevAddedRows[day] || 0) + 1,
        }));
    };

    const handleRemoveRow = (day, index) => {
        setAddedRows((prevAddedRows) => ({
            ...prevAddedRows,
            [day]: Math.max((prevAddedRows[day] || 0) - 1, 0),
        }));
    };
    console.log(schedule)




    const daysOfWeek = [
        t("createDiscount.monday"),
        t("createDiscount.tuesDay"),
        t("createDiscount.wednesday"),
        t("createDiscount.thursday"),
        t("createDiscount.friday"),
        t("createDiscount.saturday"),
        t("createDiscount.sunday")
      ];
    const datePeriod = [
        t("createDiscount.start_date"),
        t("createDiscount.end_date")
      ]
    const timePeriod =[t("createDiscount.start_time"),
    t("createDiscount.end_time")]
    const [selectedDateTimes, setSelectedDateTimes] = useState('');

    console.log(selectedDateTimes)

    const handleDateTimeChange = (event, index, type) => {
        const { value } = event.target;

        setSelectedDateTimes((prevDateTimes) => ({
            ...prevDateTimes,
            [type]: {
                ...(prevDateTimes[type] || {}),
                [index]: value,
            },
        }));
    };

    const [timeValues1, setTimeValues1] = useState({});

    const handleTimeChange1 = (day, row, type, value) => {
        setTimeValues1(prevTimeValues => ({
            ...prevTimeValues,
            [`${day}-${row}-${type}`]: value
        }));
    };


    const tooltips = [
        {
            title: t("createDiscount.exclusive"),
            content: t("createDiscount.exclusive_para"),
        },
        {
            title: t("createDiscount.competive"),
            content: t("createDiscount.competive_para"),
        },
        {
            title: t("createDiscount.stackable"),
            content: t("createDiscount.stackable_para"),
        },
    ];
    const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/create-discount");
    
  };


    return (
        // discount details
        <div className='createDiscount_main_con' style={{height:"83vh"}}>
            <div style={{ padding: "10px", backgroundColor: " #FBFBFB" }}>
                <div className='createDiscount_head_Con'>
                    <div className='createDiscount_closeIcon_con'>
                        <CloseIcon fontSize="inherit" className='createDiscount_closeIcon' onClick={handleNavigate}/>
                    </div>
                    <Typography className='createDiscount_head'>Create a Discount</Typography>
                </div>

                <div className='createDiscount_discount_table'>
                    <div style={{ width: "80%" , height:"75vh","overflow-y":"scroll"}}>

                    <Paper elevation={0.5} style={{ padding: "20px",marginTop:'20px', borderLeft: "2px solid #AE9BE2 " ,borderBottom: "1px solid #EEE",borderRight: "1px solid #EEE",borderTop: "1px solid #EEE",}}>
                            <Typography className='createDiscount_table_head'>{t("createDiscount.details")}</Typography>
                            <div style={{ display: "flex", justifyContent: 'space-between', marginTop: "10px" }}>
                                <div>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <TextField
                                            label={
                                                <Typography className='createDiscount_label'>
                                                    {t("createDiscount.discount_name")}
                                                </Typography>
                                            }
                                            variant="outlined"
                                            className='createDiscount_textField'
                                            value={discountName}
                                            onChange={(e) => setDiscountName(e.target.value)}
                                            
                                        />
                                        <FormControl style={{ marginLeft: "20px" }} className='createDiscount_textField'>
                                            <InputLabel id="demo-simple-select-label" className='createDiscount_label'>{t("createDiscount.select_location")}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Select Location"
                                                value={selectedLocation}
                                                onChange={handleLocationChange}
                                            >
                                                <MenuItem value="Hyedrabad">Hyedrabad</MenuItem>
                                                <MenuItem value="Vizag">Vizag</MenuItem>
                                                <MenuItem value="Kakinada">Kakinada</MenuItem>

                                            </Select>
                                        </FormControl>

                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                                        <FormControl variant="outlined" className='createDiscount_textField'>
                                            <InputLabel id="demo-simple-select-label1" className='createDiscount_label'>{t("createDiscount.discount_type")}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label1"
                                                id="demo-simple-select1"
                                                label={t("createDiscount.discount_type")}
                                                value={selectedDiscountType}
                                                onChange={handleDiscountTypeChange}
                                            >
                                                <MenuItem value="flat">{t("createDiscount.flat")}</MenuItem>
                                                <MenuItem value="%">%</MenuItem>


                                            </Select>
                                        </FormControl>
                                        <TextField
                                            label={
                                                <Typography className='createDiscount_label'>
                                                   {t("createDiscount.enter_amount")}
                                                </Typography>
                                            }
                                            variant="outlined"
                                            className='createDiscount_textField'
                                            value={discountAmount}
                                            onChange={(e) => setDiscountAmount(e.target.value)}
                                            style={{ marginLeft: "20px" }}
                                           
                                        />
                                    </div>
                                </div>
                                <div className='createDiscount_img_con'>
                                    <img src={imageAvtar} alt="img" className='createDiscount_img' />
                                    <Typography className='createDiscount_img_text'>{t("createDiscount.add_img")}</Typography>
                                </div>
                                <div>

                                </div>

                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "40px", marginTop: "10px" }}>
                                <Button className='createDiscount_clear_button' style={{ margin: '7px' }}>{t("createDiscount.clear")}</Button>
                                <Button className='createDiscount_save_button' style={{ margin: '7px' }}>{t("createDiscount.save")}</Button>
                            </div>
                        </Paper>

                        <Paper elevation={3} style={{ padding: "10px", marginTop: "10px", borderLeft: "2px solid #9BDAE2" }}>
                            <Typography className='createDiscount_table_head'>Discount Rule <span className='createDiscount_span_text'>apply this discount automatically to specific times, quantities, items or categories.</span></Typography>
                            <div className='createDiscount_discountRule_box' style={{ marginTop: "20px" }}>
                                <Typography className='createDiscount_table_head2'>Add customer group to which discount would be applicable</Typography>
                                <div style={{ display: "flex", alignItems: "center",justifyContent:"space-between", border: "1px solid #EEE" }}>
                                <div className='createDiscount_textField style_input1 ' >
                                        <TextField
                                           
                                            variant="standard"

                                            placeholder='Apply to Customers'
                                            value={minCartValue}
                                            onChange={(e) => setMinCartValue(e.target.value)}

                                            InputProps={{
                                                disableUnderline: true, // This removes the underline
                                            

                                            }}
                                        />
                                    </div>
                                   
                                        <Button className='createDiscount_button_style'>Add eligible items or categories</Button>
                                        <div style={{display:"flex",alignItems:"center"}}>
                                        <Typography className='createDiscount_apply_all'>Apply to all items</Typography>
                                    
                                        <FormControlLabel
                                        control={
                                            <Switch
                                                checked={applyToAll}
                                                onChange={handleChangeApplyToAll}
                                                color="warning"

                                            />
                                        }

                                        style={{ marginLeft: "5px" }} />
</div>
                                </div>
                                
                            </div>
                        </Paper>
                        <Typography className='createMinimum_para'>If multiple automatic discounts are applicable, the greater discount will be applied. Learn more</Typography>

                        <Paper elevation={0.5} style={{ padding: "20px",marginTop:'20px', borderLeft: "2px solid #BAE29B " ,borderBottom: "1px solid #EEE",borderRight: "1px solid #EEE",borderTop: "1px solid #EEE",}}>
                            <div style={{ display: "flex", alignItems: "center" }}>


                                <Typography className='createDiscount_table_head' >{t('createDiscount.discount_reason')}</Typography>
                                <FormControl style={{ marginLeft: "50px", }} className='createDiscount_textField'>
                                    <InputLabel id="demo-simple-select-label2" className='createDiscount_label'>{t('createDiscount.promotion_offer')}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label2"
                                        id="demo-simple-selec2t"
                                        label={t('createDiscount.promotion_offer')}
                                        value={selectedPromotion}
                                        onChange={handlePromotion}
                                    >
                                        <MenuItem value="1"><Typography>Birthday</Typography></MenuItem>
                                        <MenuItem value="2"><Typography>Annversary</Typography></MenuItem>
                                        <MenuItem value="3"><Typography>NewYear</Typography></MenuItem>

                                    </Select>
                                </FormControl>
                            </div>
                            <Typography className='createDiscount_table_head2' >{t("createDiscount.select_order_type")} </Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "10px" }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='DineIn'
                                                checked={checkboxes.DineIn}
                                                onChange={handleCheckboxChange}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t("createDiscount.dineIn")}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='Collection'
                                                checked={checkboxes.Collection}
                                                onChange={handleCheckboxChange}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t('createDiscount.collection')}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='Delivery'
                                                checked={checkboxes.Delivery}
                                                onChange={handleCheckboxChange}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t("createDiscount.delivery")}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='All'
                                                checked={checkboxes.All}
                                                onChange={handleCheckboxChange}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t('createDiscount.all')}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>
                            </div>

                            <Typography className='createDiscount_table_head2' >{t("createDiscount.oredr_availability")} </Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "10px" }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='Epos'
                                                checked={checkboxes1.Epos}
                                                onChange={handleCheckboxChange1}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t('createDiscount.epos')}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='Online'
                                                checked={checkboxes1.Online}
                                                onChange={handleCheckboxChange1}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t("createDiscount.online")}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='Mobile'
                                                checked={checkboxes1.Mobile}
                                                onChange={handleCheckboxChange1}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t('createDiscount.mobile')}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='All1'
                                                checked={checkboxes1.All1}
                                                onChange={handleCheckboxChange1}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t('createDiscount.all')}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>
                            </div>

                            <Typography className='createDiscount_table_head2' >{t("createDiscount.select_zones")} </Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "10px" }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='Zone1'
                                                checked={checkboxes2.Zone1}
                                                onChange={handleCheckboxChange2}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t("createDiscount.zone")} 1</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='Zone2'
                                                checked={checkboxes2.Zone2}
                                                onChange={handleCheckboxChange2}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t("createDiscount.zone")} 2</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='Zone3'
                                                checked={checkboxes2.Zone3}
                                                onChange={handleCheckboxChange2}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t("createDiscount.zone")} 3</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='All2'
                                                checked={checkboxes2.All2}
                                                onChange={handleCheckboxChange2}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t('createDiscount.all')}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>
                            </div>

                            <Typography className='createDiscount_table_head2' >{t("createDiscount.payment_type")}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "10px" }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='card'
                                                checked={checkboxes3.card}
                                                onChange={handleCheckboxChange3}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t("createDiscount.card")}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='cash'
                                                checked={checkboxes3.cash}
                                                onChange={handleCheckboxChange3}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t("createDiscount.cash")}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='onlinePay'
                                                checked={checkboxes3.onlinePay}
                                                onChange={handleCheckboxChange3}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t("createDiscount.online_pay")}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='All3'
                                                checked={checkboxes3.All3}
                                                onChange={handleCheckboxChange3}
                                                color='warning'
                                                className='createDiscount_box'
                                            />
                                        }
                                        label={<Typography className='createDiscount_check_text'>{t('createDiscount.all')}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                </FormGroup>
                            </div>

                            <Typography className='createDiscount_table_head2' >{t("createDiscount.discount_selection")}</Typography>
                            <FormControl component='fieldset'>

                                <RadioGroup
                                    aria-label='options'
                                    name='options'
                                    value={selectedValue}
                                    onChange={handleRadioChange}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row', // Display in a row
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        margin: '10px',
                                    }}
                                >
                                    <FormControlLabel
                                        value='single_use'
                                        control={<Radio color='warning' />}
                                        label={<Typography className='createDiscount_check_text'>{t("createDiscount.singleUse")}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                    <FormControlLabel
                                        value='multiple_use'
                                        control={<Radio color='warning' />}
                                        label={<Typography className='createDiscount_check_text'>{t("createDiscount.multiple_use")}</Typography>}
                                        className='createDiscount_checkBox'
                                    />
                                    <FormControlLabel
                                        value='first_time_use'
                                        control={<Radio color='warning' />}
                                        label={<Typography className='createDiscount_check_text'>{t("createDiscount.first_time")}</Typography>}
                                        className='createDiscount_checkBox , createDiscount_checkBox1'

                                    />
                                </RadioGroup>
                            </FormControl>

                        </Paper >
                        <Paper elevation={0.5} style={{ padding: "20px",marginTop:'20px', borderLeft: "2px solid #E2A39B " ,borderBottom: "1px solid #EEE",borderRight: "1px solid #EEE",borderTop: "1px solid #EEE",}}>

                            <Paper elevation={0.8} style={{ marginTop: "20px" ,padding:'20px', border: "1px solid #EEE" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={setMinimumSpend}
                                                onChange={handleChangesetMinimumSpend}
                                                color="warning"

                                            />
                                        }

                                        style={{ marginLeft: "5px" }} />
                                    <div style={{ marginLeft: "10px" }}>
                                        <Typography className='createMinimum_head' >{t("createDiscount.set_minimum")}</Typography>
                                        <Typography className='createMinimum_para'>{t("createDiscount.set_minimum_para")}</Typography>
                                    </div>

                                </div>
                                {setMinimumSpend && (
                                    <div>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className='createMinimum_head'>{t("createDiscount.day")}</TableCell>
                                                        <TableCell className='createMinimum_head'>{t("createDiscount.open_time")}</TableCell>
                                                        <TableCell className='createMinimum_head'>{t("createDiscount.close_time")}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {daysOfWeek.map((day, index) => (
                                                        <React.Fragment key={index}>
                                                            <TableRow>
                                                                <TableCell className='createDiscount_row_head' style={{ width: "160px" }}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <Checkbox
                                                                            color="warning"
                                                                            checked={checkedDays[day] || false}
                                                                            onChange={(event) => handleDayCheckboxChange(day, event.target.checked)}
                                                                        />
                                                                        <Typography className='createMinimum_head'>{day}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FormControl sx={{ m: 1 }} variant="standard" focused>
                                                                        <Typography style={{ marginBottom: "4px" }} className='createMinimum_para'>{t("createDiscount.open")}</Typography>
                                                                        <TextField
                                                                            variant="standard"
                                                                            type='time'
                                                                            className="createDiscount_timeInput"
                                                                            id={`standard-adornment-${day}-start`}
                                                                            value={openValues[day] || ''}
                                                                            onChange={(event) => {
                                                                                handleOpenTimeChange(day, event.target.value);
                                                                                handleTimeChange(day, 'open', event.target.value);
                                                                            }}
                                                                            InputProps={{
                                                                                disableUnderline: true, // This removes the underline
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FormControl sx={{ m: 1 }} variant="standard" focused>
                                                                        <Typography style={{ marginBottom: "4px" }} className='createMinimum_para'>{t("createDiscount.close")}</Typography>
                                                                        <TextField
                                                                            variant="standard"
                                                                            type='time'
                                                                            className="createDiscount_timeInput"
                                                                            id={`standard-adornment-${day}-end`}
                                                                            value={closeValues[day] || ''}
                                                                            onChange={(event) => {
                                                                                handleCloseTimeChange(day, event.target.value)
                                                                                handleTimeChange(day, 'close', event.target.value);
                                                                            }}
                                                                            InputProps={{
                                                                                disableUnderline: true, // This removes the underline
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <CloseIcon onClick={() => handleClearRow(day)} />
                                                                </TableCell>
                                                                {checkedDays[day] && (
                                                                    <div style={{ marginTop: "34px" }}>
                                                                        <AddIcon onClick={() => handleAddRow(day)} />
                                                                    </div>
                                                                )}
                                                            </TableRow>
                                                            {Array.from({ length: addedRows[day] || 0 }).map((_, i) => (
                                                                <TableRow key={`${day}-added-${i}`}>
                                                                    <TableCell />
                                                                    <TableCell>
                                                                        <FormControl sx={{ m: 1 }} variant="standard" focused>
                                                                            <Typography style={{ marginBottom: "4px" }} className='createMinimum_para'>{t("createDiscount.open")}</Typography>
                                                                            <TextField
                                                                                variant="standard"
                                                                                type='time'
                                                                                className="createDiscount_timeInput"
                                                                                id={`standard-adornment-${day}-start`}
                                                                                value={timeValues1[`${day}-${i}-open`] || ''}
                                                                                onChange={(event) =>
                                                                                    handleTimeChange1(day, i, 'open', event.target.value)
                                                                                }
                                                                                InputProps={{
                                                                                    disableUnderline: true,
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <FormControl sx={{ m: 1 }} variant="standard" focused>
                                                                            <Typography style={{ marginBottom: "4px" }} className='createMinimum_para'>{t("createDiscount.close")}</Typography>
                                                                            <TextField
                                                                                variant="standard"
                                                                                type='time'
                                                                                className="createDiscount_timeInput"
                                                                                id={`standard-adornment-${day}-end`}
                                                                                value={timeValues1[`${day}-${i}-close`] || ''}
                                                                                onChange={(event) =>
                                                                                    handleTimeChange1(day, i, 'close', event.target.value)
                                                                                }
                                                                                InputProps={{
                                                                                    disableUnderline: true,
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <IconButton onClick={() => handleRemoveRow(day, i)}>
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </React.Fragment>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </div>
                                )}
                            </Paper>
                            <Paper elevation={0.8} style={{ marginTop: "20px",padding:"20px" , border: "1px solid #EEE"}}>
                                <div style={{ display: "flex", alignItems: "center" ,marginBottom:'10px'}}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={setdateRange}
                                                onChange={handleChangesetSetDate}
                                                color="warning"

                                            />
                                        }

                                        style={{ marginLeft: "5px" }} />
                                    <div style={{ marginLeft: "10px" }}>
                                        <Typography className='createMinimum_head' >{t("createDiscount.date_range")}</Typography>
                                        <Typography className='createMinimum_para'>{t("createDiscount.set_minimum_para")}</Typography>
                                    </div>

                                </div>
                                {setdateRange && (
                                    <div style={{ marginTop: "6px" }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow>
                                                    </TableRow>
                                                </TableHead >
                                                <div style={{ display: "flex" }}>
                                                    <TableBody style={{ width: "50%" }}>
                                                        {datePeriod.map((date, index) => (
                                                            <React.Fragment key={index}>
                                                                <TableRow >
                                                                    <TableCell className='createDiscount_row_head' style={{ width: "160px" }}>
                                                                        <div style={{ display: "flex", alignItems: "center" }}>

                                                                            <Typography className='createMinimum_head'>{date}</Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <FormControl>
                                                                            <TextField
                                                                                variant="standard"
                                                                                type="date"
                                                                                value={selectedDateTimes['date']?.[index] || ''}
                                                                                onChange={(event) => handleDateTimeChange(event, index, 'date')}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                InputProps={{
                                                                                    disableUnderline: true, // This removes the underline
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </React.Fragment>
                                                        ))}
                                                    </TableBody>
                                                    <TableBody style={{ width: "50%" }}>
                                                        {timePeriod.map((date, index) => (
                                                            <React.Fragment key={index}>
                                                                <TableRow >
                                                                    <TableCell className='createDiscount_row_head' style={{ width: "160px" }}>
                                                                        <div style={{ display: "flex", alignItems: "center" }}>

                                                                            <Typography className='createMinimum_head'>{date}</Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <FormControl>
                                                                            <TextField
                                                                                variant="standard"
                                                                                type="time"
                                                                                value={selectedDateTimes['time']?.[index] || ''}
                                                                                onChange={(event) => handleDateTimeChange(event, index, 'time')}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                InputProps={{
                                                                                    disableUnderline: true, // This removes the underline
                                                                                }}
                                                                            />
                                                                        </FormControl>

                                                                    </TableCell>
                                                                </TableRow>
                                                            </React.Fragment>
                                                        ))}
                                                    </TableBody>
                                                </div>
                                            </Table>
                                        </TableContainer>
                                    </div>)}

                            </Paper>
                            <Paper elevation={0.8} style={{ marginTop: "20px",padding:"20px" , border: "1px solid #EEE"}}>
                                <div style={{ display: "flex", alignItems: "center",marginBottom:'10px' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={minSpend}
                                                onChange={handleMinSpend}
                                                color="warning"

                                            />
                                        }

                                        style={{ marginLeft: "5px" }} />
                                    <div style={{ marginLeft: "10px" }}>
                                    <Typography className='createMinimum_head' >{t("createDiscount.set_minimum")}</Typography>
                                        <Typography className='createMinimum_para'>{t("createDiscount.set_minimum_para")}</Typography>
                                    </div>

                                </div>
                                {minSpend && (
                                    <div style={{ display: "flex", alignItems: "center", border: "1px solid #EEE" }}>
                                        <div >
                                            <Typography className='createDiscount_maxdiscount_head'>{t("createDiscount.subtotal")}</Typography>
                                        </div>
                                        <div className='createDiscount_textField style_input '>
                                            <TextField
                                                fullWidth
                                                variant="standard"

                                                placeholder='£0.00'
                                                value={minAmount}
                                                onChange={(e) => setMinAmount(e.target.value)}

                                                InputProps={{
                                                    disableUnderline: true, // This removes the underline
                                                    

                                                }}
                                            />
                                        </div>
                                    </div>

                                )}

                            </Paper>
                            <Paper elevation={0.8} style={{ marginTop: "20px" ,padding:"20px", border: "1px solid #EEE" }}>
                                <div style={{ display: "flex", alignItems: "center",marginBottom:'10px' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={maxDiscount}
                                                onChange={handleMaxDiscount}
                                                color="warning"

                                            />
                                        }

                                        style={{ marginLeft: "5px" }} />
                                    <div style={{ marginLeft: "10px" }}>
                                        <Typography className='createMinimum_head' >{t("createDiscount.maximum_discount")}</Typography>
                                        <Typography className='createMinimum_para'>{t("createDiscount.discount_para")}</Typography>
                                    </div>

                                </div>
                                {maxDiscount && (
                                    <div>
                                        <div style={{ display: "flex", alignItems: "center", border: "1px solid #EEE" }}>
                                            <div >
                                                <Typography className='createDiscount_maxdiscount_head'>{t("createDiscount.max_value")}</Typography>
                                            </div>
                                            <div className='createDiscount_textField style_input '>
                                                <TextField
                                                    fullWidth
                                                    variant="standard"

                                                    placeholder='£0.00'
                                                    value={maxDiscountAmount}
                                                    onChange={(e) => setmaxDiscountAmount(e.target.value)}

                                                    InputProps={{
                                                        disableUnderline: true, // This removes the underline
                                                        

                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <Typography className='createMinimum_para'>{t("createDiscount.maxDiscount_para")}</Typography>
                                    </div>
                                )}

                            </Paper>



                        </Paper>
                        <Paper elevation={0.5} style={{ padding: "20px",marginTop:'20px', borderLeft: "2px solid #E19BE2 " ,borderBottom: "1px solid #EEE",borderRight: "1px solid #EEE",borderTop: "1px solid #EEE",}}>
                            <div style={{ display: "flex", alignItems: "center",marginBottom:'10px' }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography className='createDiscount_discount_mark' >{t('createDiscount.mark_discount')}</Typography>
                                    <Tooltip title={<div >
                                        <Typography className='createDiscount_toolTip_head'>{tooltips[0].title}: <span className='createDiscount_toolTip_text'> {tooltips[0].content}</span></Typography>
                                        <Typography className='createDiscount_toolTip_head'>{tooltips[1].title}: <span className='createDiscount_toolTip_text'> {tooltips[1].content}</span></Typography>
                                        <Typography className='createDiscount_toolTip_head'> {tooltips[2].title}: <span className='createDiscount_toolTip_text'> {tooltips[2].content}</span></Typography>
                                    </div>} placement="bottom-start" classes={{ tooltip: 'custom-tooltip' }} >
                                        <Button sx={{ m: 1 }}>
                                            <div style={{ position: 'relative', display: 'inline-block' }} className="custom-icon-container">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
                                                </svg>
                                                <Typography
                                                    component="span"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        color: '#ffffff',
                                                        fontWeight: 'bold',
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    !
                                                </Typography>
                                            </div></Button>
                                    </Tooltip>
                                </div>
                                <div>
                                    <FormControl style={{ marginLeft: "20px" }} className='createDiscount_textField'>

                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"

                                            value={selectedMarkDiscount}
                                            onChange={handleMarkDiscount}
                                        >
                                            <MenuItem value="exclusive"><Typography>{t("createDiscount.exclusive")}</Typography></MenuItem>
                                            <MenuItem value="competitive"><Typography>{t("createDiscount.competive")}</Typography></MenuItem>
                                            <MenuItem value="stackable"><Typography>{t("createDiscount.stackable")}</Typography></MenuItem>

                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <FormControl component='fieldset'>

                                <RadioGroup
                                    aria-label='options'
                                    name='options'
                                    value={selectedValue1}
                                    onChange={handleRadioChange1}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row', // Display in a row
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        margin: '10px',
                                    }}
                                >
                                    <FormControlLabel
                                        value='single_use'
                                        control={<Radio color='warning' />}
                                        label={<Typography className='createDiscount_discount_mark'>{t("createDiscount.use_custom_cupon")}</Typography>}

                                    />
                                    <FormControlLabel
                                        value='multiple_use'
                                        control={<Radio color='warning' />}
                                        label={<Typography className='createDiscount_discount_mark'>{t("createDiscount.automatically")}</Typography>}

                                    />

                                </RadioGroup>
                            </FormControl>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography className='createDiscount_discount_mark' style={{ marginRight: "50px" }}>{t("createDiscount.cupon_code")}</Typography>
                                <TextField

                                    variant="outlined"
                                    className='createDiscount_textField'
                                    value={couponCode}
                                    onChange={(e) => setCouponcode(e.target.value)}
                                    
                                />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography className='createDiscount_discount_mark' >{t("createDiscount.limited_stock")}</Typography>
                                    <Tooltip title={<div >
                                        <Typography className='createDiscount_toolTip_head'>{tooltips[0].title}: <span className='createDiscount_toolTip_text'> {tooltips[0].content}</span></Typography>
                                        <Typography className='createDiscount_toolTip_head'>{tooltips[1].title}: <span className='createDiscount_toolTip_text'> {tooltips[1].content}</span></Typography>
                                        <Typography className='createDiscount_toolTip_head'> {tooltips[2].title}: <span className='createDiscount_toolTip_text'> {tooltips[2].content}</span></Typography>
                                    </div>} placement="bottom-start" classes={{ tooltip: 'custom-tooltip' }} >
                                        <Button sx={{ m: 1 }}>
                                            <div style={{ position: 'relative', display: 'inline-block' }} className="custom-icon-container">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
                                                </svg>
                                                <Typography
                                                    component="span"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        color: '#ffffff',
                                                        fontWeight: 'bold',
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    !
                                                </Typography>
                                            </div></Button>
                                    </Tooltip>
                                </div>
                                <div>
                                    <TextField

                                        variant="outlined"
                                        className='createDiscount_textField'
                                        value={limitedStock}
                                        onChange={(e) => setLimitedStock(e.target.value)}
                                        
                                    />
                                </div>
                            </div>

                        </Paper>

                    </div>

                </div>
            </div>

        </div>
    );
}

export default CreateDiscountCustomerGroup;
