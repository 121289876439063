import { Box, Typography,Autocomplete,TextField,Button,Popover, IconButton,List,ListItem,ListItemText,Divider } from '@mui/material'
import React from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import {t} from "i18next"
const locationsList=["location4","location5","location3"]



  const rows=[
    {
        id:1,
        branchName:"Madhapur",
        
        pairedDevices:"3 KDS devices Paired"                                                                                                                                                                                                                                                                                                              
    },
    {
        id:2,
        branchName:"Madhapur",
        pairedDevices:"3 KDS devices Paired"
    },
    {
        id:3,
        branchName:"Madhapur",
        pairedDevices:"3 KDS devices Paired"
    },
    {
        id:4,
        branchName:"Madhapur",
        pairedDevices:"3 KDS devices Paired"
    },
  ]




function KioskListing() {
    const [value, setValue] = React.useState('1');
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const PopoverOpen = Boolean(anchorEl);
   // first popup
    const [firstPopUpOpen, setFirstPopUpOpen] = React.useState(false);
    const handleFirstPopUpOpen = () => setFirstPopUpOpen(true);
    const handleFirstPopUpClose = () => setFirstPopUpOpen(false);
    //second popup
    const [secondPopUpOpen, setSecondPopUpOpen] = React.useState(false);
    const handleSecondPopUpOpen = () => setSecondPopUpOpen(true);
    const handleSecondPopUpClose = () => setSecondPopUpOpen(false);
    //code functions
    const [isTrue, setIsTrue] = React.useState(false);
    
    
    
    const handleCopyIcon=()=>{
      setIsTrue(!isTrue)
      if(isTrue){
        const inputCode="1234 1234 1234"
        navigator.clipboard.writeText(inputCode)
      }
    }

    const id = PopoverOpen ? 'simple-popover' : undefined;

  
    const columns: GridColDef[] = [
        {
          field: 'branchName',
          headerClassName:"device-set-up-table-header",
          //@ts-ignore
          headerName: t("kiosk_listing.branch_name"),
          flex:1
        },
        {
          field: 'pairedDevices',
          headerClassName:"device-set-up-table-header",
          //@ts-ignore
          headerName: t("kiosk_listing.branch_name"),
          width:300
        },{
         field:"",
         disableColumnMenu:true,
          width:150,
          renderCell:(params)=>(
            <Box>
                <IconButton onClick={handleClick} >
                    <EditIcon/>
                </IconButton>
                <Popover
              id={id}
              open={PopoverOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              style={{
                width: "180px",
                height: "225px",
                paddingTop: "0px",
                paddingBottom: "0px"
              }}
              elevation={8}
            >
              {popoverContent}
            </Popover>
            </Box>
          )
        },
       
       
      ];




    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };



      const getRowSize=()=>{
        return "device-set-up-table-row device-set-up-table-row-hover"
      }
      
      

      const popoverContent = (
        <List style={{ padding: '0px 0px 0px 0px' }}>
          <Button style={{ textTransform:"capitalize",padding: '0px 0px 0px 0px' }}>
            <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
              <ListItemText className='device-set-up-table-row-hover' primary={t("kiosk_listing.edit")} />
            </ListItem>
          </Button>
          <Divider />
          <Button style={{textTransform:"capitalize", padding: '0px 0px 0px 0px' }}>
            <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
              <ListItemText className='device-set-up-table-row-hover' primary={t("kiosk_listing.delete")} />
            </ListItem>
          </Button>
        
          
        </List>
      );
  return (
    <Box>
        <Box>
                    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <Autocomplete
                    
                    id="combo-box-demo"
                    options={locationsList}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField color='warning' {...params} label={<Typography className='device-set-up-header-drop-down-label'>{t("kiosk_listing.select_location")}</Typography>}/>}
                    />
                    </Box>
                    <Box sx={{ height: 400, width: '100%',marginTop:"15px" }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        }}
                        pageSizeOptions={[5]}
                        getRowClassName={getRowSize}
                        disableRowSelectionOnClick
                    />
                    </Box>
                </Box>

        
    </Box>
    
  )
}

export default KioskListing
