import React from "react";
import { Button, Divider, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useForm, Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Switch from '@mui/material/Switch';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { Calendar } from 'primereact/calendar';

const CreateCustomer = () => {
    const { t } = useTranslation();
    const breadcrumbs = [
        <Typography key="1">{t("sales.sales_label")}</Typography>,
        <Typography key="3">
            {t("sales.customers")}
        </Typography>,
        <Typography className="primaryColor" key="3">
            {t("sales.create_customer")}
        </Typography>,
    ];
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            fax: "",
            customerGroup: "",
            notes: "",
            company: "",
            companyId: "",
            taxId: "",
            address1: "",
            address2: "",
            city: "",
            postcode: "",
            country: "",
            birthday: "",
            anniversary: "",
            newsletter: false,
            status: false,
            defaultAddress: false,
        },
    });

    const onSubmit = (data) => console.log(data);
    return (
        <>
            <Paper className="paper">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
                <Divider className="divider" />
                <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
                        <FormControl
                            className="inputHolder"
                            error={errors.firstName}
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-first-name">
                                {t("create_customer.input_labels.fname")}
                            </InputLabel>
                            <Controller
                                name="firstName"
                                rules={{ required: true, maxLength: 32, minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                            {errors.firstName && (
                                <FormHelperText className="inputErrors" id="first-name-error">
                                    {t("create_customer.input_labels.fname_error")}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            error={errors.lastName}
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-last-name">
                                {t("create_customer.input_labels.lname")}
                            </InputLabel>
                            <Controller
                                name="lastName"
                                rules={{ required: true, maxLength: 32, minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                            {errors.lastName && (
                                <FormHelperText className="inputErrors" id="last-name-error">
                                    {t("create_customer.input_labels.lname_error")}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            error={errors.phone}
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-phone">
                                {t("create_customer.input_labels.phone")}
                            </InputLabel>
                            <Controller
                                name="phone"
                                rules={{ required: true, minLength: 10, maxLength: 12 }}
                                control={control}
                                render={({ field }) => <Input type="tel" {...field} />}
                            />
                            {errors.phone && (
                                <FormHelperText className="inputErrors" id="phone-error">
                                    {t("create_customer.input_labels.phone_error")}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            error={errors.email}
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-email">
                                {t("create_customer.input_labels.email")}
                            </InputLabel>
                            <Controller
                                name="email"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => <Input type="email" {...field} />}
                            />
                            {errors.email && (
                                <FormHelperText className="inputErrors" id="email-error">
                                    {t("create_customer.input_labels.email_error")}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-fax">
                                {t("create_customer.input_labels.fax")}
                            </InputLabel>
                            <Controller
                                name="fax"
                                rules={{ maxLength: 32, minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            error={errors.customerGroup}
                            variant="standard"
                            sx={{ m: 1 }}
                        >
                            <InputLabel id="user-group">
                                {" "}
                                {t("create_customer.input_labels.customer_group")}
                            </InputLabel>
                            <Controller
                                name="customerGroup"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        labelId="customer-group"
                                        id="customer-group"
                                        label="customer Group"
                                        {...field}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={"Default"}>Default</MenuItem>
                                        <MenuItem value={"Default 1"}>Default 1</MenuItem>
                                        <MenuItem value={"Hubrise"}>Hubrise</MenuItem>
                                    </Select>
                                )}
                            />
                            {errors.customerGroup && (
                                <FormHelperText className="inputErrors" id="customer-group-error">
                                    {t("create_customer.input_labels.customer_group_error")}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-company">
                                {t("create_customer.input_labels.company")}
                            </InputLabel>
                            <Controller
                                name="company"
                                rules={{ maxLength: 32, minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-company-id">
                                {t("create_customer.input_labels.company_id")}
                            </InputLabel>
                            <Controller
                                name="companyId"
                                rules={{ maxLength: 32, minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-tax-id">
                                {t("create_customer.input_labels.tax_indentifier")}
                            </InputLabel>
                            <Controller
                                name="taxId"
                                rules={{ maxLength: 32, minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                            error={errors.customerGroup}
                        >
                            <InputLabel htmlFor="standard-adornment-address-1">
                                {t("create_customer.input_labels.address1")}
                            </InputLabel>
                            <Controller
                                name="address1"
                                rules={{ required: true, minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                            {errors.address1 && (
                                <FormHelperText className="inputErrors" id="address-1-error">
                                    {t("create_customer.input_labels.address_error")}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-address-2">
                                {t("create_customer.input_labels.address2")}
                            </InputLabel>
                            <Controller
                                name="address2"
                                rules={{ minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-city">
                                {t("create_customer.input_labels.city")}
                            </InputLabel>
                            <Controller
                                name="city"
                                rules={{ minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-post-code">
                                {t("create_customer.input_labels.post_code")}
                            </InputLabel>
                            <Controller
                                name="postcode"
                                rules={{ minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-country">
                                {t("create_customer.input_labels.country")}
                            </InputLabel>
                            <Controller
                                name="country"
                                rules={{ minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormControl>
                        <FormControl className="calenderHolder">
                            <Controller
                                name="birthday"
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex d-flex-column">
                                        <label className="block mb-0" htmlFor="birthday">{t("create_customer.input_labels.birthday")}</label>
                                        <Calendar id="birthday" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" {...field} />
                                    </div>
                                )}
                            />
                            <Controller
                                name="anniversary"
                                control={control}
                                render={({ field }) => (
                                    <div>
                                        <label className="block mb-0" htmlFor="anniversary">{t("create_customer.input_labels.anniversary")}</label>
                                        <Calendar id="anniversary" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" {...field} />
                                    </div>
                                )}
                            />
                            <Controller
                                name="newsletter"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={<Switch {...field} color="warning" />}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        label={t("create_customer.input_labels.newsletter")}
                                        labelPlacement="start"
                                    />)}
                            />
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={<Switch {...field} color="warning" />}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        label={t("create_customer.input_labels.status")}
                                        labelPlacement="start"
                                    />)}
                            />
                        </FormControl>

                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <Controller
                                name="defaultAddress"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={<Checkbox {...field} color="warning" />}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        label={t("create_customer.input_labels.default_address")}
                                        labelPlacement="end"
                                    />)}
                            />
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}></FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-notes">
                                {t("create_customer.input_labels.notes")}
                            </InputLabel>
                            <Controller
                                name="notes"
                                control={control}
                                render={({ field }) => <Input multiline rows={4} {...field} />}
                            />
                        </FormControl>
                        <br />
                        <br />
                        <Button
                            focusRipple
                            className="primaryButton"
                            variant="contained"
                            type="submit"
                        >
                            {t("buttons.submit")}
                        </Button>
                    </form>
                </Box>
            </Paper>
        </>
    );
};

export default CreateCustomer;
