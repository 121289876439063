import { FormGroup,FormControlLabel,Checkbox,Box,IconButton,Typography,Button, Paper,Autocomplete,TextField,Switch,FormControl,InputLabel,OutlinedInput,InputAdornment} from '@mui/material'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { useState } from 'react'



import RenderGoogleMap from "../utils/googleMaps";
import AreaWise from './areaWise';
import DistanceOrZoneWise from './distanceOrZoneWise';
import DistanceWise from './distanceWise';
import {t} from "i18next"

import "./deliveryZone.css"

const locationsList=["location1","location2","location3"]



function DeliveryZone() {
    const buttonsList=[
        {id:1,
         buttonText:t("delivery_zone.area_wise")
        },
        {
        id:2,
        buttonText:t("delivery_zone.distance_wise")
    },
    {
        id:3,
        buttonText:t("delivery_zone.zone/radius")
    }]

    const [activeButton,setActiveButton]=useState(buttonsList[2].id)

    const handleButton=(id:number)=>{
        setActiveButton(id)
        
    }

    const renderMappingComponents=()=>{
        switch(activeButton){
            case 2:
                return (<DistanceWise/>)
            case 3:
                return (<DistanceOrZoneWise/>)
            default:
                return (<AreaWise/>)

        }
    }  
  return (
    <Box>

      <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",margin:"10px"}}>
            <IconButton className='create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover'>
            <ArrowBackIcon/>
            </IconButton>    
            <Typography className='edit-inventory-header-name'>{t("delivery_zone.delivery")}</Typography>
            <IconButton>
            <Button color='warning' variant='contained'>{t("delivery_zone.save")}</Button>
            </IconButton>
        </Box>

        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <Paper elevation={2} sx={{width:"60%",padding:"15px"}}>
                <Typography className='delivery-zone-details-text'>{t("delivery_zone.details")}</Typography>
                <Box>
                <Autocomplete
                    
                    id="combo-box-demo"
                    options={locationsList}
                    sx={{ width: 300 ,marginTop:"10px",marginBottom:"10px"}}
                    renderInput={(params) => <TextField {...params} label= {<Typography className='delivery-zone-auto-complete-label'>{t("delivery_zone.select_location")}</Typography>} />}
                    />
                </Box>
                <Box sx={{width:"50%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <Typography className='delivery-zone-details-paragraph'>{t("delivery_zone.include_discounted")}</Typography>
                    <Switch color='warning' />
                </Box>
                <Box sx={{width:"40%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <Typography className='delivery-zone-details-paragraph'>{t("delivery_zone.extra_mile_shipping_rates")}</Typography>
                    <Switch color='warning' />
                </Box>
                <Box sx={{marginTop:"10px",marginBottom:"10px",width:"80%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <FormControl sx={{width:300 }}>
                <InputLabel className='delivery-zone-auto-complete-label' htmlFor="outlined-adornment-amount">{t("delivery_zone.rate")}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<InputAdornment className='delivery-zone-auto-complete-label' position="start">£</InputAdornment>}
                    label={t("delivery_zone.amount")}
                />
                </FormControl>
                <FormControl sx={{width:300 }}>
                <InputLabel className='delivery-zone-auto-complete-label' htmlFor="outlined-adornment-amount">{t("delivery_zone.maximum_distance")}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                />
                </FormControl>
                </Box>
                <Typography className='delivery-zone-details-paragraph'>{t("delivery_zone.select_payment_type")}</Typography>
                <Box sx={{ display: "flex",marginTop:"10px" }}>
                            <FormGroup sx={{ margin: "10px" }}>
                                <FormControlLabel

                                    control={
                                        <Checkbox
                                           
                                            name='Card'
                                            color='warning'
                                        />
                                    }
                                    label={<Typography className='delivery-zone-checkbox-label'>{t("delivery_zone.card")}</Typography>}
                                    className="delivery-zone-checkbox"
                                />
                            </FormGroup>
                            <FormGroup sx={{ margin: "10px" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox     
                                            name='Cash'
                                            color='warning'
                                        />
                                    }
                                    label={<Typography className='delivery-zone-checkbox-label'>{t("delivery_zone.cash")}</Typography>}
                                    className="delivery-zone-checkbox"
                                />
                            </FormGroup>
                            <FormGroup sx={{ margin: "10px" }}>
                                <FormControlLabel

                                    control={
                                        <Checkbox
                                           
                                            name='Online Pay'
                                            color='warning'
                                        />
                                    }
                                    label={<Typography className='delivery-zone-checkbox-label'>{t("delivery_zone.online_pay")}</Typography>}
                                    className="delivery-zone-checkbox"
                                />
                            </FormGroup>
                        </Box>
                        <Box sx={{marginTop:"10px",marginBottom:"10px",width:"80%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <Typography className='delivery-zone-details-paragraph'>{t("delivery_zone.limit_orders_per_hour")}</Typography>
                            <TextField id="outlined-basic" variant="outlined" />
                            <Autocomplete
                            id="combo-box-demo"
                            options={locationsList}
                            sx={{ width: 300 ,marginTop:"10px",marginBottom:"10px"}}
                            renderInput={(params) => <TextField {...params} label={<Typography className='delivery-zone-auto-complete-label'>{t("delivery_zone.select_delivery_area_limit")}</Typography>} />}
                            />

                        </Box>
                        <Box sx={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                            <Button color='warning' variant='outlined'>{t("delivery_zone.cancel")}</Button>
                            <Button sx={{marginLeft:"10px"}} color='warning' variant='contained'>{t("delivery_zone.save")}</Button>
                        </Box>

            </Paper>

        
        </Box>
        
        <Paper sx={{marginTop:"15px",padding:"20px"}} elevation={2}>
                <Typography className='delivery-zone-details-text'>{t("delivery_zone.select_type")}</Typography>
                <Box sx={{width:"42%",display:"flex",justifyContent:"space-between",marginTop:"15px",marginLeft:"10px"}}>
                    {buttonsList.map((button)=>(
                        <Button onClick={()=>handleButton(button.id)} sx={{width:"160px",textTransform:"none",border:"0px solid #707070"}} className={button.id===activeButton?"delivery-zone-area-wise-text":""} color='warning' variant='outlined'>{button.buttonText} {button.id===activeButton&&<CheckCircleIcon/>}</Button>
                    ))}
                </Box>
                <Box>

                </Box>
                {renderMappingComponents()}
            </Paper>
            

    </Box>
  )
}

export default DeliveryZone
