import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import {BranchProvider} from './context/branches'
import ToastProvider from "./toasterFile";

import Client from "./graphicalQl/apolloclient";


import './i18n'

// const client = new ApolloClient({
//   uri:  'https://flyby-router-demo.herokuapp.com/',
//   cache: new InMemoryCache(),
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
   <ToastProvider>
      <ApolloProvider client={Client}>
     
        <BranchProvider>
        <App />    
        </BranchProvider>
      </ApolloProvider>
      </ToastProvider>
    </BrowserRouter>
  </React.StrictMode>
);
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
