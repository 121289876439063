import React from "react";
import { Button, Divider, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useForm, Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useTranslation } from "react-i18next";

const AboutMyBusiness = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    <Typography key="1">{t("business_information.label")}</Typography>,
    <Typography className="primaryColor" key="3">
      {t("business_information.about_my_business")}
    </Typography>,
  ];
  const { handleSubmit, control } = useForm();

  const onSubmit = (data: any) => console.log(data);
  return (
    <>
      <Paper className="paper">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Divider className="divider" />

        <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
          <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              className="inputHolder"
              sx={{ m: 1 }}
              variant="standard"
            >
              <InputLabel htmlFor="standard-adornment-business-name">
                {t("business_information.business_name")}
              </InputLabel>
              <Controller
                name="businessName"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </FormControl>
            <FormControl
              className="inputHolder"
              sx={{ m: 1 }}
              variant="standard"
            >
              <InputLabel htmlFor="standard-adornment-business-owner">
                {t("business_information.business_owner")}
              </InputLabel>
              <Controller
                name="businessOwner"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </FormControl>
            <FormControl
              className="inputHolder"
              sx={{ m: 1 }}
              variant="standard"
            >
              <InputLabel htmlFor="standard-adornment-language">
                {t("business_information.language")}
              </InputLabel>
              <Controller
                name="language"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </FormControl>

            <br />
            <br />
            <Button
              focusRipple
              className="primaryButton"
              variant="contained"
              type="submit"
            >
              {t("buttons.submit")}
            </Button>
          </form>
        </Box>
      </Paper>
    </>
  );
};

export default AboutMyBusiness;
