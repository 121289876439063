import { Box, Typography,FormControlLabel,Radio,TextField,Button, IconButton, Paper,FormControl,RadioGroup } from '@mui/material'
import React from 'react'
import { GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import "./kdsSettings.css"
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KitchenCapacityQuotingTime from './kitchenCapacityQuotingTime';
import ManualQuotingTime from './manualQuotingTime';
import KdsSettingsOrders from './kdsSettingsOrders';
import KdsSettingsLayout from './kdsSettingsLayout';
import {t} from "i18next"
const itemsList=["pizza","Biryani","Dhum biryani","Burger"]


  const initialRows=[
    {
        id:1,
        stationName:"Madhapur1",
        pairedDevice:"KDS 1"
    },
    {
        id:2,
        stationName:"Madhapur2",
        pairedDevice:"KDS 2"
    },

  ]
;
interface NewRow{
    id:number
    stationName:string
    pairedDevice:string
}

const initialNewRow:NewRow={
    id:0,
    stationName:"",
    pairedDevice:""
}



const initialLimitOrderPerItemList=[
    {
        id:1,
        limitPerUser:"",
        item:itemsList[0],
        fromTiime:"",
        toTime:"",
        day:{
          Monday:false,
          Tuesday:false,
          Wednesday:false,
          Thursday:false,
          Friday:false,
          Saturday:false,
          Sunday:false
        }
    }
]

interface inputValues{
    dineInHr:string
    dineInMin:string
    collectionHr:string
    collectionMin:string
    deliveryHr:string
    deliveryMin:string
    addHr:string
    addMin:string
    amount:string
    channel1Hr:string
    channel1Min:string
    channel2Hr:string
    channel2Min:string
    channel3Hr:string
    channel3Min:string


}

const initialAllInputValues:inputValues={
    dineInHr:"",
    dineInMin:"",
    collectionHr:"",
    collectionMin:"",
    deliveryHr:"",
    deliveryMin:"",
    channel1Hr:"",
    channel1Min:"",
    channel2Hr:"",
    channel2Min:"",
    channel3Hr:"",
    channel3Min:"",
    addHr:"",
    addMin:"",
    amount:""

}

const initialAllCheckboxInputValues={
    displayPOSTerminal:false,
    displayOrderTypeWithEachOrder:false,
    displayOrderId:false,
    displaySeatNumberForEachItem:false
}


function KdsSettings() {
    const [rows,setRows]=React.useState(initialRows)
    const [limitOrderPerItemList,setLimitOrderPerItemList]=React.useState(initialLimitOrderPerItemList)
    const [editIsActive,setEditIsActive]=React.useState(false)
    const [newRow,setNewRow]=React.useState(initialNewRow)
    const [pairedDeviceError,setPairedDeviceError]=React.useState(false)
    

    const newOrderPerItemList= {
        id:limitOrderPerItemList.length+1,
        limitPerUser:"",
        item:itemsList[0],
        fromTiime:"",
        toTime:"",
        repeatOn:"",
        day:{
          Monday:false,
          Tuesday:false,
          Wednesday:false,
          Thursday:false,
          Friday:false,
          Saturday:false,
          Sunday:false
        }

    }

    const columns: GridColDef[] = [
        {
          field: 'branchName',
          headerClassName:"device-set-up-table-header",
          headerName: 'Branch Name',
          flex:1
        },
        {
          field: 'status',
          headerClassName:"device-set-up-table-header",
          headerName: 'Status',
          width:300
        },
      ];

      //Radio buttons functions
      const [manualQuotingTime,setManualQuotingTime]=React.useState("manualQuotingTime")
      
      const handleTextSize=(event:React.ChangeEvent<HTMLInputElement>)=>{
        console.log(event.target.value)
      }

      const handleTicketsPerPage=(event:React.ChangeEvent<HTMLInputElement>)=>{
        console.log(event.target.value)
      }
      const handleNotes=(event:React.ChangeEvent<HTMLInputElement>)=>{
        console.log(event.target.value)
      }

      const handleManualQuotingTime=(event:React.ChangeEvent<HTMLInputElement>)=>{
        setManualQuotingTime(event.target.value)
      }

      const handleNewOnlinePlaced=(event:React.ChangeEvent<HTMLInputElement>)=>{
        console.log(event.target.value)
      }
      
      //Textfield functions

      const [allInputValues,setAllInputValues]=React.useState(initialAllInputValues)
      

      const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const { name, value, type, checked } = event.target;
        setAllInputValues((prevState) => ({
          ...prevState,
          [name]: type === 'checkbox' ? checked : value,
        }));
      };

      const handleUserPerItem=(event:string,id:number)=>{
        setLimitOrderPerItemList(prev=>
          prev.map((each)=>each.id===id?{...each,limitPerUser:event}:each)
          )
      }

      const handleFromTime=(id:number,event:string)=>{
        setLimitOrderPerItemList(prev=>
          prev.map((each)=>each.id===id?{...each,fromTiime:event}:each)
          )
      }

      const handleToTime=(id:number,event:string)=>{
        setLimitOrderPerItemList(prev=>
          prev.map((each)=>each.id===id?{...each,toTime:event}:each)
          )
      }

      const handleAutoComplete=(event:any,newValue:any,id:number)=>{
        setLimitOrderPerItemList(prev=>
          prev.map((each)=>each.id===id?{...each,item:newValue}:each)
          )
      }

      //Add new Limit Orders per Item

      const handleAddNewButton=()=>{

        setLimitOrderPerItemList((prev)=>(
            [newOrderPerItemList,...prev]
        ))
      }

      

      const handleDeleteOrderItem=(id:number)=>{
        const filteredRows=limitOrderPerItemList.filter((each)=>each.id!==id)
        setLimitOrderPerItemList(filteredRows)

      }

      

      //table Edit functions
      const handleTableEditClick=(id:number)=>{
        setEditIsActive(true)
        const editedRow=rows.filter((each)=>each.id===id)[0]
        setNewRow(editedRow)
      }
      

      const handleTableEditSaveClick=()=>{
        if (newRow.pairedDevice !== "") {
            const existingRow = rows.find(row => row.id === newRow.id)
            if (existingRow) {
              const indexOfExistingRow = rows.indexOf(existingRow)
              rows[indexOfExistingRow] = newRow
              setRows(rows)
              setNewRow(initialNewRow)
            } else {
              setRows(prev => [...prev, newRow])
            }
            setNewRow(initialNewRow)
            setEditIsActive(false)
            setPairedDeviceError(false)
          } else {
            setPairedDeviceError(true)
      
          }


      }

     

      const handleTableEditCancelClick=()=>{
        setEditIsActive(false)
        setNewRow(initialNewRow)
      }

      const handleTableOnchange=(event:React.ChangeEvent<HTMLInputElement>)=>{
        const { name, value } = event.target;      
        setNewRow((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }

      //All checkbox input functions

      const [allCheckboxInputValues,setAllCheckboxInputValues]=React.useState(initialAllCheckboxInputValues)
      // const [CheckboxInputValuesInAccordion,setCheckboxInputValuesInAccordion]=React.useState(initialLimitOrderPerItemList[0].day)

      const handleCheckBoxInputValues=(event:React.ChangeEvent<HTMLInputElement>)=>{
        const { name, value, type, checked } = event.target;
        setAllCheckboxInputValues((prevState) => ({
          ...prevState,
          [name]: type === 'checkbox' ? checked : value,
        }))
      }
      const handleCheckBoxInputValuesInAccordion=(event:React.ChangeEvent<HTMLInputElement>,id:number)=>{ 
        const { name, value, type, checked } = event.target;
        const filtered=limitOrderPerItemList.map((each:any)=>{
          if(each.id===id){
            each.day[name]=checked
          } return each
        })
      
        setLimitOrderPerItemList(filtered)
      }

        
  return ( 
    <Box sx={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"flex-start"}}>
        <Box sx={{padding:"15px",display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}>
            <IconButton className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                <CloseIcon/>
            </IconButton>
            <Typography className='kds-settings-header'>{t("kds_settings.kds_settings")}</Typography>
            <Box sx={{width:"15%"}}>
                <Button sx={{textTransform:"none"}} color='warning' variant='outlined'>{t("kds_settings.clear")}</Button>
                <Button sx={{marginLeft:"15px",textTransform:"none"}} color='warning' variant='contained'>{t("kds_settings.save")}</Button>
            </Box>
        </Box>


        <Box sx={{padding:"20px",width:"100%"}}>
            <Typography sx={{margin:"20px",marginBottom:"10px"}} className='kds-settings-king-text'>{t("kds_settings.kingfield_branch")}</Typography>
            <Paper  sx={{padding:"15px",width:"50%",margin:"20px"}}>
                <Typography className='kds-settings-general-text'>{t("kds_settings.general_settings")}</Typography>
                <TableContainer sx={{padding:"10px",border:'1px solid #EEE'}} elevation={0}  component={Paper}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell className='kds-settings-table-header'>{t("kds_settings.food_station_name")}</TableCell>
                    <TableCell className='kds-settings-table-header' align="center">{t("kds_settings.paired_kds_device")}</TableCell>  
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell className='kds-settings-table-row' component="th" scope="row">
                        {row.stationName}
                    </TableCell>
                    <TableCell className='kds-settings-table-row' align="center">
                        {row.pairedDevice}
                        <IconButton onClick={()=>handleTableEditClick(row.id)} sx={{marginLeft:"10px"}}>
                            <EditIcon/>
                        </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
    {editIsActive &&(
        <Box sx={{display:"flex",padding:"10px",margin:"5px",alignItems:"center"}}>
            <TextField color='warning' sx={{flex:1,marginRight:"15px"}} disabled  value={newRow.stationName} variant="outlined" />
            <TextField name="pairedDevice" onChange={handleTableOnchange} color='warning' sx={{flex:1}} value={newRow.pairedDevice} variant="outlined" />
            <Box sx={{width:"25%",marginLeft:"10px"}}>
            <Button onClick={handleTableEditCancelClick} color='warning' variant='outlined'>{t("kds_settings.cancel")}</Button>
            <Button onClick={handleTableEditSaveClick} sx={{marginLeft:"10px"}}  color='warning' variant='contained'>{t("kds_settings.save")}</Button>
            </Box>
            
        </Box>
        
    )


    }
    {pairedDeviceError&&<Typography color="red">{t("kds_settings.please_add_paired_device")}</Typography>}
    </Paper>
        </Box>
        
        <Box>
        <KdsSettingsLayout/>
        </Box> 
           <Box>     
            <Paper sx={{margin:"30px",padding:"15px"}}>
                   
                <FormControl>
                    <RadioGroup
                        defaultValue="manualQuotingTime"
                        name='manualQuotingTime'
                        onChange={handleManualQuotingTime}
                    >
                        <Box sx={{display:"flex",alignItems:"center"}}>
                        <Box sx={{border:"1px solid #EEE",padding:"20px",width:"360px",height:"120px"}}>
                        <FormControlLabel  value="manualQuotingTime" control={<Radio color='warning' />} label={
                           <Box>
                             <Typography className='kds-settings-radio-box-label1'>{t("kds_settings.manual_quoting_time")}</Typography>
                            <Typography className='kds-settings-radio-box-label2'>{t("kds_settings.set_quote_times_manually")}</Typography>
                           </Box>
                        } />
                        </Box>
                        <Box sx={{border:"1px solid #EEE",padding:"20px",width:"360px",height:"120px",marginLeft:"20px"}}>
                        <FormControlLabel  value="kitchenCapacityQuotingTime" control={<Radio color='warning' />} label={
                           <Box>
                           <Typography className='kds-settings-radio-box-label1'>{t("kds_settings.kitchen_capacity_quoting_time")}</Typography>
                          <Typography className='kds-settings-radio-box-label2'>{t("kds_settings.set_quote_times")}</Typography>
                         </Box>
                        } />
                        </Box>
                        </Box>
                        
                    </RadioGroup>
                    </FormControl>

                    {
                      manualQuotingTime==="manualQuotingTime" && (<ManualQuotingTime/>)
                    }
                     {
                      manualQuotingTime!=="manualQuotingTime" && (<KitchenCapacityQuotingTime/>)
                    }
            </Paper>
           </Box> 
          <Box>
          <KdsSettingsOrders/>
          </Box>         
    </Box>
  )
}

export default KdsSettings
