import * as React from 'react';
import { useState } from 'react';
import { ChangeEvent } from 'react';

import Box from '@mui/material/Box';
import { DataGrid, GridColDef} from '@mui/x-data-grid';
import { MoreVert } from '@mui/icons-material';
import { Button, Switch, Typography } from '@mui/material';
import './menuListing.css'
import { Popover, List, ListItem, ListItemText ,Divider,IconButton} from '@mui/material';
import { useNavigate } from 'react-router';

const rows = [
  { id: 1, menuName: 'Lunch', status: false, location: "all Locations",currentVersion:"version 2.30",updatedOn:'20-08-23' },
  { id: 2, menuName: 'Dinner', status: false, location: "all Locations",currentVersion:"version 2.30",updatedOn:'20-08-23' },
  { id: 3, menuName: 'Happy hours', status: false, location: "all Locations" ,currentVersion:"version 2.30",updatedOn:'20-08-23'},
  { id: 4, menuName: 'Snacks', status: false, location: "all Locations" ,currentVersion:"version 2.30",updatedOn:'20-08-23'},
  { id: 5, menuName: 'Breakfast', status: false, location: "all Locations",currentVersion:"version 2.30",updatedOn:'20-08-23' },
  { id: 6, menuName: 'Menu2', status: false, location: "all Locations",currentVersion:"version 2.30",updatedOn:'20-08-23' },
  { id: 7, menuName: 'Deserts', status: false, location: "all Locations" ,currentVersion:"version 2.30",updatedOn:'20-08-23'},
  { id: 8, menuName: 'sweets', status: false, location: "all Locations" ,currentVersion:"version 2.30",updatedOn:'20-08-23'},
  { id: 9, menuName: 'Salads', status: false, location: "all Locations" ,currentVersion:"version 2.30",updatedOn:'20-08-23'},
];

function CustomPopoverCell({ value }: { value: number }) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    const navigate=useNavigate()
    
  
    const handleHistoryNavigate=()=>{
      navigate("/menu-history")
    }
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    const popoverContent = (
      <List style={{ padding: '0px 0px 0px 0px' }}>
        <Button style={{ padding: '0px 0px 0px 0px' }}>
          <ListItem style={{ width: '180px', height: '40px' }} className='menu-Listing-pover-text-style'>
            <ListItemText primary="Edit" />
          </ListItem>
        </Button>
        <Divider />
        <Button style={{ padding: '0px 0px 0px 0px' }}>
          <ListItem style={{ width: '180px', height: '40px' }} className='menu-Listing-pover-text-style'>
            <ListItemText primary="Delete" />
          </ListItem>
        </Button>
        <Divider />
        <Button style={{ padding: '0px 0px 0px 0px' }}>
          <ListItem style={{ width: '180px', height: '40px' }} className='menu-Listing-pover-text-style'>
            <ListItemText primary="History" onClick={handleHistoryNavigate}/>
          </ListItem>
        </Button>
        <Divider />
        <Button style={{ padding: '0px 0px 0px 0px' }}>
          <ListItem style={{ width: '180px', height: '40px' }} className='menu-Listing-pover-text-style'>
            <ListItemText primary="Duplicate" />
          </ListItem>
        </Button>
      </List>
    );
  
    return (
      <Box>
        <div>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>
  
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{ width: '180px', height: '225px', paddingTop: '0px', paddingBottom: '0px' }}
            elevation={8}
          >
            {popoverContent}
          </Popover>
        </div>
      </Box>
    );
  }

 const MenuListing=()=> {
    const [updatedRows, setUpdatedRows] = useState(rows);

  const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    const updatedRow = updatedRows.map((row) =>
      row.id === id ? { ...row, status: event.target.checked } : row
    );
    setUpdatedRows(updatedRow);


  };

  const navigate=useNavigate()
  const handleNavigation=()=>{
    navigate('/menu-setup')
  }

  
  const handleRowClass=()=>{
    return(
        "menuListingRow"
    )
  }

  const handleCellClass=()=>{
    return(
        "menuListigCell"
    )
  }
    const columns: GridColDef[] = [
 
        {
          field: 'menuName',
          headerName: 'Menu Name',
          headerClassName:"menuListingHeader",
        flex:1,
         
        },
        {
          field: 'status',
          headerName: 'Status',
          headerClassName:"menuListingHeader",
          flex:1,
          renderCell:(params)=>{
              return(
                <Switch
                checked={params.row.status}
                onChange={(event) => handleStatus(event, params.row.id)}
                color='warning'
              />
              )
          }
        },
        {
          field: 'location',
          headerName: 'Location',
          headerClassName:"menuListingHeader",
          flex:1,
         
        },
        {
          field: 'currentVersion',
          headerName: 'Current version',
          headerClassName:"menuListingHeader",
          flex:1,
        
        },
        {
          field: 'updatedOn',
          headerName: 'Updated on',
          headerClassName:"menuListingHeader",
          flex:1,
        
        },
        {
          field: '',
          headerName: '',
         disableColumnMenu:true,
          width:40,
         filterable:false,

         
    renderCell: (params) => {
      return (
        <CustomPopoverCell
          value={params.row.category}
       
        />
      );
    
    },
        
        },
      ];
      
  return (
    <Box>
        <Box sx={{display:"flex",alignItems:'center',justifyContent:'space-between',padding:"1%"}}>
            <Typography className='menuListigmenu'>Menu Listing</Typography>
            <Button className='menuListigButton' onClick={handleNavigation}> Create Menu</Button>
        </Box>
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={updatedRows}
        getRowClassName={handleRowClass}
        getCellClassName={handleCellClass}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[5]}
        
        disableRowSelectionOnClick
      />
    </Box>
    </Box>
  );
}
export default MenuListing