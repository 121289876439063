import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Typography, Button, Paper, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../options/createProduct.css'
import './createCategory.css'
import { t } from 'i18next';

import Visibility from './visibility';
import { Stepper, Step, StepButton } from '@mui/material';

import CategoryDetails from './categoryDetails';
import CategoryAssignProduct from './categoryAssignProduct';
import OptionsModal from './optionsGropModal';
import CategoryAditionalDetails from './categoryAditionalDetalis';
import OptionsDailouge from './optionsDailougeBox';
import { Divider } from 'primereact/divider';
import AditionalDetailsDailouge from './categoryAdvanceDetailsDailouge';
import { ADD_ADITIONAL_DETAILS } from '../../graphicalQl/mutation/mutation';
import { useMutation } from '@apollo/client';
import {  toast } from 'react-toastify';

const steps = ['General Details', 'Assign Products', 'Assign Options',"Advanced Settings"];


  
const CategoryMain=()=>{
    const navigate=useNavigate()

    const handleNavigateTolisting=()=>{
     navigate('/category-listing')
     
     
    }

    

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{
      [k: number]: boolean;
    }>({});
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step: number) => {
      setActiveStep(step);
    };

    const handleStepByProductModal=()=>{
      setActiveStep(0)
    }
    const handleStepByOptionModal=()=>{
      setActiveStep(1)
    }
    const handleStepByProductSetting=()=>{
      setActiveStep(0)
    }
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    // const handleReset = () => {
    //   setActiveStep(0);
    //   setCompleted({});
    // };

    const [getData,setGetData]=useState([])
    const handleTime = () => {
  
    }

  
  
    const getDataFromVisibility=(data:any)=>{
      setGetData(data)
    }
    const [getDate,setGetDate]=useState(new Date())
 
    const handleDate=(data:any)=>{
      setGetDate(data)
    }
const [categoryId,setCategoryId]=useState<string>("")

    const getCategoryId=(id:string)=>{

      setCategoryId(id)
    }
    const [seoKeyword,setSeoKeyword]=useState([])

    const [metaKeyword,setmetaKeyword]=useState([])
    const [meta,setmeta]=useState("")
const handleAditionalSettingData=(seoKeyword:any,metaKeyword:any,meta:string)=>{
  setSeoKeyword(seoKeyword)
  setmetaKeyword(metaKeyword)
  setmeta(meta)
}

const [addAditionalDetails]=useMutation(ADD_ADITIONAL_DETAILS,{ context: { clientName: 'categoryClient' }})
const handleFinishButton=()=>{
  addAditionalDetails({variables:{categoryId:categoryId,businessId:2,metatagDescription:meta,metatagKeywords:JSON.stringify(metaKeyword),seoKeyword:JSON.stringify(seoKeyword),showOn:JSON.stringify(getData),hideUntil:getDate}})

  toast( <div style={{ display: 'flex', alignItems: 'center',height: '8px',justifyContent:"center" }}>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="white"/>
</svg>
  <Typography>Changes saved Sucessfully</Typography>
</div>,
  {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    className: 'custom_toast3',
  })
}

    return(
        <Box>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "1%", backgroundColor: "white",width:"60%" }}>
  <IconButton className="create-product-exit" onClick={handleNavigateTolisting}>
    <ArrowBackIcon className="create-product-exitIcon" />
  </IconButton>
 
    <Typography className="create-product-head">{t("menu.catageory.createACategory")}</Typography>
 
    

</div>
<Box sx={{ width: '100%' }}>
<Stepper nonLinear activeStep={activeStep} orientation="vertical">
      {steps.map((label, index) => (
        <Step key={label} completed={completed[index]}>
          <StepButton
            onClick={() => handleStep(index)}
           

            icon={
              completed[index] ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z" fill="#14B8A6"/>
</svg> 
              ) : (
                index === activeStep ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z" fill="#F38B08"/>
</svg> 
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M16.59 7.58L10 14.17L6.41 10.59L5 12L10 17L18 9L16.59 7.58ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#ABABAB"/>
                  </svg> 
                )
              )
            }
          >
            {completed[index] ? (
              <Typography sx={{ color: "#14B8A6" }}>{label}</Typography>
            ) : (
              index === activeStep ? (
                <Typography sx={{ color: "#F38B08" }}>{label}</Typography>
              ) : (
                <Typography sx={{ color: "grey" }}>{label}</Typography>
              )
            )}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  
      <div style={{width:"90%"}}>
        {allStepsCompleted() ? (
          <React.Fragment>
            {/* <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box> */}
          </React.Fragment>
        ) : (
          <React.Fragment>
           <Box >
{activeStep===0 && ( <Paper elevation={2} style={{ marginLeft: "10%", marginRight: "15%", padding: '1%', borderLeft: "2px solid var(--stroke-color-1-violet, #AE9BE2)" }}><CategoryDetails getCategoryId={getCategoryId} handleComplete={handleComplete}/>

</Paper>)}
{activeStep===1 && (<Paper elevation={2} style={{ marginLeft: "10%", marginRight: "15%", padding: '1%', borderLeft: "2px solid  var(--stroke-color-2-tale, #9BDAE2)" }}>

<CategoryAssignProduct id={categoryId}  handleStepByProductModal={handleStepByProductModal}/>
<Divider />
<Box sx={{display:"flex",alignItems:"center"}}>
<Button className='create_category_BacK_button' onClick={handleBack}>{<ArrowBackIcon/>} Back</Button>
<Button className='createCategory-next-active' sx={{marginLeft:"3px"}} onClick={handleComplete}>Next {<ArrowForwardIcon color='warning'/>}</Button>
</Box>
</Paper>)}
{activeStep===2 && (<Paper elevation={2} style={{ marginLeft: "10%", marginRight: "15%", marginTop: "2%", padding: '1%', borderLeft: "2px solid  var(--stroke-color-2-tale, #9BDAE2)" }}>
<Box sx={{textAlign:"center",marginTop:"3%"}}>
          <Typography className='assign_product_text'>Click on <span className='assign_product_text2'>assign options button</span> to add products to this category</Typography>
          </Box>    
<OptionsDailouge categoryId={categoryId} handleStepByOptionModal={handleStepByOptionModal}/>
       <OptionsModal categoryId={categoryId} />

       <Divider />
<Box sx={{display:"flex",alignItems:"center"}}>
<Button className='create_category_BacK_button' onClick={handleBack}>{<ArrowBackIcon/>} Back</Button>
<Button className='createCategory-next-active' sx={{marginLeft:"3px"}} onClick={handleComplete}>Next {<ArrowForwardIcon color='warning'/>}</Button>
</Box>
     </Paper>)}         

            {activeStep===3 && (  <Paper elevation={2} style={{ marginLeft: "6%", marginRight: "1%", marginTop: "2%", padding: '1% 1% 1% 2%', borderLeft: "2px solid  var(--stroke-color-4-brown, #E2A39B)" }}><Visibility handleTime2={handleTime} handleData={getDataFromVisibility} handleDate={handleDate}/>
             { <CategoryAditionalDetails handleAditionalSettingData={handleAditionalSettingData}/> }
             <AditionalDetailsDailouge categoryId={categoryId} handleStepByProductSetting={handleStepByProductSetting} />
             <Divider />
<Box sx={{display:"flex",alignItems:"center"}}>
<Button className='create_category_BacK_button' onClick={handleBack}>{<ArrowBackIcon/>} Back</Button>
<Button className='createCategory' sx={{marginLeft:"3px"}} onClick={()=>{handleComplete();handleNavigateTolisting();handleFinishButton()}} >Finish </Button>
</Box>
            </Paper>)}
           

            {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))}
            </Box> */}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
   
        </Box>
    )
}
export default CategoryMain