import React, { useContext } from "react";
import { GoogleMap, MarkerF, CircleF, useJsApiLoader } from "@react-google-maps/api";
import UseLocationData from "../../context/UseLocationData";



const containerStyle = {
  height: "765px",
  width: "100%",
};
const locationKey = process.env.REACT_APP_GOOGLE_MAPS_API || "";

const GoogleMapForDistance = () => {
  const { locationData, handlePositionUpdate } = useContext(UseLocationData);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: locationKey,
  });
  const handleMarkerDragEnd = async (e:any) => {
    const { latLng } = e;
    const latitude = latLng.lat();
    const longitude = latLng.lng();
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${locationKey}`
    );
    if (response.ok) {
      const data = await response.json();
      const getAddressComponent = (type:any) => {
        const component = data.results[0].address_components.find((c:any) =>
          c.types.includes(type)
        );
        return component ? component.long_name : "";
      };

      const address1 = getAddressComponent("route");
      const address2 = getAddressComponent("sublocality_level_1");
      const city = getAddressComponent("locality");
      const street = getAddressComponent("street_number");
      const state = getAddressComponent("administrative_area_level_1");
      const pincode = getAddressComponent("postal_code");
      handlePositionUpdate({
        latitude,
        longitude,
        addressLine1: address1,
        addressLine2: address2,
        street: street,
        city: city,
        state: state,
        postCode: pincode,
      });
    }
  };
  const calculateZoomLevel = (meters:any) => {
    const MAX_ZOOM = 16; // Maximum available zoom level
    const MIN_ZOOM = 0; // Minimum available zoom level

    const zoom = MAX_ZOOM - Math.floor(Math.log2(meters / 260)); // Adjust the divisor as needed

    return Math.max(Math.min(zoom, MAX_ZOOM), MIN_ZOOM);
  };
  return (
    isLoaded ? <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: locationData.latitude,
        lng: locationData.longitude,
      }}
      zoom={calculateZoomLevel(locationData.radius ?? 0)}
    >
      <MarkerF
        position={{
          lat: locationData.latitude,
          lng: locationData.longitude,
        }}
        draggable={true}
        onDragEnd={handleMarkerDragEnd}
      />

     
         <CircleF
         center={{
           lat: locationData.latitude,
           lng: locationData.longitude,
         }}
         radius={parseInt(locationData.radius)} // In Meters
         options={{
           strokeColor: locationData.stroke ?? "#ffbc0d",
           strokeOpacity: 1,
           strokeWeight: 3,
           fillColor: null,  
           fillOpacity: 0.10,
         }}
       />
     
    </GoogleMap> : "Loading..."
  );
};
export default GoogleMapForDistance;
