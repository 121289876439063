import React from "react";
import { Paper, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from "react-router";

const Dashboard = () => {
  const navigate=useNavigate()
  const accessToken=sessionStorage.getItem("token")
  
  if(accessToken===null){
    navigate("/sign-up")

  }
  const breadcrumbs = [
    <Typography className="primaryColor" key="2">
      Dashboard
    </Typography>,
  ];
  return (
    <div className="dashboard">
      <Paper className="paper">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
        <Divider className="divider" />
      </Paper>
    </div>
  );
};

export default Dashboard;
