import { ImageOutlined } from "@mui/icons-material";
import React, {
  useState,
  useRef,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  forwardRef,
  useImperativeHandle,
} from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

interface ImageUploaderProps {
  image: string;
  setImage: Dispatch<SetStateAction<string>>;
  ref?: React.Ref<unknown>;
}

const UploadImage: React.FC<ImageUploaderProps> = forwardRef(
  ({ setImage, image }, ref) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(image);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const image = reader.result as string;
        setSelectedImage(image);
        setImage(image);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    };

    const handleButtonClick = () => {
      fileInputRef.current?.click();
    };
    const crossStyle = {
      position: "absolute",
      top: "-10px",
      right: "-20px",
      cursor: "pointer",
    } as const;

    const clearImage = (): void => {
      setImage("");
      setSelectedImage("");
    };
    useImperativeHandle(ref, () => ({ clearImage }));

    return (
      <div>
        <div className="uploadImageHolder">
          {selectedImage ? (
            <>
              <img
                src={selectedImage}
                alt="Uploaded"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
              <CancelOutlinedIcon onClick={clearImage} style={crossStyle} />
            </>
          ) : (
            <ImageOutlined fontSize="large" />
          )}
        </div>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />
        <button
          type="button"
          className="uploadButton"
          onClick={handleButtonClick}
        >
          Upload
        </button>
      </div>
    );
  }
);

export default UploadImage;
