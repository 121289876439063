import React from 'react'
import { Box, Button, IconButton, Switch, Typography } from '@mui/material';
import "./tillSettings.css"

function TillSettings() {
    const [addCashSwitch,setAddCashSwitch]=React.useState(false)
    const [withdrawCashSwitch,setWithdrawCashSwitch]=React.useState(false)
     
    const handleAddCashSwitch=()=>{
        setAddCashSwitch(!addCashSwitch)
    }
    const handleWithdrawCashSwitch=()=>{
        setAddCashSwitch(!withdrawCashSwitch)
    }

  return (
    <Box>
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"30%"}}>
        <Typography className='till-settings-allow-pos-text'>Allow POS user to Add Cash to the Till</Typography>
        <IconButton onClick={handleAddCashSwitch}>
        <Switch color='warning'/>
        </IconButton>
        </Box>
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"30%"}}>
        <Typography className='till-settings-allow-pos-text'>Allow POS user to Withdraw Cash from the Till</Typography>
        <IconButton onClick={handleWithdrawCashSwitch}>
        <Switch color='warning'/>
        </IconButton>
        
        </Box>
        <Button className='till-settings-save-button' variant='contained'>Save</Button>

    </Box>
  )
}

export default TillSettings
