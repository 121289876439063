
import { Box, AccordionDetails, Accordion, AccordionSummary, FormGroup, Checkbox, Autocomplete, Typography, FormControlLabel, Radio, TextField, Button, IconButton, Paper, FormControl, RadioGroup, Switch } from '@mui/material'
import React from 'react'
import "./kdsSettings.css"
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { t } from "i18next"

const itemsList = ["pizza", "Biryani", "Dhum biryani", "Burger"]

const daysList = [{
    id: 1,
    day: "Monday"
},
{
    id: 2,
    day: "Tuesday"
},
{
    id: 3,
    day: "Wednesday"
},
{
    id: 4,
    day: "Thursday"
},
{
    id: 5,
    day: "Friday"
},
{
    id: 6,
    day: "Saturday"
},
{
    id: 7,
    day: "Sunday"
}

]

const initialLimitOrderPerItemList = [
    {
        id: 1,
        limitPerUser: "",
        item: itemsList[0],
        fromTiime: "",
        toTime: "",
        day: {
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false
        }
    }
]





const initialAllCheckboxInputValues = {
    displayPOSTerminal: false,
    displayOrderTypeWithEachOrder: false,
    displayOrderId: false,
    displaySeatNumberForEachItem: false
}

function KdsSettingsOrders() {
    const [limitOrderPerItemList, setLimitOrderPerItemList] = React.useState(initialLimitOrderPerItemList)
    const newOrderPerItemList = {
        id: limitOrderPerItemList.length + 1,
        limitPerUser: "",
        item: itemsList[0],
        fromTiime: "",
        toTime: "",
        repeatOn: "",
        day: {
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false
        }

    }
    //Radio buttons functions
    const handleNewOnlinePlaced = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
    }

    //Textfield functions
    const handleUserPerItem = (event: string, id: number) => {
        setLimitOrderPerItemList(prev =>
            prev.map((each) => each.id === id ? { ...each, limitPerUser: event } : each)
        )
    }

    const handleFromTime = (id: number, event: string) => {
        setLimitOrderPerItemList(prev =>
            prev.map((each) => each.id === id ? { ...each, fromTiime: event } : each)
        )
    }

    const handleToTime = (id: number, event: string) => {
        setLimitOrderPerItemList(prev =>
            prev.map((each) => each.id === id ? { ...each, toTime: event } : each)
        )
    }

    const handleAutoComplete = (event: any, newValue: any, id: number) => {
        setLimitOrderPerItemList(prev =>
            prev.map((each) => each.id === id ? { ...each, item: newValue } : each)
        )
    }

    //Add new Limit Orders per Item
    const handleAddNewButton = () => {

        setLimitOrderPerItemList((prev) => (
            [newOrderPerItemList, ...prev]
        ))
    }
    const handleDeleteOrderItem = (id: number) => {
        const filteredRows = limitOrderPerItemList.filter((each) => each.id !== id)
        setLimitOrderPerItemList(filteredRows)
    }

    //All checkbox input functions

    const [allCheckboxInputValues, setAllCheckboxInputValues] = React.useState(initialAllCheckboxInputValues)
    // const [CheckboxInputValuesInAccordion,setCheckboxInputValuesInAccordion]=React.useState(initialLimitOrderPerItemList[0].day)

    const handleCheckBoxInputValues = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
        setAllCheckboxInputValues((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }))
    }
    const handleCheckBoxInputValuesInAccordion = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const { name, value, type, checked } = event.target;
        const filtered = limitOrderPerItemList.map((each: any) => {
            if (each.id === id) {
                each.day[name] = checked
            } return each
        })

        setLimitOrderPerItemList(filtered)
    }
    return (
        <Box sx={{ width: "160%",margin:"30px" }}>
            <Paper sx={{ padding: "15px" }} elevation={2}>
                <Typography className='kds-settings-dinein-text'>{t("kds_settings.orders")}</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "25%" }}>
                    <Typography className='kds-settings-dinein-text'>{t("kds_settings.show_online_orders")}</Typography>
                    <Switch color='warning' />
                </Box>
                <Typography className='kds-settings-dinein-text'>{t("kds_settings.show_online_orders_when")}</Typography>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="newOnlineOrderIsPlaced"
                        name="radio-buttons-group"
                        onChange={handleNewOnlinePlaced}
                    >

                        <Box sx={{ padding: "8px", margin: "10px", border: "1px solid #EEE", width: "95%", borderRadius: "8px" }}>
                            <FormControlLabel value="newOnlineOrderIsPlaced" control={<Radio color='warning' sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: 27,
                                },
                            }} />}
                                label={<Typography className='kds-settings-if-order-text'>{t("kds_settings.new_online_order_is_placed")}</Typography>} />
                        </Box>
                        <Box sx={{ padding: "8px", margin: "10px", border: "1px solid #EEE", width: "95%", borderRadius: "8px" }}>
                            <FormControlLabel value="onlineOrderIsMarkedInProgress" control={<Radio color='warning' sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: 27,
                                },
                            }} />}
                                label={<Typography className='kds-settings-if-order-text'>{t("kds_settings.online_order_is_marked_in_progress")}</Typography>} />
                        </Box>
                    </RadioGroup>
                </FormControl>
                <Box>
                    <Typography className='kds-settings-dinein-text'>{t("kds_settings.display")}</Typography>
                    <FormControl>
                        <FormGroup>
                            <Box sx={{ padding: "8px", margin: "10px", border: "1px solid #EEE", width: "95%", borderRadius: "8px" }}>
                                <FormControlLabel
                                    control={<Checkbox onChange={handleCheckBoxInputValues} name="displayPOSTerminal" color='warning' sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }} />} label={<Typography className='kds-settings-if-order-text'>{t("kds_settings.display_pos_terminal")}</Typography>} />
                            </Box>
                            <Box sx={{ padding: "8px", margin: "10px", border: "1px solid #EEE", width: "95%", borderRadius: "8px" }}>
                                <FormControlLabel control={<Checkbox onChange={handleCheckBoxInputValues} name='displayOrderTypeWithEachOrder' color='warning' sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }} />} label={<Typography className='kds-settings-if-order-text'>{t("kds_settings.display_order_type")}</Typography>} />
                            </Box>
                            <Box sx={{ padding: "8px", margin: "10px", border: "1px solid #EEE", width: "95%", borderRadius: "8px" }}>
                                <FormControlLabel control={<Checkbox onChange={handleCheckBoxInputValues} name='displayOrderId' color='warning' sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }} />} label={<Typography className='kds-settings-if-order-text'>{t("kds_settings.display_order_id")}</Typography>} />
                            </Box>
                            <Box sx={{ padding: "8px", margin: "10px", border: "1px solid #EEE", width: "95%", borderRadius: "8px" }}>
                                <FormControlLabel control={<Checkbox onChange={handleCheckBoxInputValues} name='displaySeatNumberForEachItem' color='warning' sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }} />} label={<Typography className='kds-settings-if-order-text'>{t("kds_settings.dispaly_seat")}</Typography>} />
                            </Box>
                        </FormGroup>
                    </FormControl>
                </Box>
                <Box>
                    <Typography className='kds-settings-dinein-text'>{t("kds_settings.limit_item_per_user")}</Typography>
                    <Box sx={{ position: "static" }}>

                        {
                            limitOrderPerItemList.map(each => (
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "85%" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "70%" }}>

                                        <TextField
                                            onChange={(event) => handleUserPerItem(event.target.value, each.id)}
                                            value={each.limitPerUser}
                                            sx={{ width: "20%" }}
                                            color='warning'
                                            label={<Typography className='kds-settings-limit-per-label-text'>{t("kds_settings.limit_item_per_user")}</Typography>}
                                        />
                                        <Autocomplete
                                            value={each.item}
                                            onChange={(event, newValue) => handleAutoComplete(event, newValue, each.id)}
                                            options={itemsList}
                                            sx={{ width: "22%" }}
                                            renderInput={(params) => <TextField {...params}
                                                color='warning'
                                                label={<Typography className='kds-settings-limit-per-label-text'>{t("kds_settings.select_items")}</Typography>}
                                            />}
                                        />
                                        <TextField
                                            value={each.fromTiime}
                                            onChange={(event) => handleFromTime(each.id, event.target.value)}
                                            type='time'

                                            sx={{ width: "20%", margin: "15px" }}
                                            color='warning'
                                            label={<Typography className='kds-settings-limit-per-label-text'>{t("kds_settings.from_time")}</Typography>}
                                        />
                                        <TextField
                                            onChange={(event) => handleToTime(each.id, event.target.value)}
                                            type='time'
                                            value={each.toTime}
                                            sx={{ width: "20%", margin: "15px" }}
                                            color='warning'
                                            label={<Typography className='kds-settings-limit-per-label-text'>{t("kds_settings.to_time")}</Typography>}
                                        />
                                    </Box>
                                    <Accordion sx={{ width: "20%", position: "relative" }} elevation={1}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>{t("kds_settings.repeat_on")}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <Box>
                                                <FormGroup>

                                                    {daysList.map((checkboxDetails) => (

                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                name={checkboxDetails.day}
                                                                value={each.day}
                                                                onChange={
                                                                    (event) => handleCheckBoxInputValuesInAccordion(event, each.id)
                                                                }
                                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                color='warning' />}
                                                            key={each.id}
                                                            label={<Typography className='driver-petty-cash-popup-checkbox'>{checkboxDetails.day}</Typography>} />
                                                    )
                                                    )}

                                                </FormGroup>
                                            </Box>

                                        </AccordionDetails>
                                    </Accordion>

                                    <Box>
                                        <IconButton onClick={() => handleDeleteOrderItem(each.id)} sx={{ marginLeft: "15px" }} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Box>

                            ))
                        }
                        <Box>
                            <Button onClick={handleAddNewButton} className='kds-settings-add-new-button'>{t("kds_settings.add_new")}</Button>
                        </Box>
                    </Box>
                </Box>

            </Paper>
        </Box>
    )
}

export default KdsSettingsOrders
