import { Box, Typography,IconButton,Paper,InputBase,Autocomplete,TextField } from '@mui/material'
import React from "react"
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import SearchIcon from '@mui/icons-material/Search';
import dragAndDropImage from "../../assets/cartCrossDragDropImage.png"
import "./cartCrossSellingRightPart.css"



const sortItemsList=["Price High to Low","Price Low to High"]

const initialCourseItems=[
    {
      id:1,
      image:dragAndDropImage,
      course:"Cake",
      price:5
    },
    {
      id:2,
      image:dragAndDropImage,
      course:"Extra Cheese",
      price:10
    },
    {
      id:3,
      image:dragAndDropImage,
      course:"Burger",
      price:13
    },
    {
      id:4,
      image:{dragAndDropImage},
      course:"Pizza",
      price:15
    },
    {
      id:5,
      image:dragAndDropImage,
      course:"French Fries",
      price:5
    },
  ]

function CartCrossSellingRightPart() {
    const [courseItems, setCourseItems] = React.useState(initialCourseItems);
    const handleDragEnd = (result:any) => {
        if (!result.destination) {
          return;
        }
        const reorderedItems = [...courseItems];
        const [movedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, movedItem);
    
        setCourseItems(reorderedItems);
      };

  return (
    <Box sx={{width:"50%",padding:"20px",marginTop:"15px"}}>
        <Typography className='cart-cross-selling-right-part-details-text'>Cart Cross Selling Item Selection</Typography>
        <Typography className='cart-cross-selling-right-part-select-items-text'>Select items to be offered to guests</Typography>
        <Box sx={{marginTop:"15px",marginBottom:"15px"}}>
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "80%" }}
            >
                 <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
           
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                //@ts-ignore
                placeholder="Search by name, option name"
                
            />    
            </Paper>
        </Box>
        <Box className="cart-cross-selling-left-box-border" sx={{padding:"15px",marginTop:"20px"}}>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <Typography className='cart-cross-selling-right-part-offered-items-text'>Offered Items</Typography>
                <Autocomplete            
                  id="combo-box-demo"
                  options={sortItemsList}
                  sx={{ width: "35%",marginTop:"10px",marginBottom:"10px" }}
                  renderInput={(params) => <TextField color='warning' {...params} label={<Typography className='cart-cross-selling-left-input-label'>{sortItemsList[0]}</Typography>} />}
                />
            </Box>
            <Box>
            <Paper sx={{padding:"20px"}} elevation={0}>
            <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="list">
                {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                    {courseItems.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.course} index={index}>
                        
                        {(provided) => (
                        <Box className="drag-and-drop-item-hover" sx={{display:"flex",justifyContent:"space-between",paddingLeft:"5px",paddingRight:"5px"}}>
                        <Box sx={{display:"flex",alignItems:"center"}}>
                        <img
                         {...provided.draggableProps}
                         {...provided.dragHandleProps}
                         ref={provided.innerRef}
                         className='drag-and-drop-item-image'
                          src={item.image} />

                        <Typography
                        sx={{marginLeft:"5px"}}
                        className='kds-setup-create-drag-text'
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                        >
                            {item.course}
                        </Typography>
                        </Box>
                        <Typography
                        sx={{marginRight:"5px"}}
                        className='kds-setup-create-drag-text'
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                        >
                            £ {item.price}
                        </Typography>
                        

                        </Box>
                       
                        )}
                    </Draggable>
                    ))}
                    {provided.placeholder}
                </Box>
                )}
            </Droppable>
            </DragDropContext>
      </Paper>
            </Box>
        </Box>
        
    </Box>
  )
}

export default CartCrossSellingRightPart
