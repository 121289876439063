import React,{useEffect,useState} from "react"
import { Button, Paper, Typography, TextField, Switch, Divider, Checkbox, FormGroup, FormControlLabel, IconButton, Box } from '@mui/material';
import { USER_ACCESS } from '../../graphicalQl/mutation/userProfileMutation'; 
import { No_OF_PERMISSIONS } from '../../graphicalQl/usequery/userProfileUseQuery';
import { useMutation,useQuery } from '@apollo/client';
import "./userAccess.css"
import {t} from "i18next"

interface PermissionList{
  name: string
  appliesFor:string
  permissionId:string

}





const UserAccess = () => {
  
  const [createUser] = useMutation(USER_ACCESS, {
    onCompleted: (data) => {
      console.log(data)
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: 'userClient' }
  });

  
  const [permissionsList,setPermissionsList]=useState<PermissionList[]>([])
  const {data,loading,error}=useQuery(No_OF_PERMISSIONS,{context:{clientName:"userClient"}})

  
  useEffect(() => {
    if (data) {
      const requiredDataList = data.permissions.edges.map((each: any) => ({
        name: each.node.name,
        appliesFor:each.node.appliesFor,
        permissionId:each.node.permissionId
      }

      ))
      setPermissionsList(requiredDataList)
    }
  }, [data]);

  const [posPermissionList, setPosPermissionList] = useState<any>([])
  const [adminPermissionList, setAdminPermissionList] = useState<any>([])

  const gettingPermissionsListSeperate=()=>{
    const emptyPosPermissionList:any=[]
    const emptyAdminPermissionList:any=[]

    permissionsList.map((eachPermission:any)=>{
      const {appliesFor}=eachPermission   
      if(JSON.parse(appliesFor).length===1 && JSON.parse(appliesFor)[0]==="pos"){
          emptyPosPermissionList.push(eachPermission)
      }else if (JSON.parse(appliesFor).length===1 && JSON.parse(appliesFor)[0]==="admin"){
        emptyAdminPermissionList.push(eachPermission)
        
      }else{
        emptyPosPermissionList.push(eachPermission)
        emptyAdminPermissionList.push(eachPermission)
      }
      
    })

    setPosPermissionList(emptyPosPermissionList)
    setAdminPermissionList(emptyAdminPermissionList)
  }
  useEffect(()=>{
    gettingPermissionsListSeperate()
  },[permissionsList])


  const [mutualIdList,setMutualIdList]=React.useState<string[]>([])

  const handleCheckbox1=(id:string,isChecked:boolean)=>{
      // const filteredList = firstCheckboxData.map((each) => {
      //   if (each.id === id) {
      //     return { ...each, isChecked: !each.isChecked }
      //   } return each
      // })
      setMutualIdList((prev)=>([...prev,id]))
    

  }
  const handleCheckbox2=(id:string,isChecked:boolean)=>{
    // const filteredList = firstCheckboxData.map((each) => {
    //   if (each.id === id) {
    //     return { ...each, isChecked: !each.isChecked }
    //   } return each
    // })
    setMutualIdList((prev)=>([...prev,id]))
  

}

console.log(permissionsList)

const filterUnique = (value:string, index:number, self:any) => {
  return self.indexOf(value) === index;
};
const uniqueIdList=mutualIdList.filter(filterUnique);

  const handleSubmitButton=(e:React.FormEvent<HTMLFormElement>)=>{
    e.preventDefault()
    createUser({ variables: {employeeId:2,permissions: JSON.stringify(uniqueIdList) } })

  }
  return (
    <Box sx={{backgroundColor:"#f9f9f9",padding:"20px"}}>
       <form onSubmit={(e)=>handleSubmitButton(e)}>
       <Box sx={{padding:"10px"}}>
            <div className='user-access-header-order'>
            <div>
                <Typography className='user-access-checkbox-heading'>2 Step Verification User Access</Typography>
                <Typography className='user-access-checkbox-paragraph'>{t("user_access.enable_user_authentication")}</Typography>
            </div>
            <div>
                <Button type='submit' sx={{textTransform:"none"}} color='warning' variant='contained'>{t("user_access.save")}</Button>
            </div>
            </div>
            <Divider/>
            <div>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"flex-start",margin:"20px",width:"70%"}}>
              <Paper sx={{width:"45%",padding:"15px"}}>

                <FormGroup sx={{padding:"10px"}}>
                {posPermissionList.map((each:any)=>(
                  <FormControlLabel 
                  control={<Checkbox color='warning'/>}
                  onChange={()=>{handleCheckbox1(each.permissionId,each.isChecked)}}
                  label={<Typography className='user-access-checkbox-label'>{each.name}</Typography>}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} 
                  />
                ))}
                </FormGroup>
              
              </Paper>

              <Paper sx={{width:"45%",padding:"15px"}}>
            
                <FormGroup sx={{padding:"10px"}}>
                {adminPermissionList.map((each:any)=>(
                  <FormControlLabel  
                  onChange={()=>{handleCheckbox2(each.permissionId,each.isChecked)}}
                  control={<Checkbox color='warning'/>}
                  label={<Typography className='user-access-checkbox-label'>{each.name}</Typography>} 
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} 
                  />
                ))}
                </FormGroup>
              
              </Paper>
      </Box>
      </div>
            
        </Box>
      
       </form>
    </Box>
  )
}

export default UserAccess
