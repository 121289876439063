import React, { useState } from 'react';
// import { SketchPicker } from 'react-color';

const ColorDiagram = () => {
  const [color, setColor] = useState('#000000'); // Initial color

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      
      <div style={{ fontSize: '10px',width:"120px", margin: '20px',display:"flex" }}>
        <h1>{color}</h1>
        <div  style={{ backgroundColor: color, width: '20px', height: '20px', margin: '0 auto' }}></div>
      </div>
      {/* <SketchPicker color={color} onChange={handleColorChange} /> */}
      
    </div>
  );
};

export default ColorDiagram;
