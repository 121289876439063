import React,{useState} from 'react';
import { Divider ,TextField} from '@mui/material';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CloseOutlined } from '@mui/icons-material';
import './bulkAdjustment.css'
import { IconButton } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {t} from 'i18next'

interface Props{
    openModal:boolean
    handleClose: () => void;
}
// interface Radio{
//     id:number
//     value:string;
// }
interface RadioValue{
    id:number,
    value:string,
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
 
  boxShadow: 24,
  p: 4,
};




export default function BulkAdujustment(props:Props) {
    const {openModal,handleClose}=props
  const [open, setOpen] = React.useState(false);
  const radioGroup:RadioValue[]=[
    {id:1,value:t("menu.bulk_adjustment.all")},
    {id:2,value:t("menu.bulk_adjustment.category")},
    {id:3,value:t("menu.bulk_adjustment.bundles")},
    {id:4,value:t("menu.bulk_adjustment.products")},
    {id:5,value:t("menu.bulk_adjustment.options")},

]
  

  useEffect(() => {
    setOpen(openModal);
    
    
  }, [openModal]);

  const [selectedValue, setSelectedValue] = useState<string>(
    "All ( Categories + Bundles + Products+Options)"
  );

  const handleRadioChange =(
    event: SelectChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValue(event.target.value as string);
  };

  const [selectedRound, setSelectedRound] = useState<string>(
    "none"
  );

  const handleRadioRound =(
    event: SelectChangeEvent<HTMLInputElement>
  ) => {
    setSelectedRound(event.target.value as string);
  };

  const [valueChange, setValueChange] = React.useState('increase');

  const handleChange = (event: SelectChangeEvent) => {
    setValueChange(event.target.value as string);
  };
  const [percentageValue, setPercentageValue] = React.useState<string>();

  const handlePercentageValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPercentageValue(event.target.value );
  };

  return (
    <div>
   
      <Modal
        open={open}
       
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between",background: "#F9F9F9",padding:"1%"}}>
            <IconButton className='bulk-adjustment-exit-icon'  onClick={handleClose}>
            <CloseOutlined />
            </IconButton>
            <Typography className='bulk-adjustment-text'>{t("menu.bulk_adjustment.bulk_adjustment_prices")}</Typography>
            <Button className='bulk-adjustment-button'>{t("menu.bulk_adjustment.save")}</Button>
          </Box>
          <Box sx={{display:"inline-flex",padding:"2%",width:"80%"}}>
            <Typography sx={{width:"20%"}}  className='bulk-adjustment-text4'>{t("menu.bulk_adjustment.apply")}</Typography>
            <FormControl sx={{marginLeft:"2%",width:"100%"}}>
    
      <RadioGroup
       value={selectedValue}
       onChange={(event)=>handleRadioChange(event)} 
        name="radio-buttons-group"
      >
        {radioGroup.map((each)=>(
 <FormControlLabel value={each.value} key={each.id} control={<Radio color='warning'/>} label={<Typography className='bulk-adjustment-text2'>{each.value}</Typography>} />
        ))}
        
      
      </RadioGroup>
    </FormControl>
          </Box>
          <Divider/>
          <Box sx={{display:'flex',alignItems:"center",justifyContent:"space-between",marginTop:'1%',paddingRight:"20%",marginBottom:'1%'}}>
            <Typography className='bulk-adjustment-text4'>{t("menu.bulk_adjustment.pricing")}</Typography>
            <FormControl >
        
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valueChange}
          
          onChange={handleChange}
          color='warning'
        >
          <MenuItem value={"increase"}>{t("menu.bulk_adjustment.increase")}</MenuItem>
          <MenuItem value={"decrease"}>{t("menu.bulk_adjustment.decrease")}</MenuItem>
         
        </Select>
      </FormControl>
      <Box sx={{display:"flex",alignItems:'center'}}>
      <TextField label={t("menu.bulk_adjustment.enter")} variant="outlined" sx={{width:'40%'}} color='warning' value={percentageValue} onChange={handlePercentageValue}/>
      <Typography className='bulk-adjustment-text3' >%</Typography>
      </Box>
          </Box>
          <Divider/>
          {/* <Box sx={{display:"inline-flex",padding:"2%",width:"80%",marginTop:'1%'}}>
          <Typography sx={{width:"20%"}}  className='bulk-adjustment-text4'>Rounding</Typography>
            <FormControl sx={{marginLeft:"2%",width:"100%"}}>
    
      <RadioGroup
       value={selectedRound}
       onChange={(event)=>handleRadioRound(event)} 
        name="radio-buttons-group"
      >
       
 <FormControlLabel value="none" control={<Radio color='warning'/>} label={<Typography className='bulk-adjustment-text2'>None</Typography>} />
 <FormControlLabel value="half_up"  control={<Radio color='warning'/>} label={<Typography className='bulk-adjustment-text2'>Half Up<span className='bulk-adjustment-text5'>( if 7.5 ==8.00)</span></Typography>} />
 <FormControlLabel value="half_down" control={<Radio color='warning'/>} label={<Typography className='bulk-adjustment-text2'>Half Down<span className='bulk-adjustment-text5'>(if 7.5==7.00)</span></Typography>} />
 <FormControlLabel value="upWards"  control={<Radio color='warning'/>} label={<Typography className='bulk-adjustment-text2'>Upwards <span className='bulk-adjustment-text5'>( if 7.62 ==8.00)</span></Typography>} />
 <FormControlLabel value="downWards"  control={<Radio color='warning'/>} label={<Typography className='bulk-adjustment-text2'>Downwards <span className='bulk-adjustment-text5'>(if 7.62==7.00)</span></Typography>} />
 <FormControlLabel value="upWardsTo"  control={<Radio color='warning'/>} label={<Typography className='bulk-adjustment-text2'>Upwards to .99<span className='bulk-adjustment-text5'>( if 7.62 ==7.99)</span></Typography>} />     
        
      
      </RadioGroup>
    </FormControl>
          </Box> */}
        </Box>
      </Modal>
    </div>
  );
}