
import { gql } from "@apollo/client";
export const EmplpoyeesList=gql`
query {
    employees(first:10,businessId:2) {
            pageInfo{hasNextPage
            hasPreviousPage}
            edges{node{employeeId
                firstName
                lastName
                mobile
                employeeStatus
				pin
                createdOn
                email
            role{roleId
							
            roleName
                }
                businessId
            }    
    }
    }
    }
`
export const LOCATIONS_LIST=gql`
query{
	storeList(first:10){
		pageInfo
		{
			
			hasNextPage
			hasPreviousPage
		}
		edges{
			
			node{
				storeId
				storeName
				brandLogo
				status
				id		
				
			}}
	}}

`

export const User_Roles=gql`
query {
    roles(first:10,businessId:2) {
            pageInfo{hasNextPage
            hasPreviousPage}
            edges{node{roleId
                roleName
                noOfUsers
                description    
                businessId
            }
        
    }
    }
    }

`

export const No_OF_PERMISSIONS=gql`
query {
    permissions(first:10) {
            pageInfo{hasNextPage
            hasPreviousPage}
            edges{node{permissionId
                name
                appliesFor
            }
        
    }
    }
    }


`

export const USER_REQUESTS=gql`
query {
    unblockRequestList(first:10,businessId:2) {
            pageInfo{hasNextPage
            hasPreviousPage}
            edges{node{employee{employeeId
            firstName
            lastName
            email
            mobile
            role{roleId
            roleName}
            pin
            createdOn}
            employeeStatus
                businessId
            }
        
    }
    }
    }
`

export const SEARCH_PERMISSIONS_BY_ROLE_ID = gql`
query role($roleId:ID){
    role(roleId:$roleId)
        {roleId
        permissions{permissionId
            appliesFor
        name}}
    }
  
`
export const GET_UPDATE_USER_DETAILS=gql`
query employee($employeeId: ID) {
    employee(employeeId: $employeeId) {
        employeeId
        firstName
        lastName
        locations
        mobile
        email		
        role{
            roleName
            roleId
        }
    }
  }

`

export const GET_EDIT_USER_ROLE=gql`
query RoleQuery($roleId: ID!) {
    role(roleId: $roleId) {
      roleId
      roleName
      permissions {
        permissionId
        appliesFor
        name
      }
    }
  }
  

`





