import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import Typography from "@mui/material/Typography";
import './rounding.css';

const Rounding: React.FC = () => {
    const { t } = useTranslation();

    const [checkedItems, setCheckedItems] = useState({
        discount: false,
        cart: false,
        taxes: false,
        tips: false,
    });

    const option1 = (
        <div className='round-wrap'>
            <Typography className="rounding_checkBox">{t("settings.discount")}</Typography>
        </div>
    );
    const option2 = (
        <div>
            <Typography className="rounding_checkBox">{t("settings.cart")}</Typography>
        </div>
    );
    const option3 = (
        <div>
            <Typography className="rounding_checkBox">{t("settings.taxes")}</Typography>
        </div>
    );
    const option4 = (
        <div>
            <Typography className="rounding_checkBox">{t("settings.tips")}</Typography>
        </div>
    );

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedItems({
            ...checkedItems,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <div>
            <Paper elevation={3} style={{ padding: "20px 20px 20px 10px" ,width:"100%"}} >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography className="rounding_titleValue">{t("settings.r_options")}</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button variant="contained" className="rounding_Button_clear">
                            {t("settings.clear")}
                        </Button>
                        <Button variant="contained" className="Rounding_save_button" style={{ marginLeft: "20px" }}>
                            {t("settings.save")}
                        </Button>
                    </div>
                </div>
                <Typography className='rounding_para' style={{ marginLeft: "58px",marginTop:"36px"}}>{t("settings.r_para")}</Typography>
                <div style={{ display: "flex", marginLeft: "36px", marginTop: '16px' }}>
                <div className="rounding_box_1_inactive" style={{ margin: "20px",borderLeft: "2px solid var(--stroke-color-1-violet, #AE9BE2)"  }}>
                        <Typography style={{ margin: "5px" }} className="rounding_button_innerStyle" >{t("settings.half_down")}</Typography>
                        <div style={{ display: "flex",alignItems:"center", margin: "5px" }}>
                            <Typography className='rounding_number'>£0.116</Typography>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="12" viewBox="0 0 25 12" fill="none" style={{ marginLeft: "7px", marginRight: "7px" }}>
                                <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z" fill="#707070" />
                            </svg>
                            <Typography className='rounding_number'>£0.12</Typography>
                        </div>
                        <div style={{ display: "flex",alignItems:"center", margin: "5px" }}>
                            <Typography className='rounding_number'>£0.116</Typography>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="12" viewBox="0 0 25 12" fill="none" style={{ marginLeft: "7px", marginRight: "7px" }}>
                                <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z" fill="#707070" />
                            </svg>
                            <Typography className='rounding_number'>£0.12</Typography>
                        </div>
                        <div style={{ display: "flex",alignItems:"center", margin: "5px" }}>
                            <Typography className='rounding_number'>£0.116</Typography>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="12" viewBox="0 0 25 12" fill="none" style={{ marginLeft: "7px", marginRight: "7px",color:"#707070" }}>
                                <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z" fill="#707070" />
                            </svg>
                            <Typography className='rounding_number'>£0.12</Typography>
                        </div>
                    </div>
                    <div className="rounding_box_1_inactive" style={{ margin: "20px",borderLeft: "2px solid var(--stroke-color-1-violet, #9BDAE2)" }}>
                        <Typography style={{ margin: "5px" }} className="rounding_button_innerStyle">{t("settings.half_up")}</Typography>
                        <div style={{ display: "flex",alignItems:"center", margin: "5px" }}>
                            <Typography className='rounding_number'>£0.116</Typography>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="12" viewBox="0 0 25 12" fill="none" style={{ marginLeft: "7px", marginRight: "7px" }}>
                                <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z" fill="#707070" />
                            </svg>
                            <Typography className='rounding_number'>£0.12</Typography>
                        </div>
                        <div style={{ display: "flex",alignItems:"center", margin: "5px" }}>
                            <Typography className='rounding_number'>£0.112</Typography>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="12" viewBox="0 0 25 12" fill="none" style={{ marginLeft: "7px", marginRight: "7px" }}>
                                <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z" fill="#707070" />
                            </svg>
                            <Typography className='rounding_number'>£0.11</Typography>
                        </div>
                        <div style={{ display: "flex",alignItems:"center", margin: "5px" }}>
                            <Typography className='rounding_number'>£0.125</Typography>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="12" viewBox="0 0 25 12" fill="none" style={{ marginLeft: "7px", marginRight: "7px" }}>
                                <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z" fill="#707070" />
                            </svg>
                            <Typography className='rounding_number'>£0.13</Typography>
                        </div>
                    </div>
                    <div className="rounding_box_1_inactive" style={{ margin: "20px",borderLeft: "2px solid var(--stroke-color-1-violet, #E2A39B)" }}>
                        <Typography style={{ margin: "5px" }} className="rounding_button_innerStyle">{t("settings.upwards")}</Typography>
                        <div style={{ display: "flex",alignItems:"center", margin: "5px" }}>
                            <Typography className='rounding_number'>£0.116</Typography>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="12" viewBox="0 0 25 12" fill="none" style={{ marginLeft: "7px", marginRight: "7px" }}>
                                <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z" fill="#707070" />
                            </svg>
                            <Typography className='rounding_number'>£0.12</Typography>
                        </div>
                        <div style={{ display: "flex",alignItems:"center", margin: "5px" }}>
                            <Typography className='rounding_number'> £0.112</Typography>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="12" viewBox="0 0 25 12" fill="none" style={{ marginLeft: "7px", marginRight: "7px" }}>
                                <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z" fill="#707070" />
                            </svg>
                            <Typography className='rounding_number'>£0.12</Typography>
                        </div>
                        <div style={{ display: "flex",alignItems:"center", margin: "5px" }}>
                            <Typography className='rounding_number'>£0.125</Typography>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="12" viewBox="0 0 25 12" fill="none" style={{ marginLeft: "7px", marginRight: "7px" }}>
                                <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z" fill="#707070" />
                            </svg>
                            <Typography className='rounding_number'>£0.13</Typography>
                        </div>
                    </div>
                    <div className="rounding_box_1_inactive" style={{ margin: "20px",borderLeft: "2px solid var(--stroke-color-1-violet, #E19BE2)" }}>
                        <Typography style={{ margin: "5px" }} className="rounding_button_innerStyle">{t("settings.downwards")}</Typography>
                        <div style={{ display: "flex",alignItems:"center", margin: "5px" }}>
                            <Typography className='rounding_number'>£0.116</Typography>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="12" viewBox="0 0 25 12" fill="none" style={{ marginLeft: "7px", marginRight: "7px" }}>
                                <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z" fill="#707070" />
                            </svg>
                            <Typography className='rounding_number'>£0.11</Typography>
                        </div>
                        <div style={{ display: "flex",alignItems:"center", margin: "5px" }}>
                            <Typography className='rounding_number'>£0.112</Typography>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="12" viewBox="0 0 25 12" fill="none" style={{ marginLeft: "7px", marginRight: "7px" }}>
                                <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z" fill="#707070" />
                            </svg>
                            <Typography className='rounding_number'>£0.11</Typography>
                        </div>
                        <div style={{ display: "flex",alignItems:"center", margin: "5px" }}>
                            <Typography className='rounding_number'>£0.125</Typography>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="12" viewBox="0 0 25 12" fill="none" style={{ marginLeft: "7px", marginRight: "7px" }}>
                                <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z" fill="#707070" />
                            </svg>
                            <Typography className='rounding_number'>£0.12</Typography>
                        </div>
                        </div>
                </div>
                <div className='display_rounding' style={{ marginLeft: "53px", marginTop: '16px', padding: "12px 12px 20px 12px" }}>
                    <Typography className="rounding_titleValue">{t("settings.dine_option")}</Typography>
                    <div style={{ marginLeft: "12px" }}>
                        <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={checkedItems.discount} onChange={handleCheckboxChange} name="discount" color='warning'/>}
                            label={option1}
                            className="rounding_chekbox_styling"

                        />
                        <FormControlLabel
                            control={<Checkbox checked={checkedItems.cart} onChange={handleCheckboxChange} name="cart"  color='warning'/>}
                            label={option2}
                            className="rounding_chekbox_styling"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={checkedItems.taxes} onChange={handleCheckboxChange} name="taxes"  color='warning' />}
                            label={option3}
                            className="rounding_chekbox_styling"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={checkedItems.tips} onChange={handleCheckboxChange} name="tips"  color='warning'/>}
                            label={option4}
                            className="rounding_chekbox_styling"
                        />
                        </FormGroup>
                    </div>
                </div>
            </Paper>
        </div>
    );
};

export default Rounding;
