import React, { useState, useEffect } from 'react';
import { Paper, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './advanceBooking.css';
import { t } from "i18next";
import EditIcon from '@mui/icons-material/Edit';
import './createSalesTax.css';
import { SelectChangeEvent } from '@mui/material';

const AdvanceBooking: React.FC = () => {
  const [selectedLocation, setSelectedValue] = useState<string>('');
  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    setSelectedValue(event.target.value);
  };

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/create_advance_booking");
  };

  interface UserData {
    id: number;
    location: string;
    status: string;
  }

  const [usersData, setUsersData] = useState<UserData[]>([]);

  useEffect(() => {
    axios.get('/advanceBooking.json')
      .then(response => {
        setUsersData(response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <div>
      <Paper elevation={3} style={{ paddingTop: "23px",width:"100%" }}>
        <div style={{ display: "flex", alignItems: "center", margin: "0px 0px 22px 32px" }}>
          <Typography className='booking_title' style={{ marginRight: '44px' }}>{t("settings.advanced_bookings")}</Typography>
          <FormControl className='booking_drop_down'>
            <InputLabel id="demo-simple-select-label">{t("settings.select_location")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={t("settings.select_location")}
              value={selectedLocation}
              onChange={handleLocationChange}
              color='warning'
              sx={{
                "&:hover": {
                  "&& fieldset": {
                    border: "2px solid #F9C584"
                  }
                }
              }}
            >
              <MenuItem value="Hyderabad">Hyderabad</MenuItem>
              <MenuItem value="Vizag">Vizag</MenuItem>
              <MenuItem value="Kakinada">Kakinada</MenuItem>
            </Select>
          </FormControl>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ paddingLeft: "60px" }}>
                <TableCell className='table_row_head' style={{ paddingLeft: '44px' }}>{t('settings.location')}</TableCell>
                <TableCell className='table_row_head'>{t("settings.status")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData.map((row) => (
                <TableRow key={row.id} className='table_row1'>
                  <TableCell className='table_row' style={{ paddingLeft: '44px' }}>{row.location}</TableCell>
                  <TableCell className='table_row'>{row.status}</TableCell>
                  <TableCell>
                    <div className='editIcon'>
                      <EditIcon style={{ color: 'var(--text-grey, grey)' }} onClick={handleNavigate} />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default AdvanceBooking;
