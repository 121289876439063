import {useState} from 'react';
import { Box,  Typography } from "@mui/material"
import image from '../../assets/userProfile.png'
import './profile.css'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
// import { styled } from '@mui/material/styles';
import ProfileTabs from './profileTab.tsx'



// import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

interface UserData {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  phoneNumber: string;
  presentDay: number;
  lastDay: number;
}
const userData:UserData[]=[{
    id:1,firstName:"Ashok",lastName:"kandregula",email:"ashokKandregula@hi.com",role:"owner",phoneNumber:"9848022338",presentDay:20,lastDay:35
}]
// const items = ["Epos (Digital and QR Menu)", "Admin App", "Restaurant Website/Mobile App"];

const Profile=()=>{
    const [imageValue, setImageValue] = useState<string | null>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageValue(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  // const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  //   height: 10,
  //   borderRadius: 5,
  //   [`&.${linearProgressClasses.colorPrimary}`]: {
  //     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  //   },
  //   [`& .${linearProgressClasses.bar}`]: {
  //     borderRadius: 5,
  //     backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  //   },
  // }));

    return(
      <Box sx={{display:"flex",padding:"1%"}}>
        <Box sx={{width:"20%"}}>
        <Box sx={{width:"100%",display:"flex",flexDirection:"column",justifyContent:'center',alignItems:"center"}} className="profile-mainCon">
            <Box className='profile-imagebackground'  id={imageValue ? "":"profile-image"}  style={{ position: 'relative',marginTop:"2%" }}>
  <img src={imageValue || image} alt="profile" style={{ width: "100%" }} />
  <div style={{ position: 'absolute', top:' 74%', left: "86%" }}>
    {/* You can replace 'YourIcon' with your actual icon component or element */}
    <Box className="profile-edit">
    <EditIcon color="action" sx={{fontSize:"22px"}} onClick={() => {
            const input = document.getElementById('image-upload-input');
            if (input) {
              input.click();
            }
          }}
        />
        <input
        type="file"
        hidden
        id="image-upload-input"
        onChange={handleImageUpload}
      />
    </Box>
  </div>
</Box>
<Typography className='profile-name'>{userData[0].firstName} {userData[0].lastName}</Typography>
<Typography className='profile-role'>{userData[0].role}</Typography>
        </Box>
        <Box sx={{width:"100%",display:"flex",flexDirection:"column",padding:"2%",paddingBottom:"10%"}} className="profile-mainCon">
<Typography className='profile-details'>Details</Typography>
<Box sx={{display:'flex',alignItems:'center',marginTop:"5%"}}>
    <EmailIcon color='action'/>
    <Typography className='profile-email' sx={{marginLeft:"5%"}}>{userData[0].email}</Typography>
</Box>
<Box sx={{display:'flex',alignItems:'center',marginTop:"5%"}}>
    <PhoneAndroidIcon color='action'/>
    <Typography className='profile-email' sx={{marginLeft:"5%"}}>{userData[0].phoneNumber}</Typography>
</Box>
        </Box>
        {/* <Box sx={{width:"100%",display:"flex",flexDirection:"column",padding:"2%"}} className="profile-mainCon">
            <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
            <Typography className='profile-text2'>Freemium Package</Typography>
            <Typography className='profile-text4'>£15<span className='profile-text3'>/month</span></Typography>
            </Box>
            <ul style={{marginTop:"3%",listStyleType:"none"}}>
                {items.map(each=><li className='profile-email'>{each}</li>)}
            </ul>
            <Box sx={{display:"flex",alignItems:"center",justifyContent:'space-between',marginTop:"2%"}}>
                <Typography className='profile-day'>Days</Typography>
                <Typography className='profile-date'>{userData[0].presentDay} of {userData[0].lastDay} days</Typography>
            </Box>
            <Box sx={{ flexGrow: 1 ,marginTop:"2%"}}>

            <BorderLinearProgress
  variant="determinate"
  value={(userData[0].presentDay / userData[0].lastDay) * 100}
/>

   </Box>
   <Typography className='profile-date' sx={{marginTop:"2%"}}>{userData[0].lastDay-userData[0].presentDay} days remaing</Typography>
   <Button className='profile-button'>Upgrade</Button>
   
        </Box> */}
        </Box>
        <Box sx={{width:"80%"}}>
          <ProfileTabs list={userData}/>
        </Box>
        </Box>
    )
}
export default Profile;