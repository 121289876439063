import React from 'react'
import { Box, Button, IconButton, Switch, Typography } from '@mui/material';
import "./posSettingsGeneralSettings.css"

function PosSettingsGeneralSettings() {
    const [addCashSwitch,setAddCashSwitch]=React.useState(false)
    const [withdrawCashSwitch,setWithdrawCashSwitch]=React.useState(false)
     
    const handleAddCashSwitch=()=>{
        setAddCashSwitch(!addCashSwitch)
    }
    const handleWithdrawCashSwitch=()=>{
        setAddCashSwitch(!withdrawCashSwitch)
    }

  return (
    <Box>
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"30%"}}>
        <Typography className='general-settings-allow-pos-text'>Allow Notifications</Typography>
        <IconButton onClick={handleAddCashSwitch}>
        <Switch color='warning'/>
        </IconButton>
        </Box>  
        <Button className='till-settings-save-button' variant='contained'>Save</Button>

    </Box>
  )
}

export default PosSettingsGeneralSettings
