import { Box, Button, IconButton, Typography, Divider, TextField } from '@mui/material'
import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LocationIdentifier from "../utils/locationIdentifier.tsx";
import RenderGoogleMap from "../utils/googleMaps";
import UseLocationData from '../../context/UseLocationData'
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import {t} from "i18next"

import "./areaWise.css"
import { SettingsSystemDaydream } from '@mui/icons-material';

interface State {
  id: number
  color: string
  distance: number
  cartAmount: number,
  shippingRate: number
  extraShipppingForLessCartAmount: number
  timeTakenForDelivery: string
  timeRange: string

}




const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: "8px",
  boxShadow: 10,
  pt: 2,
  px: 4,
  pb: 3,
};

const timePopupStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: "8px",
  boxShadow: 10,
  pt: 2,
  px: 4,
  pb: 3,
};



const initialDeleteRow = {
  id: null,
  color: "",
  distance: null,
  cartAmount: null,
  shippingRate: null,
  extraShipppingForLessCartAmount: null,
  timeTakenForDelivery: "",

}

const initialRows = [
  {
    id: 1,
    color: "#707070",
    distance: 100,
    cartAmount: 10,
    shippingRate: 1.50,
    extraShipppingForLessCartAmount: 1.50,
    timeTakenForDelivery: "60 min",
    timeRange: "11:30 to 16:30"

  },
  {
    id: 2,
    color: "red",
    distance: 300,
    cartAmount: 10,
    shippingRate: 1.50,
    extraShipppingForLessCartAmount: 1.50,
    timeTakenForDelivery: "60 min",
    timeRange: ""

  },
  {
    id: 3,
    color: "green",
    distance: 400,
    cartAmount: 10,
    shippingRate: 1.50,
    extraShipppingForLessCartAmount: 1.50,
    timeTakenForDelivery: "60 min",
    timeRange: ""
  },
  {
    id: 4,
    color: "blue",
    distance: 500,
    cartAmount: 10,
    shippingRate: 1.50,
    extraShipppingForLessCartAmount: 1.50,
    timeTakenForDelivery: "60 min",
    timeRange: ""
  },
]

interface TimeRange {
  startTime: string;
  endTime: string;
}

const initialTimeRange: TimeRange = {
  startTime: '',
  endTime: '',
};


function DistanceWise() {
  const [rows, setRows] = useState<State[]>(initialRows)

  const initialNewRow: State = {
    id: rows.length + 1,
    color: "#333333",
    distance: 0,
    cartAmount: 0,
    shippingRate: 0,
    extraShipppingForLessCartAmount: 0,
    timeTakenForDelivery: "",
    timeRange: ""
  }

  const initialAddTimeRow = {
    id: 0,
    color: "#333333",
    distance: 0,
    cartAmount: 0,
    shippingRate: 0,
    extraShipppingForLessCartAmount: 0,
    timeTakenForDelivery: "",
    timeRange: ""
  }
  
  const [locationData, setLocationData] = useState<{}>();
  const [deleteRow, setDeleteRow] = useState(initialDeleteRow)
  const [activeDistanceButton, setActiveDistanceButton] = useState(false)
  const [newRowCheckboxValues, setNewRowCheckboxValue] = useState<State>(initialNewRow)
  const [timeAddRow, setTimeAddRow] = useState<State>(initialAddTimeRow)
  const [distanceError, setDistanceError] = useState(false)
  const [setTimeButtonId, setSetTimeButtonId] = useState(0)





  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type, checked } = event.target;
    setNewRowCheckboxValue((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };


  const {
    setValue
  } = useForm()

  const handlePositionUpdate = (position: any) => {
    setLocationData(position);
    Object.entries(position).forEach(([key, value]) => {
      setValue(key, value);
    });

  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //time range picker functions

  const [timePickerOpen, setTimePickerOpen] = React.useState(false);
  const handleTimePickerOpen = () => setTimePickerOpen(true)
  const handleTimePickerClose = () => setTimePickerOpen(false)


  const combineOpenAndPassingData = (row: any) => {
    setDeleteRow(row)
    setOpen(true)

  }


  const handleSetTimeButton = (id: number) => {
    setTimePickerOpen(true)
    setSetTimeButtonId(id)




  }

  const handleNewRowSaveButton = () => {
    if (newRowCheckboxValues.distance !== 0) {
      const existingRow = rows.find(row => row.id === newRowCheckboxValues.id)
      if (existingRow) {
        const indexOfExistingRow = rows.indexOf(existingRow)
        rows[indexOfExistingRow] = newRowCheckboxValues
        setRows(rows)
        setNewRowCheckboxValue(initialNewRow)
      } else {
        setRows(prev => [...prev, newRowCheckboxValues])
      }

      setNewRowCheckboxValue(initialNewRow)
      setActiveDistanceButton(false)
      setDistanceError(false)
    } else {
      setDistanceError(true)

    }


  }

  const handleEditIcon = (id: number) => {

    setActiveDistanceButton(true)
    const filteredRow = rows.filter((each) => each.id === id)
    setNewRowCheckboxValue(filteredRow[0])

  }

  const handleDontRemoveButton = () => {
    setDeleteRow(initialDeleteRow)
    setOpen(false)
  }


  const combineDeleteRowAndPopupClose = () => {
    const { id } = deleteRow
    const filteredRow = rows.filter((each) => each.id !== id)
    setRows(filteredRow)
    setOpen(false)
  }

  const handlePopupTimeSaveButton = () => {
    const filtredRow = rows.find(row => row.id === setTimeButtonId)
    if (filtredRow && timeRange.startTime !== "" && timeRange.endTime !== "") {
      const updatedRow = { ...filtredRow, timeRange: `${timeRange.startTime} to ${timeRange.endTime}` }
      const indexOfExistingRow = rows.indexOf(filtredRow)
      rows[indexOfExistingRow] = updatedRow
      setRows(rows)
      setTimePickerOpen(false)
      setTimeRange(initialTimeRange)
    }
  }

  const handleOnDistanceButton = () => {
    setActiveDistanceButton(!activeDistanceButton)
  }

  const [timeRange, setTimeRange] = useState<TimeRange>(initialTimeRange);


  const handleTimeRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTimeRange((prevRange) => ({
      ...prevRange,
      [name]: value,
    }));

  };



  return (

    <Box sx={{ padding: "15px" }}>
      <UseLocationData.Provider value={{ locationData, handlePositionUpdate }}>     
                <Box sx={{borderRadius:"5px",width:"100%"}} className="mapContainer" id="map-canvas">
                    <LocationIdentifier />
                    <RenderGoogleMap rows={rows} />
                </Box>                   
        </UseLocationData.Provider>
      <Box>
        <TableContainer component={Paper} elevation={0}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className='area-wise-table-column' >{t("delivery_zone.color")}</TableCell>
                <TableCell className='area-wise-table-column' align="center">{t("delivery_zone.upto_distance")}</TableCell>
                <TableCell className='area-wise-table-column' align="center">{t("delivery_zone.cart_amount")}</TableCell>
                <TableCell className='area-wise-table-column' align="center">{t("delivery_zone.shipping_rate")}</TableCell>
                <TableCell className='area-wise-table-column' sx={{ width: "180px" }} align="center">{t("delivery_zone.extra_shipping_for_less_cart_amt")}</TableCell>
                <TableCell className='area-wise-table-column' sx={{ width: "150px" }} align="center">{t("delivery_zone.time_taken_for_delivery")}</TableCell>
                <TableCell className='area-wise-table-column' align="center">{t("delivery_zone.delivery_time")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ border: 0 }}
                >
                  <TableCell className='area-wise-table-row' component="th" scope="row">{row.color}</TableCell>
                  <TableCell className='area-wise-table-row' align="center">{row.distance}</TableCell>
                  <TableCell className='area-wise-table-row' align="center">{row.cartAmount}</TableCell>
                  <TableCell className='area-wise-table-row' align="center">{row.shippingRate}</TableCell>
                  <TableCell className='area-wise-table-row' align="center">{row.extraShipppingForLessCartAmount}</TableCell>
                  <TableCell className='area-wise-table-row' align="center">{row.timeTakenForDelivery}</TableCell>
                  <TableCell align="center">
                    <Box>

                      {row.timeRange !== "" ? <Box sx={{ display: "flex", alignItems: "center", width: "60%", marginLeft: "20px" }}>
                        <AccessTimeIcon color='warning' sx={{ marginRight: "10px" }} />
                        <Typography className='distance-wise-popup-time-text'> {row.timeRange}</Typography>
                      </Box>
                        :
                        <Button>
                          <IconButton onClick={() => { handleSetTimeButton(row.id) }} color='warning' className='area-wise-table-clock-button'>
                            <AccessTimeIcon sx={{ marginRight: "10px" }} />{t("delivery_zone.set_time")}
                          </IconButton>
                        </Button>}
                    </Box>
                  </TableCell>
                  <Modal

                    open={timePickerOpen}
                    onClose={handleTimePickerClose}

                  >
                    <Box sx={timePopupStyle}>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "60%" }}>

                        <IconButton onClick={handleTimePickerClose} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                          <CloseIcon />
                        </IconButton>
                        <Typography className="delete-pop-up-text">{t("delivery_zone.set_time")}</Typography>
                      </Box>

                      <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />

                      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
                        <Box>
                          <Typography>{t("delivery_zone.start_time")}</Typography>
                          <TextField
                            type="time"
                            name="startTime"
                            value={timeRange.startTime}
                            onChange={handleTimeRangeChange}
                            sx={{ width: "150px" }}
                          />
                        </Box>
                        <Box>
                          <Typography>{t("delivery_zone.end_time")}</Typography>
                          <TextField
                            type="time"
                            name="endTime"
                            value={timeRange.endTime}
                            onChange={handleTimeRangeChange}
                            sx={{ width: "150px" }}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ textAlign: "right", marginTop: "20px" }}>
                        <Button onClick={handlePopupTimeSaveButton} sx={{ textTransform: "none" }} variant='contained' color='warning'>{t("delivery_zone.save")}</Button>
                      </Box>
                    </Box>
                  </Modal>
                  <TableCell className='area-wise-table-row' align="center">
                    <Box>
                      <IconButton onClick={() => handleEditIcon(row.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => combineOpenAndPassingData(row)} className="area-wise-cross-icon-button">
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Modal open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Box>
                          <IconButton onClick={handleClose} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                            <CloseIcon />
                          </IconButton>
                          <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                        </Box>
                        <Typography className="delete-pop-up-text">{t("delivery_zone.are_you_sure")}</Typography>
                        <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                          <Button onClick={combineDeleteRowAndPopupClose} sx={{ textTransform: "none" }} className="distance-wise-popup-remove-button" color="warning" variant="outlined">{t("delivery_zone.yes_remove")}</Button>
                          <Button onClick={handleDontRemoveButton} sx={{ textTransform: "none" }} className="distance-wise-popup-do-not-remove-button" color="warning" variant="outlined">{t("delivery_zone.dont_remove")}</Button>
                        </Box>
                      </Box>
                    </Modal>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {activeDistanceButton && (
          <Box sx={{ display: "flex", alignItems: "center", padding: "8px", marginTop: "8px" }}>
            <TextField
            defaultValue="#333333"
            type='color'
            value={newRowCheckboxValues.color}
            onChange={handleInputChange}
            sx={{ flex: 1, marginRight: "5px" }}
            name='color'
            //@ts-ignore
            placeholder={t("delivery_zone.color")} />
            <TextField
            value={newRowCheckboxValues.distance}
            onChange={handleInputChange}
            sx={{ flex: 1, marginRight: "5px" }}
            name='distance'
            //@ts-ignore
            placeholder={t("delivery_zone.distance")} />
            <TextField
            value={newRowCheckboxValues.cartAmount}
            onChange={handleInputChange}
            sx={{ flex: 1, marginRight: "5px" }}
            name='cartAmount'
            //@ts-ignore
            placeholder={t("delivery_zone.cart_amt")} />
            <TextField
            value={newRowCheckboxValues.shippingRate}
            onChange={handleInputChange}
            sx={{ flex: 1, marginRight: "5px" }}
            name='shippingRate'
            //@ts-ignore
            placeholder={t("delivery_zone.shipping_rate")} />
            <TextField
            value={newRowCheckboxValues.extraShipppingForLessCartAmount}
            onChange={handleInputChange} sx={{ flex: 1, marginRight: "5px" }} name='extraShipppingForLessCartAmount'
            //@ts-ignore
            placeholder={t("delivery_zone.extra_shipping_for_less_cart_amt")} />

            <TextField value={newRowCheckboxValues.timeTakenForDelivery} onChange={handleInputChange} sx={{ flex: 1, marginRight: "5px" }} name='timeTakenForDelivery'
            //@ts-ignore
            placeholder={t("delivery_zone.time_taken_for_delivery")} />
            <Box sx={{ flex: 1, marginLeft: "20px" }}>
              <Button onClick={() => setActiveDistanceButton(false)} variant='outlined' color='warning' sx={{ marginRight: "10px", textTransform: "none" }}>{t("delivery_zone.cancel")}</Button>
              <Button onClick={handleNewRowSaveButton} sx={{ textTransform: "none" }} variant='contained' color='warning'>{t("delivery_zone.save")}</Button>
            </Box>
          </Box>
        )}
        {distanceError && <Typography sx={{ color: "red" }}>{t("delivery_zone.please_add_distance")}</Typography>}

        <Button onClick={handleOnDistanceButton} className='area-wise-add-distance-button'>{t("delivery_zone.add_distance")}</Button>

      </Box>

    </Box>
  )
}

export default DistanceWise
