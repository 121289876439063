import React, { useEffect, useState } from 'react';
import { Box,TextField, IconButton } from "@mui/material";

import '../options/createProduct.css'
import './createCategory.css'
import { t } from 'i18next';

import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';

interface State {
  
  metaTag: string
}

interface Props{
  handleAditionalSettingData: (seoKeyword: any, metaKeyword: any, meta: string) => void;
}









const CategoryAditionalDetails = (props:Props) => {
  
  const {handleAditionalSettingData}=props
 
  const initialState: State = {

    
    metaTag: ""
  };

  const [mainState, setState] = useState<State>(initialState);
  

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type, checked } = event.target;

    setState((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  

  
  const [chips, setChips] = useState<string[]>([]);
  const [newChipText, setNewChipText] = useState('');

  const handleAddChip = () => {
    if (newChipText.trim() !== '') {
      setChips([...chips, newChipText]);
      setNewChipText('');
    }
  };

  const handleDeleteChip = (chipToDelete: string) => {
    setChips(chips.filter((chip) => chip !== chipToDelete));
  };

  const [chips1, setChips1] = useState<string[]>([]);
  const [newChipText1, setNewChipText1] = useState('');

  const handleAddChip1 = () => {
    if (newChipText1.trim() !== '') {
      setChips1([...chips1, newChipText1]);
      setNewChipText1('');
    }
  };

  const handleDeleteChip1 = (chipToDelete: string) => {
    setChips1(chips1.filter((chip) => chip !== chipToDelete));
  };

  
  
  useEffect(()=>{
    handleAditionalSettingData(chips,chips1,mainState.metaTag)
  },[chips,chips1,mainState.metaTag])

 
  

  

 
  return (
    <Box>
        {/* <Typography className="create-product-details">Additional Settings</Typography> */}
        <div style={{ display: "flex", marginTop: "2%", width: "70%" }} className='create-product-chip'>
          <div>
            {chips.map((chip) => (
              <Chip
                key={chip}
                label={chip}
                onDelete={() => handleDeleteChip(chip)}

              />
            ))}
          </div>
          <div style={{ marginLeft: "auto" }}>
            <TextField
              label={t("menu.catageory.seo_keyword")}
              variant="standard"
              value={newChipText}
              onChange={(e) => setNewChipText(e.target.value)}
            />
            <IconButton onClick={handleAddChip} >
              <AddIcon />
            </IconButton>
          </div>
        </div>
        <TextField multiline 
        minRows={2}
        label={t("menu.catageory.meta_description")}
          placeholder="Meta tag description"
          name='metaTag'
          value={mainState.metaTag}
         
          onChange={handleInputChange}
          style={{ width: "70%", marginTop: '2%' }} color="warning" />
          
        <div style={{ display: "flex", marginTop: "2%", width: "70%" }} className='create-product-chip'>
          <div>
            {chips1.map((chip) => (
              <Chip
                key={chip}
                label={chip}
                onDelete={() => handleDeleteChip1(chip)}

              />
            ))}
          </div>
          <div style={{ marginLeft: "auto" }}>
            <TextField
              label={t("menu.catageory.meta_keywords")}
              variant="standard"

              value={newChipText1}
              onChange={(e) => setNewChipText1(e.target.value)}
            />
            <IconButton onClick={handleAddChip1} >
              <AddIcon />
            </IconButton>
          </div>
        </div>

      

    </Box>
  );
}

export default CategoryAditionalDetails;
