import React from 'react';

const Branch = React.createContext();

export function BranchProvider({ children }) {
  const [state, setState] = React.useState({ branchName: "Vinay's kitchen",id:7 });

  const handleBranchChange = (name,id) => {
    setState({branchName: name,id});
  };

  return (
    <Branch.Provider value={{ state, handleBranchChange }}>
      {children}
    </Branch.Provider>
  );
}
