import { gql } from "@apollo/client";
export const CATEGORY_LIST=gql`
query {
  categoryList(isOnline: true, isPos: true,businessId:2),
   {
      pageInfo{hasNextPage}
      edges{node{categoryId
        
      slugName
  
      description
        productCount
        
      }
      }
    
      
  }
    
  }
  
`
export const GET_PRODUCT=gql`
query {
  productList(first:10,businessId:2) {
      pageInfo{hasNextPage
      hasPreviousPage}
      edges{node{
        productId
        name
        price
        
        
        
      }
      }
    
      
  }
    
  }
  
`

export const OPTIONS_LIST=gql`
query {
  optionGroupList(first:10,businessId:2) {
      pageInfo{hasNextPage
      hasPreviousPage}
      edges{node{optionGroupId
        slugName
        
        options
        optionObjs{
          name
          
          optionId
          optionValues{optionValueId
          name
          price
          image}
        name}
        name
        }
      }
    
  }
    
  }
`
export const EDIT_CATEGORY = gql`
  query Category($categoryId: ID!) {
    category(categoryId: $categoryId) {
      categoryId
      slugName
      description
      image
      name
      productSet{edges{node{productId
        price
        name
        isDeleted}}}
      productCount
      isPos
      metatagKeywords
      productCount
      metatagDescription
      isOnline
      isCarry
      seoKeyword
      isDinein
      categoryStatus
      isDeleted
    }
  }
`;

export const GET_OPTIONS_IN_CATEGORY=gql`
query categoryOptionValueListing($categoryId:Int){
  categoryOptionValueListing(categoryId:$categoryId)
    
    
  }
`