import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow} from '@mui/material';
import {
    Typography, Paper, Button, Divider, Switch,TextField  } from '@mui/material';
    import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
    import './createSalesTax.css'
    import './salesTax.css';
const LoyalityPoints =()=>{

    
    const initialRows = [
        { name: 'Tier Name', data: 'Gold Tier' },
        { name: 'Amount required for vip status', data: '0 %' },
        { name: 'Points per Pound multiplier', data: '2' },
    ];

    const [toggleState, setToggleState] = useState(false);
    const [toggleStateExpire, setToggleStateExpire] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [loyaltyPoints, setLoyaltyPoints] = useState('');
    const [toggleStateVip, setToggleStateVip] = useState(false);
    const [vipTables, setVipTables] = useState([initialRows]);
    const [toggleStateTimeAway, setToggleStateTimeAway] = useState(false);
    const [lastPurcharge, setLastPurcharge] = useState("");
    const [points, setPoints] = useState('');
    const [points2, setPoints2] = useState('');
    const [singUp, setSignUp] = useState(false);
    const [anniversary, setAnniversary] = useState(false);
    const [points3, setPoints3] = useState('');
    const [points4, setPoints4] = useState('');
    const [birthday, setBirthday] = useState(false);

    const handleToggleChange = (event) => {
        setToggleState(event.target.checked);
    };

    const handleToggleChangeExpire = (event) => {
        setToggleStateExpire(event.target.checked);
    };

    const handleDropdownChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleLastPurcharge = (event) => {
        setLastPurcharge(event.target.value);
    };

    const handleToggleChangeVip = (event) => {
        setToggleStateVip(event.target.checked);
    };

    const handleAddTable = () => {
        setVipTables(prevTables => [...prevTables, initialRows]);
    };

    const handleVipTableRowChange = (event, tableIndex, rowIndex, key) => {
        const updatedTables = [...vipTables];
        updatedTables[tableIndex][rowIndex][key] = event.target.value;
        setVipTables(updatedTables);
    };

    const handleToggleChangeTimeAway = (event) => {
        setToggleStateTimeAway(event.target.checked);
    };

    const handleLoyaltyPointsChange = (event) => {
        setLoyaltyPoints(event.target.value);
    };
    const handlePoints = (event) => {
        setPoints(event.target.value);
    };

    const handlePoints2 = (event) => {
        setPoints2(event.target.value);
    };
    const handleSignUp = (event) => {
        setSignUp(event.target.checked);
    };
    const handleAnniversary = (event) => {
        setAnniversary(event.target.checked);
    };

    const handlePoints3 = (event) => {
        setPoints3(event.target.value);
    };
    const handlePoints4 = (event) => {
        setPoints4(event.target.value);
    };
    const handleBirthday = (event) => {
        setBirthday(event.target.checked);
    };

    return(
        <div>
           <Paper elevation={3} style={{ padding: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <Typography style={{ margin: "0 auto" }}>Loyalty Points</Typography>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                   
                    <Button variant="contained">
                        Save
                    </Button>
                </div>
            </div>
            <div style={{marginBottom: '10px' }}>
                <div className="sales_head_container">
                    <div className="sales_head_center">
                        <Typography className="sales_head">Allow Loyalty Points </Typography>
                    </div>
                    <Switch checked={toggleState} onChange={handleToggleChange} />
                </div>
                <Typography className="sales_para">Enable this toggle on if you want to allow loyalty points.</Typography>
            </div>
            <Divider style={{ backgroundColor: ' #D9D9D9' }} />
            {toggleState && (
                <>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                <div style={{ marginBottom: '10px' }}>
                <div className="sales_head_container">
                    <div className="sales_head_center">
                        <Typography className="sales_head">Set Expiry for Loyalty Points</Typography>
                    </div>
                    <Switch checked={toggleStateExpire} onChange={handleToggleChangeExpire} />
                </div>
                <Typography className="sales_para">Enable this toggle on if you want to allow expiry to loyalty points.</Typography>
            </div>
            <FormControl variant="outlined"  style={{ marginTop: "20px",width:"160px",marginRight:"30%" }}>
                    <InputLabel>Select an option</InputLabel>
                    <Select
                        value={selectedValue}
                        onChange={handleDropdownChange}
                        
                    >
                        <MenuItem value="1 Month">1 Month</MenuItem>
                        <MenuItem value="3 Month">3 Month</MenuItem>
                        <MenuItem value="6 Month">6 Month</MenuItem>
                        <MenuItem value="1 Year">1 year</MenuItem>
                        <MenuItem value="Custom">Custom</MenuItem>
                    </Select>
                </FormControl>
                </div>
                <Divider style={{ backgroundColor: ' #D9D9D9' }} />
                <Typography className="sales_head">Points Accumulation</Typography>
                <Typography style={{display:"flex",alignItems:"center"}}>
                    Every £ 1 spent is equal to{' '}
                    <TextField
                        
                        variant="outlined"
                        value={loyaltyPoints}
                        onChange={handleLoyaltyPointsChange}
                        style={{width:"50px"}}
                    />{' '}
                    Loyalty point.
                </Typography>
                <Divider style={{ backgroundColor: ' #D9D9D9' }} />
                <Typography className="sales_head">Points Redemption</Typography>
                <Typography style={{display:"flex",alignItems:"center"}}>
                Every  1 loyalty point earned is equal to{' '}
                    <TextField
                        
                        variant="outlined"
                        value={loyaltyPoints}
                        onChange={handleLoyaltyPointsChange}
                        style={{width:"50px"}}
                    />{' '}
                    
                </Typography>
                <Divider style={{ backgroundColor: ' #D9D9D9' }} />
                <div style={{  marginBottom: '10px' }}>
                <div className="sales_head_container">
                    <div className="sales_head_center">
                        <Typography className="sales_head">VIP</Typography>
                    </div>
                    <Switch checked={toggleStateVip} onChange={handleToggleChangeVip} />
                </div>
                <Typography className="sales_para">Every  1 loyalty point earned is equal to</Typography>
            </div>
            {toggleStateVip && (
                <>
                <div style={{display:"flex",flexWrap:"wrap",justifyContent:"space-around"}}>
            
                {vipTables.map((vipTable, tableIndex) => (
                    <div key={tableIndex} >
                        <TableContainer component={Paper} className='create_tax_table' style={{ width: "100%" ,margin:"10px"}}>
                            <Table sx={{ minWidth: 200 }}>
                                <TableBody>
                                    {vipTable.map((row, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            <TableCell component="th" scope="row" className='create_tax_type_table_row_head'>
                                               
                                                    <Typography className='row_head'>{row.name}</Typography>
                                                
                                            </TableCell>
                                            <TableCell>
                                                {toggleStateVip ? (
                                                    <TextField
                                                        value={row.data}
                                                        onChange={(e) => handleVipTableRowChange(e, tableIndex, rowIndex, 'data')}
                                                    />
                                                ) : (
                                                    <Typography className='create_tax_type_table_row'>{row.data}</Typography>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                ))}
                
            
        </div>
        <Button onClick={handleAddTable}>Add</Button>
        <Divider style={{ backgroundColor: ' #D9D9D9' }} />
                </>    
                )}
           <div style={{marginBottom: '10px' }}>
                <div className="sales_head_container">
                    <div className="sales_head_center">
                        <Typography className="sales_head">Time Away</Typography>
                    </div>
                    <Switch checked={toggleStateTimeAway} onChange={handleToggleChangeTimeAway} />
                </div>
                <Typography className="sales_para">Enable this toggle on if you want to allow time away points for customer.</Typography>
            </div>
            <div style={{marginBottom: '10px' }}>
                <div className="sales_head_container">
                    <div className="sales_head_center">
                        <Typography className="sales_head">Days since last purchase</Typography>
                    </div>
                    <TextField
                        
                        variant="outlined"
                        value={lastPurcharge}
                        onChange={handleLastPurcharge}
                        style={{width:"50px"}}
                    />
                </div>
                <Typography className="sales_para">enter number between 7 and 365</Typography>
            </div>

            <div style={{marginBottom: '10px' }}>
                <div className="sales_head_container">
                    <div className="sales_head_center">
                        <Typography className="sales_head">Points</Typography>
                    </div>
                    <TextField
                        
                        variant="outlined"
                        value={points}
                        onChange={handlePoints}
                        style={{width:"50px"}}
                    />
                </div>
                
            </div>
            <Divider style={{ backgroundColor: ' #D9D9D9' }} />
            <div style={{marginBottom: '10px' }}>
                <div className="sales_head_container">
                    <div className="sales_head_center">
                        <Typography className="sales_head">Sign UP</Typography>
                    </div>
                    <Switch checked={singUp} onChange={handleSignUp} />
                </div>
                <Typography className="sales_para">Enable this toggle on if you want to allow sign up points for customer.</Typography>
            </div>
            <div style={{marginBottom: '10px' }}>
                <div className="sales_head_container">
                    <div className="sales_head_center">
                        <Typography className="sales_head">Points</Typography>
                    </div>
                    <TextField
                        
                        variant="outlined"
                        value={points2}
                        onChange={handlePoints2}
                        style={{width:"50px"}}
                    />
                </div>
                
            </div>
            <Divider style={{ backgroundColor: ' #D9D9D9' }} />

            
            <div style={{marginBottom: '10px' }}>
                <div className="sales_head_container">
                    <div className="sales_head_center">
                        <Typography className="sales_head">Sign up Anniversary</Typography>
                    </div>
                    <Switch checked={anniversary} onChange={handleAnniversary} />
                </div>
                <Typography className="sales_para">Enable this toggle on if you want to allow sign up aniversarry points for customer.</Typography>
            </div>
            <div style={{marginBottom: '10px' }}>
                <div className="sales_head_container">
                    <div className="sales_head_center">
                        <Typography className="sales_head">Points</Typography>
                    </div>
                    <TextField
                        
                        variant="outlined"
                        value={points3}
                        onChange={handlePoints3}
                        style={{width:"50px"}}
                    />
                </div>
                
            </div>
            <Divider style={{ backgroundColor: ' #D9D9D9' }} />
            
            <div style={{marginBottom: '10px' }}>
                <div className="sales_head_container">
                    <div className="sales_head_center">
                        <Typography className="sales_head">Birthday</Typography>
                    </div>
                    <Switch checked={birthday} onChange={handleBirthday} />
                </div>
                <Typography className="sales_para">Enable this toggle on if you want to allow birthday points for customer.</Typography>
            </div>
            <div style={{marginBottom: '10px' }}>
                <div className="sales_head_container">
                    <div className="sales_head_center">
                        <Typography className="sales_head">Points</Typography>
                    </div>
                    <TextField
                        
                        variant="outlined"
                        value={points4}
                        onChange={handlePoints4}
                        style={{width:"50px"}}
                    />
                </div>
                
            </div>
            <Divider style={{ backgroundColor: ' #D9D9D9' }} />
                </>
            )}
            </Paper>
        </div>
    )
}

export default LoyalityPoints