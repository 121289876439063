import { Box,FormGroup,FormControlLabel,Checkbox, Button, Divider, Paper, Typography } from '@mui/material'
import React from 'react'
import { t } from 'i18next'

import "./stockAdjustmentReasons.css"

interface State{
    stockReceived:boolean
    inventoryRecount:boolean
    damage:boolean
    lost:boolean
    restockItem:boolean
    theft:boolean

}



    const initialStatesOfCheckbox:State={
        stockReceived:false,
        inventoryRecount:false,
        damage:false,
        lost:false,
        restockItem:false,
        theft:false,
    }



function StockAdjustmentReasons() {
    
    
    const [checkBoxValue,setCheckBoxValue]=React.useState<State>(initialStatesOfCheckbox)
   

    




    const handleCheckbox=(event:React.ChangeEvent<HTMLInputElement>)=>{
        const {value,checked,name,type}=event.target
        
        setCheckBoxValue((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
          }));

    }






  

  return (
    <Box sx={{padding:"20px",backgroundColor:"#f9f9f9"}}>
        <Box sx={{padding:"15px 10px 15px 10px",display:"flex",justifyContent:"space-between",alignItems:"center",backgroundColor:"#ffffff"}}>
            <Typography className='stock-adjustment-reasons-header'>{t("stock_adjustment_reasons.stock_adjustment_reasons")}</Typography>
            <Button className='stock-adjustment-reasons-header-save-button' color='warning' variant='contained'>{t("stock_adjustment_reasons.save")}</Button>
        </Box>
        <Divider sx={{marginBottom:"20px",marginTop:"20px"}}/>
        <Paper elevation={2} sx={{width:"60%",padding:"20px",borderLeft:"2px solid #AE9BE2"}}>
            <Box sx={{width:"70%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Box>
            <Typography className='stock-adjustment-reasons-heading'>{t("stock_adjustment_reasons.show_reasons_for_inventory_adjustments")}</Typography>
            
            </Box>
           
            

            
            </Box>
            <Typography className='stock-adjustment-reasons-paragraph'>{t("stock_adjustment_reasons.display_reasons_inventory_adjustments")}</Typography>  
                <Box>
                    <Box>
               
                     <Box sx={{display:"flex",alignItems:"center"}}>
                        <FormGroup sx={{margin:"10px"}}>
                        <FormControlLabel
                         control={
                             <Checkbox 
                               sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                name="stockReceived"
                                 onChange={handleCheckbox}
                                 color='warning'  
                                className='stock-adjustment-reasons-checkbox-size'
                             />
                         }
                         label={<Typography className='stock-adjustment-reasons-checkbox-label' >{t("stock_adjustment_reasons.stock_received")}</Typography>}
                         className="stock-adjustment-reasons-checkbox"
                     />
                     <FormControlLabel
                         control={
                             <Checkbox 
                               sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                name='inventoryRecount'
                                 onChange={handleCheckbox}
                                 color='warning'  
                                className='stock-adjustment-reasons-checkbox-size'
                             />
                         }
                         label={<Typography className='stock-adjustment-reasons-checkbox-label' >{t("stock_adjustment_reasons.inventory_recount")}</Typography>}
                         className="stock-adjustment-reasons-checkbox"
                     />
                      <FormControlLabel
                         control={
                             <Checkbox 
                               sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                name='damage'
                                 onChange={handleCheckbox}
                                 color='warning'  
                                className='stock-adjustment-reasons-checkbox-size'
                             />
                         }
                         label={<Typography className='stock-adjustment-reasons-checkbox-label' >{t("stock_adjustment_reasons.damage")}</Typography>}
                         className="stock-adjustment-reasons-checkbox"
                     />
                      <FormControlLabel
                         control={
                             <Checkbox 
                               sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                name='lost'
                                 onChange={handleCheckbox}
                                 color='warning'  
                                className='stock-adjustment-reasons-checkbox-size'
                             />
                         }
                         label={<Typography className='stock-adjustment-reasons-checkbox-label' >{t("stock_adjustment_reasons.lost")}</Typography>}
                         className="stock-adjustment-reasons-checkbox"
                     />
                      <FormControlLabel
                         control={
                             <Checkbox 
                               sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                name='restockItem'
                                 onChange={handleCheckbox}
                                 color='warning'  
                                className='stock-adjustment-reasons-checkbox-size'
                             />
                         }
                         label={<Typography className='stock-adjustment-reasons-checkbox-label' >{t("stock_adjustment_reasons.restock_item")}</Typography>}
                         className="stock-adjustment-reasons-checkbox"
                     />
                      <FormControlLabel
                         control={
                             <Checkbox 
                               sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                name='theft'
                                 onChange={handleCheckbox}
                                 color='warning'  
                                className='stock-adjustment-reasons-checkbox-size'
                             />
                         }
                         label={<Typography className='stock-adjustment-reasons-checkbox-label' >{t("stock_adjustment_reasons.theft")}</Typography>}
                         className="stock-adjustment-reasons-checkbox"
                     />
                 </FormGroup>
                 
                     </Box>
                
            </Box>     
                </Box>
            
        </Paper>

    </Box>
  )
}

export default StockAdjustmentReasons
