import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Paper,
  Divider,
  Grid,
  Button,
  FormControl,
  Input,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  TextField
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../utils/strictModeDroppable.tsx";
import "./drag.css";

import { Add } from "@mui/icons-material";
import AddOption from "./addOption.tsx";
import { Controller, useForm } from "react-hook-form";
interface Product {
  id: string;
  optionName: string;
  price: string;
  image: string;
}
const optionsData = [
  {
    id: "1",
    optionName: "Tomato",
    price: "$ 2.00",
    image:
      "https://images.pexels.com/photos/533280/pexels-photo-533280.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "2",
    optionName: "Sweet corn",
    price: "$ 4.00",
    image:
      "https://images.pexels.com/photos/603030/pexels-photo-603030.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "3",
    optionName: "Olive",
    price: "$ 6.00",
    image:
      "https://images.pexels.com/photos/4109910/pexels-photo-4109910.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];
const swapData = [
  {
    id: "4",
    optionName: "Tomato",
    price: "$ 2.00",
    image:
      "https://images.pexels.com/photos/533280/pexels-photo-533280.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "5",
    optionName: "Sweet corn",
    price: "$ 4.00",
    image:
      "https://images.pexels.com/photos/603030/pexels-photo-603030.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "6",
    optionName: "Olive",
    price: "$ 6.00",
    image:
      "https://images.pexels.com/photos/4109910/pexels-photo-4109910.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];
export default function CreateOptions() {
  const { t } = useTranslation();
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      optionName: "",
      minSelect: "",
      maxSelect: "",
      convertionalModifier: false,
      swap: false,
      add: true,
      extra: true,
      onSide: true,
      no: true,
      light: false,
    },
  });

  const [showOptionCreate, setShowOptioncreate] = useState(false);
 
  const [options, setOptions] = useState<Product[]>(optionsData);
  const [swap, setSwap] = useState<Product[]>(swapData);

  const handleDragEnd = (data: DropResult) => {
    const { source, destination } = data;
    if (!destination) return;

    if (data.type === "OPTIONS") {
      const newItems = Array.from(options);
      const [reorderedItem] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, reorderedItem);

      setOptions(newItems);
    } else {
      const newItems = Array.from(swap);
      const [reorderedItem] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, reorderedItem);

      setSwap(newItems);
    }
  };
  const onSubmit = (data: any) => console.log(data);
  
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper className="paper" sx={{ mb: 2 }}>
      <div className='create_tax_head_container' style={{ padding: "12px 20px 0px 12px" }}>
       
       <div className='create_exitIcon'>
       <ArrowBackIcon  sx={{height:"24px",width:'24px'}}/>
       </div>
       
                 
                 <Typography className="create_tax_main">Create an Option</Typography>
                 <div className='create_tax_button_con'>
                   <Button variant="contained" className='create_tax_button_style'>
                     {t("settings.clear")}
                   </Button>
                   <Button variant="contained" className='create_tax_button_style1'>
                     {t("settings.save")}
                   </Button>
                 </div>
               </div>
        <Divider className="divider" />
        <div className="card">
         <Typography className="details_button">Details</Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
              
                <TextField
              label={
                <Typography >
                 Option Name
                </Typography>
              }
              variant="outlined"
              
             
              color='warning'
              sx={{
                marginTop:"3%",marginLeft:'10%',
                "&:hover": {
                  "&& fieldset": {
                    border: "2px solid #F9C584"
                  }
                }
              }}
            />
            <div style={{display:"flex",  marginTop:"3%",marginLeft:'10%'}}>
            <TextField
              label={
                <Typography >
                 Minimum Select
                </Typography>
              }
              variant="outlined"
              
             
              color='warning'
              sx={{
                "&:hover": {
                  "&& fieldset": {
                    border: "2px solid #F9C584"
                  }
                }
              }}
            />
                 <TextField
              label={
                <Typography >
               Maximum Select
                </Typography>
              }
              variant="outlined"
              
              
              color='warning'
              sx={{
                marginLeft:"2%",
                "&:hover": {
                  "&& fieldset": {
                    border: "2px solid #F9C584"
                  }
                }
              }}
            />
            </div>
                
              <div >
                
            <Grid container alignItems="left">
      <Grid item>
        <FormGroup style={{marginTop:"5%"}}>
          <FormControlLabel
            label={<Typography className="details_button">  Conversational Modifiers</Typography>}
            control={
              <Switch
                checked={checked}
                onChange={handleChange}
                color="warning"
              />
            }
            labelPlacement="start" // Place label on the left
          />
        </FormGroup>
      </Grid>
    </Grid>
    <Typography className="options-para" style={{marginLeft:"10%"}}>Select conversational modifier options</Typography>
    </div>
                {checked && (
                  <>
                  <div style={{display:"flex",alignItems:"center", marginTop: "10px"}}>
                    <FormControl
                      className="inputHolder"
                      sx={{width: "20%" }}
                    >
                      <Controller
                        name="swap"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox {...field} color="warning" />}
                            onChange={(e: any) =>
                              field.onChange(e.target.checked)
                            }
                            label={t("menu.create.swap")}
                           
                          />
                        )}
                      />
                      
                    </FormControl>
                    <Typography className="options-para">
                        {t("menu.create.swap_desc")}
                      </Typography>
                      </div>
                      <div style={{display:"flex",flexWrap:"wrap",justifyContent:"space-evenly"}}>
                    <FormControl
                      className="options-checkbox"
                      sx={{ marginTop: "10px", width: "20%" }}
                      
                    >
                      <Controller
                        name="add"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox {...field} color="warning"  /> }
                            onChange={(e: any) =>
                              field.onChange(e.target.checked)
                            }
                            label="Add"
                            labelPlacement="start"
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl
                     className="options-checkbox"
                     sx={{ marginTop: "10px", width: "20%" }}
                    >
                      <Controller
                        name="extra"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox {...field} color="warning" />}
                            onChange={(e: any) =>
                              field.onChange(e.target.checked)
                            }
                            label="Extra"
                            labelPlacement="start"
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl
                       className="options-checkbox"
                       sx={{ marginTop: "10px", width: "20%" }}
                    >
                      <Controller
                        name="onSide"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox {...field} color="warning"  />}
                            onChange={(e: any) =>
                              field.onChange(e.target.checked)
                            }
                            label="On side"
                            labelPlacement="start"
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl
                      className="options-checkbox"
                      sx={{ marginTop: "10px", width: "20%" }}
                    >
                      <Controller
                        name="no"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox {...field} color="warning"  />}
                            onChange={(e: any) =>
                              field.onChange(e.target.checked)
                            }
                            label="No"
                            labelPlacement="start"
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl
                     className="options-checkbox"
                     sx={{ marginTop: "10px", width: "20%" }}
                    >
                      <Controller
                        name="light"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox {...field} color="warning" />}
                            onChange={(e: any) =>
                              field.onChange(e.target.checked)
                            }
                            label="Light"
                            labelPlacement="start"
                          />
                        )}
                      />
                    </FormControl>
                    </div>
                  </>
                )}
                <br />
                <br />
                <Button
                  focusRipple
                  className="primaryButton"
                  variant="contained"
                  type="submit"
                >
                  {t("buttons.submit")}
                </Button>
              </form>
            </Grid>
            <Grid item xs={12} md={6}>
              {watch("swap") && (
                <>
                  <div>
                    <div>
                      <h2>{t("menu.create.swap_values")}</h2>
                      <p>{t("menu.create.swap_desc")}</p>
                      <div
                        className="draggableRow"
                        style={{ cursor: "initial", marginTop: "20px" }}
                      >
                        <div style={{ width: "8.33%" }}>{""}</div>
                        <div style={{ width: "12.66%" }}>{""}</div>
                        <div style={{ width: "41.66%" }}>
                          <b>{t("menu.create.swap_value_name")}</b>
                        </div>
                        <div style={{ width: "16.66%" }}>
                          <b>{t("menu.create.price")}</b>
                        </div>
                        <div>{""}</div>
                      </div>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <StrictModeDroppable
                          type="SWAP"
                          droppableId="optionsZone"
                        >
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {swap.map((items, index) => {
                                return (
                                  <Draggable
                                    key={items.id}
                                    draggableId={items.id}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        className="draggableRow"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <DragIndicatorIcon
                                          style={{
                                            opacity: 0.5,
                                            width: "8.33%",
                                          }}
                                          fontSize="large"
                                        />
                                        <div style={{ width: "12.66%" }}>
                                          <img
                                            src={items.image}
                                            alt={items.optionName}
                                            width="35px"
                                            height="35px"
                                            style={{
                                              objectFit: "cover",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        </div>
                                        <p style={{ width: "41.66%" }}>
                                          {items.optionName}
                                        </p>
                                        <p style={{ width: "16.66%" }}>
                                          {items.price}
                                        </p>
                                        <CloseIcon />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </StrictModeDroppable>
                      </DragDropContext>
                    </div>
                    <Button
                      endIcon={<Add />}
                      focusRipple
                      color="warning"
                      variant="text"
                      type="button"
                      onClick={() => setShowOptioncreate(true)}
                    >
                      Add Swap
                    </Button>

                    <AddOption
                      open={showOptionCreate}
                      setOpen={setShowOptioncreate}
                    />
                  </div>
                  <br />
                  <br />
                </>
              )}
              <div>
                <div>
                  <h2>{t("menu.create.option_values")}</h2>
                  <div
                    className="draggableRow"
                    style={{ cursor: "initial", marginTop: "20px" }}
                  >
                    <div style={{ width: "8.33%" }}>{""}</div>
                    <div style={{ width: "12.66%" }}>{""}</div>
                    <div style={{ width: "41.66%" }}>
                      <b>{t("menu.create.option_value_name")}</b>
                    </div>
                    <div style={{ width: "16.66%" }}>
                      <b>{t("menu.create.price")}</b>
                    </div>
                    <div>{""}</div>
                  </div>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <StrictModeDroppable
                      type="OPTIONS"
                      droppableId="optionsZone"
                    >
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {options.map((items, index) => {
                            return (
                              <Draggable
                                key={items.id}
                                draggableId={items.id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="draggableRow"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <DragIndicatorIcon
                                      style={{ opacity: 0.5, width: "8.33%" }}
                                      fontSize="large"
                                    />
                                    <div style={{ width: "12.66%" }}>
                                      <img
                                        src={items.image}
                                        alt={items.optionName}
                                        width="35px"
                                        height="35px"
                                        style={{
                                          objectFit: "cover",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </div>
                                    <p style={{ width: "41.66%" }}>
                                      {items.optionName}
                                    </p>
                                    <p style={{ width: "16.66%" }}>
                                      {items.price}
                                    </p>
                                    <CloseIcon />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </StrictModeDroppable>
                  </DragDropContext>
                </div>
                <Button
                  endIcon={<Add />}
                  focusRipple
                  color="warning"
                  variant="text"
                  type="button"
                  onClick={() => setShowOptioncreate(true)}
                >
                  Add Option
                </Button>

                <AddOption
                  open={showOptionCreate}
                  setOpen={setShowOptioncreate}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Box>
  );
}
