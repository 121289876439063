import { Box,Button,IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import FirstPageSharpIcon from '@mui/icons-material/FirstPageSharp';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';

import image1 from "../../assets/theme1Left.png"
import image2 from "../../assets/theme1Right.png"
import image3 from "../../assets/theme1Bottom.png"
import image4 from "../../assets/theme1Top.png"

import "./posPopup.css"

interface savedProps{
    handlePopupTheme1Close:()=>void
    handleTheme1State:()=>void
}




function PosPopupTheme1(props:savedProps) {

    const {handlePopupTheme1Close,handleTheme1State}=props
    const bottomIconStyle = {
        transform: 'rotate(90deg)', // You can adjust the rotation angle as needed
      }
      const topIconStyle = {
        transform: 'rotate(270deg)', // You can adjust the rotation angle as needed
      }

    
    const [leftImageState,setLeftImageState]=useState(false)
    const [rightImageState,setRightImageState]=useState(false)
    const [topImageState,setTopImageState]=useState(true)
    const [bottomImageState,setBottomImageState]=useState(false)

    const handleLeftButton=()=>{
        setLeftImageState(true)
        setRightImageState(false)
        setTopImageState(false)
        setBottomImageState(false)
    }
    const handleRightButton=()=>{
        setLeftImageState(false)
        setRightImageState(true)
        setTopImageState(false)
        setBottomImageState(false)
    }
    const handleBottomButton=()=>{
        setLeftImageState(false)
        setRightImageState(false)
        setTopImageState(false)
        setBottomImageState(true)
    }

    const handleTopButton=()=>{
        setLeftImageState(false)
        setRightImageState(false)
        setTopImageState(true)
        setBottomImageState(false)
    }

    const handleUseThisThemeButton=()=>{
        handlePopupTheme1Close()
        handleTheme1State()
    }
    
  return (
    <Box>
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <IconButton onClick={handleUseThisThemeButton} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                <CloseIcon/>
            </IconButton>
            <Typography className='pos-pop-up-header'>Theme 1</Typography>
            <Button onClick={handleUseThisThemeButton} className='pos-pop-up-use-this-theme-button' variant='contained'>Use This Theme</Button>
        </Box>


        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"80%",margin:"15px"}}>
            <Box >
            <Typography className='pos-pop-up-sub-header'>Customise Menu Display Area:</Typography>
            <Typography className='pos-pop-up-tailer-text'>Tailor the area where you'd like the menu to show.</Typography>
            </Box>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"35%"}}>
               

               <Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
               <IconButton onClick={handleLeftButton} className={`pos-pop-up-not-active-state ${leftImageState&&"pos-pop-up-active-state"} ${!leftImageState&&"inventory-pop-up-cross-icon-button-hover"}`}>
               <FirstPageSharpIcon/>
               </IconButton>
               <Typography className='pos-pop-up-top-text'>Left</Typography>
               </Box>
              

               <Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
               <IconButton onClick={handleRightButton} className={`pos-pop-up-not-active-state ${rightImageState&&"pos-pop-up-active-state"} ${!rightImageState&&"inventory-pop-up-cross-icon-button-hover"}`}>
               <LastPageOutlinedIcon/>
               </IconButton>
               <Typography className='pos-pop-up-top-text'>Right</Typography>
               </Box>
               
              
               <Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
               <IconButton  onClick={handleBottomButton} className={`pos-pop-up-not-active-state ${bottomImageState&&"pos-pop-up-active-state"} ${!bottomImageState&&"inventory-pop-up-cross-icon-button-hover"}`}>
               <LastPageOutlinedIcon style={bottomIconStyle}/>
               </IconButton>
               <Typography className='pos-pop-up-top-text'>Bottom</Typography>
               </Box>

               <Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
               <IconButton onClick={handleTopButton} className={`pos-pop-up-not-active-state ${topImageState&&"pos-pop-up-active-state"} ${!topImageState&&"inventory-pop-up-cross-icon-button-hover"}`}>
               <LastPageOutlinedIcon style={topIconStyle}/>
               </IconButton>
               <Typography className='pos-pop-up-top-text'>Top</Typography>
               </Box>
           </Box>
        </Box>    

       <Box>
       {
        leftImageState &&(
            <Box sx={{width:"30%",margin:"15px"}}>
            <img src={image1} alt="Left View"/>
            </Box>
        )
        }
        {
        rightImageState &&(
            <Box sx={{width:"30%",margin:"15px"}}>
            <img src={image2} alt="Right View"/>
            </Box>
        )
        }
        {
        topImageState &&(
            <Box sx={{width:"30%",margin:"15px"}}>
            <img src={image4} alt="Top View"/>
            </Box>
        )
        }
        {
        bottomImageState &&(
            <Box sx={{width:"30%",margin:"15px"}}>
            <img src={image3} alt="Bottom View"/>
            </Box>
        )
        }
       </Box>
       </Box>   
    </Box>
  )
}

export default PosPopupTheme1
