import React, {  useEffect, useState } from 'react';
import { Box, Typography, Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import imageValue1 from '../../assets/image-avatar.png';
import '../options/createProduct.css'
import './createCategory.css'
import { t } from 'i18next';
import { FormEvent } from 'react';
import { CREATE_CATEGORY } from '../../graphicalQl/mutation/mutation';
import { useMutation, } from '@apollo/client';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Divider } from 'primereact/divider';

import AlertDialog from './dailougeBox';


interface Props{
  getCategoryId: (id: string) => void;
  handleComplete:()=>void
}
interface State {
  [key: string]: string | boolean;
  categoryName: string;
  search: string;
  description: string;
  showPOS: boolean;
  showOnline: boolean;
  metaTag: string
}


const CategoryDetails = (props:Props) => {
  const {getCategoryId,handleComplete}=props
  const initialState: State = {
    categoryName: '',
    search: "",
    description: '',
    showPOS: false,
    showOnline: false,
    metaTag: ""
  };
  const [mainState, setState] = useState<State>(initialState);
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type, checked } = event.target;

    setState((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const [imageValue, setImageValue] = useState<string | null>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageValue(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };


  const [categoryId, setCategoryId] = useState<string>("")
  const [createCatageory] = useMutation(CREATE_CATEGORY, {
    onCompleted: (data) => {
      if (data) {
        setCategoryId(data.createCategory.category.categoryId)
       
        
      }

    },
    onError: (error) => {
        toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography>Category name should be unique!</Typography>
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          className: 'custom_toast2',
        }
      )
    },
    context: { clientName: 'categoryClient' }
  });
useEffect(()=>{
  
})
  
  const handleCreateCategory = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    
    
    
   
    
    
    createCatageory({ variables: { name: mainState.categoryName, description: mainState.description, isPos: mainState.showPOS, isShow: mainState.showOnline, image: imageValue, businessId: 2 } })

    // setState((prevState) => ({
    //   ...prevState,
    //   description:"",
    //   categoryName: "",
    //   showPOS: false,
    //   showOnline: false,
    // }
    // ));
  }
  const handleCancelButton=()=>{
    setState((prevState) => ({
      ...prevState,
      description:"",
      categoryName: "",
      showPOS: false,
      showOnline: false,
     
    }
    ));
  }

  useEffect(()=>{
    getCategoryId(categoryId)
  })

  const [dailouge,setDailouge]=useState<boolean>(false)
const handleOpenDailouge=()=>{
setDailouge(true)
}

const handleUpdateDailougeState=()=>{
  setDailouge(false)
}


  return (
    <Box>
        {/* <Typography className="create-product-details">{t("menu.catageory.details")}</Typography> */}
        <form onSubmit={(e) => handleCreateCategory(e)}>
          <div style={{ padding: "2%", display: 'flex' }}>

            <div style={{ width: "90%" }}>
              <div className="create-product-text-container">
                <TextField id="outlined-basic"
                size='medium'
                  label={t("menu.catageory.categoryName")}
                  variant="outlined"
                  name="categoryName"
                  value={mainState.categoryName}
                  onChange={handleInputChange}
                  color="warning"
                  style={{ width: '45%' }}
                />
              </div>
              <div style={{ marginTop: "3%" }}>
                <TextField
                  multiline
                  rows={2} 
                  label={t("menu.catageory.description")}
                  placeholder="Enter a short description for the category"
                  value={mainState.description}
                  name='description'
                  onChange={handleInputChange}
                  style={{ width: "80%" }}
                  color="warning"
                />
              </div>
            </div>
            <Button
              style={{ width: '20%', marginLeft: '10%',textTransform:"none" }}
            >
              <div className="create-product-img-con">
                <img
                  src={imageValue || imageValue1}
                  alt="product"
                  style={{ width: '100%', backgroundSize: "cover" }}

                  onClick={() => {
                    const input = document.getElementById('image-upload-input');
                    if (input) {
                      input.click();

                    }
                  }}
                />
                {
                  imageValue === null ? (<Typography className="create-product-img-text">{t("menu.catageory.addImg")}</Typography>) : ("")
                }

              </div>

              <input
                type="file"
                hidden
                id="image-upload-input"
                onChange={handleImageUpload}
              />
            </Button>
          </div>



          <div style={{ marginLeft: '1%', marginTop: "1%"}}>
            <div style={{ width: "50%" }} >
              <FormControlLabel
                control={<Checkbox color="warning" name='showPOS' checked={mainState.showPOS} onChange={handleInputChange} />}
                label={<Typography className='create-product-switch-text'>{t("menu.catageory.showPos")}</Typography>}
                style={{ width: "40%" }}
              />
              <FormControlLabel
                control={<Checkbox color="warning" name='showOnline' checked={mainState.showOnline} onChange={handleInputChange} />}
                label={<Typography className='create-product-switch-text'>{t("menu.catageory.showOnline")}</Typography>}
                style={{ width: "40%" }}
              />
            </div>
            <Divider/>
 <div style={{ display: "flex", alignItems: "center",marginTop:"2%" }}>
              <Button sx={{ textTransform: "none", textDecoration: "underline" }} className="create-product-cancel_Button" onClick={handleCancelButton}>clear</Button>
              <Button
  sx={{ textTransform: "none" }}
  className="create-product-button"
  id={mainState.categoryName.length < 1 ? "createCategory-save-button" : undefined}

  type="submit"
  disabled={mainState.categoryName.length < 1}
  onClick={handleOpenDailouge}
>
  {t("menu.catageory.save")}
</Button>




            </div>
          </div>
        </form>
      
     
   <AlertDialog dailouge={dailouge} handleUpdateDailougeState={handleUpdateDailougeState} handleComplete={handleComplete} categoryId={categoryId}/>

    </Box>
  );
}

export default CategoryDetails;
