import {TextField, Box, Button, IconButton } from '@mui/material'
import React, { useState } from 'react'

import Paper from '@mui/material/Paper';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LocationIdentifier from "../utils/locationIdentifier.tsx";
import RenderGoogleMap from "../utils/googleMaps";
import UseLocationData from '../../context/UseLocationData'
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {t} from "i18next"

import "./distanceOrZoneWise.css"
import GoogleMapForDistance from '../utils/googleMapForDistance.tsx';


interface States{
    zone:string 
    color:string
    distance:number
    cartAmount:number
    shippingRate:number
    lowCartSurcharge:number
    timeTakenForDelivery:string
    postCodes:string
    startTime:string
    endTime:string
}




const initialStates:States={
    zone:"" ,
    color:"",
    distance:0,
    cartAmount:0,
    shippingRate:0,
    lowCartSurcharge:0,
    timeTakenForDelivery:"",
    postCodes:"",
    startTime:"",
    endTime:""

}





function DistanceOrZoneWise() {
    const { t } = useTranslation();
    const [locationData, setLocationData] = useState<{}|undefined>();
    const [buttonState,setButtonState ] = React.useState("Circle");
    const [allInputValues,setAllInputValues]=useState<States>(initialStates)
    const [visibilityState,setVisibilityState]=useState(true)
    const [deliveryData, setDeliveryData] = useState({ radius: 200 });
    const [setTimeActiveButton,setSetTimeActiveButton]=useState(false)
    


    const [alignment, setAlignment] = React.useState('Circle');

    const handleChange = (
      event: React.MouseEvent<HTMLElement>,
      newAlignment: string,
    ) => {
      setAlignment(newAlignment);
    };

    const handleVisibility=()=>{
        setVisibilityState(!visibilityState)

    }

    console.log(allInputValues)

    const handleCancelButton=()=>{
      setAllInputValues(initialStates)
    }
    const handleDeliveryRadius = (data:{}) => {
      setDeliveryData((prev) => { return { ...prev, ...data } })
  }
  const updateLocationData = () => {
    setLocationData((prev) => { return { ...prev, ...deliveryData } })
}
    

    const handleDeleteIcon=()=>{}

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const { name, value, type, checked } = event.target;
        setAllInputValues((prevState) => ({
          ...prevState,
          [name]: type === 'checkbox' ? checked : value,
        }));
      };

    const {
        setValue
    } = useForm()

    const handlePositionUpdate = (position: any) => {
        setLocationData(position);
        Object.entries(position).forEach(([key, value]) => {
            setValue(key, value);
        });
        
    };
  return (
    <Box sx={{width:"100%",padding:"15px",display:"flex",justifyContent:"space-between",alignItems:'flex-start'}}>
      <UseLocationData.Provider value={{ locationData, handlePositionUpdate }}>
       <Box sx={{borderRadius:"10px",width:"63%"}}>
       
              <Box id="map-canvas">
                  <LocationIdentifier />
                   <GoogleMapForDistance />
              </Box>                   
     
       </Box>
       <Box sx={{width:"35%"}}>
        <Paper sx={{padding:"15px"}}  elevation={2}>
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className='distance-wise-accrodina-header'>{t("delivery_zone.zone1")}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",padding:"10px"}}>



        <Box sx={{width:"60%",marginBottom:"15px"}}>
        <ToggleButtonGroup
            value={alignment}
            exclusive
            fullWidth  
            onChange={handleChange}
            >
            <ToggleButton className={`distance-wise-circle-button ${alignment==="Circle"&& "distance-wise-group-circle-button"}`} sx={{textTransform:"none"}} value="Circle"><Typography className='distance-wise-circle-button'>{t("delivery_zone.circle")}</Typography></ToggleButton>
            <ToggleButton className={`distance-wise-circle-button ${alignment==="Shape"&& "distance-wise-group-circle-button"}`} sx={{textTransform:"none"}} value="Shape"><Typography className='distance-wise-circle-button'>{t("delivery_zone.shape")}</Typography></ToggleButton>
            </ToggleButtonGroup>
        </Box>



        <Box sx={{width:"92%",display:"flex",justifyContent:"space-between",marginTop:"15px",marginBottom:"15px"}}>
        <TextField
          label={<Typography className='distance-wise-label-text'>{t("delivery_zone.zone1")}</Typography>}
          name="zone"
          value={allInputValues.zone}
          onChange={handleInputChange}
          
        />
          <TextField
        label={t("delivery_zone.color")}
        onChange={e => handleDeliveryRadius({ stroke: e.target.value })} onBlur={updateLocationData}
        name="color"
        variant="outlined"
        defaultValue="#hexCode"
        sx={{width:"40%"}}
        type='color'
      />
        </Box>
        <Box sx={{width:"92%",display:"flex",justifyContent:"space-between",marginTop:"15px",marginBottom:"15px"}}>
        <TextField
          name="distance"
          label={<Typography className='distance-wise-label-text'>{t("delivery_zone.distance")}</Typography>}
          onChange={e => handleDeliveryRadius({ radius: parseInt(e.target.value, 10) })} onBlur={updateLocationData}
        />
        <TextField
        value={allInputValues.cartAmount}
          onChange={handleInputChange}
          name="cartAmount"
          label={<Typography className='distance-wise-label-text'>{t("delivery_zone.cart_amount")}</Typography>}         
        />
        </Box>
        <Box sx={{width:"92%",display:"flex",justifyContent:"space-between",marginTop:"15px",marginBottom:"15px"}}>
        <TextField
          value={allInputValues.lowCartSurcharge}
          onChange={handleInputChange}
          name="lowCartSurcharge"
          label={<Typography className='distance-wise-label-text'>{t("delivery_zone.low_car_surcharge")}</Typography>}
          
        />
        <TextField
        value={allInputValues.shippingRate}
          onChange={handleInputChange}
          name="shippingRate"
          label={<Typography className='distance-wise-label-text'>{t("delivery_zone.shipping_rate")}</Typography>}
          
        />
        </Box>

        <Box sx={{width:"92%",display:"flex",justifyContent:"space-between",marginTop:"15px",marginBottom:"15px"}}>
        <TextField
        value={allInputValues.timeTakenForDelivery}
         onChange={handleInputChange}
          name="timeTakenForDelivery"
          label={<Typography className='distance-wise-label-text'>{t("delivery_zone.time_taken_for_delivery")}</Typography>}      
        />
        <TextField
          onChange={handleInputChange}
          value={allInputValues.postCodes}
          name="postCodes"
          label={<Typography className='distance-wise-label-text'>{t("delivery_zone.postcodes")}</Typography>}
          
        />
        </Box>
        <Box sx={{width:"65%",display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
        {setTimeActiveButton?
        (<Box sx={{display:"flex",justifyContent:"space-between",width:"90%"}}>
          <Box sx={{marginRight:"10px"}}>
            <Typography>{t("delivery_zone.start_time")}</Typography>
            <TextField
              type="time"
              name="startTime"
              value={allInputValues.startTime}
              onChange={handleInputChange}
              sx={{ width: "150px" }}
            />
          </Box>
          <Box>
            <Typography>{t("delivery_zone.end_time")}</Typography>
            <TextField
              type="time"
              name="endTime"
              value={allInputValues.endTime}
              onChange={handleInputChange}
              sx={{ width: "150px" }}
            />
          </Box>
        </Box>):
        (<Button onClick={()=>setSetTimeActiveButton(!setTimeActiveButton)} className='distance-wise-clock-button' color='warning'><AccessTimeIcon sx={{marginRight:"10px"}}/>{t("delivery_zone.set_time")}</Button>)}
        </Box>
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",marginTop:"20px",width:"80%"}}>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"30%"}}>
                <IconButton onClick={handleDeleteIcon} className='distance-wise-cross-icon-button'>
                    <DeleteIcon/>
                </IconButton>
                <IconButton onClick={handleVisibility} className='distance-wise-cross-icon-button'>
                    {visibilityState?<VisibilityIcon/>:<VisibilityOffIcon/>}
                </IconButton>
            </Box>
            <Box sx={{display:"flex",justifyContent:"space-between",width:"50%"}}>
                <Button sx={{marginRight:"10px"}} onClick={handleCancelButton} className='distance-wise-cancel-button' color='warning' variant='outlined'>{t("delivery_zone.cancel")}</Button>
                <Button className='distance-wise-save-button' color='warning' variant='contained'>{t("delivery_zone.save")}</Button>
            </Box>

        </Box>

        
        
        <Box>

        </Box>
          
        </AccordionDetails>
      </Accordion>
        </Paper>
       
      
       </Box>
       </UseLocationData.Provider>
    </Box>
  )
}

export default DistanceOrZoneWise
