import  React,{ useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams,GridRowSelectionModel } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import { FormControl,InputLabel,Select,MenuItem,IconButton,InputBase ,Button, Switch} from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import './productTable.css'
import MoreVert from '@mui/icons-material/MoreVert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
interface Row {
  id: number;
  category: string;
  product: string;
  location :string;
  qty: number | null;
  price:string;
  status: boolean;
  
}



export default function DataGridDemo() {
  const rows: Row[] = [
    { id: 1, category: 'Snow', product: 'Jon', location: 'All Locations', qty: 35, price: "2", status: false },
    { id: 2, category: 'Lannister', product: 'Cersei', location: 'All Locations', qty: 42, price: "2", status: false },
    { id: 3, category: 'Lannister', product: 'Jaime', location: 'All Locations', qty: 45, price: "2", status: false },
    { id: 4, category: 'Stark', product: 'Arya', location: 'All Locations', qty: 16, price: "2", status: false },
    { id: 5, category: 'Targaryen', product: 'Daenerys', location: 'All Locations', qty: null, price: "2", status: false },
    { id: 6, category: 'Melisandre', product: '', location: 'All Locations', qty: 150, price: "2", status: false },
    { id: 7, category: 'Clifford', product: 'Ferrara', location: 'All Locations', qty: 44, price: "2", status: false },
    { id: 8, category: 'Frances', product: 'Rossini', location: 'All Locations', qty: 36, price: "2", status: false },
    { id: 9, category: 'Roxie', product: 'Harvey', location: 'All Locations', qty: 65, price: "2", status: false },
  ];
  

  
  
  
  const [rowsValue, setRowsValue] = useState<Row[]>(rows);
  const [rows2, setRows] = React.useState<Row[]>(rows);
 
  console.log(rows2)
  const handleAddRowClick = (rowId: number) => {
    // Create a new row below the clicked row
    const newRow: Row = {
      id: Date.now(),
      category: '',
      product: '',
      location: 'All Locations',
      qty: null,
      price: '2',
      status: false,
    };
    
  
    const rowIndex = rows2.findIndex((row) => row.id === rowId);
    if (rowIndex !== -1) {
      const newRows = [...rows2];
      newRows.splice(rowIndex + 1, 0, newRow);
      setRowsValue(newRows);
     
      setRows(newRows)
     
    }
   
  };
  const handleStatusChange = (rowId: number, newValue: boolean) => {
    const rowIndex = rows2.findIndex((row) => row.id === rowId);
    if (rowIndex !== -1) {
      const newRows = [...rows2];
      newRows[rowIndex].status = newValue;
      setRows(newRows);
    }
  };
  
  const columns: GridColDef[] = [
  
    {
      field: 'product',
      headerName: 'Products',
      headerClassName:"product_column",
      width: 200,
      editable: true,
    },
    {
      field: 'category',
      headerName: 'Category',
      headerClassName:"product_column",
      editable: false,
      width: 200,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => handleAddRowClick(params.row.id)}>
            <ArrowDropDownIcon/> 
          </IconButton>
          {params.value}
        </div>
      ),
    },
    {
      field: 'location',
      headerName: 'Location',
      headerClassName:"product_column",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: 'qty',
      headerName: 'Qty',
      headerClassName:"product_column",
      width: 100,
      editable: true,
    },
    {
      field:"status",
      headerName:"Status",
      headerClassName:"product_column",
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          onChange={(e) => handleStatusChange(params.row.id, e.target.checked)}
          color='warning'
        />
      ),
    },
    {
      field: 'price',
      headerName: 'Price',
      headerClassName:"product_column",
      width: 150,
      sortable: true,
      editable: true,
    },
    {
      field:"actions",
      headerName:"Actions",
      headerClassName:"product_column",
      disableColumnMenu:true,

      sortable: false,
      editable:false,
      renderCell:(params)=>(
        <IconButton>
        <MoreVert/>
        </IconButton>
      )
    },
  ];
  
 
 
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  
  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state
    const updatedRows = rows.filter((row) => !rowSelectionModel.includes(row.id));
    setRows(updatedRows);
    // Clear the selection after deletion
    setRowSelectionModel([]);
  };


  const [category, setCategory] = useState<string | undefined>("");

  const handleCategory = (event: SelectChangeEvent<string>) => {
    setCategory(event.target.value);
  }
  const [filterBy, setFilterBy] = useState<string | undefined>("");

  const handleFilter = (event: SelectChangeEvent<string>) => {
    setFilterBy(event.target.value);
  }

  const [actions, setActions] = useState<string | undefined>("");

  const handleActions = (event: SelectChangeEvent<string>) => {
    setActions(event.target.value);
  }

  
 const rowClass=()=>{
  return 'product_row'
 }
  

 const navigate=useNavigate()

 const handleNavigation=()=>{
  navigate('/create-product')
 }
  return (
    <div>
      <div className='product_head_con'>
      <div style={{width:"600%",display:"flex"}}>
      <FormControl style={{width:"20%",paddingRight:"1%"}}>
  <InputLabel id="demo-simple-select-label">All Category</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={category}
    label="All Category"
    onChange={handleCategory}
    color='warning'
  >
    <MenuItem value={10}>Ten</MenuItem>
    <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem>
  </Select>
</FormControl>
<FormControl style={{width:"18%",paddingRight:"1%"}}>
  <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={filterBy}
    label="Filter By"
    onChange={handleFilter}
    color='warning'
  >
    <MenuItem value={10}>Ten</MenuItem>
    <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem>
  </Select>
</FormControl>
<Box
          component="form"
          sx={{ display: "flex", alignItems: "center"}}
          className='product-search'
        >
          <IconButton type="button"  aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            //@ts-ignore
            placeholder="search by name"
            inputProps={{ "aria-label": "search google maps" }}
          />
        </Box>
        </div>
        <div style={{width:"400%"}}>
        <FormControl style={{width:"20%",paddingRight:"1%",marginLeft:"30%",height:"48px"}}>
  <InputLabel id="demo-simple-select-label" style={{color:"#F38B08"}}>Actions</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={actions}
    label="Actions"
    onChange={handleActions}
    color='warning'
  >
    <MenuItem value={10}>Ten</MenuItem>
    <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem>
  </Select>
</FormControl>
<Button className='product_button_style'  style={{height:"48px",textTransform:"none"}} onClick={handleNavigation}> Create a Product</Button>
</div>
</div>
    <Box sx={{ height: 400, width: '100%' }}>
      <div style={{width:"100%",textAlign:"right"}}>
      <Delete onClick={handleDeleteClick}/>
      </div>
      <DataGrid
  rows={rows2}
  getRowClassName={rowClass}
  columns={columns}
  initialState={{
    pagination: {
      paginationModel: {
        pageSize: 5,
      },
    },
  }}
  pageSizeOptions={[5, 7, 10]}
  checkboxSelection
  
  className="custom-checkbox-color" // Apply the warning-checkbox class
  disableRowSelectionOnClick
  onRowSelectionModelChange={(newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);
  }}
/>

    </Box>
    </div>
  );
}