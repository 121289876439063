import React, { useState } from "react";
import { Button, Divider, FormControlLabel, Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useForm, Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { Checkbox } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './locations.css';
import BusinessHours from "./businessHours";
import UploadImage from "../utils/uploadImage.tsx";
import LocationIdentifier from "../utils/locationIdentifier.tsx";
import GoogleMapForDistance from "../utils/googleMapForDistance.tsx";
import location from '../../assets/location.gif';
import UseLocationData from '../../context/UseLocationData'

const CreateLocation = () => {
    const { t } = useTranslation();
    const breadcrumbs = [
        <Typography key="1">{t("business_information.label")}</Typography>,
        <Typography key="3">
            {t("business_information.locations")}
        </Typography>,
        <Typography className="primaryColor" key="3">
            {t("business_information.create_location")}
        </Typography>,
    ];
    const [activeTab, setActiveTab] = useState('Dine In/Shop')

    const [locationData, setLocationData] = useState();
    const [deliveryData, setDeliveryData] = useState({ radius: 200 });
    const [image, setImage] = useState('')
    const {
        handleSubmit,
        control,
        setValue
    } = useForm()


    const handlePositionUpdate = (position) => {
        setLocationData(position);
        Object.entries(position).forEach(([key, value]) => {
            setValue(key, value);
        });
        console.log(position)
    };
    const handleDeliveryRadius = (data) => {
        setDeliveryData((prev) => { return { ...prev, ...data } })
    }
    const updateLocationData = () => {
        setLocationData((prev) => { return { ...prev, ...deliveryData } })
    }
    const [dineIn, setDineIn] = useState([
        { selected: false, day: 'Monday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Tuesday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Wednesday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Thursday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Friday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Saturday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Sunday', sets: [{ start: '', end: '' }] },
    ]);
    const [collection, setCollection] = useState([
        { selected: true, day: 'Monday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Tuesday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Wednesday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Thursday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Friday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Saturday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Sunday', sets: [{ start: '', end: '' }] },
    ]);
    const [delivery, setDelivery] = useState([
        { selected: false, day: 'Monday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Tuesday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Wednesday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Thursday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Friday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Saturday', sets: [{ start: '', end: '' }] },
        { selected: false, day: 'Sunday', sets: [{ start: '', end: '' }] },
    ]);
    const defaultBlockBookings = {
        start: '',
        end: '',
        dineIn: false,
        delivery: false,
        collection: false
    }
    const [blockBookings, setBlockBookings] = useState(defaultBlockBookings)

    const handleBlockChange = (type) => (event) => {

        let value = '';
        if (type === 'start' || type === 'end') value = event.target.value
        else value = event.target.checked
        let updatedBlockBookings = { ...blockBookings };

        // Time validation logic
        if (type === 'start') {
            updatedBlockBookings = {
                ...updatedBlockBookings,
                start: value,
                end: (updatedBlockBookings.end && value >= updatedBlockBookings.end) ? '' : updatedBlockBookings.end
            };
        } else if (type === 'end') {
            updatedBlockBookings = {
                ...updatedBlockBookings,
                end: value,
                start: (updatedBlockBookings.start && value <= updatedBlockBookings.start) ? '' : updatedBlockBookings.start
            };
        } else {
            updatedBlockBookings = { ...updatedBlockBookings, [type]: value };
        }

        setBlockBookings(updatedBlockBookings);
    };

    const clearBlockBookings = () => {
        setBlockBookings(defaultBlockBookings)
    }



    const onSubmit = (data) => console.log({ ...data, ...locationData, ...blockBookings, ...dineIn, image: image });
    
    return (
        <UseLocationData.Provider value={{ locationData, handlePositionUpdate }}>
            <>
                <LocationIdentifier />
                <Paper className="paper">
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Divider className="divider" />

                    <Box>
                        <form className="locationForm" onSubmit={handleSubmit(onSubmit)}>
                            <Grid container rowSpacing={2} columnSpacing={1}>
                                <Grid item xs={12}><h3>{t("create_location.basic_details")}</h3></Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        className="locationInput"
                                        sx={{ m: 1 }}
                                        variant="standard"
                                    >
                                        <InputLabel htmlFor="standard-adornment-brand-name">
                                            {t("create_location.brand_name")}
                                        </InputLabel>
                                        <Controller
                                            name="brandName"
                                            control={control}
                                            render={({ field }) => <Input {...field} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        className="locationInput"
                                        sx={{ m: 1 }}
                                        variant="standard"
                                    >
                                        <InputLabel htmlFor="standard-adornment-local-name">
                                            {t("create_location.local_name")}
                                        </InputLabel>
                                        <Controller
                                            name="localName"
                                            control={control}
                                            render={({ field }) => <Input {...field} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <p>{t("create_location.location_description")}</p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        className="locationInput"
                                        sx={{ m: 1 }}
                                        variant="standard"
                                    >
                                        <InputLabel htmlFor="standard-adornment-business-info">
                                            {t("create_location.business_information")}
                                        </InputLabel>
                                        <Controller
                                            name="businessInfo"
                                            control={control}
                                            render={({ field }) => <Input placeholder={t("create_location.business_info_placeholder")} multiline rows={4} {...field} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        className="locationInput"
                                        variant="standard"
                                        sx={{ m: 1 }}

                                    >
                                        <InputLabel id="location-type">
                                            {t("create_location.location_type")}
                                        </InputLabel>
                                        <Controller
                                            name="locationType"
                                            control={control}
                                            defaultValue={" "}
                                            render={({ field }) => (
                                                <Select
                                                    labelId="location-type"
                                                    id="location-type"
                                                    label={t("create_location.location_type")}
                                                    {...field}
                                                >
                                                    <MenuItem value=" ">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value="Physical">Physical</MenuItem>
                                                    <MenuItem value="Mobile">Mobile</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className="mapContainer" id="map-canvas">
                                        {locationData ?
                                            <GoogleMapForDistance /> :
                                            <img className="loadingLocation" width={200} alt="loaction" src={location} />
                                        }
                                    </div>
                                </Grid>
                                {locationData && (
                                    <>
                                        <Grid item xs={12}><h3>{t("create_location.business_address")}</h3></Grid><Grid item xs={12} md={6}>
                                            <FormControl
                                                className="locationInput"
                                                sx={{ m: 1 }}
                                                variant="standard"
                                            >
                                                <InputLabel htmlFor="standard-adornment-address-line1">
                                                    {t("create_location.address_line1")}
                                                </InputLabel>
                                                <Controller
                                                    name="addressLine1"
                                                    defaultValue={locationData.address1}
                                                    control={control}
                                                    render={({ field }) => <Input {...field} />} />

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl
                                                className="locationInput"

                                                sx={{ m: 1 }}
                                                variant="standard"
                                            >
                                                <InputLabel htmlFor="standard-adornment-address-line2">
                                                    {t("create_location.address_line2")}
                                                </InputLabel>
                                                <Controller
                                                    name="addressLine2"
                                                    defaultValue={locationData.address2}
                                                    control={control}
                                                    render={({ field }) => <Input {...field} />} />

                                            </FormControl>
                                        </Grid><Grid item xs={12} md={6}>
                                            <FormControl
                                                className="locationInput"

                                                sx={{ m: 1 }}
                                                variant="standard"
                                            >
                                                <InputLabel htmlFor="standard-adornment-street">
                                                    {t("create_location.street")}
                                                </InputLabel>
                                                <Controller
                                                    name="street"
                                                    control={control}
                                                    defaultValue={locationData.street}
                                                    render={({ field }) => <Input {...field} />} />

                                            </FormControl>
                                        </Grid><Grid item xs={12} md={6}>
                                            <FormControl
                                                className="locationInput"

                                                sx={{ m: 1 }}
                                                variant="standard"
                                            >
                                                <InputLabel htmlFor="standard-adornment-city">
                                                    {t("create_location.city")}
                                                </InputLabel>
                                                <Controller
                                                    name="city"
                                                    defaultValue={locationData.city}
                                                    control={control}
                                                    render={({ field }) => <Input {...field} />} />

                                            </FormControl>
                                        </Grid><Grid item xs={12} md={6}>
                                            <FormControl
                                                className="locationInput"

                                                sx={{ m: 1 }}
                                                variant="standard"
                                            >
                                                <InputLabel htmlFor="standard-adornment-post-code">
                                                    {t("create_location.post_code")}
                                                </InputLabel>
                                                <Controller
                                                    name="postCode"
                                                    defaultValue={locationData.pincode}
                                                    control={control}
                                                    render={({ field }) => <Input {...field} />} />

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl
                                                className="locationInput"

                                                sx={{ m: 1 }}
                                                variant="standard"
                                            >
                                                <InputLabel htmlFor="standard-adornment-state">
                                                    {t("create_location.state")}
                                                </InputLabel>
                                                <Controller
                                                    name="state"
                                                    control={control}
                                                    defaultValue={locationData.state}
                                                    render={({ field }) => <Input {...field} />} />

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}><h3>{t("create_location.delivery_radius")}</h3></Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl
                                                className="locationInput"
                                                sx={{ m: 1 }}
                                                variant="standard"
                                            >
                                                <InputLabel htmlFor="standard-adornment-radius">
                                                    {t("create_location.radius")}
                                                </InputLabel>
                                                <Controller
                                                    name="radius"
                                                    control={control}
                                                    defaultValue={deliveryData.radius}
                                                    render={({ field }) => <Input {...field} value={deliveryData.radius} type="number" onChange={e => handleDeliveryRadius({ radius: parseInt(e.target.value, 10) })} onBlur={updateLocationData} />} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl
                                                className="locationInput"
                                                sx={{ m: 1 }}
                                                variant="standard"
                                            >
                                                <InputLabel htmlFor="standard-adornment-zone-color">
                                                    {t("create_location.zone_color")}
                                                </InputLabel>
                                                <Controller
                                                    name="stroke"
                                                    control={control}
                                                    render={({ field }) => <Input {...field} value={deliveryData.stroke ?? '#ffbc0d'} type="color" onChange={e => handleDeliveryRadius({ stroke: e.target.value })} onBlur={updateLocationData} />} />
                                            </FormControl>
                                        </Grid>
                                    </>)}

                                <Grid item xs={12}><h3>{t("create_location.contact_information")}</h3></Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        className="locationInput"

                                        sx={{ m: 1 }}
                                        variant="standard"
                                    >
                                        <InputLabel htmlFor="standard-adornment-email">
                                            {t("create_location.email")}
                                        </InputLabel>
                                        <Controller
                                            name="email"

                                            control={control}
                                            render={({ field }) => <Input {...field} />}
                                        />

                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        className="locationInput"

                                        sx={{ m: 1 }}
                                        variant="standard"
                                    >
                                        <InputLabel htmlFor="standard-adornment-phone">
                                            {t("create_location.phone")}
                                        </InputLabel>
                                        <Controller
                                            name="phone"

                                            control={control}
                                            render={({ field }) => <Input {...field} />}
                                        />

                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        className="locationInput"

                                        sx={{ m: 1 }}
                                        variant="standard"
                                    >
                                        <InputLabel htmlFor="standard-adornment-twitter">
                                            {t("create_location.twitter")}
                                        </InputLabel>
                                        <Controller
                                            name="twitter"

                                            control={control}
                                            render={({ field }) => <Input {...field} />}
                                        />

                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        className="locationInput"

                                        sx={{ m: 1 }}
                                        variant="standard"
                                    >
                                        <InputLabel htmlFor="standard-adornment-instagram">
                                            {t("create_location.instagram")}
                                        </InputLabel>
                                        <Controller
                                            name="instagram"

                                            control={control}
                                            render={({ field }) => <Input {...field} />}
                                        />

                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        className="locationInput"

                                        sx={{ m: 1 }}
                                        variant="standard"
                                    >
                                        <InputLabel htmlFor="standard-adornment-facebook">
                                            {t("create_location.facebook")}
                                        </InputLabel>
                                        <Controller
                                            name="facebook"

                                            control={control}
                                            render={({ field }) => <Input {...field} />}
                                        />

                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        className="locationInput"

                                        sx={{ m: 1 }}
                                        variant="standard"
                                    >
                                        <InputLabel htmlFor="standard-adornment-website">
                                            {t("create_location.website")}
                                        </InputLabel>
                                        <Controller
                                            name="website"

                                            control={control}
                                            render={({ field }) => <Input {...field} />}
                                        />

                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <h3>{t("create_location.branding")}</h3><br />
                                    <p>{t("create_location.branding_description")}</p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <h3>{t("create_location.logo")}</h3><br />
                                    <UploadImage image={image} setImage={setImage} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <h3 className="mt-2">{t("create_location.business_time_zone")}</h3><br />
                                    <FormControl
                                        className="locationInput"
                                        variant="standard"
                                        sx={{ m: 1 }}
                                    >
                                        <InputLabel id="time-zone">
                                            {" "}
                                            {t("create_location.time_zone")}
                                        </InputLabel>
                                        <Controller
                                            name="timeZone"
                                            control={control}
                                            defaultValue={" "}
                                            render={({ field }) => (
                                                <Select
                                                    labelId="time-zone"
                                                    id="time-zone"
                                                    label={t("create_location.time_zone")}
                                                    {...field}
                                                >
                                                    <MenuItem value=" ">
                                                        Select your time zone
                                                    </MenuItem>
                                                    <MenuItem value={"United Kingdom"}>United Kingdom</MenuItem>
                                                    <MenuItem value={"India"}>India</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <h3 className="mt-2">{t("create_location.preferred_language")} <span style={{ fontSize: '11px', fontWeight: "450" }}>({t("create_location.preferred_language_desc")})</span></h3><br />
                                    <FormControl
                                        className="locationInput"
                                        variant="standard"
                                        sx={{ m: 1 }}
                                    >
                                        <InputLabel id="pref-lang">
                                            {" "}
                                            {t("create_location.language")}
                                        </InputLabel>
                                        <Controller
                                            name="preferredLanguage"
                                            control={control}
                                            defaultValue={" "}
                                            render={({ field }) => (
                                                <Select
                                                    labelId="time-zone"
                                                    id="time-zone"
                                                    label={t("create_location.language")}
                                                    {...field}
                                                >
                                                    <MenuItem value=" ">
                                                        Select Language
                                                    </MenuItem>
                                                    <MenuItem value={"Telugu"}>Telugu</MenuItem>
                                                    <MenuItem value={"English"}>English</MenuItem>
                                                    <MenuItem value={"French"}>French</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}><h3>{t("create_location.business_hours")}</h3><p>{t("create_location.business_hour_desc")}</p></Grid>
                                <Grid item xs={12}>
                                    <div className="tabsHeaderHolder">
                                        <div className={activeTab === 'Dine In/Shop' && "active"} onClick={() => setActiveTab('Dine In/Shop')}>
                                            {t("create_location.dinein")}
                                        </div>
                                        <div className={activeTab === 'Collection' && "active"} onClick={() => setActiveTab('Collection')}>
                                            {t("create_location.collection")}
                                        </div>
                                        <div className={activeTab === 'Delivery' && "active"} onClick={() => setActiveTab('Delivery')}>
                                            {t("create_location.delivery")}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    {activeTab === 'Dine In/Shop' && <BusinessHours data={dineIn} updateState={setDineIn} />}
                                    {activeTab === 'Collection' && <BusinessHours data={collection} updateState={setCollection} />}
                                    {activeTab === 'Delivery' && <BusinessHours data={delivery} updateState={setDelivery} />}
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="Block-Bookings-content"
                                            id="Block-Bookings-header"
                                        >
                                            <Typography>{t("create_location.block_bookings_label")}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={2}>
                                                    <FormControl
                                                        className="locationInput"
                                                        sx={{ m: 1 }}
                                                        variant="standard"
                                                        focused
                                                    >
                                                        <InputLabel htmlFor="standard-adornment-block-start">
                                                            {t("create_location.start_time")}
                                                        </InputLabel>
                                                        <Input type='time' value={blockBookings.start} onChange={handleBlockChange('start')} />

                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <FormControl
                                                        className="locationInput"
                                                        sx={{ m: 1 }}
                                                        variant="standard"
                                                        focused
                                                    >
                                                        <InputLabel htmlFor="standard-adornment-block-end">
                                                            {t("create_location.stop_time")}
                                                        </InputLabel>
                                                        <Input type='time' value={blockBookings.end} onChange={handleBlockChange('end')} />


                                                    </FormControl>
                                                </Grid>
                                                <Grid item container md={12}>
                                                    <Grid item xs={12} md={12}><p>{t("create_location.booking_type")}</p></Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <FormControlLabel
                                                            control={<Checkbox color="warning" checked={blockBookings.dineIn} onChange={handleBlockChange('dineIn')} />}
                                                            label="Dine In"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <FormControlLabel
                                                            control={<Checkbox color="warning" checked={blockBookings.delivery} onChange={handleBlockChange('delivery')} />}
                                                            label="Delivery"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <FormControlLabel
                                                            control={<Checkbox color="warning" checked={blockBookings.collection} onChange={handleBlockChange('collection')} />}
                                                            label="Collection"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                        <Button
                                                            focusRipple
                                                            variant="contained"
                                                            type="button"
                                                            color="error"
                                                            onClick={clearBlockBookings}
                                                        >
                                                            Clear
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                        <Button
                                                            className="primaryButton"
                                                            focusRipple
                                                            variant="contained"
                                                            type="button"
                                                        >
                                                            Block
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="delivery-order-content"
                                            id="delivery-order-header"
                                        >
                                            <Typography>{t("create_location.advance_delivery")}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <Grid container>
                                                    <Grid item xs={12} md={3}>
                                                        <FormControl
                                                            className="locationInput"
                                                            sx={{ m: 1 }}
                                                            variant="standard"
                                                            focused
                                                        >
                                                            <InputLabel htmlFor="standard-adornment-delivery-min-time">
                                                                {t("create_location.min_time")}
                                                            </InputLabel>
                                                            <Controller
                                                                name="deliveryMinTime"
                                                                control={control}
                                                                rules={{ min: 0 }}
                                                                render={({ field }) => <Input type="number" placeholder="ex: 60" {...field} />}
                                                            />

                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <FormControl
                                                            className="locationInput"
                                                            variant="standard"
                                                            sx={{ m: 1 }}
                                                        >
                                                            <InputLabel id="pref-lang">
                                                            </InputLabel>
                                                            <Controller
                                                                name="deliveryMinTimeType"
                                                                control={control}
                                                                defaultValue={"Seconds"}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        labelId="delivery-min"
                                                                        id="delivery-min"
                                                                        {...field}
                                                                    >
                                                                        <MenuItem value={"Seconds"}>Seconds</MenuItem>
                                                                        <MenuItem value={"Minutes"}>Minutes</MenuItem>
                                                                        <MenuItem value={"Hours"}>Hours</MenuItem>
                                                                        <MenuItem value={"Days"}>Days</MenuItem>
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={12} md={3}>
                                                        <FormControl
                                                            className="locationInput"
                                                            sx={{ m: 1 }}
                                                            variant="standard"
                                                            focused
                                                        >
                                                            <InputLabel htmlFor="standard-adornment-delivery-max-time">
                                                                {t("create_location.max_time")}
                                                            </InputLabel>
                                                            <Controller
                                                                name="deliveryMaxTime"
                                                                control={control}
                                                                rules={{ min: 0 }}
                                                                render={({ field }) => <Input type="number" placeholder="ex: 60" {...field} />}
                                                            />

                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <FormControl
                                                            className="locationInput"
                                                            variant="standard"
                                                            sx={{ m: 1 }}
                                                        >
                                                            <InputLabel id="pref-lang">
                                                            </InputLabel>
                                                            <Controller
                                                                name="deliveryMaxTimeType"
                                                                control={control}
                                                                defaultValue={"Seconds"}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        labelId="delivery-max"
                                                                        id="delivery-max"
                                                                        {...field}
                                                                    >
                                                                        <MenuItem value={"Seconds"}>Seconds</MenuItem>
                                                                        <MenuItem value={"Minutes"}>Minutes</MenuItem>
                                                                        <MenuItem value={"Hours"}>Hours</MenuItem>
                                                                        <MenuItem value={"Days"}>Days</MenuItem>
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={12} md={3}>
                                                        <FormControl
                                                            className="locationInput"
                                                            variant="standard"
                                                            sx={{ m: 1 }}
                                                        >
                                                            <InputLabel id="pref-lang">
                                                                {t("create_location.time_slot")}
                                                            </InputLabel>
                                                            <Controller
                                                                name="deliveryTimeSlot"
                                                                control={control}
                                                                defaultValue={"05 Minutes"}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        labelId="delivery-time-slot"
                                                                        id="delivery-time-slot"
                                                                        {...field}
                                                                    >
                                                                        <MenuItem value={"05 Minutes"}> 05 Minutes</MenuItem>
                                                                        <MenuItem value={"10 Minutes"}> 10 Minutes</MenuItem>
                                                                        <MenuItem value={"15 Minutes"}> 15 Minutes</MenuItem>
                                                                        <MenuItem value={"20 Minutes"}> 20 Minutes</MenuItem>
                                                                        <MenuItem value={"25 Minutes"}> 25 Minutes</MenuItem>
                                                                        <MenuItem value={"30 Minutes"}> 30 Minutes</MenuItem>
                                                                        <MenuItem value={"35 Minutes"}> 35 Minutes</MenuItem>
                                                                        <MenuItem value={"40 Minutes"}> 40 Minutes</MenuItem>
                                                                        <MenuItem value={"45 Minutes"}> 45 Minutes</MenuItem>
                                                                        <MenuItem value={"50 Minutes"}> 50 Minutes</MenuItem>
                                                                        <MenuItem value={"55 Minutes"}> 55 Minutes</MenuItem>
                                                                        <MenuItem value={"60 Minutes"}> 60 Minutes</MenuItem>
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="collection-order-content"
                                            id="collection-order-header"
                                        >
                                            <Typography>{t("create_location.collection_delivery")}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <Grid container >
                                                    <Grid item xs={12} md={3}>
                                                        <FormControl
                                                            className="locationInput"
                                                            sx={{ m: 1 }}
                                                            variant="standard"
                                                            focused
                                                        >
                                                            <InputLabel htmlFor="standard-adornment-collection-min-time">
                                                                {t("create_location.min_time")}
                                                            </InputLabel>
                                                            <Controller
                                                                name="collectionMinTime"
                                                                control={control}
                                                                rules={{ min: 0 }}
                                                                render={({ field }) => <Input type="number" placeholder="ex: 60" {...field} />}
                                                            />

                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <FormControl
                                                            className="locationInput"
                                                            variant="standard"
                                                            sx={{ m: 1 }}
                                                        >
                                                            <InputLabel id="pref-lang">
                                                            </InputLabel>
                                                            <Controller
                                                                name="collectionMinTimeType"
                                                                control={control}
                                                                defaultValue={"Seconds"}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        labelId="collection-min"
                                                                        id="collection-min"
                                                                        {...field}
                                                                    >
                                                                        <MenuItem value={"Seconds"}>Seconds</MenuItem>
                                                                        <MenuItem value={"Minutes"}>Minutes</MenuItem>
                                                                        <MenuItem value={"Hours"}>Hours</MenuItem>
                                                                        <MenuItem value={"Days"}>Days</MenuItem>
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={12} md={3}>
                                                        <FormControl
                                                            className="locationInput"
                                                            sx={{ m: 1 }}
                                                            variant="standard"
                                                            focused
                                                        >
                                                            <InputLabel htmlFor="standard-adornment-collection-max-time">
                                                                {t("create_location.max_time")}
                                                            </InputLabel>
                                                            <Controller
                                                                name="collectionMaxTime"
                                                                control={control}
                                                                rules={{ min: 0 }}
                                                                render={({ field }) => <Input type="number" placeholder="ex: 60" {...field} />}
                                                            />

                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <FormControl
                                                            className="locationInput"
                                                            variant="standard"
                                                            sx={{ m: 1 }}
                                                        >
                                                            <InputLabel id="pref-lang">
                                                            </InputLabel>
                                                            <Controller
                                                                name="collectionMaxTimeType"
                                                                control={control}
                                                                defaultValue={"Seconds"}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        labelId="collection-max"
                                                                        id="collection-max"
                                                                        {...field}
                                                                    >
                                                                        <MenuItem value={"Seconds"}>Seconds</MenuItem>
                                                                        <MenuItem value={"Minutes"}>Minutes</MenuItem>
                                                                        <MenuItem value={"Hours"}>Hours</MenuItem>
                                                                        <MenuItem value={"Days"}>Days</MenuItem>
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={12} md={3}>
                                                        <FormControl
                                                            className="locationInput"
                                                            variant="standard"
                                                            sx={{ m: 1 }}
                                                        >
                                                            <InputLabel id="pref-lang">
                                                                {t("create_location.time_slot")}
                                                            </InputLabel>
                                                            <Controller
                                                                name="collectionTimeSlot"
                                                                control={control}
                                                                defaultValue={"05 Minutes"}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        labelId="collection-time-slot"
                                                                        id="collection-time-slot"
                                                                        {...field}
                                                                    >
                                                                        <MenuItem value={"05 Minutes"}> 05 Minutes</MenuItem>
                                                                        <MenuItem value={"10 Minutes"}> 10 Minutes</MenuItem>
                                                                        <MenuItem value={"15 Minutes"}> 15 Minutes</MenuItem>
                                                                        <MenuItem value={"20 Minutes"}> 20 Minutes</MenuItem>
                                                                        <MenuItem value={"25 Minutes"}> 25 Minutes</MenuItem>
                                                                        <MenuItem value={"30 Minutes"}> 30 Minutes</MenuItem>
                                                                        <MenuItem value={"35 Minutes"}> 35 Minutes</MenuItem>
                                                                        <MenuItem value={"40 Minutes"}> 40 Minutes</MenuItem>
                                                                        <MenuItem value={"45 Minutes"}> 45 Minutes</MenuItem>
                                                                        <MenuItem value={"50 Minutes"}> 50 Minutes</MenuItem>
                                                                        <MenuItem value={"55 Minutes"}> 55 Minutes</MenuItem>
                                                                        <MenuItem value={"60 Minutes"}> 60 Minutes</MenuItem>
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                            </Grid>
                            <br />
                            <br />
                            <Button
                                focusRipple
                                className="primaryButton"
                                variant="contained"
                                type="submit"
                            >
                                {t("buttons.submit")}
                            </Button>
                        </form>
                    </Box>
                </Paper>
            </>
        </UseLocationData.Provider>
    );
};

export default CreateLocation;
