import * as React from 'react';
import Box from '@mui/material/Box';
import {Divider} from '@mui/material'
import {Button} from '@mui/material'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 620,
  height: 320,
  bgcolor: 'background.paper',
  
  boxShadow: 24,
  p: 4,
  borderRadius: 2, 
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(true)
  
  const handleClose = () => setOpen(false);

  return (
    <div>
     
      <Modal
        open={open}
       
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div style={{display:"flex",alignItems:'center'}}>
<div style={{
    width: '40px',
    height: '40px',
    borderRadius: '4px',
    background: 'var(--text-200, #EEE)',
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    marginRight:'113px'
  }}
  onMouseEnter={(e) => (e.currentTarget.style.background = 'var(--text-300, #FFF8F0)')}
  onMouseLeave={(e) => (e.currentTarget.style.background = 'var(--text-200, #EEE)')}
  >
<CloseIcon onClick={handleClose} sx={{height:24,width:24}}/>
</div>
          <Typography sx={{
    color: '#333',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
  }}>
          You have unsaved changes.
          </Typography>
          <Divider sx={{background:'#EEE',mt:2}}/>
          </div>
          <Typography sx={{
    color: '#707070',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: '55px',
  }}>
          Are you sure you want to leave this screen and discard your changes?
          </Typography>
          
          <Button sx={{
    color: 'var(--prime-orange-900, #F38B08)',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    display: 'inline-flex',
    height: '44px',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
    flexShrink: 0,
    borderRadius: '4px',
    background: 'var(--text-200, #EEE)',
    marginRight:"51px",
    marginLeft:'104px',
    marginTop:"66px",
    "&:hover": {
        background: 'var(--text-300, #FFF8F0)',
      }
  }}
  //onMouseEnter={(e) => (e.currentTarget.style.background = 'var(--text-300, #FFF8F0)')}
         //</Box> onMouseLeave={(e) => (e.currentTarget.style.background = 'var(--text-200, #EEE)')}
         >Discard Changes</Button>
          <Button
          sx={{
            color: 'var(--base-base-100, #FEFEFE)',
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            display: 'inline-flex',
            height: '44px',
            padding: '8px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '24px',
            flexShrink: 0,
            borderRadius: '4px',
            background: ' #F38B08',
            marginTop:"66px",
            "&:hover": {
                background: 'var(--text-300, #E5850B)',
              }
          }}
          //onMouseEnter={(e) => (e.currentTarget.style.background = 'var(--text-300, #E5850B)')}
          //</Box>onMouseLeave={(e) => (e.currentTarget.style.background = 'var(--text-200, #F38B08)')}
          >Save Changes</Button>
        </Box>
      </Modal>
    </div>
  );
}