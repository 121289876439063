import { Box,Button,IconButton, Paper, Typography,InputBase } from '@mui/material'
import React from 'react'
import CloseIcon from "@mui/icons-material/Close"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {t} from "i18next"

import "./kdsEditAssignedItems.css"

const initialItems=[
    {
        id:1,
        item:"Margarita Pizza"
    },
    {
        id:2,
        item:"DumBiryani"
    },
    {
        id:3,
        item:"Italian Pizza"
    },
    {
        id:4,
        item:"French Fries"
    },
    {
        id:5,
        item:"Mutton Biryani"
    },
]

function KdsEditAssignedItems() {
    const [items1, setItems1] = React.useState(initialItems);
    const [items2, setItems2] = React.useState(initialItems);
    const [items3, setItems3] = React.useState(initialItems);
    const [searchInput1, setsearchInput1] = React.useState("");
    const [searchInput2, setsearchInput2] = React.useState("");
    const [searchInput3, setsearchInput3] = React.useState("");
    const handleDragEnd = (result:any) => {
        if (!result.destination) {
          return;
        }
    
        const reorderedItems = [...items1];
        const [movedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, movedItem);
    
        setItems1(reorderedItems);
      };

      const handleSearch1=(e:React.ChangeEvent<HTMLInputElement>)=>{
          setsearchInput1(e.target.value)
      }
      const handleSearch2=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setsearchInput2(e.target.value)
    }
    const handleSearch3=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setsearchInput3(e.target.value)
    }
      const filteredRows1=items1.filter((each)=>each.item.toLocaleLowerCase().includes(searchInput1))
      const filteredRows2=items2.filter((each)=>each.item.toLocaleLowerCase().includes(searchInput2))
      const filteredRows3=items3.filter((each)=>each.item.toLocaleLowerCase().includes(searchInput3))
  return (
    <Box sx={{padding:"15px"}}>
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"10px"}}>
        <IconButton sx={{ marginRight: "50px" }} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
            <ArrowBackIcon />
        </IconButton>
        <Typography className='kds-edit-assigned-items-header'>{t("kds_edit_assignment_items.edit_items_assigned_to_kds")}</Typography>
        <Box sx={{width:"9%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Button sx={{textTransform:"none"}} color='warning' variant='outlined'>{t("kds_edit_assignment_items.clear")}</Button>
            <Button sx={{textTransform:"none"}} color='warning' variant='contained'>{t("kds_edit_assignment_items.save")}</Button>
        </Box>
        </Box>
        <Box sx={{margin:"30px"}}>
            <Typography className='kds-edit-assigned-items-basic-text'>{t("kds_edit_assignment_items.basic_details")}</Typography>
            <Typography className='kds-edit-assigned-items-basic-description'>{t("kds_edit_assignment_items.items_that_are_assigned")}</Typography>
        </Box>
        <Box  sx={{width:"80%",display:"flex",justifyContent:"space-between",alignItems:"flex-start",margin:"20px"}}>
            <Box sx={{margin:"20px",width:"20%"}}>
                <Typography sx={{marginBottom:"15px"}}  className='kds-edit-assigned-items-basic-text'>{t("kds_edit_assignment_items.kds_1")}</Typography>
          <Paper elevation={2} sx={{padding:"10px",width:"150%"}}>
           <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "98%" }}
                >
                 <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
                <InputBase
                onChange={handleSearch1}

                value={searchInput1}
                    sx={{ ml: 1, flex: 1 }}
                    //@ts-ignore
                    placeholder={t("kds_edit_assignment_items.search_by_item_name")}
                
                />
                 
                </Paper>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="list">
                        {(provided) => (
                        <Paper sx={{padding:"12px",marginTop:"15px"}} {...provided.droppableProps} ref={provided.innerRef}>
                            {filteredRows1.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.item} index={index}>
                                {(provided) => (
                                <Typography
                                className='kds-edit-assigned-items-drag-item'
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                >
                                    {item.item}
                                </Typography>
                                )}
                            </Draggable>
                            ))}
                            {provided.placeholder}
                        </Paper>
                        )}
                    </Droppable>
                    </DragDropContext>


          </Paper>
            </Box>
            <Box  sx={{margin:"20px",width:"20%"}}>
                <Typography sx={{marginBottom:"15px"}}  className='kds-edit-assigned-items-basic-text'>{t("kds_edit_assignment_items.kds_2")}</Typography>
          <Paper elevation={2} sx={{padding:"10px",width:"150%"}}>
           <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "98%" }}
                >
                 <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
                <InputBase
                onChange={handleSearch2}
                value={searchInput2}
                    sx={{ ml: 1, flex: 1 }}
                    //@ts-ignore
                    placeholder={t("kds_edit_assignment_items.search_by_item_name")}
                
                />
                 
                </Paper>
              <Box sx={{width:"100%"}}>
              <DragDropContext  onDragEnd={handleDragEnd}>
                    <Droppable droppableId="list">
                        {(provided) => (
                        <Paper sx={{padding:"12px",marginTop:"15px"}} {...provided.droppableProps} ref={provided.innerRef}>
                            {filteredRows2.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.item} index={index}>
                                {(provided) => (
                                <Typography
                                className='kds-edit-assigned-items-drag-item'
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                >
                                    {item.item}
                                </Typography>
                                )}
                            </Draggable>
                            ))}
                            {provided.placeholder}
                        </Paper>
                        )}
                    </Droppable>
                    </DragDropContext>
              </Box>


          </Paper>
            </Box>
            <Box sx={{margin:"20px",width:"20%"}}>
                <Typography sx={{marginBottom:"15px"}}  className='kds-edit-assigned-items-basic-text'>{t("kds_edit_assignment_items.kds_3")}</Typography>
          <Paper elevation={2} sx={{padding:"10px",width:"150%"}}>
           <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "98%" }}
                >
                 <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
                <InputBase
                onChange={handleSearch3}
                value={searchInput3}
                    sx={{ ml: 1, flex: 1 }}
                    //@ts-ignore
                    placeholder={t("kds_edit_assignment_items.search_by_item_name")}
                
                />
                 
                </Paper>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="list">
                        {(provided) => (
                        <Paper sx={{padding:"12px",marginTop:"15px"}} {...provided.droppableProps} ref={provided.innerRef}>
                            {filteredRows3.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.item} index={index}>
                                {(provided) => (
                                <Typography
                                className='kds-edit-assigned-items-drag-item'
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                >
                                    {item.item}
                                </Typography>
                                )}
                            </Draggable>
                            ))}
                            {provided.placeholder}
                        </Paper>
                        )}
                    </Droppable>
                    </DragDropContext>


          </Paper>
            </Box>
        </Box>
    </Box>
  )
}

export default KdsEditAssignedItems
