import React, { useState, useEffect } from 'react';
import {
  Paper,
  Divider,
  TextField,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  List,
  ListItem,
  ListItemText,
  Popover,
  IconButton,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './discountSelfListing.css';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { t } from 'i18next';

const DiscountSelfMade: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/create-discount');
  };

  interface UserData {
    id: number;
    discountName: string;
    location: string;
    couponCode: string;
    reason: string;
    amount: number;
  }

  const [usersData, setUsersData] = useState<UserData[]>([]);

  useEffect(() => {
    axios
      .get('/discountList.json')
      .then((response) => {
        setUsersData(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const [switchStates, setSwitchStates] = useState<boolean[]>(
    usersData.map(() => false)
  );

  const handleSwitchChange = (index: number) => {
    const updatedStates = [...switchStates];
    updatedStates[index] = !updatedStates[index];
    setSwitchStates(updatedStates);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const popoverContent = (
    <List>
      <ListItem
        style={{
          width: '180px',
          height: '40px',
          backgroundColor: 'var(--prime-orange-100, #FFF8F0)',
        }}
      >
        <ListItemText primary={t('selfMadeDiscount.edit')} className="pover-text-style" />
      </ListItem>
      <Divider />
      <ListItem
        style={{
          width: '180px',
          height: '40px',
          backgroundColor: 'var(--prime-orange-100, #FFF8F0)',
        }}
      >
        <ListItemText
          primary={t('selfMadeDiscount.offer_history')}
          className="pover-text-style"
        />
      </ListItem>
      <Divider />
      <ListItem
        style={{
          width: '180px',
          height: '40px',
          backgroundColor: 'var(--prime-orange-100, #FFF8F0)',
        }}
      >
        <ListItemText
          primary={t('selfMadeDiscount.duplicate_copy')}
          className="pover-text-style"
        />
      </ListItem>
      <Divider />
      <ListItem
        style={{
          width: '180px',
          height: '40px',
          backgroundColor: 'var(--prime-orange-100, #FFF8F0)',
        }}
      >
        <ListItemText primary={t('selfMadeDiscount.delete')} className="pover-text-style" />
      </ListItem>
    </List>
  );

  return (
    <div>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <div className="dropDown_con">
          <TextField
            className="discountListingTextField"
            label={t('selfMadeDiscount.search')}
            color='warning'
          />

          <Button
            variant="contained"
            onClick={handleNavigate}
            className="discountListingButton"
            type="button"
          >
            {t('selfMadeDiscount.create_discount')}
          </Button>
        </div>

        <TableContainer style={{ marginTop: '10px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="discountListingTableHead">
                  {t('selfMadeDiscount.discount_name')}
                </TableCell>
                <TableCell className="discountListingTableHead">
                  {t('selfMadeDiscount.location')}
                </TableCell>
                <TableCell className="discountListingTableHead">
                  {t('selfMadeDiscount.cupon')}
                </TableCell>
                <TableCell className="discountListingTableHead">
                  {t('selfMadeDiscount.reason')}
                </TableCell>
                <TableCell className="discountListingTableHead">
                  {t('selfMadeDiscount.status')}
                </TableCell>
                <TableCell className="discountListingTableHead">
                  {t('selfMadeDiscount.amount')}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData.map((row, index) => (
                <TableRow key={row.id} className="discountListingRowline">
                  <TableCell className="discountListingRow">
                    {' '}
                    <VisibilityRoundedIcon />
                  </TableCell>
                  <TableCell className="discountListingRow">
                    {' '}
                    {row.discountName}
                  </TableCell>
                  <TableCell className="discountListingRow">
                    {row.location}
                  </TableCell>
                  <TableCell className="discountListingRow">
                    {row.couponCode}
                  </TableCell>
                  <TableCell className="discountListingRow">
                    {row.reason}
                  </TableCell>
                  <TableCell className="discountListingRow">
                    <Switch
                      color="warning"
                      onChange={() => handleSwitchChange(index)}
                    />
                  </TableCell>
                  <TableCell className="discountListingRow">
                    {row.amount}
                  </TableCell>
                  <TableCell>
                    <div>
                       <IconButton onClick={handleClick}>
                       <MoreVertIcon  />
                       </IconButton>
                     

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        style={{ width: '180px', height: '225px' }}
                      >
                        {popoverContent}
                      </Popover>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default DiscountSelfMade;
