import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import { Paper, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Link } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function LocationList() {
    const { t } = useTranslation();
    const toast = useRef(null);
    const [showOptions, setShowOptions] = useState(null)
    const open = Boolean(showOptions);

    const [filters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        brandName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        phone: { value: null, matchMode: FilterMatchMode.EQUALS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        location: { value: null, matchMode: FilterMatchMode.CONTAINS },
        locationType: { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    const breadcrumbs = [
        <Typography key="1">{t("business_information.label")}</Typography>,
        <Typography className="primaryColor" key="3">
            {t("business_information.locations")}
        </Typography>,
    ];

    const userData = [
        {
            id: 1,
            brandName: "E-max Take away",
            email: "emaxtakeaaway@gmail.com",
            locationType: "Physical",
            location: "location 1",
            address: "98 ron street",
            phone: "9456231075"
        },
        {
            id: 2,
            brandName: "Kritinga",
            email: "kritinga@gmail.com",
            locationType: "Physical",
            location: "location 2",
            address: "101 bostan palace",
            phone: "9456231075"
        },
        {
            id: 3,
            brandName: "Pista House",
            email: "pistahouse@gmail.com",
            locationType: "Mobile",
            location: "location 3",
            address: "red street 99",
            phone: "9456231075"
        },
        {
            id: 4,
            brandName: "Biriyani's & More",
            email: "biriyanis@gmail.com",
            locationType: "Mobile",
            location: "location 4",
            address: "east pavilon",
            phone: "9456231075"
        },
        {
            id: 5,
            brandName: "Tangedu",
            email: "tangedu@gmail.com",
            locationType: "Physical",
            location: "location 5",
            address: "Hitech region",
            phone: "9456231075"
        },
    ];



    const renderHeader = () => {
        return (
            <div className="customersTableHeader">
                <Link to='/createLocation'>
                    <Button
                        severity="warning"
                        icon="pi pi-map-marker"
                        label={t("business_information.create_location")}
                        rounded
                    />
                </Link>
            </div>
        );
    };
    const header = renderHeader();

    const handleOptionsOpen = (event, value) => {
        setShowOptions(event.currentTarget)
        console.log(value)
    }
    const handleOptionsClose = () => {
        setShowOptions(null)
    }
    const handleClick = (value) => {
        console.log(value.target.innerText)
        setShowOptions(null)
    }

    const RowActions = (rowData) => {
        return (
            <div>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={(e) => handleOptionsOpen(e, rowData)}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={showOptions}
                    open={open}
                    onClose={handleOptionsClose}
                    PaperProps={{
                        style: {
                            width: '20ch',
                            boxShadow: '0 5px 5px var(--gray-100)',
                        },
                    }}
                >
                    <MenuItem key={'Edit'} onClick={handleClick}>
                        Edit
                    </MenuItem>
                    <MenuItem key={'Close Store'} onClick={handleClick}>
                        Close Store
                    </MenuItem>
                    <MenuItem key={'Deactivate Store'} onClick={handleClick}>
                        Deactivate Store
                    </MenuItem>
                </Menu>
            </div>
        );
    };


    const customerGroupRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={['Physical', 'Mobile']} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Location Type" className="p-column-filter" showClear />
        );
    };


    return (
        <Box sx={{ width: "100%" }}>
            <Toast ref={toast} />
            <ConfirmDialog />
            <Paper className="paper" sx={{ mb: 2 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
                <Divider className="divider" />
                <div className="card">
                    <DataTable
                        value={userData}
                        paginator
                        rows={5}
                        header={header}
                        filters={filters}
                        filterDisplay="row"
                        emptyMessage="No locations found."
                        globalFilterFields={["brandName", "status", "phone", "email", "phone", "role"]}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                    >
                        <Column field="brandName" filter showFilterMenu={false} filterPlaceholder="Search by brand" sortable header="Brand Name" style={{ minWidth: '15rem' }}></Column>
                        <Column field="email" filter showFilterMenu={false} filterPlaceholder="Search by email" header="Email" style={{ minWidth: '15rem' }}></Column>
                        <Column field="locationType" filter showFilterMenu={false} filterElement={customerGroupRowFilterTemplate} header="Location Type"></Column>
                        <Column field="location" header="Location"></Column>
                        <Column field="address" header="Address"></Column>
                        <Column body={RowActions}></Column>
                    </DataTable>
                </div>
            </Paper>
        </Box>
    );
}
