import React, { useState } from 'react';

import { Box, Button, IconButton, Paper, TextField,FormControlLabel, Typography,FormControl,Select,MenuItem,InputLabel, SelectChangeEvent, } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Checkbox from '@mui/material/Checkbox';
import imageValue1 from '../../assets/image-avatar.png';
import './createBundle.css';
import BundleDragDrop from './bundleDragDrop';







interface State {
  [key: string]: string | boolean;
  bundleName: string;
  
  description: string;
  showPOS: boolean;
  showOnline: boolean;
  selectedLocation: string;

  
 

}



const CreateBundle=()=>{

  const categoryOptions = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ];
  
  const initialState: State = {
    
    bundleName: '',
    
    description: '',
    showPOS: false,
    showOnline: false,
    selectedLocation: '',
    

  };
  

    const [state, setState] = useState<State>(initialState);

    const handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { name, value, type, checked } = event.target;
    
      setState((prevState) => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }));
    };
    
    const handleSelectChange = (
      event: SelectChangeEvent
    ) => {
      const { name, value } = event.target;
    
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

  const [imageValue, setImageValue] = useState<string | null>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageValue(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  
 
  


    return(
        <Box sx={{padding:"1%"}}>
          
            <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",padding:"1%"}}>
                <IconButton className="create-bundle-exit">
            <ArrowBackIcon className="create-bundle-exitIcon"/>
            </IconButton>
            <Typography className="create-bundle-head">Create a Bundle</Typography>
            <Button className="create-bundle-button" style={{textTransform:"none"}}>Save</Button>
            
            </div>
            <Box sx={{display:'flex',flexDirection:"row",flexWrap:"wrap",height:'80vh',width:"100vw"}}>
            <Paper elevation={2} style={{width:'65%', padding:'1%',borderLeft:"2px solid var(--stroke-color-1-violet, #AE9BE2)",maxHeight:'300px'}}>
                <Typography className="create-bundle-details">Details</Typography>
                <div style={{padding:"2% 2% 0% 2%",display:'flex'}}>
                    <div style={{width:"90%"}}>
                <div className="create-bundle-text-container">
            <TextField id="outlined-basic" label="Bundle Name" variant="outlined" 
           name="bundleName"
           value={state.bundleName}
           onChange={handleInputChange}
            color="warning" style={{width:'45%'}}/>
            <FormControl style={{width:"50%"}}>
  <InputLabel id="demo-simple-select-label">Select Location</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    name="selectedLocation"
    value={state.selectedLocation}
    onChange={handleSelectChange}
    label="Select Location"
   color="warning"
   
  >
     {categoryOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
  </Select>
</FormControl>
            </div>
            <div style={{marginTop:"3%"}}>
            <TextField multiline label="Description"
             placeholder="enter a shoert description for the category" 
             value={state.description}
             name='description'
             onChange={handleInputChange}
             style={{width:"90%"}} color="warning"/>
            </div>
            </div>
            
            <Button
      style={{ width: '25%', marginLeft: '10%' }}
     
    
    >
      <div   className="create-bundle-img-con">
        <img
          src={imageValue || imageValue1} // You can set a placeholder image here
          alt="add Img"
          style={{ width: '90%' ,backgroundSize:"cover"}}
          onClick={() => {
            const input = document.getElementById('image-upload-input');
            if (input) {
              input.click();
            }
          }}
        />
        {
          imageValue===null ? (<Typography className="create-bundle-img-text">Add img</Typography>):("")
        }
        
      </div>
      <input
        type="file"
        hidden
        id="image-upload-input"
        onChange={handleImageUpload}
      />
    </Button>
            </div>
           
            <div style={{marginLeft:'3%',marginTop:"1%",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                <div style={{width:"50%"}}>
                <FormControlLabel
  control={<Checkbox color="warning"  name='showPOS' checked={state.showPOS} onChange={handleInputChange} />}
  label={<Typography className='create-product-switch-text'>Show POS</Typography>}
  style={{width:"40%"}}
/>
<FormControlLabel
  control={<Checkbox color="warning" name='showOnline' checked={state.showOnline} onChange={handleInputChange} />}
  label={<Typography className='create-product-switch-text'>Show Online</Typography>}
  style={{width:"40%"}}
/>

                
            </div>
            <div style={{display:"flex",alignItems:"center"}}>
                <Button style={{textTransform:"none",textDecoration:"underLine"}} className="create-bundle-cancel_Button">Cancel</Button>
                <Button style={{textTransform:"none"}} className="create-bundle-button">Save</Button>
            </div>
            </div>
            </Paper>
            <BundleDragDrop/>
            </Box>
        </Box>
    )
}
export default CreateBundle