import {FormGroup,Checkbox,FormControlLabel, Paper,InputBase,Accordion,AccordionDetails,AccordionSummary,TextField,Box, Button, Divider, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from "@mui/icons-material/Search";
import {t} from "i18next"
import "./driverPettyCash.css"

import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 562,
  bgcolor: 'background.paper',
  borderRadius:"8px",
  boxShadow: 24,
  p: 3,
};

const initialRows: GridRowsProp = [
    {
      id: 1,
      driverName: "Ashok",
      commission: "£ 75",
      type:"Regular",
      date:"22-06-23; 10:30 AM"
    },
    {
        id: 2,
        driverName: "Naveen",
        commission: "£ 45",
        type:"Regular",
        date:"22-06-23; 10:30 AM"
      },
      {
        id: 3,
        driverName: "Pranay",
        commission: "£ 55",
        type:"Contract",
        date:"22-06-23; 10:30 AM"
      },
   
  ];
  
 

  

const initialDriversList=[
  {id:1,driverName:"pranay",isChecked:false},
  {id:2,driverName:"Vinay",isChecked:false},
  {id:3,driverName:"Hari",isChecked:false},
  {id:4,driverName:"Naveen",isChecked:false},
  {id:5,driverName:"Ashok",isChecked:false},
]

function DriverPettyCash() {

    const [rows, setRows] = React.useState(initialRows);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [open, setOpen] = React.useState(false);
    const [driversList,setDriversList]=React.useState(initialDriversList)
    const [popupSearch,setPopupSearch]=useState("")
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handlePopupCheckbox: (id: number, isChecked: boolean) => void = (event) => {
    const filteredList = driversList.map((each) => {
      if (each.id === event) {
        return { ...each, isChecked: !each.isChecked }
      } return each
    })
    setDriversList(filteredList)
  };

  
  

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleSearchIcon=(event:React.ChangeEvent<HTMLInputElement>)=>{
    setPopupSearch(event.target.value)
  }
  const filteredDriversList=driversList.filter((each)=>(each.driverName.toLocaleLowerCase().includes(popupSearch)))

  const getRowClassName=()=>{
    return "driver-commission-table-row"
  }

  const columns: GridColDef[] = [
    {
         field: 'driverName', 
         //@ts-ignore
         headerName: t("driver_petty_cash.driver_name"),
         headerClassName:"driver-commission-table-header-name",
         flex:1, 
         editable: true },
    {
      field: 'commission',
      //@ts-ignore
      headerName: t("driver_petty_cash.commission"),
      headerClassName:"driver-commission-table-header-name",
      flex:1,
      editable: true,
    },
    {
      field: 'type',
      //@ts-ignore
      headerName: t("driver_petty_cash.type"),
      headerClassName:"driver-commission-table-header-name",
      flex:1,
      editable: true,
    },
    {
      field: 'date',
      //@ts-ignore
      headerName: t("driver_petty_cash.date"),
      headerClassName:"driver-commission-table-header-name",
      flex:1,
      type:"datetime",
      editable: true
    },
    {
      field: 'actions',
      disableColumnMenu:true,
      type: 'actions',
      width: 100,
      
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon sx={{color:"warning"}} />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon sx={{color:"#ABABAB"}} />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon sx={{color:"#ABABAB"}} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
         
        ];
      },
    },
  ];


  return (
    <Box>
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"10px"}}>
            <Typography className='driver-commission-header-text'>{t("driver_petty_cash.driver_petty_cash")}</Typography>
            <Button onClick={handleOpen} className='driver-commission-header-button'  variant='contained' color='warning'>{t("driver_petty_cash.add_petty_cash")}</Button>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
            <Box sx={{display:"flex",alignItems:"center"}}>
            <IconButton sx={{ marginRight: "50px" }} onClick={handleClose} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                <CloseIcon />
            </IconButton>
            
            <Typography sx={{ marginLeft: "50px" }}  className='driver-commission-popup-header'>{t("driver_petty_cash.petty_cash")}</Typography>
            
            </Box>
            <Divider sx={{marginBottom:"15px",marginTop:"15px"}}/>
            <Box sx={{display:"flex",justifyContent:"flex-start",flexDirection:"column",alignItems:"center"}}>
            <Accordion sx={{width:"60%"}} elevation={1}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{t("driver_petty_cash.select_driver")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Paper
                elevation={1}
                  component="form"
                  sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "100%" }}
                >
                  <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    value={popupSearch}
                    onChange={handleSearchIcon}
                    sx={{ ml: 1, flex: 1 }}
                    //@ts-ignore
                    placeholder={t("driver_petty_cash.search_by_driver_name")}
                    inputProps={{ "aria-label": "search google maps" }}
                  />
                  
                </Paper>
                <Box sx={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                <FormGroup>

                 {filteredDriversList.map((each)=>(

                   <FormControlLabel 
                    control={<Checkbox
                      onChange={() => {
                        handlePopupCheckbox(each.id, each.isChecked);
                      }}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      color='warning'/>} 
                      label={<Typography className='driver-petty-cash-popup-checkbox'>{each.driverName}</Typography>} />    
                 )
                 )}       

                </FormGroup>
                </Box>

            </AccordionDetails>
          </Accordion>

            <TextField sx={{marginTop:"20px",marginBottom:"15px",width:"60%"}} defaultValue="£ 75" label={t("driver_petty_cash.amount")} variant="outlined" />
            
            </Box>
            <Box sx={{textAlign:"right",marginTop:"20px"}}>
                <Button onClick={handleClose} className='driver-commission-popup-button' variant='contained' color='warning'>{t("driver_petty_cash.save")}</Button>
            </Box>
            </Box>
        </Modal>

        </Box>
        <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        getRowClassName={getRowClassName}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
    </Box>
  )
}

export default DriverPettyCash
