import React from "react";
import { Button, Divider, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useForm, Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useTranslation } from "react-i18next";

const AddAddress = () => {
    const { t } = useTranslation();
    const breadcrumbs = [
        <Typography key="1">{t("sales.sales_label")}</Typography>,
        <Typography key="3">
            {t("sales.customers")}
        </Typography>,
        <Typography key="3">
            {t("sales.customer_profile")}
        </Typography>,
        <Typography className="primaryColor" key="3">
            {t("sales.add_address")}
        </Typography>,
    ];
    const {
        handleSubmit,
        control,
    } = useForm({
        defaultValues: {
            address1: "",
            address2: "",
            city: "",
            postcode: "",
            country: "",
        },
    });

    const onSubmit = (data) => console.log(data);
    return (
        <>
            <Paper className="paper">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
                <Divider className="divider" />

                <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-address-1">
                                {t("create_customer.input_labels.address1")}
                            </InputLabel>
                            <Controller
                                name="address1"
                                rules={{ required: true, minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-address-2">
                                {t("create_customer.input_labels.address2")}
                            </InputLabel>
                            <Controller
                                name="address2"
                                rules={{ minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-city">
                                {t("create_customer.input_labels.city")}
                            </InputLabel>
                            <Controller
                                name="city"
                                rules={{ minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-post-code">
                                {t("create_customer.input_labels.post_code")}
                            </InputLabel>
                            <Controller
                                name="postcode"
                                rules={{ minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-country">
                                {t("create_customer.input_labels.country")}
                            </InputLabel>
                            <Controller
                                name="country"
                                rules={{ minLength: 1 }}
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormControl>

                        <br />
                        <br />
                        <Button
                            focusRipple
                            className="primaryButton"
                            variant="contained"
                            type="submit"
                        >
                            {t("buttons.submit")}
                        </Button>
                    </form>
                </Box>
            </Paper>
        </>
    );
};

export default AddAddress;
