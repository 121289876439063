import { Box, IconButton, Typography, Button, Divider } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useNavigate } from 'react-router-dom';
import ItemCrossSellingLeftPart from './itemCrossSellingLeftPart';
import ItemCrossSellingRightPart from './itemCrossSellingRightPart';

import "./cartCrossSellingCreate.css"

function ItemCrossSellingCreate() {

  const navigate = useNavigate()

  return (
    <Box sx={{ backgroundColor: "#f9f9f9", padding: "15px" }}>

      <Box sx={{ backgroundColor: "#fff" }} className='create-a-use-role-header-order' style={{ marginBottom: "10px" }}>

        <IconButton onClick={()=>navigate("/")} className='create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover'>
          <ArrowBackIcon />
        </IconButton>
        <Typography className='create-a-use-role-heading'>Cart Cross Selling</Typography>
        <Button className='cart-cross-selling-craete-save-button' variant='contained'>Save</Button>

      </Box>

      <Box sx={{display:"flex",width:"100%",alignItems:"flex-start"}}>
        <ItemCrossSellingLeftPart />
        <Divider orientation='vertical' sx={{height:"100%",margin:"15px",width:"5px"}}/>
        <ItemCrossSellingRightPart/>
      </Box>



    </Box>
  )
}

export default ItemCrossSellingCreate
