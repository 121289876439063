import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Receipts = () => {

  const [leftItems, setLeftItems] = useState([
    // { id: 'left-1', content: 'Left Item 1' },
    // { id: 'left-2', content: 'Left Item 2' },
    // { id: 'left-3', content: 'Left Item 3' },
  ]);

  

  
  const pizza=[
  { id: 'italianPizza', content: 'Italian Pizza',category:"pizza",course:"course" , variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ]},
  { id: 'maragaritaPizza', content: 'Maragarita Pizza',category:"pizza" ,course:"main2", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ]},
  { id: 'chikenBrustPizza', content: 'Chiken Brust Pizza',category:"pizza" ,course:"appetisers", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ]},
  {id:'vegPizza',content:"Veg Pizza",category:"pizza" ,course:"main", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ]},
 
]


const  biryani= [
  { id: 'italianBiryani', content: 'Italian Biryani',category:"biryani" ,course:"appetisers", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ] },
  { id: 'maragaritaBiryani', content: 'Maragarita Biryani',category:"biryani" ,course:"main", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ] },
  { id: 'chickenBreastBiryani', content: 'Chicken Breast Biryani',category:"biryani" ,course:"main2", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ] },
  { id: 'vegBiryani', content: 'Veg Biryani',category:"biryani" ,course:"appetisers", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ] },
]


const menuItems = [...leftItems];

// Use Set to store unique course names
const uniqueCoursesSet = new Set();

// Iterate through the menu items and add course names to the Set
menuItems.forEach(item => {
  uniqueCoursesSet.add(item.course);
});

// Convert the Set to an array
const uniqueCourses = Array.from(uniqueCoursesSet);




const onDragEnd = (result) => {
  console.log(result);

  if (!result.destination) {
    return;
  }

  const sourceListId = result.source.droppableId;
  const destinationListId = result.destination.droppableId;
  let sourceList;

  switch (sourceListId) {
    case 'pizza':
      sourceList = pizza;
      break;
    case 'biryani':
      sourceList = biryani;
      break;
    // Add more cases for other lists as needed
    default:
      sourceList = [];
      break;
  }

  const destinationList = leftItems;
  const draggedItem = sourceList[result.source.index];
  const copiedItem = { ...draggedItem, id: `${destinationListId}-${Date.now()}` };

  const newDestinationList = Array.from(destinationList);

  if (destinationListId === 'left' && sourceListId !== 'left') {
    newDestinationList.splice(result.destination.index, 0, copiedItem);
  } else if (destinationListId === 'left' && sourceListId === 'left') {
    console.log('Ashok');
    const [removedItem] = newDestinationList.splice(result.source.index, 1);
    newDestinationList.splice(result.destination.index, 0, removedItem);
  }

  setLeftItems(newDestinationList);
};




return (
  <div>
      <div style={{ display: 'flex' }}>
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="left" type="list">
  {(provided) => (
    <ul
      {...provided.droppableProps}
      ref={provided.innerRef}
      style={{ listStyle: 'none', padding: '0', width: '50%' }}
    >
      {uniqueCourses.map((item, index) => {
        // Filter the items for the current course
        const filteredItems = leftItems.filter((each) => each.course === item);
        const uniquePizza = {};

        const deduplicatedPizza = filteredItems.filter(item => {
          if (!uniquePizza[item.content]) {
            uniquePizza[item.content] = true;
            return true;
          }
          return false;
        });
        
        filteredItems.length = 0;
        Array.prototype.push.apply(filteredItems, deduplicatedPizza);
        
        console.log(filteredItems);
        return (
          <Accordion key={item}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${item}-content`}
              id={`panel-${item}-header`}
            >
              <Typography>{item}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {filteredItems.map((filteredItem) => (
                <Draggable
                  draggableId={filteredItem.id} // Assuming filteredItem has an 'id'
                  index={index}
                  key={filteredItem.id} // Key should be unique
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        margin: '8px',
                        padding: '8px',
                        backgroundColor: 'lightblue',
                        borderRadius: '4px',
                        ...provided.draggableProps.style,
                      }}
                    >
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>{filteredItem.category}</Typography>
                        <Typography>{filteredItem.content}</Typography>
                        {filteredItem.variations && (
                          <ul>
                            {filteredItem.variations.map((variation) => (
                              <li key={variation.id}>
                                <Typography>{variation.variation}</Typography>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
            </AccordionDetails>
          </Accordion>
        );
      })}
      {provided.placeholder}
    </ul>
  )}
</Droppable>
<div style={{display:'flex',flexDirection:"column",width:"40%"}} >

          <Droppable droppableId="pizza" type="list">
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ listStyle: 'none', padding: '0', width: '50%' }}
              >
                <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Pizza</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {pizza.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                margin: '8px',
                                padding: '8px',
                                backgroundColor: 'lightgreen',
                                borderRadius: '4px',
                                ...provided.draggableProps.style,
                              }}
                            >
                              {item.content}
                            </li>
                          )}
                        </Draggable>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          <Droppable droppableId="biryani" type="list">
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ listStyle: 'none', padding: '0', width: '50%' }}
              >
                <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Biryani</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {biryani.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                margin: '8px',
                                padding: '8px',
                                backgroundColor: 'lightgreen',
                                borderRadius: '4px',
                                ...provided.draggableProps.style,
                              }}
                            >
                              {item.content}
                            </li>
                          )}
                        </Draggable>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default Receipts;
