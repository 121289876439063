import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { ApolloLink, execute,  } from '@apollo/client/link/core';
const categoryClient = new ApolloClient({
  link: new HttpLink({
    uri: 'http://51.75.170.243:8080/v1/category', // Replace with your GraphQL API endpoint
  }),
  cache: new InMemoryCache(),
});
const userClient = new ApolloClient({
  link: new HttpLink({
    uri: 'http://51.75.170.243:8080/v1/users', // Rep
  }),
  cache: new InMemoryCache(),
});


const businessClient = new ApolloClient({
  link: new HttpLink({
    uri: 'http://51.75.170.243:8080/v1/business', // Rep
  }),
  cache: new InMemoryCache(),
});
const customLink = new ApolloLink((operation, forward) => {
  const clientName = operation.getContext().clientName;
  if (clientName === 'categoryClient') {
    return execute(categoryClient.link, operation);
  } else if (clientName === 'userClient') {
    return execute(userClient.link, operation);
  }else if (clientName === 'businessClient') {
    return execute(businessClient.link, operation);
  }

  return forward(operation);
});
const Client = new ApolloClient({
  link: customLink,
  cache: new InMemoryCache(),
});
export default Client;







