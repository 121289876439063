import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Button, TextField,InputAdornment } from '@mui/material';
import './bundleListing.css'
import { Popover, List, ListItem, ListItemText ,Divider,IconButton} from '@mui/material';
import { MoreVert, SearchRounded } from '@mui/icons-material';

import { DataGrid, GridColDef} from '@mui/x-data-grid';

import {  Switch } from '@mui/material';
import {t} from 'i18next'

const rows = [
    { id: 1, bundleName: 'Combo 3', status: false, products: "2 products",location:"All Location",qty:4,price:20 },
    { id: 2, bundleName: 'Set Meals', status: false, products: "5 products",location:"All Location",qty:6,price:20 },
    { id: 3, bundleName: 'Combo 3 ', status: false, products: "3 products " ,location:"All Location",qty:5 ,price:20 },
    { id: 4, bundleName: 'Combo 3', status: false, products: "7 products " ,location:"All Location",qty:12,price:20 },
    { id: 5, bundleName: 'Set Meals', status: false, products: "2 products ",location:"All Location",qty:1 ,price:20  },
    
  ];
  
  function CustomPopoverCell({ value }: { value: number }) {
      const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    
      const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };
      
    
      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;
    
      const popoverContent = (
        <List style={{ padding: '0px 0px 0px 0px' ,width:"100%"}}>
          <Button style={{ padding: '0px 0px 0px 0px' }}>
            <ListItem className='bundle-Listing-pover-text-style'>
              <ListItemText primary="Edit" />
            </ListItem>
          </Button>
          <Divider />
          {/* <Button style={{ padding: '0px 0px 0px 0px' }}>
            <ListItem style={{ width: '180px', height: '40px' }} className='menu-Listing-pover-text-style'>
              <ListItemText primary="Delete" />
            </ListItem>
          </Button>
          <Divider /> */}
         
          <Button style={{ padding: '0px 0px 0px 0px' ,width:"100%"}}>
            <ListItem  className='bundle-Listing-pover-text-style'>
              <ListItemText primary="Duplicate" />
            </ListItem>
          </Button>
        </List>
      );
     
    
      return (
        <Box>
          <div>
            <IconButton onClick={handleClick}>
              <MoreVert />
            </IconButton>
    
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              style={{ width: '180px', height: '225px', paddingTop: '0px', paddingBottom: '0px' }}
              elevation={8}
            >
              {popoverContent}
            </Popover>
          </div>
        </Box>
      );
    }
export default function BundleListing() {
  const [filter, setFilter] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value as string);
  };
  const [updatedRows, setUpdatedRows] = useState(rows);

  const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    const updatedRow = updatedRows.map((row) =>
      row.id === id ? { ...row, status: event.target.checked } : row
    );
    setUpdatedRows(updatedRow);


  };

  

  
  const handleRowClass=()=>{
    return(
        "bundleListingRow"
    )
  }

  const handleCellClass=()=>{
    return(
        "bundleListigCell"
    )
  }
    const columns: GridColDef[] = [
 
        {
          field: 'bundleName',
          //@ts-ignore
          headerName: t("menu.bundle.bundle-name"),
          headerClassName:"menuListingHeader",
        flex:1,
         
        },
       
        {
          field: 'products',
          //@ts-ignore
          headerName: t("menu.bundle.products"),
          headerClassName:"menuListingHeader",
          flex:1,
         
        },
        {
          field: 'location',
         //@ts-ignore
         headerName: t("menu.bundle.location"),
          headerClassName:"menuListingHeader",
          flex:1,
        
        },
        {
          field: 'qty',
          //@ts-ignore
          headerName: t("menu.bundle.qty"),
          headerClassName:"menuListingHeader",
          flex:1,
        
        },
        {
            field: 'status',
            //@ts-ignore
          headerName: t("menu.bundle.status"),
            headerClassName:"menuListingHeader",
            flex:1,
            renderCell:(params)=>{
                return(
                  <Switch
                  checked={params.row.status}
                  onChange={(event) => handleStatus(event, params.row.id)}
                  color='warning'
                />
                )
            }
          },
        {
            field: 'price',
           //@ts-ignore
           headerName: t("menu.bundle.price"),
            headerClassName:"menuListingHeader",
           
          
          },
        {
          field: '',
          headerName: '',
          headerClassName:"menuListingHeader",
         disableColumnMenu:true,
         flex:1,
         filterable:false,

         
    renderCell: (params) => {
      return (
        <CustomPopoverCell
          value={params.row.category}
       
        />
      );
    
    },
        
        },
      ];

      const navigate=useNavigate()
      const handleNavigation=()=>{
        navigate("/create-bundle")
      }

  return (
    <Box sx={{padding:'1%'}}>
        <Box sx={{display:"flex",alignItems:'center',justifyContent:"space-between"}}>
            <Box sx={{width:"50%",display:"flex",alignItems:'center',justifyContent:"space-between"}}>
      <FormControl style={{width:'20%'}}>
        <InputLabel id="demo-simple-select-label">{t("menu.bundle.filter")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter}
          label={t("menu.bundle.filter")}
          onChange={handleChange}
          color='warning'
          
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
      {/* <TextField variant='outlined' label="Search by name. description or sku" color='warning' sx={{width:"70%"}}/> */}
      <TextField
        id="input-with-icon-textfield"
        label="Search by name. description or sku"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRounded/>
            </InputAdornment>
          )
        }}
        variant="outlined"
        color='warning'
        sx={{width:"70%"}}
      />
      </Box>
      <Button className='bundle-listing-button' style={{textTransform:'none'}} onClick={handleNavigation}>{t("menu.bundle.create_bundle")} </Button>
      </Box>
      <Box sx={{ height: 400, width: '100%',marginTop:'1%' }}>
      <DataGrid
        rows={updatedRows}
        getRowClassName={handleRowClass}
        getCellClassName={handleCellClass}
        
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
    </Box>
  );
}