import { Box, Typography,Button,Popover, IconButton,List,ListItem,ListItemText,Divider } from '@mui/material'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel'
import React from 'react'
import {GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import KdsSetup from './kdsSetup';
import PosSetup from './posSetup';
import KioskSetup from './kioskSetup';
import { t } from 'i18next'
import "./deviceSetUp.css"



const locationsList=["location1","location2","location3"]



  const rows=[
    {
        id:1,
        branchName:"Madhapur",
        
        pairedDevices:"3 KDS devices Paired"
    },
    {
        id:2,
        branchName:"Madhapur",
        pairedDevices:"3 KDS devices Paired"
    },
    {
        id:3,
        branchName:"Madhapur",
        pairedDevices:"3 KDS devices Paired"
    },
    {
        id:4,
        branchName:"Madhapur",
        pairedDevices:"3 KDS devices Paired"
    },
  ]

  const firstPopupStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 940,
    height:450,
    bgcolor: 'background.paper',
    borderRadius:"5px",
    boxShadow: 24,
    p: 4,
  };

  const secondPopupStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 940,
    height:600,
    bgcolor: 'background.paper',
    borderRadius:"5px",
    boxShadow: 24,
    p: 4,
  };
  

function DeviceSetUp() {
    const [value, setValue] = React.useState('1');
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const PopoverOpen = Boolean(anchorEl);
   // first popup
    const [firstPopUpOpen, setFirstPopUpOpen] = React.useState(false);
    const handleFirstPopUpOpen = () => setFirstPopUpOpen(true);
    const handleFirstPopUpClose = () => setFirstPopUpOpen(false);
    //second popup
    const [secondPopUpOpen, setSecondPopUpOpen] = React.useState(false);
    const handleSecondPopUpOpen = () => setSecondPopUpOpen(true);
    const handleSecondPopUpClose = () => setSecondPopUpOpen(false);
    //code functions
    const [isTrue, setIsTrue] = React.useState(false);
    
    
    
    const handleCopyIcon=()=>{
      setIsTrue(!isTrue)
      if(isTrue){
        const inputCode="1234 1234 1234"
        navigator.clipboard.writeText(inputCode)
      }
    }

    const id = PopoverOpen ? 'simple-popover' : undefined;

    const firstPopupBreadcrumbs = [
        <Typography className='device-set-up-first-pop-up-header-bredcrump1' key="1">{t("device_setup.device_details")}</Typography>,
        <Typography className='device-set-up-first-pop-up-header-bredcrump2' key="2">{t("device_setup.generate_device_code")}</Typography>   
    ];

    const secondPopupBreadcrumbs = [
      <Typography className='device-set-up-first-pop-up-header-bredcrump2' key="1">{t("device_setup.device_details")}</Typography>,
      <Typography className='device-set-up-first-pop-up-header-bredcrump1' key="2">{t("device_setup.generate_device_code")}</Typography>   
  ];
    const columns: GridColDef[] = [
        {
          field: 'branchName',
          headerClassName:"device-set-up-table-header",
          headerName: 'Branch Name',
          flex:1
        },
        {
          field: 'pairedDevices',
          headerClassName:"device-set-up-table-header",
          headerName: 'Paired Devices',
          width:300
        },{
         field:"",
         disableColumnMenu:true,
          width:150,
          renderCell:(params)=>(
            <Box>
                <IconButton onClick={handleClick} >
                    <EditIcon/>
                </IconButton>
                <Popover
              id={id}
              open={PopoverOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              style={{
                width: "180px",
                height: "225px",
                paddingTop: "0px",
                paddingBottom: "0px"
              }}
              elevation={8}
            >
              {popoverContent}
            </Popover>
            </Box>
          )
        },
       
       
      ];




    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      };

      const getRowSize=()=>{
        return "device-set-up-table-row device-set-up-table-row-hover"
      }
      
      const combineFirstPopAndSecoundPopup=()=>{
        setFirstPopUpOpen(false)
        setSecondPopUpOpen(true)
      }

      const handleSecondAndFirstPopup=()=>{
        setSecondPopUpOpen(false)
        setFirstPopUpOpen(true)
      }

      const popoverContent = (
        <List style={{ padding: '0px 0px 0px 0px' }}>
          <Button style={{ textTransform:"capitalize",padding: '0px 0px 0px 0px' }}>
            <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
              <ListItemText className='device-set-up-table-row-hover' primary={t("device_setup.edit")} />
            </ListItem>
          </Button>
          <Divider />
          <Button style={{textTransform:"capitalize", padding: '0px 0px 0px 0px' }}>
            <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
              <ListItemText className='device-set-up-table-row-hover' primary={t("device_setup.delete")} />
            </ListItem>
          </Button>
        
          
        </List>
      );
  return (
    
    <Box sx={{padding:"20px"}} className="overall-background">
        <Box>
            <Typography className='device-set-up-header'>{t("device_setup.device_setup")}</Typography>
        </Box>
        <Box sx={{ width: '100%', typography: 'body1',marginTop:"15px" }}>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList sx={{backgroundColor:"#F9F9F9"}} className='background' onChange={handleChange}>
                <Tab label={<Typography className={value==="1"?"device-set-up-tabs-header-orange":"device-set-up-tabs-header"}>{t("device_setup.kds_setup")}</Typography>}  className={value==="1"?"change-color":"add-color"}  value="1" />
                <Tab label={<Typography className={value==="2"?"device-set-up-tabs-header-orange":"device-set-up-tabs-header"}>{t("device_setup.pos_Setup")}</Typography>}   className={value==="2"?"change-color":"add-color"} value="2" />
                <Tab label={<Typography className={value==="3"?"device-set-up-tabs-header-orange":"device-set-up-tabs-header"}>{t("device_setup.kiosk_setup")}</Typography>} className={value==="3"?"change-color":"add-color"} value="3" />
            </TabList>
            </Box>
            <TabPanel value="1">
             <KdsSetup/> 
            </TabPanel>
            <TabPanel value="2">
              <PosSetup/>
            </TabPanel>
            <TabPanel value="3">
              <KioskSetup/>
            </TabPanel>
        </TabContext>
        </Box>
    </Box>
  )
}

export default DeviceSetUp
