import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Typography, Button, Checkbox, FormControlLabel, Paper, InputBase, IconButton } from "@mui/material";
import '../options/createProduct.css'
import './createCategory.css'
import Modal from '@mui/material/Modal';
import { CloseOutlined } from '@mui/icons-material';
import SearchIcon from "@mui/icons-material/Search";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { t } from 'i18next';
import image from '../../assets/createProduct.png';
import {  useMutation, useQuery } from '@apollo/client';
import { OPTIONS_LIST } from '../../graphicalQl/usequery/categoryListQuery';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Chip from '@mui/material/Chip';
import { ASSIGN_OPTIONGROUP } from '../../graphicalQl/mutation/mutation';
import Stack from '@mui/material/Stack';
import { DELETE_OPTIONGROUP } from '../../graphicalQl/mutation/mutation';
import { DELETE_OPTIONVALUE } from '../../graphicalQl/mutation/mutation';
import { DELETE_OPTION } from '../../graphicalQl/mutation/mutation';
import { Divider } from 'primereact/divider';
import { GET_OPTIONS_IN_CATEGORY } from '../../graphicalQl/usequery/categoryListQuery';





interface Props{
  categoryId:string;
 
}


type OptionType = {
  name: string;
  optionValueId: string;
  price: number;
  image: string | null;
};

type OptionGroupType = {
  name: string;
  optionId: string;
  optionValues: OptionType[];


};

type YourDataType = {
  node: {
    name: string;
    optionGroupId: string;
    optionObjs: OptionGroupType[];

  }
};

type DataResult={
  id: string;
    name: string;
    optionObjs: {
        name: string;
        optionId: string;
        optionValues: OptionType[];
        isChecked: boolean;
    }[];
    isChecked: boolean;

}

type OptionType1 = {
  option_value_id: string;
  option_value_name: string;
 
};

type DataResult2={
  option_group_id: string;
  option_group_name: string;
  options: {
    option_name: string;
        option_id: string;
        option_values: OptionType1[];
        
    }[];
   

}

type OptionsList = {

  id: string;
  name: string;
  optionObjs: {
    name: string;
    optionId: string;
    isChecked: boolean;
  }[];
  isChecked: boolean;
}


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',

  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};



const OptionsModal = (props:Props) => {


  const {categoryId}=props
  
  const [openOptions, setOpenOptions] = React.useState(false);
  const handleOpenOptions = () => setOpenOptions(true);
  const handleCloseOptions = () => setOpenOptions(false);
  
  const { data: optionsData } = useQuery(OPTIONS_LIST,{ context: { clientName: 'categoryClient' }})

  function Row(props: { row: OptionsList }) {
    const { row } = props;
  
    const [open, setOpen] = React.useState(false);

    
    
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
          <FormControlLabel
  control={
    <Checkbox
      color='warning'
      checked={row.isChecked}
      onChange={() => handleCheckboxChange(parseInt(row.id))}
    />
  }
  label={
    <Typography variant="body2" className='createCategory-row'>
      {row.name}
    </Typography>
  }
/>


          </TableCell>
          {/* <TableCell component="th" scope="row">
          {row.name}
        </TableCell> */}

          <TableCell >{row.id}</TableCell>

        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                {t("menu.catageory.options")}
                </Typography>
                <Table size="medium" aria-label="purchases">
                  <TableHead>
                    <TableRow>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.optionObjs.map((historyRow) => (

                      <TableRow key={historyRow.name}>
                        <TableCell component="th" scope="row">
                         

                            <Typography className='createCategory-row'> {historyRow.name}</Typography>

                        </TableCell>
                        {/* <TableCell component="th" scope="row">
                        {historyRow.name}
                      </TableCell>
                       */}

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  const [optionsDataList, setOptionsData] = useState<YourDataType[]>([])
  useEffect(() => {
    if (optionsData) {
      setOptionsData(optionsData.optionGroupList.edges)
    }
  }, [optionsData])

  const [checkedRequiredOptions, setCheckedRequiredOptions] = useState<DataResult[]>([]);

useEffect(() => {
  const requiredOptions = optionsDataList.map((item) => ({
    id: item.node.optionGroupId,
    name: item.node.name,
    optionObjs: item.node.optionObjs.map((each) => ({
      name: each.name,
      optionId: each.optionId,
      optionValues: each.optionValues,
      isChecked: false
    })),
    isChecked: false
  }));

  setCheckedRequiredOptions(requiredOptions);
}, [optionsDataList]);

  


 

  const handleCheckboxChange = (id:number) => {
    setCheckedRequiredOptions((prevProductData) =>
      prevProductData.map((item) =>
        parseInt(item.id) === id ? { ...item, isChecked: !item.isChecked } : item
      )
    );
  };

  
  const CheckedoptionsData=checkedRequiredOptions.filter((each)=>(each.isChecked===true))
  
  const [data, setData] = useState<DataResult[]>([]);
  
  const assingnOptionData=()=>{
    setData(CheckedoptionsData)
  }
  
  

  // const selectedOptionList = optionsDataList.map((item) => ({
  //   id: item.node.optionGroupId,
  //   name: item.node.name,
  //   optionObjs: item.node.optionObjs.map((each) => ({
  //     name2: each.name,
  //     optionId: each.optionId,
  //     optionValues:each.optionValues


  //   })),
  //   isChecked: false
  // }));

  // const handleDelete = (optionId:string, optionValueId:string) => {
  //   // Find the option object with the matching optionId
  //   const optionObj = data.find((item) => item.optionObjs.find((each) => each.optionId === optionId));

  //   if (optionObj) {
  //     // Find the specific option value to delete
  //     optionObj.optionObjs.forEach((each) => {
  //       if (each.optionId === optionId) {
  //         each.optionValues = each.optionValues.filter((each2) => each2.optionValueId !== optionValueId);
  //       }
  //     });

  //     // Update the state with the modified data
  //     setData([...data]);
  //   }
  // };

  const [assignOptionGroup]=useMutation(ASSIGN_OPTIONGROUP,{ context: { clientName: 'categoryClient' },refetchQueries:[GET_OPTIONS_IN_CATEGORY]})

  const checkedOptionsIds=CheckedoptionsData.map((each)=>each.id)

  const assignselectedOptions=()=>{
    assignOptionGroup({variables:{optionGroupIds:JSON.stringify(checkedOptionsIds),categoryId:categoryId}})
  }

  const [deleteOptionGroup]=useMutation(DELETE_OPTIONGROUP,{ context: { clientName: 'categoryClient' }})


  const handleDelteOptionGroup=(optionGroupId:string)=>{
    deleteOptionGroup({variables:{optionGroupId:+optionGroupId,categoryId:categoryId},refetchQueries:[GET_OPTIONS_IN_CATEGORY]})
  }
  const [deleteOptionValue]=useMutation(DELETE_OPTIONVALUE,{ context: { clientName: 'categoryClient' },refetchQueries:[GET_OPTIONS_IN_CATEGORY]})

  
  const handleDeleteOptionValue=(optionValueId:string)=>{
    deleteOptionValue({variables:{optionValueId:+optionValueId,categoryId:categoryId}})
  }

  const [deleteOption]=useMutation(DELETE_OPTION,{ context: { clientName: 'categoryClient' },refetchQueries:[GET_OPTIONS_IN_CATEGORY]})

  
  const handleDeleteOption=(optionId:string)=>{
    deleteOption({variables:{optionId:+optionId,categoryId:categoryId}})
  }

  const [selectedOptions,setSelectedOptions]=useState<DataResult2[]>([])
  const {data:selectedoptionList}=useQuery(GET_OPTIONS_IN_CATEGORY,{context: { clientName: 'categoryClient' },variables:{categoryId:+categoryId}})

// console.log(JSON.parse(selectedoptionList.categoryOptionValueListing));
useEffect(()=>{
  if (selectedoptionList){
    if (selectedoptionList.categoryOptionValueListing){
      setSelectedOptions(JSON.parse(selectedoptionList.categoryOptionValueListing))
     
    }
   
  }
},[selectedoptionList])

const[searchValue,setSearchValue]=useState<string>("")
const handleSearch=(event:ChangeEvent<HTMLInputElement>)=>{
    setSearchValue(event.target.value)
}

const searchedResult=checkedRequiredOptions.filter((each)=>(
  each.name.toLowerCase().includes(searchValue.toLowerCase())
))

  return (
    <Box>
        <Box sx={{overflowY:"scroll"}}>
          
             {selectedOptions.length > 0 ? (
  <Box >
     {selectedOptions.map((item) => (
        <Box key={item.option_group_id} >
          <Box sx={{display:"flex"}}>
          <Typography className='createCategory-table-head' sx={{margin:"1%"}}>{item.option_group_name}</Typography>
          <IconButton sx={{marginLeft:"auto", ':hover': { background: '#FFF8F0' }}} onClick={()=>handleDelteOptionGroup(item.option_group_id)} >
                <CloseOutlined/>
              </IconButton>
              </Box>
              <Divider/>
          {item.options.map((each) => (
            <div key={each.option_id} >
              <div style={{display:"flex",marginLeft:"8%",margin:"2%"}}>
            
              <Typography>{each.option_name}</Typography>
              
             
              <Stack direction="row" spacing={1} sx={{marginLeft:"3%"}}>
                {each.option_values.map((each2) => (
                  <Chip
                    key={each2.option_value_id}
                    label={each2.option_value_name}
                    onDelete={() => handleDeleteOptionValue( each2.option_value_id)}
                    sx={{marginRight:"2%"}}
                  />
                ))}
              </Stack>
              <IconButton sx={{marginLeft:"auto",':hover': { background: '#FFF8F0' }}} onClick={()=>handleDeleteOption(each.option_id)}>
                <CloseOutlined/>
              </IconButton>
              </div>
              <Divider/>
            </div>
          
          ))}
        
        </Box>
        
      ))}
  
          </Box>) : (<div style={{ textAlign: 'center' }}><img src={image} alt="product" style={{width:"36%"}}/></div>)}
          <Box sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:'center'}}>
          <Button className='createCategory' onClick={handleOpenOptions} sx={{textTransform:'none'}}>
           {t("menu.catageory.assign_option_group")}
          </Button>
          </Box>

          <div>

            <Modal
              open={openOptions}

              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div style={{ display: "flex", alignItems: "center", padding: "1%", width: '100%' }}>
                  <IconButton className="create-product-exit" onClick={handleCloseOptions}>
                    <CloseOutlined className="create-product-exitIcon" />
                  </IconButton>
                  <Typography style={{ marginLeft: "40%" }} className='createCategory-select-product'>  {t("menu.catageory.select_options")}</Typography>

                </div>
                <Box sx={{ width: "100%", backgroundColor: "#EEEEEE", padding: "2%", textAlign: "center" }}>
                  <Typography className='createCategory-para'>{t("menu.catageory.the_selected_options")}</Typography>
                </Box>
                <Box
                  component="form"
                  sx={{ display: "flex", alignItems: "center", marginTop: "2%", width: "100%" }}
                  className='product-search'
                >
                  <IconButton type="button" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    //@ts-ignore
                    placeholder="search by name"
                    
                    name='search'
                    onChange={handleSearch}
                    
                  />
                </Box>


                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>

                        <TableCell align="right" >{t("menu.catageory.options")}</TableCell>
                        <TableCell align="right">{t("menu.catageory.price")}</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {searchedResult.map((row) => (
                        <Row key={row.name} row={row} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button className='createCategory-button' onClick={() => {
  handleCloseOptions();
  assingnOptionData();
  assignselectedOptions();
}}
 style={{ textTransform: "none" }}>{t("menu.catageory.apply_options")}</Button>
              </Box>

            </Modal>
          </div>
        </Box>

      
     

    </Box>
  );
}

export default OptionsModal;
