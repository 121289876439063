import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import { Paper, Divider } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { Toast } from "primereact/toast";
import { Tag } from 'primereact/tag';
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { SplitButton } from 'primereact/splitbutton';
import { Link } from "react-router-dom";
import './customers.css';
import DownloadCSV from "../utils/export";

export default function Customers() {
    const { t } = useTranslation();
    const toast = useRef(null);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [filters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        username: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        date: { value: null, matchMode: FilterMatchMode.EQUALS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        approved: { value: null, matchMode: FilterMatchMode.CONTAINS },
        group: { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    const breadcrumbs = [
        <Typography key="1">{t("sales.sales_label")}</Typography>,
        <Typography className="primaryColor" key="3">
            {t("sales.customers")}
        </Typography>,
    ];
    const social = [
        {
            label: 'Whatsapp',
            icon: 'pi pi-whatsapp',
        },
        {
            label: 'Instagram',
            icon: 'pi pi-instagram',
        },
        {
            label: 'Facebook',
            icon: 'pi pi-facebook',
        },
        {
            label: 'Sms',
            icon: 'pi pi-comment',
        },
    ];

    const userData = [
        {
            id: 1,
            username: "vinay1498",
            email: "vinay@gmail.com",
            status: "Enabled",
            date: "20/02/2020",
            group: "Default",
            approved: "Yes",
            loyality: "21",
        },
        {
            id: 2,
            username: "nani1478",
            email: "nani@gmail.com",
            status: "Enabled",
            date: "20/02/2020",
            group: "Default",
            approved: "Yes",
            loyality: "9",
        },
        {
            id: 3,
            username: "ram1236",
            email: "ram@gmail.com",
            status: "Disabled",
            date: "18/02/2020",
            group: "Default",
            approved: "No",
            loyality: "89",
        },
        {
            id: 4,
            username: "ramarao123",
            email: "ramarao@gmail.com",
            status: "Enabled",
            date: "18/02/2020",
            group: "Default 1",
            approved: "Yes",
            loyality: "7",
        },
        {
            id: 5,
            username: "akhil7852",
            email: "akhil@gmail.com",
            status: "Enabled",
            date: "16/02/2020",
            group: "Default",
            approved: "Yes",
            loyality: "6",
        },
        {
            id: 6,
            username: "sudheer4562",
            email: "sudheer@gmail.com",
            status: "Disabled",
            date: "16/02/2020",
            group: "Default",
            approved: "Yes",
            loyality: "9",
        },
        {
            id: 7,
            username: "suhass4562",
            email: "suhas@gmail.com",
            status: "Enabled",
            date: "14/02/2020",
            group: "Default 1",
            approved: "Yes",
            loyality: "7",
        },
        {
            id: 8,
            username: "vishwak4258",
            email: "vishwak@gmail.com",
            status: "Enabled",
            date: "14/02/2020",
            group: "hubrise",
            approved: "No",
            loyality: "3",
        },
        {
            id: 9,
            username: "ram7531",
            status: "Disabled",
            date: "12/02/2020",
            email: "rapo@gmail.com",
            group: "hubrise",
            approved: "No",
            loyality: "29",
        },
        {
            id: 10,
            username: "surya7469",
            email: "surya@gmail.com",
            status: "Enabled",
            date: "12/02/2020",
            group: "hubrise",
            approved: "Yes",
            loyality: "21",
        },
    ];

    const userDeletionConformation = () => {
        confirmDialog({
            message: "Are you sure you want to delete selected user(s)?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            rejectClassName: "p-button-text p-button-danger",
            accept,
            reject,
        });
    };

    const renderHeader = () => {
        return (
            <div className="customersTableHeader">
                <SplitButton icon="pi pi-comments" label={t("buttons.social")} rounded severity="warning" model={social} direction="down" />

                {selectedUsers.length > 0 && (
                    <>
                        <Button
                            onClick={userDeletionConformation}
                            severity="danger"
                            icon="pi pi-user"
                            label={t("buttons.delete")}
                            rounded
                        />
                        <Button
                            severity="danger"
                            icon="pi  pi-users"
                            label={t("buttons.blacklist")}
                            rounded
                        />
                    </>
                )}
                <Link to='/createCustomer'>
                    <Button
                        severity="warning"
                        icon="pi pi-users"
                        label={t("sales.create_customer")}
                        rounded
                    />
                </Link>
                <Button
                    onClick={() => DownloadCSV(userData, 'customers-list')}
                    severity="success"
                    icon="pi pi-file-export"
                    label={t("buttons.export")}
                    rounded
                />
                <Link to='/importCustomers'>
                    <Button
                        severity="success"
                        icon="pi pi-file-import"
                        label={t("sales.import_customer")}
                        rounded
                    />
                </Link>

            </div>
        );
    };
    const header = renderHeader();

    const RowActions = (rowData) => {
        return (
            <div className="headerUserContainer">
                <Link to='/customerProfile' state={rowData}>
                    <Button
                        className="edituser"
                        size="small"
                        title="Edit User"
                        aria-label="Edit User"
                        icon="pi pi-user-edit"
                        rounded
                    />
                </Link>
            </div>
        );
    };
    const User = (rowData) => {
        return (
            <Link to='/customerProfile' state={rowData}>{rowData.username}</Link>
        );
    };
    const Login = (rowData) => {
        return (
            <div className="headerUserContainer">
                <Button
                    title="Login"
                    size="small"
                    aria-label="Login"
                    icon="pi pi-sign-in"
                    rounded
                    severity="secondary"
                />
            </div>
        );
    };

    const Status = (rowData) => {
        return (
            <Tag value={rowData.status} severity={rowData.status === 'Enabled' ? 'success' : 'danger'} />
        );
    };
    const statusItemTemplate = (status) => {
        return (
            <Tag value={status} severity={status === 'Enabled' ? 'success' : 'danger'} />
        );
    };
    const statusRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={['Enabled', 'Disabled']} itemTemplate={statusItemTemplate} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Status" className="p-column-filter" showClear />
        );
    };

    const customerGroupRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={['Default', 'Default 1', 'hubrise']} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Group" className="p-column-filter" showClear />
        );
    };

    const dateFilterTemplate2 = (options) => {
        let date = userData.map((a) => {
            return a.date
        })
        date = [...new Set(date)]
        return (
            <Dropdown value={options.value} options={date} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Date" className="p-column-filter" showClear />
        );
    };


    const dateBodyTemplate = (rowData) => {
        return rowData.date
    };

    const accept = () => {
        toast.current.show({
            severity: "success",
            summary: "Confirmed",
            detail: "User Deletion Successful",
            life: 3000,
        });
    };

    const reject = () => {
        toast.current.show({
            severity: "warn",
            summary: "Rejected",
            detail: "You have rejected",
            life: 3000,
        });
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Toast ref={toast} />
            <ConfirmDialog />
            <Paper className="paper" sx={{ mb: 2 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
                <Divider className="divider" />
                <div className="card">
                    <DataTable
                        value={userData}
                        paginator
                        selectionMode="checkbox"
                        selection={selectedUsers}
                        onSelectionChange={(e) => setSelectedUsers(e.value)}
                        rows={5}
                        header={header}
                        filters={filters}
                        filterDisplay="row"
                        emptyMessage="No customers found."
                        globalFilterFields={["username", "status", "date", "email", "phone", "role"]}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                    >
                        <Column selectionMode="multiple"></Column>
                        <Column field="username" body={User} filter showFilterMenu={false} filterPlaceholder="Search by name" sortable header="Customer Name" style={{ minWidth: '15rem' }}></Column>
                        <Column field="email" filter showFilterMenu={false} filterPlaceholder="Search by email" header="Email" style={{ minWidth: '15rem' }}></Column>
                        <Column field="group" filter showFilterMenu={false} filterElement={customerGroupRowFilterTemplate} header="Customer Group"></Column>
                        <Column field="status" header="Status" filter showFilterMenu={false} filterElement={statusRowFilterTemplate} body={Status}></Column>
                        <Column field="date" dataType="date" filter showFilterMenu={false} filterElement={dateFilterTemplate2} body={dateBodyTemplate} header="Date Added" style={{ minWidth: '13rem' }}></Column>
                        <Column field="loyality" header="Loyality Points"></Column>
                        <Column header="Login into store" body={Login} style={{ minWidth: '10rem' }}></Column>
                        <Column header="Actions" body={RowActions}></Column>
                    </DataTable>
                </div>
            </Paper>
        </Box>
    );
}
