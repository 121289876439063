import React, { useContext, useState } from "react";
import { GoogleMap, MarkerF, CircleF, useJsApiLoader } from "@react-google-maps/api";
import UseLocationData from "../../context/UseLocationData";


const containerStyle = {
  height: "395px",
  width: "100%",
};

interface RenderGoogleMapsProps{
  rows:any
}
const locationKey = process.env.REACT_APP_GOOGLE_MAPS_API||"";

const RenderGoogleMap = ({ rows }: RenderGoogleMapsProps) => {
 console.log(rows)
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey : locationKey?.toString(),
  });
  const handleMarkerDragEnd = async (e:any) => {
    const { latLng } = e;

    const latitude = latLng.lat();
    const longitude = latLng.lng();
    
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${locationKey}`
    );
    if (response.ok) {
      const data = await response.json();
      const getAddressComponent = (type:any) => {
        const component = data.results[0].address_components.find((c:any) =>
          c.types.includes(type)
        );
        return component ? component.long_name : "";
      };
    }
  };
  const calculateZoomLevel = (meters:any) => {
    const MAX_ZOOM = 16; // Maximum available zoom level
    const MIN_ZOOM = 0; // Minimum available zoom level

    const zoom = MAX_ZOOM - Math.floor(Math.log2(meters / 260)); // Adjust the divisor as needed

    return Math.max(Math.min(zoom, MAX_ZOOM), MIN_ZOOM);
  };
  return (
    isLoaded ? <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: -3.745,
        lng: -38.523
      }}
      zoom={calculateZoomLevel(200)}
    >
      <MarkerF
        position={{
          lat: -3.745,
          lng: -38.523
        }}
        draggable={true}
        onDragEnd={handleMarkerDragEnd}
      />

     {rows.map((each:any)=>(
         <CircleF
         center={{
          lat: -3.745,
          lng: -38.523
         }}
         radius={parseInt(each.distance)} // In Meters
         options={{
           strokeColor: each.color,
           strokeOpacity: 1,
           strokeWeight: 3,
           // fillColor: locationData.stroke ?? "#ffbc0d",
           fillColor:null,
           fillOpacity: 0.25,
         }}
       />
     ))}
    </GoogleMap> : "Loading..."
  );
};
export default RenderGoogleMap;
