import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import "./signup.css"



function Signup() {


    const navigate=useNavigate()
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [userPasswordError, setUserPasswordError] = React.useState(false);

    const [userEmail, setUserEmail] = React.useState<string | "">("");
    const [userPassword, setUserPassword] = React.useState("")



    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUserPassword(e.target.value)
    }

    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUserEmail(e.target.value)
    }

    const handleSignupSubmitButton = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (userPassword === "") {
            setUserPasswordError(true)
        }

        if (userPassword!=="" && userEmail!==""){

        }
        const apiUrl = 'http://51.75.170.243:8080/v1/auth/token';
        const requestData = {
            username:userEmail, password:userPassword
        };

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData),
            });

            if (response.status!==500 && response.ok) {  
                const responseData = await response.json();
                if(responseData.access!==null && responseData){
                    sessionStorage.setItem('token', responseData.access)
                    // const token=sessionStorage.getItem('token');
                    // console.log(token)
                    navigate("/")
                }
                     
            } else {
                console.log(response.statusText);
            }
        } catch (error: any) {
            console.log('Error sending data to server:', error.message);
        }

        setUserEmail("")
        setUserPassword("")
    };


    


    // // when I clese the window it will become "null"
    



    return (
        <Box sx={{ height: "80vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginBottom: "15px" }}>
                    <Typography sx={{ marginBottom: "10px" }} className='sign-up-sign-up-text'>Sign in</Typography>
                    <Typography className='sign-up-welcome-text'>Welcome back</Typography>
                </Box>
                <form onSubmit={(e) => handleSignupSubmitButton(e)}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginBottom: "10px", marginTop: "10px" }}>
                        <TextField
                            sx={{ width: "100%", m: 1 }}
                            onChange={(e) => handleChangeEmail(e)}
                            id="outlined-required"
                            label={<Typography className='sign-up-email-label'>Email Address</Typography>}
                            color='warning'
                            type='email'
                            value={userEmail}

                        />
                        <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                            <InputLabel className='sign-up-email-label' htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                onChange={(e) => handleChangePassword(e)}
                                value={userPassword}
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                color='warning'
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}

                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label={<Typography className='sign-up-email-label'>Password</Typography>}
                            />
                        </FormControl>
                        {userPasswordError && <Typography sx={{ marginLeft: "10px" }} className='sign-up-forgot-password-text'>Forgot Password?</Typography>}
                    </Box>
                    <Box sx={{ textAlign: "center", margin: "10px 0 10px 15px" }}>
                        <Button type='submit' color='warning' sx={{ textTransform: "none", width: "100%" }} className='sign-up-sign-up-button' variant='contained'>Sign Up</Button>
                    </Box>

                </form>

                <Box>
                    <Typography className='sign-up-dont-have-text'>Don’t have an account?<span className='sign-up-dont-have-span-text'> Sign up</span></Typography>
                    <Typography className='sign-up-dont-need-assistance-text'>Need assistance or have questions? <span className='sign-up-dont-have-span-text'> Contact Support</span></Typography>
                </Box>

            </Box>

        </Box>
    )
}



export default Signup

