import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Switch,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Popover, List, ListItem, ListItemText } from '@mui/material';
import { t } from 'i18next';
import './salesTax.css';
import { SelectChangeEvent } from '@mui/material';
interface Option {
  value: number;
  label: string;
}

interface UserData {
  id: number;
  service_charge_name: string;
  location: string;
  status: string;
  charge_rate: number;
}

const ServiceCharges: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/create_service_charges');
  };

  const [toggleState, setToggleState] = useState(false);

  const handleToggleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggleState(event.target.checked);
  };

  const options: Option[] = [
    { value: 1, label: 'Option 1' },
    { value: 2, label: 'Option 2' },
    { value: 3, label: 'Option 3' },
  ];

  const [usersData, setUsersData] = useState<UserData[]>([]);

  useEffect(() => {
    axios
      .get('/serviceModule.json')
      .then(response => {
        setUsersData(response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value );
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const popoverContent = (
    <List style={{ padding: '0px 0px 0px 0px' }}>
      <Button style={{ padding: '0px 0px 0px 0px' }}>
        <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
          <ListItemText primary={t('selfMadeDiscount.edit')} />
        </ListItem>
      </Button>
      <Divider />
      <Button style={{ padding: '0px 0px 0px 0px' }}>
        <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
          <ListItemText primary={t('selfMadeDiscount.delete')} />
        </ListItem>
      </Button>
    </List>
  );

  return (
    <div>
      <Paper elevation={3}>
        <div style={{ paddingLeft: '12px', marginBottom: '8px', marginTop: '8px' }}>
          <div className='sales_head_container'>
            <div className='sales_head_center'>
              <Typography className='sales_head'>{t('settings.service_charge')}</Typography>
            </div>
            <Switch checked={toggleState} onChange={handleToggleChangeValue} color='warning' />
          </div>
          <Typography className='sales_para'>{t('settings.service_para')}</Typography>
        </div>
        <Divider style={{ backgroundColor: ' #D9D9D9', marginTop: '15px' }} />
        {toggleState && (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <FormControl
                style={{ margin: '12px 0px 12px 20px', width: '180px', height: '48px' }}
              >
                <InputLabel id='demo-simple-select-label'>{t('settings.select_location')}</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedOption}
                  onChange={handleChange}
                  label={t('settings.select_location')}
                  className='dropDown_con'
                  color='warning'
                  sx={{
                    '&:hover': {
                      '&& fieldset': {
                        border: '2px solid #F9C584',
                      },
                    },
                  }}
                >
                  {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button variant='contained' onClick={handleNavigate} className='salesButton'>
                {t('settings.new_charge')}
              </Button>
            </div>
            <Divider style={{ backgroundColor: ' #D9D9D9' }} />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow style={{ paddingLeft: '60px' }}>
                    <TableCell className='table_row_head' style={{ paddingLeft: '44px' }}>
                      {t('settings.service_charge_name')}
                    </TableCell>
                    <TableCell className='table_row_head'>{t('settings.location')}</TableCell>
                    <TableCell className='table_row_head'>{t('settings.status')}</TableCell>
                    <TableCell className='table_row_head'>{t('settings.tax_rate')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersData.map(row => (
                    <TableRow key={row.id} className='table_row1'>
                      <TableCell className='table_row' style={{ paddingLeft: '44px' }}>
                        {row.service_charge_name}
                      </TableCell>
                      <TableCell className='table_row'>{row.location}</TableCell>
                      <TableCell className='table_row'>{row.status}</TableCell>
                      <TableCell className='table_row'>{row.charge_rate}</TableCell>
                      <TableCell>
                        <div>
                          <IconButton onClick={handleClick}>
                            <MoreVertIcon />
                          </IconButton>

                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            style={{ width: '180px', height: '225px' }}
                          >
                            {popoverContent}
                          </Popover>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Paper>
    </div>
  );
};

export default ServiceCharges;
