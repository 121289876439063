import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 40, // Increase width
    height: 24, // Increase height
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 18, // Increase thumb width
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(18px)', // Adjust translation for the increased width
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 4,
      paddingRight: 6, // Increase right padding
      '&.Mui-checked': {
        transform: 'translateX(18px)', // Adjust translation for the increased width
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#F38B08',
        },
        
      },
      '&:hover': {
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#e5850b' : '#F9C584',
          
           // Hover background color
        }
    }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 14, // Increase thumb width
      height: 14, // Increase thumb height
      marginTop: 1, // Adjusted marginTop for centering vertically
      borderRadius: 12, // Adjust thumb border-radius
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 30, // Adjust track border-radius
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
    '&:hover': {
        '& + .MuiSwitch-track': {
          
          border:"2px solid #FCE2C2" // Hover background color
        }
    }
  }));
  

export default function CustomizedSwitches() {
  return (
    
      
     
      <Stack direction="row" spacing={1} alignItems="center">
       
        <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} size='medium'/>
       
      </Stack>
   
  );
}
