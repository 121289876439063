import { Box, Typography, FormControlLabel, Radio, Paper, FormControl, RadioGroup } from '@mui/material'
import React from 'react'
import "./kdsSettings.css"
import { t } from "i18next"
const textSizeList = [
    {
        id: 1,
        size: t("kds_settings.small")
    },
    {
        id: 2,
        size: t("kds_settings.medium")
    },
    {
        id: 3,
        size: t("kds_settings.large")
    },
]

const ticketsPerPage = [
    {
        id: 1,
        tickets: t("kds_settings.4")
    },
    {
        id: 2,
        tickets: t("kds_settings.5")
    },
    {
        id: 3,
        tickets: t("kds_settings.6")
    },
]

const notesList = [
    {
        id: 1,
        notes: t("kds_settings.1")
    },
    {
        id: 2,
        notes: t("kds_settings.2")
    },
    {
        id: 3,
        notes: t("kds_settings.3")
    },
]

function kdsSettingsLayout() {

    //Radio buttons functions
    const handleTextSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
    }

    const handleTicketsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
    }
    const handleNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
    }


    return (
        <Box sx={{width:"115%"}}>
            <Paper sx={{ padding: "20px",margin:"30px" }}>
                <Typography className='kds-settings-table-label-text'>{t("kds_settings.layout")}</Typography>

                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "90%" }} >
                    <Box >
                        <Paper elevation={2} sx={{ padding: "15px", width: "100%", marginTop: "15px", borderLeft: "2px solid #BAE29B" }}>
                            <Typography className='kds-settings-text-size-text'>{t("kds_settings.text_size")}</Typography>
                            <Typography className='kds-settings-select-the-text-text'>{t("kds_settings.select_the_text_size_to_be_shown_on_kds_device")}</Typography>
                            <Box sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        onChange={handleTextSize}
                                    >
                                        {
                                            textSizeList.map((each) => (
                                                <Box sx={{ paddingLeft: "10px", margin: "10px", border: "1px solid #EEE", width: "40%", display: "flex", alignItems: "center", borderRadius: "8px" }}>
                                                    <FormControlLabel value={each.size} control={<Radio color='warning' sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 27,
                                                        },
                                                    }} />}
                                                        label={<Typography className='kds-settings-radio-label'>{each.size}</Typography>} />
                                                </Box>
                                            ))
                                        }


                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Paper>
                    </Box>
                    <Box>
                        <Paper elevation={2} sx={{ padding: "15px", width: "100%", marginTop: "15px", borderLeft: "2px solid #E2A39B" }}>
                            <Typography className='kds-settings-text-size-text'>{t("kds_settings.tickets_per_age")}</Typography>
                            <Typography className='kds-settings-select-the-text-text'>{t("kds_settings.select_number")}</Typography>
                            <Box sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        onChange={handleTicketsPerPage}
                                    >
                                        {
                                            ticketsPerPage.map((each) => (
                                                <Box sx={{ paddingLeft: "10px", margin: "10px", border: "1px solid #EEE", width: "35%", display: "flex", alignItems: "center", borderRadius: "8px" }}>
                                                    <FormControlLabel value={each.tickets} control={<Radio color='warning' sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 27,
                                                        },
                                                    }} />}
                                                        label={<Typography className='kds-settings-radio-label'>{each.tickets}</Typography>} />
                                                </Box>
                                            ))
                                        }

                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Paper>
                    </Box>
                    <Box>
                        <Paper elevation={2} sx={{ padding: "15px", width: "100%", marginTop: "15px", borderLeft: "2px solid #E19BE2" }}>
                            <Typography className='kds-settings-text-size-text'>{t("kds_settings.notes")}</Typography>
                            <Typography className='kds-settings-select-the-text-text'>{t("kds_settings.number_of_lines")}</Typography>
                            <Box sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        onChange={handleNotes}
                                    >
                                        {
                                            notesList.map((each) => (
                                                <Box sx={{ paddingLeft: "10px", margin: "10px", border: "1px solid #EEE", width: "30%", display: "flex", alignItems: "center", borderRadius: "8px" }}>
                                                    <FormControlLabel value={each.notes} control={<Radio color='warning' sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 27,
                                                        },
                                                    }} />}
                                                        label={<Typography className='kds-settings-radio-label'>{each.notes}</Typography>} />
                                                </Box>
                                            ))
                                        }


                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </Paper>
            </Box>
    
    )
}

export default kdsSettingsLayout
