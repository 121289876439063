import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import './createCategory.css'
import { useEffect } from 'react';
import { Divider } from 'primereact/divider';
import { useNavigate } from 'react-router';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface Props{
  dailouge:boolean
  handleUpdateDailougeState:()=>void;
  handleComplete:()=>void;
  categoryId:string
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
 
  boxShadow: 24,
  p: 4,
};

export default function AlertDialog(props:Props) {
  const {dailouge,handleUpdateDailougeState,handleComplete,categoryId}=props
  const [open, setOpen] = React.useState(dailouge);

  
  const handleClose = () => {
    setOpen(false);
    handleUpdateDailougeState()
  };

  useEffect(() => {
    if(categoryId!=="")
    setOpen(true);
  }, [categoryId]);


const navigate=useNavigate()
const handleSkipNavigate=()=>{
navigate('/category-listing')
}

const handleNextButton=()=>{
  handleComplete()
}

  return (
    <div>
  
    <Modal
      open={open}
      
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Box sx={{display:"flex",alignItems:"center",width:"60%",justifyContent:"space-between"}}>
      {/* <Box className="create-product-exit" sx={{display:"flex",justifyContent:"center",alignItems:"center"}} onClick={handleClose}>
    <CloseIcon className="create-product-exitIcon"  />
  </Box> */}
  
        <Typography className='category-created'>Category Created</Typography>
      </Box>
      <Divider/>
      <Box sx={{padding:"3% 1% 3% 1%",marginTop:'1%'}}>

    
      <Typography className='create_category_modal_text'>

<span className='createCategory-table-head'> Do you want to continue to assign products to the category?</span>  then <span className='create_category_modal_next_text'>click Next</span> or you can skip and go to category listing.</Typography>
</Box>
<Box sx={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"2%"}}>
  <Button className='create_category_modal_skip_button' onClick={()=>{handleClose();handleSkipNavigate()}}>Skip</Button>
<Button className='create_category_modal_next_button' sx={{marginLeft:"3%"}} onClick={()=>{handleClose();handleNextButton()}}>Next {<ArrowForwardIcon />}</Button>
</Box>
      </Box>
    </Modal>
  </div>
  );
}










