import { Box, Typography,Autocomplete,TextField,Button,Popover, IconButton,List,ListItem,ListItemText,Divider } from '@mui/material'
import React from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import {t} from "i18next"


const locationsList=["location4","location5","location3"]



  const rows=[
    {
        id:1,
        branchName:"Madhapur",
        pairedDevices:"3 KDS devices Paired"
    },
    {
        id:2,
        branchName:"Madhapur",
        pairedDevices:"3 KDS devices Paired"
    },
    {
        id:3,
        branchName:"Madhapur",
        pairedDevices:"3 KDS devices Paired"
    },
    {
        id:4,
        branchName:"Madhapur",
        pairedDevices:"3 KDS devices Paired"
    },
  ]

  const firstPopupStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 940,
    height:450,
    bgcolor: 'background.paper',
    borderRadius:"5px",
    boxShadow: 24,
    p: 4,
  };

  const secondPopupStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 940,
    height:600,
    bgcolor: 'background.paper',
    borderRadius:"5px",
    boxShadow: 24,
    p: 4,
  };

function KdsListing() {
    const [value, setValue] = React.useState('1');
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const PopoverOpen = Boolean(anchorEl);
   
  

    const id = PopoverOpen ? 'simple-popover' : undefined;
  


  //   const firstPopupBreadcrumbs = [
  //       <Typography className='device-set-up-first-pop-up-header-bredcrump1' key="1">Device Details</Typography>,
  //       <Typography className='device-set-up-first-pop-up-header-bredcrump2' key="2">Generate Device Code</Typography>   
  //   ];

  //   const secondPopupBreadcrumbs = [
  //     <Typography className='device-set-up-first-pop-up-header-bredcrump2' key="1">Device Details</Typography>,
  //     <Typography className='device-set-up-first-pop-up-header-bredcrump1' key="2">Generate Device Code</Typography>   
  // ];
    const columns: GridColDef[] = [
        {
          field: 'branchName',
          headerClassName:"device-set-up-table-header",
          //@ts-ignore
          headerName: t("kds_listing.branch_name"),
          flex:1
        },
        {
          field: 'pairedDevices',
          headerClassName:"device-set-up-table-header",
          //@ts-ignore
          headerName:  t("kds_listing.paired_devices"),
          width:300
        },{
         field:"",
         disableColumnMenu:true,
          width:150,
          renderCell:(params)=>(
            <Box>
                <IconButton onClick={handleClick} >
                    <EditIcon/>
                </IconButton>
                <Popover
              id={id}
              open={PopoverOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              style={{
                width: "180px",
                height: "225px",
                paddingTop: "0px",
                paddingBottom: "0px"
              }}
              elevation={8}
            >
              {popoverContent}
            </Popover>
            </Box>
          )
        },
       
       
      ];

      const handleDeleteText=()=>{
        
      }




    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      };

      const getRowSize=()=>{
        return "device-set-up-table-row device-set-up-table-row-hover"
      }
      
    

      const popoverContent = (
        <List style={{ padding: '0px 0px 0px 0px' }}>
          <Button style={{ textTransform:"capitalize",padding: '0px 0px 0px 0px' }}>
            <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
              <ListItemText className='device-set-up-table-row-hover' primary={ t("kds_listing.edit")} />
            </ListItem>
          </Button>
          <Divider />
          <Button onClick={handleDeleteText} style={{textTransform:"capitalize", padding: '0px 0px 0px 0px' }}>
            <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
              <ListItemText className='device-set-up-table-row-hover' primary={ t("kds_listing.delete")} />
            </ListItem>
          </Button>
        
          
        </List>
      );
  return (
    <Box>
        <Box>
                    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <Autocomplete
                    
                    id="combo-box-demo"
                    options={locationsList}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField color='warning' {...params} label={<Typography className='device-set-up-header-drop-down-label'> { t("kds_listing.select_location")}</Typography>}/>}
                    />
                    <Button className='device-set-up-header-button'  variant='outlined' color='warning'>{ t("kds_listing.edit_items_assigned_to_kds")}</Button>
                    </Box>
                    <Box sx={{ height: 400, width: '100%',marginTop:"15px" }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        }}
                        pageSizeOptions={[5]}
                        getRowClassName={getRowSize}
                        disableRowSelectionOnClick
                    />
                    </Box>
                </Box>

        
    </Box>
    
  )
}

export default KdsListing
