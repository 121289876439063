import { FormControl,FormLabel,Radio,RadioGroup,FormControlLabel,OutlinedInput,InputAdornment,Box,Typography,Button,Autocomplete,TextField, IconButton, Switch, Divider, Paper,Accordion,AccordionDetails,AccordionSummary } from '@mui/material'
import React from 'react'
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PercentIcon from '@mui/icons-material/Percent';
import "./deliveryList.css"
import {t} from "i18next"

import {
    DataGrid,
    GridColDef
  } from '@mui/x-data-grid';

import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 620,
  bgcolor: 'background.paper',
  borderRadius:"8px",
  boxShadow: 24,
  p: 2,
};

const locationsList=["location1","location2","location3"]

const rows=[
    {   
        id:1,
        locationName:"Kingfield Branch",
    },
    {   
        id:2,
        locationName:"Madhapur Branch",
    },
    {   
        id:3,
        locationName:"Hitech city Branch",
    }
]

function DeliveryList() {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const getRowClassName=()=>{
        return "driver-list-table-row"
    }
    const columns: GridColDef[] = [
        { 
            field: 'locationName', 
            headerClassName:"delivery-list-table-header-name",
            //@ts-ignore
            headerName: t("delivery_list.store/location_name"), 
            width: 1200
         },
        {
            field: 'actions',
            //@ts-ignore
            headerName:t("delivery_list.actions"),
            headerClassName:"delivery-list-table-header-name",
            width: 100,
            renderCell:(params)=>{
              return(
                  <Box>
                      <IconButton>
                          <EditIcon/>
                      </IconButton>     
                  </Box>
              )
            }
          }
       
    ]    
  return (
   <Box>
    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"15px"}}>
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"28%"}}>
            <Typography className='delivery-list-header-text'>{t("delivery_list.delivery")}</Typography>
            <Autocomplete
            id="combo-box-demo"
            options={locationsList}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label={t("delivery_list.select_location")} />}
            />
        </Box>
        {/* <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"27%"}}>
        <Button onClick={handleOpen} variant='outlined' color='warning'  className='delivery-list-header-button'>Rush Hour Settings</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                 <Box>
                    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <IconButton sx={{ marginRight: "50px" }} onClick={handleClose} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                    <CloseIcon />
                    </IconButton>
                    <Typography className='delivery-list-header-text'>Rush Hour Settings</Typography>
                    <Button variant='contained' color='warning'>Save</Button>
                    </Box>
                    <Divider sx={{marginBottom:"10px",marginTop:"10px"}}/>
                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <Box sx={{width:"90%",padding:"10px",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <Typography className='delivery-list-header-text'>Apply to</Typography>
                            <Autocomplete
                            
                            id="combo-box-demo"
                            options={locationsList}
                            sx={{ width: 200 }}
                            renderInput={(params) => <TextField {...params} label="Select Location" />}
                            />
                            <Autocomplete
                            id="combo-box-demo"
                            options={locationsList}
                            sx={{ width: 200 }}
                            renderInput={(params) => <TextField {...params} label="Select Area" />}
                            />
                        </Box>
                    </Box>
                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"15px"}}>
                    <Accordion elevation={0} sx={{width:"90%",marginBottom:"10px",marginTop:"10px"}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className='delivery-list-header-text'>Shipping Rate</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Box sx={{display:"flex",justifyContent:"space-around",alignItems:"center"}}>
                    <Autocomplete
                            id="combo-box-demo"
                            options={locationsList}
                            sx={{ width: 270 }}
                            renderInput={(params) => <TextField {...params} label="Increase with" />}
                            />
                    <FormControl sx={{ width: '15ch' }} variant="outlined">
                  
                    <OutlinedInput
                      id="outlined-adornment-password"
                      defaultValue="0"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <PercentIcon/>
                          </IconButton>
                        </InputAdornment>
                      }
                      
                    />
                  </FormControl>

                    </Box>
                    <Box sx={{width:"80%",marginTop:"15px",marginBottom:"15px",display:"flex",justifyContent:"space-between",alignItems:"flex-start"}}>
                      <Typography className='delivery-list-header-text'>Rounding</Typography>
                      <FormControl>
                      
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel className='delivery-list-radio-border' value="None" control={<Radio color='warning' sx={{'& .MuiSvgIcon-root': {fontSize: 26},}} />}
                         label={<Typography className='delivery-list-radio-text'>None</Typography>} />

                        <FormControlLabel value="Half Up" control={<Radio color='warning' sx={{'& .MuiSvgIcon-root': {fontSize: 26},}} />}
                         label={<Typography className='delivery-list-radio-text'>Half Up<span className='delivery-list-radio-span'>( if 7.5 ==8.00)</span></Typography>} />
                        
                        <FormControlLabel value="Half Down" control={<Radio color='warning' sx={{'& .MuiSvgIcon-root': {fontSize: 26},}} />}
                         label={<Typography className='delivery-list-radio-text'>Half Down<span className='delivery-list-radio-span'>(if 7.5==7.00)</span></Typography>} />
                       
                       <FormControlLabel value="Upwards" control={<Radio color='warning' sx={{'& .MuiSvgIcon-root': {fontSize: 26},}} />}
                         label={<Typography className='delivery-list-radio-text'>Upwards<span className='delivery-list-radio-span'>( if 7.62 ==8.00)</span></Typography>} />
                      
                      
                      <FormControlLabel value="Downwards" control={<Radio color='warning' sx={{'& .MuiSvgIcon-root': {fontSize: 26},}} />}
                         label={<Typography className='delivery-list-radio-text'>Downwards<span className='delivery-list-radio-span'>(if 7.62==7.00)</span></Typography>} />
                      
                      <FormControlLabel value="Upwards to .99" control={<Radio color='warning' sx={{'& .MuiSvgIcon-root': {fontSize: 26},}} />}
                         label={<Typography className='delivery-list-radio-text'>Upwards to .99<span className='delivery-list-radio-span'>( if 7.62 ==7.99)</span></Typography>} />
                      
                      
                      </RadioGroup>
                    </FormControl>
                    </Box>                
                    </AccordionDetails>
                </Accordion>
                    </Box>
                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"15px"}}>
                    <Accordion elevation={0} sx={{width:"90%",marginBottom:"10px",marginTop:"10px"}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className='delivery-list-header-text'>Delivery Time</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Box sx={{display:"flex",justifyContent:"space-around",alignItems:"center"}}>
                    <Autocomplete
                            id="combo-box-demo"
                            options={locationsList}
                            sx={{ width: 270 }}
                            renderInput={(params) => <TextField {...params} label="Increase with" />}
                            />
                    <FormControl sx={{ width: '15ch' }} variant="outlined">
                  
                    <OutlinedInput
                      id="outlined-adornment-password"
                      defaultValue="0"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <Typography className='delivery-list-text-text'>min</Typography>
                          </IconButton>
                        </InputAdornment>
                      }
                      
                    />
                  </FormControl>

                    </Box>
            
                   


                    </AccordionDetails>
                </Accordion>
                    </Box>
                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"15px"}}>
                    <Accordion elevation={0} sx={{width:"90%",marginBottom:"10px",marginTop:"10px"}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className='delivery-list-header-text'>Set Rush Time</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Box sx={{display:"flex",justifyContent:"space-around",alignItems:"center"}}>
                    
                    </Box>
            
                   


                    </AccordionDetails>
                </Accordion>
                    </Box>
                 </Box>
                </Box>
            </Modal>

            
        </Box> */}
        <Button variant='contained' color='warning'  className='delivery-list-header-button'>{t("delivery_list.add_new_store")}</Button>
    </Box>
    <Box
      sx={{
        height: 500,
        padding:"15px",
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowClassName={getRowClassName}
      />
    </Box>
   </Box>
  )
}

export default DeliveryList
