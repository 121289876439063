import React from "react";
import { Button, Divider, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useForm, Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

const EditCustomerGroups = () => {
    const { t } = useTranslation();
    const location = useLocation()
    const breadcrumbs = [
        <Typography key="1">{t("sales.sales_label")}</Typography>,
        <Typography className="primaryColor" key="3">
            {t("sales.customer_groups")}
        </Typography>
    ];
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            groupName: location.state.groupname,
            description: "",
            approveNewCustomers: "no",
            displayCompanyNo: "no",
            companyNoRequired: "no",
            displayTaxId: "no",
            taxIdRequired: "no",
            sortOrder: location.state.sortOrder
        },
    });

    const onSubmit = (data) => console.log(data);
    return (
        <>
            <Paper className="paper">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
                <Divider className="divider" />
                <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                    <form style={{ width: "100%", display: "flex", flexDirection: "column", flexWrap: "wrap" }} onSubmit={handleSubmit(onSubmit)}>
                        <FormControl
                            className="inputHolder"
                            error={errors.groupName}
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-group-name">
                                {t("sales.input_labels.customer_group_name")} *
                            </InputLabel>
                            <Controller
                                name="groupName"
                                control={control}
                                rules={{ required: true, maxLength: 20, minLength: 3 }}
                                render={({ field }) => <Input {...field} />}
                            />
                            {errors.groupName && (
                                <FormHelperText className="inputErrors" id="group-name-error">
                                    {t("sales.input_labels.customer_group_name_error")}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-description">
                                {t("sales.input_labels.description")}
                            </InputLabel>
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => <Input multiline rows={4} {...field} />}
                            />
                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                        >
                            <FormLabel htmlFor="standard-adornment-approve-new-customers">
                                {t("sales.input_labels.approve_new_customers")}
                            </FormLabel>
                            <FormHelperText style={{ marginLeft: '0' }}>
                                {t("sales.input_labels.approve_new_customers_desc")}
                            </FormHelperText>
                            <Controller
                                name="approveNewCustomers"
                                control={control}
                                render={({ field }) =>
                                    <RadioGroup row defaultValue='no' {...field}>
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                }
                            />

                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                        >
                            <FormLabel htmlFor="standard-adornment-display-company-no">
                                {t("sales.input_labels.display_company_no")}
                            </FormLabel>
                            <FormHelperText style={{ marginLeft: '0' }}>
                                {t("sales.input_labels.display_company_no_desc")}
                            </FormHelperText>
                            <Controller
                                name="displayCompanyNo"
                                control={control}
                                render={({ field }) =>
                                    <RadioGroup row defaultValue='no' {...field}>
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                }
                            />

                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                        >
                            <FormLabel htmlFor="standard-adornment-company-no-required">
                                {t("sales.input_labels.company_no_required")}
                            </FormLabel>
                            <FormHelperText style={{ marginLeft: '0' }}>
                                {t("sales.input_labels.company_no_required_desc")}
                            </FormHelperText>
                            <Controller
                                name="companyNoRequired"
                                control={control}
                                render={({ field }) =>
                                    <RadioGroup row defaultValue='no' {...field}>
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                }
                            />

                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                        >
                            <FormLabel htmlFor="standard-adornment-display-tax-id">
                                {t("sales.input_labels.display_tax_id")}
                            </FormLabel>
                            <FormHelperText style={{ marginLeft: '0' }}>
                                {t("sales.input_labels.display_tax_id_desc")}
                            </FormHelperText>
                            <Controller
                                name="displayTaxId"
                                control={control}
                                render={({ field }) =>
                                    <RadioGroup row defaultValue='no' {...field}>
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                }
                            />

                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                        >
                            <FormLabel htmlFor="standard-adornment-tax-id-required">
                                {t("sales.input_labels.tax_id_required")}
                            </FormLabel>
                            <FormHelperText style={{ marginLeft: '0' }}>
                                {t("sales.input_labels.tax_id_required_desc")}
                            </FormHelperText>
                            <Controller
                                name="taxIdRequired"
                                control={control}
                                render={({ field }) =>
                                    <RadioGroup row defaultValue='no' {...field}>
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                }
                            />

                        </FormControl>
                        <FormControl
                            className="inputHolder"
                            sx={{ m: 1 }}
                            variant="standard"
                        >
                            <InputLabel htmlFor="standard-adornment-sort-order">
                                {t("sales.input_labels.sort_order")}
                            </InputLabel>
                            <Controller
                                name="sortOrder"
                                control={control}
                                render={({ field }) => <Input type="number" {...field} />}
                            />
                        </FormControl>
                        <br />
                        <br />
                        <Button
                            focusRipple
                            style={{ width: "150px" }}
                            className="primaryButton"
                            variant="contained"
                            type="submit"
                        >
                            {t("buttons.submit")}
                        </Button>
                    </form>
                </Box>
            </Paper>
        </>
    );
};

export default EditCustomerGroups;
