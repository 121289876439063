
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Box, Typography, Button } from "@mui/material";
import '../options/createProduct.css'
import './createCategory.css'
import { t } from 'i18next';
import { CloseOutlined } from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import  image from '../../assets/createProduct.png';
import AssignProducts from './assigngnProductsModal';
import { useMutation,useQuery } from '@apollo/client';
import { EDIT_CATEGORY } from '../../graphicalQl/usequery/categoryListQuery';
import ProductDailouge from './productDailougeBox';
import { DELTE_PRODUCT_FROM_CATEGORY } from '../../graphicalQl/mutation/mutation';
import 'react-toastify/dist/ReactToastify.css';
import { IconButton } from "@mui/material";



interface Props{
  id:string;
  handleStepByProductModal: () => void; 
  
}


const CategoryAssignProduct = (props:Props) => {
 
  const {id,handleStepByProductModal}=props

  const handleDelteProduct=(productId:string)=>{
    delteProduct({variables:{categoryId:+categoryId,productId:+productId}})
  }
  const columns: GridColDef[] = [

    {
      field: 'product',
      //@ts-ignore
      headerName: t("menu.catageory.product"),
      width: 150,
      editable: false,
      flex: 1,

    },
    {
      field: 'price',
    //@ts-ignore
    headerName: t("menu.catageory.price"),
      width: 150,
      editable: false,
      flex: 1,
    },
    {
      field: '.',
      headerName: '',
      filterable: false,
      width: 110,
      editable: false,
      renderCell: (params) => {
        return (
          <IconButton onClick={()=>handleDelteProduct(params.row.id)}>
          <CloseOutlined  />
          </IconButton>
        )
      }
    },

  ];

  
  

 

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => (setOpen(true));
  const handleClose = () => setOpen(false);
  const [categoryId, setCategoryId] = useState<string>("")

  
useEffect(()=>{
  setCategoryId(id)

},[id])

  const [assigncategoryData,setAssigncategoryData]=useState([])
 
  const {data:editData}=useQuery(EDIT_CATEGORY,{variables:{categoryId:categoryId}, context: { clientName: 'categoryClient' }})

 
 
useEffect(()=>{
  if (editData){
    
    if (editData.category !==null){
    const productData=editData.category.productSet.edges.map((item:any) => ({
      id: item.node.productId,
      product:item.node.name,
      price:item.node.price
    }))

    setAssigncategoryData(productData)
  }
}

},[editData])

const [delteProduct]=useMutation(DELTE_PRODUCT_FROM_CATEGORY,{ context: { clientName: 'categoryClient' },refetchQueries: [
  EDIT_CATEGORY, // DocumentNode object parsed with gql
  'Category' // Query name
],})
  return (
    <Box>

        <Box>
          <Box sx={{textAlign:"center",marginTop:"3%"}}>
          <Typography className='assign_product_text'>{t("menu.catageory.click_on")} <span className='assign_product_text2'>{t("menu.catageory.assign_product_button")} </span> {t("menu.catageory.to_add_products")} </Typography>
          </Box>
          {assigncategoryData.length > 0 ? (<Box sx={{ width: '100%',overflowY:"scroll" }}>
            <DataGrid
              rows={assigncategoryData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}

              disableRowSelectionOnClick
            />
          </Box>) : (<div style={{ textAlign: 'center',background:' var(--prime-orange-100, #FFF8F0,)' }}><img src={image} alt="product" style={{width:"36%"}}/></div>)}
<Box sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:'center',marginTop:"2%"}}>
          <Button className='createCategory' onClick={handleOpen} sx={{textTransform:"none"}}>
          {t("menu.catageory.assign_products")}
          </Button>
          </Box>
          <div>
            <AssignProducts open={open} upDateOpen={handleClose} categoryId={categoryId}  />


          </div>

        </Box>

    
      
     <ProductDailouge id={id} handleStepByProductModal={handleStepByProductModal}/>

    </Box>
  );
}

export default CategoryAssignProduct;
