import React, { useState } from 'react';
import { Typography, Paper, Button, Divider, TextField, Switch, Checkbox } from '@mui/material';
import { FormControl, Select, MenuItem } from '@mui/material';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,

} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { t } from "i18next";


import './rounding.css';
import './salesTax.css';
import './refund.css';
import './createAdvance.css'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,


} from '@mui/material';
import { SelectChangeEvent } from '@mui/material';

const RefundCancel: React.FC = () => {
    const [toggleState, setToogleState] = useState(false)
    const [checkedTax, setCheckedTax] = useState(false)
    const [checkedAmount, setCheckedAmount] = useState(false)
    const [showRefund, setShowRefund] = useState(false)
    const [addValue, setAddValue] = useState(false)


    const [cancelTime, setCancelTime] = useState('');
    const [acceptCancel, setAccepetCancel] = useState('');
    const [acceptCancel1, setAccepetCancel1] = useState('');
    const [cancelTime1, setCancelTime1] = useState('');



    const handleAcceptCancel = (event: SelectChangeEvent<string>) => {
        setAccepetCancel(event.target.value);
    };

    const handleAcceptCancel1 = (event: SelectChangeEvent<string>) => {
        setAccepetCancel1(event.target.value);
    };

    const handleCancelTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCancelTime(event.target.value);
    };

    const handleCancelTime1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCancelTime1(event.target.value);
    };

    const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToogleState(event.target.checked);
    };
    const handleCheckedTax = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedTax(event.target.checked);
    };

    const handleCheckedAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedAmount(event.target.checked);
    };

    const handleShowRefund = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowRefund(event.target.checked);
    };


    const [items, setItems] = useState([
        {id:"1",
            title: t("settings.order_error"),
            content: [t("settings.order_error"), t("settings.orders1"), t("settings.orders2")]
        },
        {
            id:"2",
            title: t("settings.food_quality"),
            content: [

                t("settings.food1"),
                t("settings.food2"),
                t("settings.food3"),
            ]
        },
        {
            id:"3",
            title: t("settings.delivery_issue"),
            content: [

                t("settings.delivery1"),
                t("settings.delivery2"),
                t("settings.delivery3"),

            ]
        },
        {id:"4",
            title: t("settings.payment_errors"),
            content: [

                t("settings.payment1"),
                t("settings.payment2"),
                t("settings.payment3"),

            ]
        },

        {id:"5",
            title: t("settings.service_issues"),
            content: [

                t("settings.service1"),
                t("settings.service1"),
                t("settings.service1"),

            ]
        }
    ]);
    const [inputValue1, setInputValue1] = useState('');
    const [inputValue2, setInputValue2] = useState('');
    const [textList, setTextList] = useState<string[]>([]);

    const handleAddValue = () => {
        setAddValue(true);
        setInputValue1('');
        setInputValue2('');
        setTextList([]);
    };

    const handleCancelValue = () => {
        setAddValue(false);
        setInputValue1('');
        setInputValue2('');
        setTextList([]);
    };

    const handleSaveValue = () => {
        const newItem = {
            id: Date.now().toString(), // Convert to string
            title: inputValue1,
            content: textList,
        };
        console.log(textList);
        setItems([...items, newItem]);
        handleCancelValue();
    };
    
    const handleDelete = (id: string) => {
        const updatedItems = items.filter((item) => item.id !== id);
        setItems(updatedItems);
    };

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newText = e.target.value;
        const lines = newText.split('\n').filter((line) => line.trim() !== '');
        setTextList(lines);
        setInputValue2(newText);
    };


    const [cancelSelectedValue, setCancelSelectedValue] = useState("option1"); // Initial selected value

    const handleCancelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCancelSelectedValue(event.target.value);
    };
    
    const columnHeadings = [

        t("settings.Cancellation Period"),
        t("settings.delivery"),
        t("settings.delivery"),
        t("settings.dineIn")
    ];

    const firstColumnValues = [
        t("settings.order_place_to_accept"),
        t("settings.accept_to_prepare"),
        t("settings.preparing_to_dispatch"),
        t("settings.dispatch_to_delivery"),
        t("settings.post_delivery")
    ];
    const [tableData, setTableData] = useState(
        Array.from({ length: 5 * 4 + 1 }, () => 'noRefund')
    );

    const handleDropdownChange1 = (rowIndex: number, columnIndex: number, newValue: string) => {
        const updatedData = [...tableData];
        updatedData[rowIndex * 4 + columnIndex + 1] = newValue;
        setTableData(updatedData);
    };

    const options = [
        { value: 'Minute', label: t("settings.minute") },
        { value: 'Hour', label: t("settings.hour") },
        { value: 'Day', label: t("settings.day") },
    ];

    const [refundInputValue, setRefundInputValue] = useState(''); // Change the initial value as needed
    const [refundSelectedOption, setRefundSelectedOption] = useState(''); // Change the initial value as needed


    const refundChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRefundInputValue(event.target.value);
    };

    const refundChangeSelect = (event: SelectChangeEvent<string>) => {
        setRefundSelectedOption(event.target.value );
    };



    return (
        <div>
            <Paper elevation={3} style={{ padding: "20px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography className="rounding_titleValue">{t("settings.refund&cancel")}</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button variant="contained" className="rounding_Button_clear">
                            {t("settings.clear")}</Button>
                        <Button variant="contained" className="Rounding_save_button" style={{ marginLeft: "20px" }}>
                            {t("settings.save")}</Button>
                    </div>
                </div>
                <Paper style={{ marginLeft: '68px', marginTop: '44px', width: "944px", padding: "8px 16px 8px 16px", borderLeft: "2px solid  var(--stroke-color-1-violet, #AE9BE2)" }} className='refund_page'  >
                    <div style={{ padding: "16px 0px 16px 12px", display: "flex", alignItems: "center" }}>
                        <div style={{ width: "510px", marginRight: '32px' }}>
                            <Typography className='refund_head'>{t("settings.time_limit_refund")}</Typography>
                            <Typography className='refund_para'> {t("settings.limit_refund_para")}</Typography>
                        </div>
                        <div>
                            <Typography className='create_tax_exception' style={{ marginBottom: "10px" }}>{t("settings.enter_value")}</Typography>
                            <div style={{ display: "flex", width: "228px", height: "48px" }} className='refund_input_border'>

                                <TextField

                                    variant='standard'
                                    value={refundInputValue}
                                    onChange={refundChangeInput}
                                    className='create_advance_input'
                                    placeholder="10"
                                    InputProps={{
                                        disableUnderline: true, // This removes the underline

                                    }}
                                    style={{ marginLeft: "10px", marginTop: "15px" }}
                                />
                                <FormControl style={{ width: '190px', marginBottom: "5px", marginTop: "12px", marginRight: "10px" }} className='create_advance_select' variant='standard'>

                                    <Select value={refundSelectedOption} onChange={refundChangeSelect} style={{ marginLeft: "10px" }}  >
                                        {options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>




                </Paper>
                <Paper style={{ marginLeft: '68px', marginTop: '44px', width: "944px", padding: "20px 12px 20px 12px", borderLeft: "2px solid  var(--stroke-color-2-tale, #9BDAE2)" }} className='refund_page'  >
                    <Typography className='refund_head2'>Refund Types</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography className="refund_head" style={{ marginRight: "10px" }}>{t("settings.refund_rule")}</Typography>
                        <Switch checked={toggleState} onChange={handleToggle} color='warning' />

                    </div>
                    {toggleState ? (
                        <div>
                            <Typography className='refund_para'>{t("settings.refund_rule2")}</Typography>
                        </div>
                    ) : (<div>
                        <Typography className='refund_para'>{t("settings.refund_rule3")}</Typography>
                    </div>)}
                    <div style={{ display: "flex", marginTop: "20px", marginLeft: '12px' }}>


                        <FormControlLabel

                            control={<Checkbox color='warning' checked={checkedTax} onChange={handleCheckedTax} />}
                            label={<Typography className='refund_check_text'>{t("settings.tax_only")}</Typography>}
                            className='refund_rounding_box'
                        />

                        <FormControlLabel

                            control={<Checkbox color='warning' checked={checkedAmount} onChange={handleCheckedAmount} />}
                            label={<Typography className='refund_check_text'>{t("settings.custom_amount")}</Typography>}
                            className='refund_rounding_box'
                        />
                    </div>
                </Paper>
                <Paper style={{ marginLeft: '68px', marginTop: '44px', width: "944px", padding: "20px 12px 20px 12px", borderLeft: "2px solid  var(--stroke-color-3-green, #BAE29B)" }} className='refund_page'  >

                    <div>
                        <div style={{ display: 'flex', alignItems: "center" }}>

                            <Typography className='refund_head2' style={{ marginRight: "80px" }}>{t("settings.show_refund")}</Typography>

                            <Switch checked={showRefund} onChange={handleShowRefund} color='warning' />
                        </div>
                        <Typography className='refund_head3' style={{ marginTop: "21px", marginBottom: "8px" }}>{t("settings.refund_reason")}</Typography>
                        <Typography className='refund_para'>{t("settings.reason_para")}</Typography>

                        <div className='refund_accordion' >
                            {showRefund && (
                                <div style={{ width: '804px', padding: '20px 12px' }}>

                                    {items.map((item) => (
                                        <Accordion key={item.id}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography className='refund_head'>{item.title}</Typography>
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => handleDelete(item.id)}
                                                    style={{ position: 'absolute', top: 0, right: 0, marginRight: '50px', marginTop: "6px" }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Divider style={{ marginBottom: "12px" }} />
                                                <ul>
                                                    {item.content.map((line, index) => (
                                                        <li key={index} className='refund_para' style={{ marginLeft: "10px" }}>{line}</li>
                                                    ))}
                                                </ul>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                    {addValue && (
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "12px" }}>
                                                <div style={{ display: 'flex' }}>
                                                    <TextField
                                                        style={{ marginBottom: '20px' }}
                                                        label="Add a reason"
                                                        variant="outlined"
                                                        value={inputValue1}
                                                        onChange={(e) => setInputValue1(e.target.value)}
                                                    />

                                                </div>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Button onClick={handleCancelValue} className='refund_cancel' style={{ marginRight: "20px" }}>{t("settings.cancel")}</Button>
                                                    <Button onClick={handleSaveValue} className='refund_save' style={{ marginRight: "20px" }}>{t("settings.add1")}</Button>
                                                </div>

                                            </div>
                                            <Divider style={{ marginBottom: "12px" }} />
                                            <TextareaAutosize
                                                style={{ marginBottom: '20px', width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                                                minRows={3}
                                                placeholder="Description supporting reason"
                                                value={inputValue2}
                                                onChange={handleTextareaChange}
                                            />
                                        </div>
                                    )}

                                    <Button onClick={handleAddValue} className='refund_reason' style={{ marginTop: "10px" }}>{t("settings.add_reason")}</Button>

                                </div>

                            )}
                        </div>

                    </div>

                </Paper>

                <Paper style={{ marginLeft: '68px', marginTop: '44px', width: "944px", padding: "20px 12px 20px 12px", borderLeft: "2px solid   var(--stroke-color-4-brown, #E2A39B)" }} className='refund_page'  >

                    <div>
                        <FormControl component="fieldset" >

                            <RadioGroup
                                aria-label="options"
                                name="options"
                                value={cancelSelectedValue}
                                onChange={handleCancelChange}
                                style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}

                            >
                                <FormControlLabel
                                    value="option1"
                                    control={<Radio color='warning' />}
                                    label={<div><Typography className='refund_head' style={{ width: "256px" }}>{t("settings.cancel_policy")}</Typography>
                                        <Typography className='refund_para'>{t("settings.set_quote")}</Typography>
                                    </div>}
                                    className='refund_radio'
                                />
                                <FormControlLabel
                                    value="option2"
                                    control={<Radio color='warning' />}
                                    label={<div><Typography className='refund_head' style={{ width: "256px" }}>{t("settings.accept_cancelation")}</Typography>
                                        <Typography className='refund_para'>{t("settings.before_delivery")}</Typography>
                                    </div>}
                                    className='refund_radio'
                                    style={{ marginLeft: "20px" }}
                                />

                            </RadioGroup>
                        </FormControl>

                        {cancelSelectedValue === "option1" ? (
                            <div style={{ marginTop: "20px", width: "900px" }}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {columnHeadings.map((heading, index) => (
                                                    <TableCell key={index} className='refund_head3'>{heading}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {firstColumnValues.map((status, rowIndex) => (
                                                <TableRow key={rowIndex} className='refund_head5'>
                                                    <TableCell className='refund_head5'>{status}</TableCell>
                                                    {Array.from({ length: 3 }).map((_, columnIndex) => (
                                                        <TableCell key={columnIndex}>
                                                            <FormControl>
                                                                <Select
                                                                    value={tableData[rowIndex * 3 + columnIndex + 1]}
                                                                    onChange={(event) =>
                                                                        handleDropdownChange1(
                                                                            rowIndex,
                                                                            columnIndex,
                                                                            event.target.value
                                                                        )
                                                                    }
                                                                    style={{ width: "130px" }}
                                                                    color='warning'
                                                                >
                                                                    <MenuItem value="refund">{t("settings.refund")}</MenuItem>
                                                                    <MenuItem value="noRefund">{t("settings.no_refund")}</MenuItem>
                                                                    <MenuItem value="na">{t("settings.na")}</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        ) :
                            (
                                <div>
                                    <div style={{ marginLeft: '20px', marginBottom: '10px' }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: '10px' }}>
                                            <div className="sales_head_center">
                                                <Typography className="refund_head4">{t("settings.cancelation_para")}</Typography>
                                            </div>
                                            <div style={{ display: "flex", width: "254px" }} className='refund_input_border'>
                                                <TextField
                                                    label={<Typography className='create_label'>{t("settings.enter_value")}</Typography>}
                                                    variant='standard'
                                                    value={cancelTime}
                                                    onChange={handleCancelTime}
                                                    className='create_advance_input'
                                                    InputProps={{
                                                        disableUnderline: true, // This removes the underline

                                                    }}
                                                />
                                                <FormControl style={{ width: "220px", marginTop: "16px" }} className='create_advance_select' variant='standard'>
                                                    <Select
                                                        value={acceptCancel}
                                                        onChange={handleAcceptCancel} style={{ marginLeft: "10px" }} disableUnderline={true} >
                                                        <MenuItem value="Minute">{t("settings.minutes")}</MenuItem>
                                                        <MenuItem value="Hours">{t("settings.hours")}</MenuItem>
                                                        <MenuItem value="Days">{t("settings.days")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '20px', marginBottom: '10px' }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                                            <div className="sales_head_center" >
                                                <Typography className="refund_head4">{t("settings.before_dineIn")}</Typography>
                                            </div>
                                            <div style={{ display: "flex", width: "254px" }} className='refund_input_border'>
                                                <TextField
                                                    label={<Typography className='create_label'>{t("settings.enter_value")}</Typography>}
                                                    variant='standard'
                                                    value={cancelTime1}
                                                    onChange={handleCancelTime1}
                                                    className='create_advance_input'
                                                    InputProps={{
                                                        disableUnderline: true, // This removes the underline

                                                    }}
                                                />
                                                <FormControl style={{ width: "220px", marginTop: "16px" }} className='create_advance_select' variant='standard'>
                                                    <Select value={acceptCancel1}
                                                        onChange={handleAcceptCancel1} style={{ marginLeft: "10px" }} disableUnderline={true} >
                                                        <MenuItem value="Minute">{t("settings.minutes")}</MenuItem>
                                                        <MenuItem value="Hours">{t("settings.hours")}</MenuItem>
                                                        <MenuItem value="Days">{t("settings.days")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </Paper>
            </Paper>
        </div>
    )

}

export default RefundCancel