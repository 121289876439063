import React, { useState,useEffect } from 'react';
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../utils/strictModeDroppable.tsx";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Checkbox, Divider, FormControlLabel, IconButton, List, ListItem, TextField } from '@mui/material';
import image from '../../assets/prouctAddImage.png'
import menuImage from '../../assets/menuSetup.png'

import './createBundle.css'

import CloseIcon from '@mui/icons-material/Close';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';


import SearchIcon from '@mui/icons-material/Search';


import DeleteIcon from '@mui/icons-material/Delete';
interface MenuItem {
  id: string;
  content: string;
  category: string;
  course: string;
  variations: { id: string; variation: string }[];
}


interface MenuItem2 {
  id: string;
  content: string;
  category: string;
  course: string;
  group:string
  variations: { id: string; variation: string }[];
  addPrice:{id:string;value:string,itemId:string}[];
 
}
interface Group{
  id:string;
  name:string;
  description:string;
  minimumSelect:string;
  maximumSelect:string;
  check:boolean;
}

const BundleDragDrop: React.FC = () => {

  const [group,setGroup]=useState<Group[]>([
    {id:'0',name:"",description:"",minimumSelect:"",maximumSelect:"",check:false},
    
  ])
  const [leftItems, setLeftItems] = useState<MenuItem2[]>([
    // { id: 'left-1', content: 'Left Item 1', category: '', course: '', variations: [] },
    // { id: 'left-2', content: 'Left Item 2', category: '', course: '', variations: [] },
    // { id: 'left-3', content: 'Left Item 3', category: '', course: '', variations: [] },
  ]);

  const pizza: MenuItem[] = [
    {
      id: 'italianPizza', content: 'Italian Pizza', category: "pizza", course: "", variations: [
        { id: 'italilian-1', variation: "8 inch" },
        { id: 'italilian-2', variation: "10 inch" },
        { id: 'italilian-3', variation: "12 inch" },

      ]
    },
    {
      id: 'maragaritaPizza', content: 'Maragarita Pizza', category: "pizza", course: "Main2", variations: [
        { id: 'maragaritaPizza-1', variation: "small" },
        { id: 'maragaritaPizza-2', variation: "medium" },
        { id: 'maragaritaPizza-3', variation: "large" },

      ]
    },
    {
      id: 'chikenBrustPizza', content: 'Chiken Brust Pizza', category: "pizza", course: "Appetisers", variations: [
        { id: 'chikenBrustPizza-1', variation: "8 inch" },
        { id: 'chikenBrustPizza-2', variation: "10 inch" },
        { id: 'chikenBrustPizza-3', variation: "12 inch" },

      ]
    },
    {
      id: 'vegPizza', content: "Veg Pizza", category: "pizza", course: "Main", variations: [
        { id: 'vegPizza-1', variation: "8 inch" },
        { id: 'vegPizza-2', variation: "10 inch" },
        { id: 'vegPizza-3', variation: "12 inch" },
      ]
    },
    { id: 'italianBiryani', content: 'Italian Biryani',category:"biryani" ,course:"Appetisers", variations: [
      
    ]  },
   
    { id: 'maragaritaBiryani', content: 'Maragarita Biryani',category:"biryani" ,course:"Main", variations: [
      { id: '1', variation: "8 inch" },
      { id: '2', variation: "10 inch" },
      { id: '3', variation: "12 inch" },

    ] },
    { id: 'chickenBreastBiryani', content: 'Chicken Breast Biryani',category:"biryani" ,course:"Main", variations: [
      { id: '1', variation: "8 inch" },
      { id: '2', variation: "10 inch" },
      { id: '3', variation: "12 inch" },

    ]  },
    { id: 'vegBiryani', content: 'Veg Biryani',category:"biryani" ,course:"Main", variations: [
      { id: '1', variation: "8 inch" },
      { id: '2', variation: "10 inch" },
      { id: '3', variation: "12 inch" },

    ]  }

  ]





  const onDragEnd = (result: DropResult) => {
console.log(result)

    if (!result.destination) {
      return;
    }

    const sourceListId = result.source.droppableId;
    const destinationListId = result.destination.droppableId;

    const destinationList = leftItems; // Set the correct destination list
    const foundGroup = group.find((each) => each.id === destinationListId);
const groupValue = foundGroup ? String(foundGroup.name) : '';
console.log(groupValue)
    
    if (sourceListId !== 'left' && sourceListId !== 'right') {
    
      const draggedItem = result.draggableId;

      const resultValue = pizza.find((each) =>
        each.variations.some((value) => value.id === draggedItem)
      );

      if (resultValue) {
        // If a matching variation was found, create a new array with just that variation
        const updatedVariations = resultValue.variations.filter(
          (value) => value.id === draggedItem
        );

       
        let updatedResultValue:MenuItem2 = {
          ...resultValue,
          variations: updatedVariations,
          group:groupValue,
          id: `${draggedItem}-${destinationListId}`,
          addPrice:[]
        };

        // Now you can set this updated item in your state or use it as needed
        const superItem = leftItems.filter((each) => (
          each.content === updatedResultValue.content && each.group===updatedResultValue.group
        ))

        const index = leftItems.findIndex((each) => (
          each.content === updatedResultValue.content && each.group===updatedResultValue.group
        ))
    
        const allVariations = superItem.flatMap(item => item.variations.map(variation => variation.variation));

        // Find common variations with the second list
        const commonVariations = allVariations.includes(updatedResultValue.variations[0].variation)

        if (superItem.length >= 1) {
          if (superItem[0].variations.length >= 1) {
            updatedResultValue = {
              ...superItem[0], // Spread the first element of superItem
              variations: [...superItem[0].variations, ...updatedVariations],
              id: `${draggedItem}-${Date.now()}`

            };


          }



        }

        const newDestinationList = Array.from(destinationList);
        if (index >= 0) {
          newDestinationList.splice(index, 1);
        }
        newDestinationList.splice(result.destination.index, 0, updatedResultValue);
        const duplicate = leftItems.filter((each) => (
          each.id === updatedResultValue.id && each.group===updatedResultValue.group
        ))

        if (duplicate.length === 0 && !commonVariations) {
          setLeftItems(newDestinationList);
        }

      }

    }
    else {
      const sourceList =pizza; // Set the correct source list

      const draggedItem = sourceList[result.source.index];
      const copiedItem: MenuItem2 = {
        ...draggedItem,
        id: `${destinationListId}-${draggedItem.id}`,
        group:groupValue,
      addPrice:[]
      };

      const newDestinationList = Array.from(destinationList);

      if (  sourceListId=== 'right') {
        newDestinationList.splice(result.destination.index, 0, copiedItem);
      //  else if (destinationListId === 'left' && sourceListId === 'left') {
      //   const [removedItem] = newDestinationList.splice(result.source.index, 1);
      //   newDestinationList.splice(result.destination.index, 0, removedItem);
       }

      const duplicate = leftItems.filter((each) => (
        each.id === copiedItem.id && each.group===copiedItem.group
      ))
      if (sourceListId !== "left") {
        const sameContentIndex = leftItems.findIndex((each) => (
          each.content === copiedItem.content && each.group===copiedItem.group
          
        ))

        if (sameContentIndex >= 0) {
          newDestinationList.splice(sameContentIndex + 1, 1);
        }
      }
      if ( duplicate.length === 0) {
        setLeftItems(newDestinationList);
      }
    }
  };



 
  console.log(leftItems)

  const addGroup=()=>{
    const list=[{
      id:(group.length).toString(),
      name:"",
      description:"",
      minimumSelect:"",
      maximumSelect:"",
      check:false
    }]
  
    setGroup((preValue) => [...list,...preValue ]);
   
  }
  

  const handleGroupNameChange = (groupId: string, newName: string) => {
    setGroup((prevGroup) =>
      prevGroup.map((item) => (item.id === groupId ? { ...item, name: newName } : item))
    );
  };

  const handleGroupDescriptionChange = (groupId: string, newDescription: string) => {
    setGroup((prevGroup) =>
      prevGroup.map((item) => (item.id === groupId ? { ...item, description: newDescription } : item))
    );
  };
  const handleGroupCheckChange = (groupId: string, newCheck: boolean) => {
    setGroup((prevGroup) =>
      prevGroup.map((item) => (item.id === groupId ? { ...item, check: newCheck } : item))
    );
  };

  const handleMinimumSelectChange = (groupId: string, newMinimumSelect: string) => {
    setGroup((prevGroup) =>
      prevGroup.map((item) => (item.id === groupId ? { ...item, minimumSelect: newMinimumSelect } : item))
    );
  };

  const handleMaximumSelectChange = (groupId: string, newMaximumSelect: string) => {
    setGroup((prevGroup) =>
      prevGroup.map((item) => (item.id === groupId ? { ...item, maximumSelect: newMaximumSelect } : item))
    );
  };

  const deleteItemInGroup=(id:string,groupName:string)=>{
const updatedLeftItems=leftItems.filter((each)=>(
  !(each.id===id&& each.group===groupName)
 
))
setLeftItems(updatedLeftItems)
  }


const deleteVariation = (id: string, groupName: string, variationId: string) => {
  const updatedVariation = leftItems.map((each) => {
    if (each.id === id && each.group === groupName) {
      each.variations = each.variations.filter((item) => item.id !== variationId);
    }
    return each; // Return the modified 'each'
  });
  setLeftItems(updatedVariation)
};

const UpdateAddPrice = (id: string, itemId: string, value: string) => {
  setLeftItems((prevalue) => {
    const updatedItems = prevalue.map((each) => {
      if (each.id === itemId) {
      const filterAddPrice=each.addPrice.filter((each)=>each.id!==id)
        return {
          ...each,
          addPrice: [
            ...filterAddPrice,
            { id, value,itemId }, // Add the new object to the array
          ],
        };
      }
      return each;
    });
    return updatedItems;
  });
};

const UpdateAddPrice2 = ( id:"",itemId: string, value: string) => {
  setLeftItems((prevalue) => {
    const updatedItems = prevalue.map((each) => {
      if (each.id === itemId) {
        const filterAddPrice=each.addPrice.filter((each)=>each.itemId!==itemId)
        return {
          ...each,
          addPrice: [
            ...filterAddPrice,
            {  id,value,itemId }, // Add the new object to the array
          ],
        };
      }
      return each;
    });
    return updatedItems;
  });
};

const [searchValue, setSearchValue] = useState<string>("");

const fiterPizza=pizza.filter((each)=>(
  each.content.toLowerCase().includes(searchValue)
))


console.log(leftItems)
const handleDeleteGroup=(id:string)=>{
  const updatedGroup=group.filter((each)=>(
    each.id!==id
  ))
  setGroup(updatedGroup)
}

const [openAccordionId, setOpenAccordionId] = useState<string | null>(null);
const [openAccordionId1, setOpenAccordionId1] = useState<string | null>(null);

  useEffect(() => {
    if (group.length > 0) {
      // Initialize openAccordionId with the ID of the first group
      setOpenAccordionId(group[0].id);
    }
    setOpenAccordionId1(pizza[0].id);
  }, [group]);

  const handleAccordionClick = (id: string) => {
    if (id === openAccordionId) {
      setOpenAccordionId(null);
    } else {
      setOpenAccordionId(id);
    }
  };

  const handleAccordionClick1 = (id: string) => {
    if (id === openAccordionId1) {
      setOpenAccordionId1(null);
    } else {
      setOpenAccordionId1(id);
    }
  };

  
  return (
   
      <DragDropContext onDragEnd={onDragEnd} >
        <Box sx={{display:'flex',flexDirection:'column'}}>
        <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >
      <IconButton sx={{ p: '10px' }} aria-label="menu">
      <SearchIcon />
      </IconButton>
      <InputBase
  sx={{ ml: 1, flex: 1 }}
  placeholder="Search by name."
  inputProps={{ 'aria-label': 'Search by name.' }}
  value={searchValue}
  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }}// Pass the event argument to handleSearch
/>
      
     
    </Paper>
<StrictModeDroppable droppableId="right" type="list" >
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ listStyle: 'none', padding: '0', width: '94%' }}
            >
              <Box sx={{height:"60vh",overflowY:"scroll"}}>
              {fiterPizza.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        margin: '8px',
                        padding: '8px',
                        borderRadius: '4px',
                        ...provided.draggableProps.style,
                      }}
                    >
                      <Accordion expanded={openAccordionId1 === item.id} onChange={() => handleAccordionClick1(item.id)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {item.content}
                          </Typography>
                         
                        </AccordionSummary>
                        <AccordionDetails>
                          <StrictModeDroppable droppableId={`right-${item.id}`} type="list">
                            {(provided) => (
                              <ul
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{ listStyle: 'none', padding: '0', width: '94%' }}
                              >
                                {item.variations.map((variation, variationIndex) => (
                                  <Draggable
                                    key={variation.id}
                                    draggableId={variation.id.toString()}
                                    index={variationIndex}
                                  >
                                    {(provided) => (
                                      <li
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          width:"100%",
                                          margin:"1%",
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        <Box className="create-bundle-accordionItem">
                                        <Typography >{variation.variation}</Typography>
                                        </Box>
                                        
                                      </li>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </ul>
                            )}
                          </StrictModeDroppable>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  )}
                </Draggable>
              ))}
              </Box>
              {provided.placeholder}
            </ul>
          )}
        </StrictModeDroppable>
        </Box>
        <Box sx={{display:'flex',flexDirection:'column',width: '65%',top:"469px",position:"absolute"}}>
          <Button className='create-bundle-button2' onClick={addGroup}>+ Add Group</Button>
          <List>

{group.map(each=>(
 <Accordion defaultExpanded={true} sx={{marginTop:'1%'}} expanded={openAccordionId === each.id} onChange={() => handleAccordionClick(each.id)}>
 <AccordionSummary
   expandIcon={<ExpandMoreIcon />}
   aria-controls="panel1a-content"
   id="panel1a-header"
   
 >
  <Box sx={{display:'flex',alignItems:'center',justifyContent:"space-between",width:"90%",marginLeft:"2%"}}>
   <Typography>{each.name}</Typography>
   <DeleteIcon onClick={()=>handleDeleteGroup(each.id)}/>
   </Box>
  </AccordionSummary>
  <AccordionDetails>
  <ListItem key={each.id} style={{border:"1px solid #eee",minHeight:'300px',padding:"1%"}}>
    <Box sx={{width:"100%",display:"flex",flexDirection:"column"}}>
      <Box sx={{display:"flex",justifyContent:'space-between'}}>
        <Box sx={{width:'30%'}}>
    <TextField label="Group Name" color='warning' value={each.name}
         onChange={(e) => handleGroupNameChange(each.id, e.target.value)}/>
         {each.name===""? (<Typography sx={{color:"red"}}>*Enter the name</Typography>):(null)}
         </Box>
    <FormControlLabel control={<Checkbox  color='warning'/>} label="Preselct group" value={each.check}
         onChange={(e) => handleGroupCheckChange(each.id, (e.target as HTMLInputElement).checked)} />
    </Box>
    <TextField label="description" color='warning' multiline sx={{width:'70%',marginTop:"1%"}}  value={each.description}
        onChange={(e) => handleGroupDescriptionChange(each.id, e.target.value)}/>
        <Box sx={{display:'flex',alignItems:'center',width:"50%",justifyContent:'space-between'}}>
        <TextField label="Minimum Select" color='warning' value={each.minimumSelect}  sx={{width:'40%',marginTop:"2%"}}
       onChange={(e) => handleMinimumSelectChange(each.id, e.target.value)}/>
        <TextField label="Maximum Select" color='warning'value={each.maximumSelect}  sx={{width:'40%',marginTop:"2%"}}
        onChange={(e) => handleMaximumSelectChange(each.id, e.target.value)}/>
        </Box>
        <StrictModeDroppable droppableId={each.id} type="list" key={each.id}>
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ listStyle: 'none', padding: '0', width: '100%', minHeight:"300px",
               }}
            >
              {leftItems.length>0 ?(<Box>
              {leftItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                    key={item.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        margin: '8px',
                        padding: '8px',
                        borderRadius: '4px',
                       
                        ...provided.draggableProps.style,
                      }}
                    >
                     {item.group === each.name ? ( // Check if item.group is equal to each.name
  <Box>
   
      <Box sx={{ width: "100%" }}>
        <Box sx={{display:"flex",alignItems:'center',justifyContent:"space-between"}}>
          <Box style={{ display: "flex", alignItems: "center" ,width:"20%"}}>
            
            <img src={image} alt="add" className='create-bundle-button-image' />
            <Typography className='create-bundle-text-head'>{item.content}</Typography>
            </Box>
            <Box sx={{marginRight:"20%"}}>
            {!(item.variations.length>0) ?( <TextField
                    label="Additional Amount"
                    color='warning'
                    onChange={(e) => UpdateAddPrice2("", item.id, e.target.value)}
                  />):(null)}
         </Box>
         <Box>
          <IconButton onClick={() => deleteItemInGroup(item.id, item.group)}>
            <CloseIcon />
          </IconButton>
        </Box>
        </Box>
        <Divider sx={{ width: "100%" }} />

        <List>
          {item.variations.map((eachVariation, index1) => (
            <ListItem key={eachVariation.id} sx={{ paddingLeft: "0px" }}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                  <Typography className='create-bundle-button-variation-text'>{eachVariation.variation}</Typography>
                  <TextField
                    label="Additional Amount"
                    color='warning'
                    onChange={(e) => UpdateAddPrice(eachVariation.id, item.id, e.target.value)}
                  />
                  <IconButton onClick={() => deleteVariation(item.id, item.group, eachVariation.id)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ width: "100%" }} />
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
      
  </Box>
) : (null
//   <Box>
//     {(item.group===each.name) ? (
//   leftItems.filter((value1) => value1.group === each.name).length > 0 ? (
//     null
//   ) : (
//     <Typography>Bye</Typography>
//   )
// ) : (
//   null
// )}

  
// </Box>
  // <Box sx={{textAlign:'center'}}>
  // <img src={menuImage} alt="bundle"/>

  // <Typography className='create-bundle-image-description'>Bundle group customisation: Drag-and-Drop Products here to add to this group</Typography>
  // </Box>// Display 'Hi' if the condition is not met
)}

                        
                      
                     
                    </li>
                  )}
                </Draggable>
              ))}
              </Box>):(
              <Box sx={{textAlign:'center'}}>
                <img src={menuImage} alt="bundle"/>

                <Typography className='create-bundle-image-description'>Bundle group customisation: Drag-and-Drop Products here to add to this group</Typography>
                </Box>
                )}
              {provided.placeholder}
            </ul>
          )}
        </StrictModeDroppable>
        </Box>
        </ListItem>
        </AccordionDetails>
      </Accordion>
        ))}
        </List>
        </Box>
       
        
       
      </DragDropContext>
      
  );
};

export default BundleDragDrop;
