import { Box, Typography,Autocomplete,TextField, Breadcrumbs,Modal,Button,Popover, IconButton,List,ListItem,ListItemText,Divider } from '@mui/material'
import React from 'react'
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from "@mui/icons-material/Close"
import image from "../../assets/devicesetupsecondPopup.png"
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from "@mui/material/Tooltip";




const locationsList=["location1","location2","location3"]



  const rows=[
    {
        id:1,
        branchName:"Madhapur",
        
        pairedDevices:"3 Pos devices Paired"
    },
    {
        id:2,
        branchName:"Madhapur",
        pairedDevices:"3 Pos devices Paired"
    },
    {
        id:3,
        branchName:"Madhapur",
        pairedDevices:"3 Pos devices Paired"
    },
    {
        id:4,
        branchName:"Madhapur",
        pairedDevices:"3 Pos devices Paired"
    },
  ]

  const firstPopupStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 940,
    height:450,
    bgcolor: 'background.paper',
    borderRadius:"5px",
    boxShadow: 24,
    p: 4,
  };

  const secondPopupStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 940,
    height:600,
    bgcolor: 'background.paper',
    borderRadius:"5px",
    boxShadow: 24,
    p: 4,
  };

function PosSetup() {
    const [value, setValue] = React.useState('1');
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const PopoverOpen = Boolean(anchorEl);
   // first popup
    const [firstPopUpOpen, setFirstPopUpOpen] = React.useState(false);
    const handleFirstPopUpOpen = () => setFirstPopUpOpen(true);
    const handleFirstPopUpClose = () => setFirstPopUpOpen(false);
    //second popup
    const [secondPopUpOpen, setSecondPopUpOpen] = React.useState(false);
    const handleSecondPopUpOpen = () => setSecondPopUpOpen(true);
    const handleSecondPopUpClose = () => setSecondPopUpOpen(false);
    //code functions
    const [isTrue, setIsTrue] = React.useState(false);
    
    
    
    const handleCopyIcon=()=>{
      setIsTrue(!isTrue)
      if(isTrue){
        const inputCode="1234 1234 1234"
        navigator.clipboard.writeText(inputCode)
      }
    }

    const id = PopoverOpen ? 'simple-popover' : undefined;

    const firstPopupBreadcrumbs = [
        <Typography className='device-set-up-first-pop-up-header-bredcrump1' key="1">Device Details</Typography>,
        <Typography className='device-set-up-first-pop-up-header-bredcrump2' key="2">Generate Device Code</Typography>   
    ];

    const secondPopupBreadcrumbs = [
      <Typography className='device-set-up-first-pop-up-header-bredcrump2' key="1">Device Details</Typography>,
      <Typography className='device-set-up-first-pop-up-header-bredcrump1' key="2">Generate Device Code</Typography>   
  ];
    const columns: GridColDef[] = [
        {
          field: 'branchName',
          headerClassName:"device-set-up-table-header",
          headerName: 'Branch Name',
          flex:1
        },
        {
          field: 'pairedDevices',
          headerClassName:"device-set-up-table-header",
          headerName: 'Paired Devices',
          width:300
        },{
         field:"",
         disableColumnMenu:true,
          width:150,
          renderCell:(params)=>(
            <Box>
                <IconButton onClick={handleClick} >
                    <EditIcon/>
                </IconButton>
                <Popover
              id={id}
              open={PopoverOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              style={{
                width: "180px",
                height: "225px",
                paddingTop: "0px",
                paddingBottom: "0px"
              }}
              elevation={8}
            >
              {popoverContent}
            </Popover>
            </Box>
          )
        },
       
       
      ];




    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      };

      const getRowSize=()=>{
        return "device-set-up-table-row device-set-up-table-row-hover"
      }
      
      const combineFirstPopAndSecoundPopup=()=>{
        setFirstPopUpOpen(false)
        setSecondPopUpOpen(true)
      }

      const handleSecondAndFirstPopup=()=>{
        setSecondPopUpOpen(false)
        setFirstPopUpOpen(true)
      }

      const popoverContent = (
        <List style={{ padding: '0px 0px 0px 0px' }}>
          <Button style={{ textTransform:"capitalize",padding: '0px 0px 0px 0px' }}>
            <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
              <ListItemText className='device-set-up-table-row-hover' primary="Edit" />
            </ListItem>
          </Button>
          <Divider />
          <Button style={{textTransform:"capitalize", padding: '0px 0px 0px 0px' }}>
            <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
              <ListItemText className='device-set-up-table-row-hover' primary="Delete" />
            </ListItem>
          </Button>
        
          
        </List>
      );
  return (
    <Box>
         <Modal
            open={firstPopUpOpen}
            onClose={handleFirstPopUpClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={firstPopupStyle}>
             <Box sx={{display:"flex",justifyContent:"space-between",width:"65%"}}>
             <IconButton sx={{ marginRight: "50px" }} onClick={handleFirstPopUpClose} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                    <CloseIcon />
             </IconButton>
             <Box sx={{textAlign:"center"}}>
                <Typography className='device-set-up-first-pop-up-header'>New Pos Set Up</Typography>
                <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {firstPopupBreadcrumbs}
                    </Breadcrumbs>                   
             </Box>
             </Box>
             <Divider className="divider" sx={{marginBottom:"15px"}}/>
             <Box sx={{display:"flex",justifyContent:"space-around",alignItems:"center",marginBottom:"20px"}}>
             <TextField    
                label="Enter Device Name"
                sx={{width:"40%",borderColor:"warning"}}
                color='warning'       
              />
              <Autocomplete       
                id="combo-box-demo"
                options={locationsList}
              
                sx={{ width: "40%" }}
                renderInput={(params) => <TextField color='warning' {...params} label={<Typography className='device-set-up-header-drop-down-label'>Select Location</Typography>}/>}
                />
             </Box>
             <Box sx={{height:200,display:"flex",justifyContent:"flex-end",alignItems:"flex-end",marginTop:"20px"}}>
              <Button onClick={combineFirstPopAndSecoundPopup} className='device-set-up-first-pop-up-next-button' variant='contained' color='warning'>Next <NavigateNextIcon/></Button>
             </Box>
            </Box>
        </Modal>

        
        <Modal
            open={secondPopUpOpen}
            onClose={handleSecondPopUpClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={secondPopupStyle}>
             <Box sx={{display:"flex",justifyContent:"space-between",width:"65%"}}>
             <IconButton sx={{ marginRight: "50px" }} onClick={handleSecondAndFirstPopup} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                    <CloseIcon />
             </IconButton>
             <Box sx={{textAlign:"center"}}>
                <Typography className='device-set-up-first-pop-up-header'>New Pos Set Up</Typography>
                <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {secondPopupBreadcrumbs}
                    </Breadcrumbs>                   
             </Box>
             </Box>
             <Divider className="divider" sx={{marginBottom:"15px"}}/>
             <Box sx={{display:"flex",justifyContent:"space-around",alignItems:"center"}}>
              <Box sx={{width:"40%"}}>
                <Typography className='device-set-up-second-pop-up-device-text'>Device Created</Typography>
                <Typography className='device-set-up-second-pop-up-signin-text'>Sign in with your device code.</Typography>
                <Typography className='device-set-up-second-pop-up-install-text'>1.Install EtM Kitchen Display</Typography>
                <Typography className='device-set-up-second-pop-up-install2-text'>Install EtM Kitchen Display fom the app store to your device.</Typography>
                <Typography className='device-set-up-second-pop-up-install-text'>2.Sign in</Typography>
                <Typography className='device-set-up-second-pop-up-install2-text'>Use this device code to sign in to ETM Kitchen Display.</Typography>
              </Box>
              <Box>
                <img src={image} alt="kitchen"/>
                
                <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",marginTop:"10px",marginBottom:"5px"}} className="device-set-up-second-pop-up-code-bg">
                <Typography className='device-set-up-second-pop-up-code-text'>1234 1234 1234 </Typography>
                
                <Tooltip title={isTrue ? "Copied" : "Copy"} arrow>
                <IconButton onClick={handleCopyIcon}><FileCopyIcon sx={{marginLeft:"10px"}}/></IconButton>
                </Tooltip>

                

                </Box>
                <Typography className='device-set-up-second-pop-up-device-code-text'>Device Code</Typography>
              </Box>
              
                
              
             </Box>
             
             <Box sx={{height:65,display:"flex",justifyContent:"flex-end",alignItems:"flex-end",marginTop:"20px"}}>
              <Button onClick={()=>setSecondPopUpOpen(false)} className='device-set-up-first-pop-up-next-button' variant='contained' color='warning'>Done</Button>
             </Box>
            </Box>
        </Modal>

        <Box>
                    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <Autocomplete
                    
                    id="combo-box-demo"
                    options={locationsList}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} color='warning' label={<Typography className='device-set-up-header-drop-down-label'>Select Location</Typography>}/>}
                    />
                    <Button onClick={handleFirstPopUpOpen} className='device-set-up-header-button'  variant='outlined' color='warning'>Pair A New Pos Device</Button>
                    </Box>
                    <Box sx={{ height: 400, width: '100%',marginTop:"15px" }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        }}
                        pageSizeOptions={[5]}
                        getRowClassName={getRowSize}
                        disableRowSelectionOnClick
                    />
                    </Box>
                </Box>

        
    </Box>
   
  )
}

export default PosSetup
