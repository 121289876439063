import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import './createCategory.css'
import { useEffect } from 'react';
import { t } from 'i18next';
import { Divider } from 'primereact/divider';


interface Props{
    id:string;
    deletemodal:boolean;
    handleDeleteModal:()=>void;
    handleDleteByPopover:(id:string)=>void;
    handleDeleteClick:()=>void;
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
 
  boxShadow: 24,
  p: 4,
};

export default function DeleteDailouge(props:Props) {
    const {deletemodal,handleDeleteModal,handleDleteByPopover,id,handleDeleteClick}=props
   
  
  const [open, setOpen] = React.useState(deletemodal);

  
  const handleClose = () => {
  setOpen(false)
  handleDeleteModal()
  };

  const handleDelete=()=>{
    
    handleDleteByPopover(id)
    handleDeleteClick()
  }
  

 useEffect(()=>{

    setOpen(deletemodal)

   
 },[deletemodal])

//  const handleOkayButton=()=>{
//     handleStepByProductModal()
//  }
  return (
    <div>
  
    <Modal
      open={open}
     
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Box sx={{display:"flex",alignItems:"center",width:"60%",justifyContent:"space-between"}}>
       <Box className="create-product-exit" sx={{display:"flex",justifyContent:"center",alignItems:"center"}} onClick={handleClose}>
    <CloseIcon className="create-product-exitIcon"  />
    
  </Box> 
  <Typography className='category-created'>Delete Category</Typography>
      
      </Box>
      <Divider/>
      <Box sx={{padding:"3% 1% 3% 1%",marginTop:'1%',textAlign:'center'}}>

    
      <Typography className='category-created'>

      Deleting it may affect reports and printer routing settings. This action can’t be undone.</Typography>
</Box>
<Divider/>
<Box sx={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"2%"}}>
 
<Button className='create_category_modal_delete_button1' sx={{marginLeft:"3%",textTransform:"none",backgroundColor:"#F5431C"}} onClick={()=>{handleClose();handleDelete()}}>Delete Category</Button>
</Box>
      </Box>
    </Modal>
  </div>
  );
}










