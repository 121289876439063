import React, { useEffect, useState } from 'react'
import { Typography,Popover, Paper, Button, Box, IconButton,List, ListItem, ListItemText,Divider } from '@mui/material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useQuery,useMutation } from '@apollo/client';
import { User_Roles } from '../../graphicalQl/usequery/userProfileUseQuery';
import CircularLoader from '../../loader/loader';
import { DELETE_ROLE } from '../../graphicalQl/mutation/userProfileMutation';


import "./userRole.css"

interface UserRow {
  role: string;
  access: string;
  user: number;
  noOfUsers:number
}









const UserRole = () => {
  const navigate = useNavigate()

  const [userRoleList, setUserRoleList] = useState<UserRow[]>([]);
  const [profileId, setProfileId] = React.useState<"" | string>("")
  const {data,loading,error}=useQuery(User_Roles,{context:{clientName:"userClient"}})
  if(error){
    console.log(error.message)
  }


  //delete role mutation

  const [deleteRole] = useMutation(DELETE_ROLE, {
    onCompleted: (data) => {
      console.log(data)
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: 'userClient' },
    refetchQueries:[User_Roles]
  });
  

  //poopover functions
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const PopoverOpen = Boolean(anchorEl);
  const id = PopoverOpen ? 'simple-popover' : undefined;
  const handleThreedotsClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setProfileId(id)
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  //Edit User Functions

  const handleEditUserButton=()=>{
    navigate(`/create-role/${profileId}`)

  }

  const handleAddUserButton=()=>{
    navigate('/create-role')

  }


  const columns: GridColDef[] = [
    {
      field: "role",
      //@ts-ignore
      headerName: t("create_user.roles"),
      headerClassName: "user-role-header-names",
      flex:1
  
    },
    {
      field: "access",
      //@ts-ignore
      headerName: t("create_user.access"),
      headerClassName: "user-role-header-names",
      flex:1
  
    },
    {
      field: "user",
      //@ts-ignore
      headerName: t("create_user.users"),
      headerClassName: "user-role-header-names",
      width:180,
      renderCell: (params) => (
        <div className='user-roles-header-hover' style={{ display: 'flex', alignItems: 'center', height: "32px" }}>
          {params.value.toString().length===1?`0${params.value}`:params.value}        
        </div>
      ),
    
  
    },

    {
      field: "",
      disableColumnMenu:true,
      //@ts-ignore
      headerClassName: "user-role-header-names",
      
      renderCell: (params) => (
        <div className='user-roles-header-hover' style={{ display: 'flex', alignItems: 'center', height: "32px" }}>
          
          <IconButton onClick={(event) => handleThreedotsClick(event, params.row.id)} sx={{marginLeft:"20px"}}>
          <MoreVertOutlinedIcon />
          </IconButton>
          <Popover
            id={id}
            open={PopoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            style={{
              width: "180px",
              height: "225px",
              paddingTop: "0px",
              paddingBottom: "0px"
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>
          
        </div>
      ),
    }
  
  ];

  useEffect(() => {
    if (data) {
      const requiredDataList = data.roles.edges.map((each: any) => ({
        id:each.node.roleId,
        role:each.node.roleName,
        access:each.node.description,
        user:each.node.noOfUsers
      }

      ))
      setUserRoleList(requiredDataList)
    }
  }, [data]);

  if (loading) return <CircularLoader />;
  if (error) return <p>Error : {error.message}</p>;


 const handlePoppoverDelete=()=>{
  
  deleteRole({ variables: {roleId:profileId}})
 }

  const getRowClassNames = () => {
    return 'user-role-user-names'; // Apply the CSS class to all rows
  };

  const getRowHeight = () => {
    return 43 // Apply height to the table Row
  }

  const popoverContent = (
    <List style={{ padding: '0px 0px 0px 0px' }}>
      <Button style={{ textTransform: "capitalize", padding: '0px 0px 0px 0px' }}>
        <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
          <ListItemText primary="Duplicate" />
        </ListItem>
      </Button>
      <Divider />
      <Button onClick={handleEditUserButton} style={{ textTransform: "capitalize", padding: '0px 0px 0px 0px' }}>
        <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
          <ListItemText  primary="Edit" />
        </ListItem>
      </Button>
      <Divider />
      <Button onClick={handlePoppoverDelete} style={{ textTransform: "capitalize", padding: '0px 0px 0px 0px' }}>
        <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
          <ListItemText primary="Delete" />
        </ListItem>
      </Button>
    </List>
  );


  return (
    <div style={{padding:"20px",backgroundColor:"#f9f9f9"}}>
      <Box>
        <div className='user-role-header-order'>
          <Typography className='user-role-title' >{t("create_a_user.user_role")}</Typography>
          <Button sx={{textTransform:"none"}} onClick={handleAddUserButton} color='warning' variant='contained'>{t("create_a_user.create_a_new_role")}</Button>
        </div>

        <Box sx={{ height: 400, width: '100%', padding: "10px",marginTop:"15px" }}>
          <DataGrid
            rows={userRoleList}
            columns={columns}
            getRowClassName={getRowClassNames}
            getRowHeight={getRowHeight}

            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]} />

        </Box>
      </Box>

    </div>
  )
}

export default UserRole
