import { Box, Typography,Autocomplete,TextField, Breadcrumbs,Modal,Button,Popover, IconButton,List,ListItem,ListItemText,Divider } from '@mui/material'
import React from 'react'
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from "@mui/icons-material/Close"
import image from "../../assets/devicesetupsecondPopup.png"
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from "@mui/material/Tooltip";
import {t} from "i18next"
import "./kdsSetup.css"

const locationsList=["location4","location5","location3"]


const deletePopupStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  borderRadius:"10px",
  boxShadow: 24,
  p: 4,
};

  const initialRows=[
    {
        id:1,
        branchName:"Madhapur1",
        
        pairedDevices:"3 KDS devices Paired"
    },
    {
        id:2,
        branchName:"Madhapur2",
        pairedDevices:"3 KDS devices Paired"
    },
    {
        id:3,
        branchName:"Madhapur3",
        pairedDevices:"3 KDS devices Paired"
    },
    {
        id:4,
        branchName:"Madhapur4",
        pairedDevices:"3 KDS devices Paired"
    },
  ]

  const firstPopupStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 940,
    height:450,
    bgcolor: 'background.paper',
    borderRadius:"5px",
    boxShadow: 24,
    p: 4,
  };

  const secondPopupStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 940,
    height:600,
    bgcolor: 'background.paper',
    borderRadius:"5px",
    boxShadow: 24,
    p: 4,
  };

function KdsSetup() {
    const [rows,setRows]=React.useState(initialRows)
    const [value, setValue] = React.useState('1');
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const PopoverOpen = Boolean(anchorEl);
   // first popup
    const [firstPopUpOpen, setFirstPopUpOpen] = React.useState(false);
    const handleFirstPopUpOpen = () => setFirstPopUpOpen(true);
    const handleFirstPopUpClose = () => setFirstPopUpOpen(false);
    //second popup
    const [secondPopUpOpen, setSecondPopUpOpen] = React.useState(false);
    const handleSecondPopUpOpen = () => setSecondPopUpOpen(true);
    const handleSecondPopUpClose = () => setSecondPopUpOpen(false);
    //code functions
    const [isTrue, setIsTrue] = React.useState(false);
    const handleCopyIcon=()=>{
      setIsTrue(!isTrue)
      if(isTrue){
        const inputCode="1234 1234 1234"
        navigator.clipboard.writeText(inputCode)
      }
    }

    //Deletetext functions

    const [editAnddeleteId,setEditAndDeleteId]=React.useState(0)
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const handleDeleteOpen = () => setDeleteOpen(true);
    const handleDeleteClose = () => setDeleteOpen(false);

    const id = PopoverOpen ? 'simple-popover' : undefined;
  

    const firstPopupBreadcrumbs = [
        <Typography className='device-set-up-first-pop-up-header-bredcrump1' key="1">{t("kds_setup.device_details")}</Typography>,
        <Typography className='device-set-up-first-pop-up-header-bredcrump2' key="2">{t("kds_setup.generate_device_code")}</Typography>   
    ];

    const secondPopupBreadcrumbs = [
      <Typography className='device-set-up-first-pop-up-header-bredcrump2' key="1">{t("kds_setup.device_details")}</Typography>,
      <Typography className='device-set-up-first-pop-up-header-bredcrump1' key="2">{t("kds_setup.generate_device_code")}</Typography>   
  ];
    const columns: GridColDef[] = [
        {
          field: 'branchName',
          headerClassName:"device-set-up-table-header",
          //@ts-ignore
          headerName: t("kds_setup.branch_name"),
          flex:1
        },
        {
          field: 'pairedDevices',
          headerClassName:"device-set-up-table-header",
          //@ts-ignore
          headerName: t("kds_setup.paired_devices"),
          width:300
        },
        {
         field:"",
         disableColumnMenu:true,
          width:150,

          renderCell:(params)=>{
            const popoverContent = (
              <List style={{ padding: '0px 0px 0px 0px' }}>
                <Button style={{ textTransform:"capitalize",padding: '0px 0px 0px 0px' }}>
                  <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
                    <ListItemText className='device-set-up-table-row-hover' primary={t("kds_setup.edit")} />
                  </ListItem>
                </Button>
                <Divider />           
                    <Button onClick={handleDeleteText} style={{textTransform:"capitalize", padding: '0px 0px 0px 0px' }}>
                  <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
                    <ListItemText className='device-set-up-table-row-hover' primary={t("kds_setup.delete")} />
                  </ListItem>
                </Button>
                
              
              </List>
            );
            return(
              <Box>
              <IconButton onClick={(event)=>handleClick(event,params.row.id)} >
                  <EditIcon/>
              </IconButton>
              <Popover
            id={id}
            open={PopoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            style={{
              width: "180px",
              height: "225px",
              paddingTop: "0px",
              paddingBottom: "0px"
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>
          </Box>
            )
          }
           
          
        },
       
       
      ];




    const handleClick = (event: React.MouseEvent<HTMLButtonElement>,id:number) => {
      setAnchorEl(event.currentTarget);
      setEditAndDeleteId(id)
    };
    
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      };

      const getRowSize=()=>{
        return "device-set-up-table-row device-set-up-table-row-hover"
      }
      
      const combineFirstPopAndSecoundPopup=()=>{
        setFirstPopUpOpen(false)
        setSecondPopUpOpen(true)
      }

      const handleSecondAndFirstPopup=()=>{
        setSecondPopUpOpen(false)
        setFirstPopUpOpen(true)
      }

      const handleDeleteText=()=>{
        setDeleteOpen(true)         
      }
      
      const handleDeletePopupNoButton=()=>{
        setEditAndDeleteId(0)
        setDeleteOpen(false)
      }

      const handleDeletePopupYesButton=()=>{
        const filteredRows=rows.filter(each=>each.id!==editAnddeleteId)
        setRows(filteredRows)
        setEditAndDeleteId(0)
        setDeleteOpen(false)
        setAnchorEl(null);


        
      }

      
  return (
    <Box>
        <Modal
          open={deleteOpen}
          onClose={handleDeleteClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={deletePopupStyle}>
          <Box sx={{width:"60%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
          <IconButton sx={{ marginRight: "50px" }} onClick={handleDeleteClose} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                    <CloseIcon />
            </IconButton>
            <Typography className='device-set-up-delete-popup-header'>{t("kds_setup.deactivate_kds_2")}</Typography>
          </Box>
          <Divider sx={{marginTop:"15px",marginBottom:"15px"}}/>
          <Typography sx={{margin:"20px"}} className='device-set-up-delete-popup-description'>{t("kds_setup.are_you_sure")}</Typography>
          <Box sx={{display:"flex",justifyContent:"space-around",alignItems:"center",marginTop:"30px"}}>
            <Button onClick={handleDeletePopupYesButton} className='device-set-up-delete-popup-yes-button' variant='outlined'>{t("kds_setup.yes_deactivate_kds")}</Button>
            <Button onClick={handleDeletePopupNoButton} className='device-set-up-delete-popup-no-button' variant='contained'>{t("kds_setup.no_i_dont_want_to")}</Button>
          </Box>
          </Box>
        </Modal>
         <Modal
            open={firstPopUpOpen}
            onClose={handleFirstPopUpClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={firstPopupStyle}>
             <Box sx={{display:"flex",justifyContent:"space-between",width:"65%"}}>
             <IconButton sx={{ marginRight: "50px" }} onClick={handleFirstPopUpClose} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                    <CloseIcon />
             </IconButton>
             <Box sx={{textAlign:"center"}}>
                <Typography className='device-set-up-first-pop-up-header'>{t("kds_setup.new_kds_setup")}</Typography>
                <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {firstPopupBreadcrumbs}
                    </Breadcrumbs>                   
             </Box>
             </Box>
             <Divider className="divider" sx={{marginBottom:"15px"}}/>
             <Box sx={{display:"flex",justifyContent:"space-around",alignItems:"center",marginBottom:"20px"}}>
             <TextField    
                label="Enter Device Name"
                sx={{width:"40%",borderColor:"warning"}}
                color='warning'       
              />
              <Autocomplete       
                id="combo-box-demo"
                options={locationsList}
              
                sx={{ width: "40%" }}
                renderInput={(params) => <TextField color='warning' {...params} label={<Typography className='device-set-up-header-drop-down-label'>{t("kds_setup.select_location")}</Typography>}/>}
                />
             </Box>
             <Box sx={{height:200,display:"flex",justifyContent:"flex-end",alignItems:"flex-end",marginTop:"20px"}}>
              <Button onClick={combineFirstPopAndSecoundPopup} className='device-set-up-first-pop-up-next-button' variant='contained' color='warning'>{t("kds_setup.next")} <NavigateNextIcon/></Button>
             </Box>
            </Box>
        </Modal>

        
        <Modal
            open={secondPopUpOpen}
            onClose={handleSecondPopUpClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={secondPopupStyle}>
             <Box sx={{display:"flex",justifyContent:"space-between",width:"65%"}}>
             <IconButton sx={{ marginRight: "50px" }} onClick={handleSecondAndFirstPopup} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                    <CloseIcon />
             </IconButton>
             <Box sx={{textAlign:"center"}}>
                <Typography className='device-set-up-first-pop-up-header'>{t("kds_setup.new_kds_setup")}</Typography>
                <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {secondPopupBreadcrumbs}
                    </Breadcrumbs>                   
             </Box>
             </Box>
             <Divider className="divider" sx={{marginBottom:"15px"}}/>
             <Box sx={{display:"flex",justifyContent:"space-around",alignItems:"center"}}>
              <Box sx={{width:"40%"}}>
                <Typography className='device-set-up-second-pop-up-device-text'>{t("kds_setup.device_created")}</Typography>
                <Typography className='device-set-up-second-pop-up-signin-text'>{t("kds_setup.sign_in_with_your_device_code")}</Typography>
                <Typography className='device-set-up-second-pop-up-install-text'>{t("kds_setup.install_etm")}</Typography>
                <Typography className='device-set-up-second-pop-up-install2-text'>{t("kds_setup.install_etm_display_from_app")}</Typography>
                <Typography className='device-set-up-second-pop-up-install-text'>{t("kds_setup.sign_in")}</Typography>
                <Typography className='device-set-up-second-pop-up-install2-text'>{t("kds_setup.use_this_device_code")}</Typography>
              </Box>
              <Box>
                <img src={image} alt="kitchen"/>
                
                <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",marginTop:"10px",marginBottom:"5px"}} className="device-set-up-second-pop-up-code-bg">
                <Typography className='device-set-up-second-pop-up-code-text'>1234 1234 1234 </Typography>
                
                <Tooltip title={isTrue ? <Typography>{t("kds_setup.copied")}</Typography> : <Typography>{t("kds_setup.copy")}</Typography>} arrow>
                <IconButton onClick={handleCopyIcon}><FileCopyIcon sx={{marginLeft:"10px"}}/></IconButton>
                </Tooltip>

                

                </Box>
                <Typography className='device-set-up-second-pop-up-device-code-text'>{t("kds_setup.device_code")}</Typography>
              </Box>
              
                
              
             </Box>
             
             <Box sx={{height:65,display:"flex",justifyContent:"flex-end",alignItems:"flex-end",marginTop:"20px"}}>
              <Button onClick={()=>setSecondPopUpOpen(false)} className='device-set-up-first-pop-up-next-button' variant='contained' color='warning'>{t("kds_setup.done")}</Button>
             </Box>
            </Box>
        </Modal>
        <Box>
                    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <Autocomplete
                    
                    id="combo-box-demo"
                    options={locationsList}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField color='warning' {...params} label={<Typography className='device-set-up-header-drop-down-label'>{t("kds_setup.select_location")}</Typography>}/>}
                    />
                    <Button onClick={handleFirstPopUpOpen} className='device-set-up-header-button'  variant='outlined' color='warning'>{t("kds_setup.pair_a_new_kds_device")}</Button>
                    </Box>
                    <Box sx={{ height: 400, width: '100%',marginTop:"15px" }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        }}
                        pageSizeOptions={[5]}
                        getRowClassName={getRowSize}
                        disableRowSelectionOnClick
                    />
                    </Box>
                </Box>

        
    </Box>
    
  )
}

export default KdsSetup
