import React, { useState } from 'react';
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../utils/strictModeDroppable.tsx";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./assignOptionsToProducts.css"
import { Box, IconButton } from '@mui/material';
import image from '../../assets/prouctAddImage.png'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { CloseOutlined, } from '@mui/icons-material';
import image2 from '../../assets/menuSetup.png'

import DragHandleIcon from '@mui/icons-material/DragHandle';

import { Checkbox, FormGroup, FormControlLabel, Paper, TableHead, TableBody, Table, TableContainer, Autocomplete, TableRow, TextField, TableCell, Button } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

import InputBase from '@mui/material/InputBase';


import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

import { Divider } from 'primereact/divider';

interface MenuItem {
    optionGroupId: string
    optionGroupName: string
    optionName: string
    items: {}[]
    optionValues: {}[]


}

interface DragResult {
    source: {
        droppableId: string;
        index: number;
    };
    destination: {
        droppableId: string;
        index: number;
    } | null;
}
interface Props { }



const searchResults = [
    {
        id: "1", title: "amlapuram", isChecked: false
    },
    {
        id: "1", title: "amlapuram", isChecked: false
    },
    {
        id: "1", title: "amlapuram", isChecked: false
    },
    {
        id: "1", title: "amlapuram", isChecked: false
    },
    {
        id: "1", title: "amlapuram", isChecked: false
    },
    {
        id: "1", title: "amlapuram", isChecked: false
    },
    {
        id: "1", title: "amlapuram", isChecked: false
    },
    {
        id: "1", title: "amlapuram", isChecked: false
    }, {
        id: "1", title: "amlapuram", isChecked: false
    },
    {
        id: "1", title: "amlapuram", isChecked: false
    },

]

const initialOptionValues = [
    { id: 1, optionValue: "capsicum", price: "3.00", isChecked: "false" },
    { id: 2, optionValue: "corn", price: "4.00", isChecked: "false" },
    { id: 3, optionValue: "carrot", price: "5.00", isChecked: "false" }
]



const AssignOptionsToProducts: React.FC<Props> = () => {
    const [searchInput, setSearchInput] = useState("")
    const inputClasses = searchInput ? 'assign-options-to-products-search-label' : 'assign-options-to-products-search-label';






    const [leftItems, setLeftItems] = useState<MenuItem[]>([]);

    const menuItems = [...leftItems];
    const uniqueCoursesSet = new Set();
    menuItems.forEach(item => {
        uniqueCoursesSet.add(item.optionGroupName);
    });
    const uniqueCourses = Array.from(uniqueCoursesSet);

    const itemsList = [
        {
            optionGroupId: "1",
            optionGroupName: "Toppings",
            optionName: "Veg Toppings",
            items: [
                { id: "1", item: "italian pizza" },
                { id: "2", item: "japan pizza" },
                { id: "3", item: "chinese pizza" },
                { id: "4", item: "indian pizza" }

            ],
            optionValues: [
                {
                    id: "1",
                    option: "Capsicum",
                    optionPrice: "3.00",
                    isChecked: false
                },
                {
                    id: "2",
                    option: "corn",
                    optionPrice: "4.00",
                    isChecked: true
                },
                {
                    id: "3",
                    option: "tomato",
                    optionPrice: "5.00",
                    isChecked: false
                },
            ]
        },
        {
            optionGroupId: "2",
            optionGroupName: "Toppings",
            optionName: "Non Veg Toppings",
            items: [
                { id: "1", item: "italian pizza" },
                { id: "2", item: "japan pizza" },
                { id: "3", item: "chinese pizza" },
                { id: "4", item: "indian pizza" }

            ],
            optionValues: [
                {
                    id: "1",
                    option: "Potato",
                    optionPrice: "4.00",
                    isChecked: false
                },
                {
                    id: "2",
                    option: "tomato",
                    optionPrice: "4.00",
                    isChecked: false
                },
                {
                    id: "3",
                    option: "corn",
                    optionPrice: "4.00",
                    isChecked: false
                },
            ]
        },
        {
            optionGroupId: "3",
            optionGroupName: "Crust",
            optionName: "Veg Toppings",
            items: [
                { id: "1", item: "italian pizza" },
                { id: "2", item: "japan pizza" },
                { id: "3", item: "chinese pizza" },
                { id: "4", item: "indian pizza" }

            ],
            optionValues: [
                {
                    id: "1",
                    option: "tomato",
                    optionPrice: "5.00",
                    isChecked: false
                },
                {
                    id: "2",
                    option: "tomato",
                    optionPrice: "4.00",
                    isChecked: false
                },
                {
                    id: "3",
                    option: "corn",
                    optionPrice: "4.00",
                    isChecked: false
                }
            ]
        },
        {
            optionGroupId: "4",
            optionGroupName: "Modifiers",
            optionName: "Veg Toppings",
            items: [
                { id: "1", item: "italian pizza" },
                { id: "2", item: "japan pizza" },
                { id: "3", item: "chinese pizza" },
                { id: "4", item: "indian pizza" }

            ],
            optionValues: [
                {
                    id: "1",
                    option: "tomato",
                    optionPrice: "5.00",
                    isChecked: false
                },
                {
                    id: "2",
                    option: "tomato",
                    optionPrice: "4.00",
                    isChecked: false
                },
                {
                    id: "3",
                    option: "corn",
                    optionPrice: "4.00",
                    isChecked: false
                }
            ]
        },
        {
            optionGroupId: "5",
            optionGroupName: "Salad Dressings",
            optionName: "Veg Toppings",
            items: [
                { id: "1", item: "italian pizza" },
                { id: "2", item: "japan pizza" },
                { id: "3", item: "chinese pizza" },
                { id: "4", item: "indian pizza" }

            ],
            optionValues: [
                {
                    id: "1",
                    option: "tomato",
                    optionPrice: "5.00",
                    isChecked: false
                },
                {
                    id: "2",
                    option: "tomato",
                    optionPrice: "4.00",
                    isChecked: false
                },
                {
                    id: "3",
                    option: "corn",
                    optionPrice: "4.00",
                    isChecked: false
                }
            ]
        },
    ]

    const uniqueItemSet = new Set();


    itemsList.forEach(item => {
        uniqueItemSet.add(item.optionGroupName);
    });

    const uniqueItemsList = Array.from(uniqueItemSet);


    const [optionValues, setOptionValues] = React.useState(initialOptionValues)
    const [showCloseIcon, setShowCloseIcon] = React.useState(optionValues.map((each) => false))


    const handleMouseEnter = (index: number) => {
        const updatedShowData = [...optionValues.map((each) => false)];
        updatedShowData[index] = true;
        setShowCloseIcon(updatedShowData);
    }

    const handleMouseLeave = (index: number) => {
        const updatedShowData = [...showCloseIcon];
        updatedShowData[index] = false;
        setShowCloseIcon(updatedShowData);

    }

    const onDragEnd = (result: DropResult) => {


        if (!result.destination) {
            return;
        }

        const sourceListId = result.source.droppableId;
        const destinationListId = result.destination.droppableId;

        let sourceList: MenuItem[] = itemsList;

        // switch (sourceListId) {
        //   case 'pizza':
        //     sourceList = pizza;
        //     break;
        //   case 'biryani':
        //     sourceList = biryani;
        //     break;
        //   // Add more cases for other lists as needed
        //   default:
        //     sourceList = [];
        //     break;
        // }

        const destinationList = leftItems;
        const draggedItem = sourceList[result.source.index];
        const copiedItem = { ...draggedItem, id: `${destinationListId}-${Date.now()}` };

        const newDestinationList = Array.from(destinationList);

        if (destinationListId === 'left' && sourceListId !== 'left') {
            newDestinationList.splice(result.destination.index, 0, copiedItem);
        } else if (destinationListId === 'left' && sourceListId === 'left') {

            const [removedItem] = newDestinationList.splice(result.source.index, 1);
            newDestinationList.splice(result.destination.index, 0, removedItem);
        }

        setLeftItems(newDestinationList);
    };

    return (

        <Box>
            <Box className='create-a-use-role-header-order' sx={{ width: "100%", padding: "15px", backgroundColor: "#fff" }}>
                <IconButton className='create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover'>
                    <ArrowBackIcon
                        style={{ backgroundColor: "#EEEEEE", color: "#333333" }} />
                </IconButton>
                <Typography className='create-a-use-role-heading'>Assign Options to Italian Pizza</Typography>
                <Box className='create-a-use-role-header-buttons-order'>
                    <Button sx={{ textTransform: "none" }} className='create-a-use-role-clear-button' variant='outlined' color='warning'>Clear</Button>
                    <Button type='submit' sx={{ textTransform: "none" }} variant='contained' color='warning'>Save</Button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', width: "100%", marginTop: "15px", alignItems: "flex-start" }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Box sx={{ width: "75%" }}>
                        <StrictModeDroppable droppableId="left" type="list">
                            {(provided) => (
                                <ul
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{ listStyle: 'none', padding: '0' }}
                                >
                                    {leftItems.length > 0 ? (
                                        <>
                                            {uniqueCourses.map((item: any, index: number) => {
                                                const filteredItems = leftItems.filter((each) => each.optionGroupName === item);
                                                const uniquePizza: { [key: string]: boolean } = {};
                                                const deduplicatedPizza = filteredItems.filter(item => {
                                                    if (!uniquePizza[item.optionName]) {
                                                        uniquePizza[item.optionName] = true;
                                                        return true;
                                                    }
                                                    return false;
                                                });
                                                filteredItems.length = 0;
                                                Array.prototype.push.apply(filteredItems, deduplicatedPizza);

                                                const menuItems = [...filteredItems];

                                                const uniqueCategorySet = new Set();

                                                menuItems.forEach(item => {
                                                    uniqueCategorySet.add(item);
                                                });

                                                const uniqueCategory = Array.from(uniqueCategorySet);


                                                return (
                                                    <Box key={String(item)}>
                                                        <Draggable
                                                            draggableId={`left-${item}`}  // Use a unique identifier
                                                            index={index}
                                                        >
                                                            {(provided) => (
                                                                <li
                                                                    key={`left-${item}`}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={{
                                                                        margin: '8px',
                                                                        padding: '8px',
                                                                        borderRadius: '4px',
                                                                        ...provided.draggableProps.style,
                                                                    }}
                                                                >
                                                                    <Accordion key={index}>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls={`panel-${item}-content`}
                                                                            id={`panel-${item}-header`}
                                                                        >
                                                                            <Box>
                                                                                <Typography className='dragDrop'>{String(item)}</Typography>
                                                                            </Box>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            {uniqueCategory.map((filteredItem: any) => (
                                                                                <Accordion sx={{ margin: "10px",maxHeight:"55vh" }} key={String(filteredItem)}>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                    >
                                                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                                            <Box className="dragDropImgBackground">
                                                                                                <img src={image} alt='product' className='dragDropImg' />
                                                                                            </Box>
                                                                                            <Box sx={{ marginLeft: "10%" }}>
                                                                                                <Typography className='dragDrop-text'> {filteredItem.optionName}</Typography>

                                                                                            </Box>
                                                                                        </Box>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails>
                                                                                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start",zIndex:1 }}>
                                                                                            <Typography className='assign-options-to-products-veg-toppings-text'>{filteredItem.optionName}</Typography>

                                                                                            <Box sx={{zIndex:1}} >
                                                                                                <Accordion elevation={1} sx={{ width: "100%",zIndex:1 }}>
                                                                                                    <AccordionSummary
                                                                                                        sx={{ width: "90%", marginBottom: 0,zIndex:1 }}
                                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                                        aria-controls="panel1a-content"
                                                                                                        id="panel1a-header"

                                                                                                    >
                                                                                                        <Typography sx={{ marginBottom: "0px" }}> Locations</Typography>
                                                                                                    </AccordionSummary>
                                                                                                    <AccordionDetails sx={{zIndex:1}}>

                                                                                                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between",zIndex:1 }}>
                                                                                                            <Box
                                                                                                                sx={{
                                                                                                                    display: "flex",
                                                                                                                    justifyContent: "flex-start",
                                                                                                                    flexDirection: "column",
                                                                                                                    alignItems: "flex-start",
                                                                                                                    overflowY: "auto",
                                                                                                                    overflowX: "none",
                                                                                                                    minHeight: "10vh",
                                                                                                                    maxHeight: "32vh",
                                                                                                                    marginTop: "10px"

                                                                                                                }}
                                                                                                            >
                                                                                                                <FormGroup>
                                                                                                                    <FormControlLabel
                                                                                                                        control={
                                                                                                                            <Checkbox
                                                                                                                                //  checked={allCheckboxChecked}
                                                                                                                                //  onChange={(e) => handlePopupCheckbo(e)}
                                                                                                                                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                                                                                                                                color="warning"
                                                                                                                            />
                                                                                                                        }
                                                                                                                        label={
                                                                                                                            <Typography className="driver-petty-cash-popup-checkbox">
                                                                                                                                All
                                                                                                                            </Typography>
                                                                                                                        }
                                                                                                                    />
                                                                                                                    {searchResults.map((each) => (
                                                                                                                        <FormControlLabel
                                                                                                                            key={each.id}
                                                                                                                            control={
                                                                                                                                <Checkbox
                                                                                                                                    //  onChange={() => {
                                                                                                                                    //    handlePopupCheckbox(each.id, each.isChecked);
                                                                                                                                    //  }}
                                                                                                                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                                                                                                                                    color="warning"

                                                                                                                                />
                                                                                                                            }
                                                                                                                            label={
                                                                                                                                <Typography className="driver-petty-cash-popup-checkbox">
                                                                                                                                    {each.title}
                                                                                                                                </Typography>
                                                                                                                            }
                                                                                                                        />
                                                                                                                    ))}
                                                                                                                </FormGroup>
                                                                                                            </Box>
                                                                                                        </Box>
                                                                                                    </AccordionDetails>
                                                                                                </Accordion>
                                                                                            </Box>


                                                                                            <CloseIcon />
                                                                                        </Box>
                                                                                        <Box sx={{ margin: "10px" }}>
                                                                                            <TableContainer component={Paper}>
                                                                                                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                                                                                                    <TableHead>
                                                                                                        <TableRow>
                                                                                                            <TableCell sx={{ width: "1%" }} className='assign-options-to-products-veg-toppings-text'></TableCell>
                                                                                                            <TableCell sx={{ width: "25%" }} className='assign-options-to-products-veg-toppings-text' align="left">Option Values</TableCell>
                                                                                                            <TableCell sx={{ width: "25%" }} className='assign-options-to-products-veg-toppings-text' align="left">Price</TableCell>
                                                                                                            <TableCell sx={{ width: "25%" }} className='assign-options-to-products-veg-toppings-text' align="left">Mark as included</TableCell>
                                                                                                            <TableCell sx={{ width: "10%" }} className='assign-options-to-products-veg-toppings-text'></TableCell>
                                                                                                        </TableRow>
                                                                                                    </TableHead>
                                                                                                    <TableBody>
                                                                                                        {filteredItem.optionValues.map((row: any, index: number) => (
                                                                                                            <TableRow
                                                                                                                onMouseEnter={() => handleMouseEnter(index)}
                                                                                                                onMouseLeave={() => handleMouseLeave(index)}
                                                                                                                className='assign-options-to-products-table-row'
                                                                                                                key={row.id}
                                                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, padding: "0px" }}
                                                                                                            >
                                                                                                                <TableCell sx={{ padding: "0px 0px 0px 10px" }}>{showCloseIcon[index] && <DragHandleIcon />}</TableCell>
                                                                                                                <TableCell className='assign-options-to-products-table-row' sx={{ padding: "5px 0px 5px 16px" }} align="left" component="th" scope="row">
                                                                                                                    {row.option}
                                                                                                                </TableCell>
                                                                                                                <TableCell sx={{ padding: "5px 0px 5px 16px" }} className='assign-options-to-products-table-row' align="left">{row.optionPrice}</TableCell>
                                                                                                                <TableCell sx={{ padding: "5px 0px 5px 16px" }} align="left">
                                                                                                                    <Checkbox checked={row.isChecked} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} color='success' />
                                                                                                                </TableCell>
                                                                                                                <TableCell align='center'>{showCloseIcon[index] && <CloseIcon />}
                                                                                                                </TableCell>
                                                                                                            </TableRow>
                                                                                                        ))}
                                                                                                    </TableBody>
                                                                                                </Table>
                                                                                            </TableContainer>
                                                                                        </Box>



                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            ))}
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </li>
                                                            )}
                                                        </Draggable>
                                                    </Box>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </>
                                    ) : (
                                        <Box sx={{ width: "100%", textAlign: 'center' }}>
                                            <img src={image2} alt='menu' />
                                            <Typography className='dragDrop-text4'>Easy Menu Setup: Drag-and-Drop Categories, Bundles, Products here</Typography>
                                        </Box>
                                    )}
                                </ul>
                            )}
                        </StrictModeDroppable>
                    </Box>

                    <Box style={{ display: 'flex', width: "35%", margin: "10px", flexDirection: "column" }} >
                        <Box sx={{ padding: "10px 0px 0px 20px" }}>
                            <Typography className='assign-options-to-products-option-groups-text'>Option Groups</Typography>
                        </Box>

                        <Divider />
                        <Box sx={{ padding: "10px 0px 0px 20px" }}>
                            <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "80%" }}
                            >

                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    placeholder="Search by name. description or sku"
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                    className={inputClasses}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            </Paper>

                        </Box>
                        <Divider />
                        <StrictModeDroppable droppableId="pizza" type="list">
                            {(provided) => (
                                <ul
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{ listStyle: 'none', padding: '0', }}
                                >
                                    <Box>
                                        {uniqueItemsList.map((each) => (
                                            <Accordion sx={{ margin: "10px" }} key={String(each)}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className='assign-options-to-products-veg-toppings-text'>{String(each)}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {itemsList.map((item, index) => {
                                                        if (item.optionGroupName === each) {
                                                            return (
                                                                <Draggable draggableId={`pizza-${item.optionGroupId}`}  // Use a unique identifier
                                                                    index={index}
                                                                    key={`pizza-${item.optionGroupId}`}>
                                                                    {(provided) => (
                                                                        <Paper
                                                                            className='assign-options-to-products-option-name'
                                                                            key={item.optionGroupId}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            sx={{ margin: "10px", padding: "15px" }} elevation={1}
                                                                        >
                                                                            {item.optionName}
                                                                        </Paper>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </Box>
                                    {provided.placeholder}
                                </ul>
                            )}
                        </StrictModeDroppable>
                    </Box>


                </DragDropContext>
            </Box>

        </Box>

    )




}

export default AssignOptionsToProducts