import { Box,IconButton, Typography } from '@mui/material'
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel'
import React from 'react'
import PosSettingsGeneralSettings from './posSettingsGeneralSettings';
import LayoutOrTheme from './layoutOrTheme';
import TillSettings from './tillSettings';
import "./posSettings.css"

function PosSettings() {
    const [value, setValue] = React.useState('3');

   

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      };
  return (
    <Box>
        <Box sx={{display:"flex",alignItems:"center"}}>
            <IconButton sx={{marginRight:"20px"}}  className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
            < ArrowBackIcon/>
           </IconButton>
           <Box>
           <Typography className='pos-settings-header'>POS Settings</Typography>
           <Typography  className='pos-settings-king-field-text'>Kingfield Branch</Typography>
           </Box>
     
    
        </Box>
              <Box sx={{padding:"20px"}} className="overall-background">
              <Box>
                  <Typography className='device-set-up-header'>Device Listing</Typography>
              </Box>
              <Box sx={{ width: '100%', typography: 'body1',marginTop:"15px" }}>
              <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList sx={{backgroundColor:"#F9F9F9"}} className='background' onChange={handleChange}>
                      <Tab label={<Typography className={value==="1"?"device-set-up-tabs-header-orange":"device-set-up-tabs-header"}>General Settings</Typography>}  className={value==="1"?"change-color":"add-color"}  value="1" />
                      <Tab label={<Typography className={value==="2"?"device-set-up-tabs-header-orange":"device-set-up-tabs-header"}>Till Settings</Typography>}   className={value==="2"?"change-color":"add-color"} value="2" />
                      <Tab label={<Typography className={value==="3"?"device-set-up-tabs-header-orange":"device-set-up-tabs-header"}>Layout/Theme</Typography>} className={value==="3"?"change-color":"add-color"} value="3" />
                  </TabList>
                  </Box>
                  <TabPanel value="1">
                  <PosSettingsGeneralSettings/>
                  </TabPanel>
                  <TabPanel value="2">
                    <TillSettings/>
                  </TabPanel>
                  <TabPanel value="3">
                  <LayoutOrTheme/>
                   
                  </TabPanel>
              </TabContext>
              </Box>
              </Box>
    </Box>
  )
}

export default PosSettings
