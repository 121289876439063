import { Box,IconButton,Typography,Button,Autocomplete,TextField, Paper } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DataGrid, GridColDef} from '@mui/x-data-grid';
import "./stockAdjustments.css"
import { t } from 'i18next'
import { useState } from 'react';
import { useNavigate } from 'react-router';




const stockList=["Not received","Received"]

const columns: GridColDef[] = [
    
    {
      field: 'product',
      //@ts-ignore
      headerName: t("stock_adjustments.products"),
      headerClassName:"stock-adjustments-table-column",
      flex:1,
      editable: true,
    },
    {
      field: 'sku',
      //@ts-ignore
      headerName: t("stock_adjustments.sku"),
      headerClassName:"stock-adjustments-table-column",
      flex:1,
      editable: true,
    },
    {
      field: 'variation',
      //@ts-ignore
      headerName: t("stock_adjustments.variation"),
      headerClassName:"stock-adjustments-table-column",
      flex:1,
      editable: true,
    },
    {
      field: 'stockInHand',
      //@ts-ignore
      headerName: t("stock_adjustments.stock_in_hand"),
      headerClassName:"stock-adjustments-table-column",
      sortable: false,
      flex:1,
      
    },
    {
        field: 'Reason',
        //@ts-ignore
        headerName: t("stock_adjustments.reason"),
        headerClassName:"stock-adjustments-table-column",
        
        width: 300,
        editable: true,
        renderCell:(params)=>(
          <Autocomplete
            id="combo-box-demo"
            options={params.value}
            sx={{ width: 180 }}
            renderInput={(params) => <TextField {...params} label={stockList[0]} />}
/>
        )
      },
      {
        field: 'adjustments',
        //@ts-ignore
        headerName: t("stock_adjustments.adjustments"),
        headerClassName:"stock-adjustments-table-column",
        
        width: 180,
        editable: true,
      },
      {
        field: 'Barcode',
        //@ts-ignore
        headerName: t("stock_adjustments.barcode"),
        headerClassName:"stock-adjustments-table-column",
      
        width: 130,
        editable: true,
      },
  ];
  
  const rows = [
    { id: 1, product:"Burger", sku:"001",variation:"small",stockInHand:"24",Reason:["Not received","Received"],adjustments:"40",Barcode:"00110" },
    { id: 2,product:"Burger", sku:"001",variation:"small",stockInHand:"24",Reason:["Not received","Received"],adjustments:"40",Barcode:"00110" },
    { id: 3 ,product:"Burger", sku:"001",variation:"small",stockInHand:"24",Reason:["Not received","Received"],adjustments:"40",Barcode:"00110" },
    { id: 4,product:"Burger", sku:"001",variation:"small",stockInHand:"24",Reason:["Not received","Received"],adjustments:"40",Barcode:"00110" },
    { id: 5,product:"Burger", sku:"001",variation:"small",stockInHand:"24",Reason:["Not received","Received"],adjustments:"40",Barcode:"00110" },
    { id: 6,product:"Burger", sku:"001",variation:"small",stockInHand:"24",Reason:["Not received","Received"],adjustments:"40",Barcode:"00110" },
    { id: 7, product:"Burger",sku:"001",variation:"small",stockInHand:"24",Reason:["Not received","Received"],adjustments:"40",Barcode:"00110" },
    { id: 8,product:"Burger", sku:"001",variation:"small",stockInHand:"24",Reason:["Not received","Received"],adjustments:"40",Barcode:"00110" },
    { id: 9,product:"Burger", sku:"001",variation:"small",stockInHand:"24",Reason:["Not received","Received"],adjustments:"40",Barcode:"00110" },
    { id: 10,product:"Burger", sku:"001",variation:"small",stockInHand:"24",Reason:["Not received","Received"],adjustments:"40",Barcode:"00110" },
    { id: 11,product:"Burger", sku:"001",variation:"small",stockInHand:"24",Reason:["Not received","Received"],adjustments:"40",Barcode:"00110" }
  ];
  
  





function StockAdjustments() {

  const navigate=useNavigate()
  const [changesCount,setChangesCount]=useState(0)


  const rowFontSize=()=>{
    return "stock-adjustments-table-row"
  }
  const getHover = () => {
    return "inventory-list-table-row-hover"
} 


  return (
    <Box>
    
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <IconButton onClick={()=>{navigate("/inventoryLists")}} className='create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover'>
            <ArrowBackIcon/>
            </IconButton>    
            <Typography className='edit-inventory-header-name'>{t("stock_adjustments.stock_adjustments")}</Typography>
            <IconButton >
            <Button className='stock-adjustments-header-button' color='warning' variant='contained'>{t("stock_adjustments.save")} {changesCount} {t("stock_adjustments.variants")}</Button>

            </IconButton>
            
            
        </Box>
        <Paper sx={{marginTop:"15px"}}>
        <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 5,
                },
            },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
            getRowClassName={getHover}
            getCellClassName={rowFontSize}
        />
    </Box>
        </Paper>

        
    </Box>
  )
}

export default StockAdjustments
