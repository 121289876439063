import React,{ useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Typography,IconButton } from "@mui/material"
import './categoryListing.css'
import { DataGrid, GridColDef,GridRowSelectionModel } from '@mui/x-data-grid';
import { Delete, MoreVertSharp } from '@mui/icons-material';
import { Popover, List, ListItem, ListItemText ,Divider} from '@mui/material';
import { t } from 'i18next';
import { useQuery,useMutation, NetworkStatus } from '@apollo/client';
import CircularLoader from '../../loader/loader';
import {CATEGORY_LIST} from '../../graphicalQl/usequery/categoryListQuery'
import { Delete_Category } from '../../graphicalQl/mutation/mutation';
import AssignProducts from './assigngnProductsModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteDailouge from './deleteDailouge';

interface Row {
  id:number
  categoryId: string
  productCount: number
  slugName: string
  
}



interface Row2{
  node:{
  categoryId: string
  productCount: number
  slugName: string
  }
}




function CategoryListing() {

  const [categoryList,setCategoryList]=useState<Row2[]>([])
  
  
  const { loading, error, data } = useQuery(CATEGORY_LIST,{ context: { clientName:"categoryClient" }});
  

  const [deletemodal,setDeleteModal]=useState(false)

  const handleDeleteModal=()=>{
    setDeleteModal(!deletemodal)
  }

  
  useEffect(() => {
   
    if ( data) {
      setCategoryList(data.categoryList.edges)
    }
    
  }, [ data]);



  const rowStyle=()=>{
    return 'categories-row'
  }
 const navigate=useNavigate()
  
 const HandleNavigation=()=>{
  navigate('/create-category')
 }
 
 
 const rows:Row[] = categoryList.map((item,index) => ({
  id:index+1,
  categoryId: item.node.categoryId,
  slugName: item.node.slugName,
  productCount: item.node.productCount,
}));


  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
 
  const [deleteCategoryValue] = useMutation(Delete_Category, {
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
     
    },
    context: { clientName: "categoryClient" },
    refetchQueries: [
       // Specify the query using an object with a 'query' property
      CATEGORY_LIST ,
   ],
  });
  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state
   
    const updatedRows = rows.filter((row) => rowSelectionModel.includes(row.id));
   
    
    const categoryValue = updatedRows.map((each) => +each.categoryId);

    
    
      deleteCategoryValue({variables: {categoryIds: JSON.stringify(categoryValue)}})
      setRowSelectionModel([]);
       
    
     
        
    
 
}
const handleEdit=(id:string)=>{
  navigate(`/${id}/update-category`)
}

const handleDleteByPopover=(id:string)=>{

deleteCategoryValue({variables: {categoryIds: JSON.stringify([id])}})
  
}

const[categoryIdValue,setCategoryIdValue]=useState("")
const getCategoryId=(id:string)=>{
  setCategoryIdValue(id)
}


function CustomPopoverCell({ categoryId}: { categoryId: string}) {

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const navigate=useNavigate()
  const handleEdit=(id:string)=>{
    navigate(`/${id}/update-category`)
  }
  const open = Boolean(anchorEl);
  const id = open ? categoryId : "";

  const popoverContent = (
    <List style={{ padding: '0px 0px 0px 0px' }}>
      <Button style={{ padding: '0px 0px 0px 0px' ,textTransform:"none"}} onClick={()=>handleEdit(id)}>
        <ListItem style={{ width: '180px', height: '40px' }} className='catageory-pover-text-style'>
          <ListItemText primary={t("menu.catageory.edit")} />
        </ListItem>
      </Button>
      <Divider />
      <Button style={{ padding: '0px 0px 0px 0px' ,textTransform:"none"}} onClick={()=>handleDleteByPopover(id)}>
        <ListItem style={{ width: '180px', height: '40px' }} className='catageory-pover-text-style'>
          <ListItemText primary={t("menu.catageory.delete")} />
        </ListItem>
      </Button>
      <Divider />
      <Button style={{ padding: '0px 0px 0px 0px' ,textTransform:"none"}}>
        <ListItem style={{ width: '180px', height: '40px' }} className='catageory-pover-text-style'>
          <ListItemText primary="Duplicate"/>
        </ListItem>
      </Button>
    </List>
  );

 
  return (
    <Box>
      <div>
        <IconButton onClick={handleClick}>
          <MoreVertSharp />
        </IconButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{ width: '180px', height: '225px', paddingTop: '0px', paddingBottom: '0px' }}
          elevation={8}
        >
          {popoverContent}
        </Popover>
      </div>
    </Box>
  );
}


const [open, setOpen] = React.useState(false);
  const handleOpen = () => (setOpen(true));
    
  
  const handleClose = () => setOpen(false);

  const [categoryId,setCategoryId]=useState("")
  const handleCategoryId=(category:any)=>{
    setCategoryId(category)

  }
 

  
  
 
  const columns: GridColDef[] = [
 
    {
      field: 'slugName',
      //@ts-ignore
      headerName: t("menu.catageory.categories"),
      flex: 1,
      headerClassName:"categories-column-header",
      editable: false,
      
      renderCell:(params)=>{
  
  return(
    <Box>
    <Typography className='categories-table-text-head'>{params.row.slugName}</Typography>
    <Typography className='categories-table-text-para'>{params.row.productCount} {t("menu.catageory.products")}</Typography>
    </Box>
  )
      }
    },
    {
      field:"",
      headerName: "",
     width:400,
      headerClassName:"categories-column-header",
      editable: false,
      disableColumnMenu:true,
      renderCell:(params)=>{
        return(
          <Box>
          <Button className='categories-button2' onClick={() => {
  handleOpen();
  handleCategoryId(params.row.categoryId);
}}>
  {t("menu.catageory.assignProducts")}
</Button>

          </Box>
        )
            }
      
    },
    {
      field: 'product',
      headerName: '',
      width: 200,
      headerClassName: 'categories-column-header',
      editable: false,
      cellClassName:"tableCell",
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
        <>
          {/* <CustomPopoverCell
          categoryId={params.row.categoryId}
          
          /> */}
         <DeleteIcon onClick={() => {  handleDeleteModal();getCategoryId(params.row.categoryId) }} />

          <ContentCopyIcon/>
          <EditIcon onClick={()=>handleEdit(params.row.categoryId)}/>
          </>
        );
      
      },
    }
    
  ];

  
 
 if (loading) return <CircularLoader/>;
 if (error) return <p>Error : {error.message}</p>;
 
 
    return(
        <Box sx={{padding:"1%"}}>
            <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between "}}>
            <Box>
            <Typography className="categories-head">{t("menu.catageory.categories")}</Typography>
            <Typography className="categories-para">{t("menu.catageory.categoriesPara")}</Typography>
            </Box>
            <Box sx={{display:"flex"}}>
            <Button className="categories-button" onClick={HandleNavigation} sx={{textTransform:'none'}}> {t("menu.catageory.create_catageory")}</Button>
            {rowSelectionModel.length>0 && (<Button className="categories-button" onClick={handleDeleteModal} sx={{textTransform:'none',marginLeft:"2%"}}>Delete</Button>)}
            </Box>
            </Box>
            <DeleteDailouge deletemodal={deletemodal} handleDeleteModal={handleDeleteModal} handleDleteByPopover={handleDleteByPopover} id={categoryIdValue} handleDeleteClick={handleDeleteClick}/>
            <Box sx={{ height: 400, width: '100%',marginTop:'2%' }}>
      {/* <div style={{width:"100%",textAlign:"right"}}>
      <Delete onClick={handleDeleteClick}/>
      </div> */}
      <DataGrid
        rows={rows}
        columns={columns}
        getRowClassName={rowStyle}
       
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5,15,25]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
      />
    </Box>
    <AssignProducts open={open} upDateOpen={handleClose} categoryId={categoryId} />
        </Box>
    )
}
export default  CategoryListing;