import * as React from 'react';
import "./App.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Route, Routes } from "react-router";
import CreateUser from "./pages/userManagement/createUser";
import Dashboard from "./pages/dashboard/dashboard";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { useState,useEffect } from "react";
import UserList from "./pages/userManagement/userList.tsx";
import AssignOptionsToProducts from './pages/options/assignOptionsToProducts.tsx';
import CustomerGroups from "./pages/customerGroups/customerGroups";
import CreateGroup from "./pages/customerGroups/createGroup";
import EditCustomerGroups from "./pages/customerGroups/editCustomerGroup";
import Customers from "./pages/customers/customers";
import CreateCustomer from "./pages/customers/createCustomer";
import ImportCustomers from "./pages/customers/importCustomers";
import CustomerProfile from "./pages/customers/customerProfile";
import EditCustomer from "./pages/customers/editCustomer";
import AddAddress from "./pages/customers/addAddress";
import EditAddress from "./pages/customers/editAddress";
import LocationList from "./pages/locations/locationsList";
import CreateLocation from "./pages/locations/createLocation";
import AboutMyBusiness from "./pages/aboutBusiness/aboutMyBusiness.tsx";
import CreateOptions from "./pages/options/createOptions.tsx";
import SalesTax from "./pages/accountSettings/salesTax.tsx";
import CreateSalesTax from "./pages/accountSettings/createSalesTax.tsx"
import Rounding from "./pages/accountSettings/rounding.tsx"
import ServiceCharges from "./pages/accountSettings/serviceCharges.tsx";
import CreateServiceCharge from "./pages/accountSettings/createServiceCharge.tsx";
import AdvanceBooking from "./pages/accountSettings/advance_booking.tsx";
import CreateAdvanceBooking from "./pages/accountSettings/createAdvanceBooking.tsx";
import ManageTips from "./pages/accountSettings/manageTips.tsx";
import ManagingOrders from './pages/accountSettings/managingOrders.tsx';
import OpenItems from "./pages/accountSettings/openItems.tsx";
import LoyalityPoints from "./pages/accountSettings/loyalityPoints.jsx";
import RefundCancel from "./pages/accountSettings/refunds&cancel.tsx";
import ColorDiagram from "./pages/accountSettings/colorPicker.jsx";
import CreateDiscountMinCart from "./pages/discount/createDiscountMinCart.jsx";
import CreateDiscountMinQty from "./pages/discount/createDiscountMinQty.jsx";
import CreateDiscountExactQty from "./pages/discount/createDiscountExactQty.jsx";
import CreateDiscountCustomerGroup from "./pages/discount/createDiscountCustomerGroup.jsx";
import CreateDiscountBuy1Get from "./pages/discount/createDiscountBuy1Get.tsx";
import CreateDiscountForItem from "./pages/discount/createDiscountForItem.jsx";
import CreateDiscount from "./pages/discount/createDiscount.tsx";
import CreateDiscountFreeDeleivery from "./pages/discount/createDiscountFreeDelevery.jsx";
import DiscountSelfMade from "./pages/discount/discountSelfListing.tsx";
import DiscountPreMade from "./pages/discount/discountPreMadeDiscount.tsx";
import DiscountReason from "./pages/discount/discountReason.jsx";
import CustomizedSwitches from './pages/accountSettings/antSwitch.jsx';
import Receipts from "./pages/accountSettings/reciepts.jsx";
import UserRole from "./pages/userManagement/userRole.tsx";
import CreateUserRole from "./pages/userManagement/createUserRole.tsx";
import UserAccess from "./pages/userManagement/userAccess.tsx";
import UserRequests from "./pages/userManagement/userRequests.tsx";
import InventoryLists from "./pages/inventory/inventoryLists.tsx";
import EditInventory from "./pages/inventory/editInventory.tsx";
import DriverList from './pages/deliveryListing/driverList.tsx';
import StockAdjustments from "./pages/inventory/stockAdjustments.tsx";
import InventoryHistory from "./pages/inventory/inventoryHistory.tsx";
import StockAdjustmentReasons from "./pages/inventory/stockAdjustmentReasons.tsx";
import CreateProduct from './pages/options/createProduct.tsx';
import TemporaryDrawer from './pages/leftMenuBar/drawer.tsx';
import DriverCommission from './pages/deliveryListing/driverCommission.tsx';
import ProductTable from './pages/options/productTable.tsx';
import DriverPettyCash from './pages/deliveryListing/driverPettyCash.tsx';
import Options from './pages/options/optionsList.tsx';
import DeliveryList from './pages/deliveryListing/deliveryList.tsx';
import { Divider,Typography,FormControl,Select,MenuItem } from '@mui/material';
import PersonOutlineIcon  from '@mui/icons-material/PersonOutline';
import DeliveryZone from './pages/deliveryListing/deliveryZone.tsx';
import CategoryListing from './pages/category/categoryListing.tsx'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AreaWise from './pages/deliveryListing/areaWise.tsx';
import i18n from 'i18next';
import BulkProduct from './pages/options/BulkProducts.tsx';
import CreateCategory from './pages/category/editCategory.tsx';
import DistanceOrZoneWise from './pages/deliveryListing/distanceOrZoneWise.tsx';
import MenuListing from './pages/menu/menuListing.tsx';
import MenuSetup from './pages/menu/menuSetup.tsx'
import DeviceSetUp from './pages/deviceSetUp/deviceSetUp.tsx';
import DeviceListing from './pages/deviceSetUp/deviceListing.tsx';
import KdsSetupCreate from './pages/deviceSetUp/kdsSetupCreate.tsx';
import EditZone from './pages/deviceSetUp/editZone.tsx';
import KdsEditAssignedItems from './pages/deviceSetUp/kdsEditAssignedItems.tsx';
import MenuHistory from './pages/menu/menuHistory.tsx';
import BundleListing from './pages/bundle/bundleListing.tsx';
import CreateBundle from './pages/bundle/createBundle.tsx';
import BundleDragDrop from './pages/bundle/bundleDragDrop.tsx';
import KdsSettings from './pages/deviceSetUp/kdsSettings.tsx';
import PosSettings from './pages/deviceSetUp/posSettings.tsx';
import Profile from './pages/profile/profile.tsx'
import CartCrossSellList from './pages/suggestiveSelling/cartCrossSellList.tsx';
import CartCrossSellingCreate from './pages/suggestiveSelling/cartCrossSellingCreate.tsx';
// import Profile from './pages/profile/profile.tsx';
// import Profile from './pages/profile/profile.tsx';
import RecieptBuilder from './pages/reciepts/recieptBuilder.tsx';
import ItemUpSelling from './pages/suggestiveSelling/itemUpSellList.tsx';
import ItemCrossSelling from './pages/suggestiveSelling/itemCrossSellList.tsx';
import KitchenCapacityQuotingTime from './pages/deviceSetUp/kitchenCapacityQuotingTime.tsx';
import Signup from './pages/loginFolders/signup.tsx';
import ItemCrossSellingCreate from './pages/suggestiveSelling/itemCrossSellingCreate.tsx';
import ItemUpSellingCreate from './pages/suggestiveSelling/itemUpSellingCreate.tsx';
import CategoryMain from './pages/category/createCategoryMain.tsx';
import OptionsGridTable from './pages/options/optionTableGrid.tsx';

function App() {

  const [navbar, setNavbar] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setNavbar(open);
  };

  const languages=[
    {value:'en',label:"EN"},
    {value:'es',label:"SP"},
  ]

  const [selectedLanguage, setSelectedLanguage] = useState('en');

  // Function to change the language and update the i18n configuration
 
  const handleChange = (e) => {
   
    setSelectedLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
  }

  const [shouldRenderApp, setShouldRenderApp] = useState(window.innerWidth >= 900);

  useEffect(() => {
    const handleResize = () => {
      setShouldRenderApp(window.innerWidth >= 600);
    };

    // Initial setup and event listener for window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {shouldRenderApp ?(
        
      <div style={{display:"flex"}} >
        <div style={{width:"5vw",backgroundColor: '#FFFBF6',height:"100vh"}}>
     
        <TemporaryDrawer  toggleDrawer={toggleDrawer} setNavbar={setNavbar}  />
        </div >
        <div style={{width:"94vw",height:"15vh"}}>
        
        <div style={{ display:"flex",alignItems:"center",justifyContent:"space-between",marginTop:"1%",backgroundColor:" #FFF"}}>
         <Typography className="main-head" style={{marginLeft:"3%"}}>Etakeaway-Max</Typography>
         <div style={{display:"flex",alignItems:'center',width:"30%"}}>
          <div className='drop-down'>
          <FormControl style={{ minWidth: '10%' }}>
                  <Select
                    value={selectedLanguage}
                    onChange={handleChange}
                    color='warning'
                    inputProps={{
                      style: { border: 'none' }, // Add this style to remove the border
                    }}
                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                  >
                    {languages.map((lang) => (
                      <MenuItem key={lang.value} value={lang.value}>
                        {lang.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
      </div>
      <div className='drop-down' style={{marginLeft:"5%",width:"20%"}}>
      <NotificationsNoneOutlinedIcon style={{width:'70%',height:'70%'}}/>
      </div>
      <div style={{display:'flex',alignItems:'center',marginLeft:"6%",width:"50%",marginRight:'3%'}} className='drop-down' >
      
        <PersonOutlineIcon  style={{width:'35%',height:'50%'}}/>
      
      <Typography style={{paddingLeft:"3%"}} className='main-head2'>User</Typography>
      <ExpandMoreIcon style={{marginLeft:"3%"}}/>
      </div>
         </div>
        </div>
        <Divider style={{marginTop:'8px'}}/>
      <div style={{overflowY:"scroll",height:"85vh"}}>
          <Routes>
          <Route strict exact path="/sign-up" Component={Signup} />   
            <Route strict exact path="/" Component={Dashboard} />

            <Route strict exact path="/create-user" Component={CreateUser} />
            <Route strict exact path="/create-user/:editId" Component={CreateUser} />
            <Route strict exact path="/user-list" Component={UserList} />
                
            <Route strict exact path="/customerGroups" Component={CustomerGroups} />
            <Route strict exact path="/createGroup" Component={CreateGroup} />
            <Route strict exact path="/editGroup" Component={EditCustomerGroups} />
            <Route strict exact path="/customers" Component={Customers} />
            <Route strict exact path="/createCustomer" Component={CreateCustomer} />
            <Route strict exact path="/editCustomer" Component={EditCustomer} />
            <Route strict exact path="/importCustomers" Component={ImportCustomers} />
            <Route strict exact path="/customerProfile" Component={CustomerProfile} />
            <Route strict exact path="/addAddress" Component={AddAddress} />
            <Route strict exact path="/editAddress" Component={EditAddress} />
            <Route strict exact path="/locationList" Component={LocationList} />
            <Route strict exact path="/createLocation" Component={CreateLocation} />
            <Route strict exact path="/aboutMyBusiness" Component={AboutMyBusiness} />
            <Route strict exact path="/createOption" Component={CreateOptions}/>
            <Route strict exact path="/sales_tax" Component={SalesTax}/>
            <Route strict exact path="/create_sales_tax" Component={CreateSalesTax}/>
            <Route strict exact path="/rounding" Component={Rounding}/>
            <Route strict exact path="/service_charges" Component={ServiceCharges}/>
            <Route strict exact path="/create_service_charges" Component={CreateServiceCharge}/>
            <Route strict exact path="/advance_booking" Component={AdvanceBooking}/>
            <Route strict exact path="/create_advance_booking" Component={CreateAdvanceBooking}/>
            <Route strict exact path="/manage_tips" Component={ManageTips}/>
            <Route strict exact path="/managing_orders" Component={ManagingOrders}/>
            <Route strict exact path="/open_items" Component={OpenItems}/>
           <Route strict exact path="/loyality_points" Component={LoyalityPoints}/>
           <Route strict exact path="/refund&cancel" Component={RefundCancel}/>
           <Route strict exact path="/color" Component={ColorDiagram}/>
           <Route strict exact path="/create-discount" Component={CreateDiscount}/>
           <Route strict exact path="/discount-minCart" Component={CreateDiscountMinCart}/>
           <Route strict exact path="/discount-min-quantity" Component={CreateDiscountMinQty}/>
           <Route strict exact path="/discount-exact-quantity" Component={CreateDiscountExactQty}/>
           <Route strict exact path="/discount-customer-group" Component={CreateDiscountCustomerGroup}/>
           <Route strict exact path="/discount-buy1-get" Component={CreateDiscountBuy1Get}/>
           <Route strict exact path="/discount-for-item" Component={CreateDiscountForItem}/>
           <Route strict exact path="/discount-free-delevery" Component={CreateDiscountFreeDeleivery}/>
           <Route strict exact path="/discount-list" Component={DiscountSelfMade}/>
           <Route strict exact path="/discount-preMade-list" Component={DiscountPreMade}/>
           <Route strict exact path="/discount-reasons" Component={DiscountReason}/>
           <Route strict exact path="/discountValue" Component={CustomizedSwitches}/>
           <Route strict exact path="/receipts" Component={Receipts}/>
           <Route strict exact path="/user-role" Component={UserRole}/>
           <Route strict exact path="/create-role" Component={CreateUserRole}/>
           <Route strict exact path="/create-role/:editId" Component={CreateUserRole}/>
           <Route strict exact path="/user-access" Component={UserAccess}/>
           <Route strict exact path="/user-requests" Component={UserRequests}/>
           <Route strict exact path="/inventoryLists" Component={InventoryLists}/>
           <Route strict exact path="/editInventory" Component={EditInventory}/>
           <Route strict exact path="/stockAdjustments" Component={StockAdjustments}/>
           <Route strict exact path="/inventoryHistory" Component={InventoryHistory}/>
           <Route strict exact path="/stockAdjustmentReasons" Component={StockAdjustmentReasons}/>
           <Route strict exact path="/options" Component={Options}/>
           <Route strict exact path="/product" Component={ProductTable}/>
           <Route strict exact path="/drawer" Component={TemporaryDrawer}/>
           <Route strict exact path="/create-product" Component={CreateProduct}/>
           <Route strict exact path="/driverCommission" Component={DriverCommission}/>
           <Route strict exact path="/driverPettyCash" Component={DriverPettyCash}/>
           <Route strict exact path="/driverList" Component={DriverList}/>
           <Route strict exact path="/deliveryList" Component={DeliveryList}/>
           <Route strict exact path="/deliveryZone" Component={DeliveryZone}/>
           <Route strict exact path="/bulk-product" Component={BulkProduct}/>        
           <Route strict exact path="/category-listing" Component={CategoryListing}/>
           <Route strict exact path="/create-category" Component={CategoryMain}/>
           {/* <Route strict exact path="/create-category" Component={CreateCategory}/> */}
           <Route strict exact path="/:id/update-category" Component={CreateCategory}/>
           <Route strict exact path="/menu" Component={MenuListing}/>
           <Route strict exact path="/menu-setup" Component={MenuSetup}/>
           <Route strict exact path="/areaWise" Component={AreaWise}/>
           <Route strict exact path="/distanceOrZonewise" Component={DistanceOrZoneWise}/>
           <Route strict exact path="/deviceSetUp" Component={DeviceSetUp}/>
           <Route strict exact path="/deviceListing" Component={DeviceListing}/>
           <Route strict exact path="/kdsSetupCreate" Component={KdsSetupCreate}/>
           <Route strict exact path="/editZone" Component={EditZone}/>
           <Route strict exact path="/kdsEditAssignedItems" Component={KdsEditAssignedItems}/>
           <Route strict exact path="/menu-history" Component={MenuHistory}/>
           <Route strict exact path="/bundle-listing" Component={BundleListing}/>
           <Route strict exact path="/create-bundle" Component={CreateBundle}/>
           <Route strict exact path="/bundle" Component={BundleDragDrop}/>
           <Route strict exact path="/kdsSettings" Component={KdsSettings}/>
           <Route strict exact path="/posSettings" Component={PosSettings}/>
           <Route strict exact path="/user-profile" Component={Profile}/>
           <Route strict exact path="/cartCrossSellList" Component={CartCrossSellList}/>

           <Route strict exact path="/itemCrossSellList" Component={ItemCrossSelling}/>
           <Route strict exact path="/itemUpSellList" Component={ItemUpSelling}/>

           <Route strict exact path="/cartCrossSellingCreate" Component={CartCrossSellingCreate}/>
           <Route strict exact path="/reciept-builder" Component={RecieptBuilder}/>
           <Route strict exact path="/itemCrossSellingCreate" Component={ItemCrossSellingCreate}/>
           <Route strict exact path="/itemUpSellingCreate" Component={ItemUpSellingCreate}/>
           <Route strict exact path="/kitchen" Component={KitchenCapacityQuotingTime}/>
           
           <Route strict exact path="/category-main" Component={CategoryMain}/>
           <Route strict exact path="/assignOptionsToProducts" Component={AssignOptionsToProducts}/>
           <Route strict exact path="/options/table/grid" Component={OptionsGridTable}/>
           
          </Routes>
          </div>
        </div>
      </div>
      ):(<h1 style={{color:"red"}}>the Ui not available below 900px</h1>)}
    </>
  );
}

export default App;
