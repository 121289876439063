import React, { useState } from 'react';
import { Paper, Typography, Switch, Divider,Button,Checkbox,TextField,FormControlLabel } from '@mui/material';
import { t } from "i18next";
import Tooltip from '@mui/material/Tooltip';
import './salesTax.css';
import './manageTips.css'

const ManageTips: React.FC = () => {
  const [toggleState, setToggleState] = useState(false);

  const handleToggleChange = () => {
      setToggleState(!toggleState);
  };
  const [checkboxes, setCheckboxes] = useState([
    { checked: false, label: '5' },
    { checked: false, label: '10' },
    { checked: false, label: '15' },
    { checked: false, label: '0' },
  ]);

  const [newLabel, setNewLabel] = useState('');

  const handleCheckboxChange = (index: number) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].checked = !updatedCheckboxes[index].checked;
    setCheckboxes(updatedCheckboxes);
};


const handleNewLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setNewLabel(event.target.value);
};


const handleAddCheckbox = () => {
  if (newLabel.trim() !== '') {
      setCheckboxes([...checkboxes, { checked: false, label: newLabel }]);
      setNewLabel('');
  }
};

const [addValue, setAddValue] = useState(false);
    
 
const handleAdd = () => {
  setAddValue(true);
};


  const [checkboxes1, setCheckboxes1] = useState([
    { checked: false, label: '5' },
    { checked: false, label: '1' },
    { checked: false, label: '0.5' },
    { checked: false, label: '2' },
  ]);

  const [newLabel1, setNewLabel1] = useState('');

  const handleCheckboxChange1 = (index: number) => {
    const updatedCheckboxes1 = [...checkboxes1];
    updatedCheckboxes1[index].checked = !updatedCheckboxes1[index].checked;
    setCheckboxes1(updatedCheckboxes1);
};

const handleNewLabelChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
  setNewLabel1(event.target.value);
};

const handleAddCheckbox1 = () => {
  if (newLabel1.trim() !== '') {
      setCheckboxes1([...checkboxes1, { checked: false, label: newLabel1 }]);
      setNewLabel1('');
  }
};

const [addValue1, setAddValue1] = useState(false);

const handleAdd1 = () => {
  setAddValue1(true);
};


const [tipsAfterTax, setTipsAfterTax] = useState(false);

const handleTipsAfterTax = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTipsAfterTax(event.target.checked);
};

const [smartTip, setSmartTip] = useState(false);

const handleSmartTip = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSmartTip(event.target.checked);
};

const [customTip, setCustomTip] = useState(false);

const handleCustomTip = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomTip(event.target.checked);
};

const [tippingScreen, setTippingScreen] = useState(false);

const handleTippingScreen = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTippingScreen(event.target.checked);
};

  


  return (
    <div>
      <Paper elevation={3} style={{padding:"10px",paddingBottom:"20px",width:"100%"}}>
      <div style={{ marginLeft: '20px', marginBottom: '10px' }}>
            <div className="sales_head_container">
                <div className="sales_head_center">
                    <Typography className="sales_head">{t("settings.collect_tips")}</Typography>
                </div>
                <Switch checked={toggleState} onChange={handleToggleChange} color='warning'/>
            </div>
            <Typography className="sales_para">{t("settings.collect_para")}</Typography>
        </div>
        <Divider style={{ backgroundColor: ' #D9D9D9', marginTop: "8px" }} />
        {toggleState && (
            <>
                <div className='create_tax_head_container' style={{ padding: "12px 20px 0px 12px" }}>
                    <Typography className="create_tax_main">{t("settings.manage_tips")}</Typography>
                    <div className='create_tax_button_con'>
                        <Button variant="contained" className='create_tax_button_style'>
                            {t("settings.clear")}
                        </Button>
                        <Button variant="contained" className='create_tax_button_style1'>
                            {t("settings.save")}
                        </Button>
                    </div>
                </div>
<Paper style={{marginLeft: '8%', marginTop: '44px', width: "73%", padding: "20px 12px 20px 12px" ,borderLeft: "2px solid  var(--stroke-color-1-violet, #AE9BE2)"}}  elevation={0.8} className='create_tax_page1'>
<div>
<Typography className="manage-tips_head">{t("settings.percentage_tips")}</Typography>
                <Typography className="manage-tips_para">{t("settings.percentage_para")}</Typography>
                <div style={{ display: "flex", marginTop: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: "12px" }}>
                        {checkboxes.map((checkbox, index) => (
                            <FormControlLabel
                                key={index}
                                control={<Checkbox color='warning' checked={checkbox.checked} onChange={() => handleCheckboxChange(index)} />}
                                label={<Typography className='createService_check_text'>{checkbox.label}%</Typography>}
                                className='manage-tips_checkBox'
                            />
                        ))}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: "center" }}>
                    <Button className='manage-tips_button' onClick={handleAdd} style={{ marginTop: '10px' }}>
                        {t("settings.add")}
                    </Button>
                    <div>
                        {addValue && (
                            <div style={{ marginLeft: '10px', display: "flex", alignItems: "center" }}>
                                <TextField
                                    label="Enter Number"
                                    value={newLabel}
                                    onChange={handleNewLabelChange}
                                    variant="outlined"
                                    margin="dense"
                                    style={{ marginTop: '10px' }}
                                    color='warning'
                                    sx={{
                                        "&:hover": {
                                            "&& fieldset": {
                                                border: "2px solid #F9C584"
                                            }
                                        }
                                    }}
                                />
                                <Button className='manage-tips_button' onClick={handleAddCheckbox} style={{ marginLeft: '10px', display: "flex", alignItems: "center" }}>
                                    {t("settings.add")}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
       </div>

       <Typography className="manage-tips_head" style={{ marginTop: '24px' }}>
    {t("settings.flat_amount")}
</Typography>
<Typography className="manage-tips_para">{t("settings.flat_para")}</Typography>

<div style={{ display: 'flex', flexDirection: 'row', marginTop: '12px', marginLeft: '12px' }}>
    {checkboxes1.map((checkbox, index) => (
        <FormControlLabel
            key={index}
            control={
                <Checkbox
                    color="warning"
                    checked={checkbox.checked}
                    onChange={() => handleCheckboxChange1(index)}
                />
            }
            label={
                <Typography className="createService_check_text">
                    $ {checkbox.label}
                </Typography>
            }
            className="manage-tips_checkBox"
        />
    ))}
</div>
<div style={{ display: 'flex', alignItems: 'center' }}>
    <Button className="manage-tips_button" onClick={handleAdd1} style={{ marginTop: '10px' }}>
        {t("settings.add")}
    </Button>
    <div>
        {addValue1 && (
            <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                <TextField
                    label="Enter Number"
                    value={newLabel1}
                    onChange={handleNewLabelChange1}
                    variant="outlined"
                    margin="dense"
                    style={{ marginTop: '10px' }}
                    color="warning"
                    sx={{
                        "&:hover": {
                            "&& fieldset": {
                                border: "2px solid #F9C584",
                            },
                        },
                    }}
                />
                <Button
                    className="manage-tips_button"
                    onClick={handleAddCheckbox1}
                    style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}
                >
                    {t("settings.add")}
                </Button>
            </div>
        )}
    </div>
</div>

  </Paper>
       
        

       

  <div className="manage-tips_smartTip" style={{ paddingTop: '36px', marginLeft: '68px', marginBottom: "8px" }}>
    <div style={{ display: "flex" }}>
        <Switch
            color="warning"
            checked={smartTip}
            onChange={handleSmartTip}
        />
        <div style={{ marginLeft: '10px' }}>
            <Typography className="manage-tips_head">{t("settings.smart_tips")}</Typography>
            <Typography className="manage-tips_para">{t("settings.tips_para1")}<br />{t("settings.tips_para2")}</Typography>
        </div>
    </div>
    <div style={{ display: "flex", alignItems: 'center', marginTop: "8px", marginRight: '26px' }} className="manage-tips-border">
        <Typography className="manage-tips-cart">{t("settings.set_cart")}</Typography>
        <TextField
            fullWidth
            variant="standard"
            InputProps={{
                disableUnderline: true,
            }}
            className="mangage_text"
        />
    </div>
</div>
<Paper style={{ marginLeft: '8%', marginTop: '44px', width: "73%", padding: "20px 12px 20px 12px", borderLeft: "2px solid var(--stroke-color-2-tale, #9BDAE2)" }} elevation={0.8} className="create_tax_page1">
    <Typography className="manage-tips_head">{t("settings.adv_payment_types")}</Typography>
    <div style={{ display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className="manage-tips-head2" style={{ marginRight: "80px" }}>{t("settings.cal_tips_after_tax")}</Typography>
        </div>
        <Switch
            color="warning"
            checked={tipsAfterTax}
            onChange={handleTipsAfterTax}
        />
    </div>

    <div style={{ display: "flex", marginTop: "12px" }} className="manage-tips-allowTip_con">

        <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className="manage-tips-head2" style={{ marginRight: "12px" }}>{t("settings.allow_custom_tips")}</Typography>
            <Tooltip title={
                <div>
                    <Typography className="manage_toolTip_head">{t("settings.customTips_para1")}<br /> 
                    <span className="manageTips_toolTip_text">
                        {t("settings.customTips_para2")}<br />
                        {t("settings.customTips_para3")}<br />
                        {t("settings.customTips_para4")}
                    </span>
                    </Typography>
                </div>
            } placement="bottom-start" classes={{ tooltip: 'custom-tooltip' }} style={{ marginRight: "106px" }}>
                <Button sx={{ m: 1 }}>
                    <div style={{ position: 'relative', display: 'inline-block' }} className="custom-icon-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
                        </svg>
                        <Typography
                            component="span"
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#ffffff',
                                fontWeight: 'bold',
                                fontSize: '16px',
                            }}
                        >
                            !
                        </Typography>
                    </div>
                </Button>
            </Tooltip>
            <Switch
                color="warning"
                checked={customTip}
                onChange={handleCustomTip}
            />

        </div>
        <Typography className="sales_para">{t("settings.pos_para")}</Typography>


    </div>

    <div style={{ display: "flex", alignItems: "center", marginTop: "12px" }}>

        <Typography className="manage-tips-head2" style={{ marginRight: "80px" }}>{t("settings.separate_screen")}</Typography>

        <Switch
            color="warning"
            checked={tippingScreen}
            onChange={handleTippingScreen}
        />
    </div>

</Paper>
</>
        )}
      </Paper>
    </div>
  );
};
export default ManageTips;
