import { Autocomplete,Box,FormControl,Button, Paper,InputAdornment,InputLabel,OutlinedInput, Typography,TextField,Switch, Divider,Checkbox ,FormGroup,FormControlLabel,IconButton} from '@mui/material'

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../options/createProduct.css'


const searchResults=[
  {
    id:"1",title:"amlapuram",isChecked:false
  },
  {
    id:"1",title:"amlapuram",isChecked:false
  },
  {
    id:"1",title:"amlapuram",isChecked:false
  },
  {
    id:"1",title:"amlapuram",isChecked:false
  },
  {
    id:"1",title:"amlapuram",isChecked:false
  },
  {
    id:"1",title:"amlapuram",isChecked:false
  },
  {
    id:"1",title:"amlapuram",isChecked:false
  },
  {
    id:"1",title:"amlapuram",isChecked:false
  },{
    id:"1",title:"amlapuram",isChecked:false
  },
  {
    id:"1",title:"amlapuram",isChecked:false
  },
 
]


const EditZone = () => {
  
  const handleClick=()=>{
    console.log("haro")
    toast("Category name has been saved. Your Category has been created & added to the category list", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      className: "custom-toast", // Apply the custom CSS class here
    })
  }

  
  

  return (
   
    <Box>
        

        <Button onClick={handleClick}>Click</Button>
   </Box>
  );
};

export default EditZone;
