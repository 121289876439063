import React from "react"
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import image from "../../assets/Ellipse 17.png"
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import { useQuery,useMutation } from '@apollo/client';
import {TextField, IconButton,  Box, Divider,  Typography} from '@mui/material'

import {t} from "i18next"
import "./userList.css"
import {
    GridRowsProp,
    
  } from '@mui/x-data-grid';
  const style = {
    position: 'absolute' as 'absolute',
    top: '56%',
    left: '77%',
    transform: 'translate(-50%, -50%)',
    width: "45%",
    bgcolor: 'background.paper',
    borderRadius:"8px",
    p: 3,
  };
const initialPopupRows: GridRowsProp=[
    {
        id:1,
        sNo:1,
        deliveryStatus:"Delivering in Progress",
        location:"Area/zone",
        tip:"£0.00"
    },
    {
        id:2,
        sNo:2,
        location:"Area/zone",
        deliveryStatus:"Delivered",
        tip:"£0.00"
    },
    {
      id:3,
        sNo:3,
        location:"Area/zone",
        deliveryStatus:"Cancelled",
        tip:"£0.00"
    },
    {
        id:4,
        sNo:4,
        location:"Area/zone",
        deliveryStatus:"Delivered",
        tip:"£0.01"
    },
  ]

interface DriverProfileProps{
    handlePopupClose:()=>void
    popUpOpen:boolean
    profileId:string
}

function DriverProfile(props:DriverProfileProps) {

    const {handlePopupClose,popUpOpen,profileId}=props
    console.log(profileId)
    const handleCloseIcon=()=>{
        handlePopupClose()
    }

    //date functions
     //for getting date
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${day}-${month}-${year}`;
    const [popUpDate,setPopUpDate]=React.useState<string>(formattedDate)
    const handlePopupDate=(event:React.ChangeEvent<HTMLInputElement>)=>{
        setPopUpDate(event.target.value)
    }


    const handlePopupRows=()=>{
        return "driver-list-popup-rows"
      }

      const initialPopUpColumns: GridColDef[] = [
        { field: 'sNo', headerName: 'S No',flex:1,headerClassName:"driver-list-popup-columns"},
        {
          field: 'id',
          headerName: 'Order Details',
          flex:1,
          headerClassName:"driver-list-popup-columns"
        
        },
        {
          field: 'location',
          headerName: 'Location',
          flex:1,
          headerClassName:"driver-list-popup-columns"
          
        },
        {
          field: 'deliveryStatus',
          headerName: 'Delivery Status',
          width:180,
          headerClassName:"driver-list-popup-columns",
          renderCell:(params)=>{
            var styleOfStatus;
            if(params.value==="Delivered"){
              styleOfStatus="driver-list-popup-delivered-status"
            }else if(params.value==="Cancelled"){
              styleOfStatus="driver-list-popup-cancelled-status"
            }else{
              styleOfStatus="driver-list-popup-rows"
    
            }
            return(
              <Typography className={styleOfStatus}>{params.value}</Typography>
            )
          }
        },
        {
          field: 'tip',
          headerName: 'Tip',
          flex:1,
          headerClassName:"driver-list-popup-columns"
        },
        
      ];
  return (
    <Box>
        <Modal       
                  open={popUpOpen}
                  className='over-flow'
                >
                  <Box  sx={style}>
                    <Box sx={{display:"flex",justifyContent:"space-between",width:"55%"}}>
                    <IconButton onClick={handleCloseIcon} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
                    <CloseIcon />
                    </IconButton>
                    <Typography className='delivery-list-header-text'>Driver Profile</Typography>
                    </Box>
                    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%",marginBottom:"10px",marginTop:"10px"}}>
                      <Box sx={{display:"flex",justifyContent:"space-between",marginLeft:"6px",width:"28%",alignItems:"center"}}>
                        <img src={image} alt="profile"/>
                        <Typography className='driver-list-popup-profile-text'>Yoshida Haru</Typography>
                        <IconButton className='inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover'>
                          <EditIcon/>
                        </IconButton>

                      </Box>
                      <Box>
                        <Typography className='driver-list-popup-profile-king-text'>Kingfield Store location</Typography>
                      </Box>
                    </Box>
                    <Box sx={{display:"flex",alignItems:"center",marginBottom:"10px",marginLeft:"6px"}}>
                      <IconButton className='driver-list-popup-phone-icon'>
                        <LocalPhoneOutlinedIcon/>
                      </IconButton>
                      <Typography className='driver-list-popup-phone-number'>9988775566</Typography>
                    </Box>
                    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}>
                      <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                        <IconButton sx={{marginRight:"5px",marginLeft:"0px"}}>
                        <EmailOutlinedIcon/>
                        </IconButton>
                        <Typography className='driver-list-popup-email'>Haru.sasu@gmail.com</Typography>
                      </Box>

                      <Box className="driver-list-popup-clock-text-container">
                        <Typography className='driver-list-popup-clock-text'>Clock in at 10:30 AM</Typography>
                        
                      </Box>
                    </Box>
                    <Divider sx={{marginTop:"10px",marginBottom:"10px"}}/>
                    <Box sx={{display:"flex",justifyContent:"space-between",marginTop:"15px",marginBottom:"15px"}}>
                      <Typography className='driver-list-popup-assigned-text'>Assigned Orders</Typography>
                      <Typography className='driver-list-popup-status-text'>Status:<span className='driver-list-popup-10min-text'>10min</span><span className='driver-list-popup-toreach-text'> to reach delivery location</span></Typography>
                    </Box>
                    <Box sx={{ height: 320, width: '100%' }}>
                    <DataGrid
                      rows={initialPopupRows}
                      columns={initialPopUpColumns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      getRowClassName={handlePopupRows}
                      disableRowSelectionOnClick
                    />
                  </Box>
                  <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",marginTop:"15px"}}>
                    <Typography className='driver-list-popup-driver-text'>Driver Report</Typography>
                    <Box sx={{display:"flex",alignItems:"center",width:"40%"}}>
                      <IconButton >
                        <PrintIcon color='warning' sx={{width:"24px",height:"24px"}}/>
                      </IconButton>
                    <TextField
                      sx={{marginLeft:"10px"}}
                      InputProps={{
                        style: {
                          fontFamily: 'Poppins'
                        },
                        
                        placeholder:"hello"
                      }}
                      type="date"
                      fullWidth   
                      onChange={handlePopupDate}
                      value={popUpDate}
                      
                      

                    />
                    </Box>
                  </Box>
                  <Box sx={{marginTop:"15px",marginBottom:"10px",display:"flex",justifyContent:"space-around",alignItems:"center"}}>
                    <Box className="driver-list-popup-boxes" sx={{backgroundColor:"#FEF1E1",color:"#F38B08"}}>
                      <Typography className='driver-list-popup-boxes-number'>64</Typography>
                      <Typography className='driver-list-popup-boxes-text'>Total Hours</Typography>
                    </Box>
                    <Box className="driver-list-popup-boxes" sx={{backgroundColor:"#FEF1E1",color:"#F38B08"}}>
                      <Typography color="warning" className='driver-list-popup-boxes-number'>84</Typography>
                      <Typography color="warning" className='driver-list-popup-boxes-text'>In Time Deliveries</Typography>
                    </Box>
                    <Box className="driver-list-popup-boxes" sx={{backgroundColor:"#E5F7E9",color:"#00AE26"}}>
                      <Typography color="#00AE26" className='driver-list-popup-boxes-number'>$20</Typography>
                      <Typography color="#00AE26" className='driver-list-popup-boxes-text'>Commission Earned</Typography>
                    </Box>
                  </Box>

                  <Box sx={{marginTop:"15px",marginBottom:"10px",display:"flex",justifyContent:"space-around",alignItems:"center"}}>
                    <Box className="driver-list-popup-boxes" sx={{backgroundColor:"#E5F7E9",color:"#00AE26"}}>
                      <Typography className='driver-list-popup-boxes-number'>$64</Typography>
                      <Typography className='driver-list-popup-boxes-text'>Total Tips</Typography>
                    </Box>
                    <Box className="driver-list-popup-boxes" sx={{backgroundColor:"#FEF1E1",color:"#F38B08"}}>
                      <Typography color="warning" className='driver-list-popup-boxes-number'>44</Typography>
                      <Typography color="warning" className='driver-list-popup-boxes-text'>Total Deliveries</Typography>
                    </Box>
                    <Box className="driver-list-popup-boxes" sx={{backgroundColor:"#FEECE8",color:"#F5431C"}}>
                      <Typography  className='driver-list-popup-boxes-number'>03</Typography>
                      <Typography  className='driver-list-popup-boxes-text'>Cancelled Orders</Typography>
                    </Box>
                  </Box>
                  </Box>
                </Modal>
    </Box>
  )
}

export default DriverProfile
