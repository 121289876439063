import React,{ useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Typography,IconButton } from "@mui/material"
import { DataGrid, GridColDef,GridRowSelectionModel } from '@mui/x-data-grid';
import { Delete, MoreVertSharp } from '@mui/icons-material';
import { Popover, List, ListItem, ListItemText ,Divider} from '@mui/material';
import { t } from 'i18next';
import { useQuery,useMutation } from '@apollo/client';
import CircularLoader from '../../loader/loader';
import {CATEGORY_LIST} from '../../graphicalQl/usequery/categoryListQuery'
import { Delete_Category } from '../../graphicalQl/mutation/mutation';


import AssignProducts from '../category/assigngnProductsModal';
import { GET_OPTIONS_LIST_IN_OPTIONS } from '../../graphicalQl/usequery/optionsQuery';




interface Row2{
  id:string,
        name:string,
        value:string,
}



  interface Props{
    handleSelectedData: (data: Row2[]) => void;
    optionGroupName:string;
  }

function OptionsGridTable(props:Props) {

    const {handleSelectedData,optionGroupName}=props
    

   const [optionsList,setOptionsList]=useState<Row2[]>([])
  
  
  const { loading, error, data } = useQuery(GET_OPTIONS_LIST_IN_OPTIONS,{ context: { clientName:"categoryClient" }});
  

  
console.log(data)
  
  
  useEffect(() => {
   
    if ( data) {
      
      const updatedData=data.optionList.edges.map((item:any)=>({
        id:item.node.optionId,
        name:item.node.name,
        value:"mutton",

      }))
      setOptionsList(updatedData)

    }
    
  }, [ data]);



  const rowStyle=()=>{
    return 'categories-row'
  }

  
 
 
 


  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

 useEffect(()=>{
  setRowSelectionModel([])
  
 },[optionGroupName])

 console.log(rowSelectionModel)
 
  const [deleteCategoryValue] = useMutation(Delete_Category, {
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
     
    },
    context: { clientName: "categoryClient" },
    refetchQueries: [
       // Specify the query using an object with a 'query' property
      CATEGORY_LIST ,
   ],
  });
//   const handleDeleteClick = () => {
//     // Filter out the selected rows from the current rows state
   
//     const updatedRows = rows.filter((row) => rowSelectionModel.includes(row.id));
   
    
//     const categoryValue = updatedRows.map((each) => +each.categoryId);

    
    
//       deleteCategoryValue({variables: {categoryIds: JSON.stringify(categoryValue)}})
//       setRowSelectionModel([]);
       
    
     
        
    
 
// }

const [checkedData,setCheckedData]=useState<Row2[]>([])
const handleCheckedRow=()=>{
    const updatedRows = optionsList.filter((row) => rowSelectionModel.includes(row.id));
    
    setCheckedData(updatedRows)
   
}

// console.log(checkedData)

useEffect(()=>{
    handleCheckedRow()
    handleSelectedData(checkedData)
},[rowSelectionModel])

useEffect(()=>{
 
  handleSelectedData(checkedData)
},[checkedData])


const handleDleteByPopover=(id:string)=>{

deleteCategoryValue({variables: {categoryIds: JSON.stringify([id])}})
  
}

function CustomPopoverCell({ categoryId}: { categoryId: string}) {

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const navigate=useNavigate()
  const handleEdit=(id:string)=>{
    navigate(`/${id}/update-category`)
  }
  const open = Boolean(anchorEl);
  const id = open ? categoryId : "";

  const popoverContent = (
    <List style={{ padding: '0px 0px 0px 0px' }}>
      <Button style={{ padding: '0px 0px 0px 0px' ,textTransform:"none"}} onClick={()=>handleEdit(id)}>
        <ListItem style={{ width: '180px', height: '40px' }} className='catageory-pover-text-style'>
          <ListItemText primary={t("menu.catageory.edit")} />
        </ListItem>
      </Button>
      <Divider />
      <Button style={{ padding: '0px 0px 0px 0px' ,textTransform:"none"}} onClick={()=>handleDleteByPopover(id)}>
        <ListItem style={{ width: '180px', height: '40px' }} className='catageory-pover-text-style'>
          <ListItemText primary={t("menu.catageory.delete")} />
        </ListItem>
      </Button>
      <Divider />
      <Button style={{ padding: '0px 0px 0px 0px' ,textTransform:"none"}}>
        <ListItem style={{ width: '180px', height: '40px' }} className='catageory-pover-text-style'>
          <ListItemText primary="Duplicate"/>
        </ListItem>
      </Button>
    </List>
  );

 
  return (
    <Box>
      <div>
        <IconButton onClick={handleClick}>
          <MoreVertSharp />
        </IconButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{ width: '180px', height: '225px', paddingTop: '0px', paddingBottom: '0px' }}
          elevation={8}
        >
          {popoverContent}
        </Popover>
      </div>
    </Box>
  );
}


const [open, setOpen] = React.useState(false);
  const handleOpen = () => (setOpen(true));
    
  
  const handleClose = () => setOpen(false);

  const [categoryId,setCategoryId]=useState("")
  const handleCategoryId=(category:any)=>{
    setCategoryId(category)

  }
 

  
  
 
  const columns: GridColDef[] = [
 
    {
      field: 'name',
      //@ts-ignore
      headerName: t("options"),
      flex: 1,
      headerClassName:"categories-column-header",
      editable: false,
      
      renderCell:(params)=>{
  
  return(
    <Box>
    <Typography className='categories-table-text-head'>{params.row.name}</Typography>
    </Box>
  )
      }
    },
    {
      field:"value",
      headerName: "optionValues",
     flex:1,
      headerClassName:"categories-column-header",
      editable: false,
      disableColumnMenu:true,
      renderCell:(params)=>{
        return(
          <Box>
           <Typography className='categories-table-text-head'>{params.row.value}</Typography>

          </Box>
        )
            }
      
    },
    {
      field: 'description',
      headerName: 'Actions',
      flex:1,
      headerClassName: 'categories-column-header',
      editable: false,
      
      align:'left',
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
        <>
          <Button className='categories-button2' onClick={() => {
  handleOpen();
  handleCategoryId(params.row.categoryId);
}}>
  {t("menu.catageory.assignProducts")}
</Button>
          </>
        );
      
      },
    },
    {
        field: '',
        headerName: '',
        flex:1,
        headerClassName: 'categories-column-header',
        editable: false,
        
        align:"right",
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
          <>
            <CustomPopoverCell
            categoryId={params.row.categoryId}
            
            />
          
            </>
          );
        
        },
      }
    
  ];

  
 
 if (loading) return <CircularLoader/>;
 if (error) return <p>Error : {error.message}</p>;
 
 
    return(
        <Box sx={{padding:"1%",width:"100%"}}>
           
            <Box sx={{ height: 400, width: '100%',marginTop:'2%' }}>
      <div style={{width:"100%",textAlign:"right"}}>
      <Delete/>
      </div>
      <DataGrid
        rows={optionsList}
        columns={columns}
        getRowClassName={rowStyle}
       
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5,15,25]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel );
        }}
        rowSelectionModel={rowSelectionModel}
      />
    </Box>
    <AssignProducts open={open} upDateOpen={handleClose} categoryId={categoryId} />
        </Box>
    )
}
export default  OptionsGridTable;