import React, { useState } from 'react';
import {
    Typography, Paper, Button, Divider, Switch, Checkbox, TextField, Select,
    MenuItem,FormControlLabel,FormGroup
} from '@mui/material';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import { t } from "i18next";

import './rounding.css';
import './refund.css';
import './manageOreders.css'

const ManagingOrders = () => {

    const [toggleState, setToggleState] = useState<boolean>(false);
    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToggleState(event.target.checked);
    };
    const [toggleStateReject, setToggleStateReject] = useState<boolean>(false);
    const handleToggleChangeReject = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToggleStateReject(event.target.checked);
    };

    const [toggleStateSnooze, setToggleStateSnooze] = useState<boolean>(false);

    const handleToggleChangeSnooze = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToggleStateSnooze(event.target.checked);
    };

    const [checkboxes, setCheckboxes] = useState<{ checked: boolean; label: string }[]>([
        { checked: false, label: 'Too Busy' },
        { checked: false, label: 'Unable to Deliver' },
        { checked: false, label: 'Food Unavailable' },
        { checked: false, label: "Don't Deliver" },
    ]);

    const [newLabel, setNewLabel] = useState<string>('');

    // Function to handle checkbox change
    const handleCheckboxChange = (index: number) => {
        const updatedCheckboxes = checkboxes.map((checkbox, i) =>
            i === index ? { ...checkbox, checked: !checkbox.checked } : checkbox
        );
        setCheckboxes(updatedCheckboxes);
    };

    const handleNewLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewLabel(event.target.value);
    };


    const handleAddCheckbox = () => {
        if (newLabel.trim() !== '') {
            setCheckboxes([...checkboxes, { checked: false, label: newLabel }]);
            setNewLabel('');
        }
    };

    const [addValue, setAddValue] = useState(false);

    const handleAdd = () => {
        setAddValue(true);
    };

    
    const handleAddCheckbox1 = () => {
        if (newLabel1.trim() !== '') {
            setCheckboxes1([...checkboxes1, { checked: false, label: newLabel1 }]);
            setNewLabel1('');
        }
    };

    const [addValue1, setAddValue1] = useState(false);

    const handleAdd1 = () => {
        setAddValue1(true);
    };

    const data = [
        { id: 1, modifications: t("settings.add_items"), preparationNotStarted: t("settings.not_allowed"), preparing:t("settings.not_allowed"), prepared:t("settings.not_allowed") },
        { id: 2, modifications: t("settings.changing_quantity"), preparationNotStarted: t("settings.not_allowed"), preparing:t("settings.not_allowed"), prepared: t("settings.not_allowed") },
        { id: 3, modifications:t("settings.add/remove"), preparationNotStarted: t("settings.not_allowed"), preparing: t("settings.not_allowed"), prepared: t("settings.not_allowed") },
        { id: 4, modifications: t("settings.request_sub"), preparationNotStarted: t("settings.not_allowed"), preparing: t("settings.not_allowed"), prepared: t("settings.not_allowed")},
        { id: 5, modifications:t("settings.cancel_item"), preparationNotStarted: t("settings.not_allowed"), preparing:t("settings.not_allowed"), prepared: t("settings.not_allowed")},
        { id: 6, modifications: t("settings.pre/post"), preparationNotStarted:t("settings.not_allowed"), preparing:t("settings.not_allowed"), prepared:t("settings.not_allowed") },
    ];

    const columns = [
        { id: 'modifications', label: t("settings.modification") },
        { id: 'preparationNotStarted', label: t("settings.preparation_not_started") },
        { id: 'preparing', label: t("settings.preparing") },
        { id: 'prepared', label: t("settings.prepared") },
    ];

    const [tableData, setTableData] = useState<
        { id: number; modifications: string; preparationNotStarted: string; preparing: string; prepared: string }[]
    >(data);

    const handleValueChange = (
        rowIndex: number,
        columnName: keyof typeof data[0],
        newValue: string | number
    ) => {
        if (columnName !== "id") {
            const updatedData = [...tableData];
            updatedData[rowIndex][columnName] = String(newValue); // Convert to string
            setTableData(updatedData);
        }
    };

    const [checkboxes1, setCheckboxes1] = useState<{ checked: boolean; label: string }[]>([
        { checked: false, label: '5' },
        { checked: false, label: '12' },
        { checked: false, label: '10' },
        { checked: false, label: '11' },
    ]);

    const [newLabel1, setNewLabel1] = useState<string>('');

    // Function to handle snooze checkbox change
    const handleCheckboxChange1 = (index: number) => {
        const updatedCheckboxes1 = [...checkboxes1];
        updatedCheckboxes1[index].checked = !updatedCheckboxes1[index].checked;
        setCheckboxes1(updatedCheckboxes1);
    };

    const handleNewLabelChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewLabel1(event.target.value);
    };

    return (
        <Paper elevation={3} style={{ padding: "20px" }}>
             <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography className="rounding_titleValue">{t("settings.manage_orders")}</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button variant="contained" className="rounding_Button_clear">
                            {t("settings.clear")}</Button>
                        <Button variant="contained" className="Rounding_save_button" style={{ marginLeft: "20px" }}>
                            {t("settings.save")}</Button>
                    </div>
                </div>
                <Divider style={{ backgroundColor: ' #D9D9D9',marginTop:"27px" }} />
            <div style={{marginTop:"16px",marginBottom:"16px",marginLeft:"68px"}}>
                <div style={{display:"flex",alignItems:"center"}}>
                    
                        <Typography className="refund_head2" style={{marginRight:"136px"}}>{t("settings.auto_accept_orders")}</Typography>
                    
                    <Switch checked={toggleState} onChange={handleToggleChange} color='warning'/>
                </div>
                <Typography className="refund_para">{t("settings.auto_accept_para")}</Typography>
            </div>
            <Divider style={{ backgroundColor: ' #D9D9D9' }} />
            {toggleState &&
                (<>
                 <Paper style={{marginLeft: '8%', marginTop: '44px', width: "73%", padding: "8px 16px 8px 16px", borderLeft: "2px solid  var(--stroke-color-1-violet, #AE9BE2)" }} className='refund_page'  >

                 
                    <div style={{display:'flex',alignItems:"center"}}>
                        
                            <Typography className="refund_head" style={{marginRight:"60px"}}>{t("settings.show_reason")}</Typography>
                        
                        <Switch checked={toggleStateReject} onChange={handleToggleChangeReject} color='warning'/>
                       
                    </div>
                    <Typography className='refund_para'>{t("settings.show_reasons_para")}</Typography>
                    {toggleStateReject &&
                        (<>
                            <div>
                               
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                <FormGroup>
      {checkboxes.map((checkbox, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={checkbox.checked}
              onChange={() => handleCheckboxChange(index)}
              color='warning'
              sx={{width:"24px",height:"24px"}}
            />
          }
          label={<Typography className='refund_head4'>{checkbox.label}</Typography>}
          className="rounding_chekbox_styling"
        />
      ))}
    </FormGroup>
                                </div>

                                <Button  onClick={handleAdd} style={{ marginTop: '10px' }} className='refund_reason'>
                                   {t("settings.add_reason")}
                                </Button>
                                <div>
                                    {addValue && (
                                        <div style={{ marginLeft: '10px', display: "flex", alignItems: "center" }}>
                                            <TextField
                                                label="Enter Name"
                                                value={newLabel}
                                                onChange={handleNewLabelChange}
                                                variant="outlined"
                                                margin="dense"
                                                style={{ marginTop: '10px' }}
                                                color='warning'
                                            />

                                            
                                            <Button  onClick={handleAddCheckbox} style={{ marginLeft: '10px', display: "flex", alignItems: "center" }} className='refund_reason'>
                                            {t("settings.add_reason")}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Divider style={{ backgroundColor: ' #D9D9D9' }} />
                        </>)
                    }
                    </Paper>
                    <Paper style={{marginLeft: '8%', marginTop: '44px', width: "73%", padding: "8px 16px 8px 16px", borderLeft: "2px solid  var(--stroke-color-2-tale, #9BDAE2)" }} className='refund_page'  >
                    <div>
                        
                            <div style={{display:"flex",alignItems:"center"}}>
                              
                                    <Typography className="refund_head" style={{marginRight:"60px"}}>{t("settings.snooze")}</Typography>
                                
                                <Switch checked={toggleStateSnooze} onChange={handleToggleChangeSnooze} color='warning'/>
                            </div>
                            <Typography className="refund_para">{t("settings.snoze_para")}</Typography>
                        
                        {toggleStateSnooze && (
                            <>
                                <div>
                                    <div style={{ display: "flex",marginTop:'12px' }}>
                                        {checkboxes1.map((checkbox, index) => (
                                            <div key={index}>
                                                <div style={{ display: 'flex', alignItems: 'center',marginRight:"20px" }} className='orders_checkBox2'>
                                                <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={checkbox.checked}
              onChange={() => handleCheckboxChange1(index)}
              color='warning'
            />
          }
          label={<Typography className='refund_head4'>{checkbox.label} {t("settings.min")}</Typography>}
        />
                                                    
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <Button onClick={handleAdd1} style={{ marginTop: '10px' }} className='refund_reason'>
                                    {t("settings.add_snooze")}
                                    </Button>
                                    <div>
                                        {addValue1 && (
                                            <div style={{ marginLeft: '10px', display: "flex", alignItems: "center" }}>
                                                <TextField
                                                    label="value in MIn"
                                                    value={newLabel1}
                                                    onChange={handleNewLabelChange1}
                                                    variant="outlined"
                                                    margin="dense"
                                                    style={{ marginTop: '10px' }}
                                                />
                                                <Button  onClick={handleAddCheckbox1} style={{ marginLeft: '10px', display: "flex", alignItems: "center" }} className='refund_reason'>
                                                    {t("settings.add1")}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                  
                                </div>
                            </>
                        )}
                    </div>
                    </Paper>
                    <Paper style={{marginLeft: '8%', marginTop: '44px', width: "73%", padding: "8px 16px 8px 16px", borderLeft: "2px solid  var(--stroke-color-3-green, #BAE29B)" }} className='refund_page'  >
                    <Typography className="refund_head2">{t("settings.modifying_orders")}</Typography>

<TableContainer component={Paper} elevation={1}>
    <Table>
        <TableHead>
            <TableRow>
                {columns.map((column) => (
                    <TableCell key={column.id} className='refund_head'>{column.label}</TableCell>
                ))}
            </TableRow>
        </TableHead>
        <TableBody>
    {tableData.map((row, rowIndex) => (
        <TableRow key={row.id} className='refund_head5'>
            {columns.map((column) => (
                <TableCell key={column.id} className='refund_head5'>
                    {column.id === 'modifications' ? (
                        row[column.id]
                    ) : (
                        <Select
                            value={row[column.id as keyof typeof data[0]] || "Not Allowed"} // Explicitly cast column.id
                            onChange={(e) => handleValueChange(rowIndex, column.id as keyof typeof data[0], e.target.value)} // Explicitly cast column.id
                            style={{ width: '130px' }}
                            color='warning'
                            sx={{
                                "&:hover": {
                                    "&& fieldset": {
                                        border: "2px solid #F9C584"
                                    }
                                }
                            }}
                        >
                            {[t("settings.allowed"),t("settings.not_allowed")].map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </TableCell>
            ))}
        </TableRow>
    ))}
</TableBody>
    </Table>
</TableContainer>
                        </Paper>
                   
                   
                </>)
            }
        </Paper>
    )
}
export default ManagingOrders;