import React from 'react';
import { Paper, Typography,Button,Divider} from '@mui/material';
import { t } from "i18next";
import './discountReasons.css'
const DiscountReason=()=>{
    return(
        <div>
            <Paper elevation={3}style={{paddingTop:"12px"}}>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginRight:"20px",marginLeft:"24px",marginBottom:"16px"}}>
<Typography className='reasons_head'>{t('reasons.discount_reason')}</Typography>
<Button color='warning' className='reasons_button'>{t("reasons.create_reason")}</Button>

</div>
<Divider/>
<Typography className='reasons_line' style={{marginLeft:"24px",marginTop:'20px',marginBottom:"20px"}}>{t("reasons.reason_type")}</Typography>
<Divider/>

<Typography className='reasons_line2' style={{marginLeft:"24px",marginTop:'20px',marginBottom:"20px"}}>{t("reasons.reason_type")}</Typography>
<Divider/>

<Typography className='reasons_line2' style={{marginLeft:"24px",marginTop:'20px',marginBottom:"20px"}}>{t("reasons.reason_type")}</Typography>
<Divider/>

<div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginRight:"20px",marginLeft:"24px",marginBottom:"16px",marginTop:"10px"}}>
<Typography className='reasons_head'>{t('reasons.void_reason')}</Typography>
<Button color='warning' className='reasons_button'>{t("reasons.create_void")}</Button>

</div>
<Divider/>
<Typography className='reasons_line' style={{marginLeft:"24px",marginTop:'20px',marginBottom:"20px"}}>{t("reasons.reason_type")}</Typography>
<Divider/>

<Typography className='reasons_line2' style={{marginLeft:"24px",marginTop:'20px',marginBottom:"20px"}}>{t("reasons.reason_type")}</Typography>
<Divider/>

<Typography className='reasons_line2' style={{marginLeft:"24px",marginTop:'20px',marginBottom:"20px"}}>{t("reasons.reason_type")}</Typography>
<Divider/>
            </Paper>
        </div>
    )
}
export default DiscountReason