import { Box, Button, Typography,Paper } from '@mui/material'
import React from 'react'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import shoppingIcon from "../../assets/shoppingIcon.png"
import CartCrossSelling from './cartCrossSelling';
import ItemCrossSelling from './itemCrossSellList';
import ItemUpSelling from './itemUpSellList';
import { useNavigate } from 'react-router-dom';

import "./cartCrossSellList.css"


function CartCrossSellList() {
   const navigate = useNavigate()
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };

  return (
  <Box sx={{backgroundColor:"#FCFCFC"}}>
      <Box>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange}>

            <Tab label={
                <Box  sx={{padding:"25px",margin:"20px"}} className={value!=="1"?"cart-cross-sell-list-tab-header-box-hover cart-cross-sell-list-tab-header-box":"cart-cross-sell-list-tab-header-box"}>
                    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                        {value==="1"&&(<img src={shoppingIcon} alt="Shopping Icon"/>)}
                       <Typography className='cart-cross-sell-list-tab-header'>Cart Cross Selling</Typography>
                    </Box>
                        <Button disabled={value!=="1"} onClick={()=>{navigate("/cartCrossSellingCreate")}}  className={value==="1"?'cart-cross-sell-list-tab-active-button':"cart-cross-sell-list-tab-in-active-button"} variant='contained'>Create</Button>
                </Box>
            } 
            value="1" />

            <Tab sx={{margin:"15px"}} label={
                <Box sx={{padding:"25px",margin:"20px"}} className={value!=="2"?"cart-cross-sell-list-tab-header-box-hover cart-cross-sell-list-tab-header-box":"cart-cross-sell-list-tab-header-box"}>
                    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                        {value==="2"&&(<img src={shoppingIcon} alt="Shopping Icon"/>)}
                       <Typography className='cart-cross-sell-list-tab-header'>Item Cross Selling</Typography>
                    </Box>
                    <Box>
                        <Button disabled={value!=="2"} onClick={()=>{navigate("/itemCrossSellingCreate")}} className={value==="2"?'cart-cross-sell-list-tab-active-button':"cart-cross-sell-list-tab-in-active-button"} variant='contained'>Create</Button>
                    </Box>
                </Box>
            } 
             value="2" />
            

               <Tab sx={{margin:"15px"}} label={ 
                <Box sx={{padding:"25px",margin:"20px"}} className={value!=="3"?"cart-cross-sell-list-tab-header-box-hover cart-cross-sell-list-tab-header-box":"cart-cross-sell-list-tab-header-box"}>
                    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                        {value==="3"&&(<img src={shoppingIcon} alt="Shopping Icon"/>)}
                    <Typography className='cart-cross-sell-list-tab-header'>Item Up Selling</Typography>
                    </Box>

                    <Box>
                        <Button disabled={value!=="3"} onClick={()=>{navigate("/itemUpSellingCreate")}} className={value==="3"?'cart-cross-sell-list-tab-active-button':"cart-cross-sell-list-tab-in-active-button"} variant='contained'>Create</Button>
                    </Box>
                </Box>
            }
             value="3" />

          </TabList>
        </Box>

        <TabPanel value="1">
            <CartCrossSelling/>
        </TabPanel>
        <TabPanel value="2">
          <ItemCrossSelling/>
        </TabPanel>

        <TabPanel value="3">
          <ItemUpSelling/>
        </TabPanel>
      </TabContext>

    </Box>
  </Box>
  )
}

export default CartCrossSellList
