import React, { useState, ChangeEvent } from 'react';
import { SelectChangeEvent } from '@mui/material';

import {
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Switch,
} from '@mui/material';
import { TabContext, TabList, TabPanel} from '@mui/lab';
import Tab from '@mui/material/Tab';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import './createAdvance.css';
import './createSalesTax.css';
import BasicModal from './modal';
import DeleteIcon from '@mui/icons-material/Delete';

interface RowData {
  selectType: string;
  amount: string;
  orderType: string;
}

interface OptionA{
  value: string;
  label: string;
}

interface Props {
  data: RowData[];
  options1: OptionA[];
  t: (key: string) => string;
}

interface Option {
  value: string;
  label: string;
}

const options: Option[] = [
  { value: 'Minute', label: "minute"},
  { value: 'Hour', label: "hour" },
  { value: 'Day', label: "day" },
];

const CreateAdvanceBooking: React.FC = () => {
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);

  const handleNavigateExit = () => {
    setModal((prevState) => !prevState);
  };
  const initialData: RowData[] = [
    { selectType: 'delivery', amount: '', orderType: '' },
  ];
  const [data, setData] = useState(initialData);

  const handleAddRow = () => {
    const lastRow = rows[rows.length - 1]; // Get the last row in the array
    const newRow: RowData = {
      selectType: lastRow.selectType,
      amount: lastRow.amount,
      orderType: lastRow.orderType,
    };
  
    setRows([...rows, newRow]); // Add the new row to the rows array and update the state
  };
  
  const handleDeleteRow = (index: number) => {
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
  };
  const options1 = [
    { value: 'delivery', label: 'Delivery' },
    { value: 'collection', label: 'Collection' },
    { value: 'dinIn', label: 'DineIn' },
  ];

  interface TableStyles {
    tableContainer: React.CSSProperties;
    tableHeaderCell: React.CSSProperties;
    tableCell: React.CSSProperties;
  }
  
  
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

   
  

 





  const [deleverySelectedOption1, deleverySetSelectedOption1] = useState<string>('Minute');

  const deleveryChangeSelect1 = (event: SelectChangeEvent<string>) => {
    deleverySetSelectedOption1(event.target.value as string);
  };

  const [deleverySelectedOption2, deleverySetSelectedOption2] = useState<string>('Minute');
  const deleveryChangeSelect2 =(event: SelectChangeEvent<string>) => {
    deleverySetSelectedOption2(event.target.value as string);
  };

  const [deleverySelectedOption3, deleverySetSelectedOption3] = useState<string>('Minute');
  const deleveryChangeSelect3 =(event: SelectChangeEvent<string>) => {
    deleverySetSelectedOption3(event.target.value as string);
  };

  const [deleveryInputValue1, setDeleveryInputValue1] = useState<string>('');
  const deleveryInputChange1 = (event: ChangeEvent<HTMLInputElement>) => {
    setDeleveryInputValue1(event.target.value);
  };

  const [deleveryInputValue2, setDeleveryInputValue2] = useState<string>('');
  const deleveryInputChange2 = (event: ChangeEvent<HTMLInputElement>) => {
    setDeleveryInputValue2(event.target.value);
  };
  const [deleveryInputValue3, setDeleveryInputValue3] = useState<string>('');
  const deleveryInputChange3 = (event: ChangeEvent<HTMLInputElement>) => {
    setDeleveryInputValue3(event.target.value);
  };

  const [collectionSelectedOption1, collectionSetSelectedOption1] = useState<string>('Minute');
  const collectionChangeSelect1 = (event: SelectChangeEvent<string>) => {
    collectionSetSelectedOption1(event.target.value as string);
  };

  const [collectionSelectedOption2, collectionSetSelectedOption2] = useState<string>('Minute');
  const collectionChangeSelect2 = (event: SelectChangeEvent<string>) => {
    collectionSetSelectedOption2(event.target.value as string);
  };

  const [collectionSelectedOption3, collectionSetSelectedOption3] = useState<string>('Minute');
  const collectionChangeSelect3 = (event: SelectChangeEvent<string>) => {
    collectionSetSelectedOption3(event.target.value as string);
  };

  const [collectionInputValue1, setCollectionInputValue1] = useState<string>('');
  const collectionInputChange1 = (event: ChangeEvent<HTMLInputElement>) => {
    setCollectionInputValue1(event.target.value);
  };

  const [collectionInputValue2, setCollectionInputValue2] = useState<string>('');
  const collectionInputChange2 = (event: ChangeEvent<HTMLInputElement>) => {
    setCollectionInputValue2(event.target.value);
  };
  const [collectionInputValue3, setCollectionInputValue3] = useState<string>('');
  const collectionInputChange3 = (event: ChangeEvent<HTMLInputElement>) => {
    setCollectionInputValue3(event.target.value);
  };

  const [dineSelectedOption1, dineInSetSelectedOption1] = useState<string>('Minute');
  const dineInChangeSelect1 = (event: SelectChangeEvent<string>) => {
    dineInSetSelectedOption1(event.target.value);
  };

  const [dineSelectedOption2, dineInSetSelectedOption2] = useState<string>('Minute');
  const dineInChangeSelect2 = (event: SelectChangeEvent<string>) => {
    dineInSetSelectedOption2(event.target.value );
  };

  const [dineSelectedOption3, dineInSetSelectedOption3] = useState<string>('Minute');
  const dineInChangeSelect3 = (event: SelectChangeEvent<string>) => {
    dineInSetSelectedOption3(event.target.value);
  };

  const [dineInInputValue1, setDineInInputValue1] = useState<string>('');
  const dineInInputChange1 = (event: ChangeEvent<HTMLInputElement>) => {
    setDineInInputValue1(event.target.value);
  };

  const [dineInInputValue2, setDineInInputValue2] = useState<string>('');
  const dineInInputChange2 = (event: ChangeEvent<HTMLInputElement>) => {
    setDineInInputValue2(event.target.value);
  };
  const [dineInInputValue3, setDineInInputValue3] = useState<string>('');
  const dineInInputChange3 = (event: ChangeEvent<HTMLInputElement>) => {
    setDineInInputValue3(event.target.value);
  };


const [showTextField, setShowTextField] = useState<boolean>(false);

const toggleTextField = () => {
  setShowTextField(!showTextField);
};

const [advanceInputValue1, setadvanceInputValue1] = useState<string>('');
  const advanceInputChange1 = (event: ChangeEvent<HTMLInputElement>) => {
    setadvanceInputValue1(event.target.value);
  };
 
  const [advanceSelectedOption1, advanceSetSelectedOption3] = useState<string>('Minute');
  const advanceChangeSelect1 = (event: SelectChangeEvent<string>) => {
    advanceSetSelectedOption3(event.target.value as string);
  };
  



const [showTextField1, setShowTextField1] = useState<boolean>(false);

const toggleTextField1 = () => {
  setShowTextField1(!showTextField1);
};

const [rows, setRows] = useState<RowData[]>(data);

  const handleSelectChange = (
    e: SelectChangeEvent<string>,
   
    rowIndex: number,
    field: keyof RowData
  ) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][field] = e.target.value as string;
    setRows(updatedRows);
  };

  const handleTextChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, // Use ChangeEvent<HTMLInputElement> here
    rowIndex: number,
    field: keyof RowData
  ) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][field] = e.target.value;
    setRows(updatedRows);
  };




  return (
    <div>
      <Paper elevation={3} style={{paddingBottom:"10px"}}>
      <div className='create_tax_head_container' style={{ padding: "12px 20px 0px 12px" }}>
      <div className='create_exitIcon'>
        <ArrowBackIcon onClick={handleNavigateExit} sx={{ height: "24px", width: '24px' }} />
      </div>
      <Typography className="create_tax_main">{t("settings.advanced_bookings")}</Typography>
      <div className='create_tax_button_con'>
        <Button variant="contained" className='create_tax_button_style'>
          <Typography>{t("settings.clear")}</Typography>
        </Button>
        <Button variant="contained" className='create_tax_button_style1'>
          <Typography>{t("settings.save")}</Typography>
        </Button>
      </div>
    </div>

    <Paper style={{ marginLeft: '8%', marginTop: '44px', width: '83%', padding: '20px 12px 20px 12px', borderLeft: '2px solid var(--stroke-color-1-violet, #AE9BE2)' }} elevation={1} className='create_tax_page1'>
      <Typography className='craete_advance_head1'>
        {t('settings.advanced_booking_para')} <span className='create_advance_span'>{t('settings.advanced_span')}</span>
      </Typography>

        <Box sx={{ width: '98%',marginTop:'20px' }} className="border_style" >
  <TabContext value={value}>
  <Box>
  <TabContext value={value}>
  <TabList onChange={handleChange} className='tabColor'>
    <Tab
      label={t("settings.delivery")}
      value="1"
      className={value === "1" ? 'change_color' : 'add_color'}
    />
    <Tab
      label={t("settings.collections")}
      value="2"
      className={value === "2" ? 'change_color' : 'add_color'}
    />
    <Tab
      label={t("settings.dine_in")}
      value="3"
      className={value === "3" ? 'change_color' : 'add_color'}
    />
  </TabList>
</TabContext>
    </Box>

    <TabPanel value="1" style={{ padding: '0px' }}>
  <div style={{ backgroundColor: "#FEFEFE", paddingLeft: '20px' }}>
    <Typography className='create_advance_tab_head'>
      {t("settings.advance_booking_time")}
    </Typography>

    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <div>
        <Typography className='create_tax_exception' style={{ marginTop: "10px" }}>
          {t("settings.min_time_advance")}
        </Typography>
        <div style={{ display: "flex", width: "228px", height: "48px" }} className='refund_input_border'>
          <TextField
            variant="standard"
            value={deleveryInputValue1}
            onChange={deleveryInputChange1}
            className='create_advance_input'
            placeholder="10"
            InputProps={{
              disableUnderline: true, // This removes the underline
            }}
            style={{ marginLeft: "10px", marginTop: "15px" }}
          />
         <FormControl style={{ width: '190px', marginBottom: '5px', marginTop: '12px', marginRight: '10px' }} className='create_advance_select' variant='standard'>
      <Select
        value={deleverySelectedOption1}
        onChange={deleveryChangeSelect1}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
        </div>
      </div>
      <div>
        <Typography className='create_tax_exception' style={{ marginTop: "10px", marginLeft: "68px" }}>
          {t("settings.max_time_advance")}
        </Typography>
        <div style={{ display: "flex", width: "228px", marginLeft: "68px" }} className='refund_input_border' >
          <TextField
            variant="standard"
            fullWidth
            value={deleveryInputValue2}
            onChange={deleveryInputChange2}
            className='create_advance_input'
            placeholder="10"
            InputProps={{
              disableUnderline: true, // This removes the underline
            }}
            style={{ marginLeft: "10px", marginTop: "15px" }}
          />
          <FormControl style={{ width: '190px', marginBottom: "5px", marginTop: "12px", marginRight: "10px" }} className='create_advance_select' variant='standard'>
            <Select
              value={deleverySelectedOption2} onChange={deleveryChangeSelect2}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
    <div>
      <Typography className='create_tax_exception' style={{ marginTop: "10px" }}>
        {t("settings.time_slot")}
      </Typography>
      <div style={{ display: "flex", width: "228px", height: "48px" }} className='refund_input_border' >
        <TextField
          variant="standard"
          value={deleveryInputValue3}
          onChange={deleveryInputChange3}
          className='create_advance_input'
          placeholder="10"
          InputProps={{
            disableUnderline: true, // This removes the underline
          }}
          style={{ marginLeft: "10px", marginTop: "15px" }}
        />
        <FormControl style={{ width: '190px', marginBottom: "5px", marginTop: "12px", marginRight: "10px" }} className='create_advance_select' variant='standard'>
          <Select value={deleverySelectedOption3}
    onChange={deleveryChangeSelect3} style={{ marginLeft: "10px" }}>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  </div>
</TabPanel>


<TabPanel value="2" style={{ padding: '0px' }}>
  <div style={{ backgroundColor: "#FEFEFE", paddingLeft: '20px' }}>
    <Typography className='create_advance_tab_head'>
      {t("settings.advance_collection")}
    </Typography>

    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <div>
        <Typography className='create_tax_exception' style={{ marginBottom: "10px" }}>
          {t("settings.min_time_advance")}
        </Typography>
        <div style={{ display: "flex", width: "228px", height: "48px" }} className='refund_input_border'>
          <TextField
            variant="standard"
            value={collectionInputValue1}
            onChange={collectionInputChange1}
            className='create_advance_input'
            placeholder="10"
            InputProps={{
              disableUnderline: true, // This removes the underline
            }}
            style={{ marginLeft: "10px", marginTop: "15px" }}
          />
          <FormControl style={{ width: '190px', marginBottom: "5px", marginTop: "12px", marginRight: "10px" }} className='create_advance_select' variant='standard'>
            <Select value={collectionSelectedOption1} onChange={collectionChangeSelect1} style={{ marginLeft: "10px" }}>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div>
        <Typography className='create_tax_exception' style={{ marginBottom: "10px", marginLeft: '68px' }}>
          {t("settings.max_time_advance")}
        </Typography>
        <div style={{ display: "flex", width: "228px", height: "48px", marginLeft: '68px' }} className='refund_input_border' >
          <TextField
            variant="standard"
            fullWidth
            value={collectionInputValue2}
            onChange={collectionInputChange2}
            className='create_advance_input'
            placeholder="10"
            InputProps={{
              disableUnderline: true, // This removes the underline
            }}
            style={{ marginLeft: "10px", marginTop: "15px" }}
          />
          <FormControl style={{ width: '190px', marginBottom: "5px", marginTop: "12px", marginRight: "10px" }} className='create_advance_select' variant='standard'>
            <Select value={collectionSelectedOption2} onChange={collectionChangeSelect2} style={{ marginLeft: "10px" }}>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
    <div>
      <Typography className='create_tax_exception' style={{ marginTop: "10px" }}>
        {t("settings.time_slot")}
      </Typography>
      <div style={{ display: "flex", width: "228px", height: "48px" }} className='refund_input_border' >
        <TextField
          variant="standard"
          value={collectionInputValue3}
          onChange={collectionInputChange3}
          className='create_advance_input'
          placeholder="10"
          InputProps={{
            disableUnderline: true, // This removes the underline
          }}
          style={{ marginLeft: "10px", marginTop: "15px" }}
        />
        <FormControl style={{ width: '190px', marginBottom: "5px", marginTop: "12px", marginRight: "10px" }} className='create_advance_select' variant='standard'>
          <Select value={collectionSelectedOption3} onChange={collectionChangeSelect3} style={{ marginLeft: "10px" }}>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  </div>
</TabPanel>

<TabPanel value="3" style={{ padding: '0px' }}>
  <div style={{ backgroundColor: "#FEFEFE", paddingLeft: '20px' }}>
    <Typography className='create_advance_tab_head'>
      {t("settings.advance_dining")}
    </Typography>

    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <div>
        <Typography className='create_tax_exception' style={{ marginBottom: "10px" }}>
          {t("settings.min_time_advance")}
        </Typography>
        <div style={{ display: "flex", width: "228px", height: "48px" }} className='refund_input_border'>
          <TextField
            variant="standard"
            value={dineInInputValue1}
            onChange={dineInInputChange1}
            className='create_advance_input'
            placeholder="10"
            InputProps={{
              disableUnderline: true, // This removes the underline
            }}
            style={{ marginLeft: "10px", marginTop: "15px" }}
          />
          <FormControl style={{ width: '190px', marginBottom: "5px", marginTop: "12px", marginRight: "10px" }} className='create_advance_select' variant='standard'>
            <Select value={dineSelectedOption1} onChange={dineInChangeSelect1} style={{ marginLeft: "10px" }}>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div>
        <Typography className='create_tax_exception' style={{ marginBottom: "10px", marginLeft: '68px' }}>
          {t("settings.max_time_advance")}
        </Typography>
        <div style={{ display: "flex", width: "228px", height: "48px", marginLeft: '68px' }} className='refund_input_border' >
          <TextField
            variant="standard"
            fullWidth
            value={dineInInputValue2}
            onChange={dineInInputChange2}
            className='create_advance_input'
            placeholder="10"
            InputProps={{
              disableUnderline: true, // This removes the underline
            }}
            style={{ marginLeft: "10px", marginTop: "15px" }}
          />
          <FormControl style={{ width: '190px', marginBottom: "5px", marginTop: "12px", marginRight: "10px" }} className='create_advance_select' variant='standard'>
            <Select value={dineSelectedOption2} onChange={dineInChangeSelect2} style={{ marginLeft: "10px" }}>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
    <div>
      <Typography className='create_tax_exception' style={{ marginTop: "10px" }}>
        {t("settings.time_slot")}
      </Typography>
      <div style={{ display: "flex", width: "228px", height: "48px" }} className='refund_input_border' >
        <TextField
          variant="standard"
          value={dineInInputValue3}
          onChange={dineInInputChange3}
          className='create_advance_input'
          placeholder="10"
          InputProps={{
            disableUnderline: true, // This removes the underline
          }}
          style={{ marginLeft: "10px", marginTop: "15px" }}
        />
        <FormControl style={{ width: '190px', marginBottom: "5px", marginTop: "12px", marginRight: "10px" }} className='create_advance_select' variant='standard'>
          <Select value={dineSelectedOption3} onChange={dineInChangeSelect3} style={{ marginLeft: "10px" }}>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  </div>
</TabPanel>

  </TabContext>
          </Box>

          {modal && (
          <BasicModal/>
        )}
        </Paper>
        
        <Paper elevation={1} style={{ marginLeft: '8%', marginTop: '44px', width: '83%', padding: '20px 12px 20px 12px', borderLeft: '2px solid var(--stroke-color-2-tale, #9BDAE2)' }}  className='create_tax_page1'>
          <div >

         
      <Typography className='craete_advance_head1'>{t("settings.advance_payment")}</Typography>
      <TableContainer component={Paper}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell className='create_advance_tab_head'>{t('settings.select_type')}</TableCell>
        <TableCell className='create_advance_tab_head'>{t('settings.amount')}</TableCell>
        <TableCell className='create_advance_tab_head'>{t('settings.order_type')}</TableCell>
        <TableCell></TableCell> {/* Empty cell for the delete button */}
      </TableRow>
    </TableHead>
    <TableBody>
      {rows.map((row, rowIndex) => (
        <TableRow key={rowIndex}>
          <TableCell>
            <FormControl className='create_advance_select'>
              <InputLabel className='create_label2'>{t('settings.select%')}</InputLabel>
              <Select
                value={row.selectType}
                label={t('settings.select%')}
                onChange={(e) => handleSelectChange(e, rowIndex, 'selectType')}
                style={{ width: '140px' }} 
                
                color='warning'
                sx={{
                  '&:hover': {
                    '&& fieldset': {
                      border: '2px solid #F9C584',
                    },
                  },
                }}
              >
                <MenuItem value='%'>%</MenuItem>
                <MenuItem value='Flat'>{t('settings.flat')}</MenuItem>
              </Select>
            </FormControl>
            
          </TableCell>
          <TableCell>
            <TextField
              label={<Typography className='create_label2'>{t('settings.amount')}</Typography>}
              variant='outlined'
              value={row.amount}
              onChange={(e) => handleTextChange(e, rowIndex, 'amount')}
              color='warning'
              style={{ width: '140px' }} 
              sx={{
                '&:hover': {
                  '&& fieldset': {
                    border: '2px solid #F9C584',
                  },
                },
              }}
            />
          </TableCell>
          <TableCell>
            <FormControl className='create_advance_select'>
              <InputLabel className='create_label2'>{t('settings.order_type')}</InputLabel>
              <Select
                value={row.orderType}
                label={t('settings.order_type')}
                onChange={(e) => handleSelectChange(e, rowIndex, 'orderType')}
                style={{ width: "140px" }}
                color='warning'
                sx={{
                  '&:hover': {
                    '&& fieldset': {
                      border: '2px solid #F9C584',
                    },
                  },
                }}
              >
                {options1.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableCell>
          <TableCell>
          
              <DeleteIcon onClick={() => handleDeleteRow(rowIndex)}/>
            
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

      <Button onClick={handleAddRow} style={{ marginTop: "12px" }} className='refund_reason'>
        {t("settings.add_new_row")}
      </Button>
      </div>
    </Paper>
    <Paper
      elevation={1}
      className='create_tax_page1'
      style={{
        marginLeft: '8%', marginTop: '44px', width: '83%',
        padding: '20px 12px 20px 12px',
        borderLeft: '2px solid var(--stroke-color-3-green, #BAE29B)',
      }}
    >
      <Typography className='craete_advance_head1'>{t('settings.advance_setting')}</Typography>

      <div style={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}>
        <Typography className='craete_advance_head2' style={{ display: 'flex', marginRight: '30px' }}>
          {t('settings.auto_accept')}
        </Typography>
        <Switch onClick={toggleTextField} color='warning' />
      </div>
      
      {showTextField && (
        <div style={{ width: '70%' }}>
          {/* Notification Time Fields */}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography className='craete_advance_head2'>{t('settings.notification_time')}</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography className='create_tax_exception'>{t('settings.enter_value')}</Typography>
              <div style={{ display: 'flex', width: '228px', height: '48px' }} className='refund_input_border'>
                <TextField
                  variant='standard'
                  value={advanceInputValue1}
                  onChange={advanceInputChange1}
                  className='create_advance_input'
                  placeholder='10'
                  InputProps={{
                    disableUnderline: true, // This removes the underline
                  }}
                  style={{ marginLeft: '10px', marginTop: '15px' }}
                />
                <FormControl
                  style={{ width: '190px', marginBottom: '5px', marginTop: '12px', marginRight: '10px' }}
                  className='create_advance_select'
                  variant='standard'
                >
                  <Select
                    value={advanceSelectedOption1}
                    onChange={advanceChangeSelect1}
                    style={{ marginLeft: '10px' }}
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          {/* Repeat similar blocks for other notification time fields */}
        </div>
      )}

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className='craete_advance_head2' style={{ marginRight: '30px' }}>
          {t('settings.notification_send')}
        </Typography>
        <Switch onClick={toggleTextField1} color='warning' />
      </div>
    </Paper>
           <div>
           
           </div>
           
           
        
        
       
      </Paper>
    </div>
  );
};

export default CreateAdvanceBooking;
