import React, { useState } from "react";
import { Paper, Divider, Chip } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import StoreIcon from '@mui/icons-material/Store';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import GroupsIcon from '@mui/icons-material/Groups';
import CakeIcon from '@mui/icons-material/Cake';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CardActions from '@mui/material/CardActions';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
const CustomerProfile = () => {
    const [activeTab, setActiveTab] = useState('Dashboard')
    const { t } = useTranslation();
    const list = {
        display: 'flex',
        gap: '10px',
        marginBottom: '10px',
        fontWeight: '500',
        alignItems: 'center'
    }
    const breadcrumbs = [
        <Typography key="1">{t("sales.sales_label")}</Typography>,
        <Typography key="3">
            {t("sales.customers")}
        </Typography>,
        <Typography className="primaryColor" key="3">
            {t("sales.customer_profile")}
        </Typography>,
    ];
    const customer = {
        firstName: "Madara",
        lastName: "Uchiha",
        email: "mission@infiniteTsukuyomi.leaf",
        phone: "9632546841",
        fax: "7826945",
        customerGroup: "Hubrise",
        notes: "",
        company: "Leaf village Ichiraku Ramen",
        companyId: "LF25687",
        taxId: "GB123456789",
        address1: "Uchiha clan",
        address2: "Forest base of hokage rock",
        city: "Hidden leaf village",
        postcode: "000015",
        country: "United Kingdom",
        birthday: "04/01/2023",
        anniversary: "05/27/2023",
        newsletter: true,
        status: false,
        defaultAddress: true,
    }
    const addresses = [
        {
            type: 'DEFAULT',
            address1: "Uchiha clan",
            address2: "Forest base of hokage rock",
            city: "Hidden leaf village",
            postcode: "000015",
            country: "United Kingdom",
        },
        {
            type: 'HOME',
            address1: "Uzumaki clan",
            address2: "Forest base of hokage rock",
            city: "Hidden leaf village",
            postcode: "879123",
            country: "United Kingdom",
        },
        {
            type: 'OFFICE',
            address1: "Haruna clan",
            address2: "Forest base of hokage rock",
            city: "Hidden leaf village",
            postcode: "993778",
            country: "United Kingdom",
        }
    ]

    const orderHistory = [
        {
            date: 'Mar 11 2023',
            time: '09:07 AM',
            orderID: '#001',
            orderType: 'Dine In',
            items: '1 Fish Taco, 2 Pizza',
            total: '$ 12'
        },
        {
            date: 'Mar 12 2023',
            time: '06:45 PM',
            orderID: '#002',
            orderType: 'Delivery',
            items: '1 Combo, 2 Coke',
            total: '$ 42'
        },
        {
            date: 'Mar 13 2023',
            time: '04:00 PM',
            orderID: '#003',
            orderType: 'Dine In',
            items: '1 Combo, 2 Coke',
            total: '$ 33'
        },
        {
            date: 'Mar 14 2023',
            time: '10:07 AM',
            orderID: '#004',
            orderType: 'Delivery',
            items: '1 Fish Taco, 2 Pizza',
            total: '$ 50'
        },
    ]

    const orderDateAndTime = (rowData) => {
        return <>
            <p style={{ fontSize: "12px", fontWeight: "600" }}>{rowData.date}</p>
            <p>{rowData.time}</p>
        </>
    }
    const orderDetails = (rowData) => {
        return <>
            <p style={{ fontSize: "12px", fontWeight: "600" }}>{rowData.orderID}</p>
            <p>{rowData.orderType}</p>
        </>
    }

    return (
        <div className="dashboard">
            <Paper className="paper">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
                <Divider className="divider" />
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={3} >
                        <h1 className="flex-between">
                            Madara Uchiha{" "}
                            <Link to="/editCustomer" state={customer} >
                                <Button icon="pi pi-user-edit" severity="warning" outlined rounded />
                            </Link>
                        </h1>
                        <Divider className="divider" />
                        <p style={list}><PhoneIcon style={{ color: 'var(--primary)' }} />9632546841</p>
                        <p style={list}><EmailIcon style={{ color: 'var(--primary)' }} />mission@infiniteTsukuyomi.leaf</p>
                        <p style={list}><PlaceIcon style={{ color: 'var(--primary)' }} />Uchiha clan, Forest base of hokage rock, Hidden leaf village</p>
                        <Divider className="divider" />
                        <p style={list}><StoreIcon style={{ color: 'var(--primary)' }} />Leaf village Ichiraku Ramen</p>
                        <p style={list}><PriceChangeIcon style={{ color: 'var(--primary)' }} />GB123456789</p>
                        <p style={list}><GroupsIcon style={{ color: 'var(--primary)' }} />Hubrise</p>
                        <p style={list}>
                            <p style={list}><CakeIcon style={{ color: 'var(--primary)' }} />01/04/2023</p>
                            <p style={list}><CakeIcon style={{ color: 'var(--primary)' }} />27/02/2023</p>
                        </p>
                        <Card elevation={0} sx={{ background: 'var(--surface-100)' }}>
                            <CardContent sx={{ padding: '10px' }}>
                                <h5>Notes</h5>
                                <ul style={{ fontSize: '11px', fontWeight: '500',listStyleType:'none' }}>
                                    <li>- Prefers less spicy food</li>
                                    <li>- Nut Allergic</li>
                                </ul>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={9} >
                        <Grid container xs={12} md={12} rowSpacing={{ xs: 1, md: 2 }} columnSpacing={{ xs: 1, md: 1 }}>
                            <Grid item xs={12} md={2}>
                                <Link to="/addAddress">
                                    <Card className="addresscard no-outline" variant="outlined" >
                                        <CardContent className="addAddress">
                                            <AddCircleIcon sx={{ fontSize: '45px', opacity: '0.5', color: 'var(--primary)' }} />
                                            <p>Add Address</p>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Grid>
                            {addresses.map(addr => {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <Card
                                            className="addresscard"
                                            variant="outlined"
                                        >
                                            <Link state={addr} to="/editAddress">
                                                <CardContent className="addAddress address">
                                                    <div className="flex-between">
                                                        <Chip
                                                            color={
                                                                addr.type === "DEFAULT"
                                                                    ? "success"
                                                                    : "default"
                                                            }
                                                            label={addr.type}
                                                            size="small"
                                                        />
                                                    </div>
                                                    <p>
                                                        {addr.address1}, {addr.address2},{" "}
                                                        <br />
                                                        {addr.city}, {addr.postcode}, <br />
                                                        {addr.country}
                                                    </p>
                                                </CardContent>
                                            </Link>

                                            <CardActions className="flex-between">
                                                <Button
                                                    icon="pi pi-trash"
                                                    severity="danger"
                                                    text
                                                    rounded
                                                />
                                                {addr.type !== "DEFAULT" && (
                                                    <span className="setDefault">
                                                        Set as default
                                                    </span>
                                                )}
                                            </CardActions>
                                        </Card>
                                  </Grid>
                                );
                            })}
                        </Grid>
                        <Divider className="divider" />
                        <Grid xs={12} md={12}>
                            <div className="tabsHeaderHolder">
                                <div className={activeTab === "Dashboard" && "active"} onClick={() => setActiveTab('Dashboard')}>Dashboard</div>
                                <div className={activeTab === "Order History" && "active"} onClick={() => setActiveTab('Order History')}>Order History</div>
                                <div className={activeTab === "Loyalty Points" && "active"} onClick={() => setActiveTab('Loyalty Points')}>Loyalty Points</div>
                            </div>

                            <div>
                                {activeTab === "Dashboard" && <div className="tab tabFlex">
                                    <div className="tabCard yellow" >
                                        <h2>8</h2>
                                        <p>Total Orders</p>
                                    </div>
                                    <div className="tabCard green" >
                                        <h2>$540</h2>
                                        <p>Total Spent</p>
                                    </div>
                                    <div className="tabCard yellow" >
                                        <h2>$40</h2>
                                        <p>Total Tips</p>
                                    </div>
                                    <div className="tabCard green" >
                                        <h2>607</h2>
                                        <p>Points spent</p>
                                    </div>
                                    <div className="tabCard yellow" >
                                        <h2>1152</h2>
                                        <p>Loyalty Points</p>
                                    </div>
                                </div>}
                                {activeTab === "Order History" && <div className="tab">
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={8}>
                                            <DataTable
                                                value={orderHistory}
                                                emptyMessage="No Order History."
                                            >
                                                <Column body={orderDateAndTime} header="Date" />
                                                <Column body={orderDetails} header="Order Details" />
                                                <Column field="items" header="Items" />
                                                <Column field="total" header="Total" />
                                            </DataTable>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <h4 className="historyHead">Favourite Items</h4>
                                            <div className="historyBody">
                                                <p>Steak burger</p>
                                                <p>Combo Meal</p>
                                                <p>Italian Pizza</p>
                                                <p>Coke</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>}
                                {activeTab === "Loyalty Points" && <div className="tab tabFlex">
                                    <div className="tabCard green" >
                                        <h2>8</h2>
                                        <p>Available Points</p>
                                    </div>
                                    <div className="tabCard yellow" >
                                        <h2>$540</h2>
                                        <p>Life Time Points</p>
                                    </div>
                                    <div className="tabCard green" >
                                        <h2>$40</h2>
                                        <p>Redeemed Points</p>
                                    </div>
                                    <div className="tabCard yellow" >
                                        <h2>607</h2>
                                        <p>Redemptions</p>
                                    </div>
                                    <div className="tabCard green" >
                                        <h2>1152</h2>
                                        <p>Last Redemption </p>
                                    </div>
                                    <div className="tabCard yellow" >
                                        <h2>1152</h2>
                                        <p>Qualified Rewards</p>
                                    </div>
                                </div>}
                            </div>
                        </Grid>
                    </Grid>

                </Grid>
            </Paper>
        </div>
    );
};

export default CustomerProfile;
