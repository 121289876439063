import { Box, Paper, Typography,TextField,Checkbox,FormControl, Switch, IconButton } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Table, TableBody, TableCell, TableContainer ,TableRow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react'

import "./cartCrossSellingLeftPart.css"



const daysOfWeek=["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"]

const menuList=[
  "Biryani",
  "Pizza",
  "Burger"
]

function ItemUpSellingLeftPart() {

  //Cross Sell schedule functions

  const [crossSellScheduleStatus,setCrossSellScheduleStatus]=React.useState(false)

  const handleCrossSellScheduleStatus=()=>{
    setCrossSellScheduleStatus(!crossSellScheduleStatus)
  }

  //Cross Sell schedule functions

  const [crossSellavailabilityStatus,setCrossSellAvailabilityStatus]=React.useState(false)

  const handleCrossSellAvailabilityStatus=()=>{
    setCrossSellAvailabilityStatus(!crossSellavailabilityStatus)
  }



  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date); // Update the selectedDate state with the chosen date
};
  return (
    <Box sx={{width:"50%"}}>
    <Box>
        <Paper elevation={1} sx={{padding:"10px",borderLeft:"1px solid #AE9BE2",marginTop:"15px"}}>
            <Typography className='cart-cross-selling-left-part-details-text'>Details</Typography>
            <Box sx={{display:"flex", flexDirection:"column"}}>
             <TextField
              label={<Typography className='cart-cross-selling-left-input-label'>Internal Name</Typography>}  
               color='warning'
                variant="outlined"
                 sx={{width:"35%",marginTop:"10px",marginBottom:"10px"}} />
             <TextField
              label={<Typography className='cart-cross-selling-left-input-label'>Title</Typography>}
               color='warning'
               variant="outlined"
               sx={{width:"50%",marginTop:"10px",marginBottom:"10px"}} />

            <Autocomplete
                  
                  id="combo-box-demo"
                  options={menuList}
                  sx={{ width: "35%",marginTop:"10px",marginBottom:"10px" }}
                    
                  renderInput={(params) => <TextField color='warning' {...params} label={<Typography className='cart-cross-selling-left-input-label'>Select Menu</Typography>} />}
                />

            </Box>
        </Paper>
    </Box>

    <Box sx={{marginTop:"20px"}}>
        <Paper elevation={1} sx={{padding:"15px",borderLeft:"1px solid #E2A39B"}}>
          <Box className="cart-cross-selling-left-box-border">
          <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"flex-start",width:"37%"}} >
          <IconButton onClick={handleCrossSellScheduleStatus}>
             <Switch color='warning'/>
           </IconButton>
          <Box>   
             <Typography className='cart-cross-selling-left-part-details-text'>Cross Sell Schedule</Typography>
             <Typography className='cart-cross-selling-left-part-set-date-text'>Set date range</Typography>
          </Box>
          </Box>

          {crossSellScheduleStatus && (
            <Box sx={{display:"flex",justifyContent:"space-around",alignItems:"center",width:"100%",marginTop:"10px"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer sx={{width:"120%"}} components={['DatePicker']}>
                    <DatePicker
                    sx={{width:"90%"}}
                        value={selectedDate}
                        onChange={handleDateChange}
                        label="Start date" />
                </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer sx={{width:"120%"}} components={['DatePicker']}>
                    <DatePicker
                       sx={{width:"90%"}}
                        value={selectedDate}
                        onChange={handleDateChange}
                        label="End date" />
                </DemoContainer>
            </LocalizationProvider>
            </Box>
          )}

          </Box>

         <Box className="cart-cross-selling-left-box-border" sx={{marginTop:"15px"}}>
         <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"flex-start",width:"40%",marginTop:"10px",marginBottom:"10px"}} >
          <IconButton onClick={handleCrossSellAvailabilityStatus}>
             <Switch color='warning'/>
           </IconButton>
          <Box>   
             <Typography className='cart-cross-selling-left-part-details-text'>Cross Sell Availability</Typography>
             <Typography className='cart-cross-selling-left-part-set-date-text'>Select items to be offered to guests</Typography>
          </Box>
          </Box>
          {crossSellavailabilityStatus && (
            <Box sx={{width:"100%",padding:"10px"}}>
            {daysOfWeek.map((day, index) => (
                <Box sx={{width:"90%",paddingRight:"10px",margin:"5px",display:"flex",justifyContent:"space-between",alignItems:"center",borderRadius: "6px",border:"1px solid #EEE"}}>
                    
                        <Box className="cart-cross-selling-left-input-label-bg">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox
                                sx={{width:"13.5"}}
                                    color="warning"
                                    // checked={checkedDays[day] || false}
                                    // onChange={(event) => handleDayCheckboxChange(day, event.target.checked)}
                                />
                                <Typography className='cart-cross-selling-left-checkbox-label'>{day}</Typography>
                            </div>
                        </Box>

                        <Box>
                            <FormControl sx={{ m: 1 }} variant="standard" focused>
                                <Typography style={{ marginBottom: "4px" }} className='createMinimum_para'>open</Typography>
                                <TextField
                                    variant="standard"
                                    type='time'
                                    className="createDiscount_timeInput"
                                    id={`standard-adornment-${day}-start`}
                                    // value={openValues[day] || ''}
                                    // onChange={(event) => {
                                    //     handleOpenTimeChange(day, event.target.value);
                                    //     handleTimeChange(day, 'open', event.target.value);
                                    // }}
                                    InputProps={{
                                        disableUnderline: true, // This removes the underline
                                    }}
                                />
                            </FormControl>
                        </Box>

                        <Box>
                            <FormControl sx={{ m: 1 }} variant="standard" focused>
                                <Typography style={{ marginBottom: "4px" }} className='createMinimum_para'>close</Typography>
                                <TextField
                                    variant="standard"
                                    type='time'
                                    className="createDiscount_timeInput"
                                    id={`standard-adornment-${day}-end`}
                                    // value={closeValues[day] || ''}
                                    // onChange={(event) => {
                                    //     handleCloseTimeChange(day, event.target.value)
                                    //     handleTimeChange(day, 'close', event.target.value);
                                    // }}
                                    InputProps={{
                                        disableUnderline: true, // This removes the underline
                                    }}
                                />
                            </FormControl>
                        </Box>

                        <Box>
                            <IconButton>
                            <CloseIcon/>
                            </IconButton>
                        </Box>  
                    
                </Box>
            ))}



       </Box>
          )}
         </Box>


        
            
          

            
        </Paper>
    </Box>
    </Box>
    
  )
}

export default ItemUpSellingLeftPart
