import { gql } from "@apollo/client";

export const GET_OPTIONS_LIST_IN_OPTIONS=gql`
query {
    optionList(first:10,businessId:2,name_Icontains:"toppings") {
            pageInfo{hasNextPage
            hasPreviousPage}
            edges{node{optionId
                
                
                name
                optionValues{
                    name
                }
                }
            }
        
        
    }
        
    }
   
    
`
export const GET_OPTION_GROUP_IN_OPTIONS=gql`
query {
    optionGroupList(first:10,businessId:2) {
            pageInfo{hasNextPage
            hasPreviousPage}
            edges{node{optionGroupId
                
                
            
                optionObjs{
                    name
                    
                    optionId
                    
                name}
                name
                }
            }
        
    }
        
    }
`