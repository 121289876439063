import  React,{ useState, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { DataGrid, GridColDef,  GridRowSelectionModel } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import { FormControl,Select,MenuItem,IconButton ,Button, TextField, Typography} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './productTable.css'

import './BulkProduct.css'
import image from '../../assets/prouctAddImage.png';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Popover from '@mui/material/Popover';


interface Row {
  id: number;
  category: string|null;
  product: string;
  sku:string;
  location :string;
  soldBy: string|null;
  price:string;
  description:string|null;
  optionsGroup:string;
  condtion:boolean;
  image: string | null; 
  clickCount: boolean;
  mainProduct:string;
}





export default function BulkProduct() {
  const rows: Row[] = [
    { id: 1, category: '',sku:"", product: '', location: ' ', soldBy: '', price: "", optionsGroup: "false",description:"",condtion:false,image:"" ,clickCount:true,mainProduct:""},
    { id: 2, category: '',sku:"", product: '', location: ' ', soldBy: '', price: "", optionsGroup: 'false',description:"" ,condtion:false,image:"",clickCount:true,mainProduct:""},
    { id: 3, category: '',sku:"", product: '', location: ' ', soldBy: '', price: "", optionsGroup: 'false',description:"",condtion:false,image:"" ,clickCount:true,mainProduct:""},
    { id: 4, category: '',sku:"", product: '', location: ' ', soldBy: '', price: "", optionsGroup: 'false',description:"",condtion:false,image:"" ,clickCount:true,mainProduct:""},
    { id: 5, category: '',sku:"", product: '', location: ' ', soldBy: '', price: "", optionsGroup: 'false',description:"",condtion:false,image:"" ,clickCount:true ,mainProduct:""},
    
  ];
  

 
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  
  
  const [rows2, setRows] = React.useState<Row[]>(rows);

  


  const handleAddRowClick = (rowId: number) => {
    // Find the index of the row that was clicked
    const rowIndex = rows2.findIndex((row) => row.id === rowId);
  
    if (rowIndex !== -1) {
      // Create a new row with values copied from the previous row
      const newRow: Row = {
        id: Date.now(),
        category: rows2[rowIndex].category,
        sku:rows2[rowIndex].sku,
        product: "",
        location: rows2[rowIndex].location,
        soldBy: rows2[rowIndex].soldBy,
        price: rows2[rowIndex].price,
        optionsGroup: rows2[rowIndex].optionsGroup,
        description: rows2[rowIndex].description,
        condtion:true,
        image: rows2[rowIndex].image,
        clickCount: true,
        mainProduct:rows2[rowIndex].product,
      };
      rows2[rowIndex].clickCount =false;
      // Insert the new row below the clicked row
      const newRows = [...rows2];
      newRows.splice(rowIndex + 1, 0, newRow);
      // Update the state with the new rows
      setRows(newRows);
    }
  };
  
  

  

  const handleProductChange = (rowId: number, newValue: string) => {
    const rowIndex = rows2.findIndex((row) => row.id === rowId);
    if (rowIndex !== -1) {
      const newRows = [...rows2];
      
      newRows[rowIndex].product = newValue;
      setRows(newRows);
    }
  };

  const handleSkuChange = (rowId: number, newValue: string) => {
    const rowIndex = rows2.findIndex((row) => row.id === rowId);
    if (rowIndex !== -1) {
      const newRows = [...rows2];
      
      newRows[rowIndex].sku = newValue;
      setRows(newRows);
    }
  };

  const handlecategoryChange = (rowId: number, newValue: string) => {
    const rowIndex = rows2.findIndex((row) => row.id === rowId);
    if (rowIndex !== -1) {
      const newRows = [...rows2];
      
      newRows[rowIndex].category = newValue;
      setRows(newRows);
    }
  };
  const handleLocationChange = (rowId: number, newValue: string) => {
    const rowIndex = rows2.findIndex((row) => row.id === rowId);
    if (rowIndex !== -1) {
      const newRows = [...rows2];
      
      newRows[rowIndex].location = newValue;
      setRows(newRows);
    }
  };

  const handlePriceChange = (rowId: number, newValue: string) => {
    const rowIndex = rows2.findIndex((row) => row.id === rowId);
    if (rowIndex !== -1) {
      const newRows = [...rows2];
      
      newRows[rowIndex].price = newValue;
      setRows(newRows);
    }
  };

  const handlesoldChange = (rowId: number, newValue: string) => {
    const rowIndex = rows2.findIndex((row) => row.id === rowId);
    if (rowIndex !== -1) {
      const newRows = [...rows2];
      
      newRows[rowIndex].soldBy = newValue;
      setRows(newRows);
    }
  };
  
  const handleDescriptionChange = (rowId: number, newValue: string) => {
    const rowIndex = rows2.findIndex((row) => row.id === rowId);
    if (rowIndex !== -1) {
      const newRows = [...rows2];
      
      newRows[rowIndex].description = newValue;
      setRows(newRows);
    }
  };

  
  

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = e.target?.result as string;
        // Find the row by ID and update its image property
        const updatedRows = rows2.map((row) =>
          row.id === selectedRowId ? { ...row, image } : row
         
          
        );
      
        setRows(updatedRows);
        
        setSelectedRowId(null); // Clear selected row ID
      };
      reader.readAsDataURL(file);
    }
  };
const [row3,setRow3]= React.useState<Row[]>(rows);
  const handleAccordion=(row1:Row)=>{
    setRow3(rows2)
  
    const rowIndex = rows2.findIndex((row) => row.id === row1.id);
    if (rowIndex !== -1) {
      const newRows = [...rows2];
      
      newRows[rowIndex].clickCount = true;
      setRows(newRows);
    }
 const updateRows=rows2.filter((each)=>each.mainProduct!== row1.product)
 
 setRows(updateRows);

  }
  const handleAccordion2=(row1:Row)=>{
    const updateRows=row3
   
    const rowIndex = rows2.findIndex((row) => row.id === row1.id);
    if (rowIndex !== -1) {
      const newRows = [...rows2];
      
      newRows[rowIndex].clickCount = false;
      setRows(newRows);
      setRow3(rows2)
    }
    
    setRows(updateRows);
     }

     console.log(rows2)
  const columns: GridColDef[] = [
  
    {
      field: 'product',
      headerName: 'Products',
      headerClassName: "bulk-product-column",
      width: 400,
      cellClassName:"bulk-product-column",
      resizable: true,
      editable: false,
      renderCell: (params) => {
return(
  !params.row.condtion ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!params.row.clickCount ?(
          <IconButton onClick={()=>handleAccordion(params.row,)}><ArrowDropUpIcon /></IconButton>):(
          <IconButton onClick={()=>handleAccordion2(params.row)}><ArrowDropDownIcon /></IconButton>
          )}
          <Button  className="bulk-product-image1">
          <img
            src={params.row.image || image}
            alt="product"
            className="bulk-product-image"
            onClick={() => {
              const input = document.getElementById('image-upload-input');
              if (input) {
                input.click();
                setSelectedRowId(params.row.id); // Store the selected row ID
              }
            }}
          />
          <input
            type="file"
            hidden
            id="image-upload-input"
            onChange={handleImageUpload}
          />
        </Button>
           <TextField
            value={params.value}
            placeholder='product Name'
            onChange={(e) => handleProductChange(params.row.id, e.target.value)}
            color='warning'
          />
          <div>
      
      <IconButton onClick={() => handleAddRowClick(params.row.id)} className='bulk-product-plus'
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}>
            <AddIcon/> 
          </IconButton>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }} className='bulk-product-popover'>Add variant.</Typography>
      </Popover>
    </div>
         
          
        </div>
      ):( <div style={{ display: 'flex', alignItems: 'center',marginLeft:"30%" }}>
      <Button  className="bulk-product-image1">
      <img
        src={params.row.image || image}
        alt="product"
        className="bulk-product-image"
        onClick={() => {
          const input = document.getElementById('image-upload-input');
          if (input) {
            input.click();
            setSelectedRowId(params.row.id); // Store the selected row ID
          }
        }}
      />
      <input
        type="file"
        hidden
        id="image-upload-input"
        onChange={handleImageUpload}
      />
    </Button>
       <TextField
        value={params.value}
        placeholder='size Name'
        onChange={(e) => handleProductChange(params.row.id, e.target.value)}
        color='warning'
      />
     
  
    </div>)  
)
    },
  },
    {
      field: 'sku',
      headerName: 'Sku',
      headerClassName: 'bulk-product-column',
      cellClassName: 'bulk-product-column',
      editable: false,
      width: 200,
      resizable: true,
      renderCell: (params) => {
        if ( params.row.condition) {
          return (
            <TextField
              value={params.value}
              placeholder='sku'
              onChange={(e) => handleSkuChange(params.row.id, e.target.value)}
              color="warning"
            />
          );
        } else  if (!params.row.condition){
          
          return (
            <TextField
              value={params.value}
              placeholder='sku'
              onChange={(e) => handleSkuChange(params.row.id, e.target.value)}
              color="warning"
            />
          );
        }
        else{
          return(<Typography>b</Typography>)
        }
        }
      },
       
      {
        field: 'price',
        headerName: 'Price',
        headerClassName: "bulk-product-column",
        cellClassName:"bulk-product-column",
        width: 200,
        sortable: true,
        editable: false,
        renderCell: (params) => (
          <TextField
            value={params.value}
            placeholder='price'
            onChange={(e) => handlePriceChange(params.row.id, e.target.value)}
            color='warning'
          />
        ),
      },
    
      {
        field: 'category',
        headerName: 'Category',
        headerClassName: 'bulk-product-column',
        cellClassName: 'bulk-product-column',
        editable: false,
        width: 200,
        renderCell: (params) => {
         
        
          return (
            !params.row.condtion ? (
              <FormControl style={{ width: '80%', paddingRight: '1%' }}>
               
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder='category'
                  value={params.value || ''}
                  onChange={(e) => handlecategoryChange(params.row.id, e.target.value)}
                  
                  color="warning"
                >
                  <MenuItem value={''}> </MenuItem>
                  <MenuItem value={'Burger'}>Burger</MenuItem>
                  <MenuItem value={'Pizza'}>Pizza</MenuItem>
                  <MenuItem value={'Biryani'}>Biryani</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Typography></Typography>
            )
          );
        },
        
      
        
      },
      
   
    {
        field: 'location',
        headerName: 'Location',
        headerClassName: "bulk-product-column",
        cellClassName:"bulk-product-column",
        editable: false,
        width: 200,
        renderCell: (params) => {
          return(
           !params.row.condtion ? (  
         
          <FormControl style={{ width: '80%', paddingRight: '1%' }}>
          
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            placeholder='location'
            value={params.value || ''}
            onChange={(e) => handleLocationChange(params.row.id, e.target.value)}
          
            color="warning"
          >
            <MenuItem value={''}> </MenuItem>
            <MenuItem value={'Hyd'}>Hyd</MenuItem>
            <MenuItem value={'anakaplli'}>Anakaplli</MenuItem>
            <MenuItem value={'Amalapuram'}>Amalapuram</MenuItem>
          </Select>
        </FormControl>
            ):(<Typography>{params.row.location}</Typography>)
          )
        }
      },
      {
        field: 'soldBy',
        headerName: 'Sold By',
        headerClassName: "bulk-product-column",
        width: 200,
        cellClassName:"bulk-product-column",
        editable: false,
        renderCell: (params) => {
          return(
            !params.row.condtion ? (    
          <FormControl style={{width:"80%", paddingRight: "1%" }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              placeholder='sold By'
              value={params.value || ''} // Ensure the value is not undefined
              onChange={(e) => handlesoldChange(params.row.id, e.target.value)}
              color='warning'
            >
                <MenuItem value={""} > </MenuItem>
              <MenuItem value={"perItem"}>Per Item</MenuItem>
              <MenuItem value={"quantity"}>Quantity</MenuItem>
              <MenuItem value={"bulk"}>Bulk</MenuItem>
            </Select>
          </FormControl>):(<Typography>{params.row.soldBy}</Typography>)
       )
      }
      },
    
    {
        field: 'description',
        headerName: 'Description',
        headerClassName: "bulk-product-column",
        cellClassName:"bulk-product-column",
        editable: false,
        width: 200,
        renderCell: (params) => {
          return(
            !params.row.condtion ? (    
          <TextField
          multiline
          placeholder='description'
            value={params.value}
            onChange={(e) => handleDescriptionChange(params.row.id, e.target.value)}
            color='warning'
          />):(<Typography></Typography>)
          )
        }
        
      },
      {
        field:"optionsGroup",
        headerName:"options Group",
        headerClassName: "bulk-product-column",
        cellClassName:"bulk-product-column",
        sortable: false,
        editable: false,
        width: 200,

        renderCell: (params) => {
        
          return(
        
          <Button>add Option</Button>
          )
        }
        
      },
    
   
  ];
  
 
 
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  
  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state
    const updatedRows = rows.filter((row) => !rowSelectionModel.includes(row.id));
    setRows(updatedRows);
    // Clear the selection after deletion
    setRowSelectionModel([]);
  };


  

  
 const cellClass=()=>{
  return 'bulk-product-cell'
 }
 const rowClass=()=>{
  return 'bulk-product-row'
 }

 
 const handleAddRowClick2 = () => {
  // Create a new row with values copied from the last row in the table
  const lastRowIndex = rows2.length;
  const newRow: Row = {
    id: lastRowIndex+1,
    category: "",
    sku:"",
    product: "",
    location: "",
    soldBy: "",
    price: "",
    optionsGroup:"",
    description: "",
    condtion: false,
    image: "",
    clickCount: true,
    mainProduct:"",
  };

  // Insert the new row at the end of the table
  const newRows = [...rows2];
  newRows.splice(0, 0, newRow);

  // Update the state with the new rows
  
  setRows(newRows);
};
console.log(rows2)

const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
       <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",padding:"1%"}}>
                <IconButton className="bulk-product-exit">
            <ArrowBackIcon className="bulk-product-exitIcon"/>
            </IconButton>
            <Typography className="bulk-product-head">Bulk Add/Edit Products</Typography>
            <div style={{display:'flex',alignItems:"center",width:"20%",justifyContent:"space-between"}}>
            <Button onClick={handleAddRowClick2} sx={{textTransform:"none",marginLeft:"2%"}} className='bulk-product-button2'>Add Product</Button>
           
            <Button className="bulk-product-button" style={{textTransform:"none",marginRight:"2px"}}>Save</Button>
            </div>
            
            </div>
    <Box >
      <div style={{width:"100%",textAlign:"right"}}>
      <Delete onClick={handleDeleteClick}/>
      </div>
      <Box
      >
      <DataGrid 
      rows={rows2}
      getCellClassName={cellClass}
      getRowClassName={rowClass}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      pageSizeOptions={[5, 25, 50]}
      checkboxSelection
      autoHeight={true}
      showCellVerticalBorder={true}
      rowHeight={80}
      disableRowSelectionOnClick
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
    />
    </Box>

    </Box>
    
    </div>
  );
}