import React, { useState } from 'react';
import { DragDropContext, Draggable,DropResult  } from  "react-beautiful-dnd";
import { StrictModeDroppable } from "../utils/strictModeDroppable.tsx";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './menuDragDrop.css'
import { Box, Divider, IconButton } from '@mui/material';
import image from'../../assets/prouctAddImage.png'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { CloseOutlined,} from '@mui/icons-material';
import image2 from '../../assets/menuSetup.png'

interface MenuItem {
  id: string;
  content: string;
  category: string;
  course: string;
  variations: { id: number; variation: string }[];
}

interface DragResult {
  source: {
    droppableId: string;
    index: number;
  };
  destination: {
    droppableId: string;
    index: number;
  } | null;
}
interface Props {}
const MenuDragDrop: React.FC<Props> = () => {
  const [leftItems, setLeftItems] = useState<MenuItem[]>([]);

  

  
  const pizza:MenuItem[]=[
  { id: 'italianPizza', content: 'Italian Pizza',category:"pizza",course:"" , variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ]},
  { id: 'maragaritaPizza', content: 'Maragarita Pizza',category:"pizza" ,course:"Main2", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ]},
  { id: 'chikenBrustPizza', content: 'Chiken Brust Pizza',category:"pizza" ,course:"Appetisers", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ]},
  {id:'vegPizza',content:"Veg Pizza",category:"pizza" ,course:"Main", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ]},
  { id: 'italianBiryani', content: 'Italian Biryani',category:"biryani" ,course:"Appetisers", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ] },
  { id: 'maragaritaBiryani', content: 'Maragarita Biryani',category:"biryani" ,course:"Main", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ] },
  { id: 'chickenBreastBiryani', content: 'Chicken Breast Biryani',category:"biryani" ,course:"Main", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ] },
  { id: 'vegBiryani', content: 'Veg Biryani',category:"biryani" ,course:"Main", variations: [
    { id: 1, variation: "8 inch" },
    { id: 2, variation: "10 inch" },
    { id: 3, variation: "12 inch" },
    
  ] }
 
]


// const  biryani:MenuItem[]= [
  
// ]

//left ietms unique filters
const menuItems = [...leftItems];
const uniqueCoursesSet = new Set();
menuItems.forEach(item => {
  uniqueCoursesSet.add(item.course);
});
const uniqueCourses = Array.from(uniqueCoursesSet);

//right items filters
const categories = [...pizza];
const uniqueCatgorySet = new Set();
categories.forEach(item => {
  uniqueCatgorySet.add(item.category);
});
const uniqueCategory = Array.from(uniqueCatgorySet);






const onDragEnd = (result:DropResult) => {


  if (!result.destination) {
    return;
  }

  const sourceListId = result.source.droppableId;
  const destinationListId = result.destination.droppableId;
  let sourceList:MenuItem[]=pizza;

  // switch (sourceListId) {
  //   case 'pizza':
  //     sourceList = pizza;
  //     break;
  //   case 'biryani':
  //     sourceList = biryani;
  //     break;
  //   // Add more cases for other lists as needed
  //   default:
  //     sourceList = [];
  //     break;
  // }

  const destinationList = leftItems;
  const draggedItem = sourceList[result.source.index];
  const copiedItem = { ...draggedItem, id: `${destinationListId}-${Date.now()}` };

  const newDestinationList = Array.from(destinationList);

  if (destinationListId === 'left' && sourceListId !== 'left') {
    newDestinationList.splice(result.destination.index, 0, copiedItem);
  } else if (destinationListId === 'left' && sourceListId === 'left') {
   
    const [removedItem] = newDestinationList.splice(result.source.index, 1);
    newDestinationList.splice(result.destination.index, 0, removedItem);
  }

  setLeftItems(newDestinationList);
};




const handleDelete = (itemId: string, variationId: number) => {
  // Find the index of the menu item in leftItems array
  const itemIndex = leftItems.findIndex((item) => item.id === itemId);

  // Make a copy of the leftItems array
  const updatedLeftItems = [...leftItems];

  // Find the index of the variation to be removed in the variations array
  const variationIndex = updatedLeftItems[itemIndex].variations.findIndex(
    (variation) => variation.id === variationId
  );

  // Remove the variation from the array
  updatedLeftItems[itemIndex].variations.splice(variationIndex, 1);

  // Update the state with the modified leftItems
  setLeftItems(updatedLeftItems);
};


const handleDeleteItem=(id:string)=>{
  console.log(id)
  const updatedItems=leftItems.filter((each)=>(
   
    each.id!==id
  ))
  setLeftItems(updatedItems)

  
}
return (
 
    <div style={{ display: 'flex', width:"100%"}}>
      <DragDropContext onDragEnd={onDragEnd}>
       <div style={{width:"80%"}}>
        <StrictModeDroppable droppableId="left" type="list">
        {(provided) => (
        <ul
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ listStyle: 'none', padding: '0'}}
      >
        {leftItems.length > 0 ? (
          <>
            {uniqueCourses.map((item, index) => {
              const filteredItems = leftItems.filter((each) => each.course === item);
              const uniquePizza: { [key: string]: boolean } = {};
              const deduplicatedPizza = filteredItems.filter(item => {
                if (!uniquePizza[item.content]) {
                  uniquePizza[item.content] = true;
                  return true;
                }
                return false;
              });
              filteredItems.length = 0;
              Array.prototype.push.apply(filteredItems, deduplicatedPizza);

              const menuItems = [...filteredItems];

              const uniqueCategorySet = new Set();

              menuItems.forEach(item => {
                uniqueCategorySet.add(item.category);
              });

              const uniqueCategory = Array.from(uniqueCategorySet);

              return (
                <div key={String(item)}>
                  <Draggable
                    draggableId={`left-${item}`}  // Use a unique identifier
                    index={index}
                  >
                    {(provided) => (
                      <li
                        key={`left-${item}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          margin: '8px',
                          padding: '8px',
                          borderRadius: '4px',
                          ...provided.draggableProps.style,
                        }}
                      >
                        <Accordion key={index}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel-${item}-content`}
                            id={`panel-${item}-header`}
                          >
                            <Box>
                              <Typography className='dragDrop'>{String(item)}</Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            {uniqueCategory.map((filteredItem) => (
                              <Accordion key={String(filteredItem)}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Box className="dragDropImgBackground">
                                      <img src={image} alt='product' className='dragDropImg' />
                                    </Box>
                                    <Box sx={{ marginLeft: "10%" }}>
                                      <Typography className='dragDrop-text'> {String(filteredItem)}</Typography>
                                      <Typography className='dragDrop-text2'>Gluten-free base available upon request. </Typography>
                                    </Box>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Divider />
                                  {filteredItems.map((each) => {
                                    if (each.category === filteredItem) {
                                      return (
                                        <div key={each.id} style={{ display: "flex", alignItems: "center", marginTop: "2%" }}>
                                          <Typography className='dragDrop-text3' sx={{ marginRight: "6%" }}>{each.content}</Typography>
                                          <Stack direction="row" spacing={1}>
                                            {each.variations.map((each1) => (
                                              <Chip label={each1.variation} key={each1.id} onDelete={() => handleDelete(each.id, each1.id)} />
                                            ))}
                                          </Stack>
                                          <IconButton onClick={() => handleDeleteItem(each.id)} style={{marginLeft:"auto"}}>
                                            <CloseOutlined />
                                          </IconButton>
                                        </div>
                                      );
                                    }
                                    return null;
                                  })}
                                </AccordionDetails>
                              </Accordion>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                </div>
              );
            })}
            {provided.placeholder}
          </>
        ) : (
        <Box sx={{width:"100%",textAlign:'center'}}>
          <img src={image2} alt='menu'/>
          <Typography className='dragDrop-text4'>Easy Menu Setup: Drag-and-Drop Categories, Bundles, Products here</Typography>
        </Box>
        )}
      </ul>
    )}
        </StrictModeDroppable>
      </div>
      
        <div style={{ display: 'flex', flexDirection: "column" }} >

          <StrictModeDroppable droppableId="pizza" type="list">
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ listStyle: 'none', padding: '0',  }}
              >
                <div>
                  {uniqueCategory.map((each) => (
                    <Accordion key={String(each)}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{String(each)}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {pizza.map((item, index) => {
                          if (item.category === each) {
                            return (
                              <Draggable  draggableId={`pizza-${item.id}`}  // Use a unique identifier
                              index={index}
                              key={`pizza-${item.id}`}>
                                {(provided) => (
                                  <li
                                    key={item.id}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      margin: '8px',
                                      padding: '8px',
                                      backgroundColor: 'lightgreen',
                                      borderRadius: '4px',
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    {item.content}
                                  </li>
                                )}
                              </Draggable>
                            );
                          }
                          return null;
                        })}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
                {provided.placeholder}
              </ul>
            )}
          </StrictModeDroppable>
        </div>
      </DragDropContext>
    </div>
  
);
};

export default MenuDragDrop;
