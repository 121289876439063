import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  width: "100%",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDropDownIcon sx={{ fontSize: "1.9rem" }} />}
    {...props}
    
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0)",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginRight: theme.spacing(1),
  },
  "&:hover": {
    backgroundColor: "#f0f0f0", // Change to your desired hover background color
    cursor: "pointer", // Add a pointer cursor on hover
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
}));

export default function CustomAccordion(data) {
  const { list } = data;
  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState(false);
  const [accordionClick,setAccordionClick]=React.useState(false)
  const changeaccordioncolor=()=>{
    setAccordionClick(true)
  }
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [selectedItem, setSelectedItem] = React.useState(null);

  const changeselectedcolor = (label) => {
    setSelectedItem(label);
  };

  return (
    <>
      {list.map((item, i) => (
        <Accordion
          expanded={expanded === item.activeItem}
          onChange={handleChange(item.activeItem)}
          key={`${item.activeItem}-${i}`}
        >
          <AccordionSummary className={accordionClick ? "accordionTrue":"accordionFalse"}>
            <Typography>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails onClick={changeaccordioncolor}>
            {item.details.map((options, j) => {
              const { toggleDrawer, label, url } = options;
              return (
                <div
                  className="subMenuOptions"
id={(selectedItem===label) ? ("selectLabel"):("")}
                  key={label}
                  onClick={() => {
                    toggleDrawer(false);
                    navigate(url);
                    changeselectedcolor(label);
                  }}
                  
                >
                  <span>{label}</span>
                </div>
              );
            })}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
