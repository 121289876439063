import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Paper, Divider } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import './customers.css'
export default function ImportCustomers() {
    const { t } = useTranslation();
    const toast = useRef(null);

    const breadcrumbs = [
        <Typography key="1">{t("sales.sales_label")}</Typography>,
        <Typography key="3">
            {t("sales.customers")}
        </Typography>,
        <Typography className="primaryColor" key="3">
            {t("sales.import_customer")}
        </Typography>,
    ];
    const importData = [
        {
            id: 1,
            reqId: 12,
            dateTime: "01-04-2023 12:12:33",
            customers: 2,
            status: "Completed"
        },
        {
            id: 2,
            reqId: 13,
            dateTime: "04-04-2023 09:12:33",
            customers: 7,
            status: "In Progress"
        },
        {
            id: 3,
            reqId: 14,
            dateTime: "08-04-2023 10:12:33",
            customers: 11,
            status: "Failed"
        },
        {
            id: 4,
            reqId: 15,
            dateTime: "12-04-2023 04:12:33",
            customers: 9,
            status: "Completed"
        },

    ];
    const Status = (rowData) => {
        const statuses = {
            Failed: 'danger',
            InProgress: 'warning',
            Completed: 'success',
        }
        return (
            <Tag value={rowData.status} severity={statuses[rowData.status.replace(' ', '')]} />
        );
    };
    const Log = (rowData) => {
        return <a href="file:///C:/Users/king/Downloads/greencart-ui-requirements.pdf" rel="noreferrer" target="_blank">Log</a>
    }
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        setTotalSize(0);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(0);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton } = options;

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                {chooseButton}
                {uploadButton}
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="fileHolder">
                <div className="fileHolder">
                    {file.objectURL && <img alt={file.name} role="presentation" src={file.objectURL} width={100} />}
                    <span className="fileHolder">
                        {file.name}
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" style={{ padding: '10px 15px' }} />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="uploadBody">
                <i className="pi pi-file" style={{ fontSize: '2em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop File Here
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-file', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    const renderHeader = () => {
        return (
            <div style={{ width: '100%' }}>
                <FileUpload ref={fileUploadRef} name="logo_url" url="https://tbsapi.accenflairapps.com/api/v1/image_upload/" accept="text/csv" maxFileSize={1000000}
                    onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                    headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                    chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />

            </div>
        );
    };
    const header = renderHeader();


    return (
        <Box sx={{ width: "100%" }}>
            <Toast ref={toast} />
            <ConfirmDialog />
            <Paper className="paper" sx={{ mb: 2 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
                <Divider className="divider" />
                <div className="card">
                    <DataTable
                        value={importData}
                        paginator
                        rows={5}
                        header={header}
                        rowsPerPageOptions={[5, 10, 25]}
                    >
                        <Column field="reqId" sortable header="Req #"></Column>
                        <Column field="dateTime" sortable header="Request Date & Time"></Column>
                        <Column field="customers" sortable header="Customers Imported"></Column>
                        <Column body={Status} header="Status"></Column>
                        <Column body={Log} header="Logs"></Column>
                    </DataTable>
                </div>
            </Paper>
        </Box>
    );
}
