import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './optionsList.css'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {TextField} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { t } from 'i18next';
import { Divider } from 'primereact/divider';


interface Props{
  
    addbutton:boolean;
    handleAddButton:()=>void;
    handleGroupName:(data:string)=>void;
    handleCreateGroup:()=>void;
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
 
  boxShadow: 24,
  p: 4,
};

export default function CreateOptionGroup(props:Props) {
    const {addbutton,handleAddButton,handleGroupName,handleCreateGroup}=props
   
  
  const [open, setOpen] = React.useState(addbutton);

  
  

  const [textValue,setTextValue]=React.useState('')

  const handleTextValue=(event:React.ChangeEvent<HTMLInputElement>)=>{
    setTextValue(event.target.value)
  }
  
  const handleClose = () => {
    setOpen(false)
    handleAddButton()
    handleGroupName(textValue)
    handleCreateGroup()
    };
 useEffect(()=>{

    setOpen(addbutton)

   
 },[addbutton])

//  const handleOkayButton=()=>{
//     handleStepByProductModal()
//  }
  return (
    <div>
  
    <Modal
      open={open}
     
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Box sx={{display:"flex",alignItems:"center",width:"60%",justifyContent:"space-between"}}>
       <Box className="create-product-exit" sx={{display:"flex",justifyContent:"center",alignItems:"center"}} onClick={handleClose}>
    <CloseIcon className="create-product-exitIcon"  />
    
  </Box> 
  <Typography className='category-created'>Create a Option Group</Typography>
      
      </Box>
      <Divider/>
      <Box sx={{padding:"3% 1% 3% 1%",marginTop:'1%',textAlign:'center'}}>
<TextField color='warning' label="Enter group name" onChange={handleTextValue}/>
    

</Box>

<Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
 
<Button className='create-option-save-button' sx={{marginLeft:"3%",textTransform:"none",backgroundColor:"#F5431C"}} onClick={()=>{handleClose()}}>save</Button>
</Box>
      </Box>
    </Modal>
  </div>
  );
}










