import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Checkbox, FormControlLabel, Paper, TextField, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import imageValue1 from '../../assets/image-avatar.png';
import '../options/createProduct.css'
import './createCategory.css'
import { t } from 'i18next';
import { CloseOutlined } from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import image from '../../assets/createProduct.png';
import Visibility from './visibility';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';

import { useMutation, useQuery } from '@apollo/client';
import AssignProducts from './assigngnProductsModal';
import { UPDATE_CATEGORY } from '../../graphicalQl/mutation/mutation';
import { useNavigate, useParams } from 'react-router';
import { EDIT_CATEGORY } from '../../graphicalQl/usequery/categoryListQuery';
import OptionsModal from './optionsGropModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DELTE_PRODUCT_FROM_CATEGORY } from '../../graphicalQl/mutation/mutation';
import EditDailouge from './editCategoryDailouge';
interface State {
  [key: string]: string | boolean;
  categoryName: string;
  search: string;
  description: string;
  showPOS: boolean;
  showOnline: boolean;
  metaTag: string
}


// interface Category {
//   category:{

 
//   categoryId: string;
//   categoryStatus: string;
//   description: string;
//   image: string | null;
//   isCarry: boolean;
//   isDeleted: boolean;
//   isDinein: boolean;
//   isOnline: boolean;
//   isPos: boolean;
//   metatagDescription: string;
//   metatagKeywords: string[];
//   name: string;
//   productCount: number;
//   productSet: {
//     edges: Array<{
//       node: {
//         isDeleted: boolean;
//         name:string;
//         price: string;
//         productId: string;
      
//       };
    
//     }>;
  
//   };
//   seoKeyword: string | null;
//   slugName: string;
// }
// }

const CreateCategory = () => {

  const { id } = useParams();
  const initialState: State = {

    categoryName: '',
    search: "",
    description: '',
    showPOS: false,
    showOnline: false,
    metaTag: ""
  };

  const [mainState, setState] = useState<State>(initialState);
  
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type, checked } = event.target;

    setState((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const rowStyle=()=>{
    return 'categories-row'
  }
  const [imageValue, setImageValue] = useState<string | null>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageValue(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => (setOpen(true));


  const handleClose = () => setOpen(false);
  const [chips, setChips] = useState<string[]>([]);
  const [newChipText, setNewChipText] = useState('');

  const handleAddChip = () => {
    if (newChipText.trim() !== '') {
      setChips([...chips, newChipText]);
      setNewChipText('');
    }
  };

  const handleDeleteChip = (chipToDelete: string) => {
    setChips(chips.filter((chip) => chip !== chipToDelete));
  };

  const [chips1, setChips1] = useState<string[]>([]);
  const [newChipText1, setNewChipText1] = useState('');

  const handleAddChip1 = () => {
    if (newChipText1.trim() !== '') {
      setChips1([...chips1, newChipText1]);
      setNewChipText1('');
    }
  };

  const handleDeleteChip1 = (chipToDelete: string) => {
    setChips1(chips1.filter((chip) => chip !== chipToDelete));
  };

  const [categoryId, setCategoryId] = useState<string>("")

  const columns: GridColDef[] = [

    {
      field: 'product',
     //@ts-ignore
     headerName: t("menu.catageory.product"),
     
      editable: false,
      flex: 1,

    },
    {
      field: 'price',
    //@ts-ignore
    headerName: t("menu.catageory.price"),
    
      editable: false,
      flex: 1,
    },
    {
      field: '.',
      headerName: '',
      filterable: false,
      width: 110,
      editable: false,
      renderCell: (params) => {
        return (
          <IconButton onClick={()=>handleDelteProduct(params.row.id)}>
          <CloseOutlined  />
          </IconButton>
        )
      }
    },

  ];

  
  const [getData,setGetData]=useState([])
  const handleTime = () => {

  }

  const getDataFromVisibility=(data:any)=>{
    setGetData(data)
  }
  const [getDate,setGetDate]=useState(new Date())

  const handleDate=(data:any)=>{
    setGetDate(data)
  }
  useEffect(()=>{
    if(id){
      setCategoryId(id)
    }
   
  },[id])

  const [updateCategoryData]=useMutation(UPDATE_CATEGORY,{ context: { clientName: 'categoryClient' }})

  const handleUpdateCategoryData=()=>{
    updateCategoryData({variables:{categoryId:categoryId,name:mainState.categoryName,businessId:2,description:mainState.description,isPos:mainState.showPOS,isOnline:mainState.showOnline,metatagDescription:mainState.metaTag,metatagKeywords:JSON.stringify(chips1),seoKeyword:JSON.stringify(chips),showOn:JSON.stringify(getData),hideUntil:getDate,image:imageValue}})
    toast( <div style={{ display: 'flex', alignItems: 'center',height: '8px',justifyContent:"center" }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="white"/>
  </svg>
    <Typography>{t("menu.catageory.changes_updated_succesfully")}</Typography>
  </div>,
    {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      className: 'custom_toast3',
    })
    navigate('/category-listing')
  }
 const navigate=useNavigate()

 

const [assignProductTocategoryData,setAssigncategoryData]=useState([])
 
  const {data:editData}=useQuery(EDIT_CATEGORY,{variables:{categoryId:id}, context: { clientName: 'categoryClient' }})

 
 
useEffect(()=>{
  if (editData){
    
    if (editData.category !==null){
    setState((prevState) => ({
      ...prevState,
      description:editData.category.description,
      categoryName: editData.category.name,
      
      
      showPOS: editData.category.isPos,
      showOnline: editData.category.isOnline,
      metaTag: editData.category.metatagDescription
     
    }
    ));
    if (editData.category.seoKeyword){
      setChips(JSON.parse(editData.category.seoKeyword))
    }
    if (editData.category.metatagKeywords){
      setChips1(JSON.parse(editData.category.metatagKeywords))
    }
    
    const productData=editData.category.productSet.edges.map((item:any) => ({
      id: item.node.productId,
      product:item.node.name,
      price:item.node.price
    }))

    setAssigncategoryData(productData)
  }
}

},[editData])



const [delteProduct]=useMutation(DELTE_PRODUCT_FROM_CATEGORY,{ context: { clientName: 'categoryClient' },refetchQueries: [
  EDIT_CATEGORY, // DocumentNode object parsed with gql
  'Category' // Query name
],})

const handleDelteProduct=(productId:string)=>{
  delteProduct({variables:{categoryId:+categoryId,productId:+productId}})
}


const [modalState,setModalState]=useState(false)

const handleModalState=()=>{
  setModalState(!modalState)
}

  return (
    <Box>
<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "1%", backgroundColor: "white", zIndex: 1 }}>
  <IconButton className="create-product-exit" onClick={handleModalState}>
    <ArrowBackIcon className="create-product-exitIcon" />
  </IconButton>
  <EditDailouge modalState={modalState} handleModalState={handleModalState} handleUpdateCategoryData={handleUpdateCategoryData}/>
<Typography className="create-product-head">{t("menu.catageory.edit_a_category")}</Typography>
  
  <Button className="create-product-button" style={{ textTransform: "none" }} onClick={handleUpdateCategoryData}>{t("menu.catageory.save")}</Button>
</div>


      <Paper elevation={2} style={{ marginLeft: "10%", marginRight: "15%", padding: '1%', borderLeft: "2px solid var(--stroke-color-1-violet, #AE9BE2)" }}>
        <Typography className="create-product-details">{t("menu.catageory.details")}</Typography>
       
          <div style={{ padding: "2%", display: 'flex' }}>

            <div style={{ width: "90%" }}>
              <div className="create-product-text-container">

                <TextField id="outlined-basic"
                size='medium'
                  label={t("menu.catageory.categoryName")}
                  variant="outlined"
                  name="categoryName"
                  value={mainState.categoryName}
                  onChange={handleInputChange}
                  color="warning"
                  style={{ width: '45%' }}
                />

              </div>
              <div style={{ marginTop: "3%" }}>
                <TextField
                  multiline
                  rows={2} 
                
                  label={t("menu.catageory.description")}
                  placeholder="Enter a short description for the category"
                  value={mainState.description}
                  name='description'
                  onChange={handleInputChange}
                  style={{ width: "80%" }}
                  color="warning"
                />
              </div>
            </div>

            <Button
              style={{ width: '20%', marginLeft: '10%',textTransform:"none" }}
            >
              <div className="create-product-img-con">

                <img
                  src={imageValue || imageValue1}
                  alt="product"
                  style={{ width: '100%', backgroundSize: "cover" }}

                  onClick={() => {
                    const input = document.getElementById('image-upload-input');
                    if (input) {
                      input.click();

                    }
                  }}
                />
                {
                  imageValue === null ? (<Typography className="create-product-img-text">{t("menu.catageory.addImg")}</Typography>) : ("")
                }

              </div>

              <input
                type="file"
                hidden
                id="image-upload-input"
                onChange={handleImageUpload}
              />
            </Button>
          </div>



          <div style={{ marginLeft: '3%', marginTop: "1%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ width: "50%" }} >
              <FormControlLabel
                control={<Checkbox color="warning" name='showPOS' checked={mainState.showPOS} onChange={handleInputChange} />}
                label={<Typography className='create-product-switch-text'>{t("menu.catageory.showPos")}</Typography>}
                style={{ width: "40%" }}
              />
              <FormControlLabel
                control={<Checkbox color="warning" name='showOnline' checked={mainState.showOnline} onChange={handleInputChange} />}
                label={<Typography className='create-product-switch-text'>{t("menu.catageory.showOnline")}</Typography>}
                style={{ width: "40%" }}
              />
            </div>

          </div>
        
      </Paper>
      <Paper elevation={2} style={{ marginLeft: "10%", marginRight: "15%", marginTop: "2%", padding: '1%', borderLeft: "2px solid  var(--stroke-color-2-tale, #9BDAE2)" }}>
        <Box>
          <Typography className="create-product-details" style={{ width: "10%" }}>{t("menu.catageory.product")}</Typography>
          {assignProductTocategoryData.length > 0 ? (<Box sx={{ width: '100%' }}>
            <DataGrid
              rows={assignProductTocategoryData}
              columns={columns}
              getRowClassName={rowStyle}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}

              disableRowSelectionOnClick
            />
          </Box>) : (<div style={{ textAlign: 'center' }}><img src={image} alt="product" style={{width:"36%"}}/></div>)}
<Box sx={{display:"flex",justifyContent:"center",alignItem:"center",marginTop:"1%"}}>
          <Button className='createCategory' onClick={handleOpen} sx={{textTransform:"none"}}>
           {t("menu.catageory.assign_products")}
          </Button>
          </Box>
          <div>
            <AssignProducts open={open} upDateOpen={handleClose} categoryId={categoryId}  />


          </div>
        </Box>

      </Paper>
      <Paper elevation={2} style={{ marginLeft: "10%", marginRight: "15%", marginTop: "2%", padding: '1%', borderLeft: "2px solid  var(--stroke-color-2-tale, #9BDAE2)" }}>
      <Typography className="create-product-details">{t("menu.catageory.options")}</Typography>
        <OptionsModal categoryId={categoryId} />

      </Paper>
      <Paper elevation={2} style={{ marginLeft: "10%", marginRight: "15%", marginTop: "2%", padding: '1% 1% 1% 2%', borderLeft: "2px solid  var(--stroke-color-4-brown, #E2A39B)" }}>
      <Visibility handleTime2={handleTime} handleData={getDataFromVisibility} handleDate={handleDate}/>
      </Paper>

      <Paper elevation={2} style={{ margin: '1% 15% 0% 10%', padding: '1%', borderLeft: '2px solid var(--stroke-color-3-green, #BAE29B)', backgroundColor: '#FCFCFC' }}>
        <Typography className="create-product-details">{t("menu.catageory.aditional_settings")}</Typography>
        <div style={{ display: "flex", marginTop: "2%", width: "70%" }} className='create-product-chip'>
          <div>
            {chips.map((chip) => (
              <Chip
                key={chip}
                label={chip}
                onDelete={() => handleDeleteChip(chip)}

              />
            ))}
          </div>
          <div style={{ marginLeft: "auto" }}>
            <TextField
              label={t("menu.catageory.seo_keyword")}
              variant="standard"
              value={newChipText}
              onChange={(e) => setNewChipText(e.target.value)}
            />
            <IconButton onClick={handleAddChip} >
              <AddIcon />
            </IconButton>
          </div>
        </div>
        <TextField multiline 
        label={t("menu.catageory.meta_description")}
          placeholder="Meta tag description"
          name='metaTag'
          value={mainState.metaTag}
         
          onChange={handleInputChange}
          style={{ width: "70%", marginTop: '2%' }} color="warning" />
          
        <div style={{ display: "flex", marginTop: "2%", width: "70%" }} className='create-product-chip'>
          <div>
          
            { chips1.map((chip) => (
              <Chip
                key={chip}
                label={chip}
                onDelete={() => handleDeleteChip1(chip)}

              />
            ))}
          </div>
          <div style={{ marginLeft: "auto" }}>
            <TextField
              label={t("menu.catageory.meta_keywords")}
              variant="standard"

              value={newChipText1}
              onChange={(e) => setNewChipText1(e.target.value)}
            />
            <IconButton onClick={handleAddChip1} >
              <AddIcon />
            </IconButton>
          </div>
        </div>

      </Paper>

    </Box>
  );
}

export default CreateCategory;
