import React, { useState, useEffect } from 'react'

import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputBase, Box, Button, Divider, Paper, Typography } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import { USER_REQUESTS } from '../../graphicalQl/usequery/userProfileUseQuery';
import { UNBLOCK_EMPLOYEE } from '../../graphicalQl/mutation/userProfileMutation';
import { useQuery, useMutation } from '@apollo/client';
import { t } from "i18next"
import "./userRequests.css"


interface StateUserRequests {
  id: string,
  userName: string,
  email: string,
  date: string,
  phone: string,
  pin: string,
  role: string
}






function UserRequests() {
  const { data, loading, error,refetch } = useQuery(USER_REQUESTS, { context: { clientName: "userClient" } })
  if(error){
    console.log(error.message)
  }
  const [usersRequestsList, setUsersRequestsList] = useState<StateUserRequests[]>([])

  useEffect(() => {
    if (data) {
      const requiredDataList = data.unblockRequestList.edges.map((each: any) => ({
        id: each.node.employee.employeeId,
        userName: `${each.node.employee.firstName} ${each.node.employee.lastName}`,
        email: each.node.employee.email,
        status: each.node.employeeStatus,
        date: each.node.employee.createdOn,
        pin: each.node.employee.pin,
        phone: each.node.employee.mobile,
        role: each.node.employee.role.roleName


      }

      ))
      setUsersRequestsList(requiredDataList)
    }
  }, [data]);



  
  const [unblockEmployee] = useMutation(UNBLOCK_EMPLOYEE, {
    onCompleted: (data) => {
      console.log(data)
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: 'userClient' },
    refetchQueries:[USER_REQUESTS]
  });


  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [showData, setShowData] = React.useState(usersRequestsList.map(() => false));

  const getRowClassname = () => {
    return "driver-list-table-row"
  }

  const handleUnblockButton = (id: string) => {
    unblockEmployee({ variables: { employeeId: id } })
    refetch()
  }

  const columns: GridColDef[] = [
    { field: 'id', headerClassName: "user-role-header-names", headerName: 'ID', width: 80 },
    {
      field: 'userName',
      //@ts-ignore
      headerName: t("user_requests.user_name"),
      headerClassName: "user-role-header-names",
      flex: 1,

    },
    {
      field: 'email',
      //@ts-ignore
      headerName: t("user_requests.email"),
      headerClassName: "user-role-header-names",
      flex: 1,

    },
    {
      field: 'phone',
      //@ts-ignore
      headerName: t("user_requests.phone"),
      headerClassName: "user-role-header-names",
      flex: 1,

    },
    {
      field: 'role',
      //@ts-ignore
      headerName: t("user_requests.role"),
      headerClassName: "user-role-header-names",
      flex: 0.5,


    },
    {
      field: 'date',
      //@ts-ignore
      headerName: t("user_requests.requested_on"),
      headerClassName: "user-role-header-names",
      flex: 1.5,

    },

    {
      field: 'pin',
      //@ts-ignore
      headerName: t("user_requests.reveal_pin"),
      headerClassName: "user-role-header-names",
      flex: 1,

      renderCell: (params) => {

        //show the visibleOffIcon and pin
        const toggleVisibility = (index: number) => {
          const updatedShowData = [...showData];
          updatedShowData[index] = !updatedShowData[index];
          setShowData(updatedShowData);


        };
        //show the visibleIcon and stars
        const toggleVisibilityHide = (index: any) => {
          const updatedShowData = [...showData];
          updatedShowData[index] = !updatedShowData[index];
          setShowData(updatedShowData);
        }

        return (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            {showData[params.row.id] ?  params.value :"* ".repeat(params.value.length)}
            {showData[params.row.id] ?
            <IconButton onClick={() => toggleVisibilityHide(params.row.id)}>
            <VisibilityOffIcon sx={{ marginLeft: "15px" }} />

          </IconButton>
              :
             
                <IconButton onClick={() => toggleVisibility(params.row.id)}>
                <VisibilityIcon sx={{ marginLeft: "15px" }} />
              </IconButton>}
          </Box>
        )
      },
    },
    {
      field: 'status',
      //@ts-ignore
      headerName: t("user_requests.actions"),
      headerClassName: "user-role-header-names",
      flex: 1,


      renderCell: (params) => (
        <Button onClick={() => handleUnblockButton(params.row.id)} sx={{ textTransform: "none" }} color='warning' className='user-requests-header-inside-table-button' variant='outlined'>{params.row.status}</Button>
      ),

    }
  ];

  //Search results
  const [searchInput, setSearchInput] = React.useState("")

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
  }

  const rowsOfSearchInputResults = usersRequestsList.filter((each) => (each.userName.toLowerCase().includes(searchInput)))





  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state
    const updatedRows = usersRequestsList.filter((row) => !rowSelectionModel.includes(row.id));
    setUsersRequestsList(updatedRows);
    // Clear the selection after deletion
    setRowSelectionModel([]);
  };
  return (
    <Box>
      <Paper sx={{ padding: "15px" }} >
        <Box className='user-requests-header-order'>
          <Typography className='user-requests-header-title'>{t("user_requests.user_requests")}</Typography>
          <Button sx={{ textTransform: "none" }} variant='contained' color='warning'>{t("user_requests.add_user")}</Button>
        </Box>
        <Divider />
        <Paper
          component="form"
          sx={{ p: "2px 4px", marginTop: "10px", display: "flex", alignItems: "center", width: 400 }}
        >
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            onChange={handleSearchInput}
            //@ts-ignore
            placeholder={t("user_requests.search_by_name")}
            inputProps={{ "aria-label": "search google maps" }}
          />
        </Paper>
        <Box sx={{ marginTop: "10px", padding: "15px" }}>
          
          <DataGrid
            rows={rowsOfSearchInputResults}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 7, 10]}
            
            disableRowSelectionOnClick
            getRowClassName={getRowClassname}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
          />

        </Box>
      </Paper>
    </Box>
  )

}
export default UserRequests
