import { Switch ,TextField} from '@mui/material'
import React from "react"
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Box, Typography,Button,Popover, IconButton,List,ListItem,ListItemText,Divider } from '@mui/material'

import "./cartCrossSelling.css"



const initialRows=[
    {
        id:1,
        name:"Drinks",
        status:"Active",
        modifiedDate:"22-07-23; 11:20 AM",
        condition:false
    },
    {
        id:2,
        name:"Snacks",
        status:"Active",
        modifiedDate:"22-07-23; 11:21 AM",
        condition:false
    },
    {
        id:3,
        name:"Biryani's",
        status:"Active",
        modifiedDate:"22-07-23; 11:22 AM",
        condition:false
    },
    {
        id:4,
        name:"Drinks",
        status:"Active",
        modifiedDate:"22-07-23; 11:24 AM",
        condition:false
    },
]


  interface Row {
    id: number;
    name:string
    status:string
    modifiedDate:string
    condition:boolean
    
    
   
  }
interface cartCrossProps{
  
}

function CartCrossSelling() {

  
    
    const [rows,setRows]=React.useState(initialRows)
    const [searchInput,setSearchInput]=React.useState("")
    const [newName,setNewName]=React.useState("")
    const rowsFilteredBySearch=rows.filter((each)=>(
        each.name.toLocaleLowerCase().includes(searchInput)
    ))

    const getRowClassname=()=>{
        return "cart-cross-selling-row"
    }
    const [value, setValue] = React.useState('1');
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const PopoverOpen = Boolean(anchorEl);
    const id = PopoverOpen ? 'simple-popover' : undefined;

    const handleSearchInput=(event:React.ChangeEvent<HTMLInputElement>)=>{
        setSearchInput(event.target.value)
    }

    const handleMoreVertIcon = (id:number,event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        handleUpdateId(id)
      };

    const handlePopoverClose = () => {
        setAnchorEl(null);
      };

      const handleSkuChange = (rowId: number, newValue: string) => {
        setNewName(newValue)
      const rowIndex = rows2.findIndex((row) => row.id === rowId);
        if (rowIndex !== -1) {
          const newRows = [...rows2];    
          newRows[rowIndex].name = newValue;
         
          setRows(newRows);
        }
      };

    const columns: GridColDef[] = [
       
        {
          field: 'name',
          headerName: 'Name',
          headerClassName:"cart-cross-selling-column",
          flex:2,
          renderCell: (params) => {
            if ( params.row.condition) {
              return (
                <TextField
                  value={newName}
                  name="name"
                  placeholder='Name'
                  onChange={(e) => handleSkuChange(params.row.id, e.target.value)}
                  color="warning"
                />
              );
            } 
            else{
              return(<Typography>{params.value}</Typography>)
            }
            }
          
        },
        {
          field: 'status',
          headerName: 'Status',
          headerClassName:"cart-cross-selling-column",
          flex:1,
          renderCell:(params)=>(
            <Box>
                <IconButton>
                    <Switch color='warning'/>
                </IconButton>
            </Box>
          )
        },
        {
          field: 'modifiedDate',
          headerName: 'Last Modified date',
          headerClassName:"cart-cross-selling-column",
          type: 'modifiedDate',
          width:250
          
          
        },
        {
            field:"actions",
            headerName:"",
            disableColumnMenu:true,
            renderCell:(params)=>(
                <Box>
                    <IconButton onClick={(event)=>handleMoreVertIcon(params.row.id,event)}>
                    <MoreVertIcon/>
                </IconButton>
                      <Popover
                      id={id}
                      open={PopoverOpen}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                      }}
                      style={{
                        width: "180px",
                        height: "225px",
                        paddingTop: "0px",
                        paddingBottom: "0px"
                      }}
                      elevation={8}
                    >
                      {popoverContent}
                    </Popover>
                </Box>
            )
        }
        
        
      ];

  //Add new Row Functions

  const [idOfAboveNewLine,setIdOfAboveNewLine]=React.useState(0)
  const [rows2, setRows2] = React.useState<Row[]>(rows);
  const [newStatus,setNewStatus]=React.useState("")
  
  const [newDate,setNewDate]=React.useState("")
  

  const handleUpdateId=(id:number)=>{
    setIdOfAboveNewLine(id)
  }

  const handleAddRowClick = () => {
    // Find the index of the row that was clicked
    let rowIndex = rows2.findIndex((row) => row.id === idOfAboveNewLine);
    handlePopoverClose()
    setNewName(rows[rowIndex].name)
    if (rowIndex !== -1) {
      // Create a new row with values copied from the previous row
      const presentTime=new Date()
      const newRow: Row = {
        id: Date.now(),
        status:rows2[rowIndex].status,
        name:rows2[rowIndex].name,
        modifiedDate:presentTime.toString(),
        condition:true

        
       
      };
      // Insert the new row below the clicked row
      const newRows = [...rows2];
      newRows.splice(rowIndex + 1, 0, newRow);
  
      // Update the state with the new rows
    
      setRows(newRows);
    
    }
  };
  const popoverContent = (
    <List style={{ padding: '0px 0px 0px 0px' }}>
      <Button style={{ textTransform:"capitalize",padding: '0px 0px 0px 0px' }}>
        <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
          <ListItemText className='device-set-up-table-row-hover' primary="Edit" />
        </ListItem>
      </Button>
      <Divider />
      <Button onClick={handleAddRowClick} style={{textTransform:"capitalize", padding: '0px 0px 0px 0px' }}>
        <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
          <ListItemText className='device-set-up-table-row-hover' primary="Duplicate" />
        </ListItem>
      </Button>
      <Divider />
      <Button style={{textTransform:"capitalize", padding: '0px 0px 0px 0px' }}>
        <ListItem style={{ width: '180px', height: '40px' }} className='pover-text-style'>
          <ListItemText className='device-set-up-table-row-hover' primary="Delete" />
        </ListItem>
      </Button> 
    </List>
  );

  return (
    <Box sx={{background:"#FEFEFE"}}>
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"35%"}}>
            <Typography className='cart-cross-selling-header'>Cart Cross Selling</Typography>
            <Paper
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "60%" }}
                >
                <IconButton type="button" sx={{ p: '10px' }}>
                    <SearchIcon />
                </IconButton>
                <InputBase
                color='warning'
                   onChange={handleSearchInput}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by name"
                    
                />
                </Paper>
        </Box>
        <Box sx={{ height: 400, width: '100%',margin:"15px" }}>
        <DataGrid
            rows={rowsFilteredBySearch}
            columns={columns}
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 5,
                },
            },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            getRowClassName={getRowClassname}
          />
         </Box>
    </Box>
  )
}

export default CartCrossSelling
