import { gql } from "@apollo/client";

export const CREATE_OPTION_GROUP=gql`
mutation createOptionGroup($name:String,$options:JSONString) {
    createOptionGroup(
        businessId:2,
        name:$name,
        options:$options
        
      ) {
            errorMessage
            optionGroup{optionGroupId
            name
            businessId
            options}
        success
         
        
      }
    }
`
export const DELETE_OPTIONGROUP_OPTION=gql`
mutation deleteOptionGroup($optionGroupId:Int!) {
  deleteOptionGroup(
  
    optionGroupId:$optionGroupId
    ) {
      message
      success
       
      
    }
  }
`