import React, { useEffect, useState } from 'react'
import { Table, TableContainer, TableBody, TableRow, TableCell, Checkbox, FormControl, InputLabel, Input, IconButton, Button } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

const BusinessHours = (props) => {

    const { data, updateState } = props
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600); // Set the threshold for mobile screens
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Initial check for mobile screen on component mount
        handleResize();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleCheckboxChange = (event, index) => {
        const { checked } = event.target;
        const updatedBusinessHours = [...data];
        updatedBusinessHours[index].selected = checked;
        updateState(updatedBusinessHours);
    };

    const renderInputField = (value, onChange, onBlur, error, inputType, index) => {
        return (
            <FormControl
                sx={{ m: 1 }}
                variant="standard"
                focused
                key={`${inputType}-${index}`}
            >
                <InputLabel htmlFor="standard-adornment-block-start">
                    {inputType}
                </InputLabel>
                <Input
                    type="time"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                    style={{ margin: "5px", padding: '10px 0 5px', width: '90px' }}
                />
            </FormControl>
        );
    };

    const handleInputChange = (event, index, setIndex, field) => {
        const { value } = event.target;
        const updatedBusinessHours = [...data];
        updatedBusinessHours[index].sets[setIndex][field] = value;

        const hasValidInput = updatedBusinessHours[index].sets.some(
            (set) => set.start && set.end && set.start < set.end
        );

        updatedBusinessHours[index].selected = hasValidInput;
        updateState(updatedBusinessHours);
    };

    const handleInputBlur = (index, setIndex, field) => {
        const updatedBusinessHours = [...data];
        const { start, end } = updatedBusinessHours[index].sets[setIndex];

        if (field === 'start' && end && start >= end) {
            updatedBusinessHours[index].sets[setIndex].end = '';
        } else if (field === 'end' && start && end <= start) {
            updatedBusinessHours[index].sets[setIndex].start = '';
        }

        updateState(updatedBusinessHours);
    };


    const handleAddSet = (index) => {
        const updatedBusinessHours = [...data];
        updatedBusinessHours[index].sets.push({ start: '', end: '' });
        updateState(updatedBusinessHours);
    };

    const handleClearRow = (index, setIndex) => {
        const updatedBusinessHours = [...data];
        if (updatedBusinessHours[index].sets.length === 1) {
            updatedBusinessHours[index].sets = [{ start: '', end: '' }]
            updatedBusinessHours[index].selected = false
        } else {
            updatedBusinessHours[index].sets.splice(setIndex, 1);
        }
        updateState(updatedBusinessHours);
    };

    const renderDeleteButton = (rowData, index, setIndex) => {
        return (
            !isMobile ?
                <IconButton
                    onClick={() => handleClearRow(index, setIndex)}
                >
                    <DeleteIcon />
                </IconButton> :
                <Button
                    size="small"
                    variant="text"
                    color="warning"
                >
                    Clear time
                </Button>
        );
    };
    const hasInputValues = (hour) => {
        return hour?.sets.every((set) => set.start && set.end);
    };
    const renderPlusButton = (rowData, index) => {
        return (
            hasInputValues(rowData) && (
                <IconButton onClick={() => handleAddSet(index)} disabled={!rowData.selected && !hasInputValues(rowData)}>
                    <AddIcon />
                </IconButton>
            )
        );
    };


    return <TableContainer sx={12}>
        <Table>
            <TableBody>
                {data.map((hour, index) => (
                    <TableRow key={index}>
                        <TableCell width={'50px'}>
                            <Checkbox
                                checked={hour.selected}
                                onChange={(e) => handleCheckboxChange(e, index)}
                                color="warning"
                            />
                        </TableCell>
                        <TableCell width={'90px'}>{hour.day}</TableCell>
                        <TableCell>
                            {hour.sets.map((set, setIndex) => (
                                <React.Fragment>
                                    {renderInputField(
                                        set.start,
                                        (e) => handleInputChange(e, index, setIndex, 'start'),
                                        () => handleInputBlur(index, setIndex, 'start'),
                                        (set.start && set.end && set.start >= set.end),
                                        'Open',
                                        setIndex
                                    )}
                                    {renderInputField(
                                        set.end,
                                        (e) => handleInputChange(e, index, setIndex, 'end'),
                                        () => handleInputBlur(index, setIndex, 'end'),
                                        (set.start && set.end && set.end <= set.start),
                                        'Close',
                                        setIndex
                                    )}
                                    {renderDeleteButton(hour, index, setIndex)}
                                    <br />
                                </React.Fragment>
                            ))}

                        </TableCell>
                        <TableCell>{renderPlusButton(hour, index)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}

export default BusinessHours