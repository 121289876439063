import React from "react";
import { Box,Typography,Checkbox,FormControl,Radio,RadioGroup,FormControlLabel, TextField, List, ListItem } from "@mui/material"
import Modal from '@mui/material/Modal';
import { t } from 'i18next';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import  { useState } from 'react';
import  { Dayjs } from 'dayjs';
import { Button } from "primereact/button";
import '../options/createProduct.css'
import './createCategory.css'

interface Prop{
 
  handleTime2: (data1:string,data2:string) => void;
  handleData: (data:any) => void;
  handleDate:(data2:any)=>void;
 
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
  };

  const style2 = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
  };
  


const Visibility=(props:Prop)=>{

  const weekdays=[
    
    {id:2,day:t("menu.catageory.monday"),isChecked:false},
    {id:3,day:t("menu.catageory.tuesDay"),isChecked:false},
    {id:4,day:t("menu.catageory.wednesday"),isChecked:false},
    {id:5,day:t("menu.catageory.thursday"),isChecked:false},
    {id:6,day:t("menu.catageory.friday"),isChecked:false},
    {id:7,day:t("menu.catageory.saturday"),isChecked:false},
    {id:8,day:t("menu.catageory.sunday"),isChecked:false},
]

   const{handleTime2,handleData,handleDate}=props

  //  useEffect(() => {
  //   handleData();
  // }, []);
  
    const [radioValue, setValue] = React.useState('');

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    
  };
  const [open, setOpen] = React.useState(false);
 const handleClose=()=>{
  setOpen(false)
  handleData(formattedData);
}
 const handleOpen=()=>setOpen(true)

 const [openValue, setOpenValue] = React.useState(false);
 const handleCloseValue=()=>{
  setOpenValue(false)
  handleDate(selectedDate)
}
 const handleOpenValue=()=>setOpenValue(true)
  
 const [startTime, setSartTime] = React.useState('');
const [timeStart,setTimeStart]=React.useState("")

 const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setSartTime(event.target.value);
    const [hours, minutes] = event.target.value.split(":");
    const hour = parseInt(hours, 10);
    const isPM = hour >= 12;
    const amPmHour = hour % 12 || 12; // Convert to 12-hour format
    
    setTimeStart(`${amPmHour}:${minutes} ${isPM ? "PM" : "AM"}`);
    
 };
 const [endTime, setEndTime] = React.useState('');
 const [timeEnd, settimeEnd] = React.useState('');
 const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setEndTime(event.target.value);
    const [hours, minutes] = event.target.value.split(":");
    const hour = parseInt(hours, 10);
    const isPM = hour >= 12;
    const amPmHour = hour % 12 || 12; // Convert to 12-hour format
    
     settimeEnd(`${amPmHour}:${minutes} ${isPM ? "PM" : "AM"}`);
 };

 React.useEffect(() => {
  handleTime2(timeStart,timeEnd)
  
  
}, [timeStart,timeEnd]);
 
const [weekdaysState, setWeekdaysState] = useState(weekdays);
  const [allDayChecked, setAllDayChecked] = useState<boolean|string>(false);

  const updateAllDayCheckedState = (updatedWeekdays: any) => {
    const allChecked = updatedWeekdays.every((day: any) => day.isChecked);
    const someChecked = updatedWeekdays.some((day: any) => day.isChecked);
    if (allChecked) {
      setAllDayChecked(true);
    } else if (someChecked) {
      setAllDayChecked('indeterminate');
    } else {
      setAllDayChecked(false);
    }
  };

  const handleCheckboxChange = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedWeekdays = weekdaysState.map((day) => {
      if (day.id === id) {
        return { ...day, isChecked: event.target.checked };
      }
      return day;
    });
    setWeekdaysState(updatedWeekdays);
    updateAllDayCheckedState(updatedWeekdays);
  };

  const handleAllDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const updatedWeekdays = weekdaysState.map((day) => ({ ...day, isChecked }));
    setWeekdaysState(updatedWeekdays);
    updateAllDayCheckedState(updatedWeekdays);
  };

const formattedData = weekdaysState.map((each) => ({
  [each.day]: {
    isOpen: each.isChecked,
    openTime: startTime,
    closingTime: endTime
  }
}));

const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs(new Date()));

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setSelectedDate(newDate);
    }
  };
  const formattedDateAndTime =selectedDate.format("D MMM hh:mm A");

  
    return(
        <Box>
          <Box sx={{display:"flex"}}>
          <Typography className="create-product-details" style={{width:"24%",}}>{t("menu.catageory.visibility")}<span className='createCategory-para'>({t("menu.catageory.availabityOfCategory")}) </span></Typography>
          {
  radioValue === 'showOnlyFrom' ? (
    <Typography className="category-visibility-showOnly">{t("menu.catageory.only_from")} {startTime} {t("menu.catageory.to")} {endTime}</Typography>
  ) : radioValue === 'hideUntil' ? (
    <Typography className="category-visibility-until">{t("menu.catageory.until")} {formattedDateAndTime}</Typography>
  ) : null
}
</Box>
        <FormControl>
    
     <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={radioValue}
        onChange={handleChange1}
        style={{marginTop:"1%"}}
       
      >
        <FormControlLabel value="showOnlyFrom" control={<Radio color='warning'/>} label={<Typography className='createCategory-radio-text'>{t("menu.catageory.showOnlyFrom")}</Typography>}   onClick={handleOpen}/>
        <FormControlLabel value="hideUntil" control={<Radio color='warning' />} label={<Typography className='createCategory-radio-text'>{t("menu.catageory.hideUntil")}</Typography>} onClick={handleOpenValue}/>
        <FormControlLabel value="hideFromMenu" control={<Radio color='warning'/>} label={<Typography className='createCategory-radio-text'>{t("menu.catageory.hideFromMenu")}</Typography>} />
      </RadioGroup>
    </FormControl>
    <div>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
            <Box sx={{textAlign:'center'}}>
         <Typography className="createCategory-table-head">{t("menu.catageory.showTime")}</Typography>
         <Box sx={{display:"flex",alignItems:"center",justifyContent:'space-around',marginTop:"2%"}}>
            <Box sx={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                <Typography className="create_category_startTime">{t("menu.catageory.startTime")}</Typography>
         <TextField
     
        
        type="time"
       
        color="warning"
        value={startTime}
        onChange={handleStartTimeChange}
      />
      </Box>
      <Box sx={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
        <Typography  className="create_category_startTime">{t("menu.catageory.endTime")}</Typography>
      <TextField
     
    
     type="time"
    
     color="warning"
     value={endTime}
     onChange={handleEndTimeChange}
     
   />
   </Box>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            color="warning"
            checked={allDayChecked === true}
            indeterminate={allDayChecked === 'indeterminate'}
            onChange={handleAllDayChange}
          />
        }
        label={<Typography className="createCategory-row2">{t("menu.catageory.all")}</Typography>}
        className="createCategory-checkbox2"
      />
      <List style={{ display: 'flex', flexWrap: 'wrap' }}>
        {weekdaysState.map((each) => (
          <ListItem key={each.id} style={{ width: '45%', marginLeft: '5%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="warning"
                  checked={each.isChecked}
                  onChange={handleCheckboxChange(each.id)}
                />
              }
              label={<Typography className="createCategory-row2">{each.day}</Typography>}
              className="createCategory-checkbox"
            />
          </ListItem>
        ))}
      </List>
         </Box>
         <Box sx={{width:"100%"}}>
         <Button onClick={handleClose} style={{marginLeft:"auto"}} className="createCategory">{t("menu.catageory.save")}</Button>
        </Box>
        </Box>
      </Modal>
      <Modal
        open={openValue}
        onClose={handleCloseValue}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      
      >
        <Box sx={style2} >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          
          'StaticDateTimePicker',
        ]}
       
      >
        
        <DemoItem >
        <StaticDateTimePicker
  disablePast={true}
  value={selectedDate}
  onChange={handleDateChange}
  components={{
    ActionBar: () => (
      <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, padding: '10px', backgroundColor: 'white', textAlign: 'right' }}>
        
        <Button key="accept" style={{backgroundColor:"#F38B08",color:"#FEFEFE"}} onClick={handleCloseValue}>Accept</Button>
      </div>
    ),
    
    
  }}
  
  
/>
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
        </Box>
      </Modal>
    </div>
   
    
        </Box>
    )
}
export default Visibility;