
import * as React from 'react';
import { useEffect,useState,useRef   } from 'react';
import { Box, Button, Divider, List, ListItem, Paper, Switch, TextField, Typography } from "@mui/material"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './recieptBuilder.css';
import image from '../../assets/prouctAddImage.png'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Draggable from 'react-draggable';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SketchPicker, ColorResult } from 'react-color';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


type BasicDataItem = {
  value: string;
  checked: boolean;
  // Add other properties as needed
};
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  interface List {
    id: number;
    fontSize: string;
    fontWeight: string;
    fontStyle: string;
  }
  
  interface Data {
    id: number;
    value: string;
  }
  interface Order{
   
    image:{id:number,value:string,checked:boolean},
    name:{id:number,value:string,checked:boolean},
    address:{id:number,value:string,checked:boolean}, 
    orderType:{id:number,value:string,checked:boolean}, 
    orderId:{id:number,value:string,checked:boolean}, 
    date:{id:number,value:Date,checked:boolean}, 
    items:{id:number,value:[{qty:number,name:string,price:number,id:number}],checked:boolean},
    paymentType:{id:number,value:string,checked:boolean}, 
    payStatus:{id:number,value:string,checked:boolean}, 
    total:{id:number,value:string,checked:boolean}, 
    customerDetails:{id:number,value:string,checked:boolean}, 
    timeRequested:{id:number,value:string,checked:boolean}, 
    servedBy:{id:number,value:string,checked:boolean}, 
    aditionalText:{id:number,value:string,checked:boolean}, 
    poweredBy:{id:number,value:string,checked:boolean}, 
    showServerNum:{id:number,value:string,checked:boolean}, 
    showBarCode:{id:number,value:string,checked:boolean}, 
    showQr:{id:number,value:string,checked:boolean}, 

  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{width:"100%",marginLeft:"2%"}}
      >
        {value === index && (
            <Paper>
          <Box sx={{ p: 3 }}>
            
            <Box >{children}</Box>
          </Box>
          </Paper>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  
  
   
const RecieptBuilder=()=>{
   
      
    const [value, setValue] = React.useState(0);
   
    const [imageValue, setImageValue] = React.useState<string | null>(null);
const [imageResult,setImageResult]=React.useState(image)
    

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageResult(e.target?.result as string);

      };
      reader.readAsDataURL(file);
    }
  };
  const orderDetailsHeader:Order[]=[{
        
    image:{id:1,value:imageResult,checked:false},
    name:{id:2,value:"mefhill",checked:false},
    address:{id:3,value:"Restaurent address here..Babukhan  Hyderabad, Telangana 500081,  079767 73165",checked:false},
    orderType:{id:4,value:"collection",checked:false},
    orderId:{id:5,value:" Order Id #12",checked:false},
    date:{id:6,value:new Date(),checked:false},
    items:{id:7, value: [{ qty: 1 , name:  "pizza", price:  40,id:1,}], checked: false } ,
    
    paymentType: { id:8,value: "online", checked: false },
    payStatus: { id:9,value: " pay Status done", checked: false },
    total: {id:10, value:"sub Total 10", checked: false },
    customerDetails: {id:11, value: " ashok Kandregula", checked: false },
    timeRequested: { id:12,value: " time Requested 20 min", checked: false },
    servedBy: { id:13,value: "served By  Bujji", checked: false },
    
    aditionalText: {id:14, value: "Take home a bag of meatballs and 2 pkgs. of cream sauce for only $9.99 Made from an authentic recipe!", checked: false },
    poweredBy: {id:15, value: "mefhill", checked: false },
    showServerNum: {id:16, value: "#22", checked: false },
    showBarCode: {id:17, value: "", checked: false },
    showQr: {id:18, value: "", checked: false },
  }]
  const [basicData,setBasicData]=React.useState<Order[]>(orderDetailsHeader)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const inputDate=new Date();
  
  const day = String(inputDate.getDate()).padStart(2, '0');
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const year = String(inputDate.getFullYear()).slice(-2); // Get the last two digits of the year
  const hours = String(inputDate.getHours()).padStart(2, '0');
  const minutes = String(inputDate.getMinutes()).padStart(2, '0');
    const formattedDate1 = `${day}-${month}-${year}, ${hours}:${minutes}`;
    const formattedDate2 = `${month}-${day}-${year}, ${hours}:${minutes}`;
    const formattedDate3 = `${year}-${month}-${day}, ${hours}:${minutes}`;
   

    const handleCheckValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
      
        setBasicData((prevValue) => {
          return prevValue.map((item) => {
            return {
              ...item,
              [name]: { value: (item[name as keyof Order] as any).value, checked,id:Date.now() },
            };
          });
        });
      };

      const [checkedList, setCheckedList] = React.useState<any[]>([]);

      useEffect(() => {
        const satisfiedObjects: any[] = [];
      
        basicData.forEach((item) => {
          for (const k in item) {
            const obj = item[k as keyof Order];
            if (obj && obj.checked) {
              satisfiedObjects.push(obj);
            }
          }
        });
      
        setCheckedList(satisfiedObjects);
      }, [basicData]);
      
  
      
      const [fontSize, setFontSize] = useState<string>('');
  const [fontWeight, setFontWeight] = useState<string>('');
  const [fontStyle, setFontStyle] = useState<string>('');
  const [saveButton, setSaveButton] = useState(false);
  const [basicStyle, setBasicStyle] = useState<List[]>([]);
  const [idValue,setidValue]=useState<number>(0)

  const data: Data[] = [
    { id: 1, value: 'ashok' },
    { id: 2, value: 'kandregula' },
    { id: 3, value: 'anakaplle' },
  ];

  const handleStyle = (key: number) => {
    setSaveButton(true);
    setidValue(key)
    
  };

  
  
  const handleSave2 = () => {
    setSaveButton(false);
  };
  console.log(basicStyle)
  useEffect(()=>{
    const existingStyleIndex = basicStyle.findIndex((style:List) => style.id === idValue);
    if (existingStyleIndex !== -1) {
      const updatedStyles = [...basicStyle];
      updatedStyles[existingStyleIndex] = {
        id: idValue,
        fontSize,
        fontWeight,
        fontStyle,
      };
      setBasicStyle(updatedStyles);
    } else {
      // If no existing style, add a new one
      setBasicStyle((prev:any) => [...prev, { id: idValue, fontSize, fontWeight, fontStyle }]);
    }
  },[fontSize,fontWeight,fontStyle,idValue])

      
      console.log(basicStyle)

      const downloadPDF = () => {
        const elementToCapture = document.getElementById('capture');
        
        if (elementToCapture) {
          html2canvas(elementToCapture).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
    
            const pdf = new jsPDF();
            const width = pdf.internal.pageSize.getWidth();
            const height = (canvas.height * width) / canvas.width;
    
            pdf.addImage(imgData, 'PNG', 0, 0, width, height);
    
            pdf.save('your-pdf-name.pdf'); // Change 'your-pdf-name.pdf' to the desired filename
          });
        } else {
          console.error("Element with ID 'capture' not found.");
        }
      };
     

  

const [position, setPosition] = useState({ x: 0, y: 0 });

const trackPos = (id:string,result:any) => {
  console.log(result)
  const updatedCheckedList = checkedList.map((item) => {
    if (item.id === id) {
      // Update the item with the new coordinates
      return {
        ...item,
        x: result.x,
        y: result.y,
      };
    }
    return item;
  });

  setCheckedList(updatedCheckedList);
  setPosition({ x: result.x, y: result.y });
}; 

const [selectedValue, setSelectedValue] = useState('date1'); // Initialize with the default value 'date1'

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const [selectedValue2, setSelectedValue2] = useState('qty1'); // Initialize with the default value 'date1'

  const handleSelectChange2= (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue2(event.target.value);
  };
  

   const nodeRef = useRef(null);
//   const representData=()=>{
//     return(
//       <Box>
//          <Paper elevation={2} sx={{ width: "300px", marginTop: "4%", minHeight: "40%",border:"solid 1px #333" }}>
   
//    <List>
//    {checkedList.map((item, index) => (
//      <Draggable key={item.id} onDrag={(e, data) => trackPos(data)} nodeRef={nodeRef}>
//        <ListItem key={item.id} onDoubleClick={() => handleStyle(item.id)} ref={nodeRef}>
//          {Array.isArray(item.value) ? (
//            item.value.map((item1: { name: string, qty: number, price: number, id: number }) => (
//              <div key={item1.id} style={basicStyle.find((style: List) => style.id === item.id)}>
//              {selectedValue2 === "qty1" ? (
//                <Typography>{item1.qty} * {item1.name} {item1.price}</Typography>
//              ) : selectedValue2 === "qty2" ? (
//                <Typography>{item1.qty} {item1.price} {item1.name}</Typography>
//              ) : (
//                <Typography>{item1.name} {item1.price} {item1.qty}</Typography>
//              )}
//              {/* <div>
//                x: {position.x.toFixed(0)}, y: {position.y.toFixed(0)}
//              </div> */}
//            </div>
          
           
           
           
           
           
           
//            ))
//          ) : item.value.toString().slice(-3) === 'png' ? (
//            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
//              <img src={image} alt="restaurant" style={basicStyle.find((style: List) => style.id === item.id)} className="reciept-image-style" />
//            </Box>
//          ) : item.value instanceof Date ? (
//            selectedValue === 'date1' ? (
//              <Typography style={basicStyle.find((style: List) => style.id === item.id)}> Date: {formattedDate1}</Typography>
//            ) : selectedValue === 'date2' ? (
//              <Typography style={basicStyle.find((style: List) => style.id === item.id)}>Date: {formattedDate2}</Typography>
//            ) : (
//              <Typography style={basicStyle.find((style: List) => style.id === item.id)}>Date: {formattedDate3}</Typography>
//            )
//          ) : (
//            <Box>
//              <Typography style={basicStyle.find((style: List) => style.id === item.id)}>
//                {item.value}
//              </Typography>
//            </Box>
//          )}
//        </ListItem>
//      </Draggable>
//    ))}
//  </List>



//  </Paper>
//       </Box>
//     )
//   }
  const [selectedColor, setSelectedColor] = useState<string>('#000000');
 
  const handleColorChange = (color: ColorResult) => {
    setSelectedColor(color.hex);
  };
  const initialDragAndDropData = [
    {
      id: 1,
      content: 
      
          <Divider sx={{ borderTop: "1px solid" }} />
      
      
    },
    {
      id: 2,
      content: 
      
          <Divider sx={{ borderTop: "1px dotted" }} />
       
      
    },
  ];
  
 
  const handleDragStop=(data:any)=>{
    console.log(data)
  }
  
    return(
      <Box>
       
        <Box sx={{display:"flex",alignItems:'center'}}>
        <Typography>Divider</Typography>
          <List sx={{width:'90%'}} >
            {initialDragAndDropData.map((each)=>(
              
                <ListItem key={each.id} >
                <Box sx={{border:"1px solid",padding:"2%",width:"10%",marginRight:"3%",marginLeft:'3%'}}>
                <Draggable key={each.id}  onStop={(data)=>handleDragStop(data)}>
                {each.content}
                </Draggable>
                </Box>
                </ListItem> 
             
            ))}
          </List>
       
         
          {/* <Draggable key={"solid"}>
        <Box sx={{border:"1px solid",padding:"2%",width:"10%",marginRight:"3%",marginLeft:'3%'}}>
        <Divider sx={{borderTop:"1px solid"}}/>
        </Box>
        </Draggable>
        <Box sx={{border:"1px solid",padding:"2%",width:"10%"}}>
          <Divider sx={{borderTop:"1px dotted"}}/>
        </Box>
        */}
        </Box>
     
        <Box sx={{display:"flex"}}>
         
            
          
          
        <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex',width:"60%"}}
      >
        <Tabs
          orientation="vertical"
        //   variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Header" {...a11yProps(0)}  sx={{textTransform:"none"}}/>
          <Tab label="Body" {...a11yProps(1)} sx={{textTransform:"none"}}/>
          <Tab label="Footer" {...a11yProps(2)} sx={{textTransform:"none"}}/>
          <Tab label="Branding" {...a11yProps(3)} sx={{textTransform:"none"}}/>
         
        </Tabs>
        <TabPanel value={value} index={0}>
<Box>
        
            
     <Box className="reciept-box" sx={{justifyContent:"space-between"}}>    
         
  <img src={imageResult || image} alt="profile" style={{ width:"10%"}} onClick={() => {
            const input = document.getElementById('image-upload-input');
            if (input) {
              input.click();
            }
          }}/>
        <input
        type="file"
        hidden
        id="image-upload-input"
        onChange={handleImageUpload}
      />
    
      {!imageResult && (  <Typography>Add the company image here</Typography>)}
      <Switch color='warning' name="image" onChange={handleCheckValue}   checked={basicData[0].image.checked}/>
      </Box>  
      <Box className="reciept-box" sx={{textAlign:"center"}}  >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Restaurant Name</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name="name" onChange={handleCheckValue} checked={basicData[0].name.checked}/>
      </Box>
      <Box className="reciept-box"  sx={{textAlign:"center"}} >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Address</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name='address' onChange={handleCheckValue} checked={basicData[0].address.checked}/>
      </Box>
      <Box className="reciept-box"  sx={{textAlign:"center"}} >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Order Type</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name="orderType"onChange={handleCheckValue} checked={basicData[0].orderType.checked}/>
      </Box>
      <Box className="reciept-box"  sx={{textAlign:"center"}} >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Order Id</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name='orderId' onChange={handleCheckValue} checked={basicData[0].orderId.checked}/>
      </Box>
      <Box className="reciept-box2"  sx={{justifyContent:"space-between"}} >
<Typography className='reciept-box-text' >Date & Time</Typography>
<FormControl sx={{width:"40%"}}>
           <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={selectedValue}
        onChange={handleSelectChange}
        name="radio-buttons-group"
      >
        <FormControlLabel value="date1" control={<Radio color='warning'/>} label={<Typography >{formattedDate1}</Typography>} className='reciept-box3'/>
        <FormControlLabel value="date2" control={<Radio color='warning'/>} label={<Typography>{formattedDate2}</Typography>}  className='reciept-box3' />
        <FormControlLabel value="date3" control={<Radio color='warning'/>} label={<Typography>{formattedDate3}</Typography>}  className='reciept-box3'/>
      </RadioGroup>
    </FormControl>
<Switch color='warning' name='date' onChange={handleCheckValue} checked={basicData[0].date.checked}/>
      </Box>

</Box>
 

        
        </TabPanel>
        <TabPanel value={value} index={1}>
        <Box className="reciept-box2"  sx={{justifyContent:"space-between"}} >
{/* <Typography className='reciept-box-text' >Items</Typography> */}
<FormControl sx={{width:"80%"}}>
           <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={selectedValue2}
        onChange={handleSelectChange2}
        name="radio-buttons-group"
      >
        <FormControlLabel value="qty1" control={<Radio color='warning'/>} label={<Typography >qty1 * Item name     $price</Typography>} className='reciept-box3'/>
        <FormControlLabel value="qty2" control={<Radio color='warning'/>} label={<Typography>qty1 x Item name     $price</Typography>}  className='reciept-box3' />
        <FormControlLabel value="qty3" control={<Radio color='warning'/>} label={<Typography>qty1   Item name     $price</Typography>}  className='reciept-box3'/>
      </RadioGroup>
    </FormControl>
<Switch color='warning' name='items'onChange={handleCheckValue} checked={basicData[0].items.checked}/>
      </Box>
        <Box className="reciept-box" sx={{textAlign:"center"}}  >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Payment Type</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name='paymentType' onChange={handleCheckValue} checked={basicData[0].paymentType.checked}/>
      </Box>
      <Box className="reciept-box"  sx={{textAlign:"center"}} >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Pay Status</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name='payStatus' onChange={handleCheckValue} checked={basicData[0].payStatus.checked}/>
      </Box>
      <Box className="reciept-box"  sx={{textAlign:"center"}} >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Total</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name='total' onChange={handleCheckValue} checked={basicData[0].total.checked}/>
      </Box>
      <Box className="reciept-box"  sx={{textAlign:"center"}} >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Customer Details</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name='customerDetails' onChange={handleCheckValue} checked={basicData[0].customerDetails.checked}/>
      </Box>
      <Box className="reciept-box"  sx={{textAlign:"center"}} >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Time Requested</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name='timeRequested' onChange={handleCheckValue} checked={basicData[0].timeRequested.checked}/>
      </Box>
     
        </TabPanel>
        <TabPanel value={value} index={2}>
        <Box className="reciept-box" sx={{textAlign:"center"}}  >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Additional Text</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name='aditionalText'onChange={handleCheckValue} checked={basicData[0].aditionalText.checked}/>
      </Box>
      <Box className="reciept-box"  sx={{textAlign:"center"}} >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Powered by</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name='poweredBy' onChange={handleCheckValue} checked={basicData[0].poweredBy.checked}/>
      </Box>
      <Box className="reciept-box"  sx={{textAlign:"center"}} >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Show server num</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name='showServerNum' onChange={handleCheckValue} checked={basicData[0].showServerNum.checked}/>
      </Box>
      <Box className="reciept-box"  sx={{textAlign:"center"}} >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Show Barcode</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name='showBarCode' onChange={handleCheckValue} checked={basicData[0].showBarCode.checked}/>
      </Box>
      <Box className="reciept-box"  sx={{textAlign:"center"}} >
<Typography className='reciept-box-text' sx={{width:"80%"}}>Show QR code</Typography>
<Switch color='warning' sx={{marginLeft:"auto"}} name='showQr' onChange={handleCheckValue} checked={basicData[0].showQr.checked}/>
      </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
        <div>
      <div>
        <div
        
         
        >
          
            <div >
              <div />
              <SketchPicker color={selectedColor} onChange={handleColorChange} />
            </div>
          
        </div>
      </div>
      <p>Selected color: {selectedColor}</p>
    </div>
        </TabPanel>
        
      </Box>
    
      {/* <Button onClick={downloadPDF}>Download PDF</Button> */}

      <Box sx={{width:"40%",marginLeft:"2%",display:'flex',flexDirection:"column",alignItems:"center",}} id="capture">
      {saveButton && (
        <div style={{display:"flex",justifyContent:"center",marginTop:'1%',width:"100%"}}>
          
          
      <FormControl sx={{width:"20%",marginRight:"1%"  }}>
        <InputLabel id="demo-simple-select-label">fontSize</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={fontSize}
          label="fontSize"
          onChange={(e) => setFontSize(e.target.value)}
          color='warning'
        >
          <MenuItem value="20px">20px</MenuItem>
          <MenuItem value="18px">18px</MenuItem>
          <MenuItem value="16px">16px</MenuItem>
        </Select>
      </FormControl>
   
          
          
      <FormControl sx={{width:"20%" ,marginRight:"1%" }}>
        <InputLabel id="demo-simple-select-label">fontWeight</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={fontWeight} onChange={(e) => setFontWeight(e.target.value)}
          label="fontWeight"
          color='warning'
        >
          <MenuItem value="bold">bold</MenuItem>
          <MenuItem value="normal">normal</MenuItem>
          <MenuItem value="500">500</MenuItem>
          <MenuItem value="200">200</MenuItem>
        </Select>
      </FormControl>
   
          
          
      <FormControl sx={{width:"20%",marginRight:"1%" }}>
        <InputLabel id="demo-simple-select-label">fontStyle</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
           value={fontStyle} onChange={(e) => setFontStyle(e.target.value)}
          label="fontStyle"
          color='warning'
        >
          <MenuItem value="italin">italic</MenuItem>
          <MenuItem value="normal">normal</MenuItem>
         
        </Select>
      </FormControl>
   
          <button onClick={handleSave2}>Save</button>
        </div>
      )}
      <Paper elevation={2} sx={{ width: "300px", marginTop: "4%", minHeight: "80%",border:"solid 1px #333" }}>
   
      <List>
      {checkedList.map((item, index) => (
        <Draggable key={item.id}  onDrag={(result:any) => trackPos(item.id,result)} nodeRef={nodeRef}  defaultPosition={{x: 0, y: 0}} >
          <ListItem key={item.id} onDoubleClick={() => handleStyle(item.id)} ref={nodeRef}>
            {Array.isArray(item.value) ? (
              item.value.map((item1: { name: string, qty: number, price: number, id: number }) => (
                <div key={item1.id} style={basicStyle.find((style: List) => style.id === item.id)} className="itemHover">
                {selectedValue2 === "qty1" ? (
                  <Typography>{item1.qty} * {item1.name} {item1.price}</Typography>
                ) : selectedValue2 === "qty2" ? (
                  <Typography>{item1.qty} {item1.price} {item1.name}</Typography>
                ) : (
                  <Typography>{item1.name} {item1.price} {item1.qty}</Typography>
                )}
                {/* <div>
                  x: {position.x.toFixed(0)}, y: {position.y.toFixed(0)}
                </div> */}
              </div>
             
              
              
              
              
              
              
              ))
            ) : item.value.toString().slice(-3) === 'png' ? (
              <Box sx={{width:"20%"}} className="itemHover">
                <img src={imageResult} alt="restaurant" style={basicStyle.find((style: List) => style.id === item.id)} className="reciept-image-style" />
              </Box>
            ) : item.value instanceof Date ? (
              selectedValue === 'date1' ? (
                <Typography style={basicStyle.find((style: List) => style.id === item.id)}> Date: {formattedDate1}</Typography>
              ) : selectedValue === 'date2' ? (
                <Typography style={basicStyle.find((style: List) => style.id === item.id)}>Date: {formattedDate2}</Typography>
              ) : (
                <Typography style={basicStyle.find((style: List) => style.id === item.id)}>Date: {formattedDate3}</Typography>
              )
            ) : (
              <Box className="itemHover">
                <Typography style={basicStyle.find((style: List) => style.id === item.id)}>
                  {item.value}
                </Typography>
              </Box>
            )}
          </ListItem>
        </Draggable>
      ))}
    </List>



    </Paper>




      </Box>
    
      </Box>
      </Box>
    )
}


export default RecieptBuilder