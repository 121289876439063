import { Box,Button,IconButton, Typography,Autocomplete,TextField } from '@mui/material'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close"
import {t} from "i18next"
import "./kdsSetupCreate.css"

const rows=[
  {
    id:1,
    stationName:"Kitchen",
    pairedKdsDevice:"KDS 1",
    selectCourses:["course1","course2","course3"]
  },
  {
    id:2,
    stationName:"Kitchen",
    pairedKdsDevice:"KDS 1",
    selectCourses:["course1","course2","course3"]
  },
  {
    id:3,
    stationName:"Food Station1",
    pairedKdsDevice:"KDS 1",
    selectCourses:["course1","course2","course3"]
  },
  {
    id:4,
    stationName:"Food Station2",
    pairedKdsDevice:"KDS 1",
    selectCourses:["course1","course2","course3"]
  }

]

const initialCourseItems=[
  {
    id:1,
    course:"Course 1"
  },
  {
    id:2,
    course:"Course 2"
  },
  {
    id:3,
    course:"Course 3"
  },
  {
    id:4,
    course:"Course 4"
  },
  {
    id:5,
    course:"Course 5"
  },
]


const initialMultiPleOrdersList=[
  {
    id:1,
    order:"Void Orders",
    description:"The order has been voided from the POS workstation."
  },
  {
    id:2,
    order:"VIP orders",
    description:"A VIP customer"
  },
  {
    id:3,
    order:"Recalled Orders",
    description:"The order has been recalled to the display after marked as complete previously"
  },
  {
    id:4,
    order:"Bumped Orders",
    description:"A rush order"
  },
  {
    id:5,
    order:"Late Orders",
    description:"The orders which are already getting late (As per the set item preparation time)"
  },
  {
    id:6,
    order:"Dine In Orders",
    description:"Dine In Orders"
  },
  {
    id:7,
    order:"Collection Orders",
    description:"Collection Orders"
  },
  {
    id:8,
    order:"Running Orders",
    description:"The additional orders being placed by a single customer while having food"
  },
  {
    id:9,
    order:"Delivery Orders",
    description:"Delivery Orders"
  },
]

function KdsSetupCreate() {
  // course functions
  const [courseItems, setCourseItems] = useState(initialCourseItems);
   // Function to handle the drag-and-drop reordering
   const handleDragEnd = (result:any) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = [...courseItems];
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setCourseItems(reorderedItems);
  };



  // orders functions
  const [orders, setOrders] = useState(initialMultiPleOrdersList);
  const handleDragEndOfOrders = (result:any) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = [...orders];
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setOrders(reorderedItems);
  };
  


 
  return (
   <Box sx={{padding:"15px"}}>
   <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
   <IconButton sx={{ marginRight: "50px" }} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
        <CloseIcon />
     </IconButton>
     <Typography className='kds-setup-create-header'>{t("kds_setup_create.kds_setup_create")}<span className='kds-setup-create-sub-header'>(madhapur branch)</span></Typography>
     <Box>
      <Button sx={{marginRight:"15px"}} color='warning' variant='outlined'>{t("kds_setup_create.clear")}</Button>
      <Button color='warning' variant='contained'>{t("kds_setup_create.save")}</Button>
     </Box>
   </Box>
   <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
   <Paper sx={{width:"70%",padding:"10px"}}>
      <Typography className='kds-setup-create-arrange-text'>{t("kds_setup_create.arrange_the_following")}</Typography>
      <Box sx={{padding:"5px",display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
      <TableContainer component={Paper} sx={{width:"90%"}}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{width:200}} className='kds-setup-create-table-header' align='left'>{t("kds_setup_create.kds_station_name")}</TableCell>
            <TableCell sx={{width:200}} className='kds-setup-create-table-header' align="center">{t("kds_setup_create.paired_kds_device")}</TableCell>
            <TableCell sx={{width:50}} className='kds-setup-create-table-header' align="left">{t("kds_setup_create.select_courses")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {rows.map((row) => (
            <TableRow
              
              key={row.id}
              sx={{'&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className='kds-setup-create-table-row' align='left' component="th" scope="row">
                {row.stationName}
              </TableCell>
              <TableCell className='kds-setup-create-table-row' align='center' component="th" scope="row">
                {row.pairedKdsDevice}
              </TableCell>
              <TableCell className='kds-setup-create-table-row' align='right'>
             <Box sx={{width:"95%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
             <Autocomplete
              options={row.selectCourses}
              sx={{ width: 250 }}
              renderInput={(params) => <TextField {...params} label={<Typography className='kds-setup-create-label-text'>{t("kds_setup_create.select_courses")}</Typography>} />}
            />
            <IconButton>
              <CloseIcon/>
            </IconButton>
             </Box>
              </TableCell>
            </TableRow>
          ))}
         
        </TableBody>
      </Table>
    </TableContainer>

      </Box>
      <Button className='kds-setup-create-table-add-new' sx={{textTransform:"none",margin:"15px"}} color='warning'>{t("kds_setup_create.add_new_kds_station")}</Button>
     </Paper>

   </Box>
   <Box sx={{marginTop:5,display:"flex",justifyContent:"center",alignItems:"center"}}>
    <Paper sx={{width:"70%",padding:"20px"}}>
      <Typography className='kds-setup-create-table-header'>{t("kds_setup_create.sequence_wise")} </Typography>
      <Typography className='kds-setup-create-set-sequence-text'>{t("kds_setup_create.set_the_sequence")}</Typography>

      <Paper sx={{padding:"20px",marginTop:"20px"}} elevation={3}>
      <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
            {courseItems.map((item, index) => (
              <Draggable key={item.id} draggableId={item.course} index={index}>
                {(provided) => (
                  <Typography
                   className='kds-setup-create-drag-text'
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    {item.course}
                  </Typography>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
       
     

      </Paper>
      
    </Paper>
   </Box>
   <Box sx={{marginTop:5,display:"flex",justifyContent:"center",alignItems:"center"}}>
    <Paper sx={{width:"70%",padding:"20px"}}>
      <Typography className='kds-setup-create-table-header'>{t("kds_setup_create.prioritisation")} </Typography>
      <Typography className='kds-setup-create-set-sequence-text'>{t("kds_setup_create.admin_can_set")}</Typography>
      <Paper   sx={{padding:"20px",marginTop:"15px"}} elevation={3}>
      <DragDropContext onDragEnd={handleDragEndOfOrders}>
      <Droppable droppableId="list">
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
            {orders.map((item, index) => (
              <Draggable key={item.id} draggableId={item.order} index={index}>
                {(provided) => (
                  <Box
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    <Box sx={{display:"flex",alignItems:"center",width:"80%"}}>
                    <Typography sx={{width:"30%"}} className='kds-setup-create-drag-text'>{item.order}</Typography>
                    <Typography className='kds-setup-create-drag-text2'>{item.description}</Typography>
                    </Box>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>      
       </Paper>
    </Paper>
   </Box>

     
   </Box>
  )
}

export default KdsSetupCreate
