import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import { Paper, Divider } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Link, useNavigate } from "react-router-dom";

export default function CustomerGroups() {
  const { t } = useTranslation();
  const toast = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectedGroup, setSelectedGroup] = useState([]);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    groupname: { value: null, matchMode: FilterMatchMode.CONTAINS },
    sortOrder: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const breadcrumbs = [
    <Typography key="1">{t("sales.sales_label")}</Typography>,
    <Typography className="primaryColor" key="3">
      {t("sales.customer_groups")}
    </Typography>,
  ];
  const groupData = [
    {
      id: 1,
      groupname: "default",
      sortOrder: 0,
    },
    {
      id: 2,
      groupname: "hubrise",
      sortOrder: 2,
    },
    {
      id: 3,
      groupname: "default(Default)",
      sortOrder: 1,
    }
  ];
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const userDeletionConformation = () => {
    confirmDialog({
      message: "Are you sure you want to delete selected group(s)?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      rejectClassName: "p-button-text p-button-danger",
      accept,
      reject,
    });
  };
  const renderHeader = () => {
    return (
      <div className="userTableHeader">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            style={{ width: "40vw" }}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
        <div className="userTableHeader">
          <Button
            onClick={() => navigate('/createGroup')}
            severity="warning"
            icon="pi pi-users"
            label={t("sales.create_group")}
            rounded
            size="small"
          />

          {selectedGroup.length > 0 && (
            <Button
              onClick={userDeletionConformation}
              style={{ marginLeft: "10px" }}
              severity="danger"
              icon="pi pi-users"
              label={t("buttons.delete")}
              rounded
              size="small"
            />
          )}
        </div>

      </div>
    );
  };
  const header = renderHeader();

  const RowActions = (rowData) => {
    return (
      <div className="headerUserContainer">
        <Link to='/editGroup' state={rowData}>
          <Button
            className="edituser"
            size="small"
            aria-label="Edit Group"
            icon="pi pi-user-edit"
            rounded
          />
        </Link>
      </div>
    );
  };

  const accept = () => {
    toast.current.show({
      severity: "success",
      summary: "Confirmed",
      detail: "User Deletion Successful",
      life: 3000,
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Paper className="paper" sx={{ mb: 2 }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Divider className="divider" />
        <div className="card">
          <DataTable
            value={groupData}
            paginator
            selectionMode="checkbox"
            selection={selectedGroup}
            onSelectionChange={(e) => setSelectedGroup(e.value)}
            rows={5}
            header={header}
            filters={filters}
            globalFilterFields={["groupname", "sortOrder"]}
            rowsPerPageOptions={[5, 10, 25]}
          >
            <Column selectionMode="multiple"></Column>
            <Column field="groupname" sortable header="Customer Group Name"></Column>
            <Column field="sortOrder" sortable header="Sort Order"></Column>
            <Column header="Actions" body={RowActions}></Column>
          </DataTable>
        </div>
      </Paper>
    </Box>
  );
}
