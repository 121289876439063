import React from 'react'
import { Box, Typography, Autocomplete, TextField, Paper, IconButton, InputBase, SelectChangeEvent } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { t } from 'i18next'
import "./inventoryLists.css"

const columns: GridColDef[] = [
    
    {
        field: 'date',
        //@ts-ignore
        headerName: t("stock_history.date"),
        headerClassName: "inventory-list-table-column",
        flex:1
        
    },
    {
        field: 'product',
        //@ts-ignore
        headerName: t("stock_history.product_name"),
        headerClassName: "inventory-list-table-column",
        flex:1
        
    },
    {
        field: 'variation',
        //@ts-ignore
        headerName: t("stock_history.variation"),
        headerClassName: "inventory-list-table-column",
        flex:1
        
    },
    {
        field: 'reason',
        //@ts-ignore
        headerName: t("stock_history.reason"),
        headerClassName: "inventory-list-table-column",
        sortable: true,
        flex:1
        
    },
    {
        field: 'location',
        //@ts-ignore
        headerName: t("stock_history.location"),
        headerClassName: "inventory-list-table-column",
        
        sortable: true,
        flex:1
        
    },
];
const locationLists = ["Location1", "Location2", "Location3", "Location4"]

const rows = [{
    id: 1,
    date: "12-08-23; 14:20 PM", product: "pizza", variation: "10 Inch", reason: "12 Received", location: "location1"
},
{
    id: 2,
    date: "12-08-23; 14:20 PM", product: "pizza", variation: "10 Inch", reason: "12 Received", location: "location1"
}]





function InventoryHistory() {


    const [userInput,setUserInput]=React.useState<string>("")
    const [userSelectInput,setUserSelectInput]=React.useState<string>(locationLists[0])

    const handleInput=(event:React.ChangeEvent<HTMLInputElement>)=>{
        setUserInput(event.target.value)

     
    }
    const handleInputChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setUserSelectInput(newValue); // Update the inputValue state with the current input value
      };

      const getHover = () => {
        return "inventory-list-table-row-hover inventory-list-table-row"
    }


    const getFontSize = () => {
        return "inventory-list-table-row"
    }
    
    return (
        <Box sx={{padding:"20px",backgroundColor:"#f9f9f9"}}>
            <Box className="inventory-header-names-order" sx={{ width: "100%",padding:"10px",backgroundColor:"#FFFFFF" }}>
              <Box sx={{width:"50%",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
              <Typography className='inventory-header-name'>Inventory History</Typography>
                <Box sx={{ width: "68%", display: "flex",justifyContent:"space-between", alignItems: "center" }}>
                    <Autocomplete
                    defaultValue={locationLists[0]}
                        defaultChecked
                        value={userSelectInput}
                        id="combo-box-demo"
                        onInputChange={handleInputChange}
                        options={locationLists}
                        sx={{ width: 150 }}
                        renderInput={(params) => <TextField color='warning' {...params} label={locationLists[0]} />}


                    />
                    <Paper elevation={2}
                        
                        sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "68%" }}
                    >
                        <IconButton sx={{ p: "10px" }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                           onChange={handleInput}
                           value={userInput}

                            name="inventoyHistorySearch"
                            sx={{ ml: 1, flex: 1 }}
                            //@ts-ignore
                            placeholder={t("stock_history.search_by_name_sku")}
                            inputProps={{ "aria-label": "search google maps" }}
                        />

                    </Paper>
                    
                </Box>

              </Box>

               
            </Box>


            <Box  sx={{marginTop:"15px",padding:"10px"}}>
                <Paper elevation={1} sx={{ height: 400, width: '100%',padding:"5px" }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        getRowClassName={getHover}
                        getCellClassName={getFontSize}
                    />
                </Paper>

            </Box>

        </Box>
    )
}

export default InventoryHistory
