import { Box, Button, IconButton, Paper, TextField, Typography, Autocomplete, Switch, Divider, SelectChangeEvent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from 'i18next'

import "./editInventory.css"
import React from 'react';
const locationLists = ["Location1", "Location2", "Location3", "Location4"]
const reasonsList=["Not Received","Received"]
const unitsList=["Kg's","Ltr's"]

interface State{
    amount:number
    barcode:string
    quantity:number
    toggleQuntity:number,
    


}



const initialCheckboxValues:State={
    amount:0,
    barcode:"",
    quantity:0,
    toggleQuntity:0,
    
}



const EditInventory = () => {
     
    const [checkboxValues,setCheckboxValues]=React.useState<State>(initialCheckboxValues)
    const [controlToggle,setControlToggle]=React.useState(false)

    const [location,setLocation]=React.useState(locationLists[0])
    const [reason,setReason]=React.useState(reasonsList[0])
    const [unitPerItem,setUnitPerItem]=React.useState(unitsList[0])


    



    const handleCheckboxValues=(event:React.ChangeEvent<HTMLInputElement>)=>{
        const {type,value,name}=event.target
        setCheckboxValues((prev)=>({
            ...prev,
            [name]:value
        }))
    }

    const handleToggle=()=>{
        setControlToggle(!controlToggle)
    }



  return (
    <Box sx={{backgroundColor:"#f9f9f9",padding:"20px"}}>
       <Box sx={{backgroundColor:"#ffffff",width:"100%",padding:"8px"}}>
       <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",margin:"10px"}}>
            <IconButton className='create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover'>
            <ArrowBackIcon/>
            </IconButton>    
            <Typography className='edit-inventory-header-name'>{t("edit_inventory.edit_inventory")}</Typography>
            <IconButton>
            <Button sx={{textTransform:"none"}} color='warning' variant='contained'>{t("edit_inventory.save")}</Button>
            </IconButton>
        </Box>
       </Box>
        <Divider/>
       <Box sx={{marginTop:"15px",width:"100%"}}>
       <Paper elevation={1} sx={{padding:"20px"}}>
            <Typography sx={{margin:"10px"}}>{t("edit_inventory.details")}</Typography>

            

            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"60%",margin:"10px"}}>
            <TextField
            color='warning'
            id="outlined-required"
            label={t("edit_inventory.product_name")}
            name="productName"
            defaultValue="pizza"
            disabled
            sx={{width:"35%"}}
            />
            <TextField
            id="outlined-required"
            label={t("edit_inventory.sku")}
            defaultValue="110022"
            name="sku"
            sx={{width:"20%"}}
            color='warning'
            disabled
            />

            <Autocomplete
            id="combo-box-demo"
            options={locationLists}
            defaultValue={locationLists[0]}
            sx={{ width:"35%" }}
            renderInput={(params) => <TextField color='warning' {...params} label={t("edit_inventory.select_location")} />}
            />
            </Box>

             <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"60%",margin:"10px"}}>
             <Autocomplete
            id="combo-box-demo"
            options={unitsList}
            sx={{ width: "35%" }}
            renderInput={(params) => <TextField color='warning' {...params} label={t("edit_inventory.unit_per_item")} />}
            />
            <TextField
            id="outlined-required"
            label={t("edit_inventory.amount")}
            onChange={handleCheckboxValues}
            name="amount"
            sx={{width:"20%"}}
            color='warning'
            />
            <TextField
            id="outlined-required"
            label={t("edit_inventory.barcode")}
            onChange={handleCheckboxValues}
            name='barcode'
            sx={{width:"35%"}}
            color='warning'
            />
            
            </Box>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"36%",margin:"10px"}}>
            <Autocomplete
            id="combo-box-demo"
            options={locationLists}
            sx={{ width: "58%" }}
            renderInput={(params) => <TextField color='warning' {...params} label={t("edit_inventory.select_reason")} />}
            />
             <TextField
            id="outlined-required"
            label={t("edit_inventory.quantity")}
            onChange={handleCheckboxValues}
            name='barcode'
            sx={{width:"33%"}}
            color='warning'
            />
            

            </Box>
            <Box>

            <Box  sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"20%",margin:"15px"}}>
                <Typography className='edit-inventory-recurring-text'>{t("edit_inventory.recurring_stock")}</Typography>
                <IconButton onClick={handleToggle}>
                    <Switch color='warning'/>
                </IconButton>
            </Box>
                
            </Box>
           {controlToggle && 
             (
                <Box sx={{marginTop:"10px"}}>
                <Typography className='edit-inventory-paragraph-text'>{t("edit_inventory.every_day")}</Typography>
                <TextField
                id="outlined-required"
                label={t("edit_inventory.quantity")}
                onChange={handleCheckboxValues}
                name='toggleQuantity'
                color='warning'
                sx={{width:"100px"}}
                />
                </Box>
             )
           
           }
            
            
        </Paper>
       </Box>
    </Box>
  )
}

export default EditInventory
