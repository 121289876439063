import * as React from 'react';
import '../options/createProduct.css'
import { useEffect } from 'react';
import { Box, Modal, Button, Typography, TextField, Checkbox, FormControlLabel ,FormControl,MenuItem,InputLabel,Select} from '@mui/material';

import Visibility from '../category/visibility'
import image from '../../assets/prouctAddImage.png'
import { SelectChangeEvent } from '@mui/material';
import './menuModal.css';

interface State{
    menu:string;
    description:string;
    showPOS: boolean;
    showOnline: boolean;
    location:string;
    startTime:string;
    endTime:string;
}
interface Prop{
    open:boolean;
    handleClose: () => void;
    handleData: (data: State) => void;
   
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
   
    boxShadow: 24,
    p: 4,
  };
  


  

    
     
    

export default function NestedModal(props:Prop) {
    
const {open,handleClose,handleData}=props
   
  const [open1, setOpen] = React.useState(false);


  
  

  const [state, setState] = React.useState({
    menu: '',
    description: '',
    showPOS: false,
    showOnline: false,
    location:"",
    startTime:"",
    endTime:""
  });
 
  

  const handleChange = (event: SelectChangeEvent) => {
    setState((prevState) => ({
        ...prevState,
        location:event.target.value
      }));
  };

  useEffect(() => {
    setOpen(open);
    
    
  }, [open]);

  const getTime=(data1:string,data2:string)=>{
    
    setState((prevState) => ({
        ...prevState,
        startTime:data1,
        endTime:data2
      }));
    
  }
  
  const handleSave=()=>{
    handleData(state);
    setState({
        menu: '',
        description: '',
        showPOS: false,
        showOnline: false,
        location:"",
        startTime:"",
        endTime:""
      })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;

    setState((prevState) => ({
      ...prevState,
      [name]: name === 'showPOS' || name === 'showOnline' ? checked : value,
    }));
  };

  const [imageValue, setImageValue] = React.useState<string | null>(null);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
    
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            setImageValue(e.target?.result as string);
          };
          reader.readAsDataURL(file);
        }
      };

    const handleData1=()=>{

    }
    const handleDate=()=>{
      
    }

//   const [openChild, setOpenChild] = React.useState(false);
//   const handleOpenChild = () => {
//     setOpenChild(true);
//   };
//   const handleCloseChild = () => {
//     setOpenChild(false);
//   };
// const result=true
  return (
    <div>
     
      <Modal
        open={open1}
         onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style,  }}>
        
       
        <div style={{  display: 'flex' }}>
          <div style={{ width: "90%" }}>
            <div className="create-product-text-container">
              <TextField
                id="outlined-basic"
                label="Menu name"
                variant="outlined"
                name="menu"
                value={state.menu}
                onChange={handleInputChange}
                color="warning"
                style={{ width: '45%' }}
              />
             <FormControl sx={{marginLeft:"1%",width:'45%'}}>
  <InputLabel id="demo-simple-select-label">select Location</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={state.location}
    label="select Location"
    onChange={handleChange}
    color='warning'
  >
    <MenuItem value={"hyd"}>Hyd</MenuItem>
    <MenuItem value={"delhi"}>Delhi</MenuItem>
    <MenuItem value={"vizag"}>Vizag</MenuItem>
  </Select>
</FormControl>
            </div>
            <div style={{ marginTop:"3%"}}>
          <TextField
            multiline
            label="Description"
            placeholder="Enter a short description for the category"
            value={state.description}
            name='description'
            onChange={handleInputChange}
            style={{ width: "100%" }}
            color="warning"
          />
        </div>
          </div>

          <Button
      style={{ width: '25%', marginLeft: '10%' }}
    >
      <div   className="create-product-img-con">
       
<img
           src={imageValue || image} 
            alt="product"
            style={{ width: '100%' ,backgroundSize:"cover"}}
           
            onClick={() => {
              const input = document.getElementById('image-upload-input');
              if (input) {
                input.click();
        
              }
            }}
          />
        {
          imageValue===null ? (<Typography className="create-product-img-text">Add img</Typography>):("")
        }
        
      </div>
      
      <input
            type="file"
            hidden
            id="image-upload-input"
            onChange={handleImageUpload}
          />
    </Button>
        </div>

      

        <div style={{ marginLeft: '3%', marginTop: "1%", display: "flex", alignItems: "center",justifyContent:"space-between" }}>
          <div style={{width:"51%"}} >
            <FormControlLabel
              control={<Checkbox color="warning" name='showPOS' checked={state.showPOS} onChange={handleInputChange} />}
              label={<Typography className='create-product-switch-text'>Show POS</Typography>}
              style={{width:"40%"}}
            />
            <FormControlLabel
              control={<Checkbox color="warning" name='showOnline' checked={state.showOnline} onChange={handleInputChange} />}
              label={<Typography className='create-product-switch-text'>Show Online</Typography>}
              style={{width:"40%"}}
            />
        </div>

          <div style={{ display: "flex", alignItems: "center",}}>
            <Button style={{ textTransform: "none", textDecoration: "underline" }} className="create-product-cancel_Button">Cancel</Button>
            <Button style={{ textTransform: "none" }} className="create-product-button" onClick={handleSave}>Save</Button>
          </div>
        </div>
      {/* <Typography className='menuModal'>Visibility  <span className='menuModal-para'>(Availability of Menu) </span></Typography>
      <FormControl>
     
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="showOnly" control={<Radio color='warning'/>} label={<Typography className='menuModal-para' >Show only from</Typography>} onClick={handleOpenChild}/>
        <FormControlLabel value="hideUntil" control={<Radio color='warning'/>} label= {<Typography className='menuModal-para'>Hide until</Typography>}/>
        
        
      </RadioGroup>
    </FormControl>
    <Modal
        open={openChild}
        onClose={handleCloseChild}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
      
        </Box>
      </Modal> */}
      <Visibility handleTime2={getTime} handleData={handleData1} handleDate={handleDate}/>
      <Typography className='menuModal'>Select Channel Type</Typography>
        </Box>
      </Modal>
    </div>
  );
}