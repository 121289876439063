import { Button, Paper, Typography, TextField, Switch, Divider, Checkbox, FormGroup, FormControlLabel, IconButton, Box } from '@mui/material';
import React, {  useState,useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation,useQuery } from '@apollo/client';
import { createUserRole,UPDATE_ROlE } from '../../graphicalQl/mutation/userProfileMutation';
import { No_OF_PERMISSIONS, GET_EDIT_USER_ROLE,User_Roles } from '../../graphicalQl/usequery/userProfileUseQuery';





import { t } from 'i18next';

import "./createUserRole.css"
const label = { inputProps: { 'aria-label': 'Switch demo' } };




interface stateMutualIdList{
  id:number
}

interface PermissionList{
  name: string
  appliesFor:string
  permissionId:string

}

const CreateUserRole = () => {
  const {editId}=useParams()

  const gettingEditPermissionsListSeperate=(permissions:any)=>{
    
    const editPosPermissionList:any=[]
    const editAdminPermissionList:any=[]

    permissions.map((eachPermission:any)=>{
      const {appliesFor}=eachPermission   
      if(JSON.parse(appliesFor).length===1 && JSON.parse(appliesFor)[0]==="pos"){
        editPosPermissionList.push(eachPermission.permissionId)
      }else if (JSON.parse(appliesFor).length===1 && JSON.parse(appliesFor)[0]==="admin"){
        editAdminPermissionList.push(eachPermission.permissionId)
        
      }else{
        editPosPermissionList.push(eachPermission.permissionId)
        editAdminPermissionList.push(eachPermission.permissionId)
      }
      
    })

    if(editAdminPermissionList!==null){
      const addCheckboxesToTheLocationList=permissionsList.map((eachPermission:any)=>{
        if(editAdminPermissionList.includes(eachPermission.permissionId)){
          return {...eachPermission,isChecked:true}
        }return {...eachPermission,isChecked:false}
      }
      )
    
      setAdminPermissionList(addCheckboxesToTheLocationList)
      setInitialAdminPermissionList(addCheckboxesToTheLocationList)
    

     }

     if(editPosPermissionList!==null){
      const addCheckboxesToTheLocationList=permissionsList.map((eachPermission:any)=>{
        if(editPosPermissionList.includes(eachPermission.permissionId)){
          return {...eachPermission,isChecked:true}
        }return {...eachPermission,isChecked:false}
      }
      )
        
      
      setPermissionsList(addCheckboxesToTheLocationList)
     setInitialPosPermissionList(addCheckboxesToTheLocationList)

     }
  
  }
  
  
  const [permissionsList,setPermissionsList]=useState<PermissionList[]>([])
  const {data,loading,error}=useQuery(No_OF_PERMISSIONS,{context:{clientName:"userClient"}})
  if(error){
    console.log(error.message)
  }
  const [initialUserRolePermissions,setInitialUserRolePermissions]=useState([])
  
  const {data:editUserData,loading:editUserLoading,error:editUserError}=useQuery(GET_EDIT_USER_ROLE,{context:{clientName:"userClient"},variables:{roleId:editId}})
  if(editUserError){
    console.log(editUserError.message)
  }
  useEffect(()=>{
   if(editId!==undefined){
    if(editUserData){  
      const {roleName,permissions,roleId}=editUserData.role
      setRoleNameInput(roleName)
      gettingEditPermissionsListSeperate(permissions)
      
      
      
    }
   }
  })

  
  useEffect(() => {
    if (data) {
      const requiredDataList = data.permissions.edges.map((each: any) => ({
        name: each.node.name,
        appliesFor:each.node.appliesFor,
        permissionId:each.node.permissionId,
        isChecked:false
      }

      ))
      setPermissionsList(requiredDataList)
      setInitialUserRolePermissions(requiredDataList)
    }
  }, [data]);
  
 
  const navigate = useNavigate()
  

  const [creatingUserRole] = useMutation(createUserRole, {
    onCompleted: (data) => {
      if (data) {
        console.log(data)
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: 'userClient' },
    refetchQueries:[User_Roles]
  });



  const [updateUserRole] = useMutation(UPDATE_ROlE, {
    onCompleted: (data) => {
      console.log(data)
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: 'userClient' },
    refetchQueries:[User_Roles]
  });

  //Update User Function
  
  const handleCreateSaveSubitButton=(event:React.FormEvent<HTMLFormElement>)=>{
    event.preventDefault()

    const gettingAvailableIdsFromPosPermissions=posPermissionList.map((each:any)=>{
      if(each.isChecked){
        return each.permissionId
      }
    })
    const gettingAvailableIdsFromAdminPermissions=adminPermissionList.map((each:any)=>{
      if(each.isChecked){
        return each.permissionId
      }
    })

    const mutualIdList= [...gettingAvailableIdsFromPosPermissions,...gettingAvailableIdsFromAdminPermissions]
    
    const filterUnique = (value:string, index:number, self:any) => {
      return self.indexOf(value) === index;
    };
    const uniqueIdList=mutualIdList.filter(filterUnique);
    


    
    creatingUserRole({variables:{
      businessId:2,
      roleName:roleNameInput,
      permissions:JSON.stringify(uniqueIdList)
        
    }})
   
    
  }

  const handleEditSaveSubitButton=(event:React.FormEvent<HTMLFormElement>)=>{
    event.preventDefault()

    const gettingAvailableIdsFromPosPermissions=posPermissionList.map((each:any)=>{
      if(each.isChecked){
        return each.permissionId
      }
    })
    const gettingAvailableIdsFromAdminPermissions=adminPermissionList.map((each:any)=>{
      if(each.isChecked){
        return each.permissionId
      }
    })

    const mutualIdList= [...gettingAvailableIdsFromPosPermissions,...gettingAvailableIdsFromAdminPermissions]
    
    const filterUnique = (value:string, index:number, self:any) => {
      return self.indexOf(value) === index;
    };
    const uniqueIdList=mutualIdList.filter(filterUnique);
     
    updateUserRole({variables:{
      businessId:2,
      roleId:editId,
      roleName:roleNameInput,
      permissions:JSON.stringify(uniqueIdList)
        
    }})

    
  }

  const [fisrtCardisTrue, setFirstCardIsTrue] = useState(true)
  const [secondCardisTrue, setSecondCardIsTrue] = useState(true)
  const [posPermissionList, setPosPermissionList] = useState<any>([])
  const [adminPermissionList, setAdminPermissionList] = useState<any>([])

  const [initialPosPermissionList, setInitialPosPermissionList] = useState<any>([])
  const [initialAdminPermissionList, setInitialAdminPermissionList] = useState<any>([])
  
  
  
  
  const gettingPermissionsListSeperate=()=>{
    const emptyPosPermissionList:any=[]
    const emptyAdminPermissionList:any=[]

    permissionsList.map((eachPermission:any)=>{
      const {appliesFor}=eachPermission   
      if(JSON.parse(appliesFor).length===1 && JSON.parse(appliesFor)[0]==="pos"){
          emptyPosPermissionList.push(eachPermission)
      }else if (JSON.parse(appliesFor).length===1 && JSON.parse(appliesFor)[0]==="admin"){
        emptyAdminPermissionList.push(eachPermission)
        
      }else{
        emptyPosPermissionList.push(eachPermission)
        emptyAdminPermissionList.push(eachPermission)
      }
      
    })
    
    setPosPermissionList(emptyPosPermissionList)
    setAdminPermissionList(emptyAdminPermissionList)

    setInitialAdminPermissionList(emptyAdminPermissionList)
    setInitialPosPermissionList(emptyPosPermissionList)
  }
  useEffect(()=>{
    gettingPermissionsListSeperate()
  },[permissionsList])



  const handleFirstCard = () => {
    setFirstCardIsTrue(!fisrtCardisTrue)

  }

  const handleSecondCard = () => {
    setSecondCardIsTrue(!secondCardisTrue)

  }

  const handleChange1= (id: string, isChecked: boolean) => {
   const empty:any=[]
    const filteredList = posPermissionList.map((each:any) => {
      if (each.permissionId === id) {
        empty.push(each.permissionId)
        return { ...each, isChecked: !each.isChecked }
      } return each
    })
  
    
    setPosPermissionList(filteredList)
  };

  const handleChange2= (id: string, isChecked: boolean) => {
    
    const filteredList = adminPermissionList.map((each:any) => {
      if (each.permissionId === id) {
        return { ...each, isChecked: !each.isChecked }
      } return each
    })

    setAdminPermissionList(filteredList)
  };
 


  //Role name input functions
  const [roleNameInput,setRoleNameInput]=React.useState("")
  const handleRoleNameInput=(event:React.ChangeEvent<HTMLInputElement>)=>{
        setRoleNameInput(event.target.value)
  }

 

  const handleClearButton=()=>{
   setPosPermissionList(initialPosPermissionList)
   setAdminPermissionList(initialAdminPermissionList)
   setRoleNameInput("")

  }


  
  

  return (
    <div style={{ padding: "10px",backgroundColor:"#f9f9f9" }}>
     <form onSubmit={editId===undefined?handleCreateSaveSubitButton:handleEditSaveSubitButton}>
     <div className='create-a-use-role-header-order' style={{ marginBottom: "10px",padding:"10px" }}>
        <IconButton className='create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover' onClick={() => { navigate("/user-role") }}>
          <ArrowBackIcon/>
        </IconButton>
        <Typography className='create-a-use-role-heading'> {editId===undefined?t("create_a_user_role.create_a_user_role"): "Edit a User Role"}</Typography>
        <div className='create-a-use-role-header-buttons-order'>
          {editId===undefined&&<Button onClick={handleClearButton} type='submit' sx={{textTransform:"none"}} className='create-a-use-role-clear-button' variant='outlined' color='warning'>{t("create_a_user_role.clear")}</Button>}
          <Button type='submit' sx={{textTransform:"none"}}  variant='contained' color='warning'>{t("create_a_user_role.save")}</Button>
        </div>

      </div>

      <Box sx={{padding:"25px"}}>
        <div>

          <TextField
            id="outlined-required"
            value={roleNameInput}
            label={t("create_a_user_role.role_name")}
            style={{ backgroundColor: "#ffffff", width: "26rem" }}
            color='warning'
            onChange={handleRoleNameInput}
          />

        </div>


        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", marginTop: "20px",width:"75%" }}>
          
            <Paper sx={{ width: "40%" }}>
              <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", alignItems: "center" }}>
                <div>
                  <Typography className='create-a-use-role-checkbox-heading' >{t("create_a_user_role.pos")}</Typography>
                  <Typography className='create-a-use-role-checkbox-paragraph'>{t("create_a_user_role.users_can_log")}</Typography>
                </div>
                <div>
                  <Switch onChange={handleFirstCard} color='warning' {...label} defaultChecked />
                </div>
              </div>
              <Divider />
              <FormGroup sx={{ padding: "10px" }}>
                {posPermissionList.map((each:any) => (
                  <FormControlLabel
                    disabled={!fisrtCardisTrue}
                    checked={each.isChecked}
                    control={<Checkbox   className="create-a-use-role-checkbox-hover" onChange={() => { handleChange1(each.permissionId, each.isChecked) }} color='warning' />} 
                    label={<Typography className='create-a-use-role-checkbox-label'>{each.name}</Typography>}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} key={each.id}
                  />
                ))}

              </FormGroup>
              
            </Paper>
        
          
            <Paper sx={{ width: "40%", padding: "10px" }}>
              <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", alignItems: "center" }}>
                <div>
                  <Typography className='create-a-use-role-checkbox-heading' >{t("create_a_user_role.back_office")}</Typography>
                  <Typography className='create-a-use-role-checkbox-paragraph'>{t("create_a_user_role.users_can_log_into_admin")}</Typography>
                </div>
                <div>
                  <Switch onChange={handleSecondCard} color='warning' {...label} defaultChecked />
                </div>
              </div>
              <Divider />
              <FormGroup sx={{ padding: "10px" }}>
                {adminPermissionList.map((each:any) => (
                  
                  <FormControlLabel
                  disabled={!secondCardisTrue}
                  control={
                    <Checkbox
                     checked={each.isChecked}
                      onChange={() => {
                        handleChange2(each.permissionId, each.isChecked);
                      }}
                      color='warning'
                    />
                  }
                  label={
                    <Typography className='create-a-use-role-checkbox-label'>
                      {each.name}
                    </Typography>
                  }
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  key={each.id}
                />
                ))}

              </FormGroup>
              
            </Paper>
          

        </div>



      </Box>

     </form>
         </div>
  )
}

export default CreateUserRole
